import { ChainId } from '@pancakeswap/chains'
import { ManipulateType } from 'dayjs'

export const v3InfoPath = `info/v3`

export const POOL_HIDE: { [key: string]: string[] } = {
  // TODO: update to our own
  [ChainId.ETHEREUM]: [
    '0x86d257cDB7bC9c0dF10E84C8709697F92770b335',
    '0xf8DBD52488978a79DfE6ffBd81A01Fc5948bF9eE',
    '0x8Fe8D9bb8Eeba3Ed688069c3D6b556C9ca258248',
    '0xA850478aDAACe4c08fc61DE44d8CF3b64f359bEc',
    '0x277667eB3e34f134adf870be9550E9f323d0dc24',
    '0x8c0411F2ad5470A66cb2E9C64536CFb8dcD54d51',
    '0x055284A4CA6532ECc219Ac06b577D540C686669d',
    '0xB078BF211E330b5F95B7114AE845188CC36B795D',
    '0x7778797342652bd27B365962Ffc7f6eCE356EB57',
    '0xe9825d867e3BeF05223bDA609fA8aB89AeF93797',
  ],
  [ChainId.BSC]: [
    '0x87196a3BCeC98116307bdc8B887c3074E8b5bc96',
    '0x4b2C510Fe0b6a50D117220cd6D66a9C714C83dFb',
    '0xf0b579380cA08e75441DC73c2Da9f27CE23725F2',
    '0x9829f916C617e1b269b410dfD84c7F22ad479417',
    '0x9829f916C617e1b269b410dfD84c7F22ad479417',
    '0xA0E4442DC4aDDba0A57be757fbb802b48BA8051C',
    '0xBAdBEdaFe2cA7318a25B665bfA307e91A3EEb88d',
    '0xa7619D726F619062d2d2BCAdbb2ee1FB1952d6d7',
    '0x5FB5EB2c8Ecbf712115007990C70B79F6B256f9b',
  ],
}

export const TOKEN_HIDE: { [key: string]: string[] } = {
  [ChainId.ETHEREUM]: [
    '0xD46bA6D942050d489DBd938a2C909A5d5039A161',
    '0x7dFB72A2AAd08C937706f21421B15bFC34Cba9ca',
    '0x12B32f10A499Bf40Db334Efe04226Cca00Bf2D9B',
    '0x160dE4468586B6B2F8a92FEB0c260Fc6cFC743B1',
    '0xD84787a01B0cad89fBCa231E6960cC0f3f18df34',
    '0xdb19f2052D2B1aD46Ed98C66336A5dAADEB13005',
  ],
  [ChainId.BSC]: ['0xdb19f2052D2B1aD46Ed98C66336A5dAADEB13005', '0x57a63C32CC2aD6CE4FBE5423d548D12d0EEDdfc1'],
}

export const TimeWindow: {
  [key: string]: ManipulateType
} = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
}

export const ONE_HOUR_SECONDS = 3600
export const ONE_DAY_SECONDS = 86400
export const MAX_UINT128 = 2n ** 128n - 1n

export const SUBGRAPH_START_BLOCK = {
  [ChainId.BSC]: 26956207,
  [ChainId.ETHEREUM]: 16950686,
  [ChainId.POLYGON_ZKEVM]: 750149,
  [ChainId.ZKSYNC]: 8639214,
  [ChainId.ARBITRUM_ONE]: 101028949,
  [ChainId.LINEA]: 1444,
  [ChainId.BASE]: 18942139,
  [ChainId.OPBNB]: 1721753,
}

export const NODE_REAL_ADDRESS_LIMIT = 50

export const DURATION_INTERVAL = {
  day: ONE_HOUR_SECONDS,
  week: ONE_DAY_SECONDS,
  month: ONE_DAY_SECONDS,
  year: ONE_DAY_SECONDS,
}

export const ALL_STATS_REWARD_DATA = [
  {
    address: '0x897e1ac1806814b49de9372a4dfece830f2adb81',
    minters_reward: 40909.09,
    lp_providers_reward: 108802.31,
    swapers_reward: 34563.08845720927,
    holders_reward: 27361.39,
    hoaxs_reward: 0,
    total_rewards: 211635.88,
  },
  {
    address: '0xf4cb73cfee5a4c82c3919bc98ed1c3698a3bf5b6',
    minters_reward: 6075.61,
    lp_providers_reward: 38880.79,
    swapers_reward: 9374.314888293075,
    holders_reward: 0,
    hoaxs_reward: 44444.44,
    total_rewards: 98775.15,
  },
  {
    address: '0x50e9001695dd7c57c01de75cbcf150e90045c272',
    minters_reward: 31593.16,
    lp_providers_reward: 31447.8,
    swapers_reward: 1716.8434981726668,
    holders_reward: 21672.39,
    hoaxs_reward: 925.93,
    total_rewards: 87356.12,
  },
  {
    address: '0x919f69f0cc187a9b0b8c2aafcf4968928d40ce90',
    minters_reward: 40909.09,
    lp_providers_reward: 0,
    swapers_reward: 264.93155385394243,
    holders_reward: 27361.39,
    hoaxs_reward: 0,
    total_rewards: 68535.41,
  },
  {
    address: '0x130fF493Df73fD6Ea77C268d32D4044533447c75',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27090.48,
    hoaxs_reward: 925.93,
    total_rewards: 68520.46,
  },
  {
    address: '0xb6D867c8e3655Fb28954496258DE3A89c6878A6C',
    minters_reward: 40909.09,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27361.39,
    hoaxs_reward: 0,
    total_rewards: 68270.48,
  },
  {
    address: '0x9970619bd2b7da17e8eac8d7c0eea4385d120ed7',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 292.2212297128985,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67886.75,
  },
  {
    address: '0xba879508fd68628bcb317d8cfa67bcafa5c92622',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 5.451813448583983,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67599.98,
  },
  {
    address: '0x885E9a0627cC5E60a8923Da4Eb9B4b8E66443C75',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67594.53,
  },
  {
    address: '0x3FC37eA4365542685d3bCAf87fFc1948b0544CD0',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 27090.48,
    hoaxs_reward: 0,
    total_rewards: 67594.53,
  },
  {
    address: '0x030147C315224D1eC19aFEAd0C135B17112B488F',
    minters_reward: 40504.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 22485.1,
    hoaxs_reward: 0,
    total_rewards: 62989.15,
  },
  {
    address: '0x7dB28F89F5b932fCE111a134f5Bd9700f72ab24D',
    minters_reward: 35643.56,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 24110.53,
    hoaxs_reward: 0,
    total_rewards: 59754.09,
  },
  {
    address: '0x8a29a62a19185374d1f8bb95e6289fb6da40814b',
    minters_reward: 33618.36,
    lp_providers_reward: 691.42,
    swapers_reward: 141.85778703240175,
    holders_reward: 23297.81,
    hoaxs_reward: 0,
    total_rewards: 57749.45,
  },
  {
    address: '0x70d50867373331acda3513fd353ec4d394f2331c',
    minters_reward: 5265.53,
    lp_providers_reward: 38380.35,
    swapers_reward: 6545.341681869733,
    holders_reward: 3521.76,
    hoaxs_reward: 1851.85,
    total_rewards: 55564.83,
  },
  {
    address: '0xB3291b0A7044a3418C2535400fd201d48cfD409b',
    minters_reward: 33213.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 18692.43,
    hoaxs_reward: 0,
    total_rewards: 51905.75,
  },
  {
    address: '0x2589ece7a6e4d35a3b21c8d60036026dd06247c5',
    minters_reward: 22277.23,
    lp_providers_reward: 13862.95,
    swapers_reward: 0,
    holders_reward: 14899.77,
    hoaxs_reward: 0,
    total_rewards: 51039.95,
  },
  {
    address: '0xC9Bc5D2D7FC83874339fCD084d79109aE1857147',
    minters_reward: 29567.96,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 19776.05,
    hoaxs_reward: 0,
    total_rewards: 49344.01,
  },
  {
    address: '0xceb54ea393413d805a56349673856eada5fb6089',
    minters_reward: 31188.12,
    lp_providers_reward: 10108.35,
    swapers_reward: 993.1161299297398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42289.59,
  },
  {
    address: '0xd3dadd55f478b05db486ca46d2568f4f3529d5c4',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 133.67016861404556,
    holders_reward: 16254.29,
    hoaxs_reward: 0,
    total_rewards: 40690.39,
  },
  {
    address: '0x49E8D12d5574F58897f6084105F60B661eD3Fb08',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 16254.29,
    hoaxs_reward: 0,
    total_rewards: 40556.72,
  },
  {
    address: '0xe3de35272dcc00a849190f121835e282567f2001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38838.98158575764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38838.98,
  },
  {
    address: '0xBdc3922244608bDb27f619616e6AdeD5Cc0C4e6A',
    minters_reward: 23087.31,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 15170.67,
    hoaxs_reward: 0,
    total_rewards: 38257.98,
  },
  {
    address: '0x46455f2fec29f31f058a50cc70621ffaccfd030a',
    minters_reward: 0,
    lp_providers_reward: 8168.61,
    swapers_reward: 26146.553606861256,
    holders_reward: 541.81,
    hoaxs_reward: 2777.78,
    total_rewards: 37634.75,
  },
  {
    address: '0xb38a90f14b24ae81ec0b8f1373694f5b59811d8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35305.48608030681,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35305.49,
  },
  {
    address: '0x0450875ebe4932979c42baa43dabd134fb67efa8',
    minters_reward: 0,
    lp_providers_reward: 34847.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34847.12,
  },
  {
    address: '0xca5be144d8d6579a9a99ce34960241751e031d23',
    minters_reward: 20252.03,
    lp_providers_reward: 596.45,
    swapers_reward: 176.62103590262828,
    holders_reward: 13545.24,
    hoaxs_reward: 0,
    total_rewards: 34570.34,
  },
  {
    address: '0xb9df77770111f477f66f0855cfd0c48ccbcf0d57',
    minters_reward: 12961.3,
    lp_providers_reward: 6221.96,
    swapers_reward: 6530.917099401918,
    holders_reward: 8668.95,
    hoaxs_reward: 0,
    total_rewards: 34383.13,
  },
  {
    address: '0xf1bf6179d17265c75875695720a110eaaaf2c637',
    minters_reward: 0,
    lp_providers_reward: 18826.15,
    swapers_reward: 15202.908861611983,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34029.06,
  },
  {
    address: '0x6b4b86183DDAB2d5aB9d13aB2334C01631F617B2',
    minters_reward: 20252.03,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 13545.24,
    hoaxs_reward: 0,
    total_rewards: 33797.27,
  },
  {
    address: '0xb7311802fE062000e15FB1E68B4CC66D311Db8F3',
    minters_reward: 22682.27,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 10836.19,
    hoaxs_reward: 0,
    total_rewards: 33518.46,
  },
  {
    address: '0x60445f1cd286a88631543ca1322bfe2070d50abc',
    minters_reward: 0,
    lp_providers_reward: 32621,
    swapers_reward: 872.5005205634601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33493.5,
  },
  {
    address: '0x331349DD8b8D8ff68A68f85F6628742DF30bFF81',
    minters_reward: 20252.03,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 13003.43,
    hoaxs_reward: 0,
    total_rewards: 33255.46,
  },
  {
    address: '0xf8c427669273956dc623c647346b39077a08c466',
    minters_reward: 14986.5,
    lp_providers_reward: 2678.84,
    swapers_reward: 1649.0619562025945,
    holders_reward: 10023.48,
    hoaxs_reward: 3703.7,
    total_rewards: 33041.58,
  },
  {
    address: '0x7c76f4DB70b7E2177de10DE3e2f668daDcd11108',
    minters_reward: 18226.82,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 12732.53,
    hoaxs_reward: 0,
    total_rewards: 30959.35,
  },
  {
    address: '0x2d72827b55805eb1b8990b52b9cef9f9b84d5e94',
    minters_reward: 17011.7,
    lp_providers_reward: 0,
    swapers_reward: 189.0511588193924,
    holders_reward: 11919.81,
    hoaxs_reward: 0,
    total_rewards: 29120.56,
  },
  {
    address: '0xa27Eea97aFf8415Ec2d6Bfbb7dc7fB68Cbf5638f',
    minters_reward: 28352.84,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28352.84,
  },
  {
    address: '0xfbdc4e31bfde31a99479ef642f152333903c2e4b',
    minters_reward: 0,
    lp_providers_reward: 17760.43,
    swapers_reward: 9556.592542258983,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 28242.95,
  },
  {
    address: '0x7E2f09781614b814914BcC1D43fD7eE9DA9F8452',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 25656.95,
  },
  {
    address: '0x677dCf56a2Ed4a75399CE0AF79A51a6039126d46',
    minters_reward: 24707.47,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24707.47,
  },
  {
    address: '0x63f324025dab9ec0093c29ed43f2c6b2b6b5092a',
    minters_reward: 17416.74,
    lp_providers_reward: 1767.04,
    swapers_reward: 1219.6093998335482,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 24466.96,
  },
  {
    address: '0xBaAbE72573778f34D201733B0735718be9076614',
    minters_reward: 24302.43,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24302.43,
  },
  {
    address: '0x15eea6a7aef6569b6a186672e68017ae0c1c4ef9',
    minters_reward: 0,
    lp_providers_reward: 22299.29,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22299.29,
  },
  {
    address: '0xe9c9979628e8afb0103f1a6ebb1c130df45a6e63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21910.37304812962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21910.37,
  },
  {
    address: '0xbdab095bfdff77eae0e44e3fa42b9da7632bbd7b',
    minters_reward: 0,
    lp_providers_reward: 15593.59,
    swapers_reward: 6310.388510093504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21903.98,
  },
  {
    address: '0xeeced771c782fa648c2a6902fdf5fa572c49964d',
    minters_reward: 0,
    lp_providers_reward: 12320.44,
    swapers_reward: 8835.433676031886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21155.87,
  },
  {
    address: '0x83060Dc624b0E761A8f1dB59627f20256d65B1e8',
    minters_reward: 12556.26,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8398.05,
    hoaxs_reward: 0,
    total_rewards: 20954.31,
  },
  {
    address: '0xc6c9c7928821415f29c192e2f2a5813956dfa82a',
    minters_reward: 405.04,
    lp_providers_reward: 17557.09,
    swapers_reward: 522.0028080325155,
    holders_reward: 541.81,
    hoaxs_reward: 1851.85,
    total_rewards: 20877.79,
  },
  {
    address: '0x198e040505559c459c2ea49ca4fe7f8a21af1546',
    minters_reward: 0,
    lp_providers_reward: 20648.37,
    swapers_reward: 227.3219447406803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20875.69,
  },
  {
    address: '0xffd570aed9ea8317960e9ddab7d84da3e249b86d',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 341.0832478481973,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20619.44,
  },
  {
    address: '0xa90cca9f7c88ffca698b51cc6dd21d4a9ebee7d4',
    minters_reward: 0,
    lp_providers_reward: 17824.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 20602.71,
  },
  {
    address: '0x0917c74b5007a71e8181cca933ea03bd26132b78',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 67.7222654000984,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20346.08,
  },
  {
    address: '0xdC2e4B1804974fa8F17ccF159d7cA2103E888137',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20278.36,
  },
  {
    address: '0xf181B3E384f98277Bc75AD26978ceb723418c707',
    minters_reward: 12151.22,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8127.14,
    hoaxs_reward: 0,
    total_rewards: 20278.36,
  },
  {
    address: '0xdc919cc69583000a168d6c493dd82d69b2cc95e2',
    minters_reward: 0,
    lp_providers_reward: 12414.72,
    swapers_reward: 7224.5975547468415,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 19910.22,
  },
  {
    address: '0x914b815e5bcba5a9b268c37e837281ea7fc61170',
    minters_reward: 0,
    lp_providers_reward: 17442.5,
    swapers_reward: 1543.9239215980301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18986.42,
  },
  {
    address: '0x74d440064a673aa4bd2d30a35b3e26eb9d271dd7',
    minters_reward: 0,
    lp_providers_reward: 16406.23,
    swapers_reward: 1004.9798657049026,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 18608.04,
  },
  {
    address: '0x596707dbbab6c15510530d1bec210b25a7edb664',
    minters_reward: 10936.09,
    lp_providers_reward: 0,
    swapers_reward: 147.19223673672326,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 18397.71,
  },
  {
    address: '0xf181844d76676d1fec22e22568ec33160b86e67a',
    minters_reward: 10936.09,
    lp_providers_reward: 0,
    swapers_reward: 87.48676392120647,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 18338.01,
  },
  {
    address: '0xac94e00848aaf38e64d2fe74d902c593ca02bbd4',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 15601.995900891174,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 18311.44,
  },
  {
    address: '0xf6e2adabc0f69a7f1161a60a48e52214f533b839',
    minters_reward: 0,
    lp_providers_reward: 3619.17,
    swapers_reward: 14059.73613673514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17678.91,
  },
  {
    address: '0xbff81c71193d824e849d45446cd71628987add95',
    minters_reward: 0,
    lp_providers_reward: 8254.39,
    swapers_reward: 9414.636566062392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17669.03,
  },
  {
    address: '0x10b1c5c58b055da354e948fc909dbb5353a014bb',
    minters_reward: 0,
    lp_providers_reward: 16428.33,
    swapers_reward: 1017.6071128267371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17445.94,
  },
  {
    address: '0x639214d233654f9c86edfc74d7ae38b0e05d0b3c',
    minters_reward: 0,
    lp_providers_reward: 17040.67,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 17311.57,
  },
  {
    address: '0x2E048257f28A7dfF64D71AE9Fa2E967A1c28CF8b',
    minters_reward: 10531.05,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 17303.67,
  },
  {
    address: '0x38d2cea869ade70fe6c3555f0b4bea77ae7008ed',
    minters_reward: 0,
    lp_providers_reward: 17174.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17174.32,
  },
  {
    address: '0x33d43dd551f5ea41ab95f37cdd019be2a7a8c403',
    minters_reward: 0,
    lp_providers_reward: 7656.69,
    swapers_reward: 3887.0251969723663,
    holders_reward: 0,
    hoaxs_reward: 5555.56,
    total_rewards: 17099.28,
  },
  {
    address: '0x025d8388f1d15c9c668a4fa456f9561169a32ec9',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 950.4202674569201,
    holders_reward: 5689,
    hoaxs_reward: 1851.85,
    total_rewards: 16997.12,
  },
  {
    address: '0x98f8e25087c2bbe7fd1be9ba0a28d69c09db81f9',
    minters_reward: 10936.09,
    lp_providers_reward: 199.53,
    swapers_reward: 351.6954003968036,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 16905.42,
  },
  {
    address: '0xAdfB2939bB0aE00EE444A53Cb61Bc54107c68E4c',
    minters_reward: 10126.01,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 16898.63,
  },
  {
    address: '0x423ab0e736794616bC391ae3AE60085c5f21AA24',
    minters_reward: 10126.01,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 16898.63,
  },
  {
    address: '0x4eeed052e258c30e5bc7be292f071e4c4f39a514',
    minters_reward: 0,
    lp_providers_reward: 11919,
    swapers_reward: 1869.3856863606027,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 16768.34,
  },
  {
    address: '0xc61505d452f0b1b2b74f30ee8f7275e90140284d',
    minters_reward: 405.04,
    lp_providers_reward: 13922.6,
    swapers_reward: 2127.253977697697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16454.89,
  },
  {
    address: '0x831c8db621027e92b0a968861ab9e87f781365f0',
    minters_reward: 0,
    lp_providers_reward: 11171.31,
    swapers_reward: 5096.536495794387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16267.85,
  },
  {
    address: '0xD5a30E086B78d9e9414a3aeB9f6F401218787A86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 16254.29,
    hoaxs_reward: 0,
    total_rewards: 16254.29,
  },
  {
    address: '0xbd031aba22fd2310a63aaae03c704df985a0da64',
    minters_reward: 0,
    lp_providers_reward: 8017.5,
    swapers_reward: 7943.831750856462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15961.33,
  },
  {
    address: '0xc6f75fa54b00690af372330ff90f490a41bcec22',
    minters_reward: 0,
    lp_providers_reward: 9581.56,
    swapers_reward: 6376.600853336685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15958.16,
  },
  {
    address: '0x1e0e5134bcb606032f58ec0924873f8c321f6ecb',
    minters_reward: 0,
    lp_providers_reward: 15128.88,
    swapers_reward: 652.2088020781603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15781.09,
  },
  {
    address: '0xfd6d049d010582d9e6b41b9648cd4f8277581e38',
    minters_reward: 0,
    lp_providers_reward: 15736.47,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15736.47,
  },
  {
    address: '0xF2C6C9Aa52095e52f769BcD589BF2432Ce8dD1b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 15712.48,
    hoaxs_reward: 0,
    total_rewards: 15712.48,
  },
  {
    address: '0x83d7b30aE520B46374a78b3e2A408feb37F032B6',
    minters_reward: 9315.93,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6230.81,
    hoaxs_reward: 0,
    total_rewards: 15546.74,
  },
  {
    address: '0xdd95dfd64f0814654a1e901f80e98b6cda0701e9',
    minters_reward: 0,
    lp_providers_reward: 15396.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15396.15,
  },
  {
    address: '0x4336bc4884b1c1e3cb6f7e07b1a1dc5fb1c208af',
    minters_reward: 0,
    lp_providers_reward: 14881.79,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14881.79,
  },
  {
    address: '0x22d36a792eb4cbf68862e27ee7b066922213732e',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0.7380604161645512,
    holders_reward: 5147.19,
    hoaxs_reward: 925.93,
    total_rewards: 14579.71,
  },
  {
    address: '0xd6243b13ad17000b9c041dbbd15c5db838bd78f6',
    minters_reward: 0,
    lp_providers_reward: 8428.52,
    swapers_reward: 5860.241977164898,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 14559.66,
  },
  {
    address: '0x1f69ce9f2850025009ae506f72b2512ff8e8c545',
    minters_reward: 0,
    lp_providers_reward: 14507.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14507.92,
  },
  {
    address: '0x0aaf93a46845c7afd2377d40fae73f3c44b8fc7d',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 306.3984579254117,
    holders_reward: 5689,
    hoaxs_reward: 0,
    total_rewards: 14501.25,
  },
  {
    address: '0x1b29ee80ba7316f65d496ccbdf66245a8fef363e',
    minters_reward: 4050.41,
    lp_providers_reward: 1587.83,
    swapers_reward: 1062.8781510676865,
    holders_reward: 4876.29,
    hoaxs_reward: 2777.78,
    total_rewards: 14355.19,
  },
  {
    address: '0x85286c35061499180c3507b9e6ce78f45a9978d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14343.156318812005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14343.16,
  },
  {
    address: '0xcbc1070e400c832e62b6d7776cad08ae2c64d305',
    minters_reward: 0,
    lp_providers_reward: 9644.71,
    swapers_reward: 4075.555312070295,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 14262.08,
  },
  {
    address: '0x61284f29ff05886991b994385e53cdd05d70088f',
    minters_reward: 0,
    lp_providers_reward: 6988.15,
    swapers_reward: 7213.410752682958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14201.56,
  },
  {
    address: '0x03a5087497580671b37e9fC97796DdFE609fD593',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5689,
    hoaxs_reward: 0,
    total_rewards: 14194.85,
  },
  {
    address: '0xd4b55c039213729a642a4aecc3689b0a9ccd92b8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 3881.87169173904,
    holders_reward: 541.81,
    hoaxs_reward: 9259.26,
    total_rewards: 14087.98,
  },
  {
    address: '0xa510f25fcd0dcc7ec212c5d0248690c7bbe65f5c',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 59.685295385654314,
    holders_reward: 5689,
    hoaxs_reward: 925.93,
    total_rewards: 13965.35,
  },
  {
    address: '0x49c1250a747505fba4dc16caefc38bd4a0bf64ff',
    minters_reward: 0,
    lp_providers_reward: 13713.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13713.62,
  },
  {
    address: '0x29f654af010a4f20c7dcce94779a0e8fe2e30cc9',
    minters_reward: 0,
    lp_providers_reward: 5413.95,
    swapers_reward: 8247.374308225078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13661.32,
  },
  {
    address: '0xDB878025203BDCDD4E47c1a05Cf73a22654Eb3E0',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0xA3F078c0B6EEc775F5D3b1b7c426a3d4616080B2',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0xc5DF2ddA3b4cA6FD7b1044D85B0E91A12cf266Ee',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x38f6Dcbc8bfBE4CD185527f2Ab767fF8fED44Ad9',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x00911f70415ad0a17f398Aa65398f6a51B2581f7',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0xbB6407FE044bc874c6ABE484054891D7F23E7DA8',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x35EfCe5f4bd52356e8215BCB9dC7687aDe6B6400',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 13518.91,
  },
  {
    address: '0x7fd87c027652cc1eb1f9fccb333264a89c2bec42',
    minters_reward: 0,
    lp_providers_reward: 13283.86,
    swapers_reward: 112.76282442192819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13396.62,
  },
  {
    address: '0x81e99c200b79b2ec05f9f6d7b5c52aab1864aa2f',
    minters_reward: 4860.49,
    lp_providers_reward: 4470.23,
    swapers_reward: 3283.778990882226,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 13156.31,
  },
  {
    address: '0x43bc7667bf508ce3905dc0f492f106eae2800536',
    minters_reward: 810.08,
    lp_providers_reward: 11783.95,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 13135.84,
  },
  {
    address: '0x8c5cc6adb769ab499b545c70fff50b1697972d18',
    minters_reward: 0,
    lp_providers_reward: 11655.43,
    swapers_reward: 111.85998940499792,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 13121.81,
  },
  {
    address: '0x64951C98e0eBa153E78eD6Be7c95f55304B13eb9',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 13111.23,
  },
  {
    address: '0xa67D792b3861Ff23b1196dFB3BF4FD2A4152f128',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 12962.96,
    total_rewards: 12962.96,
  },
  {
    address: '0xe2e0f5dC2E3317ecfdd43deF0f3E740b6A77c16C',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 8398.05,
    hoaxs_reward: 0,
    total_rewards: 12853.5,
  },
  {
    address: '0x5f8A0e75354c032510289bed3ed4a6ADC1D4BA6B',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 12842.96,
  },
  {
    address: '0x5164463772b6Ab5A984FC7A35c5A4E76977A594D',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 12842.96,
  },
  {
    address: '0xfcd01a3332492f7c3cc076f96493281dc0b5376c',
    minters_reward: 0,
    lp_providers_reward: 9388.82,
    swapers_reward: 553.139741954607,
    holders_reward: 1896.33,
    hoaxs_reward: 925.93,
    total_rewards: 12764.22,
  },
  {
    address: '0x434327fcd2210a5996b1d95dfbd62cd61ce2fbb9',
    minters_reward: 0,
    lp_providers_reward: 5019.07,
    swapers_reward: 7598.545513469397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12617.62,
  },
  {
    address: '0xa2ca08e5b1d3cf033d4db98e26adffc7ed8df862',
    minters_reward: 0,
    lp_providers_reward: 9356.2,
    swapers_reward: 3244.255328688845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12600.46,
  },
  {
    address: '0xF0721ffdFF9818C6dcC5E1bC9F3B2a63c3AA65D9',
    minters_reward: 12556.26,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12556.26,
  },
  {
    address: '0x05A05073ef3F2AeBCA8e6630341fB1b60cA5E538',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 12437.92,
  },
  {
    address: '0x60e39B27f5ebf4F85627857222024ff6cB534E01',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 12301.15,
  },
  {
    address: '0xF363CcF2F2bF10F197E27409fDAFa20a615CB0ee',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3792.67,
    hoaxs_reward: 2777.78,
    total_rewards: 12241.02,
  },
  {
    address: '0x3dB55A59cccb7d8126698F8c447FE39a5f4A3DCD',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4876.29,
    hoaxs_reward: 0,
    total_rewards: 12167.02,
  },
  {
    address: '0xed86b19bc819974f044bd18fc87ecfcd0944badf',
    minters_reward: 0,
    lp_providers_reward: 11206.58,
    swapers_reward: 73.37716859144577,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 12092.67,
  },
  {
    address: '0x11fe1a4fc7f140850ff72881a53eea24a3d02911',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 4839.227922902518,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 12003.73,
  },
  {
    address: '0xba3620B8a00E925154B45591577Fe52fbCc514F5',
    minters_reward: 11746.17,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11746.17,
  },
  {
    address: '0xe3d0c0cb8b464100faee514f88e127ff55730e56',
    minters_reward: 0,
    lp_providers_reward: 11705.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11705.62,
  },
  {
    address: '0xd9fc64e32abe6dbad449bf242bf9e790782bac93',
    minters_reward: 6885.69,
    lp_providers_reward: 0,
    swapers_reward: 290.3123507289939,
    holders_reward: 4334.48,
    hoaxs_reward: 0,
    total_rewards: 11510.48,
  },
  {
    address: '0x606d2C436A07be40C276a6176bb1376C34e49Ee9',
    minters_reward: 6885.69,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 11491.07,
  },
  {
    address: '0xc8cc1796513d88193191d3585801e88743fec35f',
    minters_reward: 0,
    lp_providers_reward: 11359.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11359.35,
  },
  {
    address: '0xc1b3a919cd84eb29717a641a86926c3184b0a1af',
    minters_reward: 0,
    lp_providers_reward: 11346.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11346.07,
  },
  {
    address: '0x6487839dc4f4bea26a3cd187d99ea31ccaff451f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11245.57925795928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11245.58,
  },
  {
    address: '0xeabfcd03392ac20a1622a1f79b95c847b269f64f',
    minters_reward: 0,
    lp_providers_reward: 11207.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11207.52,
  },
  {
    address: '0x61E396E8856e22f348763F80f23F67EDe50b8Cc9',
    minters_reward: 6480.65,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 11086.03,
  },
  {
    address: '0xeC097c2296e217f38FaC23CfBE2b12aD2D96379C',
    minters_reward: 7290.73,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 11083.4,
  },
  {
    address: '0xb890f3b9378f6f7cb428f5231ce6a2905cc3f978',
    minters_reward: 0,
    lp_providers_reward: 10993.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10993.84,
  },
  {
    address: '0xfb3f66efb26fe232bf5c9ab5ec29d21a958fab1e',
    minters_reward: 2025.2,
    lp_providers_reward: 3880.43,
    swapers_reward: 3712.932331097964,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 10973.08,
  },
  {
    address: '0xa0f510582ca57e61c907853f7e8145f75f41be56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 750.9900388701267,
    holders_reward: 0,
    hoaxs_reward: 10185.19,
    total_rewards: 10936.18,
  },
  {
    address: '0x3adc5d795ab9b2f7e06814dbcb1cf838a27ea82c',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 615.2792637531489,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 10893.87,
  },
  {
    address: '0xFfc86F4940E60bb5f20984Fc6C88021D2cf7f1F2',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 2777.78,
    total_rewards: 10889.13,
  },
  {
    address: '0x3d3993fc70f1e64522861594875b1b3dc9baa6b6',
    minters_reward: 0,
    lp_providers_reward: 9576.15,
    swapers_reward: 1302.9297732404793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10879.08,
  },
  {
    address: '0x37ce07c03af4ce3635d5fd0a8c302d283a25504d',
    minters_reward: 0,
    lp_providers_reward: 10191.71,
    swapers_reward: 652.4349209024911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10844.14,
  },
  {
    address: '0x5b080612ed975700cadc86174775f739cb213864',
    minters_reward: 0,
    lp_providers_reward: 10827.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10827.66,
  },
  {
    address: '0x7acf64ffd848e0b18f25e9d0f9f712f7099433cb',
    minters_reward: 4860.49,
    lp_providers_reward: 850.08,
    swapers_reward: 924.265468098033,
    holders_reward: 3250.86,
    hoaxs_reward: 925.93,
    total_rewards: 10811.63,
  },
  {
    address: '0x385fa52944e8041438a75949e07a780351f4a56d',
    minters_reward: 2430.24,
    lp_providers_reward: 594.61,
    swapers_reward: 140.98444478295983,
    holders_reward: 7585.34,
    hoaxs_reward: 0,
    total_rewards: 10751.17,
  },
  {
    address: '0x91e63c37157e71b7450c52247d35139e238710fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10724.258796690976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10724.26,
  },
  {
    address: '0x59cb670ad42314dcba7059a3727adc6719bc198d',
    minters_reward: 2835.28,
    lp_providers_reward: 3602.12,
    swapers_reward: 2335.680790441483,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 10669.41,
  },
  {
    address: '0xa648a010f9033dbbcb37defd550be6d3036c5aa8',
    minters_reward: 810.08,
    lp_providers_reward: 9209.24,
    swapers_reward: 6.657615726935605,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 10567.79,
  },
  {
    address: '0x163ad4ed1ae9cbbc58f5a964d0cd57effd74b888',
    minters_reward: 7695.77,
    lp_providers_reward: 0,
    swapers_reward: 68.15158335216643,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 10472.97,
  },
  {
    address: '0x7f8b71606a01ffbe3aa8d8b64dde5ce4e38d2ccf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10383.213323823937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10383.21,
  },
  {
    address: '0x0b71bd3191e57700d14e7b696e3aa0a0ab2a4979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10341.750381891397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10341.75,
  },
  {
    address: '0xc82c9dd2510c7df55fd777561e15056bdebe4b5b',
    minters_reward: 0,
    lp_providers_reward: 9411.59,
    swapers_reward: 924.0206892134732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10335.61,
  },
  {
    address: '0x399d1ff383992f4748c97add0d697f216a6a3348',
    minters_reward: 0,
    lp_providers_reward: 3431.32,
    swapers_reward: 6863.902532056619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10295.22,
  },
  {
    address: '0xB996203ca2940ED236B5C1D1AD5dF6EAb1D659AA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 10294.38,
    hoaxs_reward: 0,
    total_rewards: 10294.38,
  },
  {
    address: '0x45a6a49dce7032bdf9bf08f287c466c820f731d1',
    minters_reward: 2025.2,
    lp_providers_reward: 3617.98,
    swapers_reward: 1105.2637742020943,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 10270.2,
  },
  {
    address: '0xbcc0e6580b2c04ea45757a964ecb9ffcdbe28b99',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 28.571561500565622,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10167.75,
  },
  {
    address: '0xbb2361C9aAB674b7a8B019105F46E8200fDD4112',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0x8ec0159B686197FA06c1653379BCc80Cde1F6A51',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0x0450591FF9929dF0383E9d01D06f7Ee7d5ABA3b7',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0xbBa637AB80AAEce8Eb97cC1C680E873aaaeAE1BE',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 10139.18,
  },
  {
    address: '0xef6aed099865ad66e63706da6f8cb83e00841baa',
    minters_reward: 0,
    lp_providers_reward: 5134.75,
    swapers_reward: 4879.954982507545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10014.7,
  },
  {
    address: '0xc1db89b97b45953c0ef51295ab6826076d347dce',
    minters_reward: 3240.32,
    lp_providers_reward: 2715.36,
    swapers_reward: 2035.4369189875654,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 9887.45,
  },
  {
    address: '0x49fc4e231c9c3c4b5e49fd9adb6fa3e9cace7586',
    minters_reward: 0,
    lp_providers_reward: 7169.2,
    swapers_reward: 2648.836424786472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9818.04,
  },
  {
    address: '0x93695a34c1535ab8abcbbe6c65fa00c6ddb9beb5',
    minters_reward: 2835.28,
    lp_providers_reward: 2354.45,
    swapers_reward: 807.1797635081446,
    holders_reward: 1896.33,
    hoaxs_reward: 1851.85,
    total_rewards: 9745.09,
  },
  {
    address: '0xf5df1e44433557420fb4fe1c077ac92442dc0787',
    minters_reward: 4455.45,
    lp_providers_reward: 1061.73,
    swapers_reward: 1231.4593791309885,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 9728.59,
  },
  {
    address: '0xef750a119b3db807b93bb422fdb798dc0785ef8c',
    minters_reward: 2430.24,
    lp_providers_reward: 3658.69,
    swapers_reward: 2002.5553744163547,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 9716.92,
  },
  {
    address: '0x0ce61dc009669c4691491263dca7c30209924ed3',
    minters_reward: 5670.57,
    lp_providers_reward: 190.93,
    swapers_reward: 31.271879031042843,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 9685.44,
  },
  {
    address: '0xf760fee18f76e167ace7c177f390d5dd4051cc12',
    minters_reward: 5265.53,
    lp_providers_reward: 579.15,
    swapers_reward: 310.5279776743151,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 9676.97,
  },
  {
    address: '0xb083379747fce720cdcda680ce34105990ffdbd9',
    minters_reward: 4455.45,
    lp_providers_reward: 1394.61,
    swapers_reward: 796.2604988578021,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 9626.27,
  },
  {
    address: '0x47033eb2f11ed928a92e1d105d0f86a76635e2bd',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 2763.7793582447257,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 9523.24,
  },
  {
    address: '0xe6822efb072201005f10eebc666173dfdee0f278',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0.1541407065182402,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 9463.39,
  },
  {
    address: '0x28107811da51900990d1d4f16832736c5e32f950',
    minters_reward: 3240.32,
    lp_providers_reward: 28.17,
    swapers_reward: 1884.997415381552,
    holders_reward: 2438.14,
    hoaxs_reward: 1851.85,
    total_rewards: 9443.48,
  },
  {
    address: '0x97a290a02eb3a7f8b39dac8f251f4718fd6e7f00',
    minters_reward: 0,
    lp_providers_reward: 9419.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9419.49,
  },
  {
    address: '0x3bac8b648a3b7f4851f8dcc5c95fa7c105f198d0',
    minters_reward: 0,
    lp_providers_reward: 9237.93,
    swapers_reward: 152.92280032877926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9390.85,
  },
  {
    address: '0xb39994c92910832c5a2df2bf2aeaab400d017338',
    minters_reward: 2430.24,
    lp_providers_reward: 3912.38,
    swapers_reward: 1052.8725489668866,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 9291.82,
  },
  {
    address: '0x32c36164ecada33179fc0eed80de0b70784ca478',
    minters_reward: 0,
    lp_providers_reward: 4485.21,
    swapers_reward: 1955.9894768345523,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 9218.98,
  },
  {
    address: '0xc537295d0A7bBCb3484719f7d9Eee775d80b1976',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 9192.33,
  },
  {
    address: '0x3ece9b9e81573abff3138b1995d5650ac8f4cd5b',
    minters_reward: 0,
    lp_providers_reward: 4839.99,
    swapers_reward: 4267.064380469421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9107.05,
  },
  {
    address: '0xc327b471064692067c5e747a05d0717dcbb169ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.39060422086888,
    holders_reward: 541.81,
    hoaxs_reward: 8333.33,
    total_rewards: 9021.53,
  },
  {
    address: '0x6a7fbf07a0aca2f7b46fd6cea0cb52a39830d831',
    minters_reward: 0,
    lp_providers_reward: 9016.44,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9016.44,
  },
  {
    address: '0x5a0950581aa641ea0c4b50bf5a465a99f35f1679',
    minters_reward: 0,
    lp_providers_reward: 8895.38,
    swapers_reward: 41.90647526861081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8937.29,
  },
  {
    address: '0xa5294016f13fb0015c1d22bd521eb65a123d34dc',
    minters_reward: 0,
    lp_providers_reward: 8851.17,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8851.17,
  },
  {
    address: '0x2d7640eada432b2459517c29969454543305b7b9',
    minters_reward: 0,
    lp_providers_reward: 19.58,
    swapers_reward: 3946.334399757094,
    holders_reward: 2979.95,
    hoaxs_reward: 1851.85,
    total_rewards: 8797.71,
  },
  {
    address: '0x108A36c39a0993E70E08b04639f6246160A45cc7',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 8787.29,
  },
  {
    address: '0xa12740090585af67808821991c4434caaf6d6f3e',
    minters_reward: 0,
    lp_providers_reward: 8780.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8780.81,
  },
  {
    address: '0x7db40142a78d6b3c3c4dc1888e87c7cbf63dd375',
    minters_reward: 0,
    lp_providers_reward: 7135.87,
    swapers_reward: 1629.7266839241747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8765.6,
  },
  {
    address: '0x842a95e35dde023764195f6555bf295af4f79c89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8738.45720129089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8738.46,
  },
  {
    address: '0xc910e715fb40e291ad817ad2c40bfc87cca0ab6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8623.898278687053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8623.9,
  },
  {
    address: '0x5157b58e80a7651af3125e3355cf8143efce186b',
    minters_reward: 0,
    lp_providers_reward: 1217.17,
    swapers_reward: 3090.152437357,
    holders_reward: 541.81,
    hoaxs_reward: 3703.7,
    total_rewards: 8552.83,
  },
  {
    address: '0xfbd9a4040e100dcdb6c2b7c45085b8a7045f5e9b',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 259.3383885434389,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 8507.46,
  },
  {
    address: '0xba8C789d80eCA2d2DAbE1C6934dfb487D7ab2595',
    minters_reward: 8505.85,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8505.85,
  },
  {
    address: '0x471601f3071ce057b0ddd539dc0e0c78450e73f0',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 115.05874016521237,
    holders_reward: 2979.95,
    hoaxs_reward: 925.93,
    total_rewards: 8476.39,
  },
  {
    address: '0xb7039fbd301cfaa7c66ba43fc10e2c60aa18f785',
    minters_reward: 0,
    lp_providers_reward: 801.48,
    swapers_reward: 7618.036391876424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8419.52,
  },
  {
    address: '0xaa3548d23D7F230E9fFe99362Db4E26d684c4346',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 8382.25,
  },
  {
    address: '0x28f4b1bb56842e5ad4ea37da69a9d5a68c570a70',
    minters_reward: 0,
    lp_providers_reward: 8314.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8314.46,
  },
  {
    address: '0x5ef588e38f08c9457f8ee2ed6b1a1d0c904037ff',
    minters_reward: 0,
    lp_providers_reward: 8009.91,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 8280.81,
  },
  {
    address: '0x366a96930D05C72F2AAfb0Ea3162d95225575607',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 0,
    total_rewards: 8111.35,
  },
  {
    address: '0xd724bE86224BcBb0400f844CCf21e5C3869bcEb4',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 0,
    total_rewards: 8111.35,
  },
  {
    address: '0xD840aA64F5B3Ac2F40A63987DEce10D2a4bBa679',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3250.86,
    hoaxs_reward: 0,
    total_rewards: 8111.35,
  },
  {
    address: '0x5BffE7AeBD0f08CA05a5E8103C84931aF9acCc52',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8100.81,
  },
  {
    address: '0xf47750d2B1491fE0566d9E07a9A6e6c3639b10c1',
    minters_reward: 8100.81,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8100.81,
  },
  {
    address: '0x0855f08e143e1db081cc3c571f40787dcfb69b9d',
    minters_reward: 0,
    lp_providers_reward: 8083.6,
    swapers_reward: 3.3737655926582986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8086.97,
  },
  {
    address: '0xc6eeabab3b6058e562639916efca10b4f2be0616',
    minters_reward: 0,
    lp_providers_reward: 7593.78,
    swapers_reward: 445.5832689749827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8039.36,
  },
  {
    address: '0xba1BF6D162b0006363E4381e776025515a5915d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4334.48,
    hoaxs_reward: 3703.7,
    total_rewards: 8038.18,
  },
  {
    address: '0x0683727de070564029387b8bbd9790b9803a596a',
    minters_reward: 0,
    lp_providers_reward: 7046.67,
    swapers_reward: 967.2989982344819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8013.97,
  },
  {
    address: '0x7493aef34f3b3b3385e71b6df7cf63e0388cae02',
    minters_reward: 0,
    lp_providers_reward: 7916.52,
    swapers_reward: 86.97705237030468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8003.5,
  },
  {
    address: '0x353c6b0d9d6c7bb8c8b1979d284dff23f71909cd',
    minters_reward: 1215.12,
    lp_providers_reward: 3645.93,
    swapers_reward: 2320.7813086210285,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 7994.54,
  },
  {
    address: '0x3d630f40de36d06603ad329cc1ba7da86fae7f3b',
    minters_reward: 0,
    lp_providers_reward: 7930.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7930.59,
  },
  {
    address: '0xfa11bc97fa3ec3c4654ae75259e3e1c0a2a72dba',
    minters_reward: 0,
    lp_providers_reward: 6971.13,
    swapers_reward: 836.2611052373882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7807.39,
  },
  {
    address: '0xb5410e3f3569be268ca4f12b325e61c9d78ffaa5',
    minters_reward: 0,
    lp_providers_reward: 7794.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7794.58,
  },
  {
    address: '0xeb2b21ff36bf9e836cb6ee44fc4d052e5ef3377a',
    minters_reward: 0,
    lp_providers_reward: 3083.11,
    swapers_reward: 2534.712771660308,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 7785.06,
  },
  {
    address: '0x10a6a0af5dddd44b742d344e736999112f413c73',
    minters_reward: 0,
    lp_providers_reward: 5392.57,
    swapers_reward: 2363.4653682073044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7756.04,
  },
  {
    address: '0x4b81ee4f2e85b847d01aaa31a41b88e906e2d854',
    minters_reward: 2025.2,
    lp_providers_reward: 2429.99,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 7661.56,
  },
  {
    address: '0x7ba70e4db89fc75f18b1fd6a7b86fdb10a2f7e38',
    minters_reward: 0,
    lp_providers_reward: 7640.23,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7640.23,
  },
  {
    address: '0x96fb02339316f1c9f109f3305828f8611b6a193a',
    minters_reward: 0,
    lp_providers_reward: 7208.98,
    swapers_reward: 353.727129490583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7562.71,
  },
  {
    address: '0x560835d8899c1bbf71a2340f234c708792a1b205',
    minters_reward: 0,
    lp_providers_reward: 7552.02,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7552.02,
  },
  {
    address: '0x3c0f8cf0fa024330e96cb473ecd6941b49c93b07',
    minters_reward: 0,
    lp_providers_reward: 4383.76,
    swapers_reward: 1513.1872877370904,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 7522.38,
  },
  {
    address: '0x182c828895CC3BCb6455DBC3Ccb606019dE32dd4',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 7435.4,
  },
  {
    address: '0x078f9867982c1ce4b6d01abb6152f6f10f0196e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.253085914091535,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 7377.68,
  },
  {
    address: '0x9D0AA44aCCb5573CA15f964fC8a7A24624B31202',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 7314.43,
    hoaxs_reward: 0,
    total_rewards: 7314.43,
  },
  {
    address: '0x472a6459f23a7c298612fcfdb4cf0431efa14b0f',
    minters_reward: 810.08,
    lp_providers_reward: 3592.51,
    swapers_reward: 2354.0053979286254,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 7298.41,
  },
  {
    address: '0x89a48aeab1c1975860660f2d1521da54bd01e215',
    minters_reward: 0,
    lp_providers_reward: 1909.32,
    swapers_reward: 5332.250592277112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7241.57,
  },
  {
    address: '0x8eed32aed28d6169243235d7a207e3c35cd4ed93',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 721.6199630178704,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 7210.17,
  },
  {
    address: '0xd39023aeec560cc50707ce4c193957006c93699d',
    minters_reward: 0,
    lp_providers_reward: 7037.75,
    swapers_reward: 147.61430613876314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7185.36,
  },
  {
    address: '0x0fef8e435a72f79d5e0316a7028137f9407002f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.00076859333632,
    holders_reward: 7043.53,
    hoaxs_reward: 0,
    total_rewards: 7119.53,
  },
  {
    address: '0xc133d9bc08fe8cc22ccb973d659a67de66ea252a',
    minters_reward: 4050.41,
    lp_providers_reward: 215.74,
    swapers_reward: 92.02746382608206,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 7067.23,
  },
  {
    address: '0x25043a0bed7386d1da7571de3bf5b283e4a7ed9d',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 274.5817954213248,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 7034.04,
  },
  {
    address: '0x5d649d87f709972db586f4e1928a59cf57e74b22',
    minters_reward: 0,
    lp_providers_reward: 7033.08,
    swapers_reward: 0.034226257239643794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7033.11,
  },
  {
    address: '0xc02a104F9cF3a1143c06Eab296e9d224b457E170',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 7025.09,
  },
  {
    address: '0x705c053d69eb3b8acc7c404690bd297700ccf169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6985.216046381334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6985.22,
  },
  {
    address: '0x951fc0065ae7f28e1bc867de7e68e6f9109f8380',
    minters_reward: 0,
    lp_providers_reward: 2294.87,
    swapers_reward: 1469.5125379442886,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 6970.75,
  },
  {
    address: '0x2085d3fe77e135bbf852e58de48413a26cc62e00',
    minters_reward: 0,
    lp_providers_reward: 3667.78,
    swapers_reward: 3257.96619671355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6925.75,
  },
  {
    address: '0xfb14a2a025a74125d807338b96f075678058a216',
    minters_reward: 4050.41,
    lp_providers_reward: 55.92,
    swapers_reward: 18.918877488265096,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6834.3,
  },
  {
    address: '0x2b7f8ede13c845a3cbc61afa6823b93de333ddae',
    minters_reward: 0,
    lp_providers_reward: 6789.43,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6789.43,
  },
  {
    address: '0x7b2069a910e033937b69fa00abfb83b4d2998b45',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 14.04165448080299,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6773.5,
  },
  {
    address: '0xb93A768f5F2C8BE0e7D4528b7bd4734a5703834a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 6772.62,
    hoaxs_reward: 0,
    total_rewards: 6772.62,
  },
  {
    address: '0xad2292ae5Db2821eA9B6e0564FF074041579Ec08',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x2A433035ae7eC396C48BEF726AE62F959c9151A4',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x68674FAF97b37c8c13eA3557CD931FcFaDf9d13b',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x72B7bbbB816787E857FE6c73386098B7Ac408b70',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x4B87D3237610F7bF52c836C479D83008dcc65342',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x34D111d921750A2785d64697838bDbc91045f528',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x6149e004211852a0300a44A1Dd07c442E7A53BB2',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6759.46,
  },
  {
    address: '0x436DFa7D4aeb9e25D7CcCA4486Cc052328B8a4A6',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 6756.82,
  },
  {
    address: '0x39fea4ccf4adc93cbaa7b626606b9b2043bc813b',
    minters_reward: 0,
    lp_providers_reward: 2011.68,
    swapers_reward: 4658.455532701536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6670.14,
  },
  {
    address: '0xf07da2f0d5ddb7dae824ebe830c3f742117e6387',
    minters_reward: 0,
    lp_providers_reward: 3163.03,
    swapers_reward: 3485.1592791011717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6648.19,
  },
  {
    address: '0x4fd102ad7aa70600719b11fc122a3085b128f5b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1085.9511372797685,
    holders_reward: 0,
    hoaxs_reward: 5555.56,
    total_rewards: 6641.51,
  },
  {
    address: '0xbc104e3e61b2afe7fca264e92031ac23910aa913',
    minters_reward: 0,
    lp_providers_reward: 6303.47,
    swapers_reward: 289.38867329764673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6592.86,
  },
  {
    address: '0xaef969eb47df8689850f826d2a3e0f6cd87a2c38',
    minters_reward: 0,
    lp_providers_reward: 4255.8,
    swapers_reward: 2280.0633898290052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6535.86,
  },
  {
    address: '0x486058f0d5c838454a7b5905c3b1ece98b76972c',
    minters_reward: 2025.2,
    lp_providers_reward: 1014.55,
    swapers_reward: 1622.5941937695816,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 6514.19,
  },
  {
    address: '0x8623f2a1d1f179878c6de05d011d522b2d05d9ef',
    minters_reward: 6480.65,
    lp_providers_reward: 0,
    swapers_reward: 31.27993238246097,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6511.93,
  },
  {
    address: '0xD720F3a80c92f16C7A94545162a96cC674C51D4d',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 6488.55,
  },
  {
    address: '0x2Ea0286b54EF0d79bEAAf12AAd574F7B101029b1',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 6488.55,
  },
  {
    address: '0x5662045ae117a02b48cb0b9e645b7e8d9f615ffc',
    minters_reward: 0,
    lp_providers_reward: 4503.79,
    swapers_reward: 1969.783331458504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6473.57,
  },
  {
    address: '0x0971ab754625ff40457f77deceb55cae915d78b1',
    minters_reward: 0,
    lp_providers_reward: 3796,
    swapers_reward: 2596.384440478955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6392.38,
  },
  {
    address: '0x3f1dd1c270f6e08d825696e652bb4ac48f304c4e',
    minters_reward: 3645.36,
    lp_providers_reward: 0,
    swapers_reward: 2.560331364653293,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6356.97,
  },
  {
    address: '0x4b69ff56b0601c7d898cdb2f2d2883d1077fdf99',
    minters_reward: 0,
    lp_providers_reward: 3428.31,
    swapers_reward: 2926.1344062537064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6354.44,
  },
  {
    address: '0xc78b20e3b6f2e106ca5b112b96b38ad2a49b76fc',
    minters_reward: 3645.36,
    lp_providers_reward: 558.79,
    swapers_reward: 486.32883542938305,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 6315.91,
  },
  {
    address: '0xe8cd45e550f5fdec5694f6d12ac02ed68dad609b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6300.69112413138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6300.69,
  },
  {
    address: '0x48ce3b9c869fc5cb0c6cd7fec5a1bbb629359e08',
    minters_reward: 1620.16,
    lp_providers_reward: 1496.05,
    swapers_reward: 494.51582845791995,
    holders_reward: 812.71,
    hoaxs_reward: 1851.85,
    total_rewards: 6275.29,
  },
  {
    address: '0x1bd717ccb1946e07d5800d3cc01602c5927ed5df',
    minters_reward: 0,
    lp_providers_reward: 5102.16,
    swapers_reward: 343.2621341080972,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 6258.13,
  },
  {
    address: '0x233e5064d4e94304e3ba89fbd486555e601fab5b',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 296.26777834797934,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 6245.64,
  },
  {
    address: '0x08db5102166abbae0cdd1e1e917df0eee81a86b2',
    minters_reward: 3240.32,
    lp_providers_reward: 2688.82,
    swapers_reward: 0.9422625468181935,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 6200.98,
  },
  {
    address: '0x42536063689537a0942bcb814529dcfb5953541c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5085.692797251546,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 6169.31,
  },
  {
    address: '0x3b69ec2221ede0ea01d01b7a346f5a02f8cc6518',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6161.171737661352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6161.17,
  },
  {
    address: '0x63f67a2e42352c693e8d7c88dff0ad6c58e29bb1',
    minters_reward: 2835.28,
    lp_providers_reward: 383.69,
    swapers_reward: 1015.641234438112,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 6130.94,
  },
  {
    address: '0xa2DB216821ba2A33C0f15CB6f8aDFAb13Df41036',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 5555.56,
    total_rewards: 6097.37,
  },
  {
    address: '0xcd180eca01740098321951e9698a43520c3b6fab',
    minters_reward: 0,
    lp_providers_reward: 5682.56,
    swapers_reward: 130.66871056421596,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 6084.13,
  },
  {
    address: '0x2D0d7b9Bb3a0B998FE24ee1eCC23C068c7c8e8F1',
    minters_reward: 3645.36,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 6083.5,
  },
  {
    address: '0x788157B1397D6334618593890a911277f62541Eb',
    minters_reward: 6075.61,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6075.61,
  },
  {
    address: '0xada4389661b5aa5756636ca7d3c051c3d04edabb',
    minters_reward: 0,
    lp_providers_reward: 2370.23,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 3703.7,
    total_rewards: 6073.93,
  },
  {
    address: '0x0850a184714490b7ef2204c83e69532080f4845f',
    minters_reward: 0,
    lp_providers_reward: 1961.92,
    swapers_reward: 4002.137947048465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5964.06,
  },
  {
    address: '0x00e84c0dcc2eb8be3b36deea3ec2b569e482ee3a',
    minters_reward: 810.08,
    lp_providers_reward: 2695.67,
    swapers_reward: 1098.9359953942555,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 5959.21,
  },
  {
    address: '0x4ab5899d8e9a87e457e6db1393e96588bd12d689',
    minters_reward: 0,
    lp_providers_reward: 5951.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5951.11,
  },
  {
    address: '0xE6EA3F27e7e9Dad9eF1D7dee64E82bd414e8B5aD',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 5946.74,
  },
  {
    address: '0xca585e09df67e83106c9bcd839c989ace537bf95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5919.575398094543,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5919.58,
  },
  {
    address: '0xa328813626a965d4b606692bf86b4a83760436d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 702.5908950777601,
    holders_reward: 5147.19,
    hoaxs_reward: 0,
    total_rewards: 5849.78,
  },
  {
    address: '0x0a1263f2c94c61af166f58258b31eb18f8b86a15',
    minters_reward: 5670.57,
    lp_providers_reward: 37.21,
    swapers_reward: 123.64825248409859,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5831.43,
  },
  {
    address: '0xe88a6d6632d5ea8a219fc2c802290221177b6664',
    minters_reward: 2430.24,
    lp_providers_reward: 283.98,
    swapers_reward: 1491.420327620511,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 5831.07,
  },
  {
    address: '0xfe95b7a015af9dd500e9107104f33c94529032ff',
    minters_reward: 2025.2,
    lp_providers_reward: 652.23,
    swapers_reward: 1459.281544073726,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 5762.14,
  },
  {
    address: '0x51b77a2def34100af465bd1f3c658a96a92b6a3e',
    minters_reward: 0,
    lp_providers_reward: 5326.26,
    swapers_reward: 433.11167091795414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5759.37,
  },
  {
    address: '0xc190f054b6ea68592f8673a70dec932271cfb728',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 501.1299864014008,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 5732.7,
  },
  {
    address: '0x3458d5cbac485bdda4b7439a18152195e93d134f',
    minters_reward: 0,
    lp_providers_reward: 5459.75,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 5730.65,
  },
  {
    address: '0x21C93e25ca2F29c62d1ca78c0D885bc07109BABd',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 5673.2,
  },
  {
    address: '0xB892808cAf35929D6D965670A5A4c0f52Da8e2B1',
    minters_reward: 5670.57,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5670.57,
  },
  {
    address: '0xc2595965b280de29cf054a0b05d8211de3fcc095',
    minters_reward: 2430.24,
    lp_providers_reward: 1267.18,
    swapers_reward: 871.2107717876853,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 5652.25,
  },
  {
    address: '0xc6ab34235e4b2b7f42a3f6d78830e83760881667',
    minters_reward: 0,
    lp_providers_reward: 5633.72,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5633.72,
  },
  {
    address: '0xb8e6890fab931a27b98de3cc55e270e8c2ca2121',
    minters_reward: 0,
    lp_providers_reward: 53.36,
    swapers_reward: 132.13317624000854,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 5603.59,
  },
  {
    address: '0x1d4e7Abdd3Ea7B553576C392B33629da52250321',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 5555.56,
    total_rewards: 5555.56,
  },
  {
    address: '0x6398b4743d3cf953d5d68bed5daeeefb77cb448a',
    minters_reward: 3240.32,
    lp_providers_reward: 80.43,
    swapers_reward: 31.288597309863803,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5519.28,
  },
  {
    address: '0xe9f963a68c34b4528fc8d2e51c0aca438993b5b9',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 109.14976538268287,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5516.71,
  },
  {
    address: '0xb3bde72b6dc0c54cd9d20e1900bf4637b46c4edd',
    minters_reward: 0,
    lp_providers_reward: 5481.02,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5481.02,
  },
  {
    address: '0xcefa4d1feeaffc599ef9a76fe07b826f2d0b293d',
    minters_reward: 0,
    lp_providers_reward: 5479.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5479.96,
  },
  {
    address: '0xb329237d45be5f8eb5edeae1c3640b07300906a5',
    minters_reward: 2430.24,
    lp_providers_reward: 1208.77,
    swapers_reward: 184.80339359252497,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 5449.24,
  },
  {
    address: '0x0503da89b9ac2140afad0918e3d434619044e285',
    minters_reward: 0,
    lp_providers_reward: 4201.96,
    swapers_reward: 1220.1078987560252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5422.07,
  },
  {
    address: '0x8c33A5E7A05fc66c91b7c9bd77A2f53D8Ca86728',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 5418.1,
    hoaxs_reward: 0,
    total_rewards: 5418.1,
  },
  {
    address: '0x5d1555e2685e42c7b2de76b78119e37fc35fd724',
    minters_reward: 0,
    lp_providers_reward: 1810.87,
    swapers_reward: 3599.257622891609,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5410.13,
  },
  {
    address: '0xfcA75B06257706996ECFD49db1d6382E3fF4eddE',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x36C736fcd2e8786BfE03ED50583Cb238e7076A3F',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x6d0d932e0bC8503740364Da8eDf0c9c9E1B8C2e8',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x4efdE34c54965F956BF6834f0e7dC4286f6a490d',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 5407.56,
  },
  {
    address: '0x15cc4931ac9650b17f4a2347841d999dcb61c350',
    minters_reward: 0,
    lp_providers_reward: 654.44,
    swapers_reward: 1431.6106201753907,
    holders_reward: 541.81,
    hoaxs_reward: 2777.78,
    total_rewards: 5405.64,
  },
  {
    address: '0xd1173c7b20d26f248a7D04F349EfD195b7EA09CE',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 5402.3,
  },
  {
    address: '0x5763623a45c0243dae7326c021f334e41950a226',
    minters_reward: 0,
    lp_providers_reward: 2904.52,
    swapers_reward: 2496.7908263747604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5401.31,
  },
  {
    address: '0x9cd5618c08f20bfba4646bcf39e624e1086b70e5',
    minters_reward: 0,
    lp_providers_reward: 5315.41,
    swapers_reward: 71.64635282174831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5387.06,
  },
  {
    address: '0x81cb0f36e5b144db75f29439af9aadd9ca083a53',
    minters_reward: 1215.12,
    lp_providers_reward: 1982.15,
    swapers_reward: 145.72823616888863,
    holders_reward: 1083.62,
    hoaxs_reward: 925.93,
    total_rewards: 5352.55,
  },
  {
    address: '0xd867fd8d61fa8e2245daf1161086020ab3826472',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 1078.9908781290828,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 5274.06,
  },
  {
    address: '0x0336beC3E4C06A8c355e73FE4fc67Af32075d019',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5265.53,
  },
  {
    address: '0xB6Ae3AdcCf77e7B6aBE93FECd97d9E4A8c326599',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5265.53,
  },
  {
    address: '0x2cb2600B3be433C8C73E702FDE928a57183074Cb',
    minters_reward: 5265.53,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5265.53,
  },
  {
    address: '0x41fc820ee3feaca518c66c1246fd7adb9c06d1cd',
    minters_reward: 0,
    lp_providers_reward: 3568.67,
    swapers_reward: 1693.1925631154236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5261.86,
  },
  {
    address: '0xf5db653ace1976099b4032b41b41df33240bdf83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5225.2777747368555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5225.28,
  },
  {
    address: '0x7f4e6a872904da76522ae579e4f7a6c625576f84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5220.838213030017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5220.84,
  },
  {
    address: '0x5c05960853ae3dfbdfd4f747e6307958f5724c00',
    minters_reward: 0,
    lp_providers_reward: 1742.88,
    swapers_reward: 3450.827482722824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5193.71,
  },
  {
    address: '0xc834f90bc27e9ac5e9eea4927f3dce0465de8a2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5149.598237084114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5149.6,
  },
  {
    address: '0x0f7f17b3635e7d53f0b1b7b6beec9f52ea6451e5',
    minters_reward: 1215.12,
    lp_providers_reward: 1919.52,
    swapers_reward: 1199.074398615518,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 5146.42,
  },
  {
    address: '0xbE7a950A2F630cC9FA03008F149F1Ae3EEfca7A4',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 5131.39,
  },
  {
    address: '0x847c2b158a8893f03b04e4c43ab0603074b21faa',
    minters_reward: 0,
    lp_providers_reward: 2120.59,
    swapers_reward: 224.48713480605738,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 5122.86,
  },
  {
    address: '0xb58eb8f96e61039710259f78ff0670cae8d0bc1a',
    minters_reward: 0,
    lp_providers_reward: 4114.58,
    swapers_reward: 438.10537468858155,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 5094.5,
  },
  {
    address: '0x6d67b62d129f2fd423eb867686a1a48303d10bcd',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 22.594668051881023,
    holders_reward: 541.81,
    hoaxs_reward: 3703.7,
    total_rewards: 5078.18,
  },
  {
    address: '0x35c6432ebc042f0ec6490a6ef5f40423fd06019f',
    minters_reward: 0,
    lp_providers_reward: 3628.9,
    swapers_reward: 1435.497080740638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5064.4,
  },
  {
    address: '0x22c67265037fa9dd907900dbcc5ab8e7a7529bf0',
    minters_reward: 0,
    lp_providers_reward: 2516.19,
    swapers_reward: 2471.4854119148745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4987.68,
  },
  {
    address: '0xc8d46c87757b9012c7e21fdd21b903499b293ea5',
    minters_reward: 0,
    lp_providers_reward: 687.38,
    swapers_reward: 2436.4190376792494,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 4975.65,
  },
  {
    address: '0x283c4c08df792484f0417564bf14be0629b4fbf7',
    minters_reward: 2025.2,
    lp_providers_reward: 538.95,
    swapers_reward: 124.26307624869943,
    holders_reward: 1354.52,
    hoaxs_reward: 925.93,
    total_rewards: 4968.86,
  },
  {
    address: '0x651c2dad2c14c0e474a730c526a95b68292dd3b7',
    minters_reward: 0,
    lp_providers_reward: 4847.96,
    swapers_reward: 74.01533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4921.98,
  },
  {
    address: '0x372a30cd91d78b129767be232d9f283d9babcb69',
    minters_reward: 0,
    lp_providers_reward: 2133.79,
    swapers_reward: 2754.8190108565764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4888.61,
  },
  {
    address: '0x4c8503e721e7e87b62b0d4f09d600f557ce6633b',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 9.440907504228862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4869.93,
  },
  {
    address: '0xb4765A1537de84AC8A9CE1AaFa0aAB39ED2C59C7',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4860.49,
  },
  {
    address: '0xfBe7735916E73fcb46f5f66545C5f1D6C69C43c0',
    minters_reward: 4860.49,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4860.49,
  },
  {
    address: '0x1a33250a0baf81a73a5607ce528409517d95c2b6',
    minters_reward: 0,
    lp_providers_reward: 3267.69,
    swapers_reward: 1547.1214917785016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4814.81,
  },
  {
    address: '0xc2ef1173a4b051bbcc4474c407bbb2ac7e02e363',
    minters_reward: 2430.24,
    lp_providers_reward: 12.57,
    swapers_reward: 178.89962787060725,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 4788.95,
  },
  {
    address: '0x293c3fc7cbadef92bef7fc3c16751107a2ddf59a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4784.135458335499,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4784.14,
  },
  {
    address: '0x325ca972863f1e977f01810d280fff12b82ee68e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4756.530811774126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4756.53,
  },
  {
    address: '0x53d750b47d26750ebf2d7aaa0aec6d37ceff7393',
    minters_reward: 0,
    lp_providers_reward: 126.14,
    swapers_reward: 4626.998150694495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4753.14,
  },
  {
    address: '0x79C805907Bc383F19C9b9716C56d34dAa1ccEF41',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4731.61,
  },
  {
    address: '0x0DFc982BB98317f94392C252082F0bA7944fD4eB',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4731.61,
  },
  {
    address: '0xd37ec4c923605bd14cd83c818531610c1dc8082a',
    minters_reward: 810.08,
    lp_providers_reward: 1766.63,
    swapers_reward: 1603.1898126749822,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4721.71,
  },
  {
    address: '0xD2878d866d325e0cD919426C8fF7de2d51Fa12A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 2777.78,
    total_rewards: 4674.11,
  },
  {
    address: '0xc1ae49d59f3557c6ec4f2398831c72b623885338',
    minters_reward: 3240.32,
    lp_providers_reward: 620.79,
    swapers_reward: 811.6472186706318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4672.76,
  },
  {
    address: '0xa4b39e41f0422ea144a5878691f3c5dc06c8d707',
    minters_reward: 0,
    lp_providers_reward: 460.77,
    swapers_reward: 2977.2337987792744,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 4634.83,
  },
  {
    address: '0x9A631439DA1CD18b86cC10009D7EB40B1631CEcE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 4629.63,
    total_rewards: 4629.63,
  },
  {
    address: '0x04b188ab85f1df6951d46e98f6ae875918face99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07445096006790906,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 4605.45,
  },
  {
    address: '0x35f3FFA936003495c9517D59cC57f7322cd8646A',
    minters_reward: 10126.01,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4605.38,
    hoaxs_reward: 0,
    total_rewards: 14731.39,
  },
  {
    address: '0xef3dDf16475678B7B040E87746f2A9D942361e27',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 4594.84,
  },
  {
    address: '0xd0b8743372085a086e4f86eca8508c159a4df3b8',
    minters_reward: 1215.12,
    lp_providers_reward: 2564.94,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 4592.77,
  },
  {
    address: '0x3db9a60cbd652600657343fcb61785b0a5211c56',
    minters_reward: 0,
    lp_providers_reward: 3454.64,
    swapers_reward: 1126.035423632787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4580.68,
  },
  {
    address: '0x27da921c3868376ed300c8e6bf9a36e163253fc8',
    minters_reward: 0,
    lp_providers_reward: 4572.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4572.18,
  },
  {
    address: '0xa9e063572492a4abe5e910500e4415539800cbf0',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 2262.534600978026,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 4558.63,
  },
  {
    address: '0x244cb8455263a00bbc682c99c08199641868ac25',
    minters_reward: 0,
    lp_providers_reward: 4536.01,
    swapers_reward: 6.011266505518887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4542.02,
  },
  {
    address: '0x027a9187b61e709a89c81040812cd3fe0d86e755',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4532.878180712427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4532.88,
  },
  {
    address: '0xfb42f6e97f0fd1aff4a3d5d1713e6d8447517d4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4489.1848000999025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4489.18,
  },
  {
    address: '0xa392e60caeea390acb9bcc60509cd211d9e2da6e',
    minters_reward: 0,
    lp_providers_reward: 3213.74,
    swapers_reward: 1255.3070010812883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4469.05,
  },
  {
    address: '0xAED36a1347A3C417d292915936e75543364C0076',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4455.45,
  },
  {
    address: '0x2d006DF7a95121a3b8Ebe9A8b8E858CA687492c9',
    minters_reward: 4455.45,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4455.45,
  },
  {
    address: '0x48b06bbe93d09b6f14b5a2ff3772a0ba8721293b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4439.428546839037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4439.43,
  },
  {
    address: '0xcb4d3814384fddb0d1e88db6b9ebebb3894b85a4',
    minters_reward: 0,
    lp_providers_reward: 3220.95,
    swapers_reward: 1207.5599873586648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4428.51,
  },
  {
    address: '0xd8005230ba3f3f361fb5bf288e9440cff46adc44',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 346.16825122631514,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4401.84,
  },
  {
    address: '0xe975b410c3864f1819af1e662098790a4cf9b04d',
    minters_reward: 810.08,
    lp_providers_reward: 2746.44,
    swapers_reward: 297.727043497205,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4396.06,
  },
  {
    address: '0xf88487dac9e937c2ac804d8b1ebc7b2fcf8c25b5',
    minters_reward: 0,
    lp_providers_reward: 3697.93,
    swapers_reward: 417.6365685563453,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 4386.47,
  },
  {
    address: '0x7ba8174c0624c314e1d0deb4fc8a9b59ae70bfe3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4374.18282548842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4374.18,
  },
  {
    address: '0xa684ec3a92423593d294d1a5247f8b98be68b3a1',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 296.1389404089498,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4346.55,
  },
  {
    address: '0x7a680efe9b478b5125564dbb4d26216c9fd4f0c1',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 293.3261546367316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4343.74,
  },
  {
    address: '0xeae527bbb284c5e514dea821ee900ec216e2e944',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4335.930280628802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4335.93,
  },
  {
    address: '0xcC5FafCeBBfe8Cc40e8821b82dbcC7069A94B6c4',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4326.57,
  },
  {
    address: '0x10c6274a3f9bfe5b794999b8dca1d46df6ff93e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4321.867478465986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4321.87,
  },
  {
    address: '0x5644cac0dfbacfdf6ab85578c3e0faa41819ee6d',
    minters_reward: 2025.2,
    lp_providers_reward: 933.24,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 4312.96,
  },
  {
    address: '0xd4d350e0e7730660e6cdf532d613cdfe775d6513',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 255.52569295908896,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4311.2,
  },
  {
    address: '0xc54f4b1f3e6e0b9ab4900a0d73b17dae6d186f55',
    minters_reward: 0,
    lp_providers_reward: 4307.22,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4307.22,
  },
  {
    address: '0x53062aa6df1142ea0fb7e38fe02c3f1d0c741bbf',
    minters_reward: 0,
    lp_providers_reward: 1473.63,
    swapers_reward: 2828.452908483314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4302.08,
  },
  {
    address: '0x8edb6b5d273e75c9218006e5e96f85fcb31e7a78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1519.0553395415366,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 4296.84,
  },
  {
    address: '0x0733a6e17c208ec23c738bd18fd88bd5e7bee191',
    minters_reward: 0,
    lp_providers_reward: 315.16,
    swapers_reward: 459.7563176559796,
    holders_reward: 3521.76,
    hoaxs_reward: 0,
    total_rewards: 4296.68,
  },
  {
    address: '0x92ddf198b2e5cfddf9be7b52f6fb88ae36bfd514',
    minters_reward: 1215.12,
    lp_providers_reward: 101.21,
    swapers_reward: 0,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 4296.28,
  },
  {
    address: '0xacfa0066b4f2321116d6aaa571aaf176fed1a89c',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 203.50780581759147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4253.92,
  },
  {
    address: '0x5c49b554f7bdf02c9640f19b38232c8228e7a777',
    minters_reward: 0,
    lp_providers_reward: 4234.51,
    swapers_reward: 9.317943432206526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4243.83,
  },
  {
    address: '0x1024b9459355e47dd2d8f803ccfa72d7a19ef5e7',
    minters_reward: 0,
    lp_providers_reward: 2180.01,
    swapers_reward: 2059.060356281073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4239.07,
  },
  {
    address: '0x9098cdd6bc85be58f3477ace504d5d38bfcbf4b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4195.0560337378665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4195.06,
  },
  {
    address: '0xab2A9dDAa3F914353b0D6cB1a97Dff1c0d6Bbd09',
    minters_reward: 3645.36,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4187.17,
  },
  {
    address: '0x6d3855a882f9e86993e410e350461cfee96e6624',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 217.05829515622324,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 4138.59,
  },
  {
    address: '0x2b25e8d77642e6b8be906c66254538d52611d0ba',
    minters_reward: 2025.2,
    lp_providers_reward: 657.56,
    swapers_reward: 91.29298804656122,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 4128.57,
  },
  {
    address: '0x1448f01301e814e68008e1be6260d4f801d71f76',
    minters_reward: 0,
    lp_providers_reward: 3802.68,
    swapers_reward: 320.90646459673025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4123.59,
  },
  {
    address: '0xb7f4547af2c705ae90e26a4386c5c45a92a0a99f',
    minters_reward: 0,
    lp_providers_reward: 4119.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4119.11,
  },
  {
    address: '0x6298b696e5307cccc8b01c996ee3291f261d0d7c',
    minters_reward: 0,
    lp_providers_reward: 2790.45,
    swapers_reward: 1324.485834297122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4114.94,
  },
  {
    address: '0x6c1afc5cc455aa9ec59c2237e282331058669429',
    minters_reward: 0,
    lp_providers_reward: 3475.77,
    swapers_reward: 633.5366331650097,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4109.31,
  },
  {
    address: '0xd2764fca2253b9dff244a69442c5de342bdf1bd7',
    minters_reward: 0,
    lp_providers_reward: 3880.92,
    swapers_reward: 225.67389051492216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4106.59,
  },
  {
    address: '0x870400210760EE543aD04F70b00E66215F6d1cb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 4063.57,
    hoaxs_reward: 0,
    total_rewards: 4063.57,
  },
  {
    address: '0x25390e0C9037F301ff57749662ee38d0c6bF429a',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0x0887504aB79c74E806E1558ADb5223Cb92a77484',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0xF870D21122C7F519caf6d09a4F81Fe1E19f2Ec3E',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0xeb4B18605c4AEbcaA7B0C844cFBA1b9a6fa988bD',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0x7CD1820f9Cf4EBEe5e0114EA2594E5502aE39166',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0xA6BbFb8b81E82B5dF07C42f11583d0a6Fc330241',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 4055.67,
  },
  {
    address: '0x9f2e3d5c4d2b0651bf5a81da54ca2b7138a5420a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4054.5130570400634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4054.51,
  },
  {
    address: '0xA8716fAda2049Ea1941560167358d61e5D7f47D2',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xe845e1496ffD073C9C075Da56FB7016c1f0E5200',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0x3A4BC5174fBc3dE9313FCa034D9e0E4AB86D6ECc',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xc0eE3f4a3b898A7404487cB931a734C6e9a6FaD5',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xF1C3977242F926BaC8Da0aFED41A3aF4fA916696',
    minters_reward: 4050.41,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4050.41,
  },
  {
    address: '0xf861cb0563620baf2b5de119914d2ae828855dc3',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 2670.090570989196,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 4021.98,
  },
  {
    address: '0x646C52cDc855672fBb1d394C9A9b16f67904a053',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 1851.85,
    total_rewards: 4019.09,
  },
  {
    address: '0x3d1b3888917ec9353c3ba0b14765c294803bd970',
    minters_reward: 0,
    lp_providers_reward: 170.28,
    swapers_reward: 3839.2830347687304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4009.56,
  },
  {
    address: '0xdF0A38e12c927a436Fe93Bb82eC95Ed6FFF27611',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 3703.7,
    total_rewards: 3974.6,
  },
  {
    address: '0xbaa99cc903864f1e8384f67cf22fb01db68e67ef',
    minters_reward: 0,
    lp_providers_reward: 1572.83,
    swapers_reward: 2376.934170498161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3949.76,
  },
  {
    address: '0xb8ebbe55b5c18ad0075b3b247b50ea82385bda0d',
    minters_reward: 0,
    lp_providers_reward: 960.2,
    swapers_reward: 587.4918522408863,
    holders_reward: 541.81,
    hoaxs_reward: 1851.85,
    total_rewards: 3941.35,
  },
  {
    address: '0x3255db9adfe3d2c95118d58d94c6ee98d4c98933',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 689.6480093061926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3929.97,
  },
  {
    address: '0x74db2ce1aff791732e8167d105c41f754b39cd58',
    minters_reward: 0,
    lp_providers_reward: 3014.12,
    swapers_reward: 913.0564670656504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3927.18,
  },
  {
    address: '0x215a20f91fadf2f99c285f66080642d0439bd984',
    minters_reward: 0,
    lp_providers_reward: 1622.04,
    swapers_reward: 2303.9220875702517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3925.96,
  },
  {
    address: '0xa35900d4d6124179c66cc2caefb9dfb6268ffcea',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 3.372546717454194,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3922.27,
  },
  {
    address: '0xa4937fe46085b5ec73a91d380412c5777d56c0c9',
    minters_reward: 0,
    lp_providers_reward: 1685.58,
    swapers_reward: 1376.974586740776,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3875.26,
  },
  {
    address: '0x9be283faae3f4792740d96d677fc6c4666769420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1073.7761729757717,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 3851.56,
  },
  {
    address: '0xe7d9d0623a5dc7807b1d7436e47ae62255dd064d',
    minters_reward: 0,
    lp_providers_reward: 3849.78,
    swapers_reward: 0.2943683931710472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3850.07,
  },
  {
    address: '0x9f010a1583f7ed30d6cd46937c023e9fb4a5f847',
    minters_reward: 405.04,
    lp_providers_reward: 2175.15,
    swapers_reward: 992.0387934779731,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3843.13,
  },
  {
    address: '0xef16ed3e3b6ca4b43fd7cb3363be21772eb90f44',
    minters_reward: 0,
    lp_providers_reward: 3811.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3811.71,
  },
  {
    address: '0x9c9423fb544ed248c9d456ed85422d861d3bd0ba',
    minters_reward: 2835.28,
    lp_providers_reward: 346.05,
    swapers_reward: 84.36669329900033,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 3807.51,
  },
  {
    address: '0x1346408290b7042b9e434096067f17153abbc968',
    minters_reward: 0,
    lp_providers_reward: 2721.22,
    swapers_reward: 1071.9717641894144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3793.19,
  },
  {
    address: '0xAec0FAd25847188b2ef9A36ADc2033930A500c77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 3792.67,
    hoaxs_reward: 0,
    total_rewards: 3792.67,
  },
  {
    address: '0x5390d29Db2C20bE45D74e58fC012A4f3DF3347E8',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3784.76,
  },
  {
    address: '0xd3b1c15f3459c391a0159eb1ab5e918dce3d9b4a',
    minters_reward: 0,
    lp_providers_reward: 3633.03,
    swapers_reward: 142.43204413390683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3775.46,
  },
  {
    address: '0xd395aad4dbcfcc34d7ef0619a3581754f44eed49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3770.135590676272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3770.14,
  },
  {
    address: '0xc1e1a16a9800977f778e99f72e3da10427d8e68e',
    minters_reward: 0,
    lp_providers_reward: 1218.89,
    swapers_reward: 1066.640635609558,
    holders_reward: 541.81,
    hoaxs_reward: 925.93,
    total_rewards: 3753.27,
  },
  {
    address: '0xef37f48ec243ed3936596a2ab443e5ae301458f3',
    minters_reward: 1620.16,
    lp_providers_reward: 54.13,
    swapers_reward: 172.58600339731947,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 3743.21,
  },
  {
    address: '0x7f684f944844c7e04294c846d88e20925b1cddaa',
    minters_reward: 0,
    lp_providers_reward: 3714.59,
    swapers_reward: 17.47271389260097,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3732.06,
  },
  {
    address: '0x7083b1d4730f22b4a80fb38c0c94546df663e668',
    minters_reward: 2835.28,
    lp_providers_reward: 316.88,
    swapers_reward: 14.803066882861925,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 3708.77,
  },
  {
    address: '0xc80a02d9775fcd7248351d9ca6886174df049f9f',
    minters_reward: 0,
    lp_providers_reward: 3694.85,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3694.85,
  },
  {
    address: '0x00e2f5ac83da949d8bfaa80e822462096e0f0b66',
    minters_reward: 0,
    lp_providers_reward: 1277.42,
    swapers_reward: 1594.7599041312285,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3684.89,
  },
  {
    address: '0xc6c79e5d759aa576a49b00a57402be439c7b0735',
    minters_reward: 0,
    lp_providers_reward: 3672.4,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3672.4,
  },
  {
    address: '0xdfde9a033f395dd68adf16d8afcaaccbda04de69',
    minters_reward: 0,
    lp_providers_reward: 3666.16,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3666.16,
  },
  {
    address: '0xc356219335bdfab476317d733ed29482659f720b',
    minters_reward: 2025.2,
    lp_providers_reward: 277.47,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3657.19,
  },
  {
    address: '0xA9F282f6949C13b05EaF63D559A69f700D8dCc29',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3647.99,
  },
  {
    address: '0x4fae2690e17f94635cc70ad4acd42df09136b0cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3640.5312066261813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3640.53,
  },
  {
    address: '0x49d7dbca1b55654e9b9af4a5a00ca337885f2b05',
    minters_reward: 0,
    lp_providers_reward: 3633.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3633.19,
  },
  {
    address: '0x5260a84a08ea84456e81668212e422dc1847c8ef',
    minters_reward: 0,
    lp_providers_reward: 3627.24,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3627.24,
  },
  {
    address: '0xaea35d1f19473bd0cfb126c5dbd880ab5cff8344',
    minters_reward: 0,
    lp_providers_reward: 1150.36,
    swapers_reward: 1373.8795678067047,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3607.86,
  },
  {
    address: '0x5fd31e1cba20b6264c2a9f60839e95427332becf',
    minters_reward: 0,
    lp_providers_reward: 724.03,
    swapers_reward: 2874.748461645346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3598.78,
  },
  {
    address: '0x8056e36e003e26de864116b3f26d775996fd1a84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3572.838994715721,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3572.84,
  },
  {
    address: '0xcec754b3c254a1d9c8176afa5e3c977f7d5d99f9',
    minters_reward: 2025.2,
    lp_providers_reward: 189.99,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3569.71,
  },
  {
    address: '0xf0109a5b04ef6212003631c3f33d2b1cbff96f1c',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 180.79536911605237,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3560.52,
  },
  {
    address: '0x6103a798ca2543fa72e44daa33b209f39b858251',
    minters_reward: 0,
    lp_providers_reward: 3560.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3560.37,
  },
  {
    address: '0xdcfd3dbd657b4c013329e5a923312fa216f7a06b',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 179.14444654615622,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3558.86,
  },
  {
    address: '0x5d0f37d44969ac70932d16e5ba5efe7340730d4c',
    minters_reward: 2430.24,
    lp_providers_reward: 3.39,
    swapers_reward: 2.081262437890187,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3519.33,
  },
  {
    address: '0x8a3cc3463f94211acd9a2c2b1ac6954a3a31b1ec',
    minters_reward: 0,
    lp_providers_reward: 3472.61,
    swapers_reward: 34.08126986462363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3506.69,
  },
  {
    address: '0x0d5ed9f1505dbd8940d4fb1f41227515ba7dcaa8',
    minters_reward: 2025.2,
    lp_providers_reward: 39.1,
    swapers_reward: 76.08614144571395,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3494.91,
  },
  {
    address: '0x020483457161737fb818f86c848bde3d87f335f6',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 101.99295571367115,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3481.71,
  },
  {
    address: '0xfb935a55b0b67a6504a2afabb198ae5f882bef44',
    minters_reward: 0,
    lp_providers_reward: 1254.12,
    swapers_reward: 2208.425021876777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3462.55,
  },
  {
    address: '0x9d2d3e7e634c4777dcf4d40aa90612d0dd7383e4',
    minters_reward: 0,
    lp_providers_reward: 431.34,
    swapers_reward: 3006.421973274853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3437.76,
  },
  {
    address: '0x89f6b520a1715373fa21e311d6833d19b3cec5dc',
    minters_reward: 0,
    lp_providers_reward: 2304.6,
    swapers_reward: 206.88842463705532,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 3437.42,
  },
  {
    address: '0x8f4c42482c6d11d3f82655a3658d3d4d6fd6abf4',
    minters_reward: 0,
    lp_providers_reward: 3395.67,
    swapers_reward: 40.83808455875602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3436.51,
  },
  {
    address: '0x065c6d15cbe85232aee7300e5e3cdc6cf8420482',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3429.9756489155898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3429.98,
  },
  {
    address: '0x03b035a7b0f2db1c647260c02f6a80e33e500f19',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 48.91944979021019,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3428.64,
  },
  {
    address: '0x3bde68f42d8c77bb4f4a78145c0944766b84b2cc',
    minters_reward: 0,
    lp_providers_reward: 1522.32,
    swapers_reward: 1903.1793127786393,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3425.5,
  },
  {
    address: '0x6261a906c95ed6048431cfbc59c620bce77ed9e6',
    minters_reward: 0,
    lp_providers_reward: 1854.55,
    swapers_reward: 1560.9666032891087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3415.52,
  },
  {
    address: '0xe6bb18aeefc7e8027fa69272eda3fcf81a07581c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3403.4744524859507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3403.47,
  },
  {
    address: '0x061AD0e704847ab4ecE9A870f9C81332b7EaFe1c',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9Ac0dE067A5971A1B3a35d86379bb42fC35bC8E5',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x58149d7AEd0d311E0AdAc9dc8a4Eb5B45f1f6aEe',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xaACEBB9982475f5eeaB2Ad240d26818e0100e04d',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xe20a37E484aC1BAa6b404Fe006770A83b4A59cb2',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9afe62e177D10d294c0a80BbcC719B7F4Ef0af52',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x8C93eD4a308854afD21bE387570B5622caCa9169',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9AB80F940353392eF1393f38350BFcc59DD6cBce',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x35615Dc3488C9657a537313bCAbb4D43662B9C5d',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x4264b8e4486959163B14330622362206c3d275C7',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x9Eaa0072d10fCaF6DCdabf09091C88a64eB4215A',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xE880B0d34F3AF49Fb8503Ad4971Ff882882ab12c',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x960b79AfCfc89Ece532ffF64A158c63592AcA9EB',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xDe671D2bFbDe1751f431bf7BF55009ABa642AC2A',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xe5B8D74c9ebC33CeF1911F646211Ca52213fA793',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x86F5B90934b372130b403d87BB82c4c238452454',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x4c3403dd0f426a983C405889dfC4E0aB3d5fAFb6',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0xC79BAB87c2ead914A29e394Fbe28E1ef78b57115',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x8a1e82145eab037Bd4b66f9273a495fDAE3449E1',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x75eb5f87f72C2c42609Fbe83ef7436a22dCdD756',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x5ce1AC043657cE8742cfCd6EF4703854aad02E3D',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3379.72,
  },
  {
    address: '0x678cae398cb2fb96f06b05018473b8a383b329c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.48674537205056,
    holders_reward: 1354.52,
    hoaxs_reward: 1851.85,
    total_rewards: 3364.86,
  },
  {
    address: '0x924d868847ddb4149fd26d50d95c460176be04f7',
    minters_reward: 1215.12,
    lp_providers_reward: 434.86,
    swapers_reward: 895.6107050186959,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 3358.3,
  },
  {
    address: '0xcaaaec0429ecbafd882ebb7093ab768082a58e51',
    minters_reward: 0,
    lp_providers_reward: 2195.58,
    swapers_reward: 889.7598298828615,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3356.24,
  },
  {
    address: '0xe743b1760ea8a597a866f8c9c689ea58a50aadf0',
    minters_reward: 405.04,
    lp_providers_reward: 2669.8,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3345.74,
  },
  {
    address: '0x5bef79de1c0ccd1c9629f92a57959f4df99d0245',
    minters_reward: 1215.12,
    lp_providers_reward: 327.13,
    swapers_reward: 47.26107978123943,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 3328.15,
  },
  {
    address: '0x7e4c1256a446d2a42dc6c26343eac510c955c4d4',
    minters_reward: 0,
    lp_providers_reward: 533.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 3310.81,
  },
  {
    address: '0xc3a744584ec29001799322e7119ed99e70eb6b42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3306.9513380054464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3306.95,
  },
  {
    address: '0x8eecf7ebab3fa3a3443c71b3f46fdc2b5c9fde78',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 322.32218336878464,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 3297,
  },
  {
    address: '0x10494ba8b148675be0b269bc1fd76ad3543b19b0',
    minters_reward: 0,
    lp_providers_reward: 1616.91,
    swapers_reward: 1650.1345445832878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3267.04,
  },
  {
    address: '0x592aef450db8b4d9c39a4cbfcd0137b2fa70fe31',
    minters_reward: 0,
    lp_providers_reward: 1365.64,
    swapers_reward: 696.5988422786274,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 3259.07,
  },
  {
    address: '0xb951de20f84486a9469fff484ddaa4a7e0b5b9d7',
    minters_reward: 0,
    lp_providers_reward: 3249.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3249.46,
  },
  {
    address: '0xC19e51A53fD2457Aa4d3E3E811b5823750C9429B',
    minters_reward: 3240.32,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3240.32,
  },
  {
    address: '0x7c014808c2552871ed6a16fe900e851f64c1c3b5',
    minters_reward: 0,
    lp_providers_reward: 417.62,
    swapers_reward: 2797.4323490736974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3215.05,
  },
  {
    address: '0xfc139ea7a13b403b5600704da881a550a333ed2d',
    minters_reward: 0,
    lp_providers_reward: 285.63,
    swapers_reward: 2910.3440344242067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3195.97,
  },
  {
    address: '0xd8706fc02717362a28984cbe9b271c99d051d3c8',
    minters_reward: 0,
    lp_providers_reward: 3180.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3180.69,
  },
  {
    address: '0xbe95bbd10ad74733b3188a9cce15f83f3dc00a5d',
    minters_reward: 810.08,
    lp_providers_reward: 1000.86,
    swapers_reward: 1364.4524864249222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3175.39,
  },
  {
    address: '0xee0443c7ef021bb299ea5e4eb81bd6ff0945424c',
    minters_reward: 0,
    lp_providers_reward: 3171.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3171.84,
  },
  {
    address: '0xe1191342e673ba423639ad095f9d079b398f65cc',
    minters_reward: 0,
    lp_providers_reward: 3170.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3170.99,
  },
  {
    address: '0xe89b6d860194d1c2a0b329e959bf9445ee77d899',
    minters_reward: 810.08,
    lp_providers_reward: 1977.8,
    swapers_reward: 110.82854448137779,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3169.61,
  },
  {
    address: '0xac8fbcd7f6c3553cc58a40a3be4bfb93fa073f0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3165.49622166499,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3165.5,
  },
  {
    address: '0x88b751a0ab7e91c471eacdf584ab75bd8a92ec26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3145.367883980434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3145.37,
  },
  {
    address: '0xe74623f58f75a0696b933af7e0dfef3dd5c94e2a',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 31.553239606943055,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3140.37,
  },
  {
    address: '0xb54bd60d96e8f87b2a2a34f3b2b4eab6905d7786',
    minters_reward: 0,
    lp_providers_reward: 2552.81,
    swapers_reward: 581.0599185720984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3133.87,
  },
  {
    address: '0x6d318e12972eae289409344bfd7c18599caefdd4',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 424.1503879589494,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3127.93,
  },
  {
    address: '0xc3a324af00b66140f7d869cb74eb9990b0933e59',
    minters_reward: 0,
    lp_providers_reward: 2087.44,
    swapers_reward: 764.444265929175,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3122.78,
  },
  {
    address: '0x134dbcb57a92c4de1b19f5482dd7dc1e085b084c',
    minters_reward: 0,
    lp_providers_reward: 935.9,
    swapers_reward: 2186.82671155577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3122.73,
  },
  {
    address: '0x50f1c74ff0934e0915a277e02a1438812a45bd85',
    minters_reward: 0,
    lp_providers_reward: 2769.01,
    swapers_reward: 342.05973452790806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3111.07,
  },
  {
    address: '0x910db2813df3bcaf370290736a4696d9fffb6d65',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0.32993690109106844,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3109.15,
  },
  {
    address: '0x5D5b0B967a9e006Af9737FFC37902f80BB23CEb3',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 3108.82,
  },
  {
    address: '0x0c17144e3fb17a7f6aee82008386f53b498d37cb',
    minters_reward: 0,
    lp_providers_reward: 3098.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3098.71,
  },
  {
    address: '0x960448de8c3d33aed56e29b3d71c54606dbe2c09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3098.2870659142304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3098.29,
  },
  {
    address: '0x14c0e0b5ec526f8b42c4e84e0118646fe5e5fd44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3097.8098594630064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3097.81,
  },
  {
    address: '0x808da8bb95c2e3282ed35077143706fcf5e03722',
    minters_reward: 0,
    lp_providers_reward: 1373.01,
    swapers_reward: 1704.9297337460691,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3077.94,
  },
  {
    address: '0xfc968a2eb2185ed98e8c4f2535fbbc4a2460e443',
    minters_reward: 0,
    lp_providers_reward: 3057.17,
    swapers_reward: 1.7003446704545535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3058.87,
  },
  {
    address: '0xf5d0F1182996faA744346b5a0680821d4ca1D187',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 2777.78,
    total_rewards: 3048.68,
  },
  {
    address: '0x6925db439640e52eb2449c0411117a3050a3de9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3033.5945066998106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3033.59,
  },
  {
    address: '0x59889b1835c9b4cfcbb9aec1264baba201f13d75',
    minters_reward: 0,
    lp_providers_reward: 3029.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3029.15,
  },
  {
    address: '0x51559d8186ca93e8a5cba0d28de9eb722affdbc3',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 726.3194319890866,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 3022.42,
  },
  {
    address: '0xaf9eefdf361063301c58625387e88c3421dba49f',
    minters_reward: 0,
    lp_providers_reward: 1843.85,
    swapers_reward: 1176.426003283576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3020.28,
  },
  {
    address: '0xe35ea80a9d9fee2c52df187527709572d5d4b37b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.64686126430361,
    holders_reward: 2979.95,
    hoaxs_reward: 0,
    total_rewards: 3007.6,
  },
  {
    address: '0xfbbd3a8da2022db08d11d443528f7b5f09534313',
    minters_reward: 0,
    lp_providers_reward: 2983.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2983.94,
  },
  {
    address: '0xd9998Ce41599FFE232fcED928e82a0c6f70ADCDb',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 2977.32,
  },
  {
    address: '0x3fc3d51abcf05d61fe3d67b2ce613bb617a93c9a',
    minters_reward: 0,
    lp_providers_reward: 1781.89,
    swapers_reward: 100.13103923447623,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2965.64,
  },
  {
    address: '0xad61f4e0445025895e7a2c9e527da6e498828fef',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 257.8848373221531,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2961.66,
  },
  {
    address: '0x14fb8e37533fc1a49ebf477caaa8d41c765b3f2f',
    minters_reward: 0,
    lp_providers_reward: 2952.14,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2952.14,
  },
  {
    address: '0x2db062d7d4d8cdf6e9d169ef61ec81bbd3e0748e',
    minters_reward: 0,
    lp_providers_reward: 2948.67,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2948.67,
  },
  {
    address: '0xa3f7deef9dfe7c10117d836237d173ea20302a5d',
    minters_reward: 0,
    lp_providers_reward: 2942.64,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2942.64,
  },
  {
    address: '0xb4aab4ae3c36ad9a8ab84e57954f6723149bb4ab',
    minters_reward: 0,
    lp_providers_reward: 24.87,
    swapers_reward: 2913.142937732179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2938.01,
  },
  {
    address: '0xde696a15143ecac5f5bcd56cc717d544b1a56c07',
    minters_reward: 0,
    lp_providers_reward: 2888.29,
    swapers_reward: 48.34675060728067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2936.64,
  },
  {
    address: '0xa4bd6a9220ff24287a74e99d116f0cff24d212ae',
    minters_reward: 0,
    lp_providers_reward: 1696.64,
    swapers_reward: 1239.0120575377862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2935.65,
  },
  {
    address: '0xf0183578D77F70a61C2b9462458f737bc4c1C3F4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 1851.85,
    total_rewards: 2935.47,
  },
  {
    address: '0xf450cf54324457b1764e36618a613819355b77d4',
    minters_reward: 0,
    lp_providers_reward: 2764.51,
    swapers_reward: 163.5688862170653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2928.08,
  },
  {
    address: '0x530e444938759be600cd21337b357b9b71ef46ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.83007000112312,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 2910.61,
  },
  {
    address: '0x0695f17ba74bd06ae6c7f576f2aad2ba82164bb7',
    minters_reward: 0,
    lp_providers_reward: 783.07,
    swapers_reward: 2125.885335756699,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2908.96,
  },
  {
    address: '0x46f66240fc9a9a5fcb279c0f23eda3c73234e958',
    minters_reward: 1215.12,
    lp_providers_reward: 432.39,
    swapers_reward: 428.4373349939522,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2888.66,
  },
  {
    address: '0x95d3b4c79c1b947b960eaac89d9d7861bd4436dd',
    minters_reward: 0,
    lp_providers_reward: 2824.51,
    swapers_reward: 53.691376538529234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2878.2,
  },
  {
    address: '0x311674e34d19787f3d5b31950039c8fd718b7146',
    minters_reward: 0,
    lp_providers_reward: 2874.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2874.05,
  },
  {
    address: '0x04c3157913e5cbd35cb9cb293731de8463691e9d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2054.3846246581325,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2867.09,
  },
  {
    address: '0xfff409db8a6df5317859b2eec93ceb5c32819855',
    minters_reward: 1620.16,
    lp_providers_reward: 110.07,
    swapers_reward: 53.101107045652,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2866.95,
  },
  {
    address: '0x69c96785c6cb97d2b319662c7182488918fb7fcc',
    minters_reward: 0,
    lp_providers_reward: 2653.87,
    swapers_reward: 198.24540962513638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2852.12,
  },
  {
    address: '0xf13c3a62257a3350eb7f2826153ad54404481b96',
    minters_reward: 0,
    lp_providers_reward: 1445.52,
    swapers_reward: 1396.5189588958203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2842.04,
  },
  {
    address: '0xc31f7f55ec62ed6d9a628e2deec8174ef57c0915',
    minters_reward: 0,
    lp_providers_reward: 441.83,
    swapers_reward: 547.9117596044691,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 2841.59,
  },
  {
    address: '0xa087365bc8c99836aa5134a0c1f00c258cf62ee8',
    minters_reward: 0,
    lp_providers_reward: 1223.03,
    swapers_reward: 1613.4527840235191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2836.48,
  },
  {
    address: '0x84843B0114090fd22Edf908b78D20262437e4096',
    minters_reward: 2835.28,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2835.28,
  },
  {
    address: '0x2538dc2a54214f5a25c4ce7eff4f03765e18244f',
    minters_reward: 0,
    lp_providers_reward: 2831.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2831.1,
  },
  {
    address: '0x2b63ef5d5e7d3b9c6939010e89ff9487c454ecf2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2821.5062520474594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2821.51,
  },
  {
    address: '0x665f3964a9841bb510a7fe861ba05ecf24f695e6',
    minters_reward: 0,
    lp_providers_reward: 2076.92,
    swapers_reward: 453.09647940199505,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2800.92,
  },
  {
    address: '0xa1952db7d21e45c49a768c7ca143167abda54660',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 76.7340884653281,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2780.51,
  },
  {
    address: '0x7723000de847d13856Aa46993e6D1d499D13af1B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 2777.78,
    total_rewards: 2777.78,
  },
  {
    address: '0x7dc5467c0911755496b9343e81af716014a784f2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 67.98634528024347,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2771.77,
  },
  {
    address: '0xc4fd0e63c863ba9f6cb1df9ab218060b94a25f91',
    minters_reward: 0,
    lp_providers_reward: 2179.85,
    swapers_reward: 582.0755803749394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2761.93,
  },
  {
    address: '0x5802e1f830d0e460e07544923c39c0bb538d090b',
    minters_reward: 0,
    lp_providers_reward: 2738.68,
    swapers_reward: 0.04197195301403278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2738.72,
  },
  {
    address: '0x3728c0d4d11d3076ae5ea01360ef62217a25fbda',
    minters_reward: 0,
    lp_providers_reward: 2627.63,
    swapers_reward: 102.98426558570894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2730.61,
  },
  {
    address: '0x12b91287560e8f5ce6ebb8740085b62f83a51fea',
    minters_reward: 0,
    lp_providers_reward: 2729.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2729.94,
  },
  {
    address: '0xbee1261d36b2cc89452e5ed270eac282adea8123',
    minters_reward: 0,
    lp_providers_reward: 11.75,
    swapers_reward: 6.979550141388677,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 2727.78,
  },
  {
    address: '0x490ae54c76c09e59de9e7f2cec8f3d428963d633',
    minters_reward: 0,
    lp_providers_reward: 118.68,
    swapers_reward: 2604.030157575698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2722.71,
  },
  {
    address: '0x9e667903c73884064fc7bce6be6b9967644262eb',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 15.24932774572979,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2719.03,
  },
  {
    address: '0xb69813d917bd8e7B0d016742D346081A930647A1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2709.05,
    hoaxs_reward: 0,
    total_rewards: 2709.05,
  },
  {
    address: '0x2a7ea4a0c834371b0338dc663eeb95c85344f81b',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 4.9797808295486234,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2708.76,
  },
  {
    address: '0xb599af4106270937544072bc3f498aa47e555735',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0.36782808806500117,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2704.15,
  },
  {
    address: '0x2Ec2330045D5Ef04bE19DB405B07FDc9d2E178a2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x51d845648FC85ef7cC90fEdd20D86872b17aE7a7',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0xf1c26989591031f6Eb38792C540d60eDc4d52972',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x90229e01D680eb5285f0B6341aa222d720bD2314',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x4bddF582a245d2eABB1Bb335d922c7eD9FAD19B2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x8e025267A659adc863bC7B0A52a1bA74AcBAadAA',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0x30cD700ab29F52786bd9Bfd8a4a5e1587878d11e',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2703.78,
  },
  {
    address: '0xf7a1b59819c3ce8efc5d71927e19c9e159d80ada',
    minters_reward: 0,
    lp_providers_reward: 2676.65,
    swapers_reward: 9.771880513067023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2686.42,
  },
  {
    address: '0x8d196504c6007153ae74655964327eff5744123c',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 109.87521993008207,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 2679.52,
  },
  {
    address: '0x8383297413893c7c3952282455068fbf4fa1bcc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2655.911164546972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2655.91,
  },
  {
    address: '0xe9b8ba5a96d92a42b82dce057c4c7e904174aa4c',
    minters_reward: 0,
    lp_providers_reward: 2391.59,
    swapers_reward: 256.9887011627693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2648.58,
  },
  {
    address: '0xf793950ef6405f8e3c293c0ca81608ab7a527d32',
    minters_reward: 0,
    lp_providers_reward: 886.2,
    swapers_reward: 562.038496294485,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 2645.07,
  },
  {
    address: '0x4858dc291cf11808271c07236200d4302868dd09',
    minters_reward: 0,
    lp_providers_reward: 846.75,
    swapers_reward: 1788.3464188847483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2635.1,
  },
  {
    address: '0x1f7158fa98ffd75add995254efd4a345e1b65207',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 603.2560519093522,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2631.09,
  },
  {
    address: '0x2c7e8ef43c64982cdfd9dc1b78b7a8eaed1d7677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2623.0815407269843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2623.08,
  },
  {
    address: '0x9684b216d18c3862025ea28877017393a3f0fc14',
    minters_reward: 0,
    lp_providers_reward: 1571.7,
    swapers_reward: 1047.887828948203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2619.59,
  },
  {
    address: '0x30b18f0cb0c3a0e83f1442f215578c4fc8802c6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2619.2161413797444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2619.22,
  },
  {
    address: '0x4500e166ba2c9fa90003e3c044aea3b17fcacf53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2609.9510000225728,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2609.95,
  },
  {
    address: '0x457aa5441cc5bf2f6439f7ca842b7a1b52227fa4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2600.5136251007184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2600.51,
  },
  {
    address: '0xbefd314fae2f56ddb9b8e6edbd3ece9ec7acb4d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 966.8024965813473,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 2592.23,
  },
  {
    address: '0xa6fe60f9cbcae2556ef6facf3b1a9c5042b9ef34',
    minters_reward: 0,
    lp_providers_reward: 1252.08,
    swapers_reward: 1337.3624464126613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2589.44,
  },
  {
    address: '0xc151381195f8baeab85fe5dbae5d0e35b5c2c3cb',
    minters_reward: 0,
    lp_providers_reward: 930.11,
    swapers_reward: 1645.2429581826996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2575.35,
  },
  {
    address: '0x4aA15d4E00D8661cF1031D3403376eB6b0f77F95',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 2569.64,
  },
  {
    address: '0x8a0113c1c9b0b0fb4529f1f7e7ce40410158f56a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2566.915337893086,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2566.92,
  },
  {
    address: '0x9a4040f4f23d4958ca2df55f864d6e7f3c3579a5',
    minters_reward: 0,
    lp_providers_reward: 5.9,
    swapers_reward: 2545.994890290751,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2551.89,
  },
  {
    address: '0x12f34c50f7dbecdbcbe65aaf297dccd9e56a8e0f',
    minters_reward: 0,
    lp_providers_reward: 2513.91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2513.91,
  },
  {
    address: '0x0b4f59f204f6011bb2cae02f3562252f10eb3802',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 483.65228035169554,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2511.48,
  },
  {
    address: '0x2ee6e04683fbf399852387046886677c1a5739c3',
    minters_reward: 1620.16,
    lp_providers_reward: 85.95,
    swapers_reward: 797.6959005924906,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2503.81,
  },
  {
    address: '0xa2067165dbc7e4ae89fc9c476376c1fc6d8b50a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 486.660142630649,
    holders_reward: 1083.62,
    hoaxs_reward: 925.93,
    total_rewards: 2496.21,
  },
  {
    address: '0x4f03615e1775c2173ad483cbb38e63c5b8984f47',
    minters_reward: 0,
    lp_providers_reward: 2492.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2492.9,
  },
  {
    address: '0xfb89b7582dd4b971a6a7da1d052eea6990e636b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2488.883714154514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2488.88,
  },
  {
    address: '0x7a8653571a0f36f837aa475bc3d6ac083531d7d3',
    minters_reward: 0,
    lp_providers_reward: 2446.68,
    swapers_reward: 15.147649070420439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2461.83,
  },
  {
    address: '0xfdd57ceb9d40a847cd7a3c06d331aaf3a1bfccc1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2461.4558593171705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2461.46,
  },
  {
    address: '0xc53e70a0dba5fc94d9fb5e14b84a5b3ea36fc856',
    minters_reward: 0,
    lp_providers_reward: 2457.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2457.55,
  },
  {
    address: '0x54c219f0f24aa795f6a18e8fd99a84c858252bc0',
    minters_reward: 0,
    lp_providers_reward: 2039.61,
    swapers_reward: 410.84085330304174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2450.45,
  },
  {
    address: '0x6730cb283805e3f4907c69c7e6de9c1befd27835',
    minters_reward: 0,
    lp_providers_reward: 643.5,
    swapers_reward: 66.58218481345048,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 2448.72,
  },
  {
    address: '0xe2ee9c5e9cdc329e7ec12f0dc53ac0dc3a06cb98',
    minters_reward: 1620.16,
    lp_providers_reward: 13.74,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2446.61,
  },
  {
    address: '0x26aecc46d6689ba43a41d1429e93896562216fe0',
    minters_reward: 0,
    lp_providers_reward: 2443.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2443.42,
  },
  {
    address: '0x5ef993d9ebeac17baaf0e6b37c5e54c756d867b6',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 13.065754821404196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2443.31,
  },
  {
    address: '0x96379898ADAfb0bff44dDDcC014B805b44D347b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 2438.14,
    hoaxs_reward: 0,
    total_rewards: 2438.14,
  },
  {
    address: '0xe041cfdd86ba24ea14d6b2328b443e826a0969ac',
    minters_reward: 0,
    lp_providers_reward: 2435.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2435.84,
  },
  {
    address: '0xda1f41F246Fd7DdC2BdE3582e3a0AA45139aF1B2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2432.87,
  },
  {
    address: '0x5Caac568a988cb43B5C92aB32b53c58b4ab02Ff2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2432.87,
  },
  {
    address: '0x9a794df76c2d1992f04c37bd5e02cf74f435a5bc',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 1.63916886632315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2431.88,
  },
  {
    address: '0xe098843e1c6afda7b90d10ac4eb7bc7063cd186d',
    minters_reward: 0,
    lp_providers_reward: 1.9,
    swapers_reward: 2428.3494686301597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2430.25,
  },
  {
    address: '0x5D5bADf0C09Cc5a14E6FCCbA1a0fA54Eb1a3fdbB',
    minters_reward: 2430.24,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2430.24,
  },
  {
    address: '0xecf0ce6b1f7506544b7965c28e6cc27367680017',
    minters_reward: 1620.16,
    lp_providers_reward: 213.12,
    swapers_reward: 587.1343724835577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2420.41,
  },
  {
    address: '0xeefca716307f3472d3d3eaeb1bdece025188e593',
    minters_reward: 1215.12,
    lp_providers_reward: 225.06,
    swapers_reward: 147.79788582698066,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2400.69,
  },
  {
    address: '0x0734d15428e6f93dbe65b886eccf05b7fc151f6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2389.3508489827436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2389.35,
  },
  {
    address: '0x61f1230db5d1709a021358bac7541374df4b5dd2',
    minters_reward: 0,
    lp_providers_reward: 978.16,
    swapers_reward: 1404.1011778631546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2382.26,
  },
  {
    address: '0x1e923007687cfd53a5e3314b76e30afaedb03cf0',
    minters_reward: 0,
    lp_providers_reward: 2379.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2379.77,
  },
  {
    address: '0xaa6ee5f1ad07e532711e7f38f79313b5397e034a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2106.996333040613,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2377.9,
  },
  {
    address: '0x1b6c7d9bccf3f6b2b1c6303acb556526c4b7107d',
    minters_reward: 0,
    lp_providers_reward: 2377.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2377.25,
  },
  {
    address: '0x92010c09e38125c4b40e69aefcba318d84db6250',
    minters_reward: 0,
    lp_providers_reward: 1406.79,
    swapers_reward: 969.4880313705961,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2376.28,
  },
  {
    address: '0x4bb66561e90f677a378b898c2ddaf57e4534f513',
    minters_reward: 0,
    lp_providers_reward: 2370.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2370.66,
  },
  {
    address: '0x1a4446c2ffb3d6cf2adfe96d26cc9f326df881e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2353.836410644376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2353.84,
  },
  {
    address: '0x59b3ac671fe63b3749c5db934cece7d3363f0d4a',
    minters_reward: 0,
    lp_providers_reward: 652.21,
    swapers_reward: 1696.5151698857806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2348.73,
  },
  {
    address: '0x71e9f694c2e6cce05d55f6e2152f45bd74b88044',
    minters_reward: 2025.2,
    lp_providers_reward: 104.58,
    swapers_reward: 216.2133195422183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2345.99,
  },
  {
    address: '0x49d8aa21b3382bcfcbeb49c11451b7435404f0bf',
    minters_reward: 810.08,
    lp_providers_reward: 1502.26,
    swapers_reward: 1.2947215965791234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2313.63,
  },
  {
    address: '0x26217d520485ae1a9f8cb8418847ae2cfbf9188c',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 282.14830548613725,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2309.98,
  },
  {
    address: '0x3ed32657c263b9e38375f5dc155e844335d486c8',
    minters_reward: 1215.12,
    lp_providers_reward: 59.77,
    swapers_reward: 222.35373207676403,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2309.95,
  },
  {
    address: '0x272c7831d8df272ac1413caed1be6385bedf8606',
    minters_reward: 1215.12,
    lp_providers_reward: 273.96,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2301.79,
  },
  {
    address: '0x1b0f26664c1a1e2ce014270bb2ebc23a18f021f0',
    minters_reward: 0,
    lp_providers_reward: 2300.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2300.21,
  },
  {
    address: '0xa4a30711fBc0905573D68378E2005F2390eFF30F',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2298.74,
  },
  {
    address: '0xc26A1e619D956Fda64B6bDD7c76efa8582828EDf',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2298.74,
  },
  {
    address: '0x010c9f17dc4d0ef8a140b59b1301c01298f4b1b7',
    minters_reward: 0,
    lp_providers_reward: 1116.14,
    swapers_reward: 1172.2413808552894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2288.38,
  },
  {
    address: '0x241c7809e31ae711805feb8082a926c708be13d2',
    minters_reward: 0,
    lp_providers_reward: 1726.39,
    swapers_reward: 560.8169247827417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2287.21,
  },
  {
    address: '0xc046ee83741c5cf638dc3c3fd52862db7bc6bcd7',
    minters_reward: 0,
    lp_providers_reward: 995.55,
    swapers_reward: 1290.279459137316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2285.83,
  },
  {
    address: '0x7558cd06f3f53391e50d093ee000266b685fc875',
    minters_reward: 0,
    lp_providers_reward: 503.11,
    swapers_reward: 567.8779129789249,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 2267.82,
  },
  {
    address: '0xdd7a10cd48044c3a724ba73e143f410cc4ef34f5',
    minters_reward: 810.08,
    lp_providers_reward: 913.51,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2265.4,
  },
  {
    address: '0x9deda34f2302c96372e1d634beab5b2bbd308536',
    minters_reward: 0,
    lp_providers_reward: 1908.17,
    swapers_reward: 356.28028255853366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2264.45,
  },
  {
    address: '0xb4596df3f7469bc117922de384fc7410ce9b370d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2263.1994001589587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2263.2,
  },
  {
    address: '0xe9b42e395e375bb65d38403eec2a353d9e28e0d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2262.879935518319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2262.88,
  },
  {
    address: '0x8279b1d1c8aa23e2dc7c99f657679ddda3f433f6',
    minters_reward: 0,
    lp_providers_reward: 1010.38,
    swapers_reward: 1251.960030132123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2262.34,
  },
  {
    address: '0xd784e0c2a104685b6e66924c2c2aa723cefb9514',
    minters_reward: 0,
    lp_providers_reward: 485.74,
    swapers_reward: 567.7611156421258,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 2250.33,
  },
  {
    address: '0x8251a2ee15fb7e9d87a822b1e0f3e2d7fa36f5fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2244.264055850227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2244.26,
  },
  {
    address: '0xe058034a671f6732a38499994ad14a80eb8fc93c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2237.2569656500837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2237.26,
  },
  {
    address: '0x950bd21ab0d2911288ca37d6b94b26a826713357',
    minters_reward: 0,
    lp_providers_reward: 979.97,
    swapers_reward: 1256.7630161308002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2236.73,
  },
  {
    address: '0xf2490a9b571d18b1b84a3c22eeadcb8d6891af3a',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 205.62081175546686,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2233.45,
  },
  {
    address: '0x0c363081a03842781ecf96350a81dac6c1dd2c9e',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 201.67936811094128,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2229.51,
  },
  {
    address: '0xf6d64c3f1d48dc73b179deb8b29d3eb9bf4a68d0',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 465.12087052568364,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2222.05,
  },
  {
    address: '0xa31c98406897120a669d9c168f4600d616d92c3f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1291.147277988865,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 2217.08,
  },
  {
    address: '0xcf628285969171060fc5b86bef9367c19c6c3f6f',
    minters_reward: 0,
    lp_providers_reward: 1621.88,
    swapers_reward: 587.7383991089548,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2209.62,
  },
  {
    address: '0xbad4a7e54d283d3f7c821e98c1aacaf41113ca5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.760425227131556,
    holders_reward: 2167.24,
    hoaxs_reward: 0,
    total_rewards: 2204,
  },
  {
    address: '0x331065fc55f5ee0896fb1abc712ff0f0c4e839f7',
    minters_reward: 0,
    lp_providers_reward: 850.81,
    swapers_reward: 1080.7222096965256,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2202.43,
  },
  {
    address: '0x9217aada66f94c3d0b6041bc86b90db8998c6c97',
    minters_reward: 0,
    lp_providers_reward: 799.44,
    swapers_reward: 1390.5604148531088,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2190,
  },
  {
    address: '0x195b2554dda2d0d39e3eef94fbf819c107484fa1',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 158.86045648795462,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2186.69,
  },
  {
    address: '0x037afd9b6d7b05beed5324f601fd2757acf540c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2184.0792348797127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2184.08,
  },
  {
    address: '0x561eb00cec8af529e21e40b2846847a3e8a6a7f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 327.6893259186624,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 2179.54,
  },
  {
    address: '0x2d963cb19eeeabc23eaf2f4ae6b5cc90b7bd9bc7',
    minters_reward: 0,
    lp_providers_reward: 2162.82,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2162.82,
  },
  {
    address: '0xd793e05ea4a48b8fb4eca4ff9b4a676011dac64d',
    minters_reward: 0,
    lp_providers_reward: 1643.01,
    swapers_reward: 243.82551985252803,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 2157.74,
  },
  {
    address: '0x74e3262fc95280eb9e22a21ac668359a86dbe9a6',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 129.34292764180515,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2157.17,
  },
  {
    address: '0xfaacff69d0a13ca156c8740b8bd37453456461b9',
    minters_reward: 0,
    lp_providers_reward: 1968.66,
    swapers_reward: 183.25619097684324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2151.92,
  },
  {
    address: '0x4d7c8f733cde83ae7ac2511cb940933599370f62',
    minters_reward: 0,
    lp_providers_reward: 1839.59,
    swapers_reward: 310.6206423354066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2150.21,
  },
  {
    address: '0x93552122d90c30cd013d2dfe574e3c156396f591',
    minters_reward: 0,
    lp_providers_reward: 2148.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2148.97,
  },
  {
    address: '0x1000513c90c42da43413cf060578702cc74aaacb',
    minters_reward: 0,
    lp_providers_reward: 435.69,
    swapers_reward: 1166.0089742388095,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2143.51,
  },
  {
    address: '0x41c540320aa7040aea649845bd0fb7e49deea8c0',
    minters_reward: 1215.12,
    lp_providers_reward: 135.25,
    swapers_reward: 238.03226717055645,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2130.21,
  },
  {
    address: '0x27c523cfa09ce654e706077b46c57eef9c9e5880',
    minters_reward: 0,
    lp_providers_reward: 498.84,
    swapers_reward: 1624.5306276095653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2123.37,
  },
  {
    address: '0xeeb6357f68a5ccbfd8c8d564fd88afc8dbfcc648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2118.7940883168935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2118.79,
  },
  {
    address: '0xac2d528ed9add1ed761ac06bab3dd8fb40792189',
    minters_reward: 0,
    lp_providers_reward: 571.3,
    swapers_reward: 450.9618774580983,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 2105.88,
  },
  {
    address: '0xc534f8110086725809ce7f9205f150779609a151',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2105.5571886748535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2105.56,
  },
  {
    address: '0xb8a16e7ad99a7c05692f11f2abb5c0370521bf41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2102.7709041580747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2102.77,
  },
  {
    address: '0x9f49b14950cc6b52e25a633abc646c13a603fa97',
    minters_reward: 0,
    lp_providers_reward: 2097.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2097.86,
  },
  {
    address: '0xcb951993b70057de4151446e573073be23f7b9bf',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 69.4829496642661,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2097.31,
  },
  {
    address: '0x25e73ad99d960c1f96d2609a9d9862bc89072ea6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2096.686695664932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2096.69,
  },
  {
    address: '0x3953106a34ae4e656d8a2aa1038967b8122095dd',
    minters_reward: 0,
    lp_providers_reward: 1852.91,
    swapers_reward: 229.84646791751035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2082.76,
  },
  {
    address: '0x6714625c3308a33b9a767dab49a34844cdef0107',
    minters_reward: 0,
    lp_providers_reward: 2081.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2081.76,
  },
  {
    address: '0xd69b9f96177ee1534c9295c4ef4eb70bda4dd7f7',
    minters_reward: 0,
    lp_providers_reward: 2079.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2079.18,
  },
  {
    address: '0x7dfb326e6d44d2c9e296c1baac22ff81f8310003',
    minters_reward: 0,
    lp_providers_reward: 1403.57,
    swapers_reward: 668.2192210194514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2071.79,
  },
  {
    address: '0x1fdebe3c40972e6a7838b3d3eb72881e5fdf18e8',
    minters_reward: 0,
    lp_providers_reward: 1777.11,
    swapers_reward: 286.5275407877692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2063.64,
  },
  {
    address: '0x783dbaaf1fa8e5ac404e576cc038a0012749f043',
    minters_reward: 0,
    lp_providers_reward: 2056.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2056.25,
  },
  {
    address: '0xcbe9bd7b1357ba350f521526a786d7dd6dd37bfb',
    minters_reward: 0,
    lp_providers_reward: 1418.96,
    swapers_reward: 634.0964234976916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2053.06,
  },
  {
    address: '0xad503eccd35724a6f463bfd47c58e57eda7892cb',
    minters_reward: 0,
    lp_providers_reward: 2053.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2053.05,
  },
  {
    address: '0x31ebedebb0aac3ebaac8230e2fcf59e7ded62e2b',
    minters_reward: 0,
    lp_providers_reward: 1273.42,
    swapers_reward: 775.6579385000898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2049.08,
  },
  {
    address: '0xaf7aa4cf4e10941c285817df14edf247a00a80fc',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 15.292287182665143,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2043.12,
  },
  {
    address: '0xa26f68ac2a90e6363e64bc55960f2d75d2ddf730',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 12.283211788581228,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2040.11,
  },
  {
    address: '0x21a590a6ca71a19918c009579beb7e551ac43f6a',
    minters_reward: 1215.12,
    lp_providers_reward: 7.35,
    swapers_reward: 3.534154509161336,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2038.71,
  },
  {
    address: '0x1798f42cad4e53913046e6c62e75c218258686f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2033.3647327763306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2033.36,
  },
  {
    address: '0xed99cc7d9d491b26966f05329feadf5f49d2c160',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 7.2480638348043165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2032.45,
  },
  {
    address: '0x6f2c40252c301648393816a1fbdccb7a19b7702f',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 2.846812866322788,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2030.68,
  },
  {
    address: '0x7de1df3e283bf920f1c4574141fecac0462384b2',
    minters_reward: 810.08,
    lp_providers_reward: 678.56,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2030.45,
  },
  {
    address: '0xA8d2C1178A9b6B98De5530bBF8DBfCB6fddf8f93',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x834fe96456115029871860eE88db1d4e12d8Eb1B',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xAfFB16ed9Da2e415E0f1A9e4d2EE065B4E3cCe9B',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x2C1f2767670645e1d7f0Da8aB9fe3AC7875AF00B',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xCB00d822323B6f38d13A1f951d7e31D9dfDED4AA',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xfc241EbB1186fEA483a0d4E5EC1476F0ba368Ea0',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xF05B327E5a49Bbc83eDe53749313f7E61a3a05F3',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x09EBd3F96BDE4ecEa73c906E81BDf858fD15e508',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x5b5121690b9C72283205A62CeDDe81fE84CB1A9b',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x762D5548ea30BbF09ADb153d0a0ceaE75AB418cf',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xEF24B9D608A81613BEA24B67ad7DE190Ccf606Be',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x0e39ae909E395debB589c639257aabb36CF670B6',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x689Ec53984503bda7dbcCA4ce2EA006A3839928a',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x72B784428623413855e6bE85C7EFCe0a4Ec38B54',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xDF861b73cDe3f062b3e7D6A4e42b5A745b758191',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xBCc00Ee6D420943B675AEd5B3bb52558dDd7Ca1b',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xE545BE4690D6d39A9E2301889f181fcc41eB1b1C',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x5cA1699468880Bef5f8A56cdC97a9BD2de11A7D6',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x1172e85dfB32FD7f418699cDE854f542a0254443',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x08fA7B7451D57D7Abd57a76587cC1EA14Ca14a1E',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x9dabc608F8aa8cfEbB7f81663804A0932a91c95c',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0x8a69d9278D6ff6aF5aC0a534D6103862Bb2D342E',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xD200ffeEB88D88cDaDaaba9F3DD9f5E31c77d54a',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 2027.83,
  },
  {
    address: '0xaBe44eF1548A92424165371123d41E21146f14e0',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x9D7B96A8c10d53b226a9d924c3dEd37D9d21C0C8',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x0DC13FeFc0b21c139a12C849421c16E6bb9A70d4',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x57e388eD18360c11BedFF63a5aC461704FE9A8E5',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0x9F607bC8Cba9d6678d32Ab4fc2C0259daD6e03c1',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0xA461a0F6598767ce55DeA88AC51F3ff510bF55ad',
    minters_reward: 2025.2,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2025.2,
  },
  {
    address: '0xa087c1638e9bf6bb55fdf642fa0d53de87aa1a06',
    minters_reward: 810.08,
    lp_providers_reward: 564.29,
    swapers_reward: 107.10534588510453,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 2023.29,
  },
  {
    address: '0x1ed68f3a345be78c43613762076a4fab69132c9a',
    minters_reward: 810.08,
    lp_providers_reward: 920.89,
    swapers_reward: 282.09203709234623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2013.06,
  },
  {
    address: '0xeb40f3185a08278141b12cf20a9d0bea6447bda9',
    minters_reward: 0,
    lp_providers_reward: 1328.18,
    swapers_reward: 681.4357594026957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2009.62,
  },
  {
    address: '0xf7bf76d355f1ecc958030497e08f3c3de78b208f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2001.3775121973508,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2001.38,
  },
  {
    address: '0x672eafbddd3cfe88eb85cfa899bbb525e039e5ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1995.6676712831784,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1995.67,
  },
  {
    address: '0x5b43dfec0b2d938660cd9e22f0415e8767a42f69',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 234.8462124985087,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1991.78,
  },
  {
    address: '0x91b5a622d6c97a850cdea11db2262a46ecbddcb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1985.7003407107168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1985.7,
  },
  {
    address: '0xcda7fb02af0b66a46ca3be20e2f289290eb470d8',
    minters_reward: 0,
    lp_providers_reward: 1879.02,
    swapers_reward: 103.22489584647478,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1982.24,
  },
  {
    address: '0x203a2b4c94bb375cf1f5e51be1711922615f2ab6',
    minters_reward: 810.08,
    lp_providers_reward: 627.44,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1979.33,
  },
  {
    address: '0x1609ba15025caa185ed284d6cdd71c71d3bec360',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.20402206573971,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 1951.05,
  },
  {
    address: '0x75ece9b12e18b1d01f87c0ee2204e4bf160a9036',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1931.2325631510316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1931.23,
  },
  {
    address: '0xf9861b7eb028251a017a465081df75fd14aaaaec',
    minters_reward: 0,
    lp_providers_reward: 1920.83,
    swapers_reward: 4.40930511385512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1925.24,
  },
  {
    address: '0x6efd09a691a36d6b0643e935682b2c453a5d32b1',
    minters_reward: 0,
    lp_providers_reward: 1923.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1923.59,
  },
  {
    address: '0x4d92e12de2ada7284187ee7ebc1ceaa06ebea73b',
    minters_reward: 0,
    lp_providers_reward: 1914.51,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1914.51,
  },
  {
    address: '0x39bb73bd6be81c192b64b390fcd08f42525339e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1914.1544988632947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1914.15,
  },
  {
    address: '0xfa7c7c77ef7b5ac65714450b3ffcf148e19ed77d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1910.9251786584975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1910.93,
  },
  {
    address: '0x6237c3e5a234596dc5704ae3940a0929a03a2935',
    minters_reward: 405.04,
    lp_providers_reward: 952.98,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1899.83,
  },
  {
    address: '0x2ab516e124a669841654acb1403ab42c8154702a',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 4.040331702543529,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1897.74,
  },
  {
    address: '0x00cA04c45da318d5b7E7B14D5381Ca59F09C73f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0xa37822AB0F6a59B55ecFbbFBcE524864EF2EBD74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0xB33066b5B075C368fd7Ab24c630a784C04b5b461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x6D459A03Cc8373644C90F930E6BAC80C1966d187',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x9fFd84B8ea294fc4927A627016897877FcA23808',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x8e87e306a72b8cb47E10C01C9715E340C6b8781F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1896.33,
    hoaxs_reward: 0,
    total_rewards: 1896.33,
  },
  {
    address: '0x1C4570f5Bc159fe9Fe296c5180a5803433139D3D',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1891.06,
  },
  {
    address: '0xda66d8f641ff6449f5bd06013c44d9f876ddd9b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1890.1277315290902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1890.13,
  },
  {
    address: '0x2dbdef5d941a45e5d6dce0184b9cd024a8a88e5a',
    minters_reward: 0,
    lp_providers_reward: 1885.08,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1885.08,
  },
  {
    address: '0xe28e213e71177f19e34403801332ab77ce1071f6',
    minters_reward: 0,
    lp_providers_reward: 1526.67,
    swapers_reward: 348.5005752163473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1875.17,
  },
  {
    address: '0x5c97dac2ec021ce2a1f9e859237356c017e92285',
    minters_reward: 0,
    lp_providers_reward: 1390.21,
    swapers_reward: 484.87581948555356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1875.09,
  },
  {
    address: '0x80b620062a0ef9613c487a2e8c0fa0c72aae9b76',
    minters_reward: 0,
    lp_providers_reward: 1854.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1854.92,
  },
  {
    address: '0x7CB678F8EF03b71410F1290b43A76619A2123A88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 1851.85,
  },
  {
    address: '0xE18ff21F1EDea06b7AF475A1B7A81Baeef8fFc25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 1851.85,
    total_rewards: 1851.85,
  },
  {
    address: '0xde446893252b9af11a88ae1a9976dd83a5f0b9a3',
    minters_reward: 405.04,
    lp_providers_reward: 888.64,
    swapers_reward: 285.89298854428876,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1850.47,
  },
  {
    address: '0x9bc739e1617e07e975a1ceb9ffa51a19d260c72c',
    minters_reward: 0,
    lp_providers_reward: 1847.47,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1847.47,
  },
  {
    address: '0x04f5885c2c6a24f89121b1747f1f53b87992e270',
    minters_reward: 0,
    lp_providers_reward: 1845.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1845.81,
  },
  {
    address: '0xd263b093076130d5c2db662e2237532b6eb8cc87',
    minters_reward: 0,
    lp_providers_reward: 389.8,
    swapers_reward: 1455.875178095612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1845.68,
  },
  {
    address: '0xb691b4b6d0ed845a306b5fef8be9a80dfca4fdfa',
    minters_reward: 0,
    lp_providers_reward: 1823.78,
    swapers_reward: 21.668090146435194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1845.45,
  },
  {
    address: '0x5aaec7b9dec7bd01b3c65a97a8762f10f6ff126f',
    minters_reward: 0,
    lp_providers_reward: 1841.91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1841.91,
  },
  {
    address: '0x1b9165619615989ebbcaba0b60469d10adc95ebf',
    minters_reward: 0,
    lp_providers_reward: 1836.98,
    swapers_reward: 4.644113570507153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1841.62,
  },
  {
    address: '0x7000ca306393f73f7f6b6d53e1ca59eef0c46732',
    minters_reward: 0,
    lp_providers_reward: 127.52,
    swapers_reward: 1712.9794081131608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1840.5,
  },
  {
    address: '0x8a84cba1a6e036bbc98d6ab2bb07608176f52ba2',
    minters_reward: 0,
    lp_providers_reward: 1838.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1838.42,
  },
  {
    address: '0xb0d82d2fdfeb39ba1d1562f54e4d93b10f21cc3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1834.6489483158327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1834.65,
  },
  {
    address: '0xc191ebe4f01864bbb3974bd36aaf8d4b47ee67a7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1426.1943165492776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1831.23,
  },
  {
    address: '0xc5181a5aaab262c85199e97e49994d7996f15a92',
    minters_reward: 0,
    lp_providers_reward: 1437.56,
    swapers_reward: 388.1679218792222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1825.73,
  },
  {
    address: '0xb482982de7e57021a40da0b02d5f9f73021f8338',
    minters_reward: 0,
    lp_providers_reward: 1821.04,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1821.04,
  },
  {
    address: '0xfc4407fe5828bd19b3955eb9a3dad3d9427d7002',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 352.7474435475284,
    holders_reward: 541.81,
    hoaxs_reward: 925.93,
    total_rewards: 1820.49,
  },
  {
    address: '0xaad6653ec072a6f0d18023844f4dd4d9664a80fb',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1143.0349325680636,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1818.97,
  },
  {
    address: '0x7fce412f0e4860036ae16ba0a9a1f28f4a8c7aa0',
    minters_reward: 0,
    lp_providers_reward: 1786.57,
    swapers_reward: 30.0617686348043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1816.63,
  },
  {
    address: '0xdd91ebf26f8a3334b17b53a4b2c65fdb6a6a2d9d',
    minters_reward: 0,
    lp_providers_reward: 1193.4,
    swapers_reward: 611.7086344243417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1805.11,
  },
  {
    address: '0x9f54598363ada26717656c11dcc66a96bf856b5a',
    minters_reward: 0,
    lp_providers_reward: 1801.22,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1801.22,
  },
  {
    address: '0x88b6e74056bb26e3eb84fa300108e9e3b1217cb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1790.7807678729923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1790.78,
  },
  {
    address: '0xe2d717740cf727a0d017b4c7b35f3c2612c2d13d',
    minters_reward: 0,
    lp_providers_reward: 1764.59,
    swapers_reward: 25.075605072088052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1789.67,
  },
  {
    address: '0xf161a5ea83fd2fbfdc8cac89e60361910f30238d',
    minters_reward: 0,
    lp_providers_reward: 1785.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1785.71,
  },
  {
    address: '0xd40e4876a1b731b3b83e2fd661e1757abb389224',
    minters_reward: 0,
    lp_providers_reward: 1784.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1784.49,
  },
  {
    address: '0xf612cbfb72a27124384d5de202c3ed414cd028f1',
    minters_reward: 0,
    lp_providers_reward: 1530.96,
    swapers_reward: 249.0431822489708,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1780,
  },
  {
    address: '0xf9d02cf8ab6e782aae86d9067a43ba7723999d37',
    minters_reward: 0,
    lp_providers_reward: 1633.28,
    swapers_reward: 144.9911935557147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1778.27,
  },
  {
    address: '0x4e388d31f6fa5ec1af464b4f6db60714a8d6d8ff',
    minters_reward: 0,
    lp_providers_reward: 1772.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1772.84,
  },
  {
    address: '0x4565e08505ae3a7a49eb1ac416fd421b523d6d60',
    minters_reward: 0,
    lp_providers_reward: 1765.44,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1765.44,
  },
  {
    address: '0xbba2f283f86460bb19832a668c07310045933445',
    minters_reward: 0,
    lp_providers_reward: 1117.1,
    swapers_reward: 373.6687715602147,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1761.67,
  },
  {
    address: '0xaec539a116fa75e8bdcf016d3c146a25bc1af93b',
    minters_reward: 0,
    lp_providers_reward: 908.41,
    swapers_reward: 852.2568691253895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1760.67,
  },
  {
    address: '0xD58077b50C07AEF5b6963186FaC044f9C6C86f44',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1756.93,
  },
  {
    address: '0xbfdd5fb1911dbfca5e77b99b5726776a603e8fdf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1754.321444883126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1754.32,
  },
  {
    address: '0x91b69467f3e6d3eacaafe45a98c966b8b54c80ce',
    minters_reward: 0,
    lp_providers_reward: 1744.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1744.45,
  },
  {
    address: '0x543ca818620fedea6b780054d7108aa1ffa23a76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1471.3324552134723,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1742.23,
  },
  {
    address: '0xaD3ed58F28F1AE43eF59fb25fc4e2Ad7b05bff15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 1738.64,
  },
  {
    address: '0x5Dd50a20022Bb03ABd4f307aF2f9768BeeD71113',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 1738.64,
  },
  {
    address: '0xD4793089EBa24a81e8E1261C9Fd9A281b9316B66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 925.93,
    total_rewards: 1738.64,
  },
  {
    address: '0x5bcf1b386034156856277e960e32007facaf7382',
    minters_reward: 0,
    lp_providers_reward: 1738.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1738.1,
  },
  {
    address: '0x4a55fb9d92c9bfe67a79a7db944cfd86ecd60960',
    minters_reward: 0,
    lp_providers_reward: 1723.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1723.69,
  },
  {
    address: '0x605ba11c879aa90d4fc66239c187228d72f08ee1',
    minters_reward: 0,
    lp_providers_reward: 900.72,
    swapers_reward: 822.0161078380415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1722.74,
  },
  {
    address: '0xcdc5d3626ecfb46b27e1f12d21d1eac32d7c42f6',
    minters_reward: 0,
    lp_providers_reward: 1436.44,
    swapers_reward: 284.2656294619937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1720.71,
  },
  {
    address: '0xbd431a188d82854c337b63eda6da2d84e46864f6',
    minters_reward: 0,
    lp_providers_reward: 1718.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1718.11,
  },
  {
    address: '0xfbe4c84825edf4644776c0b50c4d5c15910de501',
    minters_reward: 0,
    lp_providers_reward: 172.53,
    swapers_reward: 1541.0068435444596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1713.54,
  },
  {
    address: '0xaa0ce039181b14d2b12f609a518447025eb729dc',
    minters_reward: 0,
    lp_providers_reward: 1705.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1705.57,
  },
  {
    address: '0xf39eda37727a4240ab3a172950e54d7d55420633',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1700.8139865324308,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1700.81,
  },
  {
    address: '0xf68f4f3df51602a6909e22d37c94bd2ecc935012',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 342.6913607481927,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1694.58,
  },
  {
    address: '0x02517b50e8c3ad6f77e8d23a16b417489d41918d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1674.7502127433359,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1674.75,
  },
  {
    address: '0x515f12ba6a464523102a8c0ed5ed2099c77e7c74',
    minters_reward: 0,
    lp_providers_reward: 1659.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1659.9,
  },
  {
    address: '0xf56f0687dc5dfc7a5246f40e289b920c3ec24cef',
    minters_reward: 810.08,
    lp_providers_reward: 197.56,
    swapers_reward: 101.03447404309483,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1650.48,
  },
  {
    address: '0x9be83826afdf22a88027f8e5b79f428178bd9635',
    minters_reward: 0,
    lp_providers_reward: 1578.95,
    swapers_reward: 64.8126928045967,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1643.76,
  },
  {
    address: '0xf727bdef5fe302943cd6cba0c2131a332a458f02',
    minters_reward: 810.08,
    lp_providers_reward: 12.29,
    swapers_reward: 7.449369481679475,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1642.53,
  },
  {
    address: '0x280b015a37d3f315ebb1a7e2fc503f2f16162e0b',
    minters_reward: 0,
    lp_providers_reward: 1638.77,
    swapers_reward: 0.2689884456508537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1639.04,
  },
  {
    address: '0xEdE4C452478B2821869b4CD80621a65AaDfF068f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0xB2FF9CeA3A81cB1EdD5EB078BC966207085708E4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0x8E60E2844C6277FB88a76bDCB75eF6cC5f435c0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0x2D3CEc958A8862caa65C9573029eaBDE3Fa7bFC8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1625.43,
    hoaxs_reward: 0,
    total_rewards: 1625.43,
  },
  {
    address: '0x6C983F58674b7cAd6d81d67F2026656BA28e0871',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1622.79,
  },
  {
    address: '0x5E1Ab7EAd251122925751534d0DD839b51a6F609',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1622.79,
  },
  {
    address: '0xfFc44564A82f2FA44E900341E330f3c5692D6902',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1622.79,
  },
  {
    address: '0xfEe9c69dB5A73FD86426A62A27B7739c8E96fF40',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x50218e2EBC2e8233154C9a49aa9c0F980109981D',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0xF16b0Ec639a6AA31c7372122985100155c12E68D',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x8f03410896fB3C2a517206B04c9A3d0b0b5A83B3',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x7417FB40DFb97BF16AB1c32114D4586D3a832F3C',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x491cd10ABeB9697284705f2Cf93cAa7feaD45598',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0xfb54E5B023d73308f70e7CEbeaE093791750899E',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0xB2012aa647521EF624173403731f8ce8Fb6273A2',
    minters_reward: 1620.16,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1620.16,
  },
  {
    address: '0x8b2b9f4af4ae984894901ea61a3d436b1b8b47b3',
    minters_reward: 0,
    lp_providers_reward: 860.68,
    swapers_reward: 758.8003853501742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1619.48,
  },
  {
    address: '0x32c5e2cfcbe602c07f5fa2a8fb3d6b81b82ef06a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1614.8047359153024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1614.8,
  },
  {
    address: '0x9fb3520bd0bde83231d1723dab8c143cb1e1d178',
    minters_reward: 0,
    lp_providers_reward: 1203.4,
    swapers_reward: 410.71192958112135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1614.11,
  },
  {
    address: '0x3bd57b0f7bd94189d7bf8f0051b3d6e6191c073a',
    minters_reward: 810.08,
    lp_providers_reward: 124.02,
    swapers_reward: 136.56145828654184,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1612.47,
  },
  {
    address: '0x2f9994048ee63271f5beb337c902b74b93a0136a',
    minters_reward: 0,
    lp_providers_reward: 882.41,
    swapers_reward: 717.0631629366453,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1599.47,
  },
  {
    address: '0xca39cad98604338792d145218e109ba57dcaf5b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1596.1010580905072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1596.1,
  },
  {
    address: '0x2f4397662fd3cbd08271aa53ae9696fe2629d491',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1593.6079858322132,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1593.61,
  },
  {
    address: '0xd27b569617d47e83e587afdba45cbbb384f93ec0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 665.1801050578333,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1591.11,
  },
  {
    address: '0x9ba5aa36d74004a2988863a0242b200305d225c5',
    minters_reward: 0,
    lp_providers_reward: 1578.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1578.77,
  },
  {
    address: '0xe15f20f3e34daf19db195c6df295b1f1680a3e9c',
    minters_reward: 0,
    lp_providers_reward: 1572.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1572.49,
  },
  {
    address: '0x6ac526fcefb0306fa1d1b70b23d7218611636a10',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 219.9946159775487,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1571.88,
  },
  {
    address: '0x6363936c384d45689257384c06c9a7d6340bf6d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1570.7676318205918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1570.77,
  },
  {
    address: '0xd662bdaa93f1ff0bc6d9173c2042b943cee9cf0d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1161.2897583501156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1566.33,
  },
  {
    address: '0xd20d4241f3592dcc1c8715c9ff1d50fbf69e684b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1563.2744683144854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1563.27,
  },
  {
    address: '0xf758136491b5e2cfa0c574b0d0a8969abe59cece',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1284.8613971784791,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1555.76,
  },
  {
    address: '0x89a99e24209d1a8b82eea8ce0da83323f69cee59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1550.9696981685565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1550.97,
  },
  {
    address: '0xb60f94395b9798c884f5d35e0247b6deb7db1b34',
    minters_reward: 0,
    lp_providers_reward: 28.58,
    swapers_reward: 1521.9693035490936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1550.55,
  },
  {
    address: '0x41b0b01f4d82a2dfb8f14f349ebf3e43ae5a8543',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1549.4573635106392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1549.46,
  },
  {
    address: '0x25d94453d72e68dcf5592b52a5da15dd3c3a6bbe',
    minters_reward: 0,
    lp_providers_reward: 333.93,
    swapers_reward: 18.641371041353548,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1549.4,
  },
  {
    address: '0xecf863709cdc0d6092b39c0fd8bfaa3b016005f5',
    minters_reward: 0,
    lp_providers_reward: 1524.77,
    swapers_reward: 20.480898866961986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1545.25,
  },
  {
    address: '0xfd25d661e28f684e72c484dfe7cceb0af43a12f9',
    minters_reward: 0,
    lp_providers_reward: 251.95,
    swapers_reward: 1286.674527257051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1538.62,
  },
  {
    address: '0x97330028855659fa4b4e21298e4388876d1463ac',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 183.40060662461138,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1535.29,
  },
  {
    address: '0x31e5cd82de5bbddf053477530e7c0e00b6dfbae6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1530.5479977005903,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1530.55,
  },
  {
    address: '0x733fe79e57460a470e3926cf1c7f2bbf7b67e1e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1529.12045285787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1529.12,
  },
  {
    address: '0x84c5a3ad16ce2f2c18f1306ec65c7bbcd3c1f9ad',
    minters_reward: 0,
    lp_providers_reward: 1407.74,
    swapers_reward: 112.11084828445615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1519.85,
  },
  {
    address: '0x9da44337d17e026b417d51ca8663f303ecc0f52b',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 165.03132586608544,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1516.92,
  },
  {
    address: '0xa5c2a69538ab1199892fc18f7089e27fa438aa7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1514.7519223340296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1514.75,
  },
  {
    address: '0x5880ad155eddab6ea413f8c8b243c11f737573f9',
    minters_reward: 810.08,
    lp_providers_reward: 137.5,
    swapers_reward: 21.330001436625647,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1510.72,
  },
  {
    address: '0x3441dead1690134a75268b5055df9602e7fb6ce6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1508.7396767411344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1508.74,
  },
  {
    address: '0x05ecad2c639bf305acb2a55757d068aded1b0329',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1233.4496966835866,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1504.35,
  },
  {
    address: '0x667fd03a7c8f61954874cb1b67a6465f166d30f0',
    minters_reward: 0,
    lp_providers_reward: 992.52,
    swapers_reward: 511.26612606770135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1503.79,
  },
  {
    address: '0xfbc55d59133480c958e6d6cb5a0b9d35a40f160b',
    minters_reward: 0,
    lp_providers_reward: 139.03,
    swapers_reward: 436.68991094280636,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1501.65,
  },
  {
    address: '0x101664e7451fc83f7b56ab6f04c832ac07de78b5',
    minters_reward: 0,
    lp_providers_reward: 816.34,
    swapers_reward: 683.5897066450242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1499.93,
  },
  {
    address: '0x5e5ba519c7439f2a0a6a6c6902603c3673669c07',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 144.65458817477165,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1496.54,
  },
  {
    address: '0x7094072f97d04c0784ffb95677c52c605254152d',
    minters_reward: 810.08,
    lp_providers_reward: 93.54,
    swapers_reward: 43.962110858483655,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1489.39,
  },
  {
    address: '0x6d4f84d8779b0bff9d4ab187cf57014fa0727777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1488.0627066246884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1488.06,
  },
  {
    address: '0xb80Cdc02f00a73E9739Bf1419C0d4696604b794C',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1486.02,
  },
  {
    address: '0x5c61531863D28Db04Aa311cDC2673596656eb489',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1486.02,
  },
  {
    address: '0x0c1dafe341E8c13865C594255952EA924D98c5B2',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1486.02,
  },
  {
    address: '0x5ab0fd33350d2348a5f623c405f3f047e4058488',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1480.9200728511296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1480.92,
  },
  {
    address: '0x9e7cb9f87f008093227c60b0842a0723fbece98f',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 117.82294814978438,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1469.71,
  },
  {
    address: '0xe707cdf33ca67493f899c605075645b1321ca520',
    minters_reward: 810.08,
    lp_providers_reward: 61.96,
    swapers_reward: 55.42402731071224,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1469.27,
  },
  {
    address: '0x9D18FEF6314Eab60DeF6cbFAC071aFcce9a0b2D7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 925.93,
    total_rewards: 1467.74,
  },
  {
    address: '0xc65a883f0ee8de66968f07d1439c852d3b20d829',
    minters_reward: 0,
    lp_providers_reward: 1449.88,
    swapers_reward: 17.23719539375975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1467.12,
  },
  {
    address: '0x7d95d7b8c0d5e0225ac33a59276d51a729591ce7',
    minters_reward: 810.08,
    lp_providers_reward: 47.96,
    swapers_reward: 66.79131561155359,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1466.64,
  },
  {
    address: '0xa018f6ca170eef1469c53b345faeb94c81b3f95c',
    minters_reward: 0,
    lp_providers_reward: 1447.04,
    swapers_reward: 16.000559151722072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1463.04,
  },
  {
    address: '0xddae97c79bde1923fa644005f59578d502ed4dfb',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 110.98041292379008,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1462.87,
  },
  {
    address: '0xbbf5252d7b1b95b179fc073c29c5d7ff2a35655a',
    minters_reward: 405.04,
    lp_providers_reward: 95.61,
    swapers_reward: 690.8548112307744,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1462.4,
  },
  {
    address: '0x9ce7fb226d968bd947c28efbb3fe0bd1181eeb8a',
    minters_reward: 0,
    lp_providers_reward: 418.7,
    swapers_reward: 1042.5446135468878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1461.24,
  },
  {
    address: '0xcecf94d543134150f6f489fbd934fbdfecef18af',
    minters_reward: 0,
    lp_providers_reward: 955.48,
    swapers_reward: 502.4302942624519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1457.91,
  },
  {
    address: '0x4846d969f441d32e400b58eea494543570f96d4c',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 104.73991667292977,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1456.63,
  },
  {
    address: '0xc4f32536edf1995aac548b5674446e5c9c3665d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 637.8493056549032,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1450.56,
  },
  {
    address: '0x653c6c4859c69e5df0f86184f3203aeb2b3a3773',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 95.56844908634957,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1447.46,
  },
  {
    address: '0x283a84057399ac1dfa38fbf902e3350c2e7e64c0',
    minters_reward: 0,
    lp_providers_reward: 1406.42,
    swapers_reward: 37.307398492018024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1443.73,
  },
  {
    address: '0xcaf88361edd46a25d7cc09f3330321b8e4e8ad95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1442.985489382174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1442.99,
  },
  {
    address: '0xb2cfa150a56d3b8eee9f1e3e217ce21e2b132167',
    minters_reward: 405.04,
    lp_providers_reward: 764.89,
    swapers_reward: 1.3321951257533693,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1442.16,
  },
  {
    address: '0x867127f8bdc9b5b1091ca2a70fac3696492120c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1436.0778471563608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1436.08,
  },
  {
    address: '0x2bb129060eb397ef4ee53aa14ae386a62aa210a2',
    minters_reward: 810.08,
    lp_providers_reward: 80.74,
    swapers_reward: 2.9172274580247857,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1435.55,
  },
  {
    address: '0xc546b485ef243ed1d5db40c0da3c353b545de6b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1431.831329770101,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1431.83,
  },
  {
    address: '0xe853d53d9dd5b0266dda2b2475b638664669c64c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1430.8786705585298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1430.88,
  },
  {
    address: '0xb8b8d22515cf41c01049e7a67991ee09fc12f3bb',
    minters_reward: 0,
    lp_providers_reward: 413.61,
    swapers_reward: 1015.2203191043865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1428.83,
  },
  {
    address: '0x91a7a51470815da75e597923dc415ad84d01c671',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 74.13888087669254,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1426.03,
  },
  {
    address: '0x62a764df03e4d2c14f6938221e54b8fc3d6fe3ed',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 72.81541201309533,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1424.71,
  },
  {
    address: '0xbbad1747486ec253601f4ee23a1a7139fb15f8d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 497.7636733974561,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1423.69,
  },
  {
    address: '0xaefa9a2ad4a1bc31c92736d555009d996175d66e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 495.30960733490974,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1421.24,
  },
  {
    address: '0xe29f436db1dc49e3251dc80710de5983a6074c55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1415.2716322195354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1415.27,
  },
  {
    address: '0x21f0dfb065d1a8ed2cdbc684fee7cd1808d6be1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 489.1052222979589,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1415.04,
  },
  {
    address: '0xc7c633fa121dab5968d95ad7200e410fb8460cc9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 62.53266187275841,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1414.42,
  },
  {
    address: '0x31105bd518476cd0ba38e601625c417fa6d1fb47',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 198.02186440229073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1413.14,
  },
  {
    address: '0xb2e6a26240046f3b7126476b94130eea51cee65e',
    minters_reward: 810.08,
    lp_providers_reward: 5.04,
    swapers_reward: 56.02409951842945,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1412.95,
  },
  {
    address: '0xc271c1fbbb9db5870b986d4fb8686c65b01e21a6',
    minters_reward: 0,
    lp_providers_reward: 1064.14,
    swapers_reward: 344.14876218944875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1408.29,
  },
  {
    address: '0x7bb39a6f8b8d5c5be67174e16cd8b1b5441b8693',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1408.0428888414422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1408.04,
  },
  {
    address: '0x880b496e1a5dbc63162c30e8946c514df637c90e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 477.6043534670929,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1403.53,
  },
  {
    address: '0x2a91a261b8e9d916dd059708f248e60b94ef7a0e',
    minters_reward: 0,
    lp_providers_reward: 1321.59,
    swapers_reward: 77.47772077058812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1399.07,
  },
  {
    address: '0x14fadc70494734b1ac2436417f1b2654c206c41a',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 46.11935973588448,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1398.01,
  },
  {
    address: '0x9b7b7ae3f219f27785d7bd6686fbb77ca0385235',
    minters_reward: 0,
    lp_providers_reward: 1391.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1391.19,
  },
  {
    address: '0x0408002921be35823ae79783c18d39b1019185de',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 35.77578313808755,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1387.67,
  },
  {
    address: '0x3a098f56c1cae95b5b1b2e3e6e79b5821ed7386e',
    minters_reward: 810.08,
    lp_providers_reward: 20.03,
    swapers_reward: 13.795830528193305,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1385.72,
  },
  {
    address: '0x1a494ba32a54ec1657b3d0655b40d578dd5e40b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1379.5519550630895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1379.55,
  },
  {
    address: '0xbbf1c13a936293c801ddb6eedb7a47d1bb55a4d9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 27.65466842837663,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1379.54,
  },
  {
    address: '0xf74b27e2fd2af5739590798502c15ae1b18bb1a6',
    minters_reward: 810.08,
    lp_providers_reward: 26.37,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1378.26,
  },
  {
    address: '0xed4daaf5e86f6eddbd358266369a804563f434e1',
    minters_reward: 0,
    lp_providers_reward: 1376.8,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1376.8,
  },
  {
    address: '0x2b930738edbc7192ebdf547c9bafb915e3727ec0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 22.664807564421743,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1374.55,
  },
  {
    address: '0xc76d9ae13cafa270a83ca7fbb8d7221f3a3b2fd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1372.9635771537248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1372.96,
  },
  {
    address: '0xeb3cd99dd5f8a5488aaae9e6a3221b9be179de4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 443.4792434777165,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1369.41,
  },
  {
    address: '0xcf302aa6c760ef536f882998726509df606b522b',
    minters_reward: 0,
    lp_providers_reward: 1366.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1366.81,
  },
  {
    address: '0x106714adc33fadb9c4dd11eb0b97798d6e38ff69',
    minters_reward: 0,
    lp_providers_reward: 479.05,
    swapers_reward: 887.491770626359,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1366.54,
  },
  {
    address: '0x83a39f87819476d03a9623323438dc0fb178d608',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 14.294649427214436,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1366.18,
  },
  {
    address: '0xc0877993530258dbf4be8ec2d3e2afc6a48d2235',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 13.905354545383831,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1365.8,
  },
  {
    address: '0x1fca17c185277fa5fc096658f07138aeef68e952',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 13.867588677837803,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1365.76,
  },
  {
    address: '0x38030470d4e0f2f0da89e13a7ac4facfe71b01e7',
    minters_reward: 0,
    lp_providers_reward: 1356.06,
    swapers_reward: 5.735039787856228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1361.8,
  },
  {
    address: '0xc773e47707511481147a7bf2ec508f89a08cb9b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1359.7009497610218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1359.7,
  },
  {
    address: '0x2e4c405568c517421062d7525e8d896ce8e2f6b1',
    minters_reward: 0,
    lp_providers_reward: 67.37,
    swapers_reward: 1292.1287496693192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1359.5,
  },
  {
    address: '0xD51EB2EffcBa4122ca390aD16a7d0aba666e7D03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 1354.52,
  },
  {
    address: '0x099fa0D80F7d6eADD0C7fE5d0e27E33F641c3b98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1354.52,
    hoaxs_reward: 0,
    total_rewards: 1354.52,
  },
  {
    address: '0x21e8b39e42804945f1730050a07e4b425a4193f2',
    minters_reward: 810.08,
    lp_providers_reward: 1.62,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1353.51,
  },
  {
    address: '0xa102fb6cb2780edc6385d01acd8fa3d155461193',
    minters_reward: 0,
    lp_providers_reward: 153.8,
    swapers_reward: 2.692870049229977,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1353.32,
  },
  {
    address: '0x632d39503270670e2cd85794b78f9e094b12b272',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0.12535377020384897,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1352.02,
  },
  {
    address: '0x09086b82f5B6af82617e2943de764f0b5d2153BE',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xF7dA792597B4167158c6593a8b1717783c7d0DE0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x5CD64DDFD9F25CFD2C678eB9f54eDa1A69a0083e',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x4A08a585bAB0416eb4873e3682A5A6CDAe707ce0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xDA8681acE25181131C48b4705586bcf0c3b7A3D9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xf484701c70569984B33Dd7d5CA749291F51dE56d',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xc848b58c616146b8A37474F35F57a62111B3Dc8c',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x382B4d40034858956207498AAE03E088989c13A4',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x4FDeE0D34381b620d91B76A440e373a83f7c2e1B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x1184fA9A6f73A5789c0f0D0618C31E0Fe7ef459b',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x2f1782E63cD639298Df15Ac4FfEe7F5523D6C150',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x6dCF5927d14De19Dd948E1f6A6E8B96813fC6F37',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE5d16550c3Ab4fc21C35fa50BA58BdcD28Fd1dEa',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xe70115e55A3B609FF90945A9cad06c3813d661FC',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8CcA26F56c856878C39993Ff03ecCB577D47BD0A',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x2D21B7b9d93427c123909D8B79e420dBA27A530c',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7FdfeB7E9dc80CD2e7B5d21A77c8609a29b644b7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x51Ca0dF61aaF61cD7f33405395516E407B33b7dD',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xd079cf93aC0B9FB8732948030EC937F72D5a81F0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xEb67ffF9A0Ca472D57679504A3667B35eB9Fff17',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE50f4698977813dC5Df1FA4b9344CeD81086f78d',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xFCF35b5aa4159BabFf6c741CF140cA32c01CBC39',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xc5d32f74BC31B9881149f21439A148f27549C038',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7650C7e6fe529eEaf892aC552f51A05501774462',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xB173BdeED203582c409d7e6171dDCf9E5A54e259',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xA4195FC6651684545Dc18Ef7097B13A043A91592',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x159b136c2ABc83bFdF85956cBA9c3BB13651b42C',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7C9Cec7093695630EAAd197FAcd01f99032D5052',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xDf244FB6474536AC48E3836dad0468325c63862A',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x1c97b6F3D07f5c3ee22816bc231141bBFb3B47bc',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x3f043123198d5C7f1A6C58CD3d96dd6970dBD52B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xA897cE842f8347dFeFD9F0DADE08A2A5aC38523a',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xb253881d582071abeDBA53c58A9Ef8403d6409d7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE04D0f2D0BfDD39B75501720fe7e3168365233e5',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x3798F2Fc298241266d06e7A6240dFA5647971aFA',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x45ce08C2CA9092095241a14c083911b5ab226B5B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xC800316a38dEbd1a22f851B18267e46A52Fce117',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x6A74a76A95696709543407D60C143D5b0e04f57E',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xf6c87096aCAAcB524d8BD5DD0026218231179201',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xC6dA8506F1390aDC9F3cdc7D9A47eAd1F43fDB16',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xE9eAf4a560C229A44673645cD400a7897145DEba',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xb8c9305e84ea95E4b6787A74831D4D23494a0e35',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xDfA0D14432321B5160B07904ee7048aadE9A4E65',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x38B9Dd991a205D7cF2F7f47028539cf4718eb217',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x315685232Db9bD7c236780f0ba8C14052E4bFB93',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8C7BF092a8BD79ba99AD415AE8274E2D4E06d1f8',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x37306947f60D1E24F422842C185173fcbcaBAF83',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x548eAe65E7Cf3A37A5aC7C019d393b9438B548Db',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x909B70f2FE7A279EeC393f4C75e234deC2a30754',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xC1fcAc038d5805f45f057f8A8829B779A4772c7B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x86175DD67D96FA2e1Bf30B7230C83519d1BfdC85',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x1B49C12f300b89B1996108Cb51e6794314fF6db1',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x6Ed015F3E3981a5502FAc71dd6aece8178b08367',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x94e4AcDc2799Ccad0292AE2157D75e547C33dAe9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x68254e13066B13d35402BFBca4F73D1e97120b6c',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8106832dd1a5135DcA4c70cbd9B9CCb481FcA8f7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x7821803172A92163ACc1b95B45b10e0f21994DAB',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x01dE132EdF9deb2cBd4Ef6A5bd7E3b66d852B261',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8360F6F182FCb5d0E32F3e01FE54A32487a45785',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x03dAF590730C3323CFeA618439a0088a217eF735',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xCc486DC9d97CaF6152c965a820daa08d062065C8',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x757F75757a020d01aA2D55f74d664211DD657DF6',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x911Fe644658dE707916D84B504D7BfD317b987B9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x23417B682fB63A828dd1d6Ca24a9C16bB3432ECC',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x65fB2868Ff97272DEA91D2E901B4673f57223bd7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x01A5440db6Eb2d60E2221a84CdB90b1820216dC3',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x0A5a19bf94607657cb708394Fb981AFC754E2D93',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8C4d7d482780CCB799e2415F25b767250887e580',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x3aa50ebFA6cc0C532c6ff901f8C96C4F913add99',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x510E2fD691443FfA7237245934e0aa15918023B9',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xf16236B180E18DF4C4179B2010063f113D99A254',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0xda860733370d972D4825Fe36C93602AAa4fD2193',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1351.89,
  },
  {
    address: '0x8ef4014b4110c95f9fa5e1030ef143137be995f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1349.0190486054607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1349.02,
  },
  {
    address: '0xdf6172c72c2a6cf92d65fc0d46ba006986914ddf',
    minters_reward: 0,
    lp_providers_reward: 992.49,
    swapers_reward: 352.7922753507465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1345.28,
  },
  {
    address: '0x0a451489f8c4b59aa4c84d2f53ebf11e984d5c39',
    minters_reward: 0,
    lp_providers_reward: 813.58,
    swapers_reward: 257.7310707622224,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1342.21,
  },
  {
    address: '0xb9ad57e76227f37c167cacbfeb6a1f9803ac3d82',
    minters_reward: 0,
    lp_providers_reward: 267.44,
    swapers_reward: 1073.3695699757336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1340.81,
  },
  {
    address: '0x6c3138380d98c366a181f2b57785af8f96b46d70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 414.48587272013407,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1340.42,
  },
  {
    address: '0xfd70d777769f7b354b757a6e99bdc3bea69ead11',
    minters_reward: 0,
    lp_providers_reward: 176.82,
    swapers_reward: 1162.3973385972845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1339.22,
  },
  {
    address: '0x1749458360b1ab82f5491c19da16b3cb0ec8c594',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1337.8312627191888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1337.83,
  },
  {
    address: '0xb164423e1e26dfb5a25f38ffa57359b325ef8025',
    minters_reward: 0,
    lp_providers_reward: 1103.15,
    swapers_reward: 231.08787338606874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1334.24,
  },
  {
    address: '0x9866f56b785d38e67eefed374821d1d7ae9ef18a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1329.4023967309427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1329.4,
  },
  {
    address: '0x7cc941f730672908e859b7f94981c9d44d08f722',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1327.1046386174924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1327.1,
  },
  {
    address: '0x01c9627514bbdde7d332b17bf7e4a837bd43ef51',
    minters_reward: 0,
    lp_providers_reward: 532.35,
    swapers_reward: 522.742104422252,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1325.99,
  },
  {
    address: '0x672424390d004c33455e7a51a10374f34e636734',
    minters_reward: 0,
    lp_providers_reward: 1294.37,
    swapers_reward: 29.539378720449896,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1323.91,
  },
  {
    address: '0x0c597c0cc7f7daa52d2fcbfd8b6cb9ec1410a51a',
    minters_reward: 0,
    lp_providers_reward: 1320.3,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1320.3,
  },
  {
    address: '0xcd7dbace50a4d1bb4edecd098c04fc7cbb0d4d04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1311.123870410356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1311.12,
  },
  {
    address: '0x82ad12518e43f80834096a5b3f9623f3f0b64a32',
    minters_reward: 0,
    lp_providers_reward: 1307.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1307.07,
  },
  {
    address: '0xf358ce3134e5a318039914d04e1fd27faef5806c',
    minters_reward: 0,
    lp_providers_reward: 572.72,
    swapers_reward: 731.7417048727085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1304.46,
  },
  {
    address: '0xaea762e1927a2126880068c89144adad8a9a15a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1300.0746654623888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1300.07,
  },
  {
    address: '0xbe2cc75e5a27d497de0cb33aea74fec5563bdf6e',
    minters_reward: 0,
    lp_providers_reward: 465.47,
    swapers_reward: 831.8630947037237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1297.33,
  },
  {
    address: '0x2d790605d7cec5af244b08cf4c465513e145b7cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1292.7639371244404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1292.76,
  },
  {
    address: '0x74a83bca375b76222ca0bfc077e3aab0257462c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1291.6118318751703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1291.61,
  },
  {
    address: '0x14d37fe469ee37dd2cff713df7eddbae13fcde63',
    minters_reward: 0,
    lp_providers_reward: 1291.24,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1291.24,
  },
  {
    address: '0xe057e73c28f71d0634a0379a9e89086396d29148',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.58851334761741,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1278.42,
  },
  {
    address: '0x36771bd622d53498c29c0eab49b1b7fabc7c67b4',
    minters_reward: 0,
    lp_providers_reward: 1276.89,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1276.89,
  },
  {
    address: '0x956bccf2c2e08d5203c011fc06d81a0ce2641a93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1275.4381870680413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1275.44,
  },
  {
    address: '0xf7b5054c0b8b67e7b0f6454747d98452f736787d',
    minters_reward: 405.04,
    lp_providers_reward: 852.64,
    swapers_reward: 16.181059709572118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1273.86,
  },
  {
    address: '0xb329d753b9c9e2dbe6fcf2559569e87fd128933b',
    minters_reward: 0,
    lp_providers_reward: 1272.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1272.18,
  },
  {
    address: '0xaf59ca501dae42433da19f44885821a400483110',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1268.2978380417812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1268.3,
  },
  {
    address: '0x4f437bfa3542c76e7f96b9287d3883af2ac41cda',
    minters_reward: 0,
    lp_providers_reward: 978.84,
    swapers_reward: 286.078177913381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1264.92,
  },
  {
    address: '0xa6982180d45c07076238c87e7e5a29fd9251bec9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1263.5310211655456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1263.53,
  },
  {
    address: '0x9f783ed7f7fd4c268d7f2ed187c683b5210f6a8d',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 46.79960664114371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1261.92,
  },
  {
    address: '0xbe47f78b18584a69ccaee6228630c66c382516f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1259.7832317175237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1259.78,
  },
  {
    address: '0x89099a8bdeb074c02ce44fca1235dfd50a9c7675',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1257.9160314401122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1257.92,
  },
  {
    address: '0x1ec03c63a022241f40eee3a908a6540559329dbb',
    minters_reward: 0,
    lp_providers_reward: 687.82,
    swapers_reward: 569.4424016097576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1257.26,
  },
  {
    address: '0x7ad2cb81af992b0ec215bead9de9c8be5e35f24b',
    minters_reward: 0,
    lp_providers_reward: 984.72,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1255.62,
  },
  {
    address: '0xb52ffb2fc5da23e3f51eb1e9df1c76a314700af1',
    minters_reward: 0,
    lp_providers_reward: 1200.91,
    swapers_reward: 49.47681419861171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1250.39,
  },
  {
    address: '0xec092481a452e90da952508eb005f8064eea3ee4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1250.1494911041732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1250.15,
  },
  {
    address: '0xacea9f4f939fd0e3ef5966134a70a2e5b415f1b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 322.68452072573916,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1248.61,
  },
  {
    address: '0xe9b48d7c57b84d7b7c2ee2bf4092b90730f5e4f1',
    minters_reward: 0,
    lp_providers_reward: 81.27,
    swapers_reward: 1164.2661238620751,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1245.54,
  },
  {
    address: '0x33267f6af8edf9fcf11208195d8127e73c74a66f',
    minters_reward: 0,
    lp_providers_reward: 250.93,
    swapers_reward: 68.34359155753847,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1245.2,
  },
  {
    address: '0x4e8d25d7b6a0ee67ccda7417eee8309ad27e5fd1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1244.1087568936625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1244.11,
  },
  {
    address: '0xdd4612980ac565449c94adf542d8f74dcae543cc',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 28.39649306340267,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1243.52,
  },
  {
    address: '0x6fe57be471903b55b7dbd13329299531384bdf68',
    minters_reward: 0,
    lp_providers_reward: 1107.87,
    swapers_reward: 135.4445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1243.31,
  },
  {
    address: '0x9b0c3d34d0c3e841a720981b8839aca780dc1c58',
    minters_reward: 0,
    lp_providers_reward: 1242.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1242.42,
  },
  {
    address: '0x4d5b5c0ac5ddf8abb362efbb7196f30d41d75fdb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1240.565368636455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1240.57,
  },
  {
    address: '0x4a612bda56b0cf4aedd7f0a69c2882ca8bc02cd7',
    minters_reward: 405.04,
    lp_providers_reward: 2.68,
    swapers_reward: 17.110242568827026,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1237.54,
  },
  {
    address: '0x9509565921cf36877b75b60b243f98e25c5db22e',
    minters_reward: 0,
    lp_providers_reward: 35.2,
    swapers_reward: 657.4377985949861,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1234.45,
  },
  {
    address: '0x13ce66a3099132049dda8fa92d861a32225bff31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1232.0772841395355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1232.08,
  },
  {
    address: '0x4a33f85847e27ffe3b4604d07260f1a3a3643b08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1225.913951110973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1225.91,
  },
  {
    address: '0x5ab9cdb305e57491da476f18ce648821338aa3d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1220.3815624332522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1220.38,
  },
  {
    address: '0xaCE7538e64ac6451761ccFF471d5Aa976fe25725',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1217.75,
  },
  {
    address: '0x7Ea06865B2C157aa5e9777857e46d2e9ee78314D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1217.75,
  },
  {
    address: '0xac1e2Ce7095cbd0064759f1A19AC001bd005b84e',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x980d48f66A427CD9469C0921fB8333AabDf980B3',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xf853856620C9eB2e9a43F6585bC45DE6B2a24055',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xe79cf3912Ac84F099F64372F9A4007166fD4D293',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xCE6027b575744203558F9461A0DA212F1E189058',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xcf716f078A7a03264c6b7e3b60a771cd26F23DCf',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xC47F3B3447FBD374009E3245af8Cdf824380A549',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xbDC0956A83448Eb90A1dD2417D9154101DBd3314',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x8Fd434B82D13497C25b33F2E91eb19CC826e33c0',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x1B8364a3b4437d95A2856e6A5D0aEE5A185B7179',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x905c0E8BD5E3008f2bD48374A50CB65FE24Df55A',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xCA46e8Ed7eB1581C11cC705503EE3c73F6e3F7EB',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xa8c63c19C1eF6149340476eAA841a57A78cf9c1e',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0xb48E3AcE947973a55889F927A0118Dd2279df9E3',
    minters_reward: 1215.12,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1215.12,
  },
  {
    address: '0x206d19baaf59cdf548567718ea3cb4c3411b4c84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.397874753383855,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1212.23,
  },
  {
    address: '0xa2ead40e5366d520f6c72568ba4ecf7ac31ee9d6',
    minters_reward: 0,
    lp_providers_reward: 1006.48,
    swapers_reward: 203.550563079163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1210.03,
  },
  {
    address: '0x404d59092556b340c2ff6b7cdbc7129e53301dba',
    minters_reward: 0,
    lp_providers_reward: 1209.29,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1209.29,
  },
  {
    address: '0xf03d6ca15c55ce3f5ed62f2aaad6da09c1fafd82',
    minters_reward: 0,
    lp_providers_reward: 640.56,
    swapers_reward: 567.6025247564503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1208.16,
  },
  {
    address: '0xec0812afe1e26626f18bcbd5f4bd595f272c200b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1201.2654064021972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1201.27,
  },
  {
    address: '0xc21aa060f1576292ecbce713b9d1ac0808b91a2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1198.640917506923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1198.64,
  },
  {
    address: '0x5785bf3864c04fe724bd6b1b8ee5cfd1dd6bebdd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1196.9954359328149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1197,
  },
  {
    address: '0xf09c36cf186d50abcae1bfd9421423a2f2342c95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.022248360728958368,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1196.85,
  },
  {
    address: '0xAe26364c1b9984864af0C759576DED1c8f85bD04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1196.83,
  },
  {
    address: '0xc379596438998F917D5937B256EA833b3cCDbe6B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 925.93,
    total_rewards: 1196.83,
  },
  {
    address: '0x5ebdc38e13d51fb85015adf832ab771496f64391',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1192.4864340862387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1192.49,
  },
  {
    address: '0x08912cbff6a1491e1a64f5a7bdacbf77d279d830',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1190.1367666816338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1190.14,
  },
  {
    address: '0x754a5f0a76e005f77aace7f25e8c24dfc94536c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1184.0658452101156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1184.07,
  },
  {
    address: '0x49d002b74517bdb05ff218964645d439469acc01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1182.1462396558104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1182.15,
  },
  {
    address: '0x033ce511712a0b75dc46d7d80deebc939fae99a6',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 97.27202791770951,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1178.25,
  },
  {
    address: '0xe00334041f856ddbfa693895bdcbff54d5bff536',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1177.4313020469883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1177.43,
  },
  {
    address: '0xe94396191532b45c9fa27b085be8bf73e968a698',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.53818453587664,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1176.47,
  },
  {
    address: '0xd34efe13287503446dc7ebc2aac2d9515435ebb8',
    minters_reward: 0,
    lp_providers_reward: 200.75,
    swapers_reward: 161.02982622022208,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 1174.49,
  },
  {
    address: '0x1948a8679633fd36ae2deb3fbe6c8ce63e5a0366',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1169.9601313960038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1169.96,
  },
  {
    address: '0x00f8430e22793e5f6ee76de97db94ac601a810e2',
    minters_reward: 0,
    lp_providers_reward: 1168.37,
    swapers_reward: 0.11716376243220897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1168.49,
  },
  {
    address: '0x0e2ec742c343d006169fb59866caae50a113983f',
    minters_reward: 0,
    lp_providers_reward: 991.29,
    swapers_reward: 171.5665339562078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1162.86,
  },
  {
    address: '0x3a3a6a721ea1a58eff9763f022819e0cc0a0b911',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1162.256247817105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1162.26,
  },
  {
    address: '0xa458e7ce53fc2efa711a3738797985d852e9520d',
    minters_reward: 0,
    lp_providers_reward: 551,
    swapers_reward: 610.5478060162346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1161.55,
  },
  {
    address: '0x46038eeaff60c4a3e62ae39eaa1fafeaf99e6bd3',
    minters_reward: 0,
    lp_providers_reward: 353.52,
    swapers_reward: 259.5043780251056,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1154.83,
  },
  {
    address: '0xdc5bc0ccc7ffd05aece6231373844f3d86ccb114',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 608.5168219167625,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 1150.33,
  },
  {
    address: '0x95c648b967cdb0d2a2e94edb5ad0151e9e3fb7d8',
    minters_reward: 0,
    lp_providers_reward: 1149.7,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1149.7,
  },
  {
    address: '0x18ade2f07b0e4362bc93a980e92fea741c5e468e',
    minters_reward: 0,
    lp_providers_reward: 64.81,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1148.43,
  },
  {
    address: '0x689dcb6c727a18c60c42635f99c78bef67367ff0',
    minters_reward: 0,
    lp_providers_reward: 1148.06,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1148.06,
  },
  {
    address: '0x9cf1750d7fb783db8d1229476f83055c04d91e38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1143.375058538257,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1143.38,
  },
  {
    address: '0x02a4496afd423b2b36a34d7f6e918e71ca88898b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1139.2938519753066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1139.29,
  },
  {
    address: '0x19e20bf96360c4ce93896248121b64deac071702',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1138.9716434904549,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1138.97,
  },
  {
    address: '0x5f8b65ddc91c74ee169c7ebeb0603824ad09f39b',
    minters_reward: 405.04,
    lp_providers_reward: 102.94,
    swapers_reward: 357.24128343865084,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1136.12,
  },
  {
    address: '0x25a9401e0483956aaa678173f0c4dc0efd7d9afc',
    minters_reward: 0,
    lp_providers_reward: 134.26,
    swapers_reward: 75.3220862925766,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1135.51,
  },
  {
    address: '0xd3527934cc6bb4c0cb2c876a582af313c57b7d4f',
    minters_reward: 0,
    lp_providers_reward: 61.78,
    swapers_reward: 139.8112041337322,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1127.52,
  },
  {
    address: '0x1a3497d51d7ae5886d4073fea1a1936cc03f4803',
    minters_reward: 0,
    lp_providers_reward: 233.88,
    swapers_reward: 892.4934664303145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1126.37,
  },
  {
    address: '0xee18aee21136be4aba7f88ae4772ecf7d40de20a',
    minters_reward: 0,
    lp_providers_reward: 493.78,
    swapers_reward: 626.2453082918473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1120.03,
  },
  {
    address: '0x24a401fc934e1902061027acb271f7a33de6e453',
    minters_reward: 0,
    lp_providers_reward: 524.31,
    swapers_reward: 591.7132067791714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1116.02,
  },
  {
    address: '0x7de9728ebfd310a8a8cf627c0576ae970bbd8165',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1112.1217066214947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1112.12,
  },
  {
    address: '0x1863829ebdb673c5c2a5095b046f9ae624ca139b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1110.210391932976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1110.21,
  },
  {
    address: '0x2b8f61b570897412325a45f85f434d96f545aeaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1109.414219619293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1109.41,
  },
  {
    address: '0x730bbc6ad00eafb67e2695ddd96c7b6babe25266',
    minters_reward: 0,
    lp_providers_reward: 1108.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1108.73,
  },
  {
    address: '0x2f9a817b4c45936f1adc3bc954cd005418bed420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1108.455858727653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1108.46,
  },
  {
    address: '0xec4c15765c88090d6fd5f11251700fff6be12b4c',
    minters_reward: 0,
    lp_providers_reward: 690.89,
    swapers_reward: 415.73467888958885,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1106.62,
  },
  {
    address: '0x68f1c7005ce21131e02106645b4b24996b3b12ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1103.6132943990488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1103.61,
  },
  {
    address: '0x360607854c01f434362ebadb398c13906fc293aa',
    minters_reward: 0,
    lp_providers_reward: 1102.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1102.76,
  },
  {
    address: '0x76617e8bd106d8803f5d37a99ab83a2b787fb0fc',
    minters_reward: 405.04,
    lp_providers_reward: 375.2,
    swapers_reward: 48.650767232713825,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1099.79,
  },
  {
    address: '0x9a1cdf51831c7ae5234f94d3c07da7083ba3c4fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1098.4844086387104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1098.48,
  },
  {
    address: '0x29efeaedcc990e3ce1b02edd0560628162845eb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1091.4650479666466,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1091.47,
  },
  {
    address: '0x4e476c0602a8b2e97d5592cce6bfd0816d8eeea6',
    minters_reward: 0,
    lp_providers_reward: 794.01,
    swapers_reward: 296.5411672745664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1090.55,
  },
  {
    address: '0xd292109e1c72d34fb6a8c2514a4b30c86b07af0b',
    minters_reward: 0,
    lp_providers_reward: 1088.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1088.78,
  },
  {
    address: '0x299712f19baffc479805b7e5c181120b354a5d67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.259473959510402,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1087.88,
  },
  {
    address: '0x10fe21f989584b3a065bde150e1ef5a44116879c',
    minters_reward: 0,
    lp_providers_reward: 1045.92,
    swapers_reward: 40.58354118876518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1086.5,
  },
  {
    address: '0x6562369a978ad4633947cdc71df7b59d7dc6bb57',
    minters_reward: 0,
    lp_providers_reward: 507.52,
    swapers_reward: 578.8640875431366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1086.38,
  },
  {
    address: '0x104819a74ce1b62dAe64b9cF42217638759638A6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0xD771313365148cB61ed746Bd58829714b8A638ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x97db70d3301aBDE7Ac36BDCFAb513bF8F4C44A75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x846ce84839317AB4440f4D0AF129dD1943bFbD00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x73f48FA8a8f258826Cf8Ba3B3B883dbd65a4830a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x0089E2273D6AF5A7Befd785e0C744d493A6e11E1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 1083.62,
    hoaxs_reward: 0,
    total_rewards: 1083.62,
  },
  {
    address: '0x30A7c51564b73F5386e48E1d0c52744604BD875C',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1080.98,
  },
  {
    address: '0xf6589e10d2583f3d52fe6af0620bc5052a70272c',
    minters_reward: 0,
    lp_providers_reward: 856.55,
    swapers_reward: 224.27260575867675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1080.82,
  },
  {
    address: '0xd1dce76c9ec4743736db4df7011008b8a5a12640',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1080.7176399375817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1080.72,
  },
  {
    address: '0xef4bb99df1cd3f87e6f7b084adef80c0b0926d96',
    minters_reward: 0,
    lp_providers_reward: 80.91,
    swapers_reward: 999.2003258630307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1080.11,
  },
  {
    address: '0x0077bc388a22d264786483be9a6bad89ea94d21d',
    minters_reward: 0,
    lp_providers_reward: 1055.53,
    swapers_reward: 22.18970847284776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1077.72,
  },
  {
    address: '0xe4f8348f8d2942f8fb0c53ff680299c68be77795',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1077.6580991051185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1077.66,
  },
  {
    address: '0x2924f08742e6951455b2f5e625df5844da601bf2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1076.3425080719935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1076.34,
  },
  {
    address: '0x55be672b97f08e05ee9dcee7c216a929a9dde55c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1074.0590943839425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1074.06,
  },
  {
    address: '0xa714bac0905a1ecec18594cf78a59a8b6eede254',
    minters_reward: 0,
    lp_providers_reward: 1069.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1069.25,
  },
  {
    address: '0x8864935f43d38d4a513f300ceb41c2523c368966',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1068.3963808196766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1068.4,
  },
  {
    address: '0xc8b8975147fa3679f01aec2c02c9a8a97b58d6df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 792.9097397054815,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1063.81,
  },
  {
    address: '0x4f64524189f5d87b5b0468e5648692dadd396661',
    minters_reward: 0,
    lp_providers_reward: 196.14,
    swapers_reward: 866.9284604328127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1063.07,
  },
  {
    address: '0x9609415480650b28d6ce4389242f4eadcb4ac4a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1061.2279445928973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1061.23,
  },
  {
    address: '0x527c43ee99c162d4377907542c2cbad84741a823',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1059.6469189693235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1059.65,
  },
  {
    address: '0x25c0f883a30f291870c0af8e3be7fdb5e17b5c46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1058.66306540306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1058.66,
  },
  {
    address: '0xc45eb03dd2dfb266abd48fc56e38132aa3c00f98',
    minters_reward: 0,
    lp_providers_reward: 336.98,
    swapers_reward: 715.7569953827211,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1052.74,
  },
  {
    address: '0xebc46e505b928bfb09114dd0434fac9a23fc23a3',
    minters_reward: 0,
    lp_providers_reward: 1032.7,
    swapers_reward: 17.706444397441217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1050.41,
  },
  {
    address: '0xb5a2ceb2d46adbf09ad108be6305c45ef1330b5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1044.4511392056763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1044.45,
  },
  {
    address: '0x38f888ea34faed56de7e6366ad3c6870ba026633',
    minters_reward: 0,
    lp_providers_reward: 963.57,
    swapers_reward: 80.44282031546739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1044.01,
  },
  {
    address: '0x25e5f328e7675f5dc1d22296150c98d8d9cc1b15',
    minters_reward: 0,
    lp_providers_reward: 1043.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1043.9,
  },
  {
    address: '0x2f9e91b4993c769baaa91229a839604681e110ae',
    minters_reward: 405.04,
    lp_providers_reward: 53.82,
    swapers_reward: 312.4520564560953,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1042.21,
  },
  {
    address: '0x8f5c464e99aae0c1314e0ddbb9de626ae7a2512d',
    minters_reward: 0,
    lp_providers_reward: 1041,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1041,
  },
  {
    address: '0xbc7248b4bda899a516f348e7b6e2930d57931ce1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1039.4352761306127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1039.44,
  },
  {
    address: '0x2953adf0bac7e9e507e01541db0870056a2449e6',
    minters_reward: 0,
    lp_providers_reward: 1021.93,
    swapers_reward: 16.884255838067862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1038.81,
  },
  {
    address: '0x44b285d081caac83d83300e6cc3d864af1bf108f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 767.4344188922836,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1038.33,
  },
  {
    address: '0xaf9df16c45e28a2ad96699cc11b104c12897f625',
    minters_reward: 0,
    lp_providers_reward: 194.66,
    swapers_reward: 842.5860632650706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1037.25,
  },
  {
    address: '0x64f17da84f3f9305add04070ae5d2816b4c95727',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1034.3173604226686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1034.32,
  },
  {
    address: '0x91b29526b0d89760d7852a1be4207f961ec6dcd0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1030.120614328661,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1030.12,
  },
  {
    address: '0xec78a5c8cd9145e42189802679cbde8640f57679',
    minters_reward: 0,
    lp_providers_reward: 870.98,
    swapers_reward: 158.8939317200149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1029.87,
  },
  {
    address: '0x22dd1870778ed7c351609a05d4e9df42dc60201b',
    minters_reward: 0,
    lp_providers_reward: 322.41,
    swapers_reward: 707.4464105474005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1029.86,
  },
  {
    address: '0x508906f7358c75e5938f147f06bc938ea5704e73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.5522407454985,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1027.48,
  },
  {
    address: '0x526263f13b8894327264997dd7eab2471b25926b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1024.5693225828318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1024.57,
  },
  {
    address: '0x6c5c90b46048f71e9c0a8b231e5ddc9726ad7d1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1023.8687392755753,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1023.87,
  },
  {
    address: '0x3124a0e29bb77b2bac9eff8189c4e3f6882d2efb',
    minters_reward: 0,
    lp_providers_reward: 263.41,
    swapers_reward: 485.57631988379995,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1019.89,
  },
  {
    address: '0xa22c9199bc31db595562460be306e50b0906244c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1018.8478403671254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1018.85,
  },
  {
    address: '0x3be9aa1b2d19a48c4d58328ffb5137da45ff3dd8',
    minters_reward: 0,
    lp_providers_reward: 167.69,
    swapers_reward: 851.0138787941834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1018.7,
  },
  {
    address: '0xdd2dee340ab423fbd6069f1d34f0dcc31b4a1ae2',
    minters_reward: 0,
    lp_providers_reward: 91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 1016.93,
  },
  {
    address: '0xe9aa6073c8690361a70760179c7a1e2a2e6de8d9',
    minters_reward: 0,
    lp_providers_reward: 877.51,
    swapers_reward: 132.59397028213945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1010.1,
  },
  {
    address: '0xd9a8c19d4a1b34ecc4c49d4ebbb825288903d3d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1007.6334473316259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1007.63,
  },
  {
    address: '0x815800461b4106da899b895e4b61f0a116e7408a',
    minters_reward: 405.04,
    lp_providers_reward: 325.53,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 1001.47,
  },
  {
    address: '0xa18afd6913f600ea56f73c5776b9250c3511d323',
    minters_reward: 0,
    lp_providers_reward: 1000.67,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1000.67,
  },
  {
    address: '0xed710a9ff215623571ebdc9e50ee944da2736f2e',
    minters_reward: 0,
    lp_providers_reward: 998.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 998.39,
  },
  {
    address: '0xdb5209ff5bb6037af30b27d26be710cdf8821325',
    minters_reward: 0,
    lp_providers_reward: 591.92,
    swapers_reward: 403.1847980832833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 995.1,
  },
  {
    address: '0xc3439f8e9711db0398381acca6c42a7641904921',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 990.9645344627015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 990.96,
  },
  {
    address: '0x5e96b067719495190ca0a23bfb5efa3843263f53',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 308.70044414418885,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 984.64,
  },
  {
    address: '0x9a1e5e92761b6e8555f0799b52912475cb91260d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.3213546786252,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 983.22,
  },
  {
    address: '0x65a68741693c705ea557fb8e0b4806e3d78b9844',
    minters_reward: 0,
    lp_providers_reward: 438.22,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 980.03,
  },
  {
    address: '0xebae1c16fa9d286cd987b7f0f25f9f45e879e520',
    minters_reward: 0,
    lp_providers_reward: 979.02,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 979.02,
  },
  {
    address: '0x7e389171d8e54cd337423c41fd0a06a08d3a6f0e',
    minters_reward: 0,
    lp_providers_reward: 674.14,
    swapers_reward: 303.68240034251727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 977.82,
  },
  {
    address: '0x7defce30068f78be21020691fcd4e8f3a0001c55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 974.3570440335079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 974.36,
  },
  {
    address: '0x45880bdb90dddec7ba6205f09ce26376f0a15c8e',
    minters_reward: 0,
    lp_providers_reward: 466.1,
    swapers_reward: 506.565960027994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 972.67,
  },
  {
    address: '0x4d2a94eab88adb86d15d997427f9f8476ceaa3a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.908638114733705,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 971.84,
  },
  {
    address: '0x5aac0add617f759e2009301e08fd4c558cd560c8',
    minters_reward: 0,
    lp_providers_reward: 704,
    swapers_reward: 267.723119671892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 971.72,
  },
  {
    address: '0x0baff65719b8c7014f15968b0ad6308c1ebf707e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 971.6327878050113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 971.63,
  },
  {
    address: '0x1d901545299ea95c06fbdfc3eba40419d77ab2b6',
    minters_reward: 0,
    lp_providers_reward: 745.56,
    swapers_reward: 225.59226432830866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 971.15,
  },
  {
    address: '0xb6e92d077591b064f48ef68e2074d6fa4a0c2cc6',
    minters_reward: 0,
    lp_providers_reward: 58.34,
    swapers_reward: 641.4968013635153,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 970.74,
  },
  {
    address: '0x131beb5521112346486537280d2f2804f2018476',
    minters_reward: 0,
    lp_providers_reward: 119.27,
    swapers_reward: 849.6227949762321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 968.89,
  },
  {
    address: '0x8f7a57aa1dd18d32201bb153079657c0fc3bc7b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 966.5497008387989,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 966.55,
  },
  {
    address: '0x0a281387b85cf32c709d427b237a55ac695c6e64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 963.1437210375329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 963.14,
  },
  {
    address: '0x4f8c4c203c17cb46c4df960d7802c9b2a09b36cb',
    minters_reward: 0,
    lp_providers_reward: 410.87,
    swapers_reward: 280.90723886046425,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 962.68,
  },
  {
    address: '0x6e629b824024759924a26c666845a9bd573ba1de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 961.8917939823644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 961.89,
  },
  {
    address: '0x0598e8bd32b95b29c390f84497458ec758d879d6',
    minters_reward: 0,
    lp_providers_reward: 513.78,
    swapers_reward: 447.8233555961074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 961.6,
  },
  {
    address: '0x2a636e33807d46461f59bdbfc38e365f0d80027f',
    minters_reward: 0,
    lp_providers_reward: 3.11,
    swapers_reward: 955.9819864210244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 959.09,
  },
  {
    address: '0x463f438c5c9ca9dd2dd9abea0ae6dc2e86e32058',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.52656855315759,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 958.46,
  },
  {
    address: '0xee336adb8341f600385db80d6a3af53efa72e6d4',
    minters_reward: 0,
    lp_providers_reward: 762.76,
    swapers_reward: 194.55263159833405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 957.31,
  },
  {
    address: '0xa8288f0dd028639875db739ca3f6b60ce26036da',
    minters_reward: 0,
    lp_providers_reward: 870.77,
    swapers_reward: 85.99610684816238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 956.77,
  },
  {
    address: '0x379f2332a198af10d357e9737a795768b297d3d1',
    minters_reward: 0,
    lp_providers_reward: 806.24,
    swapers_reward: 149.75316935738888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 955.99,
  },
  {
    address: '0xeb9e7b9aa7b51001be4917c79ccaa9502b85a00c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 8.540341548352817,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 955.39,
  },
  {
    address: '0xd062c2aed5ff8a416816324619e072bed4bb4f2f',
    minters_reward: 0,
    lp_providers_reward: 87.43,
    swapers_reward: 867.6590601940155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 955.09,
  },
  {
    address: '0xfdff57bfd8d188e05116ec58a6827b490e29dccb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.925272470212885,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 954.86,
  },
  {
    address: '0xabc12db87571036629e32853e4281c7a9906b309',
    minters_reward: 0,
    lp_providers_reward: 22.6,
    swapers_reward: 6.285628100201823,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 954.82,
  },
  {
    address: '0x063d99dd0dddc863fda82368988a11e1ff173282',
    minters_reward: 0,
    lp_providers_reward: 26.54,
    swapers_reward: 1.4480640823909041,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 953.92,
  },
  {
    address: '0xce08f30183a6718fd611d854218843a46dc09916',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 5.8665230927346315,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 952.72,
  },
  {
    address: '0x6330a2de4f89d851b424908c5f19f9e546c76e2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 951.8156319534372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 951.82,
  },
  {
    address: '0xc23f8417a9d1847a6322f5251f10a817f4afb9cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 950.3411787261481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 950.34,
  },
  {
    address: '0x2F84A6483B82b32d4739eb07407d45a6358c0aE6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 946.85,
  },
  {
    address: '0xc71b9c97a27fb2cd0d98d449e75a63cba312466d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 945.5398219366602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 945.54,
  },
  {
    address: '0x403de1fb3d8efbdd368e41ecd1a2fbb0005d4b79',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 941.0084860873346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 941.01,
  },
  {
    address: '0xcd79d915aa11d10298e87590e5025e7d84ca4bbe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 936.5411206329908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 936.54,
  },
  {
    address: '0xa46bc32d2e264af53f12902fb1a31b32e969f93f',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 125.29220757400404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 935.37,
  },
  {
    address: '0xca1eff5bced8327ca3b32e64d6e3a724a1743675',
    minters_reward: 0,
    lp_providers_reward: 817.27,
    swapers_reward: 116.99098212212994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 934.26,
  },
  {
    address: '0x62b280c2f99924918cf72b3dcd6dd6141183cb7c',
    minters_reward: 0,
    lp_providers_reward: 930.16,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 930.16,
  },
  {
    address: '0x5cd5ec5734850ee78d5ca7663d05e2a8989e529f',
    minters_reward: 0,
    lp_providers_reward: 922.44,
    swapers_reward: 6.854027058515561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 929.29,
  },
  {
    address: '0x9e33c04f049ee53dbadd61f3eb19761141be5140',
    minters_reward: 0,
    lp_providers_reward: 0.04,
    swapers_reward: 657.5192167973502,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 928.46,
  },
  {
    address: '0xb2c1efd7a113db46aff345bbdb64f13ef45c1507',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 927.2613613464472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 927.26,
  },
  {
    address: '0xd91a3a9e976677f7757560a619af486e195a528e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 926.4405906733462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 926.44,
  },
  {
    address: '0x296c89ccf9397b4d9538f1b57f9962b4a174ce92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06847950705888828,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 926,
  },
  {
    address: '0x1C0bE90ADC787d6920503Ec0339f635B92898445',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x911317ad74EF53A0FF4392A42Def888F120b4281',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x0619D378d2FA4400f416CAD586DC173F0694e4b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x30E832FdeB09d88FEd4E84b68a58F181CEa4AdF9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x71a1215486C24fDb4078bdeBcBAFE9a3f371b059',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xc634bA7E7f831247Ff801db3c7932c35620ecf78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x26ee3073Cd3D3bfe756CbC24758843D1ceA17331',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xb6B8824E702897f763829ac54EA52974c0a91CFb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x19E42891B437edFB2cb2a0D3Df247Ed0e0d36142',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x46e6cEa9Ba3b65C64F95A3F8De47A1B4c6667909',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xA28fbe2838e7B88fC134DFbdee11e705c90279eC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xB15e1fc18AD797e0ff365C44bf6DA72F1dbFb372',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x8F096CE48FE24Cd448E9B6d17CB187A3e5431418',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x2753882f6a60d12641781B056E0d861f28629e8F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xaB014280DEF32938d8C2A1fF22fb31c1aE9ecCc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x16D67609F4cd7D6b388778fD0881D86622463dFB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x4D5908064EE2862B9B1C8b3676107b914BA57cEA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x67f8Bf2c521Af007328B74C8ae7416badc512357',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x81B09954C58901fd885a74239409a0F86973DCa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x44C1f4990c28Fded152454e35EA4c1C912bBd343',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x9DE911D489e0701D01f55536757dC730665eD8D5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x3D67F4Ea34F70aD862EE64310e99b3cdaaB41Ef3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x6582b7C80dF319553a988F62436b8A1be6b2B24C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xef275666f19183d0817D860147F44995152E08dA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x4A03d9852CAd039BE7ee33cBbECA5Db868Caf06A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xCA68193e07DA540a36B9d81fe19670029a50bcdA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x801A89edaB69d7264A30C746e82AE06cAff877fE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xBc2AC17aBd69F598896c83705126BA549b5Cc8Be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xA8dE85b9282Cff30d5C4cDfe3dF80315Eea45301',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x6962d791a0a6Df1B9C8759D9751544C99a621f05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x44ab2D6310fe054A0b43E9bc3CEd6f88c66582fB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xf685e39152a2bb62719Ba0c5E8685FDa776362A8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xb62fe1E5520f10396B09732CA04B8Ffc5fFa4020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xe7bd7e5f4A598716e2AE5F3cDc4C62dD5ba250d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x28fBa57B0b257019bc9f4ad2707ec19e724AC6C9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0x3C77428E76872D392Cc104F24De869158Dc69A98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xF0315EF0C714bEfe01C01A8785af94D1273BA6d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 925.93,
    total_rewards: 925.93,
  },
  {
    address: '0xa8afc6bdb6918991e9d1239a10db63e497c39353',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 923.4995185361363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 923.5,
  },
  {
    address: '0xfa22325f59c7c5152c0c3d2fa2028920cb65472a',
    minters_reward: 0,
    lp_providers_reward: 470.34,
    swapers_reward: 179.38561462537973,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 920.63,
  },
  {
    address: '0x8a2ffdd15dbb40cac30d9567b3d5c58fc198cb5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 911.4367625063816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 911.44,
  },
  {
    address: '0x2806ac1f682bf529b52c078f89deb4e029090756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 910.1018216058108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 910.1,
  },
  {
    address: '0x054f9062d8e9b1f52bc5e351d083dec8a521ff35',
    minters_reward: 0,
    lp_providers_reward: 265.84,
    swapers_reward: 637.9408465001161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 903.78,
  },
  {
    address: '0x0cde5644d88eb4bb1cd58602237b73eabd0a7a9b',
    minters_reward: 0,
    lp_providers_reward: 902.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 902.97,
  },
  {
    address: '0x30f10b84389c08dd370a0769bb447051bdc581ad',
    minters_reward: 810.08,
    lp_providers_reward: 84.57,
    swapers_reward: 7.147324713607218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 901.8,
  },
  {
    address: '0xfe2cdaea682699352d8f25e0e08207896551be53',
    minters_reward: 0,
    lp_providers_reward: 901.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 901.35,
  },
  {
    address: '0x02c7767b63ab793f66740d41004b36a4a8aae399',
    minters_reward: 0,
    lp_providers_reward: 898.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 898.55,
  },
  {
    address: '0x21c9a14c2d79327f8a90f6b71e66d9b4f01665d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 896.859475334305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 896.86,
  },
  {
    address: '0xfeb6eda97a7407abb0ad42dd26d9ddc7670d8391',
    minters_reward: 0,
    lp_providers_reward: 659.31,
    swapers_reward: 234.12772996157625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 893.44,
  },
  {
    address: '0x9ddd573b5510a1fc96b77281512c10e079feb23c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 892.0134364523415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 892.01,
  },
  {
    address: '0x695968b7b43e8cf9483d4bc2a6017892aef3cea3',
    minters_reward: 0,
    lp_providers_reward: 591.03,
    swapers_reward: 29.928695713882323,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 891.86,
  },
  {
    address: '0x932cecb858c5e7c0ca50164e5d9761e85fbfb44e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 890.3635804352883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 890.36,
  },
  {
    address: '0x59ad85d51ce6f1a82239c6f83ea8a44f915e9179',
    minters_reward: 405.04,
    lp_providers_reward: 27.3,
    swapers_reward: 186.43481932104808,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 889.67,
  },
  {
    address: '0x281f26955e457480d4d7645dda572b3df786531b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 888.1840129717157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 888.18,
  },
  {
    address: '0xc1a0d1a66a495aa83da81092e7abc86076809bb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 886.4912219863529,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 886.49,
  },
  {
    address: '0xf1672308647459f62af7e4cf3fabe48c09e872db',
    minters_reward: 0,
    lp_providers_reward: 872.32,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 886.03,
  },
  {
    address: '0x5b3427b93b0122e1f2fc69793ccf0ac52ce9a3bc',
    minters_reward: 810.08,
    lp_providers_reward: 46.89,
    swapers_reward: 28.561174226063873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 885.53,
  },
  {
    address: '0xc7cccf4f9f46b32c439aa4d39b86a4b061cd8333',
    minters_reward: 0,
    lp_providers_reward: 785.49,
    swapers_reward: 95.873980861643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 881.36,
  },
  {
    address: '0xdb1b978bb967ccb964fb0963a6a46c6f31ac79aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.26060175903643,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 875.07,
  },
  {
    address: '0x70cbafca3c3d14380d27bb90c1c2104fda3e06b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 871.0058096713476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 871.01,
  },
  {
    address: '0xf8540110bbdd77e9a056a784b424ac1ef6dea66c',
    minters_reward: 0,
    lp_providers_reward: 167.96,
    swapers_reward: 700.0365819326588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 868,
  },
  {
    address: '0x7b276f5997a8bd45b38dea932c839bb34c1f55de',
    minters_reward: 0,
    lp_providers_reward: 569.11,
    swapers_reward: 296.03098163421214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 865.14,
  },
  {
    address: '0x7fe65fa57fefca3ad0bf8c879e32ef604647f369',
    minters_reward: 0,
    lp_providers_reward: 796.25,
    swapers_reward: 68.5139830206775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 864.76,
  },
  {
    address: '0xb72fc84e8ff88b1bf3ad37273d8616b60c0a24e0',
    minters_reward: 0,
    lp_providers_reward: 862.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 862.98,
  },
  {
    address: '0x232694f42b13875881aa17ffa23add31631e728f',
    minters_reward: 0,
    lp_providers_reward: 843.44,
    swapers_reward: 16.13293850502024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 859.57,
  },
  {
    address: '0x0c40f93af171bb9ccd5d30a4e5a70c52f0e287ee',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 181.63770210755075,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 857.58,
  },
  {
    address: '0xf848d2ded87e7a1e5fe4dca043c0fdacbdef6bf3',
    minters_reward: 0,
    lp_providers_reward: 335.92,
    swapers_reward: 521.1145195796902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 857.03,
  },
  {
    address: '0xa48b270aa26481733668fef373c9104efa19c0f5',
    minters_reward: 0,
    lp_providers_reward: 336.88,
    swapers_reward: 518.9971337431605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 855.88,
  },
  {
    address: '0x097e9917e1f767617246e2654f60c1181456e69b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 852.7603711645557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 852.76,
  },
  {
    address: '0x1cc79e3869d447dff713eed92e2a802a4f83e93b',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 40.31180637340565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 850.39,
  },
  {
    address: '0x691f714e6468b6599c2e69e8fdf9df6415aa13b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 848.6219764103204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 848.62,
  },
  {
    address: '0x70e750d18f53a961001883ffe3bc1d9351af8774',
    minters_reward: 0,
    lp_providers_reward: 848.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 848.34,
  },
  {
    address: '0x02126616a46a349a0bc9c0694e478a9445375a53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 843.8335970841281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 843.83,
  },
  {
    address: '0x92b7ca6982d1d8b5b335cf98cc2552dda0a1205c',
    minters_reward: 0,
    lp_providers_reward: 159.51,
    swapers_reward: 684.0535738075523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 843.56,
  },
  {
    address: '0x7caee6616fd03486c5e4662059f0eeff663f7840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 841.9921684834522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 841.99,
  },
  {
    address: '0xcbf98866183ab7a7923d3bcd1c956aecb606afa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 840.5982981189163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 840.6,
  },
  {
    address: '0xea49df790cfc84b31a43c5c6a97e70e4d32e264f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 839.0715793210305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 839.07,
  },
  {
    address: '0xed1ecff1a8c24262abbeaeb9e472feedf60db6a2',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 28.69132497919336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 838.77,
  },
  {
    address: '0xe49c21b809c6122d662a6ffe0db02548a53fb224',
    minters_reward: 0,
    lp_providers_reward: 838.22,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 838.22,
  },
  {
    address: '0x31b8f9a85fa9b9258b5b5f1875dbd863999dca76',
    minters_reward: 0,
    lp_providers_reward: 153.43,
    swapers_reward: 683.5202788115686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.95,
  },
  {
    address: '0x5348c39803e67377546908e0867ec31893fcdb34',
    minters_reward: 0,
    lp_providers_reward: 836.6,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.6,
  },
  {
    address: '0x91d01ccb5ab7221ce23c386d74bb7b1d09e28d1d',
    minters_reward: 0,
    lp_providers_reward: 636.23,
    swapers_reward: 200.29606256722238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.53,
  },
  {
    address: '0xc2245a6b067abdea1b4b8a389de6d202370d1fa2',
    minters_reward: 0,
    lp_providers_reward: 635.8,
    swapers_reward: 200.57490142382525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.37,
  },
  {
    address: '0x3eefe846aa0b85ca6fd9dd7f124d2a71e6525cc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 836.0930553102079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 836.09,
  },
  {
    address: '0x53397b3edf74a1876087fa436edc42534138d8de',
    minters_reward: 405.04,
    lp_providers_reward: 143.52,
    swapers_reward: 16.234974762701633,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 835.69,
  },
  {
    address: '0xb7025fbfb77ec6ea7764161e6b274f19b3af22ab',
    minters_reward: 0,
    lp_providers_reward: 580.55,
    swapers_reward: 254.81385222300966,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 835.36,
  },
  {
    address: '0x51b9eba64aeefa0df99ed9092751cc083528250e',
    minters_reward: 0,
    lp_providers_reward: 592.42,
    swapers_reward: 242.58759918900958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 835.01,
  },
  {
    address: '0xb65270f4c1b1af0bb11829bc6bca7ac740744619',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.505801974629215,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 834.22,
  },
  {
    address: '0xb9531b2243c0055121337838fe6c057107a61d59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 833.9881902073076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 833.99,
  },
  {
    address: '0x8e6fec5bc2451870c0a09813b0b7d11d9eca2e70',
    minters_reward: 0,
    lp_providers_reward: 602.78,
    swapers_reward: 229.07074887381253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 831.85,
  },
  {
    address: '0x7ef0500d7b663aa396b1ee7609fc768799f0bda8',
    minters_reward: 0,
    lp_providers_reward: 831.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 831.42,
  },
  {
    address: '0x98366d84e4323ba6bfe73d07c8bd02241dca80f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 831.1387389349806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 831.14,
  },
  {
    address: '0x4a4bf7046c9782f46d6a7697fafdc4acf5767709',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 20.615949024249883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 830.7,
  },
  {
    address: '0x82a8e300c5bc6a6e7918d3e18b344dab715e2a8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 829.884740688572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 829.88,
  },
  {
    address: '0xc0d0a509926ccd9f167c5b7fa8272a47cf3215ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 825.7784235052844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 825.78,
  },
  {
    address: '0x69c988bfaaf42d83d7be0b7c82e1fcd21c5c776b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 824.0714022502245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 824.07,
  },
  {
    address: '0xe1dc8450e71d2317150a6103a24698c0ce00dd2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.914911712760166,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 820.62,
  },
  {
    address: '0xdb7ba05196b052bd754e2a6fa67efe05631ee8c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 820.2673469725703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 820.27,
  },
  {
    address: '0x7709faca08b6bd254c540f74fe6174b01cbc5321',
    minters_reward: 810.08,
    lp_providers_reward: 0.98,
    swapers_reward: 8.565480483185866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 819.63,
  },
  {
    address: '0x513e518406f5733ed42723e1524b3bfceda71064',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 818.8413880555952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 818.84,
  },
  {
    address: '0xe7c3a759083fcb1bfbe920b001c0a07751aa49a8',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 4.588030740918899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 814.67,
  },
  {
    address: '0x513561d5aecd5666d70ed011e043006627ac3200',
    minters_reward: 0,
    lp_providers_reward: 287.53,
    swapers_reward: 526.0725183521153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 813.6,
  },
  {
    address: '0x1B4305D6a02E09E4EA6AC43E1CDf95B0c336B77C',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x71eF8b9F1A234194EdA7165eDe366f45f33D469b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x121f49454216d1746cadd24910605BCc18dACEC5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x1b70f7ca3b3342cdcc45aa2D9233143f0279FE87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xA6296C3A9a066ba70F9d0F7d69C77eF22C7d26D8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x8f36b72bAD8F621757b4A870E7385A19D985aD0B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x6524e16af07e601FA3028787baa95bB589feD25b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xb4F387cc8B5aE416d42f854367e1343d95f9d359',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x4FeD9A7EaC6e8A2a4CFA5758502D85021Bea1ba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xFE1C6127C6C8d1aCDA1e883e6A146CDd075F6491',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x4D39083a277c4908844dfDE44a368BA4d26BeDaF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x3329219a9E9A64c564a959dEaA013F47B3947ccB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xC64C31394Dd31eC600bed0D0cFD09036Ed500f48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x98B8d32D3AfFC75436Fb535252Ea1FF763458019',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0x43aFDDCcb8088Ba252f1552964ef7F2adcA0e2cE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xd6897b3BBbdf00DAdA3A5CEEAf09B193b41a526e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 812.71,
    hoaxs_reward: 0,
    total_rewards: 812.71,
  },
  {
    address: '0xa267d3d317096fa6cceaeba190cb3ff6cd082cc9',
    minters_reward: 0,
    lp_providers_reward: 811.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 811.92,
  },
  {
    address: '0x5e9a1ee447314ce02c4348c90c33f0e3bafc5ab0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 1.7234456831901677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 811.8,
  },
  {
    address: '0x81d51e5af90c882ee6f88fbc9464d6ed123bea70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 810.639909755851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.64,
  },
  {
    address: '0x3d426728e82ac282653a9779b988aca414b62ac8',
    minters_reward: 0,
    lp_providers_reward: 736.48,
    swapers_reward: 73.68480179207478,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.16,
  },
  {
    address: '0xebAFD9B5183859884E2C5b99e8D934c70a71B63e',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x8356C59f9d01570a3875556b9C63E9B3c9B2d3dC',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x55F1D12A9De414c6eb62511756Ef08f82C1a33Bd',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x7BC41C0718197eC1C78709e708240eB262F50005',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xCe2628Bf21389183a0E9270DA13cA6B8D669b6B5',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x8B9a2CbF4E301c99bD513BBa7c622AFDED972c6e',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xcAf056F1382a1909A0c058EED164e150713B06E4',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xcF8fAeaCd63f606dEaD28d0499c7CAB379803a57',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x7fbFd5527C99C079DFCE04D9b7a50F9878853986',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x1be084D17aa360CBA1fAa357a023C90ddCda103B',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xbB8A7a7761C75EacfD0C3f4E0e6807582A93c270',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x31884bcF23b59575d81E1FF535aD0fbb1a9AD508',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x53382DBF6d60bd60c40c8C880B335075d41D0557',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x535dE601a341228aAA78F3c57Db1d29b7146207E',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x3B6BA6D745C37E45a7c89BD32250D4517583bE97',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x65E67F4b986902905fcf8Cc89acCF1441bC1193a',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xf8534Cb39a2479f71cb18327bA8080d9451148C7',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x944FDF30EBA69d6A7bbFd7512AC8eF899D1Fd3F0',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x18e6c344eedB55E56f0281d6E35Be6C1aB57EeC4',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xB37bb503C39F1657e501F3a7B725350E226a1c4d',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xD226b4c30Bcab63b5F6147CD13243b1bE707865b',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0x0019E3a60cdbBb49c1c01c6507fe565AF176da59',
    minters_reward: 810.08,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 810.08,
  },
  {
    address: '0xa24d0e2688ad9344c4413d55768e84904abacc3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 806.2595855452714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 806.26,
  },
  {
    address: '0xa5f207f0cd4a665c82443c07033a03f1f5e9500e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 806.2450616709114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 806.25,
  },
  {
    address: '0xe7317216e893f1bbc214df9fcac49b9caffaee99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 801.1453704343467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 801.15,
  },
  {
    address: '0xb13e086d5f328e38237cc0170ae4323635572fa5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 800.7613274156765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 800.76,
  },
  {
    address: '0x84b4f95c32af5a3382683234fbd03759d6b8b4ab',
    minters_reward: 0,
    lp_providers_reward: 800.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 800.32,
  },
  {
    address: '0x90fac5b6b2c5bdc1c094041a00a647cd7c14daaf',
    minters_reward: 0,
    lp_providers_reward: 656.13,
    swapers_reward: 143.23142708565962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 799.36,
  },
  {
    address: '0x989c6b3a2406d3ee8f85994c7f2b9478e3448359',
    minters_reward: 405.04,
    lp_providers_reward: 394,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 799.04,
  },
  {
    address: '0x3cff508ece5b14463c24bc1efb0737ece1a1d534',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 798.7903543738698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 798.79,
  },
  {
    address: '0x7691ad52e974b9663a75b11d2b80c69ce60a2bcd',
    minters_reward: 0,
    lp_providers_reward: 797.29,
    swapers_reward: 1.3173832924770525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 798.61,
  },
  {
    address: '0x73ebe702b4fb5d9c28cf035d7f3ee28bf10c4a9a',
    minters_reward: 0,
    lp_providers_reward: 754.83,
    swapers_reward: 43.552511716095715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 798.38,
  },
  {
    address: '0x38c1649b72fd245e434b9db29f6148a90aa7ca12',
    minters_reward: 0,
    lp_providers_reward: 797.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 797.71,
  },
  {
    address: '0xc696065e2e24c62d4d62122b80af7a6779b74ee3',
    minters_reward: 0,
    lp_providers_reward: 298.78,
    swapers_reward: 497.33509973346395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 796.12,
  },
  {
    address: '0x12b2850c7154115c8b70c2f345ea951cb107a02d',
    minters_reward: 0,
    lp_providers_reward: 781.81,
    swapers_reward: 11.697836011910011,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 793.51,
  },
  {
    address: '0xb5178acf39a06839d9277dfe5b8fa94d7f492b3d',
    minters_reward: 0,
    lp_providers_reward: 198.61,
    swapers_reward: 321.4749132930815,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 790.98,
  },
  {
    address: '0x15cf4b9377e7e11032d9ce3402db9dcc700ea7c9',
    minters_reward: 0,
    lp_providers_reward: 790.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 790.1,
  },
  {
    address: '0x1a61c7e5c30ae1b5db61fc174bf8a3108632fef2',
    minters_reward: 0,
    lp_providers_reward: 401.53,
    swapers_reward: 387.989469075828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 789.52,
  },
  {
    address: '0xf829b907e05820a111954899f61702bedcc31397',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 789.4632050053856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 789.46,
  },
  {
    address: '0x26d6b5e0f2f7e21ce4822324f2a749fc41ea89a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 788.750810225279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 788.75,
  },
  {
    address: '0x01064a1606d13921d4f31f9c6b728d0ae1eeacd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 788.3005195082361,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 788.3,
  },
  {
    address: '0x7705a444adbc784a651a7012b2ff9eba480f7904',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 787.7957280628073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 787.8,
  },
  {
    address: '0xa0ce0c71aa6b7f419d66ba80ea293c63c5cfc036',
    minters_reward: 0,
    lp_providers_reward: 504.29,
    swapers_reward: 282.38426054821275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 786.67,
  },
  {
    address: '0xf1e27d078de19569b45dc6a1419a9c8f31824ae7',
    minters_reward: 0,
    lp_providers_reward: 783.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 783.1,
  },
  {
    address: '0x9ce0ed21e486dc492088c40c81fdcb1fdce36461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 781.3344010139667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 781.33,
  },
  {
    address: '0x7c2513e242055708334ef56c626693d5d4af60af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 775.9854251145242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 775.99,
  },
  {
    address: '0xf1d4772342b05a27fce6f8c86fedef721808dc4e',
    minters_reward: 0,
    lp_providers_reward: 774.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 774.46,
  },
  {
    address: '0xd6d474c4b07dec796990b8c2d588867ed10ff4ec',
    minters_reward: 0,
    lp_providers_reward: 773.61,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 773.61,
  },
  {
    address: '0x7b1e3662689776d9cf46c2d960eca0b86c105953',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 770.8719220243262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 770.87,
  },
  {
    address: '0x30e4ca18ae8f9fa4993663608edb1dfbb74d7aff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 767.686735345523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 767.69,
  },
  {
    address: '0x97044cd4f5b68d73202472af6b96fe57f74cbdb4',
    minters_reward: 0,
    lp_providers_reward: 117.97,
    swapers_reward: 648.965843709891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 766.94,
  },
  {
    address: '0xf45d7dc442e9455674d0019324165fff1383daa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 764.2837263739915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 764.28,
  },
  {
    address: '0xac695a79fd53ee3324fc9110e5182f311a2f7482',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 87.60159701138481,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 763.54,
  },
  {
    address: '0x00df6a29109fc7a247f8f54e761e5f5dccc0ddb6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 762.5962777336312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 762.6,
  },
  {
    address: '0x3cc221896aa3eea547bfaf98bc894f39cdc9a393',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 762.5103795652087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 762.51,
  },
  {
    address: '0x196ecc76edca2b081aac3a5f64b1e27d72de15be',
    minters_reward: 405.04,
    lp_providers_reward: 85.68,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 761.62,
  },
  {
    address: '0xca126d8fabbef6fdbe10c6082af53bb02c759e3e',
    minters_reward: 0,
    lp_providers_reward: 760.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 760.52,
  },
  {
    address: '0xd1a5af86a0e064f01b4ece578c0fcb57bfe73983',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 756.898279231954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 756.9,
  },
  {
    address: '0x062a3f0c9926237d54ad0047f43acdfc19daa65f',
    minters_reward: 0,
    lp_providers_reward: 756.7,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 756.7,
  },
  {
    address: '0x2b65099bf02977be63f44d3c1740321ac9be154d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 755.625160128189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 755.63,
  },
  {
    address: '0x3ae9942f2d95467ce1a0d6b47dc6175ba494a213',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 79.33443325680666,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 755.27,
  },
  {
    address: '0xbf52864feb04cec892f4368bb7a285955afffce3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 79.20603089940909,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 755.15,
  },
  {
    address: '0x2be26273f94554c54d974b86ccb0f078e7f2aced',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 755.0255742069468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 755.03,
  },
  {
    address: '0x3fda54a292c47e0cb4e93bad6ff917db224628eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 754.9169593981779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 754.92,
  },
  {
    address: '0x03675f2f2343776cdb919e20e3d92c09ad32ba65',
    minters_reward: 405.04,
    lp_providers_reward: 78.97,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 754.91,
  },
  {
    address: '0xa62a7a20c38fd87d0ba3f06bfc84a3bce7596120',
    minters_reward: 0,
    lp_providers_reward: 754.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 754.07,
  },
  {
    address: '0x3cc19de595605e17c466d05d66c078d213d9256e',
    minters_reward: 0,
    lp_providers_reward: 752.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 752.96,
  },
  {
    address: '0xc4b7321926e12b0ad24b9cffe707bf15bb225ad5',
    minters_reward: 0,
    lp_providers_reward: 752.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 752.48,
  },
  {
    address: '0x0c8f0c3e8c3695848a0aa4ede18efa6101dc935a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 752.427719469625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 752.43,
  },
  {
    address: '0xf01f4510f4311f3607b186936b2d5a072c27bbc8',
    minters_reward: 0,
    lp_providers_reward: 216.78,
    swapers_reward: 259.78485469049366,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 747.46,
  },
  {
    address: '0xbc5a40c920963541abaa5f8c2c2639bee32e7d12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 745.2913257087215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 745.29,
  },
  {
    address: '0xea1420c79a940b2ecfe694ea76ac6a21eefac410',
    minters_reward: 405.04,
    lp_providers_reward: 28.08,
    swapers_reward: 40.60789692154541,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 744.63,
  },
  {
    address: '0x24b601b11188946547b9b5a339163adda767d504',
    minters_reward: 0,
    lp_providers_reward: 420.07,
    swapers_reward: 324.23931829088747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 744.31,
  },
  {
    address: '0x9cb5230d4f3bf11b32b292af4081705609c71c20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 740.4187882897688,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 740.42,
  },
  {
    address: '0x219aff82a0a6056c74d394f5b748167640dec2d0',
    minters_reward: 0,
    lp_providers_reward: 417.17,
    swapers_reward: 320.98590856669153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 738.16,
  },
  {
    address: '0xb05185f9aa0727bdde3bcbfb8afdce059af5fd00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 737.8209140559727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 737.82,
  },
  {
    address: '0x4e8644349bfd39e8d4b0c49475bf5e9d37a33981',
    minters_reward: 0,
    lp_providers_reward: 435.12,
    swapers_reward: 297.88326730267863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 733,
  },
  {
    address: '0x9b0760bca562a450a6ebcfb3d1aec37eb53d7127',
    minters_reward: 0,
    lp_providers_reward: 732.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 732.45,
  },
  {
    address: '0xda3c0338f9b8a081efd1b0dfa0afc2b317cd23d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 730.4353055008455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 730.44,
  },
  {
    address: '0xa6dd5f927d07c85170f33ecd25e7b03cf02289fe',
    minters_reward: 0,
    lp_providers_reward: 307.92,
    swapers_reward: 149.83312262719954,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 728.65,
  },
  {
    address: '0xa48978ce39ac8526cd840aa303203ce93079fbaf',
    minters_reward: 0,
    lp_providers_reward: 169.84,
    swapers_reward: 287.706240896658,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 728.45,
  },
  {
    address: '0x7bd36fe9dbff09192fca6f58d5c59d40918564b0',
    minters_reward: 405.04,
    lp_providers_reward: 42.14,
    swapers_reward: 8.73376665913563,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 726.81,
  },
  {
    address: '0xdef11402e62d0a36772e0d767756a9d00132db15',
    minters_reward: 0,
    lp_providers_reward: 724.17,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 724.17,
  },
  {
    address: '0x847b06988e4937da3f827ba9bc06b4aad6bbadf5',
    minters_reward: 0,
    lp_providers_reward: 474.9,
    swapers_reward: 247.92429227145948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 722.82,
  },
  {
    address: '0xf88f69104c13b16f2567937b580782607d96c7fc',
    minters_reward: 0,
    lp_providers_reward: 722.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 722.66,
  },
  {
    address: '0x8682dd163520f546dd29c872a98fff373d834f5a',
    minters_reward: 0,
    lp_providers_reward: 534.46,
    swapers_reward: 187.5641444652874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 722.02,
  },
  {
    address: '0x660669e6017b826c67616dcdcf300e28ef2c1bc6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 720.0385099428482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 720.04,
  },
  {
    address: '0x80b0d3dff4207cb30f148acb3dcd637172bc7855',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 714.6507862418064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 714.65,
  },
  {
    address: '0x515e46fab034f14274bf71a7d524cdcdca155af3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 38.514120646341425,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 714.45,
  },
  {
    address: '0x9ea738148255e3c176dc3575576acf738a481767',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.5640095546082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 712.56,
  },
  {
    address: '0x571b57d382b082340cdf80c6a37254e82d0e529b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.5242400882868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 712.52,
  },
  {
    address: '0x05a3081464acd5caf4e40f4480933b802176c2d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 712.4687504302931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 712.47,
  },
  {
    address: '0x6f73a9d2070aed7d1748980d4ddee8e2cefc273a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 710.9277400869294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 710.93,
  },
  {
    address: '0xa401c24efc87ecf2725af266e18e874584327bb2',
    minters_reward: 405.04,
    lp_providers_reward: 22.03,
    swapers_reward: 10.832552608040642,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 708.8,
  },
  {
    address: '0x8a449f3d25cd625decc073ca67cf60b561ba193d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 31.598259386418075,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 707.54,
  },
  {
    address: '0xb5602ad7bbf1e1aade67702f70a97ea7947bd576',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 28.29907874968423,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 704.24,
  },
  {
    address: '0x18169d0136477d7bd7aa540457932139d0951682',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 703.7646697612578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 703.76,
  },
  {
    address: '0x133830b3d5b6a6229dbec2ee7f3cf71995b682a0',
    minters_reward: 0,
    lp_providers_reward: 702.82,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 702.82,
  },
  {
    address: '0x01757bcd7aed10edcc764c67de65e67f548ca0c9',
    minters_reward: 405.04,
    lp_providers_reward: 10.84,
    swapers_reward: 14.666307731836582,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 701.45,
  },
  {
    address: '0xc5d35cc6260a291fb4b23979ce8bda6e3a76ee12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 430.0607387669521,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 700.96,
  },
  {
    address: '0x02cbb284b26abee12eeb3e5a7e91bfe9762d5560',
    minters_reward: 0,
    lp_providers_reward: 357.76,
    swapers_reward: 341.3800345332608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 699.14,
  },
  {
    address: '0x4f27c877b20607c5a681c2c6668f0695ad2d2ac7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 699.0489856749572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 699.05,
  },
  {
    address: '0x5fcc31b973cf5e9609cfc792d30299a3d6670d78',
    minters_reward: 0,
    lp_providers_reward: 546.58,
    swapers_reward: 151.69729455893398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 698.28,
  },
  {
    address: '0x0d5d19cd361a021894ca37312b4c11832c211777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 697.8961778064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 697.9,
  },
  {
    address: '0x576d166b1ad26255cb9a3fb788385c0dc0a39c56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 697.3649024699642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 697.36,
  },
  {
    address: '0xd67d81e07b18de7e643480cd9f56bc1a498fba3c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 20.49733962111166,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 696.44,
  },
  {
    address: '0xd9268c48b13fd3389fbf9186b38b1a32025ba372',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 19.880182211643575,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 695.82,
  },
  {
    address: '0xe33d44ee5aa3a5e7ac44aaf4b5959d23d7a1f9dd',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 17.943787127716025,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 693.88,
  },
  {
    address: '0x4eb3cafd85e2c9d4247c9a29804b55bff0a5a5e4',
    minters_reward: 0,
    lp_providers_reward: 519.52,
    swapers_reward: 174.15019545887023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 693.67,
  },
  {
    address: '0x224167fb7ac323e9cdadfac7da1bea84ab68cec7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 693.3723405525737,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 693.37,
  },
  {
    address: '0x6392ee6ea843e70a30ca4b21978f5f2fd5a94827',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 15.396015508499751,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 691.34,
  },
  {
    address: '0x7a69ea4d0bb04452c9f54acfd258a9d855554340',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 689.5506554048882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 689.55,
  },
  {
    address: '0x209b1dac73a95bbcb735b2ed22b7c34319072be0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 688.9439757032659,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 688.94,
  },
  {
    address: '0x8a3f2d114e12fd377ba17082d93b60751731dc7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 688.1165772759332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 688.12,
  },
  {
    address: '0xf90b156e8c094a2348d66b669b6e79a407f9a067',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 11.652179152852584,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 687.59,
  },
  {
    address: '0x77ada9cdcc8094afa5c8d06d74cf7cab7c494e30',
    minters_reward: 0,
    lp_providers_reward: 687.18,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 687.18,
  },
  {
    address: '0x33b3c4cea42a869af8ea9f9a2321afefaf9969b2',
    minters_reward: 0,
    lp_providers_reward: 56.72,
    swapers_reward: 628.441817290464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 685.16,
  },
  {
    address: '0xee02db235e130ebd34ac9ec10ae036434a88e372',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 682.7681542300504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 682.77,
  },
  {
    address: '0xc353b211e3537e39bdabf7469e07b0a6d3aaa92c',
    minters_reward: 405.04,
    lp_providers_reward: 6.5,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 682.44,
  },
  {
    address: '0xb6773bce5418aea4cd00f62572f4e5eba8bf2972',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 6.347012983976937,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 682.29,
  },
  {
    address: '0xe832f15e797b92fc0938be2f5e4513ed2734e2f7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 6.315764169741034,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 682.26,
  },
  {
    address: '0x987517e3d429f428157996838b8cb33017d4f680',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.5940539121032,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 681.4,
  },
  {
    address: '0xf8091823e14d51b0b4ab9f5efa4f7075eb5ca1f8',
    minters_reward: 0,
    lp_providers_reward: 681.31,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 681.31,
  },
  {
    address: '0x9b0f7296cea3314c6cc224002a929ce14e856739',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 2.692140919513858,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 678.63,
  },
  {
    address: '0x38efed32b291321178b4f1e54c4fd5716b673443',
    minters_reward: 0,
    lp_providers_reward: 193.84,
    swapers_reward: 484.2471688574982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 678.09,
  },
  {
    address: '0x80ce10bbc76361018dc40d0a7591ff760da0822d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 1.8511329723731067,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 677.79,
  },
  {
    address: '0xe10e2b542a5ccaa880ca2ab3b6d3c60f0b69eac4',
    minters_reward: 0,
    lp_providers_reward: 53.14,
    swapers_reward: 624.3207923316337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 677.46,
  },
  {
    address: '0x6fdff6ed330f7cafe633ba5813c80691757ab45c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 677.0268932212006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 677.03,
  },
  {
    address: '0xb1e9cc9b2a7effa6a980b7bc22dc79f1ae9828a1',
    minters_reward: 0,
    lp_providers_reward: 49.38,
    swapers_reward: 627.39835721523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 676.78,
  },
  {
    address: '0x76be46ed1c0ca46a2c137af3a8a230d46fa6f6e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 676.5623598017718,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 676.56,
  },
  {
    address: '0xacB69430EF831609cf6FDb4F113Bc72eB6A0CD32',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD2B29dc4dDAEe4D2aA030004561400Ae562C42a5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x2449630a5A933aA7435b8049b9A5435Cc7af3701',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE74d90762d7219ecBC828f0A775247299df4A696',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB2981424dE323D821815958E418c506D01FC557F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe9dc18BA182d2124e995FA4BDb708f1aC348CA34',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEDe3c709E14D023B68FdBFC1F98A394E72822a8a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD34cEb3Cc534cc854A29B93F3630c014519Ca6a1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xAEcA69863E59F99f4f348F55f73c3998FBA1Df5B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xd65e7EB1eF529c26455de82e0F37808D04Fe89a2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB42b45dbc6Bb29D2B4a17eB2B0Ad19877C2f962A',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1D08C120eA9B4eD9ac992B93470C9fb7ad1C033D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x09955409a79996e12929B4469Ab94Ed8A6970108',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6F5A7dE7b0Aa5Ca1B362F1CfE57C8ad48491a309',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7A04E1D5CB5b5F62919901e0591A9Ac3543D326D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x4f4cE98753e2722B4f7877425Fd5dC76553e6BBc',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6Ad13E784F52Cd57D5407bA25dC6Ad001508ede1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb11247f62Ac393dDd106472654C5fC84D80c7014',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9A4A3324e8DB172a0fDe2167326784F7370209C4',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb459e3b87CeA22568351E57C65b9B4cBc09807b8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe112E469A3114cb16673046fb934592eF277Fb52',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa5A2dbf79AE114C5A4026dA8dc53Af8bD17c9298',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe5af0f47cFc7C8324325Bbfc93C27517DeCDE23B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x81d96D76e102f628fC2a4ce149DdAB2c7ceEf04D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x03F11d30318d0a4F7b61fB29da5cf35393DE4d6a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x2Ee112B83Ba3Cb7351506cd096bc78dFD0f66EF7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xC7791a20d6Ca4b35DD23Ef9c5c97f939FA504642',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6602A52c33C9B5B7E9bCfa26396F612Da22Bc17d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1F771289de82aFB60951a2567C857de0586df4D3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc9BF461E4C8c5Ef9050abbB42e1DEe090A094535',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7be76f25757C7B79c599F5283A251B843F89c061',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x483d2daBC87A6679eef31B8683d47A62eE795676',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x41cfae546b190C48D435Ec09ccb24E9834AFB598',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x25eB0a6CDd32C6162759d86E7fDbCacb715C838b',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x3eF573342F845e2DD87182B00f10B7bd6757d344',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xfe2d3a1e84c18051D0fDFf16eEF27906d2cf0CD5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xAACF4f71E6c6E7A58E00e66aBddeC66Cb9Fba242',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7c1B93200576a0F8D21333d8E1f59d065d6a7683',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x66d7327Bb4487099F69EC90574f8BB6dAeE64285',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9C1C1cbD67f6071eF835442731f4E37D89eea52c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe0d0c3b0b80E11E622FC361377add6fA9DaD0C9C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x3B2E5A6e81789e0DEBB8906419Ddeef9968f0528',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9Fe711926391ADf52aC21D466A6147C55CBCCD9B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x407c2aC63e0959e718f10E8D2114514db1DEFE89',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9f69e8649aBEEAf52Bf02aF5A880c8C35552F0B0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7f721A4b83350fEc8683577C1f314c0A0a849470',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x5490D6abc8da43C631fdC4B03190CC9433571595',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB769877330daE37882cE6ACF392866195621a36F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB2C3c27bf78aB22e116172036aa38f5AD80ed937',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc13C68e5b3183D9F5EEB2d111E413ea79286810c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD4036b9AF3a4ed77fc3edbc03e0a2dbcb3aaDc85',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xC1eB3c55C9FD35a9A43F977fd497965d394028E4',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x05Be96bdC6756ebd03b94110bD83fB0EF70472f7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE6B503225773Db5491eE1bf988e502ef6D6FaBBF',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe2A451B623dA5e1bB6D2f582A48E152F6F6ab522',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xaa2C2FEFaf30cc5E6558Fd9d657396d32FdAE03E',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xFE33Cd204C811aFA6342A93c392649a1b547c818',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEcFf9d177809060D282A3fC0f628C7F0a11c3744',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x184778C8e48A9DEDA8D887442750E74Aa1faf5Df',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF10a07179723C54B9f43FCc6E145B026D3F58E7f',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0486DAE41EDd3Bc89D85e9bBd438037311453aC6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa1044e442AB81FcD803A10AF9F7dc98c7d0d4e14',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xfbf57a674b59F0A7C883FB0E6c436C68204f3D9A',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0De0049a21b4355c5B47F549b5AF909477f7F5d9',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x94f82C9236Ef214813009F32a74ce674eBBE37Ae',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x765b0f292a2202932D0de7f8f58F7B91fF7768eB',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xFc1F1c04F2593731fD096ef0f882b3325768a844',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7BeF9FF54535608f8051a9D187C3B841bAb97709',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x427E5697747B64097864bC681D59ae4AAb3DcCb6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xAc69faDBACD44BF75532E19ED8E57eE912102bfD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x72254ddBf4dFc87a5eFd6606d3a01B59331d2cf2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8924DAE31f9c793Cd01a600Ddbf166ae1cCc3Bc1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xcca732684d36403BA0285CfF524e543d8e3699D3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xD4C7e2cCe998FB8c48a3aB95eEd796f993D99D87',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEF30d12D7BC47Efa87749Ed037890044bdCC9DA4',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x19D6Ab1745adC0F0B99548ccDF96AA3979A7B329',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x5CC5E677Bf5329e80cF758EfCBbEE96D7Ac95073',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x81695cf52Ef8E1A35Bee61Ed150E412CF8A51d59',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x700603B3a0D7E6737dF302BC6632688627ca1bcA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x94af50af8d11fbc732030e63AE29451f770F7939',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xf0C6C0bb16ad5bCf150064985A80fEB99bBA2CA0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x56FA03a2Fd0Cef1fA4e048b2c584430eA653bD74',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x77E12B3a6c569Bf9f4b7F6b773cA0878941D0Dcd',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xf9a8834FC6CA50e9987Ee29E48F7CB7EFcACd509',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0E851c6d324Fd4552162C8bd7452678106D3dcc5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x591ED444782fEf715B741ac51aCA130035136E8c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6dd261E14FCF8E701A98059F96E289b3c9736781',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc2679002744FD321b8dEc2483B6b32758a4E2D7e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x110FBE24AC9d1B5287C7e700f68efD2d228Fa3BE',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0fE0f56dbBa181c3297F023e4Bc91bA903f55bBD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE264c8D12B50F5f9E89F6F6852C3dA4c6E8102ef',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb9EEE49D5D6F187fc9FbE26885Cd5B4AcAE07E28',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa1C34D579BDf994c1cDF733d3B2DC04624eB8351',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xc489f5366Fb596b1E708258bfff029105fB4ea8e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF484004F923480e1E4596BC7b20b3B4637889A2a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x03E068f509fC8d18Fcd13Eec39a729A318A042b7',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xCa0D19E774984b86df05A9BD936370fa7cBeD25C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE6b409E11A87aB837264B15717008CfF08B6A628',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x4D8aF6d5637D1c564556eebC3c13F0dea509b9F0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8eF0cDd76818D55EfD2BACa386734C520263b5b3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x483a2a394c228beD5eb29d0557C182896781Ff67',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x52c063fF28c20e1fCFE2049A19C4B9BaC7f8E313',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1600389a91a1c481aEcE27BE9F400495Db39DDbc',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xEDe74E99C15ED8e2bbA8Db7cBa3B4466Cf4c9BC3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8d41466ec04D2E7Ce82BdcEC0a5eA4341d5F2F09',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0255ECD9c775A7361a6d3928B35B8F819163Be08',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x81a434F395B6525106DCBFF6FD071F1F8ce6Cd1C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xCc3c0bDF1746F1b199313d1ff5b13aA29A3bBDf8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xeC8fA904D5ECF8F012f0Aa1b30F650e5b69Be657',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xB0725DFDBE20FeECcA9454588B8894CC0a3e96e3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x744b44c2b0f01649eb65b8C3D8c2Bd825403985a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x018e727CE6ED7754e9cD9DF9Ac1e9EAb1b444cdD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x0179f665e46e456954F00d5475B306E613A9fCdb',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xA23c07A701eee997d8Dc34E9eeE77962b7E222C6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x52e8236D55a11c1Be5CD01A7818657914B5e357F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x269854AC2F9AE3c35898Cef6DEB40f1711cbaC37',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE3a5F2856A6747ac7Dc5c4f1afae65700d3Cd8EA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xe5f81c417Cef1b578151F75D8B4443516173D7a0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x828D7f8554546A6328c58050ADb83F87f72662FC',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xf15bfC92DaB10e206Cc5CC1405703F20A69eF5a2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE344Bb1b5b76cBe395DFf923230266dD04232C79',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1F7D61146756F57e4F52002F754B93A02BacdF3c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xE1eA6c8242df83E5f2DCa6cf9aF83fdf9E06D503',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x8D98bf87b40C0265AEabAB1D246D968FAbf5781e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x35799Bb2B931C881e10DE14B724ae2075DF96A5b',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1218ae63bEbAF211b4A3eBa2Db80A1944F88fCC3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7Cf8377c8cE6dECBB86B4Cc2cc5C7b8DFDFb4165',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xdAC2E69A3313B173c551667C2Ba7b4c272F306E1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7959Bc163A20DA08e85e3d34487d14a6Cc630664',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xeBC1AD03D8ea7402692b759DEd8e8CC537884659',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xDDb868B6d64471c89999c3affBEc49FDa291B6e1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7eDcdF9aA7411CF395988396c50E1D2B7E9715Ad',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1349E61596bDEfF11d6efb4a1DE8bC9B17a39d4F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xA0277871A5cD0ca82c0CB1eBD507Af49392E0629',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xcc67763AE87f84376254De87c3CE313425fcbDa0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7439a0B214c2507e1f333c8Fe865f1D2b61d6c96',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF897eB82C8360367CdFA7398A2bE620FbC3e8eb9',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x1797078687B258264d74158caCFAfaFC71FC69a3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x73686B7D232669469BE284ef9810b52255bF14C1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x22E27e1814325fC3c3c4Dc04DA738D25395c97eF',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x588f8305BA37EF6B187CC6601779382f629D7025',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x85527009b8d527CF4799500F0485c2e5EE52452F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xF507E035b9243AE54b9bcA48dC5448D2888f50bD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x6331d2C75BaffFC76672B60556DEec15DfeE86a6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x7574B7e7620F233dC90d8B4A04db3c32cb81381f',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x3431D6D5D53b1159bFebB282A811C8E03D827030',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x49E7125146bC8BEfb55125BB07f901e1F607B371',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x71C7684B52bE0F3Bc29E420De36623bAd625d519',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xa85F3d2f44A30Cef635b4d29f00dE5081eb977dD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0xb15Cdd0084e492eBE16a1858e8c578c3c369CD99',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 675.94,
  },
  {
    address: '0x9d780c88dfcd23c92d96f1d7514f7739a2313f4a',
    minters_reward: 0,
    lp_providers_reward: 675.87,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 675.87,
  },
  {
    address: '0x809df8e77ad18bb25a0d33e3259a987b11587df1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 675.8190957630437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 675.82,
  },
  {
    address: '0x81d5604292076a00308733df68453eb6b497888f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 674.1671175991303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 674.17,
  },
  {
    address: '0x2a0a93411878b1079bbcd9f2f46fbc8aa7dd3278',
    minters_reward: 0,
    lp_providers_reward: 518.54,
    swapers_reward: 155.6190310640577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 674.16,
  },
  {
    address: '0x2b82e1b9cc3a0ffed2cba1f8fd24ffaf7b9a1123',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 669.8260636221232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 669.83,
  },
  {
    address: '0x9cf4865275cb5d966dc48305eee9c747b75ab8db',
    minters_reward: 0,
    lp_providers_reward: 668.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 668.15,
  },
  {
    address: '0x0b897bc0944bf6be31d4daeb1561424f0eddbb91',
    minters_reward: 0,
    lp_providers_reward: 221.6,
    swapers_reward: 444.1262177673586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 665.73,
  },
  {
    address: '0xcf479aaf2b34a49c080c390dd344fe3ea9ec3193',
    minters_reward: 0,
    lp_providers_reward: 665.36,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 665.36,
  },
  {
    address: '0xc0330348d5e8a391bc7cbc9f7c8e112522e2e8ad',
    minters_reward: 0,
    lp_providers_reward: 664.06,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 664.06,
  },
  {
    address: '0x9811af77e51ea3c09b2ca203ece0ad2c0e43f17e',
    minters_reward: 0,
    lp_providers_reward: 632.92,
    swapers_reward: 26.486867488645846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 659.41,
  },
  {
    address: '0x054b6d111a54eea555644801e53c67b6ced98ba9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 659.1910474217415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 659.19,
  },
  {
    address: '0xc509bc0d2f6631691ff5f619eb11fc34ccb1f22d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 657.0820215111601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 657.08,
  },
  {
    address: '0x7b73e8cc7bc4fca54759a2390343f730d5d6ac3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 656.4444596044926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 656.44,
  },
  {
    address: '0xd2769d97d2d3f133d98f43703eebefbcd5abdd27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 653.7745759047939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 653.77,
  },
  {
    address: '0x5c3edbeef66275288b79c171d8087c4f40501d5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 652.6531980528208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 652.65,
  },
  {
    address: '0xc2fa2920ba5d871cb7c54c303dd16d777e14026b',
    minters_reward: 0,
    lp_providers_reward: 429.85,
    swapers_reward: 222.24651341182405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 652.1,
  },
  {
    address: '0x0df4211bfadebb7e592261c7764fdcb62b653e3f',
    minters_reward: 0,
    lp_providers_reward: 328.39,
    swapers_reward: 322.93436487978494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 651.32,
  },
  {
    address: '0xf37f3d5f77da1d183fab2e47ec6cfc3bf288c927',
    minters_reward: 405.04,
    lp_providers_reward: 198.28,
    swapers_reward: 47.80697895603813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 651.13,
  },
  {
    address: '0x801318e3772dad4011e79e31d91b7480f76d0abb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 650.4301265483299,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 650.43,
  },
  {
    address: '0x8c39e0a768b692f2bb196e9ec45f0d710420c609',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 649.2394977444429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 649.24,
  },
  {
    address: '0xd53cbb50e42918da2fa1818d6367c3fb518a34ab',
    minters_reward: 0,
    lp_providers_reward: 648.03,
    swapers_reward: 1.1926639268504993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 649.22,
  },
  {
    address: '0x53a3c047a652063de9836a1f4b0a6de80a65c81f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 647.2180536273651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 647.22,
  },
  {
    address: '0x935e9b501e535011edeef728d93c3ae059026b0c',
    minters_reward: 0,
    lp_providers_reward: 410.17,
    swapers_reward: 235.0403224293869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 645.21,
  },
  {
    address: '0x2c8cddb4ed5aa7d28fcfcc75313de4286e0b5e32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 643.8191388222125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 643.82,
  },
  {
    address: '0x67fcbffce6a5e03e3c8b3907407549989673512f',
    minters_reward: 0,
    lp_providers_reward: 456.85,
    swapers_reward: 186.33334166792858,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 643.18,
  },
  {
    address: '0xdc30659df09f979c77be97e5193de5d0138872f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 642.3381567859831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 642.34,
  },
  {
    address: '0xb0683953c8bbc925b8dcd449ffc043f0bbdb20c0',
    minters_reward: 0,
    lp_providers_reward: 642.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 642.25,
  },
  {
    address: '0x0276aba641ab5a8d3d41b0ed98ea17f64e287a37',
    minters_reward: 0,
    lp_providers_reward: 641.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 641.95,
  },
  {
    address: '0x6d427b95b5bfe92d54e6877f81f03ac29b1f023b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 639.6600775840238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 639.66,
  },
  {
    address: '0xab063f52b380237579d357f82adcf9f69e3563a6',
    minters_reward: 0,
    lp_providers_reward: 585.48,
    swapers_reward: 52.40025679486327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 637.88,
  },
  {
    address: '0xe501c7a3694d5016b6d00f33a6d2712ac97293a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 636.7599545635929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 636.76,
  },
  {
    address: '0x9ba395c6c466aad1a0a064ba51c76c9cc7325aa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 636.6324367437311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 636.63,
  },
  {
    address: '0xa1b3b143104a02489e1d1ce69f1c97ee3db5d1e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 635.351548684989,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 635.35,
  },
  {
    address: '0xa7a9b1940ba555f11876e3cf74aca48c84b0c63f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 633.4638916780824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 633.46,
  },
  {
    address: '0x7ee27b531c804bae74294254416b45eb891ca39b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 632.7042392285106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 632.7,
  },
  {
    address: '0x6bd667f118fdf3bb3316f6f74a0e5009f85734d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 632.1556740494395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 632.16,
  },
  {
    address: '0x54d25506984fb1e73484f523e02951ac4e253d1e',
    minters_reward: 0,
    lp_providers_reward: 631.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 631.94,
  },
  {
    address: '0xf43e8c65956aef41f53b2be01845e01880897116',
    minters_reward: 0,
    lp_providers_reward: 371.49,
    swapers_reward: 256.6984740357372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 628.19,
  },
  {
    address: '0x2ae4afa2dd52c7a9d34e75dd8900bd44e5315a03',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 220.82226929403564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 625.86,
  },
  {
    address: '0x183a60ab7f1b91f56518974e462c7eb58e917e20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 625.6625964251813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 625.66,
  },
  {
    address: '0x78c3c32938d311dc8598f91d3eda3753d9d583de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 622.8133908638703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 622.81,
  },
  {
    address: '0xa1212d6d46e35402d7362d2b9141ab80440b36d5',
    minters_reward: 0,
    lp_providers_reward: 84.45,
    swapers_reward: 535.8227032327671,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 620.27,
  },
  {
    address: '0xd549d3abd0ca4afe1c3df9f6f8da05497ccfa1ce',
    minters_reward: 0,
    lp_providers_reward: 618.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 618.12,
  },
  {
    address: '0x513b21a65fc2ceb8e1bab3d07f8871fbdc7516a2',
    minters_reward: 0,
    lp_providers_reward: 617.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.74,
  },
  {
    address: '0x68f701cb658b409d11236f987713f63a854a822b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 617.6824643307771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.68,
  },
  {
    address: '0x7d804f283e54f9d7ef5f314ec74c56f503ada4b9',
    minters_reward: 0,
    lp_providers_reward: 52.58,
    swapers_reward: 565.0433309770901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.62,
  },
  {
    address: '0xe5f40c99e569d891b052964c689836461305bdec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 617.0671407040658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 617.07,
  },
  {
    address: '0x119a9a3e0993a187f1bc7e30ce6cd967710d2d86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 616.4508413782079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 616.45,
  },
  {
    address: '0x1e021509548093a7073c7b3bc07ee154c18e26ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 614.6680191263814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 614.67,
  },
  {
    address: '0xaaa4edb369dc8f5f0b63efdaa7dd9f934f29643e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 613.7409782744076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 613.74,
  },
  {
    address: '0x86a593df74ce5637fbd8b88dadf1bc84b99c7e03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 612.495882574851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 612.5,
  },
  {
    address: '0x896d93f1dffc0313be17e9eb17566be1ff41d284',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 610.5049778559877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 610.5,
  },
  {
    address: '0x2056d6ada53f9daac2850ffb29b4ecabb1afd0d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 610.2419770778406,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 610.24,
  },
  {
    address: '0x135f845fc7b133eac6202b5c7f5036e0a0e77d42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 606.619564549725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 606.62,
  },
  {
    address: '0xc37108b03a83eb9c8ebb5a14b506b2659a50956b',
    minters_reward: 0,
    lp_providers_reward: 458.25,
    swapers_reward: 147.36111873481855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 605.61,
  },
  {
    address: '0xbcc0c3b5065649a3dd3641b4812d285494fcedf0',
    minters_reward: 0,
    lp_providers_reward: 376.76,
    swapers_reward: 227.924561241531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 604.68,
  },
  {
    address: '0x0d06ebb762a1e0baf67fdb878d5c58863248ac01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 602.8734770277937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 602.87,
  },
  {
    address: '0x9cd004b52898eb4928156c1d67b4fb31ab7f4c9e',
    minters_reward: 0,
    lp_providers_reward: 600.08,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 600.08,
  },
  {
    address: '0x94a900d08d349e602adf137412f64095aa548022',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 596.7665200872794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 596.77,
  },
  {
    address: '0xbe76b20576d152d1ad01ba9f218a54004e9b739a',
    minters_reward: 0,
    lp_providers_reward: 143.36,
    swapers_reward: 451.87017720069144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 595.23,
  },
  {
    address: '0x4ccea44106ed8af5ab884c33008263195c105903',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 593.7438914639099,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 593.74,
  },
  {
    address: '0x09bc324bc5978695e1cee0bb2b072d7911959394',
    minters_reward: 0,
    lp_providers_reward: 265.53,
    swapers_reward: 324.85433183911806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 590.38,
  },
  {
    address: '0x6ac969407e803fcd83db727892b49eeba19479b2',
    minters_reward: 0,
    lp_providers_reward: 245.49,
    swapers_reward: 342.8683489847914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 588.36,
  },
  {
    address: '0xc29fb3e79e8d72326f288dfc677226b889329621',
    minters_reward: 0,
    lp_providers_reward: 588.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 588.33,
  },
  {
    address: '0x23f7ebe0c643d657d920d3e2c0f438cd0a673ec3',
    minters_reward: 0,
    lp_providers_reward: 302.11,
    swapers_reward: 285.7413322118732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 587.85,
  },
  {
    address: '0xfe4a679d8ee7390057010eec5e24d6427e1c3686',
    minters_reward: 0,
    lp_providers_reward: 587.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 587.48,
  },
  {
    address: '0xbface1f1b4e4b457e1046bcccb5d574b3d802c75',
    minters_reward: 0,
    lp_providers_reward: 107.72,
    swapers_reward: 478.5069000664697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 586.23,
  },
  {
    address: '0x4164196895087191e5d9e292022225ca72debab5',
    minters_reward: 0,
    lp_providers_reward: 585.87,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 585.87,
  },
  {
    address: '0xf8ca55e19603de02a361dae0a892e469a36694a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 585.713301279694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 585.71,
  },
  {
    address: '0xfd16c55176fac6032991e71c03e929e7355c6d52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 584.7649474603071,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 584.76,
  },
  {
    address: '0xe80f69ec5a1a0850bd0834cf44fbc90ae7a8f324',
    minters_reward: 0,
    lp_providers_reward: 414.83,
    swapers_reward: 169.08905066819037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 583.92,
  },
  {
    address: '0xbe812066f2c61234159b58a5e88bec38dcbb1d11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 583.2391284950721,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 583.24,
  },
  {
    address: '0x43917c252fb50ceb284e3f6b1386ac151d495b2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 583.0782691751125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 583.08,
  },
  {
    address: '0xf2569dce6e002517a9ad1c7a69e44c47e9fb08d0',
    minters_reward: 0,
    lp_providers_reward: 581.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 581.66,
  },
  {
    address: '0x5fc115803064995b493170cf9194cba7df3c29d7',
    minters_reward: 0,
    lp_providers_reward: 107.08,
    swapers_reward: 473.9388183738694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 581.02,
  },
  {
    address: '0x0c50a367f97f824e5192e2f992db799a929d347c',
    minters_reward: 0,
    lp_providers_reward: 541.24,
    swapers_reward: 39.197264464212594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 580.44,
  },
  {
    address: '0x2a06f5fcfbef774c4d546a81f37698a3f9659921',
    minters_reward: 0,
    lp_providers_reward: 2.87,
    swapers_reward: 35.48510782185151,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 580.17,
  },
  {
    address: '0x40922917102c812fa3ba55aa365277d1d3f703d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 579.8682788282154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 579.87,
  },
  {
    address: '0x5a12c8c32cb99ad5827b6b067b9097c0a592201e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 577.7625813772637,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 577.76,
  },
  {
    address: '0x8ad7ac916662b69450a4390e245965ec4f49ed11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 577.5500481312429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 577.55,
  },
  {
    address: '0xad1e671c7bd93a264c4bbe0f32bc57dee3d11e7b',
    minters_reward: 0,
    lp_providers_reward: 215.04,
    swapers_reward: 361.1356351915297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 576.18,
  },
  {
    address: '0x3487865e5761dc90edcd8e019eaf35c47699663e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 575.3560885513785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 575.36,
  },
  {
    address: '0x5004b6422a9d0c6ed4202c9b63478e812993190e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 574.6117657317128,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 574.61,
  },
  {
    address: '0x5c3dec60919e1de594aaa896428f39e0c5a9d069',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 572.9121081439491,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 572.91,
  },
  {
    address: '0x3f51dec8f9f7d2f24dd100cb6d7f9d636236f167',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 572.1847292027104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 572.18,
  },
  {
    address: '0x7316cb0ef81f650a51c329c9ec067e2a63757890',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 572.0681191492914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 572.07,
  },
  {
    address: '0x126b9e299553ef3e4acf92b85bd5c34d539d6ec1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 571.0567624752773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 571.06,
  },
  {
    address: '0x97f5d8c4a947604d932b651130599422e2bfe43e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 569.7916344536715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 569.79,
  },
  {
    address: '0x635a28fb5f9612d7d99b335968ace9a9462ea333',
    minters_reward: 0,
    lp_providers_reward: 413.65,
    swapers_reward: 154.83736099927333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 568.49,
  },
  {
    address: '0x91fb2a4716b58f571f616f175e5260cecc542d3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 566.9665403400608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.97,
  },
  {
    address: '0x5df58c7ba2e6df33a740e91c57795655526c3b0a',
    minters_reward: 0,
    lp_providers_reward: 566.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.84,
  },
  {
    address: '0x9d65d25aa774ab2afbdba2a04ad071484366d44a',
    minters_reward: 0,
    lp_providers_reward: 566.26,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.26,
  },
  {
    address: '0xf1d827d71013c847a1d81458b7e2efbe48672431',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 566.0806335262414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 566.08,
  },
  {
    address: '0xc25d7f9a1eb1ed68603b7fd4ae682ef7294b6947',
    minters_reward: 0,
    lp_providers_reward: 433.91,
    swapers_reward: 132.0230029847781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 565.93,
  },
  {
    address: '0xbcdd6c529b9beaacf8b3f89192f65744c3ff1589',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 565.4944534654819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 565.49,
  },
  {
    address: '0xcd9958a8632d87a4acaa1aeb4ce0280a2eac92e5',
    minters_reward: 0,
    lp_providers_reward: 279.06,
    swapers_reward: 285.91289437501786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 564.97,
  },
  {
    address: '0x9320327efe158888ec86011cf254b569ad021e7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 564.7262421503832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 564.73,
  },
  {
    address: '0x7ea06282da102decdc7f2b6bb252caa19ea03428',
    minters_reward: 0,
    lp_providers_reward: 564.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 564.46,
  },
  {
    address: '0xc8df103556d0c43f51df62d4111964ad5a4cd79f',
    minters_reward: 0,
    lp_providers_reward: 559.77,
    swapers_reward: 3.7810510147361187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 563.55,
  },
  {
    address: '0xd11a5981e469e6f2f7fbd0f035f17e001a76497f',
    minters_reward: 0,
    lp_providers_reward: 563.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 563.33,
  },
  {
    address: '0xc3052acf246f25b35c81026fe7e0ba049538f2b8',
    minters_reward: 0,
    lp_providers_reward: 114.81,
    swapers_reward: 448.3728732614809,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 563.18,
  },
  {
    address: '0x106331266906126c204acf2a247acc925fcb0c60',
    minters_reward: 0,
    lp_providers_reward: 448.6,
    swapers_reward: 113.0607017925079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 561.66,
  },
  {
    address: '0x35a926580fd42d5c2b03451147cafedbd37f82fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 561.5559995784474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 561.56,
  },
  {
    address: '0x49b012e4730aa493af51ade0b1622a116c9de268',
    minters_reward: 0,
    lp_providers_reward: 558.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 558.84,
  },
  {
    address: '0x01c5cc528523f5e758ff0eb401593e53b7e4927f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 558.4290838823928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 558.43,
  },
  {
    address: '0x85a4b6129f7bfa5d17528caff919635e10df393e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 557.7191283176675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 557.72,
  },
  {
    address: '0xcf58feacea6330638602c6c08bb1b7ece59495cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 557.4501018046028,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 557.45,
  },
  {
    address: '0x120aa77a859edccbd95029f54e81914e4d854a37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.228244240669948,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 556.04,
  },
  {
    address: '0x622347b90808f948849272251c6c4c6f0572bce8',
    minters_reward: 0,
    lp_providers_reward: 417.33,
    swapers_reward: 137.4043975590675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 554.73,
  },
  {
    address: '0xccf03c7d60a056d94829653845ce941dbfaf85b9',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 149.05915997895514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 554.1,
  },
  {
    address: '0xce26544358af1ee403536f079605623dd9b919dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 550.7466950473415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 550.75,
  },
  {
    address: '0xcc5742318328892b0cec63e2777499faccbafe92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 550.2132120710224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 550.21,
  },
  {
    address: '0xe20d4495ad1eead206a6a01c62d551b1259c55ec',
    minters_reward: 0,
    lp_providers_reward: 539.58,
    swapers_reward: 7.168355326133121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 546.75,
  },
  {
    address: '0x47e54d41d2f8785615c49917d1f2c660c113c864',
    minters_reward: 0,
    lp_providers_reward: 370.55,
    swapers_reward: 175.79383588299345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 546.34,
  },
  {
    address: '0xd45738fdb3def21404a6c489fa514749102a6c01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.350647931117321,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 546.16,
  },
  {
    address: '0xba31e2f7e3789383082327874ce03d93905ec962',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 544.4411066278248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 544.44,
  },
  {
    address: '0xfa77790988d6a344b94c29b58a748cc7ec0729d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 543.5697184043024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 543.57,
  },
  {
    address: '0x1bdbf63d173ade5836944b981bbfefcf9c62c337',
    minters_reward: 0,
    lp_providers_reward: 0.25,
    swapers_reward: 0.26373480160856555,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 542.32,
  },
  {
    address: '0x9eaC5785efb5E54e72e5d0938f7A263c2643E2Fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xd86d489308c5016B64a1Ebae203751a691674D77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xE8c161e642FC7Cd68A75076991a179D016227b1D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xA7508d08F63dD93CfD69f05EF70204FC3aB88c7E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xaD7E94cd588e7Cb44787650Efed60bC87b2fF15A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xA27aD9463E6c12e119af3E6d3952489f84a6747F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xDf2aE1D86aBf5A37397537b04a3ae9b0962BEf8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x56925bccAC196297F54BD1b6409C0B51401f6885',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xBBAf87c5c309E2d5BF89293B9159D7CFcE2D2d26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x908e8C97785598ca713AdA941C83D6A5542542ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x19dd9AB025051146A50DF90680c0446Cd021697e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x50C9EbA869A6b971458f8e54680A43B7d83fBa52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xC296004Bc4d20103AB900B72d4263BC6A84038aC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x1C59F234acE21c1a5E640b1D645a04922C070075',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x6E7dd5e17aa3c4f3368F71c2bcDbb16256B10C1B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x235F65C197913659baD357Cd4a3717919A6da4fD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xf50DB813C751b0B3e780397E9E6F1d28b11d219c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x0E546fD08B7B7B342c7adC1153919c325ee4c36A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xD21Cea7B5B29ebBA99Dd8D9faBFac735DCD3131c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x559342bFFa06172C9CD47bD49634D916C17FeC2A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x51E524A6d6C496eF9c11452EB77C8bcDFff8e62f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x8e4536C9706680d63Ba17E7280a734A4a7D2fc9d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x6Af34c2d2754EA6c0Ab88399f3c6A9FB6B66DbdC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x19Ab49AE5dA267d70550d181A3fa31B8E6407E64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x3FEa28d3c9A65bfFF5c317d067cb829AC23365bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xA59f6130E3edF3918ef146aD8AE9EfE344F08725',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x4e3b0a44f5678c400d37105D4E0A5AaB79C18c14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x7Bc69C22c400420Cd310FF284017b4E3e989F823',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x0E4Eb7bADa4B56b81310Dba13ec95FdCA6358E1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x3553ed2C3557DB4b5fC563cA4BaEa385EFb83F1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x718d3744Dd4E297365b9C6d9b9F33B6A865689D3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x54458729Da30642B5eCCBBacF5dD07a51BD4ae28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0xcB59a647c18DE85E5b04008fDdd4b9D21dBc4834',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x0F30D63F28DD322C8d9d2E37C83A6bd474634984',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 541.81,
    hoaxs_reward: 0,
    total_rewards: 541.81,
  },
  {
    address: '0x8e8f84b84102dbe31fd8fcec07d8efe8ca66e79d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 537.3889270567928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 537.39,
  },
  {
    address: '0xebc8b84aae52eb5e93b137b054f3d597f3b660fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 536.3902520199358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 536.39,
  },
  {
    address: '0xe299fa5f79f0b1c875feef1c516ece9c95ca6382',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 535.2097367911798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 535.21,
  },
  {
    address: '0x4e6203442764866862b2b91261844518c07167f6',
    minters_reward: 0,
    lp_providers_reward: 535.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 535.19,
  },
  {
    address: '0x17df0b2920d63f4bfb703e341ca0edbe2be31077',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 534.549247609529,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 534.55,
  },
  {
    address: '0x812b4b8b0e0de30e64e8ae5bfaff28d6a9df273a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 533.7179873688801,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 533.72,
  },
  {
    address: '0xbe3ff62e3dbce9d38eac5029b5f977ec6531cdd4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 530.7472603306852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 530.75,
  },
  {
    address: '0xfbbf9063f347d8f08ad7525fcf98bbb3ffe11cae',
    minters_reward: 0,
    lp_providers_reward: 530.17,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 530.17,
  },
  {
    address: '0x69357c13130cc4417f1910c2bc6516d65979f945',
    minters_reward: 0,
    lp_providers_reward: 529.83,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 529.83,
  },
  {
    address: '0x4c96a0e85359708e10da3916143d2d18c3f3cc01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 527.630980097432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 527.63,
  },
  {
    address: '0x1adcf8ec739ff0df6a8a63298018a01179f3cf11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 526.9929293965316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 526.99,
  },
  {
    address: '0x011349ad2ea488fc57ed42708c8aa0d6598f64e1',
    minters_reward: 0,
    lp_providers_reward: 444.23,
    swapers_reward: 81.26671848011809,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 525.5,
  },
  {
    address: '0xd6632d3ccde18be02e2977aef3667a699187f0e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 525.4073583359458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 525.41,
  },
  {
    address: '0x2179e2cbffa7c9d90fc520e363089c1fe2c9774b',
    minters_reward: 0,
    lp_providers_reward: 521.18,
    swapers_reward: 2.9995377025730123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 524.18,
  },
  {
    address: '0x8375eb237c8ccafecaa8e220b21df3f549f81240',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 523.6450213565041,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 523.65,
  },
  {
    address: '0x80f1536bc45445298d6d5d305ab4eb9ca57d5a6f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 522.9609712124242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 522.96,
  },
  {
    address: '0x87d28f6cbbac5afcdf2a6bbb369548b02e726162',
    minters_reward: 0,
    lp_providers_reward: 219.13,
    swapers_reward: 302.5985257191921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 521.73,
  },
  {
    address: '0x0efef56d7717755e98a2a292ddf4d33fd95b8bfc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 521.2400929193896,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 521.24,
  },
  {
    address: '0xaef87b30637f177a4db561d95895ba5f3aa2705f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 519.5085846796773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 519.51,
  },
  {
    address: '0xa06d0dc0f00d1dd3eb83477e8788dbf89f30bb09',
    minters_reward: 0,
    lp_providers_reward: 116.65,
    swapers_reward: 400.98858398117767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 517.64,
  },
  {
    address: '0x5a7eb7afd532f06761484f8adf9b91bead3fbc36',
    minters_reward: 0,
    lp_providers_reward: 349.85,
    swapers_reward: 166.95640684505065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 516.81,
  },
  {
    address: '0xbbc6e1c2fb494de9d0b6d88acaf61da00b021123',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 516.4522508380516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 516.45,
  },
  {
    address: '0xd147bfd8d38e97115f2b748fef821a7c6590c907',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 512.1901834925822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 512.19,
  },
  {
    address: '0xeaab8389ca8fa6aee66c587ae5cc7b7da44b0023',
    minters_reward: 0,
    lp_providers_reward: 5.44,
    swapers_reward: 234.87786310830415,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 511.22,
  },
  {
    address: '0x25a30bd02b8923aac802b6b91a42dec0e00fad5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 511.1734562714629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 511.17,
  },
  {
    address: '0xaeb5eb89fd1f7e42548193b8a766a205e5e93e35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 511.1668895890632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 511.17,
  },
  {
    address: '0x43898807be33ae07f04ee354357f26cde55d7e5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 511.12207379401696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 511.12,
  },
  {
    address: '0x2ea518a71b52461b013460e72ca7e02008b5fe64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 509.43524239986635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 509.44,
  },
  {
    address: '0x95719cd8eca117a9f133d6205648b307c22580c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 509.1393121549523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 509.14,
  },
  {
    address: '0x9abd1869167b5ef728fbb2031cf420f830c75c70',
    minters_reward: 0,
    lp_providers_reward: 67.65,
    swapers_reward: 440.0332899213225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 507.68,
  },
  {
    address: '0x890f47813857b1830bb234ec48b3f491610e8f98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 507.5917310051554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 507.59,
  },
  {
    address: '0x5aafd848ac4484e9cb4619d83c2686042b5f4a35',
    minters_reward: 0,
    lp_providers_reward: 349.95,
    swapers_reward: 157.26286058966318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 507.21,
  },
  {
    address: '0x36c5edcf698ccd913c6de49f3b925c94be1ee5cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 506.46539633319327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 506.47,
  },
  {
    address: '0x866a8b312ddd9a583d80fe1cea055a565c1d9848',
    minters_reward: 0,
    lp_providers_reward: 407.8,
    swapers_reward: 97.9403631378296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 505.74,
  },
  {
    address: '0x94f1d9b8687b89b89cba4aa0a10aa591459a3684',
    minters_reward: 0,
    lp_providers_reward: 505.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 505.37,
  },
  {
    address: '0x8ccb1ad5fd4be6f6fc292a7dd46e2f37675b4f21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 504.98358214019794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.98,
  },
  {
    address: '0xcd032a1eeef94544e57a7a2fc4c98bd0b317baaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 504.68154318716654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.68,
  },
  {
    address: '0xeb798045ac1e0303870e59a5215a525759710c05',
    minters_reward: 405.04,
    lp_providers_reward: 5.7,
    swapers_reward: 93.71608616566212,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.46,
  },
  {
    address: '0x57d3e3542bdeabf2d6079934b288e306ef902306',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 504.32997252310224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 504.33,
  },
  {
    address: '0x21d0e0ef02af3733831df26de0335b59ce92d5c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 502.4037414143339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 502.4,
  },
  {
    address: '0x7f2b7478230ded365308ec9871e6bb3188bd075e',
    minters_reward: 0,
    lp_providers_reward: 401.84,
    swapers_reward: 100.39337658434805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 502.23,
  },
  {
    address: '0x8e48470f264fd5c2a0f113b4f28128159d935b6f',
    minters_reward: 0,
    lp_providers_reward: 282.33,
    swapers_reward: 216.42464539184803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 498.75,
  },
  {
    address: '0x7afacfcacc0806bdf296d9bff7fe0295a47c801f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 496.39124161025336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 496.39,
  },
  {
    address: '0x49f2aa9785afd915de8bbd630766f74d093f453a',
    minters_reward: 0,
    lp_providers_reward: 372.51,
    swapers_reward: 122.11940421802106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 494.63,
  },
  {
    address: '0xb1018a1a919468d213e3059d951e00c000bce76f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 494.505593437814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 494.51,
  },
  {
    address: '0xff03abfa1d70f200c536c759812fa0255bf87a1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 493.99180281329495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 493.99,
  },
  {
    address: '0x93631f5aed49014a11652e1f94ee724357d62118',
    minters_reward: 0,
    lp_providers_reward: 125.96,
    swapers_reward: 367.3229371486182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 493.28,
  },
  {
    address: '0xcda948a4f0c36713e37b00655ef5377cad825c51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 493.06082358979825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 493.06,
  },
  {
    address: '0xcf9654e4a14c5bac95e0e10483f79332d2aac128',
    minters_reward: 0,
    lp_providers_reward: 399.31,
    swapers_reward: 93.21781964920933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 492.53,
  },
  {
    address: '0x73abd7734719bc898ea167fa048c450dee15c8bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 491.8975372956318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 491.9,
  },
  {
    address: '0x109d8604cd6ce0a64bf6121312f127fcb19abd5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 490.16534486213794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 490.17,
  },
  {
    address: '0x5d668c5724befff96797ca506eb8da7672081f9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 489.35343339913055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 489.35,
  },
  {
    address: '0xccae1a6eea4a77732b3801d0a835f109b07229c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 489.30283412071,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 489.3,
  },
  {
    address: '0xcf4c5e5d22f55ebed330ac95801a685480723b45',
    minters_reward: 0,
    lp_providers_reward: 277.39,
    swapers_reward: 210.34042125096866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 487.73,
  },
  {
    address: '0x6bb34d86b1f0c832e4ec5c4f7831414875c4630c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 487.4384708019833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 487.44,
  },
  {
    address: '0x915a3ac81db68025c591996ca237b16df9b97638',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 81.57282024457952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 486.61,
  },
  {
    address: '0x8e86d356a64bf1d78791282cd30e46245fcb6261',
    minters_reward: 0,
    lp_providers_reward: 486.5,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 486.5,
  },
  {
    address: '0x85b2dbdd2e4a2967bacd5ef346a2f340571d4a68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 485.10606602404465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 485.11,
  },
  {
    address: '0x23088d60f27dec692aa062b03ed3b8356364397d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 483.720585632198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 483.72,
  },
  {
    address: '0x473c737fc98b335dfd8965fa5f6c33882547d9ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 481.7507796078443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 481.75,
  },
  {
    address: '0x5c9fce1fb626b27bcf154c2bd8c87c5fcdf0b1c6',
    minters_reward: 0,
    lp_providers_reward: 352.14,
    swapers_reward: 129.40891254253592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 481.55,
  },
  {
    address: '0xcfcf848816b64191c4c7688a8b42a2c1487dae96',
    minters_reward: 0,
    lp_providers_reward: 480.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 480.93,
  },
  {
    address: '0x0a893c5dbd7285fda239c48a01d488f476d356ac',
    minters_reward: 0,
    lp_providers_reward: 474.76,
    swapers_reward: 3.317545190339864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 478.08,
  },
  {
    address: '0x9d08e2adbe1078e86c7460418390781dd7d757cc',
    minters_reward: 0,
    lp_providers_reward: 150.14,
    swapers_reward: 56.533457861610906,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 477.57,
  },
  {
    address: '0x5850bfc214b2b207cc5fa314370bdd1486df7ffd',
    minters_reward: 0,
    lp_providers_reward: 477.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 477.15,
  },
  {
    address: '0x60b30bb57ad93e2d5d746b61dea28df450f4c7f0',
    minters_reward: 0,
    lp_providers_reward: 159.92,
    swapers_reward: 316.9793362994328,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 476.9,
  },
  {
    address: '0x25c62635bd32ca156f141132b955ec6e23982c48',
    minters_reward: 0,
    lp_providers_reward: 304.48,
    swapers_reward: 171.87725493591483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 476.36,
  },
  {
    address: '0x57b778fbd5f6d82852e3d4677f091cc91f48fd8c',
    minters_reward: 0,
    lp_providers_reward: 221.32,
    swapers_reward: 254.5120786319365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 475.83,
  },
  {
    address: '0x6cf9a4602f7c1ec74b2ddeae41c1ead560e253e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 475.77031955316585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 475.77,
  },
  {
    address: '0x8202092fdc93961a4ba12d411c3ff163677e814f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 475.23352723663794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 475.23,
  },
  {
    address: '0x43d06ce861383642461c45cf5540d5e51d054f8e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 474.4242432495744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 474.42,
  },
  {
    address: '0xa8392a2bf587160ce3d9383ed89c63a06ee3298d',
    minters_reward: 0,
    lp_providers_reward: 473.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 473.94,
  },
  {
    address: '0x14436b9e5a65e220433628990dda323e775e61bd',
    minters_reward: 0,
    lp_providers_reward: 393.13,
    swapers_reward: 79.89138758091616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 473.02,
  },
  {
    address: '0x55ce38736e19fdd7de11d48a7378aa7118144464',
    minters_reward: 405.04,
    lp_providers_reward: 67.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 472.15,
  },
  {
    address: '0xe04a517d23bdc8b9b227246abe33fab10b682763',
    minters_reward: 0,
    lp_providers_reward: 113.84,
    swapers_reward: 356.0421974924834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 469.88,
  },
  {
    address: '0x90cd7f3661f8ccc582f186601bf806a6691c7a44',
    minters_reward: 0,
    lp_providers_reward: 13.89,
    swapers_reward: 455.9089218683321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 469.8,
  },
  {
    address: '0xf898a2435a48aa054f579ed6957cf702d4b32785',
    minters_reward: 0,
    lp_providers_reward: 356.38,
    swapers_reward: 113.35142380214216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 469.73,
  },
  {
    address: '0xdfa04c5464e7915215002f2d77602435cb3245f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 468.17784332809964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 468.18,
  },
  {
    address: '0x1e65c6ded3a739215bf0baf060c9ed50b3bf43bc',
    minters_reward: 0,
    lp_providers_reward: 272.46,
    swapers_reward: 195.44578308121112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 467.91,
  },
  {
    address: '0xb685178bf8741e59eace8af0b54e65eafa031c8c',
    minters_reward: 0,
    lp_providers_reward: 370.09,
    swapers_reward: 95.35307834600923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 465.44,
  },
  {
    address: '0xd0f431701a33d7289cf3d37ec2402a0d65dd13f8',
    minters_reward: 0,
    lp_providers_reward: 92.88,
    swapers_reward: 371.9974722162509,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 464.88,
  },
  {
    address: '0xa644b1affe99746ab346fa52722df69f8c16f8b2',
    minters_reward: 405.04,
    lp_providers_reward: 54.71,
    swapers_reward: 4.0269030766194644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 463.78,
  },
  {
    address: '0xdc6aa9d473d863b84629fa9d061e4ea8429997d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 463.3813480069331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 463.38,
  },
  {
    address: '0x2fda033349b651524daa77981a9f9e2ee28beb10',
    minters_reward: 405.04,
    lp_providers_reward: 55.03,
    swapers_reward: 2.263373307079631,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 462.33,
  },
  {
    address: '0x2e8220d93aa42839352800d1b06e9a3cc25e4f61',
    minters_reward: 0,
    lp_providers_reward: 162.11,
    swapers_reward: 299.6177710017776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 461.73,
  },
  {
    address: '0x07a4177fea57456a84ced84459953cfbbe881a4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 461.26043993698727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 461.26,
  },
  {
    address: '0x22b917e5025a36bd9f4d2377c43007f2903d2b6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 459.86301697426194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 459.86,
  },
  {
    address: '0x804c7d435a2b4b5f6ed5c8e9f47bb3b4ee1bc897',
    minters_reward: 0,
    lp_providers_reward: 151.35,
    swapers_reward: 306.72152926397564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 458.07,
  },
  {
    address: '0xf22bf13a914fc10a7376934a3027f0ca74322c95',
    minters_reward: 0,
    lp_providers_reward: 86.39,
    swapers_reward: 371.6402962453319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 458.03,
  },
  {
    address: '0x4cf7b09de2e74d68e72518b95e2e898e28cce917',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 457.94263149072015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.94,
  },
  {
    address: '0xc973b9d9b07576b860e42d828551870d28f3631f',
    minters_reward: 0,
    lp_providers_reward: 49.05,
    swapers_reward: 408.53691039891817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.59,
  },
  {
    address: '0x1fa25bc9d71a5f1df87d26d4401e56462c7d4288',
    minters_reward: 0,
    lp_providers_reward: 317.68,
    swapers_reward: 139.90651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.59,
  },
  {
    address: '0x30d6dd7a12ddcd1ee0b39c36b6e0c4ee6a0d2136',
    minters_reward: 0,
    lp_providers_reward: 457.41,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 457.41,
  },
  {
    address: '0xbf54bfd4dc431b1f8b3f6501c20009278d330d55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 455.068970916217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 455.07,
  },
  {
    address: '0x03838f2a3af95a4485bab46585945448f444e728',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 454.2152545725936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 454.22,
  },
  {
    address: '0xf98b332149cfeab68748bbd6a361f529798b27f7',
    minters_reward: 0,
    lp_providers_reward: 33.91,
    swapers_reward: 418.97982272112955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 452.89,
  },
  {
    address: '0x3c99c3cb1719287765eaadb109d30e74e76d095b',
    minters_reward: 405.04,
    lp_providers_reward: 47.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 452.66,
  },
  {
    address: '0x09275039d5725318227914a358ed761ed0ffaa1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 452.53113884641914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 452.53,
  },
  {
    address: '0x83934737be4724c0c930520b3f51d399e7153346',
    minters_reward: 0,
    lp_providers_reward: 170.61,
    swapers_reward: 10.394545491492106,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 451.9,
  },
  {
    address: '0x136a87bbe1f138e6e16fcdef360799a0a0c57c4f',
    minters_reward: 405.04,
    lp_providers_reward: 5.08,
    swapers_reward: 41.165872270219104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 451.29,
  },
  {
    address: '0x5baba585c3551c9d14fb6be49f22c15af1fe8c9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.4853332479135,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 450.39,
  },
  {
    address: '0xb0aab6f0929777af5d3f51450b708baeb22cda46',
    minters_reward: 0,
    lp_providers_reward: 177.23,
    swapers_reward: 272.1593368530993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 449.39,
  },
  {
    address: '0x686def69ce32f1fadd0cd6336fe85b3972f41214',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 447.2788019719168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 447.28,
  },
  {
    address: '0xda01cd180abff9943428dc327eda849dadc83add',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 446.6275060489451,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 446.63,
  },
  {
    address: '0x9d7b92321810400881939736975a11314d013955',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 444.51022100915503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 444.51,
  },
  {
    address: '0x7435af923c222e9113ca404803fbeab99bb4fa70',
    minters_reward: 0,
    lp_providers_reward: 444.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 444.32,
  },
  {
    address: '0x97f73496063395ddaa21faeea78f5aa5c3908ba4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 441.54127913536604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 441.54,
  },
  {
    address: '0x8e9777071dcf4ae1e0b7d8ebce5344c377fb4f9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 441.14736841804626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 441.15,
  },
  {
    address: '0x89d7b42b2748d050d5cb5fdc413071e3b4aee6e2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 34.327476157141355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 439.37,
  },
  {
    address: '0xff0d8388104621f9fd416c7d35c4cbcf30c1f566',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 439.00034801516784,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 439,
  },
  {
    address: '0x4f26078dc9f7bc6c616b6bb625c5034f0500dbd1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 436.26184310999633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 436.26,
  },
  {
    address: '0x1bed8319ad56780f303b226bfca60bad29db9e66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 435.734748671058,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 435.73,
  },
  {
    address: '0xf2dd1b6c1a93782de9171c1aa203c0ab833edd84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 434.1502168506264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 434.15,
  },
  {
    address: '0x1d79914f1dbde55506c7de892f765644af3c529b',
    minters_reward: 0,
    lp_providers_reward: 141.72,
    swapers_reward: 21.502351032075207,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 434.12,
  },
  {
    address: '0x48fc7b4e0a0effb77ec21553f9311174918604c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 432.3410096731172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 432.34,
  },
  {
    address: '0xef874f7fd0c262b7cd6324b4c537ddec6b19aaec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 431.63636559949236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 431.64,
  },
  {
    address: '0xe233d8ced8a3a43fc5f53e546975852f313148af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 430.6224379283443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 430.62,
  },
  {
    address: '0xd0b3dfb9019f7986661fa3e3969cbca4d8fbe08d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 430.06219822183306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 430.06,
  },
  {
    address: '0x66af5882264a47443b1a736134db38012a7c6b6a',
    minters_reward: 0,
    lp_providers_reward: 170.77,
    swapers_reward: 259.2196357716182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.99,
  },
  {
    address: '0x7caf39086ca10efebb830edeea9759169eb99875',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.89549155165315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.9,
  },
  {
    address: '0xe3910d12538f744c775660ae190b7d35f579ab93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.5217298155414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.52,
  },
  {
    address: '0x76c1d1c8053be7e731a97e96cc0a4f256a1cefd1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.43442840326844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.43,
  },
  {
    address: '0x2caa523dd5d280f9bb753e6e69c817cb6e6475fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 429.3522295038378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 429.35,
  },
  {
    address: '0xe4eeb9fa30556e2439d48c9f37f01350d1f0a977',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 427.941024124051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 427.94,
  },
  {
    address: '0xe70037dee17c7b16d4589da381da5dcf05aa4a50',
    minters_reward: 0,
    lp_providers_reward: 378.05,
    swapers_reward: 49.76113234345485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 427.81,
  },
  {
    address: '0xdfac9660ae53e5691cefdb1280e8be8705e770a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 426.25914063046235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 426.26,
  },
  {
    address: '0x2bfe8e92cd4687ccdc98d231a8c8cf3eddf5e93d',
    minters_reward: 0,
    lp_providers_reward: 426.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 426.19,
  },
  {
    address: '0xfe4ba08cf4d59bf2c3f3902fc6d0863d54aa6859',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 426.0874065238787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 426.09,
  },
  {
    address: '0xcd0347158d7254109e3984868e67f7c4e60426ae',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 18.741022081711982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 423.78,
  },
  {
    address: '0x50e61c13ece09c31316b2821b4de3a752ae4bc80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 423.25680525806035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 423.26,
  },
  {
    address: '0x3fc1ab40a314f7964200e9629c7189c0a05d3c5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.01748830390432,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 422.92,
  },
  {
    address: '0x3193e8af09fc3ebdbf1608fd8226d4c659f3f737',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 422.4926135589166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 422.49,
  },
  {
    address: '0x82275591710603843fecc08a28f1014cf8d73c85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 422.2000333325677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 422.2,
  },
  {
    address: '0xe26ae45c7201ba2dd7fd0369601775eb5a32c3e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 421.7180593593168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 421.72,
  },
  {
    address: '0x6f648f391756df107f95bf4ff7dd6d80e1c80cde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 421.2452780542413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 421.25,
  },
  {
    address: '0xfd58304a56e19e3a5ae55310824420ed4e0fbad0',
    minters_reward: 0,
    lp_providers_reward: 421,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 421,
  },
  {
    address: '0x08e40997d588afc066e66926d2a91850492f9676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 148.53553111985696,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 419.44,
  },
  {
    address: '0xea3ee4b011b28297d7752153229e7bc5d915e646',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 14.23242921271519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 419.27,
  },
  {
    address: '0x2839805e004b64e4e3189802f1efb06c02506f8d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 419.0609333294391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 419.06,
  },
  {
    address: '0xd693cb2307ca32fe18a2f9e2f902023287944596',
    minters_reward: 405.04,
    lp_providers_reward: 13.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 418.85,
  },
  {
    address: '0x4c25db89e0a717ae69ea615db3e5a41cccb66f0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 417.71111794212436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 417.71,
  },
  {
    address: '0xd5e51b168822d51ea71b134bad4ea6c4e0d08c2c',
    minters_reward: 0,
    lp_providers_reward: 411.04,
    swapers_reward: 6.571223217669793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 417.61,
  },
  {
    address: '0x0380fef903ecc9b92316d1acd4671aa7916436d1',
    minters_reward: 0,
    lp_providers_reward: 384.91,
    swapers_reward: 32.32136935889552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 417.23,
  },
  {
    address: '0x6b40625fb110dbc837e6d93ff067ec00fc5e5988',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 416.85427080524124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 416.85,
  },
  {
    address: '0x2edcd98b168ddc71abd806e00896c0c152db6550',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 416.7863544492508,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 416.79,
  },
  {
    address: '0x1769e88b8c5a0b3496a790e185e1053465f2f7f5',
    minters_reward: 0,
    lp_providers_reward: 210.53,
    swapers_reward: 205.7832589230818,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 416.31,
  },
  {
    address: '0x235ac229b1c0faa0fdcbbe996a2774e85035928f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 415.4603386688232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 415.46,
  },
  {
    address: '0xdae95c7736fdc20783cc0e45a2d1a9a76de821e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 414.45203431662895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 414.45,
  },
  {
    address: '0x369423430b37be416b76dd7dc54d17dde2dc2d15',
    minters_reward: 0,
    lp_providers_reward: 209.3,
    swapers_reward: 204.60753431891646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.91,
  },
  {
    address: '0xef5df91452fffe48feb52b7da46f2b1963b2654b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 413.719810405812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.72,
  },
  {
    address: '0x6cd944595193c73f6b57ae896f5a0af45a984faf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 413.2778077927259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.28,
  },
  {
    address: '0x4b79a5d5039e3531df1db42b86aaf45a13a74645',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 413.12894836345305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 413.13,
  },
  {
    address: '0x5cec273bcf27773ff7afbc7cb79f6b6137142e03',
    minters_reward: 0,
    lp_providers_reward: 233.98,
    swapers_reward: 178.60808253040702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 412.59,
  },
  {
    address: '0x7edb193ae389682008c112c40673d88502bc4a98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 412.5269397820916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 412.53,
  },
  {
    address: '0xe2b630c54b60afeb3053a13a9af83dd0815a2847',
    minters_reward: 0,
    lp_providers_reward: 26,
    swapers_reward: 385.39917638155544,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 411.4,
  },
  {
    address: '0x78499636380635792342d6bba936a31e4222f56b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.3644617772631,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 411.26,
  },
  {
    address: '0xcbd4946af66d9451d7acfafee1234f8925747ebb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 411.2416235109337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 411.24,
  },
  {
    address: '0x06114f14f690cd3516efa36fccb7715ba030d9c6',
    minters_reward: 0,
    lp_providers_reward: 118.59,
    swapers_reward: 290.34500302466245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 408.94,
  },
  {
    address: '0xa0c11e5ffc0199fad5d957ea4c8dae7e79041617',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 408.0740589241869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 408.07,
  },
  {
    address: '0xc7f88e349ee75301e5a0e6ab7278a69091444e97',
    minters_reward: 0,
    lp_providers_reward: 3.8,
    swapers_reward: 404.0649909281863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 407.86,
  },
  {
    address: '0xfc0c23a038a99364ff77e1ab967132165e8bf706',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 407.08246349144576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 407.08,
  },
  {
    address: '0x243582c099f4069269ee4083b54fd8fd482c8b5e',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0.6816306692171901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.72,
  },
  {
    address: '0xE74Ac06850dDAff64E08a2c0820F80E9aEA7d706',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8945d2cA9f2e4d052487d3c773f48d590b98B875',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x80532A4b84EcD47039c1374693898fc6D4f81b2d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x566AE4163853C13375879Db2474002B6b8384730',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x27aC16aF301A8b00c839D68B2A5Aee8B8750e88F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x030583A8B6880FD09269D95624C8a69f0cEBab9B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x0F4bC1ec151b27a50d268aEb057D43B9cC357022',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x94B4DF81b1Fa30895c8e0B1e35357A7711667B63',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xC9b375c222C97bFFD2d019C62d198ee6abC6D13C',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xDD3BF3a100B594E72265ce19078BeC9996D049FA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xffd6abF4577174eFC0Ba4c703D95D270F0e74333',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x269DF84C8FA6D83E498b96B329cb9Fa659E45dA5',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xEfeEE4F347f7f8283Cb87Cf1B6e053Ea01366601',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8BBd0B963E9Ac04ba5399c0805D7022f4580e952',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6C2809a27a77ddF0ceC882C2B9c63c24E0ea996d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x51403a396851116e61F5221900Ec3C8ebBa6270c',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x05F369DC5B5db200959352D39A483E1452598aDD',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3010c64487473F95A3830C2fa45deF54d7b9028D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6F6fDE1CCdB84b708BdF473360b6032045a22d0F',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x95807c8D68abD45AFf7956F856Cd17765df2503B',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xb6100ce65C1D30B29C06a6B0836eB554152bB57d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3Ae639376F848F1829B10854E1664Acac9852dd0',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8ee54944b46cE419092f964B1ba8628E0c0E11F6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xa935fBA289c74eFE7c23aD73f3b1BB060b7530B3',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3Bf3512E48Cd3215a3f8240810309B09bb3a613E',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x8f3449B871E3B4c75463fb3316C506f7aFbCf248',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6F34c4198E1d8C3D97ed223FEBc032Ac892d4BaA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xe1ffEB90F543b3eAbe5Eca17a524F21e451E50b6',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xB4610b5227fB6B80F73AB9826b45110a9E9d8FDe',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x3D87Cb40FB8fFe4B05eCCe3c3eFD54406B23a586',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x94Ac45bd88293A82F1e46caD2D91da3ffb6e977b',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xFa0275ef8B2Ba4480E4EAe78C89B8277e25d3180',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x20712cCD7DdFF9d8eaFa932004855b26729CA437',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xe10A337549a4E2DEA26188FbF6d9CA9F56b3f19D',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x047eA31614FC085cE7B80412FBaB65fD51a8bb7a',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xddF104bc8E618f9f55b0E8480705dA081c97c921',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x80d2E52BBA0b72cEF696ea9f43D1B8573e9b933f',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x1C79BaD30a75f0Be25898Be75aF758A8F6Eeb0eb',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x238Cc3eA0228dA2dD8dE8fe7d90bf50c2B136700',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x5C2a2B3EC02C1468d6E9ff4c3aC585fd0EA39f59',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xeDF6661C0ad8d6E1f23F2d65fAd39426309b5003',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xB05dD7893EFa54796ed0D57F993B1C8Ec922293d',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x07423A6D459B8215599538b57b81F26EC9613C29',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xBf223e3800F80BeFbF9F1026d8FFB0E9636395a8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xdB298bB50fa4FaBcadECF5512c2b3b398C07d5a8',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x72E35B2a6260e52f034dd08FA68B3933c78880Dc',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xd49AC6C4753Dac8a9C5944f8335FBC68a18BFAba',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x1A520A3cD5C73069E0c39c34c2d68f15156b47fA',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x2216F54f7da436D74f0d96f8FeB9C6C2ebc1f617',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x5cE7aA5337d45deE2e89DEEc34DE2C838dC5EEcE',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xe6686Bc1f037ba02525a22B4360719Dcd21D2eE2',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xd3058732Fb3825035B6d5FC72dE279133d93e3A1',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x6D743D27C0A88195165D7F731DaC03a681C25f24',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0xA84885a2451272e133815a2d586A84e4b8fa6Cba',
    minters_reward: 405.04,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 405.04,
  },
  {
    address: '0x75efb8143e9f5f48382784dc5b2ce58e9b7a8604',
    minters_reward: 0,
    lp_providers_reward: 403.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 403.9,
  },
  {
    address: '0x080af17a23638ba8cea376f657dc866e522b8bed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 403.40051025237005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 403.4,
  },
  {
    address: '0xef2cfec535c1506fe807f1c90ea74669c5fc9104',
    minters_reward: 0,
    lp_providers_reward: 239.69,
    swapers_reward: 162.83373571148124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 402.52,
  },
  {
    address: '0xb6dfa7e5a9a4dbe333744c95f8b3764a352c5faa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 402.4771081027412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 402.48,
  },
  {
    address: '0x8997023f133edfb50af47e950fe38f7601166e78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 401.4557874924435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 401.46,
  },
  {
    address: '0xf67b73d5ec727c212bcf3bd3b4d38d899181a16d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 401.3675815600473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 401.37,
  },
  {
    address: '0x8928f06712b9ff8a876a4f0b5dc0f656a80ed68a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 400.9901392296074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 400.99,
  },
  {
    address: '0x1d1cef60dea30bb01df247e6f4243a28e6a7fe2b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 400.95871845521583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 400.96,
  },
  {
    address: '0x2d8f43bd124f5111681f00c71767bf97b15fa314',
    minters_reward: 0,
    lp_providers_reward: 37.27,
    swapers_reward: 363.5668986529657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 400.84,
  },
  {
    address: '0x7612f52caf99aa1fc06876183c78241502f9443e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 399.22705241380817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 399.23,
  },
  {
    address: '0x718967a47702e0c5ad8d36bb4976ef89d6ae7eaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 398.1391030523872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 398.14,
  },
  {
    address: '0xde179013000f2398d73841cfa4415e36726493db',
    minters_reward: 0,
    lp_providers_reward: 75.87,
    swapers_reward: 322.15569870245344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 398.03,
  },
  {
    address: '0x47ad10a05b72d19be2bcf5061368c461052483d9',
    minters_reward: 0,
    lp_providers_reward: 255.46,
    swapers_reward: 142.42984077641722,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 397.89,
  },
  {
    address: '0x194620353a9bc757a023777205c8e948fa0d8538',
    minters_reward: 0,
    lp_providers_reward: 397.64,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 397.64,
  },
  {
    address: '0x9d1427c2a7cfec2df77ec67a8d54ff7c69a8a679',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 126.24582230836452,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 397.15,
  },
  {
    address: '0x264402bed6779bc2054f027a0cab5fa13e312d6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 397.00470843100555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 397,
  },
  {
    address: '0x15bea5bf2105ce38a6e15e72708eeb7a6c281d84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 396.77434288585425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 396.77,
  },
  {
    address: '0x06b185dec9db453ab6331930e1d850c991ed1334',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 396.4354369420748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 396.44,
  },
  {
    address: '0x196b064daab7a98d2e256193bdc0bec37beffcbd',
    minters_reward: 0,
    lp_providers_reward: 273.56,
    swapers_reward: 122.87745470422036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 396.44,
  },
  {
    address: '0x4dffb6d5cb89602969f6e549dcbf7de8d0ca0c83',
    minters_reward: 0,
    lp_providers_reward: 395.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 395.48,
  },
  {
    address: '0x3d8dcd0848c10de61666ba830a5f107b01276a03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 394.2205403337345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 394.22,
  },
  {
    address: '0xc70765ce72945f22e2d08a6fd5d31d5533612915',
    minters_reward: 0,
    lp_providers_reward: 318.23,
    swapers_reward: 75.65185378745252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 393.88,
  },
  {
    address: '0x2b0c4825d9e94ebd8fdcee7af1e8bbed96bc3bc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 392.7837101058339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 392.78,
  },
  {
    address: '0x2ae8636a5e447b93905480ae7e59401e65888a32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 392.19015339810807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 392.19,
  },
  {
    address: '0x79a9891d3c23bce0a9bcfef5ca00757d4dc4afdd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 392.126110112451,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 392.13,
  },
  {
    address: '0x52cfc5041e0d1ef89efa72101d45c8b2d95b47bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 391.62973037221536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 391.63,
  },
  {
    address: '0x81134166c117ae6c8366c36be9e886b0f7147fae',
    minters_reward: 0,
    lp_providers_reward: 40.47,
    swapers_reward: 346.9492604611431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 387.42,
  },
  {
    address: '0xab50b67fae1a0f8b7a8f5435ee76b66c599bae0d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 387.40924975693275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 387.41,
  },
  {
    address: '0x6361c82a61c3e519e9b51b3a823d2bce31fe1dd1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 385.9691388119505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.97,
  },
  {
    address: '0x5e2e9507c46040e495a8f6b5925d8bd49249194f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 385.72351448370455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.72,
  },
  {
    address: '0xabc7d5c2dc9c2dd8e9d733ca658a8550bca7c7f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 385.5523653987718,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.55,
  },
  {
    address: '0xc89150b64045702bfcac428c06d89036c2b97ed4',
    minters_reward: 0,
    lp_providers_reward: 272.31,
    swapers_reward: 113.05520135334736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.37,
  },
  {
    address: '0x37e1d44f28cf848d4ba376f0e4f5919f35e18f72',
    minters_reward: 0,
    lp_providers_reward: 280.45,
    swapers_reward: 104.6798820055773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 385.13,
  },
  {
    address: '0x48d3081995b50627c505b43eafc1ca4e641127c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.01504233004349,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 383.92,
  },
  {
    address: '0x91c1f23b038f35fe00cbb46361d52350acf360b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 383.5536567691119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 383.55,
  },
  {
    address: '0x625348bdcbbbf11767bbab2cdced4e428717ad87',
    minters_reward: 0,
    lp_providers_reward: 287.63,
    swapers_reward: 95.53937408504487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 383.17,
  },
  {
    address: '0x1b9e67274e44b8eddd3f51d6426d6437ba176882',
    minters_reward: 0,
    lp_providers_reward: 382.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 382.33,
  },
  {
    address: '0x2518b311daba439f36d44531c473da06eb8c1ab7',
    minters_reward: 0,
    lp_providers_reward: 312.41,
    swapers_reward: 68.94476002530908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 381.35,
  },
  {
    address: '0x9d3d61828a9cd998663fc5c5a09134a435ad6f58',
    minters_reward: 0,
    lp_providers_reward: 238.03,
    swapers_reward: 143.2281681589224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 381.26,
  },
  {
    address: '0x2b4473a435ac75aad81d5fd7651f07ee79765ba3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 380.9201174580211,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 380.92,
  },
  {
    address: '0x8dacbe823f333bb021018e7a49d170910e1749e6',
    minters_reward: 0,
    lp_providers_reward: 261.79,
    swapers_reward: 118.63524311777455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 380.43,
  },
  {
    address: '0x8008ef3b1878cb97dd271d788b484538446b4da9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 379.7590991539562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 379.76,
  },
  {
    address: '0xf715fca60230ddb83f9c7d3e30ff61b9325b1a39',
    minters_reward: 0,
    lp_providers_reward: 377.76,
    swapers_reward: 0.40656841900883306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 378.17,
  },
  {
    address: '0x8febff3c103b32c61fa8c7401185595f4e1d8aa9',
    minters_reward: 0,
    lp_providers_reward: 272.17,
    swapers_reward: 105.77689730489227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.95,
  },
  {
    address: '0xa97adf616d1d15f241cc9b32dab7930bd6798443',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 377.91438131442834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.91,
  },
  {
    address: '0x09f4a65115447ad68cd85214f28c1a4afef3e05b',
    minters_reward: 0,
    lp_providers_reward: 377.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.39,
  },
  {
    address: '0xb8b70a510cc3753f71a652f9e786f11584617443',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 377.2433814405184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 377.24,
  },
  {
    address: '0x42ffdc8dfd16636523d444285272c0ba4af7eb15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 375.57462523349767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 375.57,
  },
  {
    address: '0x4f6c295ed73febb7c3b51751c90a2e8d95a42279',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 374.8529605076525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.85,
  },
  {
    address: '0xed525eb9a67b666fcb095a7bd1089614f31bc7e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.83436472879282,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 374.73,
  },
  {
    address: '0x217bc9ebd4027d6c135ddfd85d518857679aa8a2',
    minters_reward: 0,
    lp_providers_reward: 374.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.69,
  },
  {
    address: '0xb288cec5783b23f50113fa8f4794b01e2bd7134f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 374.3744519175942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.37,
  },
  {
    address: '0xe421cd0e681ef71784d1095c7b42b3b07e1d7d43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 374.2292977978453,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 374.23,
  },
  {
    address: '0x54b0aef17149f05f056243d21ab380bd1ff7c412',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 373.42066966442496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 373.42,
  },
  {
    address: '0x846c325de144a2cd121faac409d0b4dc35525c06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 372.0805647494228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 372.08,
  },
  {
    address: '0xfee94f9d2589a619bb0c440d6a22f245f3c89c75',
    minters_reward: 0,
    lp_providers_reward: 371.77,
    swapers_reward: 0.013012115878176281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 371.78,
  },
  {
    address: '0x51978fff0b9367a146fedeadd49c71bef939f77a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 371.6662849106311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 371.67,
  },
  {
    address: '0x275ead7b3edf2d15bd3da3e8947399f63a4d6a9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.77238032394153,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 371.67,
  },
  {
    address: '0x8827a21ed5e90c81e07685c06aeccb4fa65e0736',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.6021861826236,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 371.5,
  },
  {
    address: '0xbbe7c42a2b464fd44f63ef0fb47f01698c9b4472',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 371.35894639937493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 371.36,
  },
  {
    address: '0xd91295ca68a990c15ef5ca3fde18b7d29ebd6224',
    minters_reward: 0,
    lp_providers_reward: 21.51,
    swapers_reward: 348.5413530997568,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 370.05,
  },
  {
    address: '0x661698a5d0b06dd768e31657101829a3c1daccba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 369.5039152925141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 369.5,
  },
  {
    address: '0x40eb995ba33059268a7a64bd97a6e3a8d49abd9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 368.87093123972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 368.87,
  },
  {
    address: '0x539ad134a97a807f4573e5f77d312303cf278dba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 368.5932855476971,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 368.59,
  },
  {
    address: '0xb65b73c3084a3d1c2313d8faef20206b9d8ef14a',
    minters_reward: 0,
    lp_providers_reward: 368.41,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 368.41,
  },
  {
    address: '0xf0505bccc5c05f56b9d8ea3d3151a499bc3a9903',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 367.6398515554531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 367.64,
  },
  {
    address: '0x2a306e31331793958dba60cd38671928cc85b78b',
    minters_reward: 0,
    lp_providers_reward: 6.15,
    swapers_reward: 359.1241579825599,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 365.27,
  },
  {
    address: '0xbea24893f2d8516dd21a3bc04cc30f8ea064fc9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 364.89469303417934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 364.89,
  },
  {
    address: '0xe572df78813859e2f69654348ea815ac2a335284',
    minters_reward: 0,
    lp_providers_reward: 111.24,
    swapers_reward: 253.46051163834812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 364.7,
  },
  {
    address: '0x009fc65e1c322683b64832e46b55fbf67e7aae32',
    minters_reward: 0,
    lp_providers_reward: 355.14,
    swapers_reward: 9.481053414207162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 364.62,
  },
  {
    address: '0xa76312f85ead80ad66133a8678a9a906a5e25851',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 362.58201016772387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 362.58,
  },
  {
    address: '0x9177c804431a26002c8fcd2e3444190217427756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 362.28627916396084,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 362.29,
  },
  {
    address: '0xc79773c6b7724e77adec72061d1f2b2614917e16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 361.29900360653176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 361.3,
  },
  {
    address: '0x007375550555d7bd471ce650c4443327d4705e52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.11904975058897,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 361.02,
  },
  {
    address: '0xd7cb5a80426bd2bc243639f02a7811904eb3bd89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 360.75845251269146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 360.76,
  },
  {
    address: '0x244379242fcdaffbca1ae5e9039b5191baabd35c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 359.86763751091075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.87,
  },
  {
    address: '0x6ac8a78dcdb8b8167d487a9d341589d0878923b8',
    minters_reward: 0,
    lp_providers_reward: 142.14,
    swapers_reward: 217.35512565905447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.5,
  },
  {
    address: '0x556e2b71e0d12c62eddef902cfa9392b0c1c1bc0',
    minters_reward: 0,
    lp_providers_reward: 90.01,
    swapers_reward: 269.3902922579959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.4,
  },
  {
    address: '0xe31858c0a228522a8a9e20210a799f970f4b0cf9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 359.1787085696407,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.18,
  },
  {
    address: '0xe40d2eaaebb8c11589f0fa75dd79e9822236ed54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 359.17334798346025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 359.17,
  },
  {
    address: '0x6eb69cc8dd62af1e69c4ff73afd6e399bbbb8dbe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 358.4502835885868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 358.45,
  },
  {
    address: '0x8e96a887a621d74aadc1af1fd2ceca2163f05ed2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 357.85003002221777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 357.85,
  },
  {
    address: '0xf682c90f28b5bdb5cbc548cc2ba572b0b294ff24',
    minters_reward: 0,
    lp_providers_reward: 307.41,
    swapers_reward: 50.31861936718857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 357.73,
  },
  {
    address: '0xa509f3289efbc4e511dcc779322904a426018b2e',
    minters_reward: 0,
    lp_providers_reward: 27.02,
    swapers_reward: 330.07377654019973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 357.09,
  },
  {
    address: '0x8e6adc358fedd9c3c2fd153532659762a987f621',
    minters_reward: 0,
    lp_providers_reward: 322.64,
    swapers_reward: 34.28744317988673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 356.93,
  },
  {
    address: '0x96490ecaea837af2aeec6967d61f4bab30b58b2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 356.51683722087404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 356.52,
  },
  {
    address: '0x94aba9f81aef828abe63905fdb0e95e321fee531',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 355.8371137928205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 355.84,
  },
  {
    address: '0x2dbb80cf69aba7a65e8a0b98ef0209916e7dea26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 351.5103896114591,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 351.51,
  },
  {
    address: '0x19c8ed9d43d194bc8a64f963e84c4c4cc2efca60',
    minters_reward: 0,
    lp_providers_reward: 350.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.95,
  },
  {
    address: '0xa99777f92473af87385eb1700f71bae2ef78af8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 350.739616461715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.74,
  },
  {
    address: '0x957ab1b8ea6d44ad0e6b4908b042e18cf4e4fcab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 350.6538159055582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.65,
  },
  {
    address: '0x5c34dc139676707a3979fdbca0c7f711aa67e14f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 350.5108751706124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 350.51,
  },
  {
    address: '0x3a53d3f78a72675885a7affe72cffe1329945291',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 349.9238313637593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 349.92,
  },
  {
    address: '0xf115bda7758698f582c81c389ba0dd01277cd732',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 349.3139451412559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 349.31,
  },
  {
    address: '0xa0a575b5a784bf9296a00e41ffb238741f8533f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 349.3061191555113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 349.31,
  },
  {
    address: '0xe46eba5da6bd3e58eb1f527180852d4ef48cdd43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 348.63416798144436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 348.63,
  },
  {
    address: '0x41b3d76fca518e63dec89680f943126a900b5f00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 348.6327658299026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 348.63,
  },
  {
    address: '0xc5a35fcaf3ebd46f2f062ae42b7c2948285548a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 348.2735136705759,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 348.27,
  },
  {
    address: '0xce027a4abe227b6420594841b1a1ff953d334181',
    minters_reward: 0,
    lp_providers_reward: 347.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 347.94,
  },
  {
    address: '0xb7b42cf2e3ef393cd0fa723a28f2fe63515189ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 347.8604100055219,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 347.86,
  },
  {
    address: '0x58d45cdb0f580e4d0186700531290117c4a065ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 347.57978904287654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 347.58,
  },
  {
    address: '0x5b35ac359ed7c56128b37e2a84b8da6993ef7441',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 346.0941752824623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 346.09,
  },
  {
    address: '0xb020589f511152cba2cca755985c33f37c3494a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 345.4238971763098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 345.42,
  },
  {
    address: '0x7d6ec015351b6d7ec055082188a83a0d4b8094fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 344.91145837068296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 344.91,
  },
  {
    address: '0x6e4d8abda42caff7cd3e177a962d4e6100ae58b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 344.0715983334339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 344.07,
  },
  {
    address: '0xb10be1f7a1c546f28bb6cb0c58df768b9c553337',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 343.638064837717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 343.64,
  },
  {
    address: '0x5d98c365659e8911aa2aa095fed3687918be0e6f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 342.7013529257781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 342.7,
  },
  {
    address: '0xf5353a299dfa7a7205ce6850b191f8e461f85a1a',
    minters_reward: 0,
    lp_providers_reward: 342.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 342.68,
  },
  {
    address: '0x827457cbd88db442235ea7f75a8892b9885b36a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 342.4352348544038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 342.44,
  },
  {
    address: '0xbaac21177b8e9696dde47e353a2bdc2673e1bcb1',
    minters_reward: 0,
    lp_providers_reward: 8,
    swapers_reward: 62.911660998386594,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 341.81,
  },
  {
    address: '0x4c6e33027e247bcd3d0352d23e10fd3a38f5b557',
    minters_reward: 0,
    lp_providers_reward: 29.51,
    swapers_reward: 311.04650524709797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 340.56,
  },
  {
    address: '0xfb30fa4771c0bb35672772d42c6e2da5be3fa55b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 340.5170899281871,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 340.52,
  },
  {
    address: '0xaeabeb1caf66cecbe56d2e7834f7dc69005545ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 339.7947331087572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 339.79,
  },
  {
    address: '0x69f912c1d4974b5b71dcb9773c39d68e13e50e95',
    minters_reward: 0,
    lp_providers_reward: 64.43,
    swapers_reward: 3.4845511787698142,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 338.81,
  },
  {
    address: '0x2e706bc783789703089143a03033780f3d330ece',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 338.5923938051707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 338.59,
  },
  {
    address: '0xa7660698077d3a5ee23e06752f38dedbe7875971',
    minters_reward: 0,
    lp_providers_reward: 338.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 338.59,
  },
  {
    address: '0x38e87ae49b255b6d1f8626b09c0915f4b2660a20',
    minters_reward: 0,
    lp_providers_reward: 250.75,
    swapers_reward: 86.81836729799564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 337.57,
  },
  {
    address: '0x0034f3aa89857f803c7f9f4fc1f12c06e3bac3ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 337.069535290862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 337.07,
  },
  {
    address: '0xef5df86f34ef40758eaadcaaf5fc5426ccf8dfc9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.51725656812171,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 336.42,
  },
  {
    address: '0x4eb5e0c928b5347a9c631c2a1e41ef2629c9f458',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 335.7750635308277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 335.78,
  },
  {
    address: '0xe0c885e235328b91847021f947d967fb38116d2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 334.40420503095817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 334.4,
  },
  {
    address: '0x1e0165b582cccf0a921ae51434d935cc0b2edf74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.84405633504707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 333.84,
  },
  {
    address: '0xf027402e9ae9acd1564a7378db0c2b7e594546b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.3859420153325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 333.39,
  },
  {
    address: '0x053fd69755e63f90045440edf0e38febeaa9ba01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 333.11238452171125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 333.11,
  },
  {
    address: '0x1ed9ca18075e224c507f6578a683188691b4f4d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 332.75206245826894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 332.75,
  },
  {
    address: '0x88aa59bfcb7ea5a2203ef25d99c5dca1ce7c4f4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 332.5537030651398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 332.55,
  },
  {
    address: '0x8d79fab7d593d97681956556328d1a70689feb46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.8948780336733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.89,
  },
  {
    address: '0x0d91b63fce198203b2b7dbcc5323284f39d3dc61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.6961087270439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.7,
  },
  {
    address: '0xc41860f7ea0b1375350c73c197d7cade13a4a460',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.5642868372447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.56,
  },
  {
    address: '0x3887214822d66118eb8f69185beb3e62116e9cbb',
    minters_reward: 0,
    lp_providers_reward: 3.14,
    swapers_reward: 328.2252951746732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.37,
  },
  {
    address: '0x4f9cc66f4d0d41e6dc20aba1b781a529ff948628',
    minters_reward: 0,
    lp_providers_reward: 317.8,
    swapers_reward: 13.367869123976238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.17,
  },
  {
    address: '0x3d80d094221c0f2a5594336c0f08c857dd1b2da4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 331.12753158433065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 331.13,
  },
  {
    address: '0x94698dfcbdcbc681ec1287e902c7ba06b77aafaa',
    minters_reward: 0,
    lp_providers_reward: 158.65,
    swapers_reward: 171.9755811557046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 330.63,
  },
  {
    address: '0xd2c4d475f4130805cff1651dc731794508a452d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 329.32496390085186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 329.32,
  },
  {
    address: '0x87250c1ffe62f528bbf7ede4e59e58f8f6970b29',
    minters_reward: 0,
    lp_providers_reward: 151.57,
    swapers_reward: 177.33342696075164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.9,
  },
  {
    address: '0x1fb895c5be6eddaf7aabb9bd961e2b336161a029',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.8147587315172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.81,
  },
  {
    address: '0x8b16e9aedc6f5282b6a3db3200754c7926fbc1bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.71521955851335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.72,
  },
  {
    address: '0xf8a427db4b7e05557a9012e1829ef346b3ab2726',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.6717395932011,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.67,
  },
  {
    address: '0xffb3219b266dcd245706c90efbca50106e9acb90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 328.1924391574583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 328.19,
  },
  {
    address: '0xa6e99534c771ca6d9d5237cec07f706c9cb3d991',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 327.9555737577638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 327.96,
  },
  {
    address: '0x6184027092776673fbcca4f97caaa92b078e88c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 326.91451954831456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 326.91,
  },
  {
    address: '0x807456adcf923e84d3789e2c96376ac1fac91fa6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 326.2913886625202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 326.29,
  },
  {
    address: '0x4dc47ea9693e7f748be1c63bbb3124ed86a45071',
    minters_reward: 0,
    lp_providers_reward: 50.59,
    swapers_reward: 3.279735594459073,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 324.77,
  },
  {
    address: '0x5f7499fb9a03bab7cca6240c504ec5edc0b7503c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 324.46570304523374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 324.47,
  },
  {
    address: '0xe1869a511a7b1cbb5bf621e69d40a485ec19c2bf',
    minters_reward: 0,
    lp_providers_reward: 323.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.98,
  },
  {
    address: '0xe0984e199015acfa434e39731fd89b7e65137981',
    minters_reward: 0,
    lp_providers_reward: 200.41,
    swapers_reward: 123.47543465270448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.89,
  },
  {
    address: '0x72eeb46998be64543a604eb93cca0c1bfaf07f2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 323.61343193264685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.61,
  },
  {
    address: '0xc2e88618dbe0c2e4de76b6bc95cb66ee0262ec7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 323.2284493463914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 323.23,
  },
  {
    address: '0xb1d2d4b34d52aaa0159bd94f89663dae90712e33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 322.9826337311232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 322.98,
  },
  {
    address: '0xb424cf1fc0d8426ccbc4a3871f832d4d87fc6aee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 322.7948752641563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 322.79,
  },
  {
    address: '0xda380541a3766aa2004f6c8662f0480d8355f171',
    minters_reward: 0,
    lp_providers_reward: 321.24,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 321.24,
  },
  {
    address: '0x6ac3d5965bb8930b77e9a72a944152d0b8628065',
    minters_reward: 0,
    lp_providers_reward: 320.54,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.54,
  },
  {
    address: '0x305b9cc345ce267d4d854509425ecc54a5919c58',
    minters_reward: 0,
    lp_providers_reward: 270.25,
    swapers_reward: 50.26284312545661,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.51,
  },
  {
    address: '0xa5ca3eb29780681332cfc1eceadff3b3479cf976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 320.17851167733096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.18,
  },
  {
    address: '0xa192c1d7fb69d995827df83b37e76f1287d8dca1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 320.06175631641315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.06,
  },
  {
    address: '0xfc910aa9278fc6da68a27b8438b588c0727cea58',
    minters_reward: 0,
    lp_providers_reward: 320.06,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.06,
  },
  {
    address: '0xc266a20bae8d5b4006672a2b1da3fb990ef7ff25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 320.05416849159747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 320.05,
  },
  {
    address: '0x7965cc44101916274c049c5c1d80aeb5ffa41f3e',
    minters_reward: 0,
    lp_providers_reward: 48.44,
    swapers_reward: 0.3034735949054698,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 319.64,
  },
  {
    address: '0x729ae9598a43a1f22365ac0079043910388e44c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 319.56555032366464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 319.57,
  },
  {
    address: '0x4705b613d8c211aea1a4fddc6a10c102e57caa3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 318.9624570711363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 318.96,
  },
  {
    address: '0x3e1349eb312d901f8ce8bd3311347b728a602b81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 318.6499002737982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 318.65,
  },
  {
    address: '0x92692c124c825e0c7173e4462cd61526b8c2f67f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 318.00152442454436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 318,
  },
  {
    address: '0x6b6f740e440e42d48b6a419fe0145670787ca722',
    minters_reward: 0,
    lp_providers_reward: 316.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 316.86,
  },
  {
    address: '0xbd953a191a942622134793ed80b17d17db1ef2de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 316.73708891349935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 316.74,
  },
  {
    address: '0xef88bc8cbb4ff20ae175a989ee30798c5e33fbb4',
    minters_reward: 0,
    lp_providers_reward: 62.55,
    swapers_reward: 253.3735923780455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 315.92,
  },
  {
    address: '0x89ed66a0f628b9c8e967f700b2ed6c7b769874b5',
    minters_reward: 0,
    lp_providers_reward: 87.47,
    swapers_reward: 227.93697527219092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 315.41,
  },
  {
    address: '0xec9dd597616ecb4b60064649c98466a470c79244',
    minters_reward: 0,
    lp_providers_reward: 314.81,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.81,
  },
  {
    address: '0x66466574d659a1898b9ec8051f2b7a851ef5d0ba',
    minters_reward: 0,
    lp_providers_reward: 314.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.76,
  },
  {
    address: '0x63aadac623a1f6e5881a5306061b3339e1223d21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 314.1981286940588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.2,
  },
  {
    address: '0x4131a8ec70ea208ec4326341d291ad21caeaa8d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 314.03736691102426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 314.04,
  },
  {
    address: '0xe735c727c891f0d2b1c6c46d588e5ef807612e34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 313.6238512450035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.62,
  },
  {
    address: '0x48541c7b6fa4b8276683f09411dc8297cadc5058',
    minters_reward: 0,
    lp_providers_reward: 306.96,
    swapers_reward: 6.657615726935605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.62,
  },
  {
    address: '0x5b49977f9c4f68b6bc2b55bc90b06cd68892ef95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 313.4853705512975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.49,
  },
  {
    address: '0x8d5b31d866c87dec0cf7c291efc106695a56ec71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 313.2694022414108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 313.27,
  },
  {
    address: '0x7fbad080bf99da212c866d1fc214b7a764aa9362',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 312.6378630606054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 312.64,
  },
  {
    address: '0xd737715a4fdfcc7a33498cb2cde37b81b74be288',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 312.58847406805626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 312.59,
  },
  {
    address: '0x8f08f62c3e1f9879b1d4cd598ac54e6272ee83cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 312.2852663345712,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 312.29,
  },
  {
    address: '0x5cfc7b94ad7257bc84851129c41d54cbb058d2cd',
    minters_reward: 0,
    lp_providers_reward: 311.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 311.62,
  },
  {
    address: '0x5ca1d4be7126f51e50b5aac0aaf0417b2f7efd32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 311.2004595529365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 311.2,
  },
  {
    address: '0x064e184a5897f2aa491297a124c51db83c32a707',
    minters_reward: 0,
    lp_providers_reward: 26.44,
    swapers_reward: 284.5385191436408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 310.98,
  },
  {
    address: '0x7f71d62ca3d45b51390bc24a706fae2f284c000a',
    minters_reward: 0,
    lp_providers_reward: 310.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 310.1,
  },
  {
    address: '0x7921101127b3904c305a9aefe463ed3c859b29dd',
    minters_reward: 0,
    lp_providers_reward: 256.54,
    swapers_reward: 53.27978476989317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 309.82,
  },
  {
    address: '0xfd8a7a27ae4fb591bd467a300654002517324c52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 308.4758042898214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 308.48,
  },
  {
    address: '0x773da58a3a93d0b57f1f2bb3be3324c33d8b5c38',
    minters_reward: 0,
    lp_providers_reward: 285.77,
    swapers_reward: 22.263440394118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 308.03,
  },
  {
    address: '0xce6d3b176b02f37668504bd4c291c1334520dfac',
    minters_reward: 0,
    lp_providers_reward: 25.53,
    swapers_reward: 282.3777680247501,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 307.91,
  },
  {
    address: '0xf55a7be60e1c3cdce50d2167ccabf5cd4277aaf6',
    minters_reward: 0,
    lp_providers_reward: 24.73,
    swapers_reward: 11.834052055816878,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 307.46,
  },
  {
    address: '0xe2bc2b985553ecb7c98954cd50399c38454c4fb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 307.4531594086891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 307.45,
  },
  {
    address: '0x56f6d5648b698945e71584718d772fa2ca1dfdec',
    minters_reward: 0,
    lp_providers_reward: 26.24,
    swapers_reward: 281.00761989422904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 307.25,
  },
  {
    address: '0x905d0460c3b2c4514ae81c4fa02374ce19b9ae55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.656176018604555,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 306.56,
  },
  {
    address: '0x2895b934af1ffebdc3435357defc70f3d13d4013',
    minters_reward: 0,
    lp_providers_reward: 213.93,
    swapers_reward: 91.7957840320098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 305.73,
  },
  {
    address: '0x15a321e60d918bc21605db59e6738ec8bff094d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 305.2668752273939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 305.27,
  },
  {
    address: '0x69dd1f8b03a8ef898d68e99d0554243788a826d6',
    minters_reward: 0,
    lp_providers_reward: 159.9,
    swapers_reward: 145.18510549037305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 305.09,
  },
  {
    address: '0x8ac2edc61bb6e3577ada2a08e16c54fb90295701',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 303.75202235878686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 303.75,
  },
  {
    address: '0x569bb55ac4a1512c342933b1fadcfcec52db82d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 303.446039875009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 303.45,
  },
  {
    address: '0x19ed9f06c9244699fd1ff19558220bfac64b9c31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 302.3920108352452,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 302.39,
  },
  {
    address: '0xface74d5e15e9e0701f5e8e57a74e2d1a471ff0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 302.24632777124464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 302.25,
  },
  {
    address: '0xe71bf5e1122fbd2e6b09b0653dce74a151a9ff92',
    minters_reward: 0,
    lp_providers_reward: 67.18,
    swapers_reward: 234.29598919007498,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 301.48,
  },
  {
    address: '0x7e4e48097db478ecdb7d4be2c5780bb5b585208e',
    minters_reward: 0,
    lp_providers_reward: 104.32,
    swapers_reward: 197.08780090789486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 301.41,
  },
  {
    address: '0x3bc52a196bab833947f47ce24600efa360530698',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.98641536012997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.99,
  },
  {
    address: '0xf60866090e059afb6751f71bc40b833d05819bee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.963120590659877,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 300.86,
  },
  {
    address: '0x88bdd6b27bd4df05f0a570c4a4c9d56a0f202ced',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.8425277918625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.84,
  },
  {
    address: '0xb74ffa55311b61e9ed437325498d133f9d18ff23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.48102461798305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.48,
  },
  {
    address: '0xc34c74d264c6130034e03ec1be39cc3ecae3063e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.40759070804813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.41,
  },
  {
    address: '0xfe9059742b9f5d9af0a73ce5a8d68b183ce33198',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 300.1752927800312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 300.18,
  },
  {
    address: '0x7b977230b194b020a983f42a013bd4f10e98fe07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 299.1659016525946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 299.17,
  },
  {
    address: '0xb4ae787cd77782d78d04d04d5f90570cecda9756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 299.06387712699734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 299.06,
  },
  {
    address: '0x2c3620bc6898b5bcc825e30ccbe36dde0c423a89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.9022096226516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.9,
  },
  {
    address: '0x035a79b456e1945c7ec431afa0c0ba15e38fc39b',
    minters_reward: 0,
    lp_providers_reward: 28.32,
    swapers_reward: 270.25644370508587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.58,
  },
  {
    address: '0x12bbcfae385cf4838860b416e41b48e8bf269a27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.47534964147206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.48,
  },
  {
    address: '0x7dbec91c5df027706211dbb33a5005420ffcc15c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.3584377004978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.36,
  },
  {
    address: '0x437dc79ae608c9c4b41258728181f89029d4db1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 298.16164970963126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.16,
  },
  {
    address: '0xe8a210dd8d9e32403a74625dcec398e51687e1e8',
    minters_reward: 0,
    lp_providers_reward: 298.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 298.1,
  },
  {
    address: '0xc943f587c2598fa8adbcb6439b954d1ae1747614',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 297.977967760433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 297.98,
  },
  {
    address: '0x2215e45a8b0d47a21c503c651c93fa6c39856bc7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 297.07125286838794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 297.07,
  },
  {
    address: '0x13267ca893975873cd617114d99cb0816cffdb4f',
    minters_reward: 0,
    lp_providers_reward: 213.13,
    swapers_reward: 83.30180296414798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 296.43,
  },
  {
    address: '0xaed8f743548a544dc501caf597f61864e13bd68b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 296.17490083385644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 296.17,
  },
  {
    address: '0x5171adb7c75d10c1a452253d7f30b7e31a670a9e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 295.11516370698274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 295.12,
  },
  {
    address: '0x4c8ca58562d765cc0d5bd7fddd38b3ca6ad5cce3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 294.93120186733984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 294.93,
  },
  {
    address: '0xbd9232ffed64752312e078b9ca7287279d57d21e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 294.62693044726683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 294.63,
  },
  {
    address: '0xd917e87ebf1c861e064de42ca053a07f3b26f69d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 294.23415376647677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 294.23,
  },
  {
    address: '0x514fbfe81a7d27cc7a9c06f34c277741cda5a438',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.038268608536747,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 293.94,
  },
  {
    address: '0x3bebb9a0075c356ad5cae9d1704547ab8e84dd45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.7251346619958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.73,
  },
  {
    address: '0xdd2ac1e0f28dd7fa35ea053a54f2d7201c937c20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.6247235084996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.62,
  },
  {
    address: '0x994e477e6ad1712cc732fbf79b5de39af7e63c39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.4721214043484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.47,
  },
  {
    address: '0x51dac3344a8806b2178b9ebb4083bcea5f6768ac',
    minters_reward: 0,
    lp_providers_reward: 286.11,
    swapers_reward: 7.293068645061963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.4,
  },
  {
    address: '0x1035b321b7de7555861000e551d4cfcb409b6e1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 293.32615463673164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 293.33,
  },
  {
    address: '0x0ea141316b956e5037fc2715329bdae44f2247dd',
    minters_reward: 0,
    lp_providers_reward: 292.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.96,
  },
  {
    address: '0x66757e827d390d0c805891d8b5d9d7f095da96c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.6758782368558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.68,
  },
  {
    address: '0xdaf05f3e95b647a5c229a5496048c1a7ec23acb6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.5694863224768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.57,
  },
  {
    address: '0x6eab56b994cfc38fe4b02cba0e19d1b2d377d406',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.53011499921575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.53,
  },
  {
    address: '0x866023278e5e55d1543bf9b63be1d5678cf5e5b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 292.435083250246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 292.44,
  },
  {
    address: '0xa800bfe2fd71710cbeafc64c344a5f46f30ea7ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 291.6394972345261,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 291.64,
  },
  {
    address: '0x632dc52e750e7a380b9fced40bd2466978966e32',
    minters_reward: 0,
    lp_providers_reward: 20.44,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 291.34,
  },
  {
    address: '0xdad764d2eafb7fcf5201ff992f9f93b1288ecacd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.17488119781569,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 291.07,
  },
  {
    address: '0x603f1ac5d697a5d26effbadc70fa21f7fc811003',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.153254305664053,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 291.05,
  },
  {
    address: '0x170d2370b773d56016a14311104eab7086846f6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.718986416736954,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 288.62,
  },
  {
    address: '0x81413ff630133742db50b9a82dc1aa5647847223',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 288.13056005038976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 288.13,
  },
  {
    address: '0x49f8da0dd3d3a05b4be5fc937eeda8ad72428213',
    minters_reward: 0,
    lp_providers_reward: 287.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.71,
  },
  {
    address: '0x341431098e31fe869fca30bae789a081248c7f4a',
    minters_reward: 0,
    lp_providers_reward: 116.59,
    swapers_reward: 170.88573650071248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.48,
  },
  {
    address: '0xb358702f80eef30c00f1c6018107984d32b205d6',
    minters_reward: 0,
    lp_providers_reward: 255.13,
    swapers_reward: 32.29471236801532,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.42,
  },
  {
    address: '0xd32780fac7822a575fe8367d20402127ff96e7c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 287.3304914021248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 287.33,
  },
  {
    address: '0xaba88f4ba9a4af03756b0db788dfd593e7e36170',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.981594954069017,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 286.88,
  },
  {
    address: '0xdfb542ffee4a7ccd52ea996c6ca530e2cd428eb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.48793074100916,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 286.39,
  },
  {
    address: '0xece7f58c319c0e8aed9f9716e3a0bb5c001b80a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 286.3282347403538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 286.33,
  },
  {
    address: '0xd3d07f6f026e6ff480d9a06df0c7fc0f88bdc48a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 286.222743108216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 286.22,
  },
  {
    address: '0xd22f41d9c1172f8be4beac2b4910959f90e6b8ab',
    minters_reward: 0,
    lp_providers_reward: 286.14,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 286.14,
  },
  {
    address: '0xfefccd113e74dc10a98c676536b60f72884a09c4',
    minters_reward: 0,
    lp_providers_reward: 203.47,
    swapers_reward: 82.27722387583131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 285.75,
  },
  {
    address: '0xb65e31a1bc3ceb31dcc52dd164b5c22c4ca64020',
    minters_reward: 0,
    lp_providers_reward: 285.72,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 285.72,
  },
  {
    address: '0x20d802ef407928f02b43b3948a4f70f7d11fdb4f',
    minters_reward: 0,
    lp_providers_reward: 226.16,
    swapers_reward: 59.37152582088841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 285.53,
  },
  {
    address: '0x82b6a54205e2987760952ef0dc670c17b5678529',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.72320574032113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284.72,
  },
  {
    address: '0x6b6cfa0cbcad745e5ca383319ce8a784d955d840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.7058003896721,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284.71,
  },
  {
    address: '0x90e9e84414d5a094cf525001ceebe76c249cad26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.4819919798897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284.48,
  },
  {
    address: '0x7e2136b0992ef7899e9993b208807bd7e0de27d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 284.0024623830707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 284,
  },
  {
    address: '0x3ea41c5da4e782dad354f16b6a546110211ffaac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 283.8515375744925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 283.85,
  },
  {
    address: '0xa691699e5b1f6fee42ae90591625ee747ab67c32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 283.8456308898038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 283.85,
  },
  {
    address: '0x4ba8455a308031a0dc7f129008dcec2c0544af5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.855574814380367,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 283.76,
  },
  {
    address: '0x2b4cca706c4b2fba22af57aed953c447d877cf28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.585521317650947,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 283.49,
  },
  {
    address: '0x2d20370699cef0b48486bd97a590f9e349d198ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 283.0909354646068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 283.09,
  },
  {
    address: '0x11d7593692b3eeac1045d47b9a71c0bf697452b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 282.92963182802504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 282.93,
  },
  {
    address: '0xc55c670a0b8ab44ea66f7de88d43730c47861b99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 282.63280566810334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 282.63,
  },
  {
    address: '0x71e1bcd783d3397f4d82b70bc5dceb194eca7112',
    minters_reward: 0,
    lp_providers_reward: 39.27,
    swapers_reward: 243.3534711630821,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 282.62,
  },
  {
    address: '0xc14615ad68e6d13284faee70e20e67600ec40809',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.981895155402746,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 281.88,
  },
  {
    address: '0x0dd94f9ceafce9f3b1637b3fe6b1f966e5a12fae',
    minters_reward: 0,
    lp_providers_reward: 150.45,
    swapers_reward: 131.2096580368559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 281.66,
  },
  {
    address: '0x677939b16594147369c82247f8e5b29e5ef23814',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 281.36400677412007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 281.36,
  },
  {
    address: '0x70923478bb62a1f2ea24466345bb934e73d632ba',
    minters_reward: 0,
    lp_providers_reward: 281.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 281.34,
  },
  {
    address: '0x7ab7f1bcaaff0f198bb93a088642d1d2bdcfa609',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 280.7433108575521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 280.74,
  },
  {
    address: '0x2641036636982d55fddca6d970ee4b5d3a26bbab',
    minters_reward: 0,
    lp_providers_reward: 278.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 278.97,
  },
  {
    address: '0xffcd1aac7fe8bb859a397849aa3a84533c8305de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.918038326165407,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 278.82,
  },
  {
    address: '0x92e63682dceda91ebfb14178cbb20caf172a9297',
    minters_reward: 0,
    lp_providers_reward: 278.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 278.66,
  },
  {
    address: '0x120ad2d604f4f3436c1dc2b3514404646c809557',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 277.95946816076196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 277.96,
  },
  {
    address: '0x14882f9a40db4253ed3c8ee8caac2c422b64ab33',
    minters_reward: 0,
    lp_providers_reward: 19.4,
    swapers_reward: 258.2014767735177,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 277.6,
  },
  {
    address: '0x81a210ee3b8b72bb78a811f0c41781db2464c2af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.565201209912495,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 277.47,
  },
  {
    address: '0xcf8d793641f07f5ba3d0a35ff5f6d1f9cfb25f85',
    minters_reward: 0,
    lp_providers_reward: 112.18,
    swapers_reward: 164.61917573267726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 276.8,
  },
  {
    address: '0xf6c2d2f2d876d44edae9aa1e21563ac685370c74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 276.27052061271155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 276.27,
  },
  {
    address: '0xdb1dd374510d660291ddd53c37b868f9f16f29f0',
    minters_reward: 0,
    lp_providers_reward: 83.87,
    swapers_reward: 192.19005618894823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 276.06,
  },
  {
    address: '0xcba9cedd2e43a030086899264ad3e767d8732da2',
    minters_reward: 0,
    lp_providers_reward: 172.15,
    swapers_reward: 103.53719584310022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 275.69,
  },
  {
    address: '0x3d897dd41c1f96044eb74f33ea1bffa57e07164f',
    minters_reward: 0,
    lp_providers_reward: 0.57,
    swapers_reward: 2.3340925026936565,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 273.8,
  },
  {
    address: '0x236a0824154c3d2c991ac25c7467eadfc2d5888d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 272.95084500389953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 272.95,
  },
  {
    address: '0xa54e218732dc1768544bc69ba726f930704ab5ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 272.87744370751085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 272.88,
  },
  {
    address: '0xe71712dc0508f9c4374416c0254e6c5b76cb628c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6488352062624372,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 272.55,
  },
  {
    address: '0xb10e87949f0559fc98df339f73b049f051a36ca6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 272.44344813660194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 272.44,
  },
  {
    address: '0x5fbdc0b05e1f923784b8fd1e82b0bac983e32ee4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5790212245020189,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 271.48,
  },
  {
    address: '0xb3edcba97a97a248a9a3d518360793e2127fb421',
    minters_reward: 0,
    lp_providers_reward: 271.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 271.42,
  },
  {
    address: '0x37ef759ebb55c9d45ddfea8a6fcb71d95e1cea36',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 271.37497280189683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 271.37,
  },
  {
    address: '0x3394341A3337bc2e67A5f262d75b3377342E51f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2FD79EA8536b7b65d848f62627793c95e35Cf2D6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x24565745BaFfD490D93d0d4Fd0048003aa830ACA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE65e65f08ca1Ffed4ac9896BEc50986E91751bA1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xF2181ff45fB48A5a2E25DF13614a10f000326762',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x285973D3A973Ed4a7326c89ea68DC19E271B5aC3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xeA6e1E9cc5e37AD104e9f9A44F41F5B9A0dA727F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0721655D679eAE155d6117E1E46Ca219b8Ef03C3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6709aDf3381e06eE5c65F20A561f0168E1F8114A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0725F71c6f91C6a6C5C1cBC303ECB0B25BbEC4bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xaBf3853c2608ea58A7DF0A0A39f01126AD0CDaA1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x08189DD62C7bAc430De62beC4b5957d866aef074',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9c3Dcf5be9a76D3d1cf4152ADCe071D50d30f24e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5272f160E83132AEf4Bf9309503E518cce2cd584',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xa63A35237441eFaC01C0F5baC0031BFF7628853E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xDc3e396708Ce9D29E940dF1c7D5fE6c4f602696b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6869A8bbBB4E5adaF82beeaF7e7c1EFEf671B27d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe2EF17a5973b63f8a4B0B1C550a74157aeF12186',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x23109DB4867159Fbe08445e830c6d7cAA78cC99B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xF74E9D381B7E22b3695D82D6A7B4395C6C75f935',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xbAd1616f50e4a1348d3439c1514698db580e0428',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe6C6E0B1C1D4876D1Fd32BCa11b21D23653b5D20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe3f395eA63F28962A0c65f476F3dEF9B6068F7Cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xAC89D5dcBe6387D012f3A61A9F33a1f0D9C2B30e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x7251f278940C3685EdFe93e72cbfE22C53F60E43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0b675F4C96fB985e136f43E5b227ff8578d7647A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x488B3Ae9B55bac6622D6cB60871e97979F404DC9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD2B67dA2B5E1585Bf4D7E8522Ac00D4485bb872c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2828aFBB84624DbE434795978A7470A76a9860Ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9a0B59BE0B2220d6B788A316770Ed08E0Fe02c3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x599367b0cfdCf3a15897dA54C058C867F7FdD723',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x658116983aD239d2f62c6E73AfD687358d690AF3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xC41659691C6AB6b15777f57cB96be2a8b78BaB94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x37339f47563fb644e7add3CCB11Ae78F4C05f135',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1650a5fEbB72BD0BB3598BF80bcAB3F326A8C924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xfd3e8B3A92852F0244894537189C2E374468f195',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xb75aE7cE7Ab4192311FD7b292aDd4aAe112C9657',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0e73a15EE0fec3d96ee971bf1aA61fDB81215231',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x702C1A9032e5265dC86642F3BB2F4DfCda740Cf5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8c9D703e779FAcFe5F0F47ab1B810AEDba38C9F5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x31104f41a389F48d7AC36B8328DE65782EB6097f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x991eCaDDDEaA1B1DBf22BBcECd5D32273a6c416e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x82F793f736d0a695f5215bA56D9965D0105A684D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x4B7Bc0032ACCC405701324d72FCfBFcB7c3cD763',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3321C62a92aEfa6106cCb8104A29887557166eD9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xbE49D2765E3A39A5a8fcB80EDa3e6269599bf51b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x98b1Cf6a0C79aDaE4401fa53DB7e5Fa944b56e62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x4E0dF3eEaF199D3855309F560bf9C3b6897b6dDb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x48060Fe79144DbC33297140682363231c4bf23E5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3726A5baBeb64bdAE0f10FCCF25433FF4F0d6032',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xFA59Ae0Fe58b72634fd175c3E090019118f1B62D',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE35B77396A8c0cF1E4c52262474b4ae60Af5Ef74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x12f5A519562D58a3B835becb36BDBcE600c9A0b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc11ABe6C23b6fdC94571d3653BaDa0c744a058D4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1DA5E004fEC584df83CeDc16BBB137DC403643BF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x700759b15d4e0e60f21d3F825a31d6089A6279e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x7844728981e84a0dD70A3F387e40E77854b92eE8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x82f12b36374bF5c5bf349b24Affc54fA958C81cD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3738213B53eAE5dcDC9292D49Fa178DEd7556c90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xA3A29d2A820E6E538A1Aae125fF326795Ed80Fc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xAd77558986Aa6ce4E8E32Fc7dA0519851D398b00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3100b56FC4929a52f69FC5695a7cc1e96dbCd779',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x525Dea51CE6f0d014229ff5b99E01D162649fB68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2A3cB8Ba552D8FB11c53fD115074200DdD679173',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xBD4fdFAaD69221C842c3c6E8e1868FD8d69E4B03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8006DbBA42dAaA9274ff4bBac12c2567B606daC2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x555a5E1f1FB49Dca68D9d64BfDe7444aF6b67ba0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x4F5E94Efda61B4b86Bf039eb6cf9faDE24f5D7BC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xEDFd0d5cA89497339ad86a122eB2139700C3D741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xdE6896b9E7B5814c42aD97661a1F602522519763',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x061C328717d7aF95161900011c9A3d87acB98d40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x10D8Db6bE4DE3b63fb04A18aC6a8bfF6437D5bBA',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x03e315F3DBa2820999DC157fC26948AB0C368eB4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe3a31DDF8f127Be8170b1aAC0d01F367c13C76dB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc42189c9E334D201A903fad4F4F3822Ac60d2A93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xCd7e75FB8240bcBC54ec97fe916659C4B5993fB4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6556b7e8319B2832959a55e2E9D32d3293EC07C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9F5C28FfBFB2c14834cb37f6fC2D04e6A71406DF',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x212c5AE33F8668B700C62EE90b146f043a1EAeE8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2112F392078826011fEB8820D356272Fd6474da8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x14F2BcE33f7123e96fA25Ba9052d374c7883dAe7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xf5C7Ac359C869e1099Ac128eaf276b9783D4B4aD',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5476DA5dcedd31D253e601CaE092027e88E84648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xDE17C98CA4f9ff6208d73571F7cCFEd0A4Ae592c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xB303e12445B5aa5cB79AB18cD85aC70FB9920851',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x94006CC7abF94f8f2281ab267Aaf3eF5aFFd2DD5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x081AdA89f25751602ef7a6BB0B72209F7Bb104B3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x7ac255F64CdDAeb0385787eb4852ea9F749913b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xf058393b96fC9FD6831dC12b4c85eeeD856687e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xC37530d22352C9543E0152548831e62852CF69A4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x63525592be593899c98327eeE05b46ad2B7F4DD3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x71f3707263887dAafc777232B089353FE9222E3B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6775376958c5e77Fd599965A851b71E02337E62B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x88613e5007fD19e6DF9ac6Dccfbe90d40572562e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD27A48c902ef3E24f924ecD50eBF89507d850cbB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1d9886Ea8b73096AF9381841d5b9157Ba6127e2E',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc4EDE909e938F41Efb8E0F967e2493F2446f39f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x045ECb2CD982d0d39646D46B637d35FDeAf22505',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE1C5D7868f0B2aB2852a472d6f3B28F2a55a3106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xeFe2202a932296253a1Ba88226167173c4ec20f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x90AF5C53A466ca247d8E0f7d2A4971Aa2decB4Ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5B1275a387d58f95ea8252E48fD5214e74241992',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x57091E551ba4eB7544194682fd9824e5e46B166f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x30F9D19F76EAe695D95540795bb3C8De9A4760c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6e2992233A09Be53CDEfD3b45DF37B1E28A3dFeE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xd2253fdab45E8fB8DE8B6bdCc61Cf7F23602A3f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x31218F8e530c37d7b6Ae83C86dc186a964f2E5a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6a48e631EFac49CcF20C04EDa61d4F0a02E6f60A',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5a22e15cBa6820814CFc51EC16951CcD9b0bc169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x0eE7c96F48656f676141608133909cF5ADe42CBf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x80874002A8C51D749458E1fdB60853396D2C2D85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5F6830EF70c83D3A709F47674ca0407D2b9a694d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x47DE48B0669fDdf9d1bC9d09DCc7F601fc079875',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8a50Bebe04E4B474dFD39bddc9bd01152d17Abbc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x97923bFbd1D21Bc22984C6e511C2580fB0fd4215',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2a46F764b071215B45c648f5905B3bE0f55A07FC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xdF7165a0d847e3B150599652040d2b1f06A2263F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xDb2fBdB5d721Eb4775252EeC86Dd76df13b7C5e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6B5DEDC648Ab8450FdEed77adb77DC3E7A4E35df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x483f56647E9078E7b6a1d85Ed0584967D4A5037c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9E0E24739B3a6FD92c4CC275788e6a57b8e7d925',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x67A6789E42E889326931CFcFFae2be527893f7A9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xe8C4ACcA1Cd6A725cAaF6d16f720d486bf119481',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1539725A7412FF554e7D1De816f300B4a0099Da9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD4E7218b50F06E8D769F55D06113c0984aBc036e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x34799927733B20C3Ab7528434734899f18E43E58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x59aFEf0b7F5830fcaE9c2b6a9D0bec481667E82e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xC0e73754cDC735Dfe89ae811C85Ee8a87178a565',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x03Ea64a5CEd21E2CccFaEa0Aec283d0A0afE9424',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x82a456c6D533d21c328E2eBc4994d468C90099fB',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xCC8C4772575E4f55Dd8e63DF32962Cfdddd42B7F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x6AD289996Fd8058C58A1843691F90fCBA9360908',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x93aFaBb905bc69Dccb244626AeB452f99307Ab86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x3d7dcEC65e6bA91d339B984DB67f0E71E5e32C29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5dA7b2e9d4A8584646bBB03d9F54F84eD9Fa18fE',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xdC71E56c86D8cDC66aA3c9aA315c7cCfB564Bef6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x53Af577749043A682c8397250F111C8E375E212c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x79f38e6506D85D05942526A214b4188bd9d160f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xD4730E3786025c9B203c4ddac0B1526676B7093F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x60511Ee15267DFF727Eba88B5C86e5e5d7060Ba0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc411e09D9787C1A4cB3c691176e5659eafcfa1D1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x909d9cb593EEb611F2C6270cc33dC11cf6D2502B',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xc540c43c123cA49F64f516DEa10E2A0550491b98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x51655E31c56DdC67F9afbC35860fF8797ddc576a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xbD255260436E0d0b2942e5BDc7ad0c7B98CEE4d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x1d02049CfC74FA1b8cD3444C2Aa754aE80f435C0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xCb3473B401d90159E0d5e6f204c52a6b3Ad49380',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x2500344038fAfBA8b003c3E64F41d4b1b06238C5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xa1dd9d774a71CDE9B54Aa353c2a417deD4485E23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xE447481b91bAD4e968e6E7588ad8FECA1E312f6F',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x8EB168d87d75cEF13434A5a332D6149F290FecF0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x9548e1eF72797238D6C0DF5bdFA54E703327B051',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x72209B28715Ef1328fF9fFA7F1E53B3F9a685F6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x5712f106A3261dfa5AEA1A8e104F78bd48B45CEC',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0x507cca3E94AFCB77640956De61Fa7A48C018a189',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xeBd3A85c56DAFaf0843103c4e0464F814D764b69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 270.9,
    hoaxs_reward: 0,
    total_rewards: 270.9,
  },
  {
    address: '0xa7176d756e57f4cde70a8ccbb8a4e82b4c9ca3bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.8890635658342,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.89,
  },
  {
    address: '0x1b99131391dba73eb133e0903417703f01937ac5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.8890616003937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.89,
  },
  {
    address: '0xb907328f4a48af7961d905dd194b790a8c6ec405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.8890616003936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.89,
  },
  {
    address: '0x40adfd76ed120e1a76a18eb27bac1771b9d572a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 270.5792135194191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.58,
  },
  {
    address: '0x2db6cefc56199372ba25fbca87539a2af0087483',
    minters_reward: 0,
    lp_providers_reward: 270.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.52,
  },
  {
    address: '0xe522e3b379839e1bd1ff0730a66a5cd7aa8f8090',
    minters_reward: 0,
    lp_providers_reward: 270.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.46,
  },
  {
    address: '0xb5f03558b566c14f08cf34ef94f933df5d99331e',
    minters_reward: 0,
    lp_providers_reward: 137.61,
    swapers_reward: 132.665399326356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 270.28,
  },
  {
    address: '0xe96a1eebea027e3847c65f211b6b8110c11d7b93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 269.6731083013908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 269.67,
  },
  {
    address: '0x320b6ef3f24825fa9e7f67207c15e68d3107f95e',
    minters_reward: 0,
    lp_providers_reward: 269.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 269.33,
  },
  {
    address: '0xc814da727a5503260d651a2b0d001181b35e043f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 269.1596724260808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 269.16,
  },
  {
    address: '0x09c1b97f01ab7b10b0c8bfc1e37ea8fadee5b138',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 268.716255610009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 268.72,
  },
  {
    address: '0x443ceb9053e4d6ceb165bb1ac19d9af8a1fc4e97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 268.4415637051001,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 268.44,
  },
  {
    address: '0x2815d444a80ead8c689b7e82a0f6f30f41e11b4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.78833434642354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.79,
  },
  {
    address: '0x1ed110a638ada082ec18acf0467ee467b52ed815',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.7147858907938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.71,
  },
  {
    address: '0x1b0dc9788702d2904d2b0fa755e53beb97b5579b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.70910701834214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.71,
  },
  {
    address: '0x9c71bbfab09391b04e64e60fc6642d0d829583dd',
    minters_reward: 0,
    lp_providers_reward: 224.13,
    swapers_reward: 43.562325342730496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.69,
  },
  {
    address: '0xfb142f1d22502fa6f0f5a10a33ec2c54097b163c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.66421264921735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.66,
  },
  {
    address: '0x6767818b37df5ff1b486f696eedd086a5cb51c7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.0547820301958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.05,
  },
  {
    address: '0xf35163d7e95cfaa1288dc35d59fe8fd9a1c4ba39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 267.0399966627162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 267.04,
  },
  {
    address: '0x2c0bb594c1a676046ca091a177ffd2d0c6b200b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 266.1970391828634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 266.2,
  },
  {
    address: '0xb93962c70d5c4738ff32d4378ed7a32cff1199cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 266.0433830949334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 266.04,
  },
  {
    address: '0x084fe17a1fe64d42431a30ce67f0d6dcd670e97a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 265.6072483090635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 265.61,
  },
  {
    address: '0xfdd1481db6d28eabb98eb008ea4d6297ff3473e5',
    minters_reward: 0,
    lp_providers_reward: 143.44,
    swapers_reward: 121.87143855713593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 265.31,
  },
  {
    address: '0x0cc1366666586aff380c36a7fa36a0ab543e8112',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 263.8190829052552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263.82,
  },
  {
    address: '0x987f74b2e1820a4c543c892c1ac5fb7ed0ace5cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 263.53640829606815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263.54,
  },
  {
    address: '0x3a6d77a176591d0e272f36dab3cbd295a4a3bfb7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 263.2721412715483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263.27,
  },
  {
    address: '0x670bb2fa1231550fccaec308e6ed03b98d2f90fa',
    minters_reward: 0,
    lp_providers_reward: 177.72,
    swapers_reward: 85.27962445687716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 263,
  },
  {
    address: '0xc8146c07c81a25a07d0caaa5fdda41e4c1bbe97c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 262.375421310187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 262.38,
  },
  {
    address: '0x75ceebb0bd4a85596d06a2e134a0f3117629c13e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 262.1462112850352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 262.15,
  },
  {
    address: '0x73c825fca9b682b6a289b49fd0a15bca364fdf21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 261.86378903528794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 261.86,
  },
  {
    address: '0x0a12e3e57406a0160d6109fe9bcc4851c9162601',
    minters_reward: 0,
    lp_providers_reward: 154.23,
    swapers_reward: 107.43001190702002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 261.66,
  },
  {
    address: '0xdafb73fea6acad9820806a02bac39e284fd6f2ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 260.0754403389725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 260.08,
  },
  {
    address: '0x3e332910ff4cbf78a22ffd8fd24d8401dbc45033',
    minters_reward: 0,
    lp_providers_reward: 114.65,
    swapers_reward: 145.25023345425015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 259.9,
  },
  {
    address: '0x96dcd03687025b43c49af9ae7edf2678f3798a8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 259.0687623611106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 259.07,
  },
  {
    address: '0x73bc39121e1adc48a3085d0347823a9052d506c8',
    minters_reward: 0,
    lp_providers_reward: 258.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.76,
  },
  {
    address: '0xce9bc50255dba933e8b9bfc5cb9dc174e2c67e4b',
    minters_reward: 0,
    lp_providers_reward: 258.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.74,
  },
  {
    address: '0x8ce2185f2e694de58ab54d8adda9a9fa727ca122',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.52687492843916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.53,
  },
  {
    address: '0xfb4ceced5b403be91a3b6cfd51783f6a5c0bb98b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.42809792908736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.43,
  },
  {
    address: '0x350fe458119cfb86e2932480f52fa04163b31481',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.4016861002465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258.4,
  },
  {
    address: '0x745c38cd894f613756ff081f3c3c66c2c92dc905',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 258.0001099149458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 258,
  },
  {
    address: '0x01d916526296adaa85f31b097d1b9a91be90532f',
    minters_reward: 0,
    lp_providers_reward: 85.53,
    swapers_reward: 172.40060499006162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 257.93,
  },
  {
    address: '0x08c225e8de3403573ab9784f4f5e6207e8518123',
    minters_reward: 0,
    lp_providers_reward: 257.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 257.55,
  },
  {
    address: '0xb371f060ee392939c0acedba81c85df7e8dda6b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 256.7888089182653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 256.79,
  },
  {
    address: '0x6deeb0e31cddcfb062cf8f202a624177cc7a5d5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 256.2403404584047,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 256.24,
  },
  {
    address: '0xf7de9bc54951da3d1fa0cd34d70f02d86175d142',
    minters_reward: 0,
    lp_providers_reward: 182.81,
    swapers_reward: 72.9604136384389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.77,
  },
  {
    address: '0x5daadd995ee0009abdba40183e936a13a30795c9',
    minters_reward: 0,
    lp_providers_reward: 255.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.57,
  },
  {
    address: '0x7cb3068c7cc54d29b290bdd1a4a2189083a07626',
    minters_reward: 0,
    lp_providers_reward: 207.44,
    swapers_reward: 47.87324344885746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.31,
  },
  {
    address: '0x10985abb97fee7be33c13bb62644b1a4c9c9aecf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 255.05131512528195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.05,
  },
  {
    address: '0x19aca5ca56627dc5fc3c962e2d056a0672e52f5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 255.0219621177195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 255.02,
  },
  {
    address: '0xe0de5624517d7ef9909892d4dfa50e77fdfe9310',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 254.3897311304305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 254.39,
  },
  {
    address: '0xa7850df2207d063ef8aebcd3fdfbf3277b6370bc',
    minters_reward: 0,
    lp_providers_reward: 192.93,
    swapers_reward: 60.75076707102358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.68,
  },
  {
    address: '0x60f431728de4b39143c71e9cbf8bdc3c17af4a60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 253.67484526831063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.67,
  },
  {
    address: '0xe5b8c7d87252bb5ca372e9913a82392dac1fda5a',
    minters_reward: 0,
    lp_providers_reward: 219.16,
    swapers_reward: 34.13935587030642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.3,
  },
  {
    address: '0x6d1e980c11f625e2e70ea168a8b8546db49248d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 253.10211299671695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 253.1,
  },
  {
    address: '0x6c2f7744efb00a741c3cf48f15b5df78d08bdf5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 252.92035078748998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 252.92,
  },
  {
    address: '0x6cab467f51bc59e7da04fa0d4d38dee692bb0862',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 252.76267053824836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 252.76,
  },
  {
    address: '0xbc29d432454b674eff5270dc31fcdb335b3888f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 252.36024916151425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 252.36,
  },
  {
    address: '0x41dd121affdec12041e4b706a3dac4dd3ecfe35b',
    minters_reward: 0,
    lp_providers_reward: 251.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 251.46,
  },
  {
    address: '0xd98f762b0f9934215d996a3fece61aca3a2da0cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.8783315712165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.88,
  },
  {
    address: '0x6cadbeb02dc796c6cf61162f3a387385e1d2d321',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.4712997270279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.47,
  },
  {
    address: '0x4db23dbecdfdfc26a5c0377aab8cedaec0635864',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.21313419539007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.21,
  },
  {
    address: '0x5664c36bb6715a0fbd76f56890c3da9edffa2025',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 250.12813313701622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 250.13,
  },
  {
    address: '0xb2cdaf6a7ef976125a206476ba611b9b6032baed',
    minters_reward: 0,
    lp_providers_reward: 249.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 249.77,
  },
  {
    address: '0xc452612e9201bb7402199a86215f4d0e3cf05121',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 249.07328152079683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 249.07,
  },
  {
    address: '0x4d1b657dd4cb41e0791ee30728c27b80b37ff3d3',
    minters_reward: 0,
    lp_providers_reward: 122.41,
    swapers_reward: 126.5669232533463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 248.98,
  },
  {
    address: '0xeed4aed0ae6d86d467e66744e94b5503f69ed8a4',
    minters_reward: 0,
    lp_providers_reward: 248.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 248.52,
  },
  {
    address: '0x78bb49929bdcea744ab337d808fe13f734c63278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 248.32330895245633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 248.32,
  },
  {
    address: '0x60e8fbd2e5df4a7c7a1178dc9a49be21cfe27d85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 247.51373271277765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 247.51,
  },
  {
    address: '0x4bc07270bc4bfe67104d65ab75d0a1af02b46a70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 246.99204941843877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.99,
  },
  {
    address: '0x4136a4e102c25f6225da8288b23dbd52308981ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 246.56454749852142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.56,
  },
  {
    address: '0xaf162bf5c9a8413a8628e630f961273d7f3270ab',
    minters_reward: 0,
    lp_providers_reward: 69.31,
    swapers_reward: 177.2226017275832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.53,
  },
  {
    address: '0x893c049d6ab920d60b1838be662b3ed8b1510878',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 246.24467341799064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 246.24,
  },
  {
    address: '0x040ced25a4f5a2d24a8b031d0cde9485f082f5a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 245.28151371341707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 245.28,
  },
  {
    address: '0xcf41e98b455010a43388d5082e2aa49280a7ca98',
    minters_reward: 0,
    lp_providers_reward: 243.61,
    swapers_reward: 1.422794522728178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 245.03,
  },
  {
    address: '0x98305ba0defb14ea0c15de2e9677203ac7833ca4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 244.9447189492306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.94,
  },
  {
    address: '0xd8d57a592147164d700d9745bcddf80293e261bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 244.74141030866227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.74,
  },
  {
    address: '0x85a183b2e67be0cffd609a352c62c701e3dee4db',
    minters_reward: 0,
    lp_providers_reward: 51.89,
    swapers_reward: 192.83883373306298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.73,
  },
  {
    address: '0x42c2c7dc0121091250b6aebb08d81e88590431dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 244.5389720404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 244.54,
  },
  {
    address: '0x2fef00eafb8ae0ef81cb3028f25db56d8b8153ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 243.63581953843416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 243.64,
  },
  {
    address: '0x6c024a7f5919b78144823a2aac5aee184e1f8bb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 243.2794608494225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 243.28,
  },
  {
    address: '0x71ca5d5ad11107fbaadf1c394c3cff8e2edf1e0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 243.24571143123126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 243.25,
  },
  {
    address: '0x3ad1ed2a65efe652b5499e03ccfadc72b16ff517',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 242.0436676988986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 242.04,
  },
  {
    address: '0xa458adc94adc7953f176cf52005d1654fc3b9876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 241.7583754773678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 241.76,
  },
  {
    address: '0xbf12db7db9658f88ea5ded2c9a6eccd12b707faf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 241.180202680458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 241.18,
  },
  {
    address: '0x4608e0f70ae80e4a85b83cd47477fc5a0c606bf5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 240.8079737419648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.81,
  },
  {
    address: '0x16b2e0235ddd6392c89eca4f46a49ef31c45c5e6',
    minters_reward: 0,
    lp_providers_reward: 219.19,
    swapers_reward: 21.445667616816436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.64,
  },
  {
    address: '0x18e368d3f9de886753381e8b74c29ce2e90b82cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 240.52340972059383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.52,
  },
  {
    address: '0x4055541aa65922a6e0c6d7b38d1775cb7b7fd18f',
    minters_reward: 0,
    lp_providers_reward: 171.57,
    swapers_reward: 68.58730927559266,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 240.16,
  },
  {
    address: '0x747a7659ff76c9c8b980198f545234e5fa499300',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 235.81249019839163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 235.81,
  },
  {
    address: '0xd986067a1cfaf0c7f56153e35f551e2d968011ee',
    minters_reward: 0,
    lp_providers_reward: 169.33,
    swapers_reward: 66.42135847158731,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 235.75,
  },
  {
    address: '0x36f76616b07ffc1188075dcb65d53e9cdc54fbdb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 235.4378660477521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 235.44,
  },
  {
    address: '0xa5b8a0365213fda3b77a7b9d7fd95dba44e36395',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.9942503529545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.99,
  },
  {
    address: '0x40a265ac676a459c98469251c59b695ebd9e3158',
    minters_reward: 0,
    lp_providers_reward: 233.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.45,
  },
  {
    address: '0x99070d47fce8a9682b8a598f00ba3753269a7789',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.32860312512312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.33,
  },
  {
    address: '0x49e997265ac1f6319c8d28601fed6a4233e68d01',
    minters_reward: 0,
    lp_providers_reward: 218.74,
    swapers_reward: 14.541643245649555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.28,
  },
  {
    address: '0x05ad154674369599021ea6d9ed5469760902ade8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.13698394108974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.14,
  },
  {
    address: '0x81eac02e7d4ba5f3217c6f9da2ea8b858e5b394e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 233.1306234400293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 233.13,
  },
  {
    address: '0x5b6ddacd4364bcc78beb89244e45cada97f261ad',
    minters_reward: 0,
    lp_providers_reward: 227.67,
    swapers_reward: 4.65301951076334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 232.32,
  },
  {
    address: '0xf74fbd09a7ae9d2a00d3396519e92d4b125859c0',
    minters_reward: 0,
    lp_providers_reward: 139.79,
    swapers_reward: 92.07535714193277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.87,
  },
  {
    address: '0x3eed652f4e8851684611565c35096d3f8978b3a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 231.48996006334366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.49,
  },
  {
    address: '0xc634eebdc530345df8a9642ab2786072922c5ad7',
    minters_reward: 0,
    lp_providers_reward: 177.37,
    swapers_reward: 53.76531262276701,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.14,
  },
  {
    address: '0x4f1439549970d88751268c28b141267c528c03ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 231.01013068401994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 231.01,
  },
  {
    address: '0x45d90f575770a4ce59e34bd1b8e102b5dfcc483f',
    minters_reward: 0,
    lp_providers_reward: 133.4,
    swapers_reward: 97.58322573765416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 230.98,
  },
  {
    address: '0xfc346d5bbfca224c256a64c72c6cdaacc32d5bc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 230.43711780823082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 230.44,
  },
  {
    address: '0xfd566a7f718a43cba9e1ebc0e9497f58f9191807',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 230.13188469003438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 230.13,
  },
  {
    address: '0x8ee8826aca04af9832081a627edfa05af4f5a373',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 229.78029562696543,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 229.78,
  },
  {
    address: '0x51933b68c855573dff6b1ab8895ce95ab45e3f30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 229.57834324689986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 229.58,
  },
  {
    address: '0x7016c87e714fa8f4efb4f7f5eb4ceda0bd0945a5',
    minters_reward: 0,
    lp_providers_reward: 213.8,
    swapers_reward: 14.910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.71,
  },
  {
    address: '0xce394f85bdd16ebe1422033a845c40b4ab3bcdf4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 228.3342669891561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.33,
  },
  {
    address: '0x34e9691c0ea9870abb0153f8a8cd95f5fe930832',
    minters_reward: 0,
    lp_providers_reward: 164.22,
    swapers_reward: 64.05871420308125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.28,
  },
  {
    address: '0x46a44660d4099de2aef0070f3b1d1f661ed3d05a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 228.22232452401704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 228.22,
  },
  {
    address: '0x02cd3a738e628736b3e1fe912f226573cbb2a48a',
    minters_reward: 0,
    lp_providers_reward: 142.35,
    swapers_reward: 85.62253777445042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 227.97,
  },
  {
    address: '0x7748c62db0a5f5d66ff65d7d9dd3098e6647ada6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 227.60476514805262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 227.6,
  },
  {
    address: '0x1f926d9218731ed9a5162016f374b664b2e2a2f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 227.3319404006827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 227.33,
  },
  {
    address: '0x03a9486d44b48f135be4ad98aa27fe20f18a8de6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.7070630715436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.71,
  },
  {
    address: '0x8793e80e0a14ff34f4ba908656e40feeb713eb85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.54718615554194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.55,
  },
  {
    address: '0x08cba700bd246ead2ea3f039180bbbcdbb376839',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.43526251330402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.44,
  },
  {
    address: '0xf508d42dfa00369887910e3dd0879d53dd5257bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 226.16794445552483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.17,
  },
  {
    address: '0x9af1d3ded9c4a2ddba23aa16aaf8d229e508610e',
    minters_reward: 0,
    lp_providers_reward: 62.72,
    swapers_reward: 163.28870224673537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 226.01,
  },
  {
    address: '0x1769a619abce8bd107ed8dd4752d41366426e35d',
    minters_reward: 0,
    lp_providers_reward: 225.85,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 225.85,
  },
  {
    address: '0xe322cd0397efaf736dd2cf94da09c58231a7e5aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 225.51651473547395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 225.52,
  },
  {
    address: '0x518324bbab3385f985c12a2cf9ffddf7a9717555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 224.44303131087244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 224.44,
  },
  {
    address: '0xa149aaa4d5065288a2af6f4c2717a3351621ca47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 224.38501411699283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 224.39,
  },
  {
    address: '0xb1976ef2d411ce816d9402db8fce8bdd0cea0c02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 223.9347641938627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 223.93,
  },
  {
    address: '0x99620e6f7d0bbbfa8b324e9ec8e618df9e2a3297',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 223.60129356016856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 223.6,
  },
  {
    address: '0xbcbdafef250b042cc3e3f6821f02fd31a25dbbff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 222.97717215035522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 222.98,
  },
  {
    address: '0x648f73b09ddea378d4ad859b939a346adb35a566',
    minters_reward: 0,
    lp_providers_reward: 69.08,
    swapers_reward: 153.50059178628322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 222.58,
  },
  {
    address: '0xcb7adf7a4ffccd8bdc407f43606abf6c8624e9d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 222.43316411458804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 222.43,
  },
  {
    address: '0x2fc2a37f4f75fb1e8c220292c1a1a669e6e3d6d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 221.61276527793484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.61,
  },
  {
    address: '0xebfb5ebfe94b1e1dd0fa4f60124b846754b9d3a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 221.24557359035182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.25,
  },
  {
    address: '0xfdb5c2e0f6a94bd79b2bd7e60bc404ba7caa2c82',
    minters_reward: 0,
    lp_providers_reward: 221.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.13,
  },
  {
    address: '0x18daceb616d48681c94b58ec78bdb5dbbad581e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 221.0637103307033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 221.06,
  },
  {
    address: '0xf48be36275f0eca5f2da8b3947b942c3e6e2e52c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 220.7762948782854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 220.78,
  },
  {
    address: '0xe44cb5de3f501b4754fb093bb4a9f30e20e582af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 220.4001697347411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 220.4,
  },
  {
    address: '0xafed63a5a031a82ae7ddb1907656e71e18cf63c6',
    minters_reward: 0,
    lp_providers_reward: 140.52,
    swapers_reward: 79.34048487329717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.86,
  },
  {
    address: '0x0fc61cf9b083cfddd46dad81c6a854ae4ae3e600',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 219.54604211064165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.55,
  },
  {
    address: '0xe7d4719ab0aba0c65f235fd70c494f5d0a8bea1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 219.1431619768109,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.14,
  },
  {
    address: '0x5f70b53f43160cf39c76890488febac5ee33bee7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 219.0550307531417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 219.06,
  },
  {
    address: '0x6d7e8f61c7ee5d8cf31b8be4ca05a14dacefde9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 218.64201014751762,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.64,
  },
  {
    address: '0x576fa4818714b0414b035fef0075d57c2391b2a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 218.53717669210943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.54,
  },
  {
    address: '0xe4c90bd22e454f854e950fbcc565a513acf0c096',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 218.5145100643292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.51,
  },
  {
    address: '0x174cf68d6549dc681065ab554d4deeb1376d1523',
    minters_reward: 0,
    lp_providers_reward: 22.6,
    swapers_reward: 195.65805587432175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 218.26,
  },
  {
    address: '0x8087b6b9c24a040f51570f08f1de92f6c9caef39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 217.25797568500832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 217.26,
  },
  {
    address: '0x584a9dd5bd53cfb9dc01b65bf7b684d2727140ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 216.86173423643677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 216.86,
  },
  {
    address: '0x8d0247ab8e65384ba25e879500678799948e6d08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 216.24450659224945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 216.24,
  },
  {
    address: '0xbc0b29ce067921d84a790fa4fb8f1d7a55392067',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 216.06410194989098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 216.06,
  },
  {
    address: '0x8bc03736c78d9cf8eee4efa9f197f9ff5ad331b3',
    minters_reward: 0,
    lp_providers_reward: 215.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 215.68,
  },
  {
    address: '0x7e7d42b3ae3f8b8facda72bb1e642cac158ebfce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 215.01735051583552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 215.02,
  },
  {
    address: '0xff7ef885d761d05233045086a17ee2e4f37c134d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 214.15621019430844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 214.16,
  },
  {
    address: '0x294d59f2e809ffc7b8b1f4aeb3817d50891a15a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 214.00783603272825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 214.01,
  },
  {
    address: '0xf90f56afa1dae825db57fc305b2f8f1ccf337531',
    minters_reward: 0,
    lp_providers_reward: 67.21,
    swapers_reward: 146.3982207853902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 213.61,
  },
  {
    address: '0x90ebb05fe594c651aefc8bd111de2704e4d69249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 213.5109649742091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 213.51,
  },
  {
    address: '0x6d3a70b6afcc365ede63b2a50fffd57bbdf047c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 213.3567089198246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 213.36,
  },
  {
    address: '0xecea48244e01747f4036b021243fc6d9afd40eda',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 212.56755274676522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 212.57,
  },
  {
    address: '0xecd748402a8b2728b15ce9bd4701e871b3d63a00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 212.54065385491052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 212.54,
  },
  {
    address: '0x3c71a16cdd6642145487847976b6e1e952220ffa',
    minters_reward: 0,
    lp_providers_reward: 119.79,
    swapers_reward: 92.67081484978159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 212.46,
  },
  {
    address: '0xb7bf92a77b5032f216dfbd0d9d0461b79535c6bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 211.27318814361055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 211.27,
  },
  {
    address: '0xf3c7dc4c8f3150d77b766991f3ae5b855ee5dbf6',
    minters_reward: 0,
    lp_providers_reward: 150.1,
    swapers_reward: 61.02039924794418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 211.12,
  },
  {
    address: '0xfd44555955432858778af4c8ccec80c2e0a63278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 210.75634053230053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 210.76,
  },
  {
    address: '0x85f67cf442768b56c0fff44f5367c06a60bbbf39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 210.63658354617291,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 210.64,
  },
  {
    address: '0xb4f82349532fa1c3e408d4691122d5860f434549',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 210.14871160793456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 210.15,
  },
  {
    address: '0x89d963698dec3dbfc094b57ed00e909e603dbe7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 209.66039887635537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 209.66,
  },
  {
    address: '0xfeb23cbbe5d83e906768e890eeb1c16431a70c00',
    minters_reward: 0,
    lp_providers_reward: 105.24,
    swapers_reward: 102.93047521707483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 208.17,
  },
  {
    address: '0xb29a6b2e18f663aa6dcdb2d05a26d299e2f00e54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 207.91092601552174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.91,
  },
  {
    address: '0x44f9ae13cee696468f5fbd8c9e1bccc253c99c72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 207.50756377435386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.51,
  },
  {
    address: '0x796142461c6a538f58c76ebb2855d4e7fb896c84',
    minters_reward: 0,
    lp_providers_reward: 200.03,
    swapers_reward: 7.084970635707407,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.11,
  },
  {
    address: '0xf183946296cb00ed45233b101c3e1d5dc321daa6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 207.07878788315378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 207.08,
  },
  {
    address: '0x35831dd1b909058c06d1a81d652bae40c10f70df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 206.60818891618334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 206.61,
  },
  {
    address: '0xafda904638150600c714adf5c55d688d6baea929',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 206.14934322823734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 206.15,
  },
  {
    address: '0xf4e85d0d08aeb968c096db6ea226b06880a8635d',
    minters_reward: 0,
    lp_providers_reward: 200.24,
    swapers_reward: 5.825861860329114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 206.07,
  },
  {
    address: '0x4ec544c1114d5ea6f316a5b0d1174718d6f8c7be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 205.51378022901798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 205.51,
  },
  {
    address: '0x2a8538a2335ea7448b615007d0e9c6b8fd79eb96',
    minters_reward: 0,
    lp_providers_reward: 105.89,
    swapers_reward: 99.2514046378521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 205.14,
  },
  {
    address: '0xe9de1027c793b566a9a11ae4297efde47fe0d3f7',
    minters_reward: 0,
    lp_providers_reward: 204.41,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.41,
  },
  {
    address: '0x03e50a3d1eedfb7a32d480730bc1844241c179f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.35504573000077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.36,
  },
  {
    address: '0xd1cb18b48f8ffc3d6ae454db575f52897545bce0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.23183273288694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.23,
  },
  {
    address: '0x69168d4233808c7a2c2b87001ae097a63163b642',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.22285240619257,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.22,
  },
  {
    address: '0x7cee7755abbd4d1ed71ca47a4d313f8b2a760d02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 204.0477125262256,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 204.05,
  },
  {
    address: '0xdf0566b8649997095df6375ad42bb37f532f7e09',
    minters_reward: 0,
    lp_providers_reward: 130.93,
    swapers_reward: 72.60075995208423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 203.53,
  },
  {
    address: '0xeca8191b8a0e8953d56d28c784b7a8309bc35ade',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 203.0993628341597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 203.1,
  },
  {
    address: '0x7f6d4ac53a0d9d25ff5ab451265fbeb81de0a8f6',
    minters_reward: 0,
    lp_providers_reward: 108.24,
    swapers_reward: 94.6994444190449,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.94,
  },
  {
    address: '0x2b3b4ffdc51872f58313decff390604a58da65e1',
    minters_reward: 0,
    lp_providers_reward: 202.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.9,
  },
  {
    address: '0xef260091a4b62d0e7b3026614efb335838f9a997',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 202.4547622252486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.45,
  },
  {
    address: '0x9d9c5deeb58d02a60350cd8a97cb9d91d6d60aaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 202.42458228882833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.42,
  },
  {
    address: '0x0d02e5e6448fb2990981d3ef8eef0100f57b019e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 202.1908119711062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 202.19,
  },
  {
    address: '0x2face1ec0f39c7d3628620eb58c33a6f11069b14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 201.67759202350382,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 201.68,
  },
  {
    address: '0x7cb24a92eb6bbbb69eb55a4705a9de427841e8df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 201.59174627158308,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 201.59,
  },
  {
    address: '0xac60d837e6137ce79b1b8daf73cb780d797b6638',
    minters_reward: 0,
    lp_providers_reward: 53.12,
    swapers_reward: 147.55024288257601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.67,
  },
  {
    address: '0xe51b8bf2948d52c6cc0f385028ae92baff2e9a4b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 200.6250822828026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.63,
  },
  {
    address: '0xdebb2871d459c9a052be3742fd126b79b1d102a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 200.3354788736246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.34,
  },
  {
    address: '0x8551212e4849afaa0f9590c6666bdeab91c863ef',
    minters_reward: 0,
    lp_providers_reward: 99.88,
    swapers_reward: 100.41387194006401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 200.29,
  },
  {
    address: '0x739d646c7f31e9698d348b6f63490d9e09cb8d60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 199.72846895229034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.73,
  },
  {
    address: '0x17855737a5036a9e84007aff94e30c2cc5839621',
    minters_reward: 0,
    lp_providers_reward: 196.69,
    swapers_reward: 2.893630665167994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.58,
  },
  {
    address: '0x179d8379c1d6fc2f23c5d8e3005e797f5d90c2b1',
    minters_reward: 0,
    lp_providers_reward: 186.3,
    swapers_reward: 13.067265208136243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.37,
  },
  {
    address: '0x80e896512c3b6507b7704b02c7441758caed87c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 199.3433167401179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.34,
  },
  {
    address: '0x165e2114d488496c916b289823ae64f10e473b09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 199.24604975668203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 199.25,
  },
  {
    address: '0x16019a4fdde1856c6afc4f4a71c9665f2ccf825d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.94128854300365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.94,
  },
  {
    address: '0x9afbc1c8922396eb9eb328ca51186ca603b7b41b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.87440554856872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.87,
  },
  {
    address: '0x92c94cb1c9bcf5e7c0eb42b8123a96c1108ed71d',
    minters_reward: 0,
    lp_providers_reward: 198.77,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.77,
  },
  {
    address: '0xe3e9bb45d3dad20fd305dd6c299973d90738459c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.20499784233454,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.2,
  },
  {
    address: '0x1b96354c37df67db8f58852a64c2fc27a5797679',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 198.0473075375614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 198.05,
  },
  {
    address: '0x419ca45a734d1db6288e5a9a31663b51ef851ece',
    minters_reward: 0,
    lp_providers_reward: 194.9,
    swapers_reward: 3.090268346056633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 197.99,
  },
  {
    address: '0xd3cfb037c8d196ab7763d1605d28c8be0ce6280c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 197.12543835531977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 197.13,
  },
  {
    address: '0xfcf750d7a9201a0979694033a96865e44f963d0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.76263257327878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.76,
  },
  {
    address: '0xecbbadab24f861aa4f6fca8fafb9c3cc74280f5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.48255504921164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.48,
  },
  {
    address: '0xca042e76f58d75dca399e2514fc791f5d6546508',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.4095949015468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.41,
  },
  {
    address: '0x72810529c30fd63ec681e9d54d35c8c203a81e2d',
    minters_reward: 0,
    lp_providers_reward: 123.05,
    swapers_reward: 73.32277456518781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.37,
  },
  {
    address: '0x435d3c3efa623b79aa2282b265dd7ffcdfaa7791',
    minters_reward: 0,
    lp_providers_reward: 71.48,
    swapers_reward: 124.85190443833078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.33,
  },
  {
    address: '0x04cde7fb3b21898ef71fc1b4ea2a7a0542944bd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 196.20451311157714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 196.2,
  },
  {
    address: '0xfbb63b2ac72421238e1ad625ddd1933689fe1e20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 195.98224625942424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 195.98,
  },
  {
    address: '0xa6c2a89944a81eb26bfcca57f99ce4b8ec0c9e7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.91155913679665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.91,
  },
  {
    address: '0xe09b97ef6a3360793e9217478a15cc5cb6ef9955',
    minters_reward: 0,
    lp_providers_reward: 181.9,
    swapers_reward: 12.90477573319812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.8,
  },
  {
    address: '0x8a116a4aa75b999a9695963f9c57e40c5c97b72b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.77845850776808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.78,
  },
  {
    address: '0xd91aa1140154d9db7674697f94ece9c9845f1c6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.57687009390511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.58,
  },
  {
    address: '0xa10b43767ff8f797290727e5eaad60f794173eb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 194.27450833850185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 194.27,
  },
  {
    address: '0xb9abd5980a3ae88d088e3b43bf1006c5d8c835d5',
    minters_reward: 0,
    lp_providers_reward: 193.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.78,
  },
  {
    address: '0x050931785b6f2d5c6143d05e63aff7623d92c180',
    minters_reward: 0,
    lp_providers_reward: 168.99,
    swapers_reward: 24.166539089656204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.16,
  },
  {
    address: '0x941477af54159770dcf2493eb10731603266a9c0',
    minters_reward: 0,
    lp_providers_reward: 183.3,
    swapers_reward: 9.840734089256891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.14,
  },
  {
    address: '0x78b788f7b1d5f652ed336980c4e0a45c1531fa32',
    minters_reward: 0,
    lp_providers_reward: 7.65,
    swapers_reward: 185.37954323350186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 193.03,
  },
  {
    address: '0x66882bc2e33ae15780efdef9d880286e9560362a',
    minters_reward: 0,
    lp_providers_reward: 43.17,
    swapers_reward: 149.74407478472057,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 192.91,
  },
  {
    address: '0x177ebebb0a642c087cf6f992650bbd3216489b01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 192.64069594767645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 192.64,
  },
  {
    address: '0xb90f5bbdbbacc9080aa9cc8aeb95f2f047431bdb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 191.39077968734472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 191.39,
  },
  {
    address: '0x9478c744401bb8b130d542257dcf632930883b1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 191.09055301623465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 191.09,
  },
  {
    address: '0x775ba05c6e4b1a08818df3bc3c2bdbd056198846',
    minters_reward: 0,
    lp_providers_reward: 190.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 190.93,
  },
  {
    address: '0x8f33accd15ffac27f049ed0e78b2f41f8ee5ddb1',
    minters_reward: 0,
    lp_providers_reward: 79.97,
    swapers_reward: 110.55546279895248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 190.53,
  },
  {
    address: '0xa913c0e4f21a83a6fe698f25c0c20a7a871c3b15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 190.48921388224488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 190.49,
  },
  {
    address: '0x4e2c6e6b307be9c8536de4ec2d6e137c7cd71af8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 189.91858317811628,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.92,
  },
  {
    address: '0x22878c6563059dd72a7c17d761abe3329a66cad8',
    minters_reward: 0,
    lp_providers_reward: 189.52,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.52,
  },
  {
    address: '0xf24291e6d7e9b91bd9d83fe288d6e009d8906671',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 189.37156959431462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.37,
  },
  {
    address: '0xd1d2f6dd89c3b869cfd600b838287c3d26f5ff62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 189.2299174364587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.23,
  },
  {
    address: '0x194446a1358bcb4fd8ad46ffb9dcaa62e4a4462b',
    minters_reward: 0,
    lp_providers_reward: 79.53,
    swapers_reward: 109.66205487493303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 189.19,
  },
  {
    address: '0x31ce0717fc2b9a4cfc33d6451e3ad4fbf4849608',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.94198584941353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.94,
  },
  {
    address: '0xb6b7b63ae53c41bdde3704ae028a13d4bbaddc07',
    minters_reward: 0,
    lp_providers_reward: 186.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.59,
  },
  {
    address: '0xd4444e2bb3da3896b9f5df4937725a114353d0f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.47213789037713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.47,
  },
  {
    address: '0x97ae37c64e6ef216327cfd648e485edd84d3abbd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.44572148594094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.45,
  },
  {
    address: '0x863a3039596ed84cbe21a9bb2a26ad411ca7fe60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 186.26895314831847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 186.27,
  },
  {
    address: '0x032749a665f5c74837bcad5fced8ab2c8a720d41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 185.7763301081045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 185.78,
  },
  {
    address: '0x621ebcccb1206fdbafcce3d1a2b7f691086c1658',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 185.7210668088387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 185.72,
  },
  {
    address: '0xb3bdc2c5a7a7304151e2d1536dac2b20727c50f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 185.6679057591645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 185.67,
  },
  {
    address: '0xa687392abc0a37efe9128341610e79fbc47f0f4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 184.90043486802483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.9,
  },
  {
    address: '0x159ee973f9d76ca8aecb42a7b7bd5cc65a016329',
    minters_reward: 0,
    lp_providers_reward: 184.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.86,
  },
  {
    address: '0xfda6d99eaed6c254c0c40ee27dd3692bf6dc8992',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 184.73463897612567,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.73,
  },
  {
    address: '0xcaccf474116f42f1706d78f790f623a07157d135',
    minters_reward: 0,
    lp_providers_reward: 184.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 184.49,
  },
  {
    address: '0x0a9ba3910c0e772d370c7dbd3bc9bd8be89c341b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 183.95798387069613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 183.96,
  },
  {
    address: '0xad0d2e81018eb882b7a376bbd2bb993f7491af2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 183.53476472631198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 183.53,
  },
  {
    address: '0xa7a3f35a1be897afdb6770d7dc16dfe71e951862',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 183.33595239954698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 183.34,
  },
  {
    address: '0x3f2a533314086c414bec46498a7426977c92f5f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.97042941616004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.97,
  },
  {
    address: '0x3d13a3ca8f5378f45cbce6bc85106cb4944562c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.88057173554748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.88,
  },
  {
    address: '0xfdeeede672e05f662fa5979c283a5106e34e8a7e',
    minters_reward: 0,
    lp_providers_reward: 158.54,
    swapers_reward: 24.21549857040562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.76,
  },
  {
    address: '0x6d04c86482266a83ea09668bd95be70be4ff48c4',
    minters_reward: 0,
    lp_providers_reward: 150.19,
    swapers_reward: 32.496856743382054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.69,
  },
  {
    address: '0x6f389cdd00fc17dee90101830ae35f8b64f7494a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.31005252377605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.31,
  },
  {
    address: '0x269cf3ce8d052184f20c2169305eba9017b33667',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 182.20419219894612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 182.2,
  },
  {
    address: '0x3b682532275006ed935f69ae3cf12fc57ea8cd9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.66745803996275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.67,
  },
  {
    address: '0xf25c47ca3aeec22a7e030a0d80af29ae31a243c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.65920651309446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.66,
  },
  {
    address: '0x2ca8a723e3e435e035c1d426cbc37b1e92b7b7eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.64344371726105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.64,
  },
  {
    address: '0xb5b9cb4b86bc12af37a5d2f0746665701adf2004',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.6102148670665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.61,
  },
  {
    address: '0x365eb86628bb36140f7a6d8691e96067c7222dec',
    minters_reward: 0,
    lp_providers_reward: 117.46,
    swapers_reward: 63.993918481623055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.45,
  },
  {
    address: '0x9e2a16ad7098b0aa23a07e49c42fe4987b6496e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 181.18830927216993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 181.19,
  },
  {
    address: '0x0c056ca5af238e04811a1f4838d476ff7bcef00e',
    minters_reward: 0,
    lp_providers_reward: 180.79,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.79,
  },
  {
    address: '0xf97eb20765fff93b1480730a7c7ea75828e73ed4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.69565077444489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.7,
  },
  {
    address: '0x12bb07e3d2e1e97e3c7bfda3afbbdbd37202b337',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.69565077444489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.7,
  },
  {
    address: '0x510d47ed4c51975f77af2a086794ef2b0d4b0ee7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.69565077444489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.7,
  },
  {
    address: '0xb7af19f005009a6826f9afe8114f12f028a42458',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 180.396425706221,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 180.4,
  },
  {
    address: '0x079ff3e381d0d99b961e28fb470c246c88ac4ca0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.96209001127946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.96,
  },
  {
    address: '0xc3149db635b8261ed5ae79c60712ff2f276550b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.63639413647968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.64,
  },
  {
    address: '0x00bd1b07e63573cbff617f860d0de5aae017da99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.5102293834841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.51,
  },
  {
    address: '0x10630ca132ab6216cb296872ce1947088157af94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.36229401852435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.36,
  },
  {
    address: '0xbd3bf05625538c9ddd85982cee5226784a698e63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.30651957017682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.31,
  },
  {
    address: '0xb726085d537926c67e35cf0e4dde6405b898364f',
    minters_reward: 0,
    lp_providers_reward: 95.44,
    swapers_reward: 83.62611052373883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.07,
  },
  {
    address: '0xab795d6295e420ac6aff862a503761cb00da2447',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 179.02739596583345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 179.03,
  },
  {
    address: '0xd22811115b5107f033324971277e48312647ba93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 178.85650377960107,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 178.86,
  },
  {
    address: '0xcc0f200282301bde72d967640fa06babe6103d27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 178.67114459335042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 178.67,
  },
  {
    address: '0x3472fa1a1067bce3c74dd66574b271742a1df8ef',
    minters_reward: 0,
    lp_providers_reward: 86.54,
    swapers_reward: 91.35910431099863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.9,
  },
  {
    address: '0xbbd085033473568cb424969f7b28ae23bbadf45b',
    minters_reward: 0,
    lp_providers_reward: 97.36,
    swapers_reward: 80.53959741450062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.9,
  },
  {
    address: '0x9fae8d3c7cdad7aa4400d9b9e5f1c67f09b49126',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 177.67687428338786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.68,
  },
  {
    address: '0xa1e0a34ad57f6d2d34ae4bf4b6faf4692907786d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 177.3815949169303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 177.38,
  },
  {
    address: '0x00f5481262d4845b292ba0c41752de258b3bbeec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 176.14020932006346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 176.14,
  },
  {
    address: '0xd9f50115bf7ee0dfc81e65145bb484bf3ad6dbf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.89629867450392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.9,
  },
  {
    address: '0x17dafa7c3f4bbbf51f133adad6e32ed073c7bd42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.8123643372317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.81,
  },
  {
    address: '0xf8e36fad98ab3eda56480445af3f8a5b1e4444de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.73221097447558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.73,
  },
  {
    address: '0x5416905249699380c7a37ebfb35924c7b22c6524',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 175.65085043845463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.65,
  },
  {
    address: '0xd37d3bf75b112a31e4d1c3c30382ea5e9c572239',
    minters_reward: 0,
    lp_providers_reward: 175.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 175.1,
  },
  {
    address: '0x28d01ffc2628368903c86220c14aa18d69d8e467',
    minters_reward: 0,
    lp_providers_reward: 24.83,
    swapers_reward: 150.14394842375333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.97,
  },
  {
    address: '0x91eee6dc3696e862a2e17df79b621ccecade78ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.8766782493457,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.88,
  },
  {
    address: '0xac5cf01a7123b121b24f4e30ae7784980f95a11e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.86156823811166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.86,
  },
  {
    address: '0xe42c90b0167d2040de374d4c655307fee99a3dc1',
    minters_reward: 0,
    lp_providers_reward: 57.66,
    swapers_reward: 117.08019320450563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.74,
  },
  {
    address: '0xdc0011e205585d8c5b1488bc580139e4398f08bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.594657632647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.59,
  },
  {
    address: '0x090af172a0c1575af4222a49c3582a6356d29e63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.48345817887153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.48,
  },
  {
    address: '0x3b4f3fb912798571c4461ebaf9f97decdd254bc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 174.20052544042156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 174.2,
  },
  {
    address: '0x5c2be8e2d70c7608f8f24ea0d5661fd8831c2e99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 173.9467544509715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.95,
  },
  {
    address: '0xca4af533276282e14d938cd8b34186c12f2ab908',
    minters_reward: 0,
    lp_providers_reward: 173.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.35,
  },
  {
    address: '0xc418a29cfc0ec7fc1c06e61fdafe49b318c43f4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 173.2167017684857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.22,
  },
  {
    address: '0xf46ffea2203cc1eab3b1d13768a04e1e24c7ca93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 173.1021221287029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 173.1,
  },
  {
    address: '0x09ab231d7164f32cc1c5c2f90229d1395ad9147f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 172.94427345098993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 172.94,
  },
  {
    address: '0xd348f5def2281e3bdcf37708d13f11c3c7841f8d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 172.41198733266933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 172.41,
  },
  {
    address: '0x62381d7f95ad72fb07b29c5d2413431c3fb25a55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 172.02312901729394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 172.02,
  },
  {
    address: '0xfcd7b93a7b3f4f1276f1e89b63d22f1ef4b4bf37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 171.98237743577855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 171.98,
  },
  {
    address: '0xe37ffc9451d525c9949465e722946dc4db1c0662',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 171.74073467653184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 171.74,
  },
  {
    address: '0x84c93731bbb106e5bf01fe93b8ab47b4548ecfc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 171.6117550802202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 171.61,
  },
  {
    address: '0xb883a61590a31da2d2069d1c8d5450970758e5f7',
    minters_reward: 0,
    lp_providers_reward: 54.55,
    swapers_reward: 116.36730134613653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.92,
  },
  {
    address: '0xd490133e72dce5a80e5cfa151184ece73cd6f16a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.914616706833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.91,
  },
  {
    address: '0x66ba0e376df0f87b0fd1458354d41083046b1e1a',
    minters_reward: 0,
    lp_providers_reward: 170.75,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.75,
  },
  {
    address: '0x9200beb7858275baf7231e08a912b17aaf2b0b93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.29970084805686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.3,
  },
  {
    address: '0x0db3bb7f2d8f539c3d60e63a0295d3fbf655a158',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.27126258584715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.27,
  },
  {
    address: '0x07f6c3ab4db889dcd5c268ee5ab00717caf95a61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.11941284020483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.12,
  },
  {
    address: '0xa1d80a9e42ec86e90bb732c2e1c62408bf1bcdfd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 170.03532058264636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 170.04,
  },
  {
    address: '0x9dc8081da2012c6f1f66b01598769a46aa2c7ba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.57247873733036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.57,
  },
  {
    address: '0xaf906309e154fcbe7ff92b1ada8bccb8653cbb09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.55477717006426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.55,
  },
  {
    address: '0x12b3fee3e3860a22bb2191a08c1170bf066c77e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.43113115960483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.43,
  },
  {
    address: '0x8e0108bb48aaaeed86c0f0654e85090c9ebef638',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.19060432375724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.19,
  },
  {
    address: '0x29a82f3fa67867d287b472dae09c91a54906416f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 169.15750641629165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 169.16,
  },
  {
    address: '0x38c213a1614012f6a6c394296c09606fb61b6e1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.88792484281294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.89,
  },
  {
    address: '0x9db87f12423e5d2117710d4c9c8a698a84befab1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.88709212057717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.89,
  },
  {
    address: '0xee91725ebc479102c89221ae0e36672423bcdb4a',
    minters_reward: 0,
    lp_providers_reward: 0.49,
    swapers_reward: 168.25466528047968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.74,
  },
  {
    address: '0xa529e69a778c2c648df101a415ec85430566ab57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.61299852970288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.61,
  },
  {
    address: '0xbedce4761baff63d26fbf3cc009e4eec198c4558',
    minters_reward: 0,
    lp_providers_reward: 74.06,
    swapers_reward: 94.5125085417493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.57,
  },
  {
    address: '0x637ca4e60c85dfbe9e3fc58ac8fdc8ba968897c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.50025652579302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.5,
  },
  {
    address: '0x385018e552d732cc2515e8b505f12f3e8c4cb6fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 168.0362380743114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 168.04,
  },
  {
    address: '0xb61a8be8f7676c5d2e47af9e2945247c301635ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 167.37083114871928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 167.37,
  },
  {
    address: '0x77d11b095417b4413099f4975a161d06c52083a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.78448458414726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.78,
  },
  {
    address: '0x998c0bfa4776e5e59180bd138ae8b1abb32d8945',
    minters_reward: 0,
    lp_providers_reward: 110.22,
    swapers_reward: 56.442138929414845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.66,
  },
  {
    address: '0xfca719b2f234fce1760895b2ecb5fb2284bcb227',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.33704262641834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.34,
  },
  {
    address: '0x49dcfe2adec314c730652cdc3184c962eef10a82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.33452194495823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.33,
  },
  {
    address: '0xe89f14a82d018ef607460a152697725b94ee37fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 166.0158318976129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 166.02,
  },
  {
    address: '0x7496cc89ee286802b891bf951ae47264b0e8498e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.9350862318333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.94,
  },
  {
    address: '0xa6980c9fbea0cf9b772dff484d5b6cd36194a7f7',
    minters_reward: 0,
    lp_providers_reward: 165.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.84,
  },
  {
    address: '0xc5f62c4ada53dfd7f521d32c2e7af520d0f136de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.6563660976759,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.66,
  },
  {
    address: '0x788eeeef9660ac2a60e9bfca88f51b50b1df2cb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.59501313632154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.6,
  },
  {
    address: '0x62874c7701415216426a5b45f56012111640f26e',
    minters_reward: 0,
    lp_providers_reward: 165.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.25,
  },
  {
    address: '0x9cff7da0f0d9399605f50101381dc421948966b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 165.1619651612694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 165.16,
  },
  {
    address: '0xdabd97880702b57f3a713227c1adbfb72e5c0c08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 164.25941536585387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 164.26,
  },
  {
    address: '0xfd3cdd5cb88344c18d6369a2e61c2203ea6a7d3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 164.22782184494835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 164.23,
  },
  {
    address: '0x01cfcccc806b42557368a6f64b5ece0cee972c90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 163.9167147648163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.92,
  },
  {
    address: '0xa378ab1f57db0de82f1948d2d09f4cd303d10082',
    minters_reward: 0,
    lp_providers_reward: 73.94,
    swapers_reward: 89.74093641523227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.68,
  },
  {
    address: '0xeafd9991d29080aef7267496c64e9a66187277cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 163.26741142346572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.27,
  },
  {
    address: '0xbccc0ed2074907c3393e3dc14e72abdb6950f6d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 163.16736277226727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.17,
  },
  {
    address: '0x55958d52b0796c4cfed38f6c125df04be644c428',
    minters_reward: 0,
    lp_providers_reward: 115.84,
    swapers_reward: 47.330117034119844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 163.17,
  },
  {
    address: '0x3c4980898ff626fb538ce60509b745c6ba9ec5dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.7166464193963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.72,
  },
  {
    address: '0x6be5d58e4ea3b6fb59ffe116f34b8e34108b6673',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.53343696023617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.53,
  },
  {
    address: '0xed233283b57abb10395666497804f827ab393500',
    minters_reward: 0,
    lp_providers_reward: 68.46,
    swapers_reward: 93.88786312216565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.35,
  },
  {
    address: '0x6d09b955078c0b023903b390a434fbbf23ad50cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.33295528003427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.33,
  },
  {
    address: '0xba6129d6756f9cb2515e7454318bb51c5e0b9237',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 162.13096384207648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 162.13,
  },
  {
    address: '0xd66e1f7035491633a00a87ff851051710168625a',
    minters_reward: 0,
    lp_providers_reward: 161.38,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.38,
  },
  {
    address: '0x0730eed296483e7f19fafd631acde3c897ce2c72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 161.3279939816365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.33,
  },
  {
    address: '0x0d9073c59de3cecd911de665fd5a52be70b48ec9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 161.18255296513632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.18,
  },
  {
    address: '0x4785334bafe9dc1dae8d89d01059d87c3b221e00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 161.0157516670559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 161.02,
  },
  {
    address: '0x85bbd83e7a53a6cb983a1e6ea19c9e8164d30eed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 160.96787951971808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 160.97,
  },
  {
    address: '0xcf29484fbed558c4d1a23163cd8019c56379d203',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 160.8374884476565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 160.84,
  },
  {
    address: '0x65371e819a836a5ed9e782608f500b9dec54c301',
    minters_reward: 0,
    lp_providers_reward: 93.77,
    swapers_reward: 66.57948955035252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 160.35,
  },
  {
    address: '0x954485cac28388af1813ac1ee280bb48218715a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 159.35155824578752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 159.35,
  },
  {
    address: '0xad4d74cd7f17edae3c548ecbc0cbe0791c89a492',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.80234642641767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 158.8,
  },
  {
    address: '0x013b89ae6b2f9a7ace94c5c6aabe039517c3e11e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.49560297669942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 158.5,
  },
  {
    address: '0x9213ab87e812e5473233b30aa2cb2623dc792898',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 158.1747485734106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 158.17,
  },
  {
    address: '0x02f2baf26b2ecef29a50aa8fef0675fb5081d3d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 157.68678680744702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 157.69,
  },
  {
    address: '0x67c2136f0ca4b6696a5027342c7db5d8fb59045e',
    minters_reward: 0,
    lp_providers_reward: 127.42,
    swapers_reward: 29.974142652604883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 157.39,
  },
  {
    address: '0x89f5dc461d0a195a5f9afea9f2a763f67cd7f4ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 157.35330257857999,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 157.35,
  },
  {
    address: '0x0f546efe819698e88639680bd894795a6b2ad7e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 156.95067259381386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 156.95,
  },
  {
    address: '0x9aa9fcc3813dfaeef6baae62efcc00b257797c09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 156.28596535291442,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 156.29,
  },
  {
    address: '0x328a3867caf19d64232f7115c0aaba32c0b48616',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 155.71448189145764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.71,
  },
  {
    address: '0xde85cf5dab18e07696a6733cd33cb58eab3818ec',
    minters_reward: 0,
    lp_providers_reward: 70.61,
    swapers_reward: 85.09662350312462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.71,
  },
  {
    address: '0x69e051f04b5b8203387494f39c933988e5076e16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 155.19221105493185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.19,
  },
  {
    address: '0xa6619cb9612f9ba338caf668df73e669798cd41b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 155.1721509961856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.17,
  },
  {
    address: '0x955065690060227c0c27936c627558665d7b38b3',
    minters_reward: 0,
    lp_providers_reward: 155.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.12,
  },
  {
    address: '0x140e7aab562241ff022e3a366d27ac007ab51124',
    minters_reward: 0,
    lp_providers_reward: 153.04,
    swapers_reward: 2.0223450072727354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 155.06,
  },
  {
    address: '0xa28737d30b69ae23e32935ef8a497c6587021055',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 154.74884641617516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.75,
  },
  {
    address: '0x6f087e6cd5284f4d4e91b4117724f347ea6677eb',
    minters_reward: 0,
    lp_providers_reward: 123.06,
    swapers_reward: 31.606287406551136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.67,
  },
  {
    address: '0xe2304dd4ba5e9f1bee3b291f920b7f7604666fa0',
    minters_reward: 0,
    lp_providers_reward: 35.45,
    swapers_reward: 119.02465362182026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.47,
  },
  {
    address: '0x49c1fb54bf47b95c2c3d505ebb97067a8ac5ffcd',
    minters_reward: 0,
    lp_providers_reward: 106.15,
    swapers_reward: 47.89597651831503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 154.05,
  },
  {
    address: '0x38e3faa8a08839ec540496bbe035b110bc975c6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.89714706080255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.9,
  },
  {
    address: '0x87090041ebae950af919652aea92253de162f512',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.8904518539025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.89,
  },
  {
    address: '0xb411e467c77be3299c2dd23354c916a3ffb8b701',
    minters_reward: 0,
    lp_providers_reward: 146.07,
    swapers_reward: 7.4120107526596115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.48,
  },
  {
    address: '0x570a5b79482976c965b5f13c60ca3f81e853d1db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.1645941237674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.16,
  },
  {
    address: '0x33f2b7cd086fdf24ab73926baafc532f840b699c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 153.06139122954244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 153.06,
  },
  {
    address: '0x3ed994504a84813cf9414e42cc8f07c16ff5b898',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.7448170766592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.74,
  },
  {
    address: '0xcc566318510e9622cdf34012ab7669d5e3525129',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.53364503206322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.53,
  },
  {
    address: '0x4eebb31b05272caec451581b62bf0c372c9767ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.53004669356045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.53,
  },
  {
    address: '0xe2fbb1fa5380f14aeaa4f248b05964d0742d122a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.4069995760121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.41,
  },
  {
    address: '0xd3f1070b46e2aad65733e5a6d1cfd029c67b0791',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.16876446518035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.17,
  },
  {
    address: '0x29d5ac65e839879c87ee5208f9b4b821299492a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 152.1475585738595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 152.15,
  },
  {
    address: '0xe72f93993f71a4b84246f7cf45be2b3473358461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 150.97035601104724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 150.97,
  },
  {
    address: '0x65acbe92024c7669cbf8eea8ee67694e72a77378',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 150.2609681268329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 150.26,
  },
  {
    address: '0xdd81f86b4e8ab9c512d9ef6940b443c1759e4988',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.90839675622829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.91,
  },
  {
    address: '0xe709238bb43de71c9e16159baa67c628ff0d8e7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.74530182626648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.75,
  },
  {
    address: '0xf3195bd69dd492ffe2cc3d2d6b6c97566e807458',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.64842571150598,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.65,
  },
  {
    address: '0x97d4ba22ea1cf9d5ef7d090b483c015a0c3d62ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.62125434749794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.62,
  },
  {
    address: '0x55d9553c7ae565f4e29da88bff14adfc5203fce3',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 149.10669210884117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.5,
  },
  {
    address: '0x15a20cc468676b81d297f05bb31638002d3f210d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 149.10140386267776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 149.1,
  },
  {
    address: '0x279f09a177553b55c57a805392fb89c2eaf271d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 148.47088009204617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.47,
  },
  {
    address: '0xbd4f0f12f218b40be05d9d5d845b41dd0f68135d',
    minters_reward: 0,
    lp_providers_reward: 10.16,
    swapers_reward: 138.13031960766767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.29,
  },
  {
    address: '0x6d11f00e8e2bdb4508fc0e04a0e769b7dea8da16',
    minters_reward: 0,
    lp_providers_reward: 119.81,
    swapers_reward: 28.38421577029301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.19,
  },
  {
    address: '0xb650573d34b2c70a5d5aec943c18a5a375df903d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 148.08579692652677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 148.09,
  },
  {
    address: '0xca2474c8ce5cc302473ca32da2a3b105989a5fc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 147.8340119438476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147.83,
  },
  {
    address: '0x2c4c06abec09e600fddc406e94ac7607ffedfb15',
    minters_reward: 0,
    lp_providers_reward: 83.37,
    swapers_reward: 64.32592242246496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147.7,
  },
  {
    address: '0xb26338afa4446603fb5744d14cd26d541f9f051d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 147.0234324739507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147.02,
  },
  {
    address: '0xef89bd5a5e2592be73bfde5b94bf6599fc078570',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.9987555441745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 147,
  },
  {
    address: '0x7b170b939c4bea5421bb9dbd07f29a967a065ba0',
    minters_reward: 0,
    lp_providers_reward: 87.63,
    swapers_reward: 59.351348204247515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.98,
  },
  {
    address: '0x2c99c1f51bdb04b8fb699c56928f80610328874a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.82780388208678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.83,
  },
  {
    address: '0x13efddd89fee639878bb3cc0b7f6240e016ba30e',
    minters_reward: 0,
    lp_providers_reward: 3.83,
    swapers_reward: 142.97673539640098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.81,
  },
  {
    address: '0x2be5c96955aa755e07edcc647f52480e762981d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.73693923006485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.74,
  },
  {
    address: '0x63f01e5ebe287bcb84a5a68fa87201c6d8120ee5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.60984195388698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.61,
  },
  {
    address: '0xb03c3e3a0639d699e69ffd50be0350a1a89c6a13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.36839028831633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.37,
  },
  {
    address: '0x00ff10ccfc480bc40b34f1f747ae66c710ce2af5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.36000848754702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.36,
  },
  {
    address: '0x82ed5174cfa1131f6a3479d1dad5dedc74d22837',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 146.085774253702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 146.09,
  },
  {
    address: '0x8cf4906d85cd9c61debd85b9fd3c6b838dce18e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.97712220038645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.98,
  },
  {
    address: '0x19cac87bb9aa7715d6e7cf06a85ecadc1c9373d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.92202606865035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.92,
  },
  {
    address: '0x853cd31fc3fcfa6ec1b7b6db600e4896882dead9',
    minters_reward: 0,
    lp_providers_reward: 84.22,
    swapers_reward: 61.69861832418458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.92,
  },
  {
    address: '0x7bc7cffb988771aaa5f442256f78bf50ea07202c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.74986872233234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.75,
  },
  {
    address: '0x714dcf97b9dd4bc74f02aebd762939a97ed35962',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.61080715049786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.61,
  },
  {
    address: '0x5b49444be2a0c78f643c13e8d468cae02b3c79db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.60149387903428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.6,
  },
  {
    address: '0x9b8c064a525f619f5842b40823669d9ff55eaa23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 145.6006841447431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 145.6,
  },
  {
    address: '0x0c5b7ca5f56d2eb4c2cef7fa3da049b626bdd12f',
    minters_reward: 0,
    lp_providers_reward: 144.97,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.97,
  },
  {
    address: '0x5eaba6fde25bf0d7c8d0949ea44cd897a41d5168',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.8769186890335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.88,
  },
  {
    address: '0x442e20934fb21f6df5d1082b49baeaa0e0089857',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.5579468417574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.56,
  },
  {
    address: '0x1c3b615f3eda28816cddcbc0b1a5f7d955ac473e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.22362574513917,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.22,
  },
  {
    address: '0x6e0b018ad27843c290d3b5ddb43c33c3fcbb213c',
    minters_reward: 0,
    lp_providers_reward: 43.15,
    swapers_reward: 101.00392503441529,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.15,
  },
  {
    address: '0x6b06da77af6b5bfc842af969bc278dd3d4675acb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 144.0277616707239,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144.03,
  },
  {
    address: '0x14a008bce417f66e2bc947686a09633fe224a344',
    minters_reward: 0,
    lp_providers_reward: 26.66,
    swapers_reward: 117.34493551262555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 144,
  },
  {
    address: '0xe4808ab083a1680d8645e779e743d775f859988f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.94649427214438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.95,
  },
  {
    address: '0x6e4e73434886bd5fc9cfe5a83793ad4eee0128e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.94649427214438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.95,
  },
  {
    address: '0xd576c0600492e463ec84e7810dc07f1abe0646cf',
    minters_reward: 0,
    lp_providers_reward: 142.84,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.84,
  },
  {
    address: '0xf0049ed5ef978cf3d152ace5abc35a18c231027a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.83080236316468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.83,
  },
  {
    address: '0xa0e3e84077258f41fb8a3fc0a283096b7806abe5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.63790436778126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.64,
  },
  {
    address: '0x011c64f240195c2aea0e5b9e14375def5a6ab0c5',
    minters_reward: 0,
    lp_providers_reward: 142.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.59,
  },
  {
    address: '0xc09eda530bf9f1112a07094574cf3db04ec6bbd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.53741567419564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.54,
  },
  {
    address: '0x7778df52fe8ba70f42901f92333b00ed0a7803c8',
    minters_reward: 0,
    lp_providers_reward: 0.26,
    swapers_reward: 142.23159499345152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.49,
  },
  {
    address: '0xd2f05218e4abd4cacab839fb6b10a845a66f2cec',
    minters_reward: 0,
    lp_providers_reward: 79.97,
    swapers_reward: 62.39376951333629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.36,
  },
  {
    address: '0xa3c3ba59ba5ab7adcc3a43cca7a17f59d5bb7443',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 142.10164796110445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 142.1,
  },
  {
    address: '0xd8384717947af3018f69c1b641c9236b5eb3674e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.87967852773542,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.88,
  },
  {
    address: '0x67769d0ca8ac4c6fec9f0d70bf4a5f54c2a08da1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.6635515163698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.66,
  },
  {
    address: '0x3200e15b73a5ab31f6590f7eb5890aeb118b670c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.53799826884918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.54,
  },
  {
    address: '0x698ebcdb4c98554e92cc5d8b3bb3892820cca090',
    minters_reward: 0,
    lp_providers_reward: 107.42,
    swapers_reward: 34.115927592424065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.54,
  },
  {
    address: '0xf647693fbec963d59d74b8afebde985892fd7fab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.4266325107942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.43,
  },
  {
    address: '0x37588dfad88c5af64cb65dcf0e8a9f0d50ed4457',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.11917552803487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141.12,
  },
  {
    address: '0x3711e796f8d67779b0c44884fc1eebe9039f3fdc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 141.00135095958683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141,
  },
  {
    address: '0x47782f8494c090bf5faf0e79d8b6cc0b8e774352',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.99643111199825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 141,
  },
  {
    address: '0x40586600a136652f6d0a6cc6a62b6bd1bef7ae9a',
    minters_reward: 0,
    lp_providers_reward: 61.27,
    swapers_reward: 79.68806599081073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.96,
  },
  {
    address: '0x237442a803ed17eb5efab439836451a4c265791e',
    minters_reward: 0,
    lp_providers_reward: 140.75,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.75,
  },
  {
    address: '0xc517a33be71d04986bbe8741422a79e00664d55d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.5558522647822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.56,
  },
  {
    address: '0x8448ac5375eb88b24f7d14e3508c2f628b980516',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.45226003120885,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.45,
  },
  {
    address: '0x4e5e224acaaa2f8539458464c967cde6de3ed94e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.2266270634355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.23,
  },
  {
    address: '0x9048c9da2cbf41ea2fca56cd3c45ff811cf11006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 140.11280988602223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 140.11,
  },
  {
    address: '0x9e01b67b83aa360076de9803fd68abd07f95b07f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.9167826606048,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.92,
  },
  {
    address: '0xaf49417a1bff1684497f206e991021fdafa9fed9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.90651004677596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.91,
  },
  {
    address: '0x524e2da1f1c6991601326ccb5c35790ca0ca929e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.90651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.91,
  },
  {
    address: '0xc7a9dab32c27ba9ace11fd61ebe1a73d5fbc577d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.90651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.91,
  },
  {
    address: '0x523169c2f395b61eb5b1d227bfaf7fb003d9ec55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.54236518757685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.54,
  },
  {
    address: '0x14534a7e1f0e1583e45721ba6a3919ee8bed6a96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.52654388217658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.53,
  },
  {
    address: '0x3b3102f25ed3169267d660e4236dbc1000c19980',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.5249262233621,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.52,
  },
  {
    address: '0xb8e0fbab7fba8297e58e62c6a532473e062d3e00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.50786672420273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.51,
  },
  {
    address: '0x0910b60bc62ec8d486948a2137618c8835ee8388',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.41455166613977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.41,
  },
  {
    address: '0x3b79eef50f852eee48f2a0872bface2d30f70d17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0x94d4f65f18c0b4d2f4c8544f1a88219202fcc1e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0xb716f8eb4178ccbc9bf07cd8bd05fedc262ce834',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0x033e38a9ac3de364a8289ada1854ad9bdfd38075',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 139.37685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.38,
  },
  {
    address: '0xd5462786709aacbf9a0721a6be3b87566304a978',
    minters_reward: 0,
    lp_providers_reward: 111.83,
    swapers_reward: 27.41610823406225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.25,
  },
  {
    address: '0xfc6a97490d6bcd9c2cde8e1b41237b060c73347b',
    minters_reward: 0,
    lp_providers_reward: 139.09,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139.09,
  },
  {
    address: '0xaab61046f260f16693b44d293609b7a3241549a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.99655517952186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 139,
  },
  {
    address: '0x59478f7aa5fbccac10845a201be29e4f8790e335',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.91221682289336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.91,
  },
  {
    address: '0x48747f28f1beeb1f13394b623e72532e613fd9e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.37394684531816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.37,
  },
  {
    address: '0x135acafd0d5a68905c3c689e90e8041d87773053',
    minters_reward: 0,
    lp_providers_reward: 82.16,
    swapers_reward: 55.96260401871038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.12,
  },
  {
    address: '0x9369c1dada59ef4d8e66350bd23ca215b98caf19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 138.1000515818232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 138.1,
  },
  {
    address: '0x7546ee77e161ccf57634b8f17ee9b885fcd6b0e9',
    minters_reward: 0,
    lp_providers_reward: 137.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.93,
  },
  {
    address: '0xd250768a3ad3b4534d4d5c0307d0f1501e5fb066',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 137.88588072546634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.89,
  },
  {
    address: '0xe4e22b3c6fcf4584ee6b27abb36b7536b764ad88',
    minters_reward: 0,
    lp_providers_reward: 137.71,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.71,
  },
  {
    address: '0xa2847f9e38236299d7da3f3146b2d728fd60d8cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 137.6152656333093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.62,
  },
  {
    address: '0x309eb98def83dc892f19dddff63c4009abe3efc7',
    minters_reward: 0,
    lp_providers_reward: 123.91,
    swapers_reward: 13.612694852918676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.52,
  },
  {
    address: '0x91a207aff938e9e63de4cd756303d5450e1f4bf4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 137.0741532840851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 137.07,
  },
  {
    address: '0xfdff139b421b8036fd760026f8b820afaf00a0f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.84780334637094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.85,
  },
  {
    address: '0x23f3191dbefd8fa43f4fa3b5e258903c7a87bbe9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.71081954415507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.71,
  },
  {
    address: '0x24cfa7c4154d8411f84f0a0119d1c478ead6d2dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.25692219833945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.26,
  },
  {
    address: '0xeead7c00f872f14980e6934bd9a5c9a408bd9a48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 136.161996356253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 136.16,
  },
  {
    address: '0xff95180a37558c675906e76b67bd735f672c2203',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.79557650271823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.8,
  },
  {
    address: '0xcc751ca90bc6bdbac814d9034cb6a8e8756b0723',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.77620275416143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.78,
  },
  {
    address: '0x10411a5cc506529b84bcbc54926c3858a5338ebf',
    minters_reward: 0,
    lp_providers_reward: 90.91,
    swapers_reward: 44.874758996245085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.78,
  },
  {
    address: '0x94e95bba2a1a16e9ac9eebe56dc5f062dc6c8959',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.4445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.44,
  },
  {
    address: '0x6a607d29e528c26cfb02acec281a7df902707c71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.4445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.44,
  },
  {
    address: '0x421fd272e30597cd708c10c0b71f3df7c5eff94e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.3460407289526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.35,
  },
  {
    address: '0x7e33d90b28cb0e74b65d388e290e4e903e2fd71d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.14315686683952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.14,
  },
  {
    address: '0xe4cadeaa73df0e049d160ecce9c118d660604094',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 135.0351052997869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 135.04,
  },
  {
    address: '0x3168866520430d547ec7796e64dc2869d7e522c9',
    minters_reward: 0,
    lp_providers_reward: 116.55,
    swapers_reward: 18.239567583023206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.79,
  },
  {
    address: '0x57e0a4f12ab9c076df4d5b603cc7b6583579bdab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 134.42137750636562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.42,
  },
  {
    address: '0xaf0e12dbc588a1ae6f1f43baae5249207f9003f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 134.19629135682473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.2,
  },
  {
    address: '0xf77a9562fc7a93645d4d913d26ed4e16d35db4b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 134.1334255498188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 134.13,
  },
  {
    address: '0xea4f7c5a528272c2d878851598168097aa17d5ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.39876489126436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.4,
  },
  {
    address: '0xc52b5609b0d9b4ca3e45d52fc3ed7b6879bfad8d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.30001945860147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.3,
  },
  {
    address: '0x18f9efa9a60a2f526211ec46a37e0eb4d4c0d093',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.26542958742323,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.27,
  },
  {
    address: '0x7d2a21ce0485836e4220d95516ef3712ddeda19b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.19829196822684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.2,
  },
  {
    address: '0xf7824e0fe7fefe8d7fd24b142f24a55c695e1cf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 133.18772672059194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 133.19,
  },
  {
    address: '0x6dbb6785140f231d83d9314afa59d09861868c99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.4931921687683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.49,
  },
  {
    address: '0x411fa7df6243bc50400aa1b0cf8fc556bb9a4dad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.48449043932172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.48,
  },
  {
    address: '0xdf97c609bf4f151573fc57566cabf6d1bcdd015c',
    minters_reward: 0,
    lp_providers_reward: 85.84,
    swapers_reward: 46.55224100678601,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.39,
  },
  {
    address: '0x8969c18fd98017a5097f7999f17aa4a8844f41ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 132.10410981501303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 132.1,
  },
  {
    address: '0xf7068ca96194cb4121a3dea1a92c50bfaf7f6788',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 131.93086874921477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131.93,
  },
  {
    address: '0x539af311938d7ea603769ebef6d9ec5174652fea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 131.7463376348619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131.75,
  },
  {
    address: '0x036f59770acc9e1ee270086a84a85b03b159dccd',
    minters_reward: 0,
    lp_providers_reward: 49.48,
    swapers_reward: 81.59241541723247,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131.07,
  },
  {
    address: '0xed4babc9b5aa9202004fdd779e1747d8b07b6b87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 130.99645313448082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 131,
  },
  {
    address: '0xabefa6e29d218cdb3ff7697d52fa11c156abc47c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 130.9436074618814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 130.94,
  },
  {
    address: '0x5d248a35c29695514baad1be882b7f8e358b8685',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 130.67660468541814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 130.68,
  },
  {
    address: '0x288303ec4028853b01c813366b4b8c2607afa5a4',
    minters_reward: 0,
    lp_providers_reward: 8.94,
    swapers_reward: 121.30645693390984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 130.25,
  },
  {
    address: '0xf86ded8a2ee03b9bc9c0713a59b01948295d539b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.82258549934045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.82,
  },
  {
    address: '0x8156439a29017b20d423a69fc25d4154717acefa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.806045082023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.81,
  },
  {
    address: '0x58e19acadb61e452f469daa8c9d301725c5653b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.76538361731997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.77,
  },
  {
    address: '0xcee750fd3b90bb7d2e494730b9159a8fdd1aa15c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.6423861016358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.64,
  },
  {
    address: '0x0a6fd0a153869ccb6dd16cf3ddf8b50aff94893f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 129.6274478043643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.63,
  },
  {
    address: '0x9e5de869d2981c413595117c43d5447642e7c454',
    minters_reward: 0,
    lp_providers_reward: 73.11,
    swapers_reward: 55.94120445254536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 129.05,
  },
  {
    address: '0x5736982062897d9f4c31b7b20df680aad072c921',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.98711272252942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.99,
  },
  {
    address: '0xe9091817e8bd3317b53035812d31cae1e902f7da',
    minters_reward: 0,
    lp_providers_reward: 105.92,
    swapers_reward: 22.972800395560537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.89,
  },
  {
    address: '0x7e123bcaa92082c3f2e2b40b943431f5a2abe3f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.8025178031862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.8,
  },
  {
    address: '0x46e6d3d520c50942139b895af97eacd6289ed2da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.59591738360805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.6,
  },
  {
    address: '0x54487cb9cd4d486436ea6d0c9edfcac62d973f9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.54371608251617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.54,
  },
  {
    address: '0x6fa48e0836800cc3f304f222082413f0b850bcb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.4238372931825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.42,
  },
  {
    address: '0xc8afe22ca2ec15bf6a06f6a81976e70480b78220',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.36194445878104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.36,
  },
  {
    address: '0xcb024ddb4fa872a83ce9b50ab0f42f0c46099a24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.3289429137877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.33,
  },
  {
    address: '0x039c7aee67a86e2ed79d6f5f8d269a8bbd971b1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.3289429137877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.33,
  },
  {
    address: '0xc103e07f669ba18b1ce8157e0545f08fe1304cad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.3289429137877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.33,
  },
  {
    address: '0xcee961ad444ff252243272aad7d5e03024df232d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 128.01927960300253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 128.02,
  },
  {
    address: '0x750171c533c900ea65fb20f9d4f52b9a8889c09a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 127.50168203327189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.5,
  },
  {
    address: '0x218905aac8f9826c88289f882da2bb7c22dbdc51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 127.33963460838073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.34,
  },
  {
    address: '0x75a95a570ab384ffbe5587f5abd5eb089aeaee40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 127.28565651842703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.29,
  },
  {
    address: '0x601ced301628029d9d045dd1154f92a724753cb6',
    minters_reward: 0,
    lp_providers_reward: 54.01,
    swapers_reward: 73.11770717712172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 127.13,
  },
  {
    address: '0x0a0f9e3c8f55e903db9dfc5e5d1f19ebbb19a68b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 126.91136600658186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 126.91,
  },
  {
    address: '0x0b416906e539407efa36f149e5d494eac251f16f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 126.06631614478906,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 126.07,
  },
  {
    address: '0x73208b829e88d92fbe81b5e3d424d99e6bff0516',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.94547467853351,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.95,
  },
  {
    address: '0x2c579d28826ec23a29ab4b5fdffb0dc9cdfa7015',
    minters_reward: 0,
    lp_providers_reward: 124.34,
    swapers_reward: 1.5882608500211144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.93,
  },
  {
    address: '0x618fb476c7d2a6c4619a5984511d2f1dee37d5f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.73933933882374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.74,
  },
  {
    address: '0x15f7bfcc45df5b46098373d91da362285efd5cb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.73111536479662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.73,
  },
  {
    address: '0xf3ccada6c58a8ed78907281ec4a97bbf2bbde3a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.59657754127056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.6,
  },
  {
    address: '0x4517356e9d01ff88be1bb90288a32e832ef1944f',
    minters_reward: 0,
    lp_providers_reward: 125.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.59,
  },
  {
    address: '0xc3a6f5343c410b821d0121cb9d531953d8a9c278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 125.26085182483766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 125.26,
  },
  {
    address: '0x58c93d10a17ce752d58218918323234acc2ce749',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.91733167292645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.92,
  },
  {
    address: '0xd0201837904a90aa676fd61acdda15830545f58d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.881437139713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.88,
  },
  {
    address: '0x9fd3751938ea8813ee905a5f4b4153608370c473',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.58574499083717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.59,
  },
  {
    address: '0x56f74265e50b4fd9112101569dbf60d30987f79b',
    minters_reward: 0,
    lp_providers_reward: 124.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.56,
  },
  {
    address: '0x0adb4da404b67975294cea62e306ce6ca574dc8b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.53757425047957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.54,
  },
  {
    address: '0x371e7823b64ce4fd82877ee64e46f1608fda4587',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 124.53738776160236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.54,
  },
  {
    address: '0x36f88aea238d4a5222c25df9dbf038c21e345677',
    minters_reward: 0,
    lp_providers_reward: 66.72,
    swapers_reward: 57.818576454305166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 124.54,
  },
  {
    address: '0x76c09468489127d41b818a1a24d5165ad2b6c0d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 123.70876245049419,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.71,
  },
  {
    address: '0x56223243befb086cc661302d61725d07dab62042',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 123.61149955594082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.61,
  },
  {
    address: '0x0f1aeb0c1b85fc9d589fc17effd054a38cbf7ab1',
    minters_reward: 0,
    lp_providers_reward: 63.29,
    swapers_reward: 60.23991933179277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.53,
  },
  {
    address: '0xc1dc28dd1a60a39813b87d8bd21cb72395083ae9',
    minters_reward: 0,
    lp_providers_reward: 123.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 123.33,
  },
  {
    address: '0x4014eaea09810a768501c9536c527764575fc439',
    minters_reward: 0,
    lp_providers_reward: 112.13,
    swapers_reward: 10.656005020627262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.79,
  },
  {
    address: '0xa220460a376aeff72d3f15c9d64105351dc3dc6a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.66594994411955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.67,
  },
  {
    address: '0x1d7b0f2514ebc1b370284637098ba0bd16339c2f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.64798707096517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.65,
  },
  {
    address: '0xe26009a420f21048eed184ff9146d88fde00d0d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.59201879383667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.59,
  },
  {
    address: '0x92652829ee4b95f356904577180f1a11b689d135',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.58683198416912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.59,
  },
  {
    address: '0x19894487ed37b95f69ee2d8a2930260b3fba711a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 122.26364826563974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 122.26,
  },
  {
    address: '0x82d7f4e5a06007fc5464d0d5f51b8c7f59b33391',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.90007772017715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.9,
  },
  {
    address: '0xe171b15facfc69e33575f566213ec172db9ec181',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.57338699986768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.57,
  },
  {
    address: '0xe5afee501ad5371e4a662131f7df2ce85ff061f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.54744654356554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.55,
  },
  {
    address: '0xc6967f0c428d369495f274408bee3b4f141dcae1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.51562869920186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.52,
  },
  {
    address: '0xb3841125055757ae716b764837d2daa681ee87cd',
    minters_reward: 0,
    lp_providers_reward: 18.62,
    swapers_reward: 102.7858720925613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.41,
  },
  {
    address: '0x88ef0e143e6fbcef2952d321b1532c9395303be5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.28681100818113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.29,
  },
  {
    address: '0x0777449aec166f1f9b5858fab979b7f8de6edee7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 121.22422556525656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 121.22,
  },
  {
    address: '0xf7faff5c982ce9049bd13f3a82fe2aa429ed45df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.97367281435669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.97,
  },
  {
    address: '0xe3494070bac83b259d5f93982077edbd12a4f026',
    minters_reward: 0,
    lp_providers_reward: 49.42,
    swapers_reward: 71.43841839473181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.86,
  },
  {
    address: '0x5d849610482153c7139356c3787bc2b7347bacb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.76689144475735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.77,
  },
  {
    address: '0xc74f46e2dbdec674ad71692c53522663a50696ca',
    minters_reward: 0,
    lp_providers_reward: 94.81,
    swapers_reward: 25.84280979290873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.65,
  },
  {
    address: '0x9e359eda89a78488238c5379234155d16cf2dc0c',
    minters_reward: 0,
    lp_providers_reward: 120.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.59,
  },
  {
    address: '0xf01fb6021e8c8f2e7a4e33cf1fbb05d1be6e5fe8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.4604635346464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.46,
  },
  {
    address: '0x5774b76e9442815474f7345cc9ca080f1e275e00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 120.1849395660355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 120.18,
  },
  {
    address: '0xf5cfa7b8b8c4e69df3f656569cb03491f3298a42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.912278690051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.91,
  },
  {
    address: '0x53aa71c6f8c78bd0c15ff6cf8694dc8d47c5a819',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.8635942036561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.86,
  },
  {
    address: '0xed4d5412a1e8a565da5dc53712c6ad27c0107402',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.57200522581036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.57,
  },
  {
    address: '0xe6ca7a11e768e05d499182ff9d0696aa08e89a45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 119.3951093697636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 119.4,
  },
  {
    address: '0x7a52259761a27c3b190e95d82b5bab8406a79b35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.96737497209499,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.97,
  },
  {
    address: '0xc805fa6561ecfa676137953aea92b660307e93c3',
    minters_reward: 0,
    lp_providers_reward: 111.34,
    swapers_reward: 7.590545614534436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.93,
  },
  {
    address: '0xc9250751f45b7e8020199aa2eeda7f91ca614bad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.52283252532801,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.52,
  },
  {
    address: '0xfd424df23832fcb9b5e14680882c441706d0432a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.39315652657125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.39,
  },
  {
    address: '0xffeab7cf9bcb2867248c9f2d75531dc9ae147f55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.16576232533023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.17,
  },
  {
    address: '0xa17237a12495fc04defcdd3fc6b922dd07095ce7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 118.11743755741213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 118.12,
  },
  {
    address: '0xaf1d45f93cd5962e21d764631e7fdbb740dfae1c',
    minters_reward: 0,
    lp_providers_reward: 110.68,
    swapers_reward: 7.147324713607218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.83,
  },
  {
    address: '0xee5f2e9970543ca38ae565db60d302e0e0f0628e',
    minters_reward: 0,
    lp_providers_reward: 38.89,
    swapers_reward: 78.89320088690569,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.78,
  },
  {
    address: '0x58af59f0cef8eeea8b1e53fc73398d4bcb72aaec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.56122020228696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.56,
  },
  {
    address: '0x7d00fbd3a266117428f6fce6d268cb08693175a9',
    minters_reward: 0,
    lp_providers_reward: 117.53,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.53,
  },
  {
    address: '0x91d72d3b45a24963276264725b9786a28f032d41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.43182378777881,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.43,
  },
  {
    address: '0xba0d6a00a79a3f80d5b3628d7e19d188fcfef0ee',
    minters_reward: 0,
    lp_providers_reward: 117.4,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.4,
  },
  {
    address: '0x21697e02eb60d186ef347c112d106071851ac0c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.32290377820091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.32,
  },
  {
    address: '0xedde0e6d9b9da150fb23336d678efaeec9c7b11c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 117.25746263416995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.26,
  },
  {
    address: '0x6820dad7041fb3b51166289b6ecf4b4b08d5f1ff',
    minters_reward: 0,
    lp_providers_reward: 87.34,
    swapers_reward: 29.850989081905787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 117.19,
  },
  {
    address: '0xaa18c6487447488959f93a6d91b5b41d5d882fe8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.94945061125905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.95,
  },
  {
    address: '0x8f203daa193b261dd364c7ae0e09e5f94d588ae3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.7190110206303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.72,
  },
  {
    address: '0x7fb2b0bda60f03c9437a848e348335b9975b02cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.22715463518354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.23,
  },
  {
    address: '0x940707533b82fb289c70057363453c8051138ba5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.17655623920427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.18,
  },
  {
    address: '0xbe0d81990422d1d9d7bc077b19c04cde725d4b51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 116.1764201500694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 116.18,
  },
  {
    address: '0x022381dd65cb5fbabc68318fca4c8111aa54a343',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.93627264594576,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.94,
  },
  {
    address: '0x531cdf82e7a2826259d8c8f95429c07764e33ade',
    minters_reward: 0,
    lp_providers_reward: 2.29,
    swapers_reward: 113.55281427184903,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.84,
  },
  {
    address: '0x530c4d2e3d9eaab672f918384a093c314d914f29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.80982157584234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.81,
  },
  {
    address: '0xe65db3cfd28216454fdbb9cd3c17440ef2eafde8',
    minters_reward: 0,
    lp_providers_reward: 100.24,
    swapers_reward: 15.51013441661918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.75,
  },
  {
    address: '0x849e346010af41cb483508fb21a76f2a631ee2c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.4485108532436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.45,
  },
  {
    address: '0x7bc208a1389aae134944d9d2f1f8e0cae453d3b8',
    minters_reward: 0,
    lp_providers_reward: 115.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.42,
  },
  {
    address: '0x943fb222612ecc3fc10ae56b02c7345b00df302a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.38829056377854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.39,
  },
  {
    address: '0x53c12f0ab159f512d0a9b1552c92822dec73567a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.17259197579006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.17,
  },
  {
    address: '0x4037ffb93132abee2fc0241a8b358449fe19acd9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.13298190562062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.13,
  },
  {
    address: '0x3f5caccf3b389af56bc2388274480f925feedb23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 115.07969493687607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 115.08,
  },
  {
    address: '0x3e35a3716026f404ca6be496737cf388885f0c3b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.98590197014089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.99,
  },
  {
    address: '0xcffe6865f30dd90d58439751929605d6fa979698',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.91329986514488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.91,
  },
  {
    address: '0x204c722b8398a161923b053f588650352082e396',
    minters_reward: 0,
    lp_providers_reward: 113.92,
    swapers_reward: 0.8739543133228767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.79,
  },
  {
    address: '0x629cc93a55cd0ad6d038bb32be030c4de7e5bc47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.78475694938153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.78,
  },
  {
    address: '0x84a69e5569f0897b4bad2cb8b238ab61ba82ba20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.50393110954218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.5,
  },
  {
    address: '0x88a6de573ea781fe41b8dfd7503708dabc354918',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.46042370978094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.46,
  },
  {
    address: '0xd6430009c8663484cefd1a1a187b4f4c8a3ed9a4',
    minters_reward: 0,
    lp_providers_reward: 60.63,
    swapers_reward: 53.724243591871705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.35,
  },
  {
    address: '0x9d49b4caa39386aaac060f971e10ad784b4c7e7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.29115758604222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.29,
  },
  {
    address: '0x9fa37c9457ffdbd85afe0b5648b37f560db47a29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.10442698776758,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.1,
  },
  {
    address: '0x6a3b578231fbfca381a729d51c37ba4d080dd715',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 114.0475852118716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 114.05,
  },
  {
    address: '0x85b05f4d30a87a49a5bb4b54da1dd4875f51f01f',
    minters_reward: 0,
    lp_providers_reward: 58.53,
    swapers_reward: 55.42523891339929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.96,
  },
  {
    address: '0x48cc6dc464d0ad78fcd9aa5d1ab49bc34e93c9d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.8213324762103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.82,
  },
  {
    address: '0x6d31eb700b634cc5138cfcc4d098517d693a8123',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.74026652512697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.74,
  },
  {
    address: '0xa183e271d887faf472e97782555cbc4d17e16469',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 113.30795410568034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.31,
  },
  {
    address: '0x5d43e0f30cd994c83986a7a264b06605b0b8f479',
    minters_reward: 0,
    lp_providers_reward: 82.97,
    swapers_reward: 30.282562703538336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.25,
  },
  {
    address: '0xd8fd8b4599a99962ad7347100f949bddcde0c8c5',
    minters_reward: 0,
    lp_providers_reward: 104.13,
    swapers_reward: 9.110388469644445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.24,
  },
  {
    address: '0x30a6a57be0dce02ce3000c16deb30dfc5d01c8ee',
    minters_reward: 0,
    lp_providers_reward: 11.65,
    swapers_reward: 101.54945874897857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 113.2,
  },
  {
    address: '0xeeea011e7a0ac8a2d3b0e184c616ec471f252ed2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.94217001817181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.94,
  },
  {
    address: '0xb8c348584bdd31269e8beb949cc6e4f7e0624485',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.62926569689701,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.63,
  },
  {
    address: '0xe4e281bbb0702643ddf8e97d3eaacaa0ccaedbc3',
    minters_reward: 0,
    lp_providers_reward: 112.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.62,
  },
  {
    address: '0x048ba2c1f213ac3a87ab75e0b44492e1ba502630',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.4295581656799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.43,
  },
  {
    address: '0x11cec3f0441066188d1b41b46abf51e516bd389e',
    minters_reward: 0,
    lp_providers_reward: 79.44,
    swapers_reward: 32.92241186722149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.36,
  },
  {
    address: '0x2df19c28c93c18bc2d355088ae8aa6912d5e6d9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.32258903281091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.32,
  },
  {
    address: '0x0609d2f5e90d7ce69ba7a6e749538af0107d07b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.30383468608403,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.3,
  },
  {
    address: '0xd2a9f5eedb2dc70d3ea8a08a40435a58e26202d2',
    minters_reward: 0,
    lp_providers_reward: 112.16,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.16,
  },
  {
    address: '0x66e7e178abb465b59569e3f8a9d4491435feb71c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 112.123700726251,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 112.12,
  },
  {
    address: '0xa35d2624696c311f6c4cb7dcecfcb30eb7f0980d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.96981824773438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.97,
  },
  {
    address: '0x4a6ee18ecfa209481b6cf78860e869b5c2c32c3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.50148069831845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.5,
  },
  {
    address: '0x5ba638ae90c16426b021b4b62cd2e539944d5bd8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.50148069831845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.5,
  },
  {
    address: '0x57fc4a71c47a717dad63bafe4158dc25d1829f91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.47027847287532,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.47,
  },
  {
    address: '0xc1009986e256e6b4df2f3ca90e0601a15da47eb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.40144677680496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.4,
  },
  {
    address: '0x49b338b4305960d88fd5380b67d621a5cc3e4084',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 111.07621290211898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 111.08,
  },
  {
    address: '0x231b8b1f054b695a76c8172acbb145d382ae0963',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 110.83309581724717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 110.83,
  },
  {
    address: '0x8c518c5df29275415e0d62fb596c9ebf6aec24df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 110.69965765605441,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 110.7,
  },
  {
    address: '0x84779ce7e8882a4752aac7f4e6680cc5fd439be2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 110.4755046578933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 110.48,
  },
  {
    address: '0xa6aba66039000adc1783f8aab76cac78bb1ff341',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.90542162794526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.91,
  },
  {
    address: '0x6f5c1c80b2bdc3d1276665adefa7b829b02455e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.82155877275358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.82,
  },
  {
    address: '0xb6fea6a9ff8696ba017fa968baf6e9f9514705e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.35989713833877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.36,
  },
  {
    address: '0x08ca6f5a6f4fd7ab4bd6bb754441e90af3d12eff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 109.22696983421531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 109.23,
  },
  {
    address: '0x53ac078327f74502f30160615732d11997248b32',
    minters_reward: 0,
    lp_providers_reward: 66.26,
    swapers_reward: 42.485687464286116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.75,
  },
  {
    address: '0x1b1e028903abeb867eff7b5dbdd5892f50395ecb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.41841604028662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.42,
  },
  {
    address: '0xc843893f86a904f8198ab97a7956771282b45c03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.38079021656016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.38,
  },
  {
    address: '0x87301f0f7de054bb22d9788941b889b9751401c6',
    minters_reward: 0,
    lp_providers_reward: 1.03,
    swapers_reward: 107.33929458014583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.37,
  },
  {
    address: '0xb1a76510a9a229ced8c10c1517be3aebd6b90992',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.33278705527319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.33,
  },
  {
    address: '0x928c9536e4c537414bc754f453ec346f07d84347',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.1785090495711,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.18,
  },
  {
    address: '0x52907d29f24a026c1eec5b5ca6981ed89981a23b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 108.07010617859913,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 108.07,
  },
  {
    address: '0x1fa8afa13afff3a539618a7f526c95db82543499',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.96029046436736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.96,
  },
  {
    address: '0xc4aa508040c4970ca4317b23eb952992fbc3e8c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.91457273390171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.91,
  },
  {
    address: '0xf655b7f777c2196bfbf2c34f4e65daa4e3505990',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.67703844328425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.68,
  },
  {
    address: '0x4aa545a0108110dee6469d4ac1d9f9d523a86d28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.57927993200852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.58,
  },
  {
    address: '0xdbdd372aa9386dde2ae9c3b1240b95caff778880',
    minters_reward: 0,
    lp_providers_reward: 92.02,
    swapers_reward: 15.458625841046524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.48,
  },
  {
    address: '0x49d372ca71b90eb1bc326a9f1a369ba29686a6df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.27011132530626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.27,
  },
  {
    address: '0x77ed0d508fdf1044c6b679201af899e9d853dad8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 107.23458533153575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 107.23,
  },
  {
    address: '0x2d0d08360a8bf358f16f7b75bde834cdbbd74154',
    minters_reward: 0,
    lp_providers_reward: 106.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.92,
  },
  {
    address: '0x76aa89a959e760f8332c347de4d846e12e9ad18b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.81846201547009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.82,
  },
  {
    address: '0x88737fc61a7a48868589f8ede2f9c14dedb28303',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.55420049389846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.55,
  },
  {
    address: '0xc00f158a987593e0e2de2ff1e773b42af0f35d44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.4508666121147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.45,
  },
  {
    address: '0x8fa68712e4ca3dca3fcf1213b1892c2e35364a9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.4213499169198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.42,
  },
  {
    address: '0x093ae2e4f1b03e61dc61a1014878cdbe0589a9d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.19947788937515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.2,
  },
  {
    address: '0x23e31624d86c25247623fd35a0c158a8334ee741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 106.13208318286522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 106.13,
  },
  {
    address: '0x7bb8e87cae5238a2dfa86edf4ae76691a56d9b4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.86951914122248,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.87,
  },
  {
    address: '0x110bbfb43e5c5d6dc8e84202186631f69c0a5c89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.82043340306339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.82,
  },
  {
    address: '0xf32d4d8e8b73c9dc16a7ba2f61196fb893f8f580',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.8122309871493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.81,
  },
  {
    address: '0x0862d5a5eb4fd5796305d56b562fbf6b9f94ab64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.74648546913369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.75,
  },
  {
    address: '0xe2684fdffb2dbc24cc8a1f179243f70698e61934',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.74648546913369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.75,
  },
  {
    address: '0x762bb81ac7bd349e87a18efbf926e15cec5c4ed5',
    minters_reward: 0,
    lp_providers_reward: 8.54,
    swapers_reward: 97.18054717393191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.72,
  },
  {
    address: '0x053f6462645cf23437dcc30926cc4b7c62f143d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.61033635777278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.61,
  },
  {
    address: '0x46d7839384d14cc66b10df4fe578f9521e13b32c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.45407822000065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.45,
  },
  {
    address: '0x2be059cfdf5c6577f4f16b371711b1cefdbd78f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.39066339816421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.39,
  },
  {
    address: '0x8e0fc335448760205e96337a8785c2bf8c692adf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.3634729441231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.36,
  },
  {
    address: '0x9a931f1c39f5c823facf54bed3b6e0593d1408a1',
    minters_reward: 0,
    lp_providers_reward: 105.25,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.25,
  },
  {
    address: '0x3254cc0a0e9e9a064938a1528b8637321323e62f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 105.17921106801867,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.18,
  },
  {
    address: '0x139bd6add2ddb4f78e022db4b523cf3a66acd539',
    minters_reward: 0,
    lp_providers_reward: 105.09,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 105.09,
  },
  {
    address: '0x47c2f23f53a55735684ccb7106f605098afee4b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.90211791422107,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.9,
  },
  {
    address: '0x77727cdc38421ea298348b584ee2625c56a424da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.81821272306733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.82,
  },
  {
    address: '0x64392f8f436343a1c505c29b1e31822db9095dde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.77182132973054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.77,
  },
  {
    address: '0x08d8226d65ad2c6972ada9b181a1bd1ab399e61a',
    minters_reward: 0,
    lp_providers_reward: 103.08,
    swapers_reward: 1.5521293886645402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.63,
  },
  {
    address: '0x4c9914f5a23d1537c21959445fe4e57313b71498',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.57633848397253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.58,
  },
  {
    address: '0x35dbc758ece4a1f8468a7ffa45ccb5e7c59df4e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.26255507706134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.26,
  },
  {
    address: '0x97c9477ca4dbc8ad1f8f9b617b7a699e400b036b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 104.22852717712219,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 104.23,
  },
  {
    address: '0x7f187a248412dd44c5584ff02fbbfdac0ea907b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.94758524394886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.95,
  },
  {
    address: '0xc447f2e09e245849feffe66eaeccbdf809db61f9',
    minters_reward: 0,
    lp_providers_reward: 58.46,
    swapers_reward: 45.43438890144428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.89,
  },
  {
    address: '0xa47a1d4515212504ce236dcee128da8f6f88264a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.47535419900781,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.48,
  },
  {
    address: '0xbd422efbd2e2296f32659e5a94cdc8697aac8c23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.31555157750154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.32,
  },
  {
    address: '0xb0b734cfc8c50f2742449b3946b2f0ed03e96d77',
    minters_reward: 0,
    lp_providers_reward: 103.3,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.3,
  },
  {
    address: '0x8ce2adb1c3f9d9959c9de4d1b495d9368a49fece',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.28774648953004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.29,
  },
  {
    address: '0xf98f0ee190d9f2e6531e226933f1e47a2890cbda',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 103.09548560336292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 103.1,
  },
  {
    address: '0xae69c49f1ef13fcd1d68d03a135d3f4d383ce96f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.95808290943044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.96,
  },
  {
    address: '0x9ca44c0aa1eca0887c7d5309f6339aafa0a84a6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.82483605270596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.82,
  },
  {
    address: '0xce77f5dfffc6903e36b0f675e0bcfce76c1409cb',
    minters_reward: 0,
    lp_providers_reward: 48.12,
    swapers_reward: 54.51971749719988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.64,
  },
  {
    address: '0x35a525896bdc864ab9992cb56e5f4e45c0f5fdb1',
    minters_reward: 0,
    lp_providers_reward: 70.76,
    swapers_reward: 31.630882443731096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.39,
  },
  {
    address: '0x93d418348439b24edf78c8242c1de8e2de88e204',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.30824100068358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.31,
  },
  {
    address: '0x4e633198a945eccf846e16aa498c19f6f75e097f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.29712844751286,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.3,
  },
  {
    address: '0x623ba219ff26d356fee00bcc6796dff4edbc51f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.22277855643443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.22,
  },
  {
    address: '0x659d1489409dc95346247d5e1c1399292df15fa1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.07597081791033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.08,
  },
  {
    address: '0xc51c91ec328f3072b415f73fa37574357d389c76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 102.02853473442443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102.03,
  },
  {
    address: '0x7b2e479a47430c6f484e1fd3577c87f218e010b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.99750249610092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 102,
  },
  {
    address: '0x71cdcc05e02d3fd35b2358c819a05acddba19175',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.84756701876007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.85,
  },
  {
    address: '0x5f256ea51a68124fb6033f60ff3b1688b5a648f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.82188207642288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.82,
  },
  {
    address: '0x82de5525b3850e325f44edf45400700d031f3cea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.61505054303183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.62,
  },
  {
    address: '0x9c2437fcfd14ce113f72e8e954adcb3c7d9d1f2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.49201093322252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.49,
  },
  {
    address: '0xf00ce413e2efc1afea4da4ae73e2c5e4830f5fe0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.4504148716734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.45,
  },
  {
    address: '0x6ea3c78e0865415b85c84a40e6b37551f77af1c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.33117250027938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.33,
  },
  {
    address: '0x240052192e4296811b168805c3daec7736dde1af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 101.29184629129735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.29,
  },
  {
    address: '0xa325acdffb754a0915cfa6bf9135e44bc1a33bbd',
    minters_reward: 0,
    lp_providers_reward: 101.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.19,
  },
  {
    address: '0xbdd69e2de91aa45bdda6dcd9044a09d428f56b83',
    minters_reward: 0,
    lp_providers_reward: 97.88,
    swapers_reward: 3.3027872191389434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 101.18,
  },
  {
    address: '0x73bb1d3cd992728bdeeab7d9581f5d353cc53942',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.94698390804672,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.95,
  },
  {
    address: '0xfc1038205db71bba8b9e531e592e849ec2e23914',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.94043621806972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.94,
  },
  {
    address: '0x5db2445c0032fed739e0b616ade2ec2fcfb53abf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.84661807925019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.85,
  },
  {
    address: '0xdf4206c8ec5870f5994e409fbe87d9456efbd93f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.80659541400262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.81,
  },
  {
    address: '0x11cf604e5d827eafe2850e10494ad8bbbfca5365',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.7274002330034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.73,
  },
  {
    address: '0x70a176536100f6f4b7987f3837524af84ce05fdd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.50797683762629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.51,
  },
  {
    address: '0x157d2947657a819fb6399d689d4473892d26717f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.47353591237409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.47,
  },
  {
    address: '0x213f4b5357654ab3b00287fe8cd50d2fc4caeeb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.28850380928883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.29,
  },
  {
    address: '0x194e9b6510bd5cc452231be20ef887996709610a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.2711509751956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.27,
  },
  {
    address: '0x873f9f645ff4fdd87ca52adcc42f5fb4fa3f05ef',
    minters_reward: 0,
    lp_providers_reward: 83.45,
    swapers_reward: 16.77273692228218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.22,
  },
  {
    address: '0x1bb6b61d4b7c01ee9bf59340d0a4d38672678967',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 100.06063789836075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 100.06,
  },
  {
    address: '0x1a7bb2d234f96628b614e29ab4e57fcb4196edb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.97503559421611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.98,
  },
  {
    address: '0xbe0bbdc0ac90c18670dca058a8fe93fccc31b1c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.94965819443635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.95,
  },
  {
    address: '0x755c5b9e8e9487d5fa7e322f52fd641977e9968d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.88762415466898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.89,
  },
  {
    address: '0x8cbf40461d57960bdfb8f536c4f186a53c603d9b',
    minters_reward: 0,
    lp_providers_reward: 58.5,
    swapers_reward: 41.3285247623859,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.83,
  },
  {
    address: '0xe52a22bf7768e34253726de0ed15065c6845debd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.71058805410513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.71,
  },
  {
    address: '0x73fcbecd01912e7f2ab0a708e58b2c059a0f9d90',
    minters_reward: 0,
    lp_providers_reward: 63.04,
    swapers_reward: 36.60805756161349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.65,
  },
  {
    address: '0x7a0a20ed593a6e2dd8e3bf05ff82b6fa1dff1fd0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 99.20434957295069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 99.2,
  },
  {
    address: '0x1ae71c93756fd2eacf7b35e9055dc5a67f78b323',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.74778618424149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.75,
  },
  {
    address: '0xddaf9d250db0a94ca0e186f8c985eb1ed169011b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.62030843035046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.62,
  },
  {
    address: '0x4acba9bfcabb79a7c6cd56175c09f397be24206b',
    minters_reward: 0,
    lp_providers_reward: 98.33,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.33,
  },
  {
    address: '0x635ddc18e0591f5856546529acc02be8a12fe47f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.27700078100635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.28,
  },
  {
    address: '0x9afd3311165b389be5113f89778493cc25a81dff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 98.17220223979659,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.17,
  },
  {
    address: '0xb64bb996f124d34c84f1ba60c04d4bf81c043e16',
    minters_reward: 0,
    lp_providers_reward: 98.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 98.13,
  },
  {
    address: '0xe7ec8823357207165d3d1687ba617fe41487ab0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.76631061630803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.77,
  },
  {
    address: '0xb1d51c71d88b3b9329e07d970d99fd464117cb3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.75840145678409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.76,
  },
  {
    address: '0x99fd9eb075f582b9e4eb519ec1be971e88575b80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.5983207220426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.6,
  },
  {
    address: '0x5d40f1a1895faba5bc34ac181aa90251c34d1405',
    minters_reward: 0,
    lp_providers_reward: 82.89,
    swapers_reward: 14.666307731836582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.56,
  },
  {
    address: '0x9a8a7614f4d47e181a3be6df5e66a81c25f231a9',
    minters_reward: 0,
    lp_providers_reward: 97.55,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.55,
  },
  {
    address: '0x91573c2770f50fb33c3c174c489f555c44910c5e',
    minters_reward: 0,
    lp_providers_reward: 97.18,
    swapers_reward: 0.2586507124458794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.44,
  },
  {
    address: '0x2722b249ca5fbb1eb0a031edae9a7fe86febf01c',
    minters_reward: 0,
    lp_providers_reward: 9.84,
    swapers_reward: 87.38920578939978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.23,
  },
  {
    address: '0x73c1ff8781b5728897e550eb004a0d2d34f8d6d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 97.16509574445496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 97.17,
  },
  {
    address: '0xa7ec44cf8a82772c54618c178b55def62dc20a69',
    minters_reward: 0,
    lp_providers_reward: 96.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.99,
  },
  {
    address: '0xecd0eb8ee1afdc5eea31238debbb457a26e6d7b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 96.9053910760183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.91,
  },
  {
    address: '0x9a57cb830176a4e9a69f87127d09cf5ed0ba6db8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 96.59689729009696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.6,
  },
  {
    address: '0x2dc617f2edcf3aee85904de2a4569e9039da80c8',
    minters_reward: 0,
    lp_providers_reward: 4.49,
    swapers_reward: 91.98840000778166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.48,
  },
  {
    address: '0xc601584978b7c22b3bee4eacd1ac2dffc51382e0',
    minters_reward: 0,
    lp_providers_reward: 21.67,
    swapers_reward: 74.76140593575448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.43,
  },
  {
    address: '0x2771efa07e50b5ae9edcb58bee7316da981510c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 96.32812473116569,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.33,
  },
  {
    address: '0xa5a8c0fb716a4ffccf4fa920782e4d2547acd4b6',
    minters_reward: 0,
    lp_providers_reward: 41.58,
    swapers_reward: 54.713576925888965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 96.29,
  },
  {
    address: '0x2619b96310dee7b52e26b7e06bd47ee758024a90',
    minters_reward: 0,
    lp_providers_reward: 20.16,
    swapers_reward: 75.75515459370004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.92,
  },
  {
    address: '0x4fbddd86cbf8303d1f7e43955f49fb47f2c4ca94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.86136550650474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.86,
  },
  {
    address: '0x9d24753a4472c11cbc9f23407a44f90d3a84c961',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.82251967436851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.82,
  },
  {
    address: '0x2f7f2c551625dae4d0e9e09d0603f4129854843b',
    minters_reward: 0,
    lp_providers_reward: 28.12,
    swapers_reward: 67.6743337758135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.79,
  },
  {
    address: '0x2a6e929ebaf2a44adfb369453c29d9e0d02dffd8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.69910418925497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.7,
  },
  {
    address: '0x704ba16ccb74677d71332cb59bdf0280da54708f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 95.2471927585975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 95.25,
  },
  {
    address: '0x61040bb701e0426ae0cede39e6c5226d673b8f2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.90067111941897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.9,
  },
  {
    address: '0x4646c1a0bcc00f6c288fbccd864f7a8712fe3e80',
    minters_reward: 0,
    lp_providers_reward: 26.46,
    swapers_reward: 68.3417132701682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.8,
  },
  {
    address: '0xb2886bd691c9a10c4bb578b9a3b1205da71e2cbf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.72129991798445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.72,
  },
  {
    address: '0xd32a58c68cad6dda6078bbd694aa4cf720978978',
    minters_reward: 0,
    lp_providers_reward: 19.83,
    swapers_reward: 74.84749015385437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.68,
  },
  {
    address: '0xe60a4ba82bcc58a1b7a12ec5f472707f988e3b03',
    minters_reward: 0,
    lp_providers_reward: 94.64,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.64,
  },
  {
    address: '0x37c03cf03ba772306fefdfdd5281f63cc5a101e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.48237743592122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.48,
  },
  {
    address: '0x5a7ce6f3ebeae6d329613baf126384f2554e571c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.37163828401354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.37,
  },
  {
    address: '0x7508e979117fd186174d428bba5c2df3473e5532',
    minters_reward: 0,
    lp_providers_reward: 81.8,
    swapers_reward: 12.568054405192964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.37,
  },
  {
    address: '0x9fbb9df14ab913ddb3a49cf23a4c1992ba799abb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.31890762309435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.32,
  },
  {
    address: '0x19af42eb1735e6a93dd567bbb548e8e318751ace',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.1323413302535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.13,
  },
  {
    address: '0x651c0693bb36bc81d5152792e976e6c9c89ba091',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 94.12499472682755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 94.12,
  },
  {
    address: '0x74ccca4334b4bbd6582bfdde01f3843ad9888098',
    minters_reward: 0,
    lp_providers_reward: 93.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.96,
  },
  {
    address: '0x8390e281fb69b16e3c767556b6e419b376d5316c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.9281126978284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.93,
  },
  {
    address: '0x1fdb9cbbff84b732c7476f1076e7f768c5def83c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.8764236778458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.88,
  },
  {
    address: '0xd9db4046d0f1deae1485fc11de6ffaaa6208977a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.48714446670311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.49,
  },
  {
    address: '0x2348eac507ea4a175e7ab25d7a3cc6b53720e691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.47377028197528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.47,
  },
  {
    address: '0x1c31c3f5c17e5e17c59ba8536d944ce8353e9d05',
    minters_reward: 0,
    lp_providers_reward: 8.36,
    swapers_reward: 85.0221941670698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.38,
  },
  {
    address: '0x34eb6947f57389239c1337949ea33b0906cecbe1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.36894145432905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.37,
  },
  {
    address: '0x0b223c421caefe73812893f387d308eb7eb0b8ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.22742054834029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93.23,
  },
  {
    address: '0xfacec1fb75b65aab04d6a7fb8ab50790a8370ea2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 93.00467388989391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 93,
  },
  {
    address: '0x45de7c6c1f8263b48693fb6f4325c704bc1ded49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.95115066686515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.95,
  },
  {
    address: '0x6ea667ac3e7b84416741a14d1435fee5a60d8fae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.68350769780024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.68,
  },
  {
    address: '0x0ff4c489f379218f6a49788dda641cf33deb71f6',
    minters_reward: 0,
    lp_providers_reward: 80.62,
    swapers_reward: 12.052982626240288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.67,
  },
  {
    address: '0x0d84d6dbb26bd12760aa2e849f0da259fe1f2ff0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.61722152334453,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.62,
  },
  {
    address: '0xe99c2323953a25fce68c522a613f254d485c537d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.3999896785243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.4,
  },
  {
    address: '0x9b78d534cf7909422be0d3d893d7f75e2d36d58d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 92.16976344287374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 92.17,
  },
  {
    address: '0xeb93ec49db0eb825566460ba2a0ecdd160efc652',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.93210508867165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.93,
  },
  {
    address: '0x4d3fcd28dbd3daa51c89757b46bf12b3a0291a16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.75978010952437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.76,
  },
  {
    address: '0x35cde99feeed2c0a7c0cdb108b00b032e525cef5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.63680774380269,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.64,
  },
  {
    address: '0x17761f02157bae2662ac48530115805d83f87d83',
    minters_reward: 0,
    lp_providers_reward: 91.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.58,
  },
  {
    address: '0x9e3b62badbd8a699c27409627007c9fab0af71a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.46449484753671,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.46,
  },
  {
    address: '0xfee8f0ad71b37244a1f1ff10b0a5b134fe3611bf',
    minters_reward: 0,
    lp_providers_reward: 7.15,
    swapers_reward: 84.21104132689436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.36,
  },
  {
    address: '0x2fba18fc091bfa821d6c75c69a75de4c3b7af8fa',
    minters_reward: 0,
    lp_providers_reward: 78.49,
    swapers_reward: 12.750195370845992,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.24,
  },
  {
    address: '0x3bbc7dd3b57ea725985bf0da33adde731f4c7295',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 91.11827319221378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 91.12,
  },
  {
    address: '0x134fbd1249e0515f175046dc1d32f6f9a9c8311a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.73405418770355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.73,
  },
  {
    address: '0x989f2bdacec665d696687c023a170153fd23b77f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.54118063239325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.54,
  },
  {
    address: '0x70d3459d5affb269e4dac084e8866cc86d201cfd',
    minters_reward: 0,
    lp_providers_reward: 90.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.46,
  },
  {
    address: '0xfc082e33ef655fede2ea4dc341cb950f6b7f3e1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.4456682215605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.45,
  },
  {
    address: '0x32557753c18ab6cfc7f991fb327f7bb0a70fbc9e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.31387917781122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.31,
  },
  {
    address: '0x5cdc503966f6863b8bc4798265239c2b64557d4c',
    minters_reward: 0,
    lp_providers_reward: 64.27,
    swapers_reward: 25.98222397534441,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.25,
  },
  {
    address: '0xf3e427a1e67d87d63552d6123c53d812d7e931d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.16356246909093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.16,
  },
  {
    address: '0x1f427546b7af7e71099d8292de7eaef414d1e743',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.12663157038142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.13,
  },
  {
    address: '0x71e0b4ef67145e78892f42be14617bb5a8d50ae7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.0347104636487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.03,
  },
  {
    address: '0xf31e8b7c8a5a219b9057295fa0db71802ade22da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 90.02982656720032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 90.03,
  },
  {
    address: '0x50f588eee5f466eea0b70ba47d60dd9b49cfcd78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.88729101340175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.89,
  },
  {
    address: '0xc31bbea1306004f99c4284f47acf5cdeeab5ca84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.84350847828242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.84,
  },
  {
    address: '0x6de001c7f010caaed07d7f683d288d68e1806d06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.7552262231908,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.76,
  },
  {
    address: '0xb74d8d9c33e6aca894cfdf7582376acf91c9b389',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.54248051285826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.54,
  },
  {
    address: '0x84767ad8fa20cd8b07f7b3264e3323bcab774b6d',
    minters_reward: 0,
    lp_providers_reward: 79.88,
    swapers_reward: 9.532090130553039,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.41,
  },
  {
    address: '0xd363807b84697aa60d060aac90239701caa8d3d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.33846419248808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.34,
  },
  {
    address: '0x54aca9dad8057234f6ad28b5a2ed7c99f0d5063f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 89.14133103461742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 89.14,
  },
  {
    address: '0x0cca837d6892b36cda7afcc12e54d9597825415f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.64283562015004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.64,
  },
  {
    address: '0x806372b31850d9181e4a73f617403e6fd3222f74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.54767618532428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.55,
  },
  {
    address: '0x3696771337598cc13b4c5ff77b0709eab968f5ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.41645400006458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.42,
  },
  {
    address: '0x5445da1d4d563371998d087181f0f56ac63131e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.31051795131414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.31,
  },
  {
    address: '0xc4c83ee804ebf9ac53bac3d46613a9b273077827',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.25453179020491,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.25,
  },
  {
    address: '0x08363609f2370936691dbf14833d2b054608be39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.1259062145154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.13,
  },
  {
    address: '0x1d4bb43c664257ac0527ad9cb791cf7f135a2d0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 88.01378595288477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 88.01,
  },
  {
    address: '0xe691a0a6ca7e89745aacb1355d768ce0769f10ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.88052633900777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.88,
  },
  {
    address: '0x26aa35b202601a288142ee3dbef83ee6b10f8e8f',
    minters_reward: 0,
    lp_providers_reward: 87.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.86,
  },
  {
    address: '0x9f1df012431ea29b076417df5979989b7d7c37bf',
    minters_reward: 0,
    lp_providers_reward: 87.05,
    swapers_reward: 0.6709664414264943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.72,
  },
  {
    address: '0xfad49fc950015934d8598a39b3a5eb622775a57c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.50845601768495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.51,
  },
  {
    address: '0x00e28ed6831019ba5cc0c3a75463fbff9efb2bce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.48858056669378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.49,
  },
  {
    address: '0x53e74d667117ce268913da4f3ca791fd5b62c9d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 87.1301184782301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 87.13,
  },
  {
    address: '0x58a2b6f883b060ec8371feeac02be8d4c889f0a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.96916408041052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.97,
  },
  {
    address: '0xd844da86713e5031e14cf2900e30e18151552ba3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.82824135776535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.83,
  },
  {
    address: '0x224d2bde4114538589515ef7b3a4295d0430d44c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.8206427381474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.82,
  },
  {
    address: '0x1a89d1f83a7f68ce689c13d3b8ebd17aa4808966',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.64659454547018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.65,
  },
  {
    address: '0xa54d94b20970bc8de0fa5f7f6f68f681113c743a',
    minters_reward: 0,
    lp_providers_reward: 61.34,
    swapers_reward: 25.07260530789994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.41,
  },
  {
    address: '0x93a0e7c1a19ef1169c186a04e234d71232a3d7e4',
    minters_reward: 0,
    lp_providers_reward: 73.43,
    swapers_reward: 12.867144200578732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.3,
  },
  {
    address: '0x2b83f65570ff488beb9b5a9575241471b2de8ba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.27551389670127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.28,
  },
  {
    address: '0xe6c15ec355bafe943233b8bcfc75f4bef9189412',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.26519641434673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.27,
  },
  {
    address: '0x2152c4b93c86ead03ab44a63c4147ad1e6152604',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 86.23320878667353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 86.23,
  },
  {
    address: '0x24d10972f53e467c1b152589b9e9e5721195635a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.97763037980091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.98,
  },
  {
    address: '0x5df29c3748e1c94a2c86a47c0e68b3e0253db435',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.91951623995182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.92,
  },
  {
    address: '0x6e8508c2bc228e4b67c55b09d6f1aa332d9a35df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.7865243736523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.79,
  },
  {
    address: '0xeb1563e77629a757aefc4f4e2d4aa105a9d87f28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.7186273101461,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.72,
  },
  {
    address: '0x68035ee240024b4bc1080c24a266b792c9eb24c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.66087240514565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.66,
  },
  {
    address: '0xcf8e79e5fba438e67c9f8aa9c6d22fbf95e67fab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.50969947987566,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.51,
  },
  {
    address: '0xcd8d79f3479eed38b5598c5d390b5677206aea35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 85.50510570961745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 85.51,
  },
  {
    address: '0x755857e2a5dc2706fda329e690c0fbae0d8b3b28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.87022167174439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.87,
  },
  {
    address: '0x9d7ad2d86beae9f82f35b1f3fa9a246a5e6ff7fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.7901155023253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.79,
  },
  {
    address: '0x138610eebc312ea632f649b04ca5b0334a63a104',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.60571189485336,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.61,
  },
  {
    address: '0x1e222b01c3a6666028869487d9d6b7fcd2959f63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.55171148543296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.55,
  },
  {
    address: '0xf3f9aa70b0a4745e6e917d7ed3fabfe5f5c2bff6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.51578372129597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.52,
  },
  {
    address: '0x1a70644ae6c1bc93383e945c14b2798672aed2a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.50866916444642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.51,
  },
  {
    address: '0xeec0591c07000e41e88efd153801c3fc0a11f7f4',
    minters_reward: 0,
    lp_providers_reward: 84.5,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.5,
  },
  {
    address: '0x3736d31e2ab4e8cd744709528c7b40d0cd29e079',
    minters_reward: 0,
    lp_providers_reward: 72.14,
    swapers_reward: 12.279363419384065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.42,
  },
  {
    address: '0x8555782b02c2a24cfe0b107273a41ad9c4718f6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.34953341409656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.35,
  },
  {
    address: '0xc493f754ef5c8b6cce939dbbee5b78d25fd5167c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.32370877386825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.32,
  },
  {
    address: '0x53e5eceffa53a7a37a528b4310d6045668cca893',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.27122898100228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.27,
  },
  {
    address: '0x945255d8c9394834ff002fb02dde05b10ec370f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.14582428667914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.15,
  },
  {
    address: '0xa22d0d55bcf894997bbcfe5cd6bd78a8c81fd3de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 84.09950630861262,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.1,
  },
  {
    address: '0xc75a3875f881dd16ab1118067eb2cbe2f213869c',
    minters_reward: 0,
    lp_providers_reward: 84.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 84.03,
  },
  {
    address: '0xc731d04a647252ad2cf9414cd49d3d967c78e959',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.94390602806558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.94,
  },
  {
    address: '0xb32f2fb959c29286e300c1e506601c3dbaab2ed5',
    minters_reward: 0,
    lp_providers_reward: 61.59,
    swapers_reward: 22.136220748780218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.73,
  },
  {
    address: '0xe668d27636a844c61557f820f712560a27fee835',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.62931219956175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.63,
  },
  {
    address: '0x6873cbcf73ebc8108d76c5ce91bdbb5b8ce71de5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.43533828277019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.44,
  },
  {
    address: '0xa090b9dc4dec4137fd6b478c93354d6cc1e2821f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 83.2146979089365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.21,
  },
  {
    address: '0x9ab35ea056ded6faf94c4a467b55a157360d2e83',
    minters_reward: 0,
    lp_providers_reward: 60.04,
    swapers_reward: 22.997340006966937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.04,
  },
  {
    address: '0x796dc4da7dfe4ba544408a750ad88e2c1097f22f',
    minters_reward: 0,
    lp_providers_reward: 19.15,
    swapers_reward: 63.870514756654245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 83.02,
  },
  {
    address: '0x2cca2f3831b0856b1d28e4bbb277a08d65a049f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.97009138978459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.97,
  },
  {
    address: '0x096ad3f43548d94bce7180608daba54468ee42b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.94448249323649,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.94,
  },
  {
    address: '0xa3fcc3958950035d6bb8b46015986118729fd70f',
    minters_reward: 0,
    lp_providers_reward: 28.35,
    swapers_reward: 54.59461385103411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.94,
  },
  {
    address: '0xd3e17f5035b5d6bcb22e8ea7e30412068db13c42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.6106109557687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.61,
  },
  {
    address: '0x2b4804e01c793b9be4b6026758137c4b5321abde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.60076342512004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.6,
  },
  {
    address: '0x16a5fe972606a8418adeb9dd33b9a6d1a49ff6cf',
    minters_reward: 0,
    lp_providers_reward: 69.99,
    swapers_reward: 12.488460808227854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.48,
  },
  {
    address: '0x1000ac547adb0719450cc16074bbc2794fc31dea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.09212532739089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.09,
  },
  {
    address: '0x6e3500c2e01ecf16232b283d7d72f6513a7bb0c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.03109856570774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.03,
  },
  {
    address: '0x1b169e7b1d625ca011d7398ff6430543185d3c20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 82.01380915204606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 82.01,
  },
  {
    address: '0x1a1947318d09199c6c198892072b1d0f2f306f87',
    minters_reward: 0,
    lp_providers_reward: 81.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.73,
  },
  {
    address: '0xd86f0592a0ca9dbb5a5fd26e2afba02798b2eb9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.56186554133245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.56,
  },
  {
    address: '0x5607a49ed17dba51b397f6444ea5da50e6d7d9d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.41686785574062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.42,
  },
  {
    address: '0x4ef002f23361ef9d551717baa36d5add5f5e4a8e',
    minters_reward: 0,
    lp_providers_reward: 81.12,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.12,
  },
  {
    address: '0x14d10e269e9a99dd9136465c91b532ee40961c3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.08488811501033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.08,
  },
  {
    address: '0xb0ef2cdd580f2e89240574334502b850966db63a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 81.02634867735183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 81.03,
  },
  {
    address: '0xbb2770c61be9e56402b123d6f7aa53e06388b4f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.94407142408366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.94,
  },
  {
    address: '0x31bf0d1f8a16ae27e1d36a9c0dd437a7bad6e38f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.78064377772564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.78,
  },
  {
    address: '0x625df685b09333d90b07840267413b87c7f53183',
    minters_reward: 0,
    lp_providers_reward: 80.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.68,
  },
  {
    address: '0x23388046d46c4cfd88a31a1bed9570df4c34051e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.56137282348094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.56,
  },
  {
    address: '0x917192b50d9853306eb9315f9405b745beebfdbc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.55575673605476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.56,
  },
  {
    address: '0xcf0176644526c385889a30075cde91bbf6824bfc',
    minters_reward: 0,
    lp_providers_reward: 78.11,
    swapers_reward: 2.2888765935362407,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.4,
  },
  {
    address: '0x90c2b12b0aac00700af549ae7cc0c33985fa3063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.38894161584739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.39,
  },
  {
    address: '0x0bc401bdf36cb7336580c3bad25418e70a8bf9dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.37285376344951,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.37,
  },
  {
    address: '0xec3185dc088dbb28f5cb540a812da28587efd499',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.1897859059739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.19,
  },
  {
    address: '0xedf3c6376fdd3a4cbc9b8356659102f8ab17fa18',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 80.01407497791848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 80.01,
  },
  {
    address: '0xd1c102e226f1894866d4e8aebfafb5239331e58b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.85022727159946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.85,
  },
  {
    address: '0x7ab6c290ff315c0dd35cd6c84aedc9bcab1b6403',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.78943045214962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.79,
  },
  {
    address: '0x37c94db29398c0c28147a4ee1bcccbbcec4bae9a',
    minters_reward: 0,
    lp_providers_reward: 48.76,
    swapers_reward: 30.882204280764952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.64,
  },
  {
    address: '0xab9f01f85973a525b49edddf1e3aaa6bf660fa53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.56074885522142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.56,
  },
  {
    address: '0x2f282352250e5972cf6774739e88c7e1121f7834',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.4594717378095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.46,
  },
  {
    address: '0x6bcfabadd1910c62eb3681ade2d8f16f25571b6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.4436539016022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.44,
  },
  {
    address: '0xf2b7f7e47e87f4a8c322a0f495d2940e78202663',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.21568124022392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.22,
  },
  {
    address: '0x561f3d1d36599caa326f983305fc74834d5e4a0e',
    minters_reward: 0,
    lp_providers_reward: 79.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.21,
  },
  {
    address: '0x2caf576c5e2781542c65fe2d07edb176b7db55ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.20288805843823,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.2,
  },
  {
    address: '0x8a53dfdf9614d5e78784c3745af78f4bbe58f800',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 79.1375575155869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 79.14,
  },
  {
    address: '0x50224851f49f508418734efd23919d1dc45f3c4a',
    minters_reward: 0,
    lp_providers_reward: 11.64,
    swapers_reward: 67.34411991924176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.98,
  },
  {
    address: '0xd3b12401a8d772b21ead56d294f3e935491cf6f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.80991240176878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.81,
  },
  {
    address: '0xa763c828ec16fcca59b22ddd53fdd97553b59056',
    minters_reward: 0,
    lp_providers_reward: 35.51,
    swapers_reward: 43.23847084451053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.75,
  },
  {
    address: '0x3f99f9ba950887648bc340ab064fdcbba38398ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.64112211590404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.64,
  },
  {
    address: '0xdcca2f4c10673c4aa13b1b7ab820bcc8b66cbfe8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.54724461286176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.55,
  },
  {
    address: '0x7123e511cfc413ffa9868895e59093b12819c1e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.48753277254525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.49,
  },
  {
    address: '0x5408e0e31f6ce0e69ad049456512b18ba9929aa2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.31567884103002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.32,
  },
  {
    address: '0xe35a4ef33104a5b725090752daded78029ba215e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.28497803900459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.28,
  },
  {
    address: '0xacfa6dc6f46cae16573258144e4824e589eae925',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.10032245531482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.1,
  },
  {
    address: '0xa5a67a098925f5eeaba2be266d0104553f8b07df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 78.09597034333531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.1,
  },
  {
    address: '0xb770e1c1d2be72be1a8e3e6763f9c8ad540764e0',
    minters_reward: 0,
    lp_providers_reward: 78.09,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 78.09,
  },
  {
    address: '0x2359f06c3dc523fc03e9f449e69711c0d5fc0a66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 77.67776587447459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 77.68,
  },
  {
    address: '0x87134cc388674e6fc3bfafdee3f19603c6818a5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 77.48908694514024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 77.49,
  },
  {
    address: '0xc9a8a3cdbab8ba9c31967cb5e6ab5bc14c143bcc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 77.48261876702834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 77.48,
  },
  {
    address: '0xb3540f40d7429113042dc961c64b194450f3346b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.9643529063322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.96,
  },
  {
    address: '0x106139748408aa6fa8b6c5e21361997f1001ff84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.94307335815547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.94,
  },
  {
    address: '0x4d9bc3719a5f555eea0bd22a9d2387c28745deed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.89594019074151,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.9,
  },
  {
    address: '0x80fdb88b545c0468a8af653bce09b1e8d8788fa7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.68453119768218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.68,
  },
  {
    address: '0xb93d160f1800f7ddec1e575927b0df89401a3936',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.55662450681938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.56,
  },
  {
    address: '0xa7810019607f043b1e2304eadc5aa061d47b26fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.52969320002657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.53,
  },
  {
    address: '0xbc5ebb0ada6f22b3cd9acdeb2bb29f2f6a98c6b9',
    minters_reward: 0,
    lp_providers_reward: 76.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.39,
  },
  {
    address: '0xcc71a71d07a09d4c71fcdfad8c7fde3863f98e8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 76.19118364974254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 76.19,
  },
  {
    address: '0x5b627f46f9d350745f4b82a9d004e33d90662266',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.7502713887215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.75,
  },
  {
    address: '0xd9062e7fd65a53ca74fbd1b0f4c4c8bcf9255cc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.65592926480522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.66,
  },
  {
    address: '0x2d93c6a0f1071fe8763ce1c31c07eeb54353e405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.24592854723693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.25,
  },
  {
    address: '0x402b828671da14167cc570478bf827ed5b707b88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.21955660120267,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.22,
  },
  {
    address: '0x4ad7f30c7f4c878b395d76c160ad1673bde73447',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.14366691960636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.14,
  },
  {
    address: '0x5724bca01054e1ad242e67a4b700fd208c51e3bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.09779420436364,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.1,
  },
  {
    address: '0x3ecd6d2674e82ff9227022cffa24854ea45b0976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.09214510704246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.09,
  },
  {
    address: '0xe83584775fca91cca226d5a145493da333a31bed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 75.08310449931369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 75.08,
  },
  {
    address: '0x7cea440b7dea1405ec2b4a6b1732755d914e7c4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.99116131788283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.99,
  },
  {
    address: '0x77e18355bb9aa0a9e165dfef33de295bc73fd3da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.92160449138917,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.92,
  },
  {
    address: '0xdcf83357074d41b43ecec636cdae47a0b5fd063e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.9068284189645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.91,
  },
  {
    address: '0xf71116868a114d1d4e957c608e405e69a23fd663',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.89837965178832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.9,
  },
  {
    address: '0x1db52ca38c5305505117a88f3fa4cdb321b78556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.7969833143571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.8,
  },
  {
    address: '0xd03488092bdd7a98c5e226a366767bc734c6e475',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.54299894507879,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.54,
  },
  {
    address: '0x08539f0fb45ad907ec89ac1d1e91d2ce25f65094',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.44778188691387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.45,
  },
  {
    address: '0x9eba1cf562aa9a2e3ea43da99d797e8c0ba49dd2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.16000505865098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.16,
  },
  {
    address: '0xded6020db9bed819b5c8a2d0ad0d5d0615dadf74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 74.05829444454803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.06,
  },
  {
    address: '0xcb813b7548af55136e24087ce9bf10a21c012547',
    minters_reward: 0,
    lp_providers_reward: 74.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 74.03,
  },
  {
    address: '0x20b22295c30a6b88b71c27fc79db6a5f2d29aca6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.750601617209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.75,
  },
  {
    address: '0xa64cba4fa76673fa48af9269dfb86cfb7fabf737',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.58461062189147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.58,
  },
  {
    address: '0x416c9e906374153c3cd6be469bcb9c1720b6abf9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.57091717895001,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.57,
  },
  {
    address: '0x91ed62877dcbe420cabc87d395c9aaf6bc9edc1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.42654673559052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.43,
  },
  {
    address: '0x2fd9bdde0fea6de3f7cfadaf4fd8e853a5119fa3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.36197873366204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.36,
  },
  {
    address: '0x07ca14121c108031f5497a554a357206920ae233',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.30325988092939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.3,
  },
  {
    address: '0x767baf522f22eb8828698d574bf97b9502eca9f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.27039554875533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.27,
  },
  {
    address: '0x135bb51134f499163d9763240ebd8fde043534e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.14959952689767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.15,
  },
  {
    address: '0xda99f4ca0cc3397e4839f9492790c3925f961648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.05490525174139,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.05,
  },
  {
    address: '0x1d6b00bd824ffc027de7fdc42f779cf1d1d8b004',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.03791698236142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.04,
  },
  {
    address: '0x87ed2c0bff7c33eed7d4b2c8fa42e42de306d0f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 73.02402118667835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 73.02,
  },
  {
    address: '0x09ff98337ffb7893d2ab645666efe52d9f2c935c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.9712638898647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.97,
  },
  {
    address: '0x7c4ff2c171ad8d669b4814d41c28b82f546727a5',
    minters_reward: 0,
    lp_providers_reward: 72.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.9,
  },
  {
    address: '0x07e42d1e328cd4281181dbe4fb54a30d15bf380e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.30103984712726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.3,
  },
  {
    address: '0x795b239dca632ed53f4aabaa6fa37791c2480fce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.07040739481279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.07,
  },
  {
    address: '0x570cbcba146cfcdb271087eb8a8f3e93b08db912',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.06774051645753,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.07,
  },
  {
    address: '0xec3ffd0f25fafda3d94a817ac61e7a1447f0e18f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 72.03852116073463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 72.04,
  },
  {
    address: '0x3711f721852d5cb0db99265e15a3da5fbd6ce086',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.96814637474358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.97,
  },
  {
    address: '0x27cd6bbb44efc8e8f0fb7215d724d68a44376a30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.93170348132976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.93,
  },
  {
    address: '0xe1e2eda97060717c5bc4314984e9cf9ddfa1ee0d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.73181998358555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.73,
  },
  {
    address: '0xe1a2f9944eac71cd5c285d92cc82161b01a1b366',
    minters_reward: 0,
    lp_providers_reward: 68.73,
    swapers_reward: 2.9886487452789057,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.72,
  },
  {
    address: '0xe48f1c83df4e846674adc6d365d750bf4004e7a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.68779659791862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.69,
  },
  {
    address: '0x64c5503b01e6ccc451687c5392068a0bdedaf959',
    minters_reward: 0,
    lp_providers_reward: 20.39,
    swapers_reward: 51.24990286249706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.64,
  },
  {
    address: '0x9e7b0f95d369a5b7f75150a439e33d2a802098db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.63198500474401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.63,
  },
  {
    address: '0xfd7b7d7fc92314e1de347755e1c20cce4d5fb9ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.6299432280307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.63,
  },
  {
    address: '0x4528d65ea9c5fefb15e7d412ae137b061c447576',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.46703510245517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.47,
  },
  {
    address: '0xecdb39d546cefd5d12b8e5104cf241f7ea7744d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.45942735124717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.46,
  },
  {
    address: '0x8b279e231bc97fd644da2acf48463e19d432cbc8',
    minters_reward: 0,
    lp_providers_reward: 43.19,
    swapers_reward: 28.13645779075734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.33,
  },
  {
    address: '0x07465f7a17aafd010b91c883ca40743ca7154efa',
    minters_reward: 0,
    lp_providers_reward: 70.65,
    swapers_reward: 0.6586916462385263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.31,
  },
  {
    address: '0xb7c89eac464aa44922f5f2a3b5ac20bc4423dfe6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.24641960372473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.25,
  },
  {
    address: '0xf7c06e6f7357d169b1542ebe536d1c6153125bb6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.1703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.17,
  },
  {
    address: '0x82337cdbacd042fcb5fc5bfd0a1a37afe471f97c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.09915133641164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.1,
  },
  {
    address: '0x2eccfdb454dd6b8c95a012d926b515ac96353a24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.03613366259897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71.04,
  },
  {
    address: '0xdd75929127febb087f849665528bedd77cdf5508',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 71.00004072473946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 71,
  },
  {
    address: '0x6476683f00317bee0837dacc2d7d46e82d25f5d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.87005350584052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.87,
  },
  {
    address: '0x38efa958e10a0634ce05a2b2914e13d2f92d3678',
    minters_reward: 0,
    lp_providers_reward: 14.97,
    swapers_reward: 55.84274608318668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.81,
  },
  {
    address: '0xe2042dafa10a0d086e9419fc7136be5caf8c4457',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.78827881317652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.79,
  },
  {
    address: '0xbe4e6f733be757b299a2874dfd17674818fed8ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.75851466471147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.76,
  },
  {
    address: '0xaa46238993d13a5f5124f3d401aedc8fcff708fe',
    minters_reward: 0,
    lp_providers_reward: 61.8,
    swapers_reward: 8.822687111437617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.62,
  },
  {
    address: '0x85f5c6f21dd228290f7e6235a6a612a00d9af63c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.50648037080423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.51,
  },
  {
    address: '0x0db15e2b5b77f333d12b9bfe7e7a70a5399f52ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.38659616123122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.39,
  },
  {
    address: '0x539572797b9383a3d55c76a4e6aa993042c0c599',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.38401600079017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.38,
  },
  {
    address: '0x833cfe5274eea9e7c28f40f0339e74c6b9e6de7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 70.20503439104264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 70.21,
  },
  {
    address: '0x8eb1148b30e1814c770b4312f95a1e03755a52b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.96860054126641,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.97,
  },
  {
    address: '0x368349b82a08ccb144b1195a7bfe0932d932999d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.95723132041354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.96,
  },
  {
    address: '0x830f0e9b842750c72851b8aee9cac1b887060936',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.69292148850306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0x49809cc7092f0d7731eafd7ead4442573425cf58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.69292148850306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0x0796725b77a560b83f072942d382106c892abcde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.69292148850306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0xd04c6cde3e74d77b5b3c9e5481511b8c2b55c976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.68842543644902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.69,
  },
  {
    address: '0xc2151f7ddd92803fadfeffca01396231c519f293',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.59693171667915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.6,
  },
  {
    address: '0x407457a031ee3057a55e509f2018174889ddf73e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.2016384396954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.2,
  },
  {
    address: '0x0fec7a1ab1155a466a297ecf0e8b5722feb713ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.11452095520876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.11,
  },
  {
    address: '0x4fb6fa1e0f0848850f116b8a229b41fd53888a7d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.04781270509862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.05,
  },
  {
    address: '0x3e7290d6f5ee3deb53c35d6656f82ae9e0bebb69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 69.02320947368409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 69.02,
  },
  {
    address: '0xbd20e46c85201e1613023b8a8b43b314ecfd1c93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 68.61680889017502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.62,
  },
  {
    address: '0x8be0e213155de86a2b5b4fe6678bb6e2241badda',
    minters_reward: 0,
    lp_providers_reward: 51.46,
    swapers_reward: 16.99604070138833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.46,
  },
  {
    address: '0x27e09006dfb73b643c5e067fee4192a46ee5be7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 68.28762372422071,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.29,
  },
  {
    address: '0x87e731cd872c38648b24229dc173844a53fb66c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 68.12846109916973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 68.13,
  },
  {
    address: '0xd3dec7c8765fa780e2e7092ec767911c7f82cfc7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.98507972034975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.99,
  },
  {
    address: '0x4e89a6cdd0b13610528cbc37b20ac33d2113471f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.94877204433368,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.95,
  },
  {
    address: '0x8035664870bdfa63cc973a2e8a9740af2a2baa68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.86795604026389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.87,
  },
  {
    address: '0xfadfd2b8b98fce100f4eb8739741220d1d877820',
    minters_reward: 0,
    lp_providers_reward: 27.59,
    swapers_reward: 40.27365996650692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.86,
  },
  {
    address: '0xbc3fe5ecf4e355afa81f34a43bb29f1d17adce43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.72226540009842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.72,
  },
  {
    address: '0x67d60fbed3035b206f1a5be78f1b2107867db6a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.72226540009842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.72,
  },
  {
    address: '0x473cd078f1e14b66fddce76dbdfe8a974154a57c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.7222654000984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.72,
  },
  {
    address: '0x702337d657c7aab31f1a07340a212eb9ae420b30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.63180461775677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.63,
  },
  {
    address: '0xa9ebdf96f0a8d4886f3ef3a5d0f4485444c1c9f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.54600268038887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.55,
  },
  {
    address: '0x2769b845d94cdfb5cb6e9e2e6a641d22c3fba1f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.51066541502183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.51,
  },
  {
    address: '0x58782281de8d0cd4d4116c49f8e23397ef7eedb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.46957346476697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.47,
  },
  {
    address: '0x5795337839759103c57cb7596dfeadf9157d3561',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.45212173602003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.45,
  },
  {
    address: '0xb7b5a65c94b910a57aa12386180ddac6fe368668',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.39575306688982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.4,
  },
  {
    address: '0x44d7a5fdaca09fab012f2eaed2add517d2b49c0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.334836862385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.33,
  },
  {
    address: '0x705032d398791cae3a3486512656f2b9c77d8a69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.28481627030803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.28,
  },
  {
    address: '0x6232b52872748dcd7ed217aa5afd5b49a0d0a5a8',
    minters_reward: 0,
    lp_providers_reward: 40.58,
    swapers_reward: 26.520214030317696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.1,
  },
  {
    address: '0x332c82949ac440847f47a5207a7e8e191ce26a27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 67.00779387608951,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 67.01,
  },
  {
    address: '0x7a619e10632ebbd6f627edd8e67d7bbb777f8da1',
    minters_reward: 0,
    lp_providers_reward: 53.94,
    swapers_reward: 13.014245186439497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.95,
  },
  {
    address: '0x9c8192eb2b46af8a4914afd1dacebf6f37746c57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.76559953717826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.77,
  },
  {
    address: '0xb91700c6baaa217917349ae40a2649236f55df3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.67624819043343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.68,
  },
  {
    address: '0x8e23691d0b9e6bc55300249b3e134a25a8ed98bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.51440490238517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.51,
  },
  {
    address: '0x94a489e255d0c5771e6be54e7655b0dbb813acf7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.34986782560104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.35,
  },
  {
    address: '0x43c1c8fc8b3afc90c8b44dd0c328aef10f446e60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.32843906839477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.33,
  },
  {
    address: '0x5d244e23c6b071b410b8b993f58f15fb2683b84b',
    minters_reward: 0,
    lp_providers_reward: 34.35,
    swapers_reward: 31.82870722397851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.18,
  },
  {
    address: '0x1690fa67cbe0e5a9e868c4afb74a5490ac09a8f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.14154738907031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.14,
  },
  {
    address: '0xd405eeaccf2336c5a54c50b7fb63241e85379da7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 66.13957670782679,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.14,
  },
  {
    address: '0xfde66f24b190ddf5924f9abd4adc9d7119a0ec71',
    minters_reward: 0,
    lp_providers_reward: 65.7,
    swapers_reward: 0.3323759519151724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 66.03,
  },
  {
    address: '0xe95196afad667dacb36093563ec5f556180b6f7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.90817500592931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.91,
  },
  {
    address: '0x8f4624e489e0a809b74e619f13688bf5355daa7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.90817500592931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.91,
  },
  {
    address: '0xeea2fd517a4ecab4e48bceed39732a3b62b1b46b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.90817500592931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.91,
  },
  {
    address: '0x9103d91e3aaa96d8f5d255e3c826e880868b333a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.82661120026046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.83,
  },
  {
    address: '0x1ccf911f92e8cbf485ee1978b3481ed366674302',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.77275025145126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.77,
  },
  {
    address: '0x4ca17e119edceee7e9737fc94ee0d41d04eb4de3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.76647005324568,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.77,
  },
  {
    address: '0x47acf9688d0ec49238218279dade696b929bb37e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.75281036388272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.75,
  },
  {
    address: '0xd14cbbe8da07bf1a67cc72deb3192da52f59c5eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.562836903493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.56,
  },
  {
    address: '0x08eb3be5f2ea980c70c19e9a541bac6af4c22eb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.53439569154531,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.53,
  },
  {
    address: '0x12463a6b6572425ffe0c9fce713dd179959941ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.52239081470022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.52,
  },
  {
    address: '0xb6117a4883c408c356eb2a75be3a046617fe40f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.48947144142181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.49,
  },
  {
    address: '0x2bbefbf621abd01c9b98c2dedff3e6821cb48ad0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.42923793680546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.43,
  },
  {
    address: '0xfa87ff809e17331b0687d775d005c3dc2977dea4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.32877410702099,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.33,
  },
  {
    address: '0x1f4946312574cd1ae9c356190f3a87c7b38fb658',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.31193404582615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.31,
  },
  {
    address: '0x61171257b0627211885bb199d3c69190131c1916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.27099804309026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.27,
  },
  {
    address: '0x48392652402694c84dd64b6fd99b6e99f52014f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.18813189428363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.19,
  },
  {
    address: '0x23916b0ecb4c410cb37de08bdabd2c17d5b54b94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.16052668095625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.16,
  },
  {
    address: '0x7b633140f28a28dd6d78f12e9f3b93261d3888cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.033231608235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.03,
  },
  {
    address: '0xd2479467a5c4958870102204bfe9e682630e1b32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.01337478409447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.01,
  },
  {
    address: '0xa99dfab40deb03951cc7116f134fc316f398534a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 65.0088442790214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 65.01,
  },
  {
    address: '0xcda0d390eafa4011a626d8ba0ae77478f4706521',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.98281867121725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.98,
  },
  {
    address: '0xc3c5f74a04f2f2810cb0bda37a1236623fda363b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.96861181820942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.97,
  },
  {
    address: '0x84b82c36a01e3f9e6f4508a4944d54038e7c2148',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.9255181539372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.93,
  },
  {
    address: '0xba9a44e838e127f62481358287e7c9b25f806691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.87352662309901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.87,
  },
  {
    address: '0xfaa9a5c79275615be6edfa5a3461db2fe7798aee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.8211930508179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.82,
  },
  {
    address: '0x763683e2524a303d3c173a97d9d9e98b664da5b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.74959174432158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.75,
  },
  {
    address: '0x6b2767a8b2c3a171552b8367a0653c0926f17f62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.74301279270595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.74,
  },
  {
    address: '0xf7cebc7681e76fde18f10bce640790b2f5e7b93d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.60406145055774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.6,
  },
  {
    address: '0x9ba2eb094bd23b8a9cf0fdfc38181a85abdda016',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.57973233413495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.58,
  },
  {
    address: '0x9246525219ef60704020d5a9e12330379fa8b556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.40769665832975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.41,
  },
  {
    address: '0x77fcab3d842e4f3cb5faee3ba9d538f0be4363e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.36665978353953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.37,
  },
  {
    address: '0x9a52f291c153ba947a6fd4e997a97bbe9380939e',
    minters_reward: 0,
    lp_providers_reward: 52.69,
    swapers_reward: 11.59941371063135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.29,
  },
  {
    address: '0xb54acd00068adf055b6b54d0337a03790e665d15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.15950956103472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.16,
  },
  {
    address: '0x2b1c1222eb359f673bd0ebf23fce5a060dc0c67a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 64.04806808141149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 64.05,
  },
  {
    address: '0xcb6a8782227fff9b3a338487b1e61e6fd3c171ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.9878446711616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.99,
  },
  {
    address: '0x1fe6bf6b0160c4a0d7734cb36754d2af87e9e579',
    minters_reward: 0,
    lp_providers_reward: 63.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.99,
  },
  {
    address: '0x3aa697450a735914d39cf282d702c442ad72e36b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.9823088968968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.98,
  },
  {
    address: '0xb41a027c0ba9553f71151b053b4df6a4018f1795',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.91311006473292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.91,
  },
  {
    address: '0x21f472c8a492b5971f830fa01a9fbaa7f0024a49',
    minters_reward: 0,
    lp_providers_reward: 63.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.9,
  },
  {
    address: '0x416f34bf0bb568856f01d146a17c241302551d7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.89210939265602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.89,
  },
  {
    address: '0xd06c591f7d82268c99645e024d372ac1ef4e42a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.809116921489945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.81,
  },
  {
    address: '0x0a4d5cc681a6f08f8feebfaf921d517e2b8b94bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.720108412564294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.72,
  },
  {
    address: '0xfc21ebf61784c43fe0eb10944c77f0bd1af79f6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.66886091612274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.67,
  },
  {
    address: '0x84571609ff535dec645bc6d8bb6ae33c2148951e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.6122945055098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.61,
  },
  {
    address: '0xd52aee97e9424cfb7ee9fd6497c4b0a62db4a43c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.53621709174972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.54,
  },
  {
    address: '0x536e319880abdad53a5cf2365f9fb3fbbf6971bf',
    minters_reward: 0,
    lp_providers_reward: 47.36,
    swapers_reward: 16.148996899056684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.51,
  },
  {
    address: '0xd2e70c68bc87e7b80446eaa17b2e9011ce0d78ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.453664507980015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.45,
  },
  {
    address: '0x14089168aaf4fe5c633468e468515ef6ae859ad8',
    minters_reward: 0,
    lp_providers_reward: 7.09,
    swapers_reward: 56.1496373971965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.24,
  },
  {
    address: '0xdedf96f8a65b34145f5b71315b271073b2b4336c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 63.105361869146435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 63.11,
  },
  {
    address: '0x1365026b7a121220a406cb9ad541cefb4a9715ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.45392826011301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.45,
  },
  {
    address: '0x1745f883fd866104638903227379453829647b6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.44809056882014,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.45,
  },
  {
    address: '0x5d42b8590386c08f00efeb540a708b41b9e4d9cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.37140690410003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.37,
  },
  {
    address: '0x7e3ea601939ad586762f9e375bd0a79b4b3c5c3b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.34377117385473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.34,
  },
  {
    address: '0x952f0a93543b53052b9564372fe65a261a3d15fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.305343080280686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.31,
  },
  {
    address: '0xcad40ac42d90b35d5d2cf799866fd0f474420a30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.14804538047692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.15,
  },
  {
    address: '0xc7f7077a2ca6adafabc245172014f3015f876c42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.10327609679856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.1,
  },
  {
    address: '0xef9284f439b22e49c7b34a736874f5014e79b876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 62.10081852518105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 62.1,
  },
  {
    address: '0xc8389ef24cc03acd5ee97f8734a2640fecabed88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.8173625659199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.82,
  },
  {
    address: '0xf0f31ac0cd7179c13c030ca692954dd991bb9e31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.81603477982116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.82,
  },
  {
    address: '0x9cb167651081c4629b65f74316e9c75724358acd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.707114113905526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.71,
  },
  {
    address: '0xbf8c7d61385c25599ce77e3090eb853e516e852b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.706512499640446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.71,
  },
  {
    address: '0xa314e8b877d1cfffff339a8d9282833a43051555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.686960382892345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.69,
  },
  {
    address: '0x4b5a56f47034eaff9128d73d7d1c4bc922e6d08f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.55886442058143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.56,
  },
  {
    address: '0x012916ed48b20433b698cb32b488e52c639a2a66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.52633944404372,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.53,
  },
  {
    address: '0xe7037fa62b2bb8b557f4407b9b6b09351ffb92b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.44709911369436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.45,
  },
  {
    address: '0xd6caf684fc9bddb677b5516d93a7710235a34ff8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.35769555271706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.36,
  },
  {
    address: '0x9f00db1e3374c834d67e9296ebe136e3fb9ac27c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 61.10539261361764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 61.11,
  },
  {
    address: '0x3b15e90c72b6c0e9db5bedfbb6430cce6669daf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.94015498139312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.94,
  },
  {
    address: '0x8f7c3ecb9977ef32e386ac7e7ae6717f262b29f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.78643693123563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.79,
  },
  {
    address: '0x5f39f2f19d75ce6083ca88d4b1849c36403e875a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.77908015405272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.78,
  },
  {
    address: '0x0119d914b60fc558f61f2bfcc4736f9b7c3b0256',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.76953770131918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.77,
  },
  {
    address: '0xe924c83f953ae6f2388e94c217c63fe6d6a5223f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.705716033205206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.71,
  },
  {
    address: '0xc75f8e14cd5fb63bebfe19bfd6bc6ccdac0732d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.53461054741546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.53,
  },
  {
    address: '0x9a5e3cd9c92c455a1fcf4acd52068f1dd3ec3998',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.47399233348682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.47,
  },
  {
    address: '0xf64b6c8e86f5daafb6b206775c3f09b5e40e6d6f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.397983730094545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.4,
  },
  {
    address: '0x2864c45e548d586b6e19a80504697c7ffc0ae9f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.243469914019826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.24,
  },
  {
    address: '0xc2d17b4f7531217685368ce49915983763a115ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.217018164994414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.22,
  },
  {
    address: '0xfe112d832c2dda0c1924761ab75e095efa3dd594',
    minters_reward: 0,
    lp_providers_reward: 60.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.19,
  },
  {
    address: '0xadb652373ec271fcbf30ca56fc5f534f41bfc704',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.17199998179104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.17,
  },
  {
    address: '0xdbffb42a0b58d138c21767871f3a95271aac4c0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 60.07710828037528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 60.08,
  },
  {
    address: '0x07c1ee4f7f959c8bc8202b2ecc178ff9006a67e2',
    minters_reward: 0,
    lp_providers_reward: 59.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.98,
  },
  {
    address: '0x0ea7fa87e2e52e2d4d601a9db276dbe751c17696',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.928570251097604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.93,
  },
  {
    address: '0x4697e4954b0678839ca2c59f6546f912fbc96081',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.913106888707276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.91,
  },
  {
    address: '0x06d66cec581d07660c7bac9c0fefc1f708a778ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.88577469341925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.89,
  },
  {
    address: '0x194948092fcfdc954972fa22a9c76c871ba64714',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.78891866298493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.79,
  },
  {
    address: '0xd26005f8c7e54f01b4afd580fcf51b5b4efb07bb',
    minters_reward: 0,
    lp_providers_reward: 59.16,
    swapers_reward: 0.38790955811418076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.55,
  },
  {
    address: '0x894569337b599113fad4398af6d1b775d7c82d99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.54155585713929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.54,
  },
  {
    address: '0xb2b2ceabdcd6cff881f3db519547dccc72191873',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.44300504960433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.44,
  },
  {
    address: '0x1cc659d1885075404682484762aa43943f065e9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.282248161467365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.28,
  },
  {
    address: '0xe1ba0664134294e1f40f5bd150e6886475b33644',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.2122675314477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.21,
  },
  {
    address: '0xa09ca4d6d094996cd8c0e924c9872a7b4ec5e2f5',
    minters_reward: 0,
    lp_providers_reward: 33.57,
    swapers_reward: 25.573346669480838,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.14,
  },
  {
    address: '0x75324f728135230630365784ec380b7e8143f7ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.05267466030939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.05,
  },
  {
    address: '0x249b549c3b711c8882a5c5ac6abf084b52012106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 59.04939637618607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 59.05,
  },
  {
    address: '0xa0945cc88dfdc8417d382194ed3f7ab5e1409682',
    minters_reward: 0,
    lp_providers_reward: 24.36,
    swapers_reward: 34.621458670266094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.98,
  },
  {
    address: '0xdff871be81defed21f33e1720416bfa958d8f96e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.94753473434707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.95,
  },
  {
    address: '0x3c6d4eececce571170353247ddb3d0381328eaa6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.83022646582973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.83,
  },
  {
    address: '0xf36b5370dcd0bbb0cd1400b5cf24493303ea24cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.72633048429977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.73,
  },
  {
    address: '0x4a72d64d5437f4ec6432f23fad410d6802f90b74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.7225950227507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.72,
  },
  {
    address: '0x057d98048ba22f19c6c98b73456804851e65dda0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.70204684038059,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.7,
  },
  {
    address: '0xce02f7e8288bdd6e5af2a5352f181d35af0abe02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.67419735139969,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.67,
  },
  {
    address: '0xa698a7944a9e49b9f7254e400d6a5f16cab417e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.636021425284646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.64,
  },
  {
    address: '0xc61e8e336665b207ff237dae3e87626b3afa01da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.623941339267965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.62,
  },
  {
    address: '0x796c07ce2e1abe9044f725efb5033a82ed6d6dc9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.59078224727048,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.59,
  },
  {
    address: '0xa7f32bc7864cf96e13768fb5e478d59ced9f146d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.222683276332496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.22,
  },
  {
    address: '0x28557bcbe0a67abb6348fc303c9dd4a1346a5138',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.2013915714126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.2,
  },
  {
    address: '0xba9919d75145d61154b10820f1e059e1d6bc4d81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.12516276285358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.13,
  },
  {
    address: '0xf27d6228eeb14fb06c0c22668068d0ac448ce0e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.08302284952093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.08,
  },
  {
    address: '0x463d9f016495cfa44fd5303b75a492c85a42044c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 58.0717954148063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 58.07,
  },
  {
    address: '0x427888ebc70354dd2406dda5f7ba6bf991e05966',
    minters_reward: 0,
    lp_providers_reward: 57.96,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.96,
  },
  {
    address: '0x88f1096045049884df5b8f3d1f83c3522d4e4d12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.92064329865377,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.92,
  },
  {
    address: '0x138a947dc14b18a9b92d6506284680f1506d13c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.91253231757379,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.91,
  },
  {
    address: '0xdc48a98252ba523c0f62af92ea566f5f2866882d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.895318808273686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.9,
  },
  {
    address: '0x1d8b40ce7897081cb51b11e0883ba0751161d826',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.58643216767125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.59,
  },
  {
    address: '0x1e5fb0c951706a791e2e2084005b3bc394018e5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.530703899174355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.53,
  },
  {
    address: '0x976c4976889d6ee337e67204c6efadcfcf425580',
    minters_reward: 0,
    lp_providers_reward: 57.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.45,
  },
  {
    address: '0x84450d498738be547db257e2b53e2738849f1cc2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.35976436401735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.36,
  },
  {
    address: '0x246c09f185d5af4996080fba8ac7951b461b230d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.33977181232683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.34,
  },
  {
    address: '0x271a63c294189d80a9b9a5694277d079e4d5d042',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.264924194931595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.26,
  },
  {
    address: '0x65474929eaa3527a9416d31b1914f24926e13e67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.253309863975645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.25,
  },
  {
    address: '0xd32845baec193db60f5f735413a4a685ade41063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.22184857501698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.22,
  },
  {
    address: '0xd4bb86d99abfb6a6c412d8d5207b25dc0f152588',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.18626638657707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.19,
  },
  {
    address: '0x79aa373e4fea5caa358bdb7f529833fab8649e7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.125986900474196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.13,
  },
  {
    address: '0x132477bf1bf3a530f4ca72d45a8658f3bf3088f0',
    minters_reward: 0,
    lp_providers_reward: 26.11,
    swapers_reward: 31.01400673652752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.12,
  },
  {
    address: '0x9d31407edd448f8ada2e52ee90c68f5c5cd2c1dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.03206141174887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.03,
  },
  {
    address: '0x0f2a864dba126dcfb18bd0d801e75729627dcf22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.03112579172277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.03,
  },
  {
    address: '0xecf0bee9930eca4792810e2ae404cfe323d8ff37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 57.010739349477674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 57.01,
  },
  {
    address: '0x4f0d766bc67c7af65ff6e89f791d8b110a2165ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.97104048343465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.97,
  },
  {
    address: '0xc32fec02c33e2f5f3e98e7e3635037361bffc364',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.87056951358346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.87,
  },
  {
    address: '0x51ce398e86c84f86e1b49e680bc2a58dd421eab5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.785690972488766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.79,
  },
  {
    address: '0xb31c7927a6ffc931ece4221083c9d72f9b60bbad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.75927888249043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.76,
  },
  {
    address: '0xdd536275c1e7ba94bee46a76218b5360c6a1054b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.74635096215511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.75,
  },
  {
    address: '0xcdca2b8a5e6409bbefc56af17e0a5bbd091eddc9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.73786827997802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.74,
  },
  {
    address: '0x44cb7df573dafcbcdb8e38bfdc144288f3259436',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.72732161569629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.73,
  },
  {
    address: '0xb4fe8b94a4f88a20980f910284aeb618150d3a1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.63061742159692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.63,
  },
  {
    address: '0xf9f3d8588d08fb2ee5d7791492a18ee53e1f1772',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.60686136731185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.61,
  },
  {
    address: '0x3075de6242f2d498d7eb2ee7ac04187f8d7c8ae9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.488811845484086,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.49,
  },
  {
    address: '0xed4363757b2c00479f378de27848725dbfd17d18',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.484689903470034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.48,
  },
  {
    address: '0x1112fbf1a309c4f7c58e5619a2f61412d3a04293',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.44136982270439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.44,
  },
  {
    address: '0x957c2a714c4e37c825116939797814baf7f83979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.40637522646119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.41,
  },
  {
    address: '0x090e97e4449c39608e5d685cce92bb8c6f037cfa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.37025646182273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.37,
  },
  {
    address: '0xf78176905fdc1e86fd5778355abe2d40a2d7f2c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.35180713605651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.35,
  },
  {
    address: '0xec3b9c6d5b2587513c011e97e6194f958fdb2d71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.344743200193435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.34,
  },
  {
    address: '0xf5be442a0cfc371cba7e000798bac106e5609f16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.32944663390086,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.33,
  },
  {
    address: '0xc8a9a26c097d4e0d91ecb21336ae4c866154ef1e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.25333041630162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.25,
  },
  {
    address: '0xf852bb4127cff037c8752311ff21bc87b61e78f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.25134086966238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.25,
  },
  {
    address: '0x3e5ba8a2500c59d6489e7924626b950401c112fa',
    minters_reward: 0,
    lp_providers_reward: 56.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.15,
  },
  {
    address: '0x53ce41680f44da411833fb0bab2f31d9c1adb93b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 56.12969236911495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.13,
  },
  {
    address: '0x196b52b66a424fc61876258648d6eb7d8a7f481b',
    minters_reward: 0,
    lp_providers_reward: 56.07,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 56.07,
  },
  {
    address: '0x276d0f2f07db94e9aa166690866a1e4a0c7fbb66',
    minters_reward: 0,
    lp_providers_reward: 31.38,
    swapers_reward: 24.613458568684937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.99,
  },
  {
    address: '0x4af3a8ecc4e90b92262028ff6b2e49a935340aa7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.943480571840034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.94,
  },
  {
    address: '0x51e20f70236de385e456b22e9145c7164cc96be4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.926907889355206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.93,
  },
  {
    address: '0xa07ef9c9119bde8789ed19c1751f4f8d1133cdb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.91185817583847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.91,
  },
  {
    address: '0x10ab9d355cf3923feaffc56df4d423afd7a72287',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.869046958956226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.87,
  },
  {
    address: '0x1ee57e88c839113e4162000f9dfb7e4b14b12df1',
    minters_reward: 0,
    lp_providers_reward: 55.14,
    swapers_reward: 0.6863392102255862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.83,
  },
  {
    address: '0x64a05b7a0f4e20745ea270783af370d76a20537c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.787927770671516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.79,
  },
  {
    address: '0xd01325eb51556e3e44c93dab69797e0f12206ee6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.750740349159216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.75,
  },
  {
    address: '0xa368d99fee0562834d44145600ac87deb5bc6397',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.552720338136304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.55,
  },
  {
    address: '0xf95e25104ccb0bafbb73c1914f78eef63baba1e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.34446835872803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.34,
  },
  {
    address: '0x9acd1776dddeeaf40a08aefaa2b9a6068f45c028',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.33756918932246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.34,
  },
  {
    address: '0x68412b4037a033faa27de5f163b457db6ddcc7fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.25077376364875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.25,
  },
  {
    address: '0xdc462ad94c379a3e9c4bc341cc32d86a1b8e5b41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.24646439912787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.25,
  },
  {
    address: '0xcb092e5e16c2c3f95c28883f7036cba3447710b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.08708854261495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.09,
  },
  {
    address: '0x6841f0d64e41a4da9a4dc196d6272bbcc0e3ae62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 55.027219053742435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 55.03,
  },
  {
    address: '0xd0665d71a1671df4059f72e1eafed95b6a3e123c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.95324115638061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.95,
  },
  {
    address: '0xce7cc5b29ba1cd2bbb7f3bbd02e5f0583656cc7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.9182745069904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.92,
  },
  {
    address: '0x884b442ca78ed1b30cf6a0958b6c713898e75006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.74273006988523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.74,
  },
  {
    address: '0x847b7d78a9eba2b023f99955d0cd766ea39c6b31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.73694618953459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.74,
  },
  {
    address: '0x153f26c2c7169b1fc7dc6703261a9d31c6064f4c',
    minters_reward: 0,
    lp_providers_reward: 54.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.68,
  },
  {
    address: '0xd9bcd446535aae908b34d62eab601f17593d7968',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.64488051092984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.64,
  },
  {
    address: '0xbbfefc2662c6e367ee671e956b47a9b58a2bf8fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.49123115888652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.49,
  },
  {
    address: '0x491ec03cbebb539cf46f2030ca92ac3d2d998b5e',
    minters_reward: 0,
    lp_providers_reward: 31.73,
    swapers_reward: 22.744097683534427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.47,
  },
  {
    address: '0x7dc7d88354b061979440ddefb370f4a453e5441a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.43686280719706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.44,
  },
  {
    address: '0xb10d4993a92f71ac13f3c279bc7b1daebd000e34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.30130095593677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.3,
  },
  {
    address: '0xfb3ced42058878749379f96db1c0dc685a4b4fa3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.24447285694956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.24,
  },
  {
    address: '0x8bfff4d264cc4fcc33a52f918d980c8a1244e0da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.14077086548155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.14,
  },
  {
    address: '0xa1eb4271758b8a640f911c7ac79dabca9a9c0cd2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.02995664450469,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.03,
  },
  {
    address: '0x63ec9165ab74d7cf628b2e71dd76d1bd1ed8f492',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 54.00513157649368,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 54.01,
  },
  {
    address: '0xd21ee439f35671767e9bc3410f54aa10713850db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.97769648460257,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.98,
  },
  {
    address: '0x1b9888c1c9e0fe406e5fa2ac2741c2487a20b31b',
    minters_reward: 0,
    lp_providers_reward: 53.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.95,
  },
  {
    address: '0x04da3b81db7bc5f943d6372456a574309f74982c',
    minters_reward: 0,
    lp_providers_reward: 53.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.94,
  },
  {
    address: '0x67acd25d9b3d4971f9f8edbd3192d6a0bf21fbbe',
    minters_reward: 0,
    lp_providers_reward: 11.75,
    swapers_reward: 41.97195301403279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.72,
  },
  {
    address: '0x48871bd8a5df14c93b3653e009dcdcd1cb9f49d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.6419838095327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.64,
  },
  {
    address: '0x960029bae074337926ed22d6146ecb260997f444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.5721275285496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.57,
  },
  {
    address: '0x2d6f68153b440011c974e18008d03d20e4cee57c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.367209270037144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.37,
  },
  {
    address: '0x7014ca26170ec96b9a37fcfca0fbdafcc5162850',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.229900730830046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.23,
  },
  {
    address: '0x1ddd6cf038fef900837a6fc480b59eaa8e946107',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.21367401842777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.21,
  },
  {
    address: '0x883df836f1ce43c786d476fec2925408cce9c425',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.21220753140146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.21,
  },
  {
    address: '0x90ee7b35fe92b5e83f5391c7ca8969f08489c4e9',
    minters_reward: 0,
    lp_providers_reward: 53.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.21,
  },
  {
    address: '0x367cd9ff6237b4ddeaadbe9e3bc33d2b7df9aa9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.14342238318631,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.14,
  },
  {
    address: '0xcc15607a9a7919ee93b1ea99c46ff41f8c957784',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.12167317265728,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.12,
  },
  {
    address: '0xc500c4d3be0582170436d05061c10b5ed31de41c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.096311623970884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.1,
  },
  {
    address: '0x18e38769507c83e853b46955cffe6928052d2292',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 53.02658496811412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 53.03,
  },
  {
    address: '0xf6b95ca80c797377ee91bb92ede70ea317725abe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.9777600754629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.98,
  },
  {
    address: '0x1141d6c30c39776d75ab97dad365e921f4ed18c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.95323743545445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.95,
  },
  {
    address: '0xbeff45771fe7a68573bf998604d4927e3ed3b0be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.840298007597774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.84,
  },
  {
    address: '0x1f88703a800be53d8a3b0a10a475d6ccb2ff3be6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.69087063117827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.69,
  },
  {
    address: '0x8d376759452c45ecff24e8bd13de1648124b4aef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.611322848566004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.61,
  },
  {
    address: '0x6ed27d3922c8f0bc7a172805e58d262716c5a417',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.605481821832484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.61,
  },
  {
    address: '0x84c2513b9e1e726846e6d603f161ea3297f36786',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.59830044040094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.6,
  },
  {
    address: '0x5039c4997580fe39cc26c75cc3c2a2d7c6caeacf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.57432074151719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.57,
  },
  {
    address: '0xdc61f0ea8f4584fb43ad0e5253054404434f0dba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.52903640217686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.53,
  },
  {
    address: '0x71adf68a5831cc7023e637a6bc527c413db17427',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.52201070218651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.52,
  },
  {
    address: '0xa030377564c2ca9feae9cae3dd78940c10f4bd3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.49770454787959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.5,
  },
  {
    address: '0xd664b771cf724796275c2d3ded647c509b4286e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.487715168314274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.49,
  },
  {
    address: '0xc5de0f663dcd9fa47c94969539e89dc7f1b92446',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.45220236830997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.45,
  },
  {
    address: '0xde277e98ad9cc2a76cc7e1d497a96eda6e600a69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.44495481641424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.44,
  },
  {
    address: '0x96987bb621b272dd588091ef5d26747b4c91af49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.38841078613887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.39,
  },
  {
    address: '0x3a6f14c89838b9f3fc30a37b475da440a09889b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.3091018417825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.31,
  },
  {
    address: '0x5861e7ec5495a0c8fad9ae73ecb64a67d5e30772',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.265146345796545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.27,
  },
  {
    address: '0x9ae5cc5e93b27e7930abf48f7bcd214af9aab772',
    minters_reward: 0,
    lp_providers_reward: 30.49,
    swapers_reward: 21.777535504128398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.27,
  },
  {
    address: '0x4eff73f19e1cc49834d7cf9c1b29deb5d4f8e699',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.202181961194995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.2,
  },
  {
    address: '0x60d6e4fea988dacfe40f6af3cf303a3c31203484',
    minters_reward: 0,
    lp_providers_reward: 48.71,
    swapers_reward: 3.492511202489641,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.2,
  },
  {
    address: '0x62de3335a36078f704de048a5ffb4563a53e31e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.14955028735881,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.15,
  },
  {
    address: '0xacc8cc7467192f7f44f80b0424467e96d7721e27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 52.07089206285768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 52.07,
  },
  {
    address: '0xadad958b5f5a106e08445d8dbe651dd5b051a04d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.82401561630142,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.82,
  },
  {
    address: '0x04c11ab8869f6077954e02b439eab6b9d430186f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.813643184023846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.81,
  },
  {
    address: '0x4f73aeb0ef6e795e425f36f0f9e797696a311880',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.79370444956198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.79,
  },
  {
    address: '0xd1f74e6a921bfa62bafc4e2244fefa420518b076',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.72203536054704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.72,
  },
  {
    address: '0xcdba7997c01eb3f5394cb5b2977727deb2a2c846',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.62191383899094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.62,
  },
  {
    address: '0x17cc2e9aac2adb37eb618f65dee4b732a4db61e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.56482446774157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.56,
  },
  {
    address: '0xbdf096031d224b502b5ebd84f08b2be612e564f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.214598165448834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.21,
  },
  {
    address: '0xf740f4da8e78ffbf5188aaf4cf9de0fca5a7eed2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.1615970383119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.16,
  },
  {
    address: '0x252d88e9d0bd2e4eee6ede26e89bf974fbef1f93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 51.08861816184108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 51.09,
  },
  {
    address: '0x0a016afcf1defa05ce0b340204adc26cc3092b3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.94335166123662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.94,
  },
  {
    address: '0xdd21610e323cb69535b3df11ae553c5412bdaa68',
    minters_reward: 0,
    lp_providers_reward: 37.05,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.76,
  },
  {
    address: '0x965d023d514b2a7cdb4165830712ed6900a6d6b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.70358561151883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.7,
  },
  {
    address: '0xe1e91d9221d3bd9987c830766390c68576b8842e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.59557050775026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.6,
  },
  {
    address: '0x14afbf99dd49ee52fd37c7a6f4f85184fbae1090',
    minters_reward: 0,
    lp_providers_reward: 50.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.58,
  },
  {
    address: '0x33d308cd150a8497e4713375500f50bd7c255420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.52892700695513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.53,
  },
  {
    address: '0x5a5d9a3def04d319ce978ff67416e186b5f296c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.52226642461831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.52,
  },
  {
    address: '0x52c82ab3e7a6f671d44c7ac3f8ebe1eeecbc05a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.09867139967304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.1,
  },
  {
    address: '0xc04b40e948561f1ede5d7e2d4803f41a181cfa8d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.08624107510459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.09,
  },
  {
    address: '0xe0df1c7d28002362fe4c5f8c1fe2e9dd4e1beac3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 50.0120997129468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50.01,
  },
  {
    address: '0x955f13f882b6d9b9ecfab8c7d5824233fe152e9e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.99622403863481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 50,
  },
  {
    address: '0xdd7abbfbdf889f91df92ed717c9f4b12ad830827',
    minters_reward: 0,
    lp_providers_reward: 49.95,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.95,
  },
  {
    address: '0x41e9266bafc82a82729f736ff7b02f6e32148e10',
    minters_reward: 0,
    lp_providers_reward: 7.94,
    swapers_reward: 41.938629183981874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.88,
  },
  {
    address: '0xfa7d0cfee4d881cf8159e0d18c692ccb71e9c0c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.69792897326141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.7,
  },
  {
    address: '0x7114c34f5bba989fc4e088b0fd99be2fe78596d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.67234386517256,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.67,
  },
  {
    address: '0xfc55df4bd90c01ead2e766173e98d52ee4f9bbcb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.3847586982379,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.38,
  },
  {
    address: '0x088a5450136fe2711c0591be5d3e3c2e1e130313',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.33327009989194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.33,
  },
  {
    address: '0x6ebbaf5a69cadc6d3054c11dd305ed814ce29baa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.19527733327422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.2,
  },
  {
    address: '0xc5a2795f791b644e43765d0e86a0061e0d6f6555',
    minters_reward: 0,
    lp_providers_reward: 45.09,
    swapers_reward: 4.11046635018424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.2,
  },
  {
    address: '0x442f80859e9e61e48952125fc27de05233876439',
    minters_reward: 0,
    lp_providers_reward: 15.04,
    swapers_reward: 34.1463492678398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.19,
  },
  {
    address: '0x9aac2c44a1805028d4306d49473d6eeeb723d25f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.17891187064293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.18,
  },
  {
    address: '0xec7a7b5b3902e9bd381b2f94f643d315e9f03f7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.14883874547253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.15,
  },
  {
    address: '0xa8026ba31ff91a280310efec34b74ad14ac3c987',
    minters_reward: 0,
    lp_providers_reward: 1.59,
    swapers_reward: 47.564240941242964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.15,
  },
  {
    address: '0x22b2eb3dce7962defa23649714a1329ebc8e173b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.06899212901387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.07,
  },
  {
    address: '0x992e63c763b61a94c77757cb5e5bf24a8056eeff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.04509859587188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49.05,
  },
  {
    address: '0xc58823f64f27762599fc32bda3e80b82b40f2ee1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 49.00295341991468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 49,
  },
  {
    address: '0xd5b62d414746629b7e28ff9564d74d46d0b7412f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.61271377208013,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.61,
  },
  {
    address: '0xb441e1feb44c325e47a2d7528578ee9d57020b87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.59395789188199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.59,
  },
  {
    address: '0xe5ad63dbe47765b7f99c0671962073b24410c16a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.477793444472475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.48,
  },
  {
    address: '0x66e214977e8abdfa7b2bf5d6d2a95a7ea19a9eac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.30183280578467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.3,
  },
  {
    address: '0x9448705e9902a99b40dafbb8e2c5036916657f5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.274825919243845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.27,
  },
  {
    address: '0xd08455537dab2b369b8c36a4f1ce96af001675f5',
    minters_reward: 0,
    lp_providers_reward: 8.04,
    swapers_reward: 40.20001351115019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.24,
  },
  {
    address: '0x6b2d890c0ec18506cf8ce7509cc32cc8f3e76b26',
    minters_reward: 0,
    lp_providers_reward: 48.21,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.21,
  },
  {
    address: '0x218f4992f66a9e4cc4fe77b949768b4e275c1c2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.088802428107385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.09,
  },
  {
    address: '0x3066ccc533dd18be48482218f6bb31dd450c6b2f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 48.06414242438875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 48.06,
  },
  {
    address: '0xe9022283b2ec250260f3c5635dcbe2c0fba21fd6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.97163997954466,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.97,
  },
  {
    address: '0xf2e20e91c8e22f72ba89d0c9e188291e04581dcc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.883092639813476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.88,
  },
  {
    address: '0x6a83deeb4f5ee9f92eba24e06b651c77c3a53976',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.85792758358122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.86,
  },
  {
    address: '0x7de9179020bd40bec1f966ef3fda903620342a90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.79146136298103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.79,
  },
  {
    address: '0xb83968053449f757a7ec59d4d37405b0f6d3034b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.6613895442465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.66,
  },
  {
    address: '0x888b6365c6d0949e61a2a6fbbde168c8fd0f847b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.64443198521536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.64,
  },
  {
    address: '0x1da285ebb7bcdbae760402ac5680a9c23b401649',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.475487617434744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.48,
  },
  {
    address: '0x1db3d2e989d9d1c13f37fe5b8063e9aa84ec4fa2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.40641801719235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.41,
  },
  {
    address: '0x9c5b201a5df1409ee978e9cde2a5b32892b823e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.40558578006888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.41,
  },
  {
    address: '0x3a9799de4ad7decb8eeef718f3d44cc71d537725',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.4002535685517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.4,
  },
  {
    address: '0xc42cb65bf4d574c488de5f1ae2524948820406eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.39754595333391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.4,
  },
  {
    address: '0xfc1235e6523becf4b329ed9adf21750cfaf65bfb',
    minters_reward: 0,
    lp_providers_reward: 14.11,
    swapers_reward: 33.28260742576448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.39,
  },
  {
    address: '0x3b4a01755e98ea292010c6256e062ff6d6e3c4a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.355839561934346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.36,
  },
  {
    address: '0x41d15d8c33b178c05c1991f8960b9b7070ba9040',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.347408083190764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.35,
  },
  {
    address: '0x6d8b6d5eae663d99290267091c1f8b15ecac2f3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.16803024985243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.17,
  },
  {
    address: '0x3cf404a8c14521d97e767963dea465e3616db985',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.16781917947035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.17,
  },
  {
    address: '0x7cd9806dea5483845b8742afba51d3fb26fe280d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 47.01943975917869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 47.02,
  },
  {
    address: '0xd7f7a351f7cbbd222d043bcdb8916ab79d9f85eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.98623003905327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.99,
  },
  {
    address: '0x2eee32b4ddafd9b55461a5fd6c81485b10339f2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.96881437757559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.97,
  },
  {
    address: '0x1d6b6eb26d7c78b3f899ba79511f38f28d6eed6a',
    minters_reward: 0,
    lp_providers_reward: 42.99,
    swapers_reward: 3.9388268659735255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.93,
  },
  {
    address: '0xa8c044ec218ce9726ef38b43e966d421bad64e27',
    minters_reward: 0,
    lp_providers_reward: 46.81,
    swapers_reward: 0.0754399072478878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.89,
  },
  {
    address: '0xf6dc6724235908e5b6a0efea55fe6d49fd6e04d0',
    minters_reward: 0,
    lp_providers_reward: 24.08,
    swapers_reward: 22.724894525921684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.8,
  },
  {
    address: '0x50275e4704f7b91a741d07018760719496ccbf0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.793683537887965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.79,
  },
  {
    address: '0xa0adb444676fbd15ac503ad6d7ebe3877e26877b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.78567328312035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.79,
  },
  {
    address: '0xf29350cb38951d6685042688c7a88b0d442aa0f2',
    minters_reward: 0,
    lp_providers_reward: 46.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.73,
  },
  {
    address: '0xe7aa93956cc8c3d8ddeb3e77944d0d63714f7773',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.68438924520463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.68,
  },
  {
    address: '0xd5696d5978b021ea4040d32ee0c3dfc20078bbd8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.633521005820974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.63,
  },
  {
    address: '0xe19137d091649d5c7a1392b32cad28742d40e87e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.63165902143603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.63,
  },
  {
    address: '0x1d2770a5f5402d359d40075d0c27ee340ed4b0c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.59663456657963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.6,
  },
  {
    address: '0x543c40bf447a00885ede1e010047a103b724014b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.592918595267705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.59,
  },
  {
    address: '0xcb4776bd53ec9272e3a0267ff9e139bb02bcc35e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.58512192329787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.59,
  },
  {
    address: '0xe53d5cc9c8a06548b951e3b86d14555267d7d820',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.58324633408026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.58,
  },
  {
    address: '0x62ff31ed72b49ee6e4150cbaf6f63cef0d6758c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.52187898160857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.52,
  },
  {
    address: '0x3f379517da2ddee8a1753f440abc3a44d129a3df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.4200306537648,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.42,
  },
  {
    address: '0x3a6778f46efe7470a62fd310d4482026210c29bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.327913660702706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.33,
  },
  {
    address: '0x0eb36d2949fa39f974bca82538a4eb1d693309ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.24221195547206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.24,
  },
  {
    address: '0x20a54b9128d30111cb05a171796b192aee804082',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.168598851982786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.17,
  },
  {
    address: '0xe9092a46f6fc322e6c65b6db85386385cca8a8f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.153922995005686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.15,
  },
  {
    address: '0x973802eb8d4210fe68f244116f56cd07cdec7dca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.08335539431069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.08,
  },
  {
    address: '0xe16a8c8e950bbb7fd0a90293a8a6474ccc99ae2c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.04407776980482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.04,
  },
  {
    address: '0x8f5511aa70f4ddf2d480a135202eb02b4a3797d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 46.031608387892796,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 46.03,
  },
  {
    address: '0x6438a04a5ac6e6de409de34b5ab24a61b3624f04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.92320875526322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.92,
  },
  {
    address: '0x0daee5712d03bc8ee5d8e28ced494e5be700174c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.86532737937547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.87,
  },
  {
    address: '0xf1ba45abea4bbe1f6139761bb66e4a7502785d24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.828623530359536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.83,
  },
  {
    address: '0xb7da5f820313f24ca0090c8a16898fa38d8cfcc2',
    minters_reward: 0,
    lp_providers_reward: 45.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.78,
  },
  {
    address: '0x8acb7ec57af88a69e86349de901e3d567101a2dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.650489286060974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.65,
  },
  {
    address: '0xf6175e0c79cca5d50d5e7304ffe1072b13b74e5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.62766068260381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.63,
  },
  {
    address: '0x50f194efa2af19faf68b8938d856a796d3cb39f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.494257274851776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.49,
  },
  {
    address: '0x80e25ac6fb952734cdfc85ca369b462c821d2e39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.40935654408156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.41,
  },
  {
    address: '0x22de1554fc7c1192eb64f8ff35c50c6a3495a854',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.372248463312374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.37,
  },
  {
    address: '0x44b25ec156875ed3398425fc6ddc9b807249ac6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.25898419342899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.26,
  },
  {
    address: '0xf942742a158c3b73b12b251768c012cd9d770b8d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.25529338414486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.26,
  },
  {
    address: '0xeb3aa6d11cce103323a1a5dfdac44e939f9f7b0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.249366071347374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.25,
  },
  {
    address: '0xeaaedc4ad87a89ecd6d2267c6364c0482bc59335',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.24450272432486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.24,
  },
  {
    address: '0x411cbe244ba96a375a27e74dc68746bea169a6cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 45.06515107186225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 45.07,
  },
  {
    address: '0xa70c0032fe069d91a52ef5d094490a0f03e970fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.98426916116064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.98,
  },
  {
    address: '0x7889086911668fb6e780425eb926b6b32c090ada',
    minters_reward: 0,
    lp_providers_reward: 44.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.98,
  },
  {
    address: '0x8087e3bb33fc3282d93bb40f232b882cc286cf6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.929019651237176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.93,
  },
  {
    address: '0xafd8fe62739088c2ae58e5f13a9d8a67dfd6304a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.82652711699215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.83,
  },
  {
    address: '0x26cbf43651326cedd24a272783100e401640e69b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.82571861269899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.83,
  },
  {
    address: '0xf2a836987b055e0821fd9b824dfa8ff4114a7807',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.81431285506675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.81,
  },
  {
    address: '0x067833fd1d72e2f2780625b59d59b8f20b088ebb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.7856752129522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.79,
  },
  {
    address: '0x1e46702abf33986a2047412c13653159224854c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.75500018196763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.76,
  },
  {
    address: '0xa50af704fed5c065bf344e5edcf20c195f55aab5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.69978662268834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.7,
  },
  {
    address: '0xb3a91f46bec551b457e4c67b984dfce69393dee0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.68708483893138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.69,
  },
  {
    address: '0xe60b9539b454b4a5b58eab76635edc766f15a14f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.6865754351401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.69,
  },
  {
    address: '0x8bc4e9d8170cb36a390fe2a36a1f328411ea9405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.68641181935728,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.69,
  },
  {
    address: '0xa38b5394f84a29c7eac52b4a67914446c8c4f3cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.6300418671293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.63,
  },
  {
    address: '0x9a13d649a10c4155a7ff56467e3ccd5f63aead8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.60842158400622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.61,
  },
  {
    address: '0x96f4b7bf2d584a7d53d31199dff8f8f3ad2043b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.59096919734027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.59,
  },
  {
    address: '0x08d27646ed3a2e9f6d4e5d633c8d332c3c8ae32d',
    minters_reward: 0,
    lp_providers_reward: 27.99,
    swapers_reward: 16.554067671163846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.54,
  },
  {
    address: '0x54883d4a10f35e434784fb50e30e0e34e8ea2fb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.5348488544489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.53,
  },
  {
    address: '0xe291725aaacd6b4a54fd8fecbea2f7a6fa0d2b19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.50986908991791,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.51,
  },
  {
    address: '0xf9659b5d14e11184bec30a30708e7e18c9620555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.50001773580159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.5,
  },
  {
    address: '0x72efdb764315c47dea69029b9fcd01353087ff5f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.4896757883533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.49,
  },
  {
    address: '0xfdd15ede7748d42aeca8fedc619b3bdcf3c8751f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.31952639019172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.32,
  },
  {
    address: '0x23561dbc4d22cfb3d0a1310ba92ef38e347e8c81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.293713545683865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.29,
  },
  {
    address: '0xd333459fab540566ec2855c5062a92ef11fa0046',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.279739918050836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.28,
  },
  {
    address: '0x5da986f6327269dc1a9d7ff360fdeb7b7b2a60e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.25699189295561,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.26,
  },
  {
    address: '0x542f9a56345aef94a0658b2d235fbc737c944622',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.24152464155694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.24,
  },
  {
    address: '0xb6b12f72a636e6ee505cbf1d62ec08b4b22530e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.23497802288578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.23,
  },
  {
    address: '0x56dee6da6a3e62f44a210992342ad818c8b4b3ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.22365004582583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.22,
  },
  {
    address: '0x4bb2c2938d983f63b9d804285c8683bf8ee8a9da',
    minters_reward: 0,
    lp_providers_reward: 14.87,
    swapers_reward: 29.281156977351667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.15,
  },
  {
    address: '0xcbd7b1bfa9b63ef6b4b5f99f95c6703e734f7bbb',
    minters_reward: 0,
    lp_providers_reward: 44.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.15,
  },
  {
    address: '0x5e012b2001a5eaf5d43b773989fff6b4d966b2f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 44.0849601443584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.08,
  },
  {
    address: '0xeb407fb5c944f17aae668e534f491d186193371b',
    minters_reward: 0,
    lp_providers_reward: 18.02,
    swapers_reward: 26.040231147395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.06,
  },
  {
    address: '0xfd2e75596bd5c94b30051e9f26f0ba3c9c3298c8',
    minters_reward: 0,
    lp_providers_reward: 19.18,
    swapers_reward: 24.877357223879354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 44.06,
  },
  {
    address: '0xe1ca6194e03f22289edd06ef10a3eef02f45e007',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.96843534120892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.97,
  },
  {
    address: '0xbcfe8ebfddead14454761199505b9fd0d5cadef3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.91458228642367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.91,
  },
  {
    address: '0x6bc2c8bc2ff94fec3d774f965422949ea9848125',
    minters_reward: 0,
    lp_providers_reward: 34.83,
    swapers_reward: 9.044983427518057,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.87,
  },
  {
    address: '0xa633a5daf55295187c4987026bbc533fbeb7b3b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.861893572626904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.86,
  },
  {
    address: '0xdc484255450ce654de60c5d66e6dfd8928abf68a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.71746246800276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.72,
  },
  {
    address: '0x5ae6fd2ad9007d3059af71a64c58201d6cd24239',
    minters_reward: 0,
    lp_providers_reward: 43.69,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.69,
  },
  {
    address: '0x61c83d2b8b80acc4bd3f18707bb577318cd354eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.65259501762335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.65,
  },
  {
    address: '0x7258a1284ee57d430ff03894b31ca8ad90975185',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.633804303442645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.63,
  },
  {
    address: '0x26211b3ee39c73c1d65d09e73b400b551abb76de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.47989208601195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.48,
  },
  {
    address: '0x7225a93c074658f5b0a1ce1cacdd2d3e6640ac80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.46349808616046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.46,
  },
  {
    address: '0x24a1fab5ecbfa61fbd7ea6304f0b02e158991258',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.455875535039375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.46,
  },
  {
    address: '0x92a9cc8b05aff8440a66005adbf7c8ef5085fc4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.383680411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.38,
  },
  {
    address: '0xd51824f3d9540b719d420595338e071933a06f48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.35195570223056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.35,
  },
  {
    address: '0xa84bdf49e4bdd101884ea2fef6c33cd8d9e845b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.29493070340566,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.29,
  },
  {
    address: '0x29d7f32b0c09ca1f3bacb840e2aa21f0205b8413',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.20527905031478,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.21,
  },
  {
    address: '0xd710f884bf16c72a2584879bc4661674213cad3b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.16633275012923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.17,
  },
  {
    address: '0xddf976cc54d997ec6da52c71aed2b045342a2e17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.14378379411907,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.14,
  },
  {
    address: '0x67f8948f4e8b53d75ceeb7e66b5d2a7bdb5b22ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.142398243027074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.14,
  },
  {
    address: '0xcbb7e6b95428c3427565ffcf877981f4451479b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.12332506228635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.12,
  },
  {
    address: '0x734dec6ef1d8aa02207746aa63f52f9f487d7489',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 43.090278579153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 43.09,
  },
  {
    address: '0x0385bc7b28bc7873f481d4a7bc66b76b875746b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.98274486576031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.98,
  },
  {
    address: '0x3955197f21d214b03f07b6d16544105bd17e5832',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.97440483219815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.97,
  },
  {
    address: '0x8289386d7a1c1dc005ca62c2d5084d700adb5d1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.89700724037404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.9,
  },
  {
    address: '0x0641954630510c6ee8d0ae5f02c7180bbe0b3a0f',
    minters_reward: 0,
    lp_providers_reward: 42.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.9,
  },
  {
    address: '0x38d2932bd58c7fc696817177a09a83fe1c576e1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.89451851082748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.89,
  },
  {
    address: '0xa83280f4b52c9050f42f26813109c45edeab8a48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.88582779541369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.89,
  },
  {
    address: '0xad09cb70884dac120acfbb1b56beddb6427b12d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.831474262379764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.83,
  },
  {
    address: '0x7af860532a492ece1bc54c4c748f244843991865',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.80152399795997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.8,
  },
  {
    address: '0xd2146484b25e7a9590a05e77ecc3c6dcf0852c63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.78605639866005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.79,
  },
  {
    address: '0x5addb92c541cfe660d20e8792ca7f2152178ff22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.720594573959886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.72,
  },
  {
    address: '0xccd9ed94fa3e9a03fb43554779bae79833dec22e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.68104983065944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.68,
  },
  {
    address: '0x5c6005dfe2622ae4a7e5d9eb8597b4c239d26392',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.671442376196765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.67,
  },
  {
    address: '0xa2548a9f87165add7b6e86b83b9a730ee77c8c51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.56899958049891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.57,
  },
  {
    address: '0x130d0b4797e12864255a35cded6bdade1c570055',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.51299377668111,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.51,
  },
  {
    address: '0xed6c942be6c4eba8c7d6e93acd334b0b06c0bcd4',
    minters_reward: 0,
    lp_providers_reward: 41.11,
    swapers_reward: 1.4003019839525952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.51,
  },
  {
    address: '0x4dd55acd99e5bb4d25257e9a376b86aad733bd72',
    minters_reward: 0,
    lp_providers_reward: 42.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.48,
  },
  {
    address: '0x9c9ac7c3d694ede660c853dced179064869a5db9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.423736409835755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.42,
  },
  {
    address: '0x2ca99e26d1fa44b333f25d7847e4a652498cd5c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.29940755689095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.3,
  },
  {
    address: '0x9c86f73061239e720d159360971e886fc14b7d3b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.27286597932522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.27,
  },
  {
    address: '0x660400a60a948f62f0c6fd3ca16b26b3ffe788a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.26026474794913,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.26,
  },
  {
    address: '0x15b9cd782d91309a8c927ae6a86d09f492bee118',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.25329705331081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.25,
  },
  {
    address: '0xd69d7df4b9d91625e1359fcee7a94279359e589d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.09450103540108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.09,
  },
  {
    address: '0x9f31b3be992891088bdf1e57c08133c4f2514bcd',
    minters_reward: 0,
    lp_providers_reward: 33.09,
    swapers_reward: 8.97792524789887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.07,
  },
  {
    address: '0x1f93abf54ab6ff791cf44918bc7b9c7c2c56feab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.010891746515135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42.01,
  },
  {
    address: '0x9046722f21638e154581e98de9bfb0836e68c7be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 42.00019851920135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 42,
  },
  {
    address: '0xfc78f07986381160033383562a0b208cfd00dba2',
    minters_reward: 0,
    lp_providers_reward: 24.11,
    swapers_reward: 17.872743929406052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.98,
  },
  {
    address: '0x4bfb94b39d5dbfc6642cb90a333d04bfc0bc70dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.97195301403279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.97,
  },
  {
    address: '0xcd00ee8409feb0b2e0c3630a3a9c8ed4f3c9b1a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.946145686056695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.95,
  },
  {
    address: '0x79584dac3a464d5d54bfb625f80dc4b790d24b61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.94585380571495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.95,
  },
  {
    address: '0xdad4d2c549a990b245aa32c7281b172f59414c8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.93059742713602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.93,
  },
  {
    address: '0xd53114452d51f9eff9207bb21332c00913801bb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.89388689710958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.89,
  },
  {
    address: '0x9776dbe09b8ef900b0ab2068aaa84b5a0f349b80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.81575289310183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.82,
  },
  {
    address: '0xe4b782a0efb411a7474a770ac70e7100e5e6ecec',
    minters_reward: 0,
    lp_providers_reward: 14.1,
    swapers_reward: 27.68173252617259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.78,
  },
  {
    address: '0x57b91fcc5732b7e7068be7bddaba13f33b3961fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.749177855015176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.75,
  },
  {
    address: '0xd338d23a1c34902254dd68497c9354c930f24bb5',
    minters_reward: 0,
    lp_providers_reward: 41.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.74,
  },
  {
    address: '0xc040626dc9869d42c43df190c2c931e3ed9278e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.71525751571225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.72,
  },
  {
    address: '0x1dc1918803ed25b670ca3fbc53bc600abd6ab806',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.66538728382238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.67,
  },
  {
    address: '0x113a6ece7ba12551730775fdc00035eaf632fbee',
    minters_reward: 0,
    lp_providers_reward: 3.36,
    swapers_reward: 38.27855771182817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.64,
  },
  {
    address: '0xdadb821deb8d39a61207d25b39cd8105183107e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.601927333139834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.6,
  },
  {
    address: '0x96f34bc1c814c4ce9f4c1a507e549d948f1017db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.528256703609124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.53,
  },
  {
    address: '0x7c49cc6ba8020b1088289023a3f6b5c5bca05bc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.513425929059174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.51,
  },
  {
    address: '0xcf6aef19437abe496f2698f61afd2ca30e3f2c78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.46694769712967,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.47,
  },
  {
    address: '0x90020d509a7454f7117f0d303bb4cca02358e2dc',
    minters_reward: 0,
    lp_providers_reward: 15.21,
    swapers_reward: 26.25354207398147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.46,
  },
  {
    address: '0xa33ff1944c2f76a8eefb5b2e6e12874260bd8e97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.40311957627238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.4,
  },
  {
    address: '0x99de31b793ce8c2dd4b44c2e11bcd04584a1e4a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.39450636437431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.39,
  },
  {
    address: '0x34d8bb91d194b0495d7eb632d12462093ff067fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.330401251678225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.33,
  },
  {
    address: '0x8b47224289e2d1f5cc8aeda2f8f8143213dcc7ba',
    minters_reward: 0,
    lp_providers_reward: 41.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.32,
  },
  {
    address: '0xaa75d2dc5e30f883cafa0b4dc48f1945062fb764',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.27260509702426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.27,
  },
  {
    address: '0x1611bfab3af0f8537a620959e615e2826d0608e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.231714976410714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.23,
  },
  {
    address: '0xd0c33ac480fcca39b8cf650978b1611787c5c3d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.16832580326528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.17,
  },
  {
    address: '0xb27cc0ebca671429de48842097de3e9f985b153d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.15289631030966,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.15,
  },
  {
    address: '0x283c87be4c9003a0fa6539d2211a76d5180c37a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.13628827495793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.14,
  },
  {
    address: '0xb4acfbd7e998da6892ab70d8abcf4f4a86413758',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.12113657434222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.12,
  },
  {
    address: '0x6fe2c965dbd611bc579e3c9c7e94aca0ef5068f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.04832862461745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.05,
  },
  {
    address: '0x37c0d9f7aec7cd4781d27bbc80f518c736b38b14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.043194903277524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.04,
  },
  {
    address: '0xf984b551ec0e45d2f8189ad1dedc4562f15dba0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 41.03505112106604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.04,
  },
  {
    address: '0x572cdda5a552007d33cefbc18a67fdaeb62db736',
    minters_reward: 0,
    lp_providers_reward: 20.31,
    swapers_reward: 20.731225297234996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 41.04,
  },
  {
    address: '0x00e318403a4330c1fa916f82b415a32ae19085f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.99427839315424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.99,
  },
  {
    address: '0x4206930aa452b7df36566576e18ca8a5afec4ab9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.86390532525241,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.86,
  },
  {
    address: '0xa2e411490e03bd17880f5a9d83624ac3118cc9c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.8570922265819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.86,
  },
  {
    address: '0xc29a3555180ec6ba86580bbb56510268b0e6c0d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.801233947250196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.8,
  },
  {
    address: '0xa9f2699926ca83b246b46f7173192e7e1b461643',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.78706017993573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.79,
  },
  {
    address: '0x933061adff2b28a840a20371789140dbadb6ce3f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.76407743038595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.76,
  },
  {
    address: '0x7b6b63ba33069d2ab73446b1bec3aa32ce0a663c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.67478353446839,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.67,
  },
  {
    address: '0x11bf4fc5c71ab90aef566dd1f5a435c42e1a0844',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.65487242607496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.65,
  },
  {
    address: '0x858d36289849cfd6a66166c7f7f222634c1952da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.63335924005904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.63,
  },
  {
    address: '0xb0f3c0b62a9baf47ec3f7a2ef17701568db8b1f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.59110327778998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.59,
  },
  {
    address: '0xfa7cfa07e8cb642117a374f27f24475acbb36a29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.56615115544824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.57,
  },
  {
    address: '0xf56b77830b4beb12236ac7ade20dc7d75d9059c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.5084840782697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.51,
  },
  {
    address: '0x92dc1da5a554685308bc07ad1f6245a035ad3e15',
    minters_reward: 0,
    lp_providers_reward: 20.84,
    swapers_reward: 19.668980293232043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.51,
  },
  {
    address: '0xc9a421f7e7dcebb4ee24dec6543da2c5e21b0f5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.486967043856254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.49,
  },
  {
    address: '0x723908691451d9eb7a51b584c0dd33945e5f12cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.393689367844914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.39,
  },
  {
    address: '0xde25a90bef46208057ff9ce8505b4223878d005f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.38145579752523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.38,
  },
  {
    address: '0x205f6448a64ae37e8ba5d1c44ba888395980e131',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.35717136088042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.36,
  },
  {
    address: '0x7348502ebda84df77e22e021bfeb02cf1616aa9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.27701543968863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.28,
  },
  {
    address: '0xf3116e7afe780f1335a041891f60107481cecc78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.26903076619464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.27,
  },
  {
    address: '0x527534a0f0fe40bd6b947bb8bd54555b43e77064',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.24512933140216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.25,
  },
  {
    address: '0xf58a24eaa342639f62a2bf7f632362abc639e4e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.21130104327361,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.21,
  },
  {
    address: '0x2c998cb284294aea33c3420d5c9c4458df70c951',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.12521521030829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.13,
  },
  {
    address: '0x7c7b54df335c591fd1030b7db2738306907efaa2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.12439592700744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.12,
  },
  {
    address: '0xda8ca7e0a52e1cf737935a4db9dfb85e13baec53',
    minters_reward: 0,
    lp_providers_reward: 15.86,
    swapers_reward: 24.254391635672377,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.11,
  },
  {
    address: '0xdd1bafab95ac10f865529d85c072978e28131c82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.076158354596984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.08,
  },
  {
    address: '0x35e13ee6e374f4e300bc65be220f293b29c9a503',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.07313486267515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.07,
  },
  {
    address: '0x243ccab759e815e3f1dbbc13b7a7c9261feae842',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.036087177309845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.04,
  },
  {
    address: '0xfc8e4b5d1b09948e7397eedb7fe831a13859968e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 40.02612582230571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 40.03,
  },
  {
    address: '0xdc44b09b504160db695de2bbf5c0fe9b8d9d5af0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.994444591585186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.99,
  },
  {
    address: '0xd061f4882f6fd16b2c7ce6c59fd03121f597d92e',
    minters_reward: 0,
    lp_providers_reward: 11.17,
    swapers_reward: 28.76818321337078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.94,
  },
  {
    address: '0xa5acee760c5a2971acbb93857d1edb81c464dfa5',
    minters_reward: 0,
    lp_providers_reward: 39.53,
    swapers_reward: 0.40560578884235876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.94,
  },
  {
    address: '0xf0b0b188588320a9b301b52f04530b739b7dc113',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.90168870695791,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.9,
  },
  {
    address: '0x29bbb19186b43409c15b11c6f37cf21b7dbd6a12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.89669002235416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.9,
  },
  {
    address: '0xebd846ee50ad7b8890270641a7849b76271d2568',
    minters_reward: 0,
    lp_providers_reward: 39.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.86,
  },
  {
    address: '0x86fb95f19b94ccf3663c291c2a40e24c2e37f1cd',
    minters_reward: 0,
    lp_providers_reward: 16.91,
    swapers_reward: 22.903072026085816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.81,
  },
  {
    address: '0x48f4a5906ce05f07e5fa699d19a793638b223a74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.790131781627835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.79,
  },
  {
    address: '0x5657b833744e1d52599f923a9d045fcefd4d0fe2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.73909607962489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.74,
  },
  {
    address: '0x564c91bd9a5620aac588507ef4c201053b4060c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.733448853284386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.73,
  },
  {
    address: '0x2c6b4f3d3e16a3fe94b7dd668edae547b10797a1',
    minters_reward: 0,
    lp_providers_reward: 10.51,
    swapers_reward: 29.18464588581422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.69,
  },
  {
    address: '0x02cf678e101ca86c3ca90f4d39dd74cf7bf1d25f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.66457254449617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.66,
  },
  {
    address: '0xe34c255a548e5a7ca5b9797fd8231a30bb223f61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.5762508157737,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.58,
  },
  {
    address: '0x29114d76e2636137282949d2f79af8581f6ff87f',
    minters_reward: 0,
    lp_providers_reward: 6.52,
    swapers_reward: 33.03229768618155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.55,
  },
  {
    address: '0x4bd8080ca0c7f69b64e7478b60beadfa59af43bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.47486739406037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.47,
  },
  {
    address: '0xac440044dca365aee25a86bbccf7063e721cf79b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.38744783822645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.39,
  },
  {
    address: '0xef6f495c3d37aedb7a2368b9ddf45192cb647fb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.38526765567348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.39,
  },
  {
    address: '0xb4d67397c33bec4439444c0c05a2bb2765b1878b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.333716112310675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.33,
  },
  {
    address: '0x447d1aee7e7d999fceae8b1fcbce675d5c49caf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.296444549918334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.3,
  },
  {
    address: '0x54582396cb2b32e9bcabbef4b7c32be4ee086e00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.236509795678124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.24,
  },
  {
    address: '0xb4d45db7d21754585a2731c3541be4084401069f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.186287985034305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.19,
  },
  {
    address: '0x0d88f0e9633fa2bc5a7eb5580bff08b611380d25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.10178668331231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.1,
  },
  {
    address: '0x165b791eb34133d188c1336e27d1a19210f7aa83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 39.05271306592162,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 39.05,
  },
  {
    address: '0x6c5243b5bf4a24d0a6d12054d8e7cfbf207d773b',
    minters_reward: 0,
    lp_providers_reward: 38.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.92,
  },
  {
    address: '0xb21307672d44c87a14a19694f06746406aee237a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.89607962981102,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.9,
  },
  {
    address: '0x56ae5dc7a93ae2d6cbff41cdcfc547a89931c717',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.89086020138844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.89,
  },
  {
    address: '0xea05f980e02fede57ed7b2b366a91a9717b32be1',
    minters_reward: 0,
    lp_providers_reward: 38.87,
    swapers_reward: 0.017766432498931832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.89,
  },
  {
    address: '0x69d983efbf917a7b2c6971513f90afc36d175d78',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.80540976826749,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.81,
  },
  {
    address: '0x041394a55f0e54b490b32ca32487822c8924067e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.64064188062217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.64,
  },
  {
    address: '0x3caeecde10b871da4b18708945896b4f0c8d7c4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.59482100170575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.59,
  },
  {
    address: '0xcef5668b0178f41ddd65bd30075b1f8e2eb3e06a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.5886576957114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.59,
  },
  {
    address: '0xebf1747a65172d2889675019b097722eac9464ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.5786171090828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.58,
  },
  {
    address: '0x3e4ed880c9658d1d02fc252f347584402534d651',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.51548896220012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.52,
  },
  {
    address: '0xad6e772e43bea53213f91ffbd7b5eae171af9c6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.5121844880912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.51,
  },
  {
    address: '0x91bdce1dab8cf15a42bcf53648fc89c9b175ed20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.48313235429835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.48,
  },
  {
    address: '0x3c2490b21af11908a3c896420f87b1ab203e7d15',
    minters_reward: 0,
    lp_providers_reward: 20.74,
    swapers_reward: 17.734069140225348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.47,
  },
  {
    address: '0x9d80ab3450f03b1455594dc8bc3c8a85c3f19aa4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.457172642649844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.46,
  },
  {
    address: '0xc2d3fa550c1052e42b36dd199bb483ae38e3ebf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.45646411427068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.46,
  },
  {
    address: '0x011a53ea78a8675ed9080a29033b85addcebdaa5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.44828140793312,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.45,
  },
  {
    address: '0xc1b0d55038c8991960b0cdd5f08dff84ebf60d92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.42255234252461,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.42,
  },
  {
    address: '0x3f7957dcdcfaba4794768864156707fda1e26303',
    minters_reward: 0,
    lp_providers_reward: 24.13,
    swapers_reward: 14.287937387306279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.42,
  },
  {
    address: '0x8906c6fa8850f248c4e68d5799091a36aa3fa24d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.326342628911064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.33,
  },
  {
    address: '0x692b480aca05c47d27f0d22e82ab6e1fb6a91665',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.25211712001459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.25,
  },
  {
    address: '0x33118af30e31efdba9a062365f8807b490e7c806',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.23722715265957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.24,
  },
  {
    address: '0xc4ff4fdaad14244bbbc888f530ab14c0368de9a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.20715227124964,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.21,
  },
  {
    address: '0xcc4cbd591d59a83079cd4314c993002754b641a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.14630536626202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.15,
  },
  {
    address: '0x78186230e105b29da740c0367ccf9eb2bd168eb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.11620977580613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.12,
  },
  {
    address: '0x730c2b93b7ecaea985bece210d1b3d6d1e07143f',
    minters_reward: 0,
    lp_providers_reward: 7.21,
    swapers_reward: 30.910181259353017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.12,
  },
  {
    address: '0xcc90764aa53365f1bd94dd7b492ca7ed4afa1552',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.06985192995485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.07,
  },
  {
    address: '0x3facdde5e71c7f9544f554ddfdbd36573fc2ace0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 38.04914329247807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.05,
  },
  {
    address: '0x27360752c63564928f114d09a73eeb4e2c597372',
    minters_reward: 0,
    lp_providers_reward: 1.4,
    swapers_reward: 36.62324469628962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.02,
  },
  {
    address: '0x607a1bac2cddfd5a97be906b0db4641e611b39b8',
    minters_reward: 0,
    lp_providers_reward: 31.9,
    swapers_reward: 6.120375996087595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 38.02,
  },
  {
    address: '0xe45117b8da6a243d18964a3f83bed6d87e06d41b',
    minters_reward: 0,
    lp_providers_reward: 22.64,
    swapers_reward: 15.326708490615898,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.97,
  },
  {
    address: '0x020e1783df2b0f50d848e5ba46ec96983f765d01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.958179970662215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.96,
  },
  {
    address: '0x6d3da23c592367934c3c1a20901a5d62c0755536',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.91223374418703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.91,
  },
  {
    address: '0x39e4192530368d3e1462d18445811b575e21d582',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.88701424879575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.89,
  },
  {
    address: '0xa516d8024591c306744291d7458aaf2d90598b51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.87602374774711,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.88,
  },
  {
    address: '0x4c842522ca8ad9189bfd043b50dea6ddd8eeab45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.78648329629886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.79,
  },
  {
    address: '0xe155b19c385d7a5e2e5027e4dd9156d45bd023e0',
    minters_reward: 0,
    lp_providers_reward: 37.78,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.78,
  },
  {
    address: '0x3993c658180e0c177ea458fb0e9c53de2fe37863',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.67502336841443,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.68,
  },
  {
    address: '0xfab70fd177f8f57d1b001ba4ae7ceadae21e8f01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.6029843198597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.6,
  },
  {
    address: '0x87ca3835cca1448709876d5029d4fdae93d86483',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.54887879116872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.55,
  },
  {
    address: '0x24ac370b394d9a73bb641873ac9fa8a9e233a6cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.46802405928956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.47,
  },
  {
    address: '0x64ab8ba6e1484dd21b282103cd41e8d9347123c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.42626428389413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.43,
  },
  {
    address: '0xaf1e60a869ba0fda4d2abf130789413c68ba35d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.382690500854316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.38,
  },
  {
    address: '0x07f2fb2cb6aa6ab8a965dd29f8bc1251bc4654f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.367407017337534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.37,
  },
  {
    address: '0x9b498c08515035679868f22f8c45e6cb6ea6efa2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.359506340396344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.36,
  },
  {
    address: '0x4b084583ed9f6cdfe4768bbe5a2b2199088c7873',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.341916125048954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.34,
  },
  {
    address: '0xd7b52e06729bd5fdc90a298af3947afcde4ccf26',
    minters_reward: 0,
    lp_providers_reward: 37.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.34,
  },
  {
    address: '0xa8715a69b34e24c27cb4dcd34a536a6341582581',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.27535096566944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.28,
  },
  {
    address: '0x43052db4eda22e9f6b9aec12e6fd27d4527f8c4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.162573026836945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.16,
  },
  {
    address: '0x9cf8bffdd4ce18a5bfecec919d5ba602baf74257',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 37.00766720715482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 37.01,
  },
  {
    address: '0xbd28a0a8945384bc481ce83de25b646ab200fb73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.78957595830219,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.79,
  },
  {
    address: '0xaf355d42e8ec6d38a45ede7a3d8cfa80b86676d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.77944929129062,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.78,
  },
  {
    address: '0x0d71488e58bc9a45b072223820a05425e6c3ffdc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.76185542040919,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.76,
  },
  {
    address: '0x3f6a3a637e39d2fbd946e2ad16a218babf840ff6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.68809481605432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.69,
  },
  {
    address: '0xcc920e7e20e4f2dfe450599cdc1e75cfcefdb454',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.57543575410705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.58,
  },
  {
    address: '0x63dec41642f00c1199a92ec951bc3deab8bda55b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.569506147021045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.57,
  },
  {
    address: '0xd395db18a3ac948d5a38fdaf88314db2124b70a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.56255092210742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.56,
  },
  {
    address: '0xf54d1696d2e047c17c9212a20946d998b1c15a0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.520195963906744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.52,
  },
  {
    address: '0x1c9658e18de3b2a49cd2b26fb2655c2f4088aec4',
    minters_reward: 0,
    lp_providers_reward: 36.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.42,
  },
  {
    address: '0x65aa213160e9f6f5eeed25def9e1ef923e2497ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.388943890105246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.39,
  },
  {
    address: '0x2bd9db9b0235d945097018ed37585186f1a95161',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.35285135061136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.35,
  },
  {
    address: '0x5716e8fdcd8be70d3f4c6a9f40f5846ece05ad87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.28452945828682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.28,
  },
  {
    address: '0xbdce944bd44bab5042296f2f5a1e362514ff337c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.21920869053107,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.22,
  },
  {
    address: '0x22e1d5fe74b733c6795397476bd2b35e43391dde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 36.099118157628276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 36.1,
  },
  {
    address: '0xeb8a424eed512857037d91f69db5c282ebbc181e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.77587414822275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.78,
  },
  {
    address: '0x0fada9436828e66c73460819dc7dbb43d59a3bb0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.76728801947952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.77,
  },
  {
    address: '0x54ce1bc21db656a8968ca13fadfb0c526686a761',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.65582648099022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.66,
  },
  {
    address: '0x0dbefa011640c2d51359e830b92158b4e33ec1ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.49872211750707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.5,
  },
  {
    address: '0x0864052dcfda43033cc2129daded95a4824374e1',
    minters_reward: 0,
    lp_providers_reward: 35.5,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.5,
  },
  {
    address: '0x46a97149e3d14a7876e26017cee888cc008c22d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.46355343728535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.46,
  },
  {
    address: '0x3f21f81ec027599ef3ab6225e105267b979e0939',
    minters_reward: 0,
    lp_providers_reward: 35.46,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.46,
  },
  {
    address: '0x9d6ba8738dd587114c895ecd40623fc319e1bb99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.41735573240163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.42,
  },
  {
    address: '0x56113e1a3db81bee43928f68f540b1a18c836e60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.334986708598414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.33,
  },
  {
    address: '0x1ae322fa297644ef7499502fec1a06e18b2b04bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.31145080933246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.31,
  },
  {
    address: '0x01f73dde03932714854b1bd0b5752722b52f0b3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.30148048400242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.3,
  },
  {
    address: '0x041b71228d496036f13f1b9a19e35f8f3d4ef5c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.2826832650629,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.28,
  },
  {
    address: '0x597d47b1e90c66589d2a975ce66fe1914a43838e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.24217321076445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.24,
  },
  {
    address: '0xf76d57b1efbc9c5d6c3ffe5466f5a5e7a3c6dd34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.14513250803718,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.15,
  },
  {
    address: '0x7ad5718ace87bf1be2ed47be0edad0a69643e309',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.12490876927122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.12,
  },
  {
    address: '0xe204bd3c8d426ad153dde35fe152ee49633c294e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.101581180204846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.1,
  },
  {
    address: '0xc2fea627cb59fb714277d2240ffefb67770ba9e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.0544171955378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.05,
  },
  {
    address: '0x42f537bb3a95845c18265a62c5ede19fdf8784a5',
    minters_reward: 0,
    lp_providers_reward: 14.91,
    swapers_reward: 20.107240638471925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35.02,
  },
  {
    address: '0x2b1c48eb05d7d96dd0ea1c0f484207fa26f58f9e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 35.00002815637364,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 35,
  },
  {
    address: '0xd9da41ae27cf2a8e36511bdbb340bd0cf0eb527f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.91640161282354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.92,
  },
  {
    address: '0xbc740227c292043f5721118c54b1dab2bb3de899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.87384203559085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.87,
  },
  {
    address: '0x018d3f14c745c37510f87130c960b9b7bd980cea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.862505645174984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.86,
  },
  {
    address: '0x6ef5268f3ab783d97e14865516a2cf9abafc223a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.79666076426565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.8,
  },
  {
    address: '0x0e0806b03f7a60bf706a8421323fe6122b2fadf6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.72151590695816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.72,
  },
  {
    address: '0xa1671c8c02e68a1b7c8a8b49783cafcf3d2ddc97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.712790932184625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.71,
  },
  {
    address: '0xbfd6c6a9da1032fe84a50f1e45360b802069b0a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.616341952346716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.62,
  },
  {
    address: '0x6ffead202823851b92c3ba3336e15af29ee44213',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.583389642114426,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.58,
  },
  {
    address: '0xcaf2eae018462d5c990676a4ed38bf59f9c64c52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.52410345398693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.52,
  },
  {
    address: '0x4e637ce5eb8a0d626e2f0ff1b72b831e4ad3a7bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.387523972822464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.39,
  },
  {
    address: '0x86e65b6c6117321f451c59ac9501eafd73565382',
    minters_reward: 0,
    lp_providers_reward: 6.74,
    swapers_reward: 27.594548565138137,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.33,
  },
  {
    address: '0x76fb6078dc2537713aa1c3466120b39bbe082798',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.30069943301799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.3,
  },
  {
    address: '0x6e1355c3b0b55dc8a0c9d7d2b15d671f8f32eee5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.282664802596585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.28,
  },
  {
    address: '0x6044043bc5ae21f9d62fb0ea5c12ba92ee891543',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.2468967856199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.25,
  },
  {
    address: '0xfc254d918d50d849833f42075ffa646e64fe9afe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.230516172818604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.23,
  },
  {
    address: '0x2a7534b3d9260dc71f884e54bdfdcdedb44e2eaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.19077428309658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.19,
  },
  {
    address: '0x9f140445def1180c0d172ffb66715a970d828200',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.14302302068263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.14,
  },
  {
    address: '0xe5a533bc565cd0ded43e0c0e6001ac85fc2fbe6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.0712655555668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.07,
  },
  {
    address: '0x31879cc9bf075a62c7c4665cd02f1d2c9df5f823',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xbca2049f25cde47d61cb8a2b2484ef6672ad8dec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xcfc7a6236d03a5bc790456d0d14193d9e7e58c3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xf428aa69ce244c0a82a5e08587f34e38957adb86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0x9d853d6fcb05720afcaccf7e9d81118abd0c02d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 34.02975432530346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 34.03,
  },
  {
    address: '0xe8545bffa909cc1a7f5c7fcab410523d0d1cdc45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.94446191042118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.94,
  },
  {
    address: '0xf177cd8ad744bca4b0de652a82340c17e4d29ee0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.82614764106554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.83,
  },
  {
    address: '0xe518ffa7309491c5f5c947fd52011771c36ab146',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.67853759829733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.68,
  },
  {
    address: '0xfb48076ec5726fe0865e7c91ef0e4077a5040c7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.676142286852034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.68,
  },
  {
    address: '0x96aa982c950573c39744eeb1344c04f061028fc6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.675825443386465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.68,
  },
  {
    address: '0x5586735b5bcca3ee29934d41336aebb18b48350c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.663893138733876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.66,
  },
  {
    address: '0xfdb312a678fe2cbcdfa4ea473aa03e4435407f42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.50323729082169,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.5,
  },
  {
    address: '0x77561dbf31e98562d7701e4c6cbf0eb61124132e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.447081469246925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.45,
  },
  {
    address: '0x91952e96faeb1e53a0c4b10eedc7a65e32926a6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.442097806329485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.44,
  },
  {
    address: '0x5acd29efdac786d4f5d9797158e42d2574f0d76e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.43479456431676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.43,
  },
  {
    address: '0x8d52dd9fd1856726deb2fee2cd5331069aee9c87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.409054544287294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.41,
  },
  {
    address: '0x49c5b4c4509fdf17034147540f5f176bc0830eeb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.37930670367477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.38,
  },
  {
    address: '0x23223534f7851c67a9bca0cbcca6d299890bb775',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.369236148536025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.37,
  },
  {
    address: '0xfe6436d7e01915d783ab177833ac06fe0151bdfb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.34482448232164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.34,
  },
  {
    address: '0x893a5c01e7bba4526268d39621a6cf7a91933824',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.32493747361288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.32,
  },
  {
    address: '0x9f1922a36178b3509dde41d5b0a12ee9c3ca8261',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.218367240478564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.22,
  },
  {
    address: '0xda0e806d4fef18bb893740a92ac777d1c8c4dee8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.15031202300892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.15,
  },
  {
    address: '0xbeea6fac12378171078a33c4f61869aa21a911a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 33.014974575401396,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 33.01,
  },
  {
    address: '0xecb34796bdbe3921443e8838f74cc48b38648c8b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.98368222319835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.98,
  },
  {
    address: '0xcc622d0b3c08ef8d74cc2fb8db68045bcbcf8877',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.883395907333444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.88,
  },
  {
    address: '0x31c4f9c7000fd3f40a280e64091a257db22a7d3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.806220435457135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.81,
  },
  {
    address: '0xf56822fd9b08dc3caaecce29c09ba3b85ad832f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.776318258639726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.78,
  },
  {
    address: '0x478a3bd07a620e89e06b127703219a888bdc9216',
    minters_reward: 0,
    lp_providers_reward: 19.89,
    swapers_reward: 12.865202142917559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.76,
  },
  {
    address: '0x839b46b4cad8e57380c47a36a26d132b9c8e39b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.63828730587081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.64,
  },
  {
    address: '0x3b3e4bc78b1ce1d8e7697534c982f8b981c32aec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.625257517508736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.63,
  },
  {
    address: '0x6bc4cd5e4e9df85038b0ac3925bb22a66941d6f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.60026343577911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.6,
  },
  {
    address: '0x59a01dc6709dbe544b54cb299e50610ec4dda705',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.565498058105206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.57,
  },
  {
    address: '0xd82a3540fefebd36c4996f1f62bac065c0231abb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.5586843984663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.56,
  },
  {
    address: '0x224fa96e58239744b4b5ed22536ba1d7ffee24d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.516637550666175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.52,
  },
  {
    address: '0xf390b8c873150f7e4a687d09e9c363e91c0a787f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.49589805346555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.5,
  },
  {
    address: '0xdf24530e05bf65b2305ced15fd36dac09ee7f840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.48543137510725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.49,
  },
  {
    address: '0x7b9a24aee10cc23af28a8e1009c5636e44da2b6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.48199153959517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.48,
  },
  {
    address: '0x18a16e64456b4cf586be2bda3030b81529f5b455',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.47088090855572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.47,
  },
  {
    address: '0x0b43642138f591342ded8681c52003ce68f8cb7a',
    minters_reward: 0,
    lp_providers_reward: 32.42,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.42,
  },
  {
    address: '0xef63ba8059c7f322d18c96f34671f4e0e3c80b05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.396861412355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.4,
  },
  {
    address: '0x4dc245f5ae2a84e25318e06abb2bd5f0eeca57b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.33676937569475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.34,
  },
  {
    address: '0x8617bbdd536cf7caa60c3b143bda74de6087fcc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.32744282454172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.33,
  },
  {
    address: '0xa734407cc41102bf652bf7f669de955c0dc168dc',
    minters_reward: 0,
    lp_providers_reward: 11.06,
    swapers_reward: 21.194713483082943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.25,
  },
  {
    address: '0x7580202d84c0687e89f7427e8b1f3bacd3173b26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.242882672101075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.24,
  },
  {
    address: '0x158beab07a152424194d6e4e7fe00ef105f46706',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.215224612955716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.22,
  },
  {
    address: '0x3760e45b49ca00f2dffbd835801d8b33e22b8c63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.07560792111203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.08,
  },
  {
    address: '0x7e682f89a72390cebcc2c5a5020ecd389752ca75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.063035282092514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.06,
  },
  {
    address: '0x3afaff498ef2f54cd82220f65ba8927b33d19619',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 32.054145772132536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 32.05,
  },
  {
    address: '0xd2533981322e39f07ffddac5e24fc80f1ff60f9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.988576150141665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.99,
  },
  {
    address: '0xf5cdd1d98041ef766afbf1f17536e85176c5fcec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.916059059853676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.92,
  },
  {
    address: '0xd5aabd3425b88a6ed7ebedf66877385a20d6fdaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.709108236764425,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.71,
  },
  {
    address: '0x322e69d4ef69009e791828450f3574eb595d4c43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.698881888298583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.7,
  },
  {
    address: '0xe221180576aa706574fc37bcb162118151a2457f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.604773481674012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.6,
  },
  {
    address: '0x9042dd571baba4a778e5ffcd809fc95ab179e2da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.59650153543822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.6,
  },
  {
    address: '0xb6b0d23660e9ba8bf3afed52709825c908288573',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.588874529283355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.59,
  },
  {
    address: '0xed78af9bf9a82823511429de2a760567a06bf320',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.57055970795623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.57,
  },
  {
    address: '0x634a2f9b3e1f65ee7bfc2fd4dadc3784be764ee9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.470229801505365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.47,
  },
  {
    address: '0xf26a260ae8097b2d6eb6213b77ed631db13286a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.364027819767212,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.36,
  },
  {
    address: '0x7e9793932c531e0c1fa0c90930c5b98c971845fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.280268196096593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.28,
  },
  {
    address: '0x3653bf7b606972640a6d3401c16cb9fcf335f1f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.274088223027945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.27,
  },
  {
    address: '0xeea850728e58fe4b277fbce1babda9ccbde07a4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.249890719071768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.25,
  },
  {
    address: '0x6ac54b36e2d09478d3a62f960ba002def8aebef1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.21210026171773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.21,
  },
  {
    address: '0xd43c5ec7d644a23eb3e9a99b9290ab3eba2d9840',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.152242084045263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.15,
  },
  {
    address: '0xf2d0e32f272dec038d5652a84a9ac5cccbb1fe19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.118551658128343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.12,
  },
  {
    address: '0xeadcfb7355c854998dd6135c165ec40bf8f86469',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 31.018153354957136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 31.02,
  },
  {
    address: '0xe54367833773619345cda4004851ab485a8c008f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.987744662585747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.99,
  },
  {
    address: '0x4aa2a436c372850730cd9f12eceb4e411daefe70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.97615190943031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.98,
  },
  {
    address: '0x4f40e1a5f70cfbc457c1139e0cc6463244d9a6f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.95490766724032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.95,
  },
  {
    address: '0x04c69bc666e6aa720b36c29add05f643d1260b85',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.94051699048907,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.94,
  },
  {
    address: '0x18833279f8d6c7fc17fadadef31c3c3c166a8049',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.91902659120607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.92,
  },
  {
    address: '0x1e1915897fcf17620ff67282bfc732c3dd726dce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.918892061441863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.92,
  },
  {
    address: '0x03dd6152d73e00efcd29db84934649fc6de4a9a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.84237554245577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.84,
  },
  {
    address: '0x1592dddc9358ac5648422c058c7b547358b70b0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.800683590718712,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.8,
  },
  {
    address: '0xafef2fe5fd64d46fe21bc3e20ed2436366298deb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.79595946878196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.8,
  },
  {
    address: '0xbca0c799abb62bb7e2d8eb13fe0b66b697a23296',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.717648445953788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.72,
  },
  {
    address: '0x60eec6de164085d41b2cabc5e8a15244b3fa152b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.704466707593895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.7,
  },
  {
    address: '0xb95b8127bea66944a874b7a2dec16b735dd1acbb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.630838533355394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.63,
  },
  {
    address: '0x11c9254f38464d59723ef94b70d55794d6e71f3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.583014698576932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.58,
  },
  {
    address: '0xcbb769bcb29a68874b97becd826c9bbe471a7fb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.542598453947214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.54,
  },
  {
    address: '0xd5204a495a54451ff5f4cbff011ce57ffdd740e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.49188002936507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.49,
  },
  {
    address: '0x3686d325a90ff374cb3a42cc4b0a599fec1634f5',
    minters_reward: 0,
    lp_providers_reward: 30.49,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.49,
  },
  {
    address: '0xc88f9b820ed5e713ade666ba02928593a7a6ad2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.484627883691065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.48,
  },
  {
    address: '0x4aa54e98f545abcb9ebb55b53cab8f6c7b498b5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.477207089121798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.48,
  },
  {
    address: '0x7ea0d3d9779fdd842c95aefe079ac099735151d3',
    minters_reward: 0,
    lp_providers_reward: 30.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.48,
  },
  {
    address: '0x1fb44316d2b85cad5125ee116227f4ce86aec39c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.44416722665787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.44,
  },
  {
    address: '0x2fce9eb441f50b5c34a682afd474c67b8f15036b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.437974054560602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.44,
  },
  {
    address: '0x09ca26244d9f41297c33f0e4a1d9699f916878df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.3890275900895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.39,
  },
  {
    address: '0x9b6bd0c85e7736c1c834f932c1d9300c7c21f62b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.35872728074814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.36,
  },
  {
    address: '0xc372c4a596f45abdc4db19cde206ec35864ab133',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.347728610567497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.35,
  },
  {
    address: '0x277dcd74b00fa3897fa30454fecf098a0f148f8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.319691026443902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.32,
  },
  {
    address: '0xaaf842ea6cee3fe937c1e84e59d061acf98929be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.290677254836194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.29,
  },
  {
    address: '0x36af1c5515eaf65ae27d85c5b3d135c51ae8d48e',
    minters_reward: 0,
    lp_providers_reward: 25.61,
    swapers_reward: 4.683695764736418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.29,
  },
  {
    address: '0x746d8bfbb7fc4ee1cee35a92c6e6355ccaf51664',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.253783273570022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.25,
  },
  {
    address: '0xd8326b61f0b48a13b60c8008c26bd04c712d846b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 30.198922702015228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.2,
  },
  {
    address: '0xefdea5de24669fd4d9da3b0c2f98bdcb456f8af5',
    minters_reward: 0,
    lp_providers_reward: 30.19,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 30.19,
  },
  {
    address: '0xaa768f98269ddc4bdaebda0e8068acce9484bbb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.992838798482627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.99,
  },
  {
    address: '0x489f82894bd820f375e3687231f93e29cd975b5f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.97433365951757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.97,
  },
  {
    address: '0x3c1ad8e4aa7b5a37616255801a88cb42181e58ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.921311578759912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.92,
  },
  {
    address: '0xda4229d857397a13e8af17a90841c7bb880b7c7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.884800230742883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.88,
  },
  {
    address: '0xa0517d7a5d937ba242be8ddc8f3bf56a34a86979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.850420114749042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.85,
  },
  {
    address: '0xaee0b6f79e9c90e16e2f396ec8b602d133742857',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.832910433144757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.83,
  },
  {
    address: '0xe99347c2c966771dfe8b99bc607df6904ff45e8e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.820280772535558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.82,
  },
  {
    address: '0xce70a2357ccaef8d038c1620e64cb442c45f100e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.810042382250245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.81,
  },
  {
    address: '0xb9822c48897befb553fa4593712f4c50622d1bee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.790766189139813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.79,
  },
  {
    address: '0x051c4eb4417e7ab9262d55d4a36980e1a7c4c020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.781703290879232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.78,
  },
  {
    address: '0x7f9cd47e2251b9093494d18d2ebdb80c7cab2393',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.714331850393894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.71,
  },
  {
    address: '0xc62213eedf2c9fd67355709230d12c304c54e22a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.60613376572385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.61,
  },
  {
    address: '0xd7fe34d9faf036518537291b1b9fdc228282b916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.60613376572385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.61,
  },
  {
    address: '0xe7a13027c6661dc07ea52b6b6e9cfab4a17fce88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.60613376572385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.61,
  },
  {
    address: '0xa6f9d8296d21a6d13904a62134a25aaa102d7b16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.550366784798307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.55,
  },
  {
    address: '0xfd784f1c588106583b3fe34c49faf90118718bcb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.479190709231577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.48,
  },
  {
    address: '0x89e2a4d8d52a13ec6c7522e7ee32faacf5aae554',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.47374182785431,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.47,
  },
  {
    address: '0x4f4181bbbb4845104301ff6237ba09c3389702ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.46423950840585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.46,
  },
  {
    address: '0x69a411647aaaea9722e5a5ee9a6750c21f1e8d14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.44460658399592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.44,
  },
  {
    address: '0x1356797e2ff9261a6c4f307ed2b63c692943ee8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.436839317104713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.44,
  },
  {
    address: '0x410ed13649886a7489b756bbfd9bd531adbe44f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.408959336315974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.41,
  },
  {
    address: '0x7163c4112d72dee31b99440e4d2dde504b09cde4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.401250293386482,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0x766afa3dec5eafb279e461f51e0399db3e926f26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.400296202754355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0xd5a6280c960abe1111e57562cb5e114d276a8207',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.395367425700798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0x5e3b72c389f6d8a9047c6913bdf768f268928cdc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.395367425700798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.4,
  },
  {
    address: '0x442fe6e9fb0ddbfb604061a1de6d9a16bcb3753f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.369938462485525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.37,
  },
  {
    address: '0xc452b46326756d3a573306daf25e03a625e36fb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.367565675761778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.37,
  },
  {
    address: '0xc4e29f3c265446a6d8315043e705ed504d64199a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.30974634529763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.31,
  },
  {
    address: '0xb0e216826d098de32139d1c9ec4f9f2288ca4a2f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.302609316969928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.3,
  },
  {
    address: '0x012bb4bf03b526298140cc4039a1d6c8a995c226',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.293888266771667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.29,
  },
  {
    address: '0x1704557c1d8ee7d4fa7471ba2b6af1858d57a29a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.25990456024104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.26,
  },
  {
    address: '0xbe65395f8cae7f28092fc70095d90589b83fab02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.250155685818864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.25,
  },
  {
    address: '0xa2ad002d27de571797b855b26c7c3f8cacd31f34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.194770196470515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.19,
  },
  {
    address: '0xdee1715c5bd7b6670ec74c01231251d887b17771',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.18829638744241,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.19,
  },
  {
    address: '0xb114344c7d88a2c6697ce5b8f38b3f92d7d120b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.17863446901475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.18,
  },
  {
    address: '0x532d72c19bee7f686675bbac5477b8413247d818',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.156784065604334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.16,
  },
  {
    address: '0x0472a45ebb948c50a38037e4e6676ee613e959ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.12081270678091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.12,
  },
  {
    address: '0xfa73d1a2d2da46ef1ac12141b8b9f564a301d59f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.112542526444788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.11,
  },
  {
    address: '0x696ca5906b0b56b2476bf002f2f0fa92df3e3781',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.048043048067953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.05,
  },
  {
    address: '0xfcfcf8a8c0cd840f13734d862c300d42a1ef198c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.045415997549778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.05,
  },
  {
    address: '0xbb3a91b8a3fda620f8a623078644004e403d82b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.013003381296716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29.01,
  },
  {
    address: '0xb8f760be906b84d53f21b81d50a60efa626d2e2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 29.002100144882462,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 29,
  },
  {
    address: '0x424e0502796137574c2cd5a6f78e33926d2b650e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.96059487503983,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.96,
  },
  {
    address: '0xf9091f36af2f0b68e5b6fffe479335ed67739814',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.95411822664175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.95,
  },
  {
    address: '0x0827204394c018c9f4e399e9c2f1ef4bf8b21c12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.93775999651192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.94,
  },
  {
    address: '0x06fb387e5dc337a2630dae852fcb2d2ae8ddb287',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.912952802733866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.91,
  },
  {
    address: '0x0dad7b2e72122fb6a4e27b604962f50a01baf462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.89695803669467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.9,
  },
  {
    address: '0x4de6736c9dd006b25c0823e736d8e504287bf0cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.865489852729866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.87,
  },
  {
    address: '0xd233d640d31e11fd7f9859b18e7448071fe4c619',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.827403545583547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.83,
  },
  {
    address: '0x6503ae7778d2256ce93cd2e5bc938fdcc17e7a7a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.825366409505605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.83,
  },
  {
    address: '0x54b57acd7993283b060806ca3617b4d2677d25bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.799363474376985,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.8,
  },
  {
    address: '0x58ce86c6ccc0a47129480d8a0d77ee945298a47b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.79825549291034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.8,
  },
  {
    address: '0x3579594b316531dcf79eae43733c94760acd5a5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.788864692194405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.79,
  },
  {
    address: '0x2fce3035c5afe5750aafd8bb909380e513051298',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.773129014992783,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.77,
  },
  {
    address: '0xcecbb4903c7712bce0155dfb8ddb06d18fc74699',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.72327954140613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.72,
  },
  {
    address: '0x360a66f407fa40b8dc15b508496243f72162bbfd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.71398477793507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.71,
  },
  {
    address: '0x62c4a598547f66344f590d249a0a5a2bb3ffb2e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.69279727719853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.69,
  },
  {
    address: '0x6e38bbda5973e507bcee3db5a91164e7f162980b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.62925706557176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.63,
  },
  {
    address: '0x37250b9d07151f84915980fcfb0a5f63589beeb6',
    minters_reward: 0,
    lp_providers_reward: 3.44,
    swapers_reward: 25.180813938938588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.62,
  },
  {
    address: '0x1a31ebffd2a22b31d9cc24a7f26d86fb22428647',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.61333282962192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.61,
  },
  {
    address: '0x1dbcf1bbed221001ad2c69d0ceccfdc9d08deab8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.593588668751924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0xb99c4b4edc5196a33da21a38f0ae0e76ad0293bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.593063435855154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x7133013448d7db983e4712d1805330baa51aa4e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x16c6c6daf129d69845d7ca7eeef600a524e43999',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x49b0f8890ebfcdd6b8397eff545e9483524d13f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0x27d5ed8c0b749729788b694a8df0193ebdc266ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.589298854428872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0xbf32beb1bc33175417471e4c0437da2999434a99',
    minters_reward: 0,
    lp_providers_reward: 28.48,
    swapers_reward: 0.10543238147894969,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.59,
  },
  {
    address: '0xa4390f6414a37660d4d461584a47a0455923ccf6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.57409781028625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.57,
  },
  {
    address: '0x3d1038ce20bf68dadd4c0ffe4f767fbb5d185bc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.521308095209687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.52,
  },
  {
    address: '0x1e49a5f14c0ebf2b5c67ac12cfdc16b582ee722f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.517508138254808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.52,
  },
  {
    address: '0xe744dca114a3d590954bc8d61725f979cb8113e8',
    minters_reward: 0,
    lp_providers_reward: 28.51,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.51,
  },
  {
    address: '0xe5a601d8654de42755ce5ee91703e0df6cea9365',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.46812866322788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.47,
  },
  {
    address: '0xc2c11f9df3e7c0e5ea42ee0eebf7f8a9f86c094f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.456924526100373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.46,
  },
  {
    address: '0x20f4858c446a5a80539e04477246615fec76bbe9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.45589045456356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.46,
  },
  {
    address: '0x7f38129387f522a0b5227f9c5c6aca5e58e25dd0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.419452836908725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.42,
  },
  {
    address: '0xf178288605d04c33799d50e211bf1dbaa6a85eaa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.400084786592277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.4,
  },
  {
    address: '0x463f06b718772d38a3b22b48a779ae1b3cd481e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.3659760552994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.37,
  },
  {
    address: '0x75d72cc8a1ad52b50cad1ea6294fcf609ee7e7bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.353953330443787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.35,
  },
  {
    address: '0x7f04db148b1e44c150d7b47ce92146626c0ed890',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.318698050578583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.32,
  },
  {
    address: '0xa15d5a4e006d7b19614ae9b5c08c9c3fbccf3654',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.300408913553934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.3,
  },
  {
    address: '0x7170bebabfa194820d4569423ed88e1067cbc8a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.26092393507788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.26,
  },
  {
    address: '0xc8665b8a1c5ea52d2576432d5e79e4d9b3ab4fdf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.128054873868965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.13,
  },
  {
    address: '0x256525c008b098304e5f8ed2b5573688a2ffc05d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.117886438979088,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.12,
  },
  {
    address: '0xd49970ecd77f79e240ed3e078296a40e638f3166',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 28.096262752625595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28.1,
  },
  {
    address: '0x226ae923715fba1702e7e3a11e5fff10b971ae31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.99745527038313,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 28,
  },
  {
    address: '0x6c21cdc71766c5715d04a2e6df6f240e76d23e53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.993275936148944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.99,
  },
  {
    address: '0xf587ba70fe86bf3c48fcb3f2be8724659a26adc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.986240887752114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.99,
  },
  {
    address: '0xe25a82e6d7a347f93ad1e9c688ff08cc718268dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.98130200935519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.98,
  },
  {
    address: '0x6df95c1f5add9ce98a013ad9809782d10272c6b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.979417273186563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.98,
  },
  {
    address: '0x96af22cbc1693e2bb8fb0eba10dc665b4e5b3092',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.97614581148802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.98,
  },
  {
    address: '0xcff69866f2d63ff28a4ac920de1b32e7da23ce71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.97306627579675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.97,
  },
  {
    address: '0x13e2d48907cfb0e99a9b1e9f859dcad99ef340a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.97306627579675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.97,
  },
  {
    address: '0xbcbbebf1a391462751d346e50d86c217b33f88b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.96295184253376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.96,
  },
  {
    address: '0x4acf79c753cee0b3416a084b0d0f40987c8cd42b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.916800165803615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.92,
  },
  {
    address: '0xc9296c14a59a239a49815052ecbc662f81fb75af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.87716859540122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.88,
  },
  {
    address: '0x74b868564f4185e1c3ca83e9d848f7092818dc17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.85135254857493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.85,
  },
  {
    address: '0xb7b47df7291f285d11e22396a14f1008f08929cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.839102507234117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0xce43c2dc32eb7e03e305a27d6976380881881e4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.838772686671657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0x9882b09cd65d30a80cf5ba70fb2ee4bf04958fe9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.83740153813294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0x9c2cd203b44852838351aaf15fdbfcfcc151a4dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.835816302111024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.84,
  },
  {
    address: '0xc35d4e79a139c2f8f6f11af5d571ff68bf925b3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.831644650508572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.83,
  },
  {
    address: '0x3e083f453213e7f6a6ec318b256fab67d82ea971',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.827412764980725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.83,
  },
  {
    address: '0x7698f70fc6c1d735a168b56a99548ba03f518c6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.82293488527986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.82,
  },
  {
    address: '0x4a8e19032a8aa4366b6219b5b66a8a3ac97d1185',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.765457976982614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.77,
  },
  {
    address: '0x3765489a593a0d31a479b5cb83001b33ac9b416b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.749890545997907,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.75,
  },
  {
    address: '0x9eda07d7da1503e76f873ac77fcfdfd0c9b5bbe6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.7326456696053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.73,
  },
  {
    address: '0x0db2ff0223cf3058d3b6a43db6ecd59ad0572323',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.702253022086737,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.7,
  },
  {
    address: '0x8e8c833f9ca31bfb9d7df8d2deef3f4af2c11d87',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.646393368433916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.65,
  },
  {
    address: '0x2bd6c55a17048085823a79b658b1e2ba581cc956',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.58852026052392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.59,
  },
  {
    address: '0x50c7c0ab882247566da5a00aec268541cd5139ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.587458613578253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.59,
  },
  {
    address: '0xcf43fba250df30c8e92ea3d09d58fdecd20c859e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.53081931872348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.53,
  },
  {
    address: '0x31a73de8c617cb117d647479355821c834cf3374',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.47901890717591,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.48,
  },
  {
    address: '0xc40571b08eddbaed29c0f5761d20eb61eaccbd4e',
    minters_reward: 0,
    lp_providers_reward: 27.44,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.44,
  },
  {
    address: '0x689c23e06dea58275fb616a197285ba76a493731',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.416108234062243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.42,
  },
  {
    address: '0x65fe4f30e521318806c158636542c4f2940005c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.394629984159003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.39,
  },
  {
    address: '0x7abc86a0b0f1219cd68e5b00e688fda25447d1c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.38057016970169,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.38,
  },
  {
    address: '0x83f86369a994579476ab0318c2be061226c6c4b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.36956066927419,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.37,
  },
  {
    address: '0xc36fb3dc19045ca76cd20087e43b307d8fc9773d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.352729036283343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.35,
  },
  {
    address: '0xd5d4373052655adb506a20ffe5bd6a5cd8baf8f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.302086549694696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.3,
  },
  {
    address: '0x3433dde30a25a60b49172ceab310af2e6dfe945c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.25138443966789,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.25,
  },
  {
    address: '0x3126eab5b49af5d934b04768413c6c2dbf05e614',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.246410224606528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.25,
  },
  {
    address: '0xdfcb5f0eecbf1465977ae1b9000fecaccbda0ee0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.18251580247409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.18,
  },
  {
    address: '0xba9ff201adf2fc0427a60171725bc7b95ae4fbf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.16168124141668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.16,
  },
  {
    address: '0xf870c46082ad376665f84d83b427b0a8f9e7e338',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.08891657916117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.09,
  },
  {
    address: '0x4a62e1416820d34b810561572a6a794a7da2aa46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.088906160039365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.09,
  },
  {
    address: '0x910febb344b47da528a4ce7b7ad0711eab917a94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.088906160039365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.09,
  },
  {
    address: '0x823f24e336193e459c880827fff2f6087e64e043',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.060005416851084,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.06,
  },
  {
    address: '0x115dee3599becd0eb1eca30e13755768e5591c8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.0554649607472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.06,
  },
  {
    address: '0xca3d176a5231c24de1a69763883032411e81eb5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.052983231572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.05,
  },
  {
    address: '0x14301a619835586cfd81e76de4aca030cfd3b429',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.013257473231477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.01,
  },
  {
    address: '0x463d6e56a91caad2b41cb1b389486ae7751f459a',
    minters_reward: 0,
    lp_providers_reward: 6.65,
    swapers_reward: 20.36280601047361,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27.01,
  },
  {
    address: '0x3faf150b8d5f418fccad79ba605a8d4b6c0767d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 27.004152806502603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 27,
  },
  {
    address: '0xcadc4a3909a026c35b34305cbaee7b4320d8e093',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.96719331255258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.97,
  },
  {
    address: '0x10f6dfa68d3ff1f28dae1884dfb3a98eab41ba41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.933000428136445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.93,
  },
  {
    address: '0x2d5f217c91c1e274811bd84bb131329f31d22212',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.92933204999172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.93,
  },
  {
    address: '0x3e1596a814d70f4e63f7f1487c5807a6bb04b967',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.869516260105854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.87,
  },
  {
    address: '0x45d8b2ce35b218c6a47a85fa5fc0d3849f43d21b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.82900241361668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.83,
  },
  {
    address: '0x38439739cbbd1ec112333ef8abfe44013727a093',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.808344128080158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.81,
  },
  {
    address: '0xd30c973f0dc4dcbe8e0e689e23c1dd3eca2a0de3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.807946406369588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.81,
  },
  {
    address: '0x04ba93afc239fe80bc7b2d7e88cdd195b82e5ad8',
    minters_reward: 0,
    lp_providers_reward: 26.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.76,
  },
  {
    address: '0x088f0d80038720ad4244f0586b40f5b985d8381c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.74737919596647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.75,
  },
  {
    address: '0xbfac8003dcfd6542188d3e7f4303feea76158c93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.717161728380148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.72,
  },
  {
    address: '0xbcaf41b6f6784bcef117fc6640afaf34027cb9a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.605761960954073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.61,
  },
  {
    address: '0x506aa5c6e608f563d8d4c88b8fd82c5eea6cee28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.58368943043306,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.58,
  },
  {
    address: '0xb5cf51ad7a013fa95c3d176cbdff29bc039d4ec8',
    minters_reward: 0,
    lp_providers_reward: 26.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.56,
  },
  {
    address: '0xfabf58187434c217cceadb91ca7d8f0af4338a62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.511435779646515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.51,
  },
  {
    address: '0x819f44e8a20911894f349c307aef2eac1d7bad0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.457588869732355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.46,
  },
  {
    address: '0xc3f833abb857219304b9df45166cf3a99f355a9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.41312789845661,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.41,
  },
  {
    address: '0x1e7ec2a491a4f2d3a57d20a9f44dbc59d52a2ce0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.406766701631355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.41,
  },
  {
    address: '0x4cb50cff34aa2571d2e458dfe708419d3a1ff64a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.39185551231094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.39,
  },
  {
    address: '0xde072d7fde88d51bc091df67b28c1545952fcaf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.3738760759485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.37,
  },
  {
    address: '0x58c400bd861a625391b99ac1e60ddc82f971942b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.355730376746198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.36,
  },
  {
    address: '0x8332545194520a59460cffe2cb654b27e09ecd19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.290670492941715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.29,
  },
  {
    address: '0x2f31393aea9eb0b8f3c3995783c142bf97034f32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.26815486631258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.27,
  },
  {
    address: '0x49ffafbc9d1684a6f18fcc57ebd832d469f6bcd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.263531079862467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.26,
  },
  {
    address: '0xfc6c02b76b6a9b9cdcc37e3dca2198b345a56a61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.213769742023253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.21,
  },
  {
    address: '0x281a7a9b0ed0a4072bcd713916edda197dff194e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.198786992223194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.2,
  },
  {
    address: '0x47ac83df48b7253e69d13b2e89d8c0edb38af5ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.198345838301815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.2,
  },
  {
    address: '0x2db4bae24b3300586926c0862d1c229051fa80ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.16283575480706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.16,
  },
  {
    address: '0x0f993a4524634aae8a6cb3469aef917ca8dd1822',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.155809299032928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.16,
  },
  {
    address: '0xa8336349bff50d4475266c80bd412c99e107749d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.135885678631656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.14,
  },
  {
    address: '0xe7cf3b2350e5df65d9e43e15a458b05cf044b46b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.11699983023866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.12,
  },
  {
    address: '0xbcf100381272a7437aacefcdf8ff71fcb256e12d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.0604998510666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.06,
  },
  {
    address: '0x0406d480b7eebce5b94ed7d63a60e70636980676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.024231756352563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.02,
  },
  {
    address: '0x1a32297c1f05395fb3a18f10f154c57d58ffd738',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 26.01590502924263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 26.02,
  },
  {
    address: '0xa1fb40cb95eea13e94136318f949ec2bfd991711',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.918538240856133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.92,
  },
  {
    address: '0xea32f6f22f2492f128c0cfc117bdecb67eae05c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.797816284346275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.8,
  },
  {
    address: '0x648a6b9c22158faa95c19f9f4883231ba85116f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.711622264889133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.71,
  },
  {
    address: '0xcfb92c1a70ee9c84366b56ecb46b9b0c404d3b76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.69470776426749,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.69,
  },
  {
    address: '0x7c22c8e8c6e652df62704f51ef736182efe3691e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.669704583048674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.67,
  },
  {
    address: '0xda55185d94919ce175a7d5c0b05e741ac3909b21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.659061091025382,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.66,
  },
  {
    address: '0x98690a03135be924fd7926f360313971999764cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.64996358710592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.65,
  },
  {
    address: '0xfd4845d35f3890fca826c05fdbb1923c95ba49cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.64872389551155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.65,
  },
  {
    address: '0x69a0f7ca9ecdb343ffd269806e198006fdcda32e',
    minters_reward: 0,
    lp_providers_reward: 19.17,
    swapers_reward: 6.462214249239553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.63,
  },
  {
    address: '0x143c42cdc39101261227288ec2de874aa80d03b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.589613096389705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.59,
  },
  {
    address: '0x4bec18f2256e9f3dbc002935a59be7bb02a23140',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.586300005104516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.59,
  },
  {
    address: '0x192a75335646c561b513fff5201236c7c940bf77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.569207356092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.57,
  },
  {
    address: '0x43fa7201384b747cb175351d5f7a20c089a90f71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.559756696395155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.56,
  },
  {
    address: '0x70d512d252d89d903209e20d38fc2f1ae3106b51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.512929589133922,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.51,
  },
  {
    address: '0x27e22501aeb900fdfd0158cd09748e6f731f4876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.47797329655682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.48,
  },
  {
    address: '0x48d92467723d390aa5d88a88cdd2a0e19bd5e04a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.396249196636223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.4,
  },
  {
    address: '0x6db0a70dc197a2eaf2960a87f973d870a9ddc103',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.394256683880297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.39,
  },
  {
    address: '0x864e67ae0c447b2573e6cfbab09dd726faf87a26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.386529722059358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.39,
  },
  {
    address: '0x8e442180f3ece517a42fa1c22c4836ac4179133c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.374195200638763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.37,
  },
  {
    address: '0x4aeea0ac22df0f42176b9cc76bce3151153e0d69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.333697851299277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.33,
  },
  {
    address: '0x093483c9beeeff276b105eb8dca6cd153237544c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.302769636176297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.3,
  },
  {
    address: '0x3efba836350e9c4b772ed1fad02f92e4086979e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.237782799950352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.24,
  },
  {
    address: '0x7b6e19ea6b90300fa5d4ed554074164de1439278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.185603924916403,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.19,
  },
  {
    address: '0xfdeba4a23eb6922b9446dfb5c81df0b2dcc1d3b5',
    minters_reward: 0,
    lp_providers_reward: 13.14,
    swapers_reward: 12.013849112002504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.15,
  },
  {
    address: '0x943adb45b547a94b7015507a53c9ce26c97b185f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.093546032214608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.09,
  },
  {
    address: '0xb44df91f22f4372aba7e4cdf62f47129a4f10956',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 25.02460586650911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25.02,
  },
  {
    address: '0xe94318a6b1768549e4866af5408a27e9521321c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.999928692492713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 25,
  },
  {
    address: '0x073f620553c39e8b5a842e07ffcb6931974056ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.942813627786474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.94,
  },
  {
    address: '0x17e6991d4c0ea6edc02c46834c40d462d9c7a617',
    minters_reward: 0,
    lp_providers_reward: 24.92,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.92,
  },
  {
    address: '0x9c3ec50c4ed16543f18364eb9f404603ae82031e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.893622949718132,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.89,
  },
  {
    address: '0x9cdf52dfd8c64e81568a0c95b3a1a1a7985eccce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.844365495230193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.84,
  },
  {
    address: '0x8de11564b70aa61f4e7572f37a568a974e7da263',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.83237913382855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.83,
  },
  {
    address: '0xd5d5cf8eba5e84f138409b348f15445f3b344fb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.743255764970986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.74,
  },
  {
    address: '0x37a92b223825a281ff7454c38cba232f698d8b08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.72825178812794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.73,
  },
  {
    address: '0xad8d7f65309dd116360fd04d171ea5160e134f20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.625056937786788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.63,
  },
  {
    address: '0xac6b5caab501ef4918ba490a56358ff6a37aa9ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.617729661589365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.62,
  },
  {
    address: '0x8fa387bf5bd3c76e804490088e22101f3fc8e499',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.615882083525506,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.62,
  },
  {
    address: '0x00788221f81a0d9bc93ec5ba754c220fd73ab97e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.612287010444515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.61,
  },
  {
    address: '0x86a3f951313235dd2869923363bf88e0ec8d64c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.536104679949524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.54,
  },
  {
    address: '0x6e3bf3c7bd91144d694621298cb8da370479a7bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.536010670738758,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.54,
  },
  {
    address: '0xb3a47e1455080b31e700e5e29dbac25ecae07bcd',
    minters_reward: 0,
    lp_providers_reward: 14.49,
    swapers_reward: 10.020017092488391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.51,
  },
  {
    address: '0xcc0e81c65f995a303eadeb43be0dfc6dd59e27ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.493521640175445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.49,
  },
  {
    address: '0x2e5711196832b52b1df598894bc3479b45e7139e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.445970976436296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.45,
  },
  {
    address: '0x4ca2219e83df28f1059fdf140e3a29068e513487',
    minters_reward: 0,
    lp_providers_reward: 20.78,
    swapers_reward: 3.6665769329591456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.45,
  },
  {
    address: '0xe8716d0e896f9a87f9625ad5442ac5e2c286c601',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.428690713507915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.43,
  },
  {
    address: '0x0197b16f59a9ff17f19fab230b4d0e5fbd084039',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.398572609447314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.4,
  },
  {
    address: '0x8aa0eb46da7fb9f6fc5dee15b0f87fe1ae9545ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.352635814233786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.35,
  },
  {
    address: '0x13d577f3c883fc859fc01baaabb20e6a8695b916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.34252967006208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.34,
  },
  {
    address: '0x567e363d3ca2548b9523b849d76254e97e8ad444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.327857501088054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.33,
  },
  {
    address: '0xf58c8ea0e6d392c5ff9bf87e5c78b39b4216cd06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.32356562130935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.32,
  },
  {
    address: '0x6de6a592b18c985eedf28705132db700c053ad7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.31709270967161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.32,
  },
  {
    address: '0xb9d77378c989036b30c588cf5469c346ff0f0a4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.30295745346756,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.3,
  },
  {
    address: '0xe590c7c73acad3c0c7d5974cac9095bfab93757f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.233131684448804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.23,
  },
  {
    address: '0xf1faff2c2dbce708e982ad407245cb6259d9cfeb',
    minters_reward: 0,
    lp_providers_reward: 24.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.13,
  },
  {
    address: '0xa1186c1d7887fa050546bafabed8d1c0e60a6a81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.10673269864591,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.11,
  },
  {
    address: '0xc3612ac99e936b94ecfdb1833e64d453f9a4fe6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.094034986291813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.09,
  },
  {
    address: '0x3ec854ff288aa5fe1fbb7903657a09b7aeea211e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.089627080675495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.09,
  },
  {
    address: '0x2937c1fe9aeea55e1a091f82d5d63a71d4cceef2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.078418076754694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.08,
  },
  {
    address: '0x805380e0372031d2a54e761aa9a053ad673b9e26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.037964898903105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.04,
  },
  {
    address: '0x3a6bc9d056265a20b34305eb58d82150d66343b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.008533431072504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24.01,
  },
  {
    address: '0x1969cbeae5bf512e1d9d7ae16e5989be7d4e62d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 24.002812803815665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 24,
  },
  {
    address: '0xef06242324bef850526e4910f84f12af58b61dc5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.967416274274843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.97,
  },
  {
    address: '0x908e9c581af20b97a17a190b4b27d3832d31bde2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.903621384996192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.9,
  },
  {
    address: '0x804a22a5feb66053a69a13da04a4ce1530422d07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.888504726876842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.89,
  },
  {
    address: '0xf07b5119deda3ef60a3924dfaee511fea5eb20d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.74587836982773,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.75,
  },
  {
    address: '0x07cac66771056554bdcdfb99e212f455dd042012',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.736857542655663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.74,
  },
  {
    address: '0xb41a4817268fa59a44c21b59506aef6b710b3726',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.72701635672843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.73,
  },
  {
    address: '0x4fd3afbcb67b2e5cb032318e6a264cd12abdee29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.722117834575187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.72,
  },
  {
    address: '0xfd215a081f7fad11a04503fadcffb0f53dc4d95f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.664474282427694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.66,
  },
  {
    address: '0x4fb3e12b7f915cfb46416dadbbb3802ba39373b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.655318636826255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.66,
  },
  {
    address: '0xe61818c83e46aa4977e2ad3c228a9091fbf8f031',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.62472640853331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.62,
  },
  {
    address: '0xe2f4ae60152decc4e136a0cf988a81d813db8a83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.621968943445694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.62,
  },
  {
    address: '0xea91cde7999de4957edafbd6cc410717c3751f57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.517144346370923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.52,
  },
  {
    address: '0xbe7c04d73c44b52904575d1822a6bb71dc6b73d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.49043268511116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.49,
  },
  {
    address: '0x939e5f4ebb471804b8a11af944eaf39b72ea04d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.4519828602141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.45,
  },
  {
    address: '0xc9e5acc802558957d76b9f925defdb076286a737',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.4411928049152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.44,
  },
  {
    address: '0x16310f905086ec443853c10b34101eb43446950f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.421284654871965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.42,
  },
  {
    address: '0x67857fcce1a9292b0c2cd80782200f9ffb5fd94d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.404602296371877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.4,
  },
  {
    address: '0x90c763616ceef16984ded647ac731d6c16376ac6',
    minters_reward: 0,
    lp_providers_reward: 15.5,
    swapers_reward: 7.858275330612227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.36,
  },
  {
    address: '0x66d0a9a9ff17267dc7ac07fa1706f338c0a07472',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.27524378299432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.28,
  },
  {
    address: '0xd427469afea1c02b67e9f5c7b542d6162332a19f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.26361501621785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.26,
  },
  {
    address: '0xcc00908019af0970102e2345fb120bdc421a4887',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.242523914057045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.24,
  },
  {
    address: '0x1e598f6d0850dc1932afbb9afb46167f437cc13f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.233445100213583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.23,
  },
  {
    address: '0x91dd49b5fc7faf08ec9e85f2ab0155a6f46ca75d',
    minters_reward: 0,
    lp_providers_reward: 23.23,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.23,
  },
  {
    address: '0x8f444f144d6e0c9de067c2f9a0711b096b1506d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.224096271492346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.22,
  },
  {
    address: '0x27670fab384743490ba09d5e851cd3720e8c96eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.196163878015724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.2,
  },
  {
    address: '0xd897d451ee0397408bd88b8dfd1272bca94b1b8a',
    minters_reward: 0,
    lp_providers_reward: 15.49,
    swapers_reward: 7.619363506068371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.11,
  },
  {
    address: '0x38b0a91d81f28f6d8f03ea4706154fa813add94f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.088473236011325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.09,
  },
  {
    address: '0xde51044d8b10c656d4061bc2e7b1263a5b0dd9e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 23.083082276266698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.08,
  },
  {
    address: '0x57f63643f8cbc97acb6ad201d2e15c9c75af8fa7',
    minters_reward: 0,
    lp_providers_reward: 19.87,
    swapers_reward: 3.2145537185661093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.08,
  },
  {
    address: '0x62815f4b3de5a94620d4e81b9f965b148a188a4f',
    minters_reward: 0,
    lp_providers_reward: 23.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 23.05,
  },
  {
    address: '0x4706f6f9945d8665cfe5ec56ae67d00328d6ff47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.8959330391528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.9,
  },
  {
    address: '0xb1cc26691026f31a9ea9ceb584d6b44e0e6d0b8b',
    minters_reward: 0,
    lp_providers_reward: 22.9,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.9,
  },
  {
    address: '0x1b89a5cc6dacaeef46bc51521abb0a8b863034ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.879462404941126,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.88,
  },
  {
    address: '0x854c6b662fe84a6537474dd3c77a52c30726d101',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.87664500400428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.88,
  },
  {
    address: '0x2699d784227e5ecd2eff6bce97c5d546ac56e72e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.676289858402825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.68,
  },
  {
    address: '0x5b41445edd946dff6775016a139efdefeb2345ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.655672295816288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.66,
  },
  {
    address: '0xe37d4ec17cb2a1c1c4f184ab15df5cd45027fc7e',
    minters_reward: 0,
    lp_providers_reward: 15.69,
    swapers_reward: 6.951036378580876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.64,
  },
  {
    address: '0x9a978b46566a771c67ebb6318565aa8cd6d7b436',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.57116391260269,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.57,
  },
  {
    address: '0xf7c9e4cf61dd31ef62bbae04f9db1ad233554eb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.561221010329657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.56,
  },
  {
    address: '0x420055e33a3c2a6aa6e11363d076efb4ecac1ad2',
    minters_reward: 0,
    lp_providers_reward: 17.85,
    swapers_reward: 4.665044179932622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.52,
  },
  {
    address: '0x9fc84977f65611784bca98d538775cc49963aa3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.444497164857687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.44,
  },
  {
    address: '0x0cfdd6a6e2f24218edce116b5260451f7682c83a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.436409625014946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.44,
  },
  {
    address: '0xc738b9649779d98b6d62f335e6fd0d58cd790b82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.42712834742234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.43,
  },
  {
    address: '0x2781014b1b7da1d0b7ba799c63eadd5a433071b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.366236300902244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.37,
  },
  {
    address: '0x15e1cf3096fcf88bf4c5b2c6f6668dd42f54f637',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.242464025406882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.24,
  },
  {
    address: '0xba2ed04e467987b99521a9ecab0de9756c56ccac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.204600324292894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.2,
  },
  {
    address: '0x289cab8984c350ed03019042528378e8d5a3c888',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.195717454176833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.2,
  },
  {
    address: '0xd926148e9086ec311440bf4654ceecc3f7e933c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.16885916524566,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.17,
  },
  {
    address: '0x6efa048f7cc35d520ea3c49fdfdccd4d7e09207f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.109958625764747,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.11,
  },
  {
    address: '0xa090d45f4524d79cfdcc34b0a1077e7494cd42da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.09361364729116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.09,
  },
  {
    address: '0x1a687f10b4dcc8effe5d58bb28991fb22f6654cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.077629487828535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.08,
  },
  {
    address: '0x27cd07cad63183663075d3a6d9c5def05f94016c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.05449740473558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.05,
  },
  {
    address: '0xafa31ff69039d436a80517c6461ce996d5a108dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 22.039205637278087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 22.04,
  },
  {
    address: '0x7819602d9d698b4acd7026d4dee4783845533e0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.96770408378332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.97,
  },
  {
    address: '0x0ce159aba3c592c030789f5ebb8382cd990e4c91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.959191990041713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.96,
  },
  {
    address: '0xe4fb834531f5ebf9c2431b42fef7baf37f72843d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.94368372328984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.94,
  },
  {
    address: '0x1f0fd8079f0e1887cdc4cd4940acbdfb7d9f130e',
    minters_reward: 0,
    lp_providers_reward: 1.56,
    swapers_reward: 20.34749069072038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.91,
  },
  {
    address: '0x8c4927247ad991f02e489b84cb86797ffeb58d21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.8932642699972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.89,
  },
  {
    address: '0xd9792829505d4ac58d93ff57e5b6f65c2b83289b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.81550942876888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.82,
  },
  {
    address: '0x508c2fa73407b5d2baba511ac420539ca90d071b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.723250026078183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.72,
  },
  {
    address: '0xe9332951b930449a71eff22d17f5e84942e412a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.71639238474988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.72,
  },
  {
    address: '0xd1c1457942a6522f465e0949e2486cd7a0bc283c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.654511627747713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.65,
  },
  {
    address: '0xfd9b8fa669cada68f15088b86b320eaf4aa943c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.639583120160975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.64,
  },
  {
    address: '0x067f79772846bff961027c2b9e13ac70087ab2f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.62948154829222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.63,
  },
  {
    address: '0xb7e1b1b619ff9bce2fb642f3fdad301c8d1cd7eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.609608078863193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.61,
  },
  {
    address: '0xe47aad4641e2048599e4994c81060a54ed509783',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.449837515683605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.45,
  },
  {
    address: '0x1d73ad505a463a7cc3fa453b0e12c47c53450b62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.415290856142263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.42,
  },
  {
    address: '0x87483e1e193f8a7751837a25dce210b709070774',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.40532633944122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.41,
  },
  {
    address: '0xa9fc77120c5714265dc50c9f211d445a655629ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.402817690948176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.4,
  },
  {
    address: '0x61e3b7730f1dd36fea4fe79e477f71e9762379a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.39914309819175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.4,
  },
  {
    address: '0x335ea878801e85cd874d059ba686ba0cbccd12e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.390175337042518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.39,
  },
  {
    address: '0x6bfa628b0d625380f327fe639a06f34b31304803',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.372784464989596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.37,
  },
  {
    address: '0xa5047309bb8d9df47f4345513cecdae41d479acd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.36752784355892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.37,
  },
  {
    address: '0x1ef3a4345e2c5126b16147e5fba386359e09a108',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.311930358085284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.31,
  },
  {
    address: '0xfee5581b1871d3745d1256e0f21abf1620a80f1b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.270223808066817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.27,
  },
  {
    address: '0x0cc2574902176d186d327ad6d5820161f8fc03aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.26965682221746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.27,
  },
  {
    address: '0x7e2a26903da70677bbb07f169bc4a1339a18d1d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.264228145353094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.26,
  },
  {
    address: '0x26b88b8d5b9eaab331945235df3163a2e9615850',
    minters_reward: 0,
    lp_providers_reward: 1.6,
    swapers_reward: 19.655921544011672,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.26,
  },
  {
    address: '0x9acdbb0dec631e034bd058c88db68c5f77a8bc8e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.249760750691824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.25,
  },
  {
    address: '0x80c9b04e42b710290fbeb38bdc9cd3e23c5d8b07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.214836051919818,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.21,
  },
  {
    address: '0x949891bd4c7108a7a5ddd96415c4288431235f61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.103212709589073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.1,
  },
  {
    address: '0x04ea1059f50e3229e3d26240c82f425d36bb0ec3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.087793797692886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.09,
  },
  {
    address: '0x646cc92efa51ef7a724d48a9b7db43bda7f13323',
    minters_reward: 0,
    lp_providers_reward: 8.66,
    swapers_reward: 12.411851814551612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.07,
  },
  {
    address: '0x8c6d4beaf291d508575ce5d6b47ce81a34e9e1eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.05561646350758,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.06,
  },
  {
    address: '0x363d878321ec9bd2c0b00b079b75c81f11328a09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 21.02537047526041,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 21.03,
  },
  {
    address: '0x86bcf723fa16f19407ec527d193edd3478a44160',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.946387932987804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.95,
  },
  {
    address: '0x8709672cd6843c0ba4c7c6bf16b460b6ba2c446d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.907460902724164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.91,
  },
  {
    address: '0x3349dd7ca2f777fbfb9fcd90627a30641e43a80c',
    minters_reward: 0,
    lp_providers_reward: 12,
    swapers_reward: 8.91144445464242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.91,
  },
  {
    address: '0x34168cc87453204cfc62927d048119160a121908',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.89495379404397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.89,
  },
  {
    address: '0xdd1e39a9fc211ad7a7076ccb6d962b7f1593ff21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.88394904587523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.88,
  },
  {
    address: '0xd79051a65845a01c2d57cd06fcb0099839803ed0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.870516917057266,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.87,
  },
  {
    address: '0xe1ec71f29999ac98751736a05a47ef78076c6709',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.847530229008633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.85,
  },
  {
    address: '0x2f25b52256a34e2141a12d9996d600231b44073c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.790478382490843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.79,
  },
  {
    address: '0x76e1e84a4ad3e81eae9b0663a6c851638a39d82e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.76291564793416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.76,
  },
  {
    address: '0xf4191d3e771e6c2a9107fdbcbe66298d998efdf7',
    minters_reward: 0,
    lp_providers_reward: 15.45,
    swapers_reward: 5.3079173488921265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.76,
  },
  {
    address: '0x580e7176533466424e17ec287d95ded08c2d3d5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.743111143483617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.74,
  },
  {
    address: '0xa5288dc2d538da0b799f5626f2d3cf07683534f2',
    minters_reward: 0,
    lp_providers_reward: 18.46,
    swapers_reward: 2.1949167913079943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.65,
  },
  {
    address: '0x9d3a28cf2adb9b6f133a6f938386730c289327db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.63067119751032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.63,
  },
  {
    address: '0x41b903253c88bdb12377e43c33f3ed75cee49546',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.622970370487604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.62,
  },
  {
    address: '0x442c8a5965d6635f8b7c1372ba81d6cafe988899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.601483183054803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.6,
  },
  {
    address: '0x2eae18a406c744cb702309e2bb652349fa59551e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.60111929330419,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.6,
  },
  {
    address: '0x721f150d0c8733aca6ad9ec9ae324e3663de6d40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.58792531807195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.59,
  },
  {
    address: '0x0ef7fdc0ea116a75547aa2b28467e4624aea688a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.575643112270026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.58,
  },
  {
    address: '0x50c0f3516429d6d65930335ed1f45f5c12720153',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.562081175546687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.56,
  },
  {
    address: '0x6832dd1b88b285cda0bce506d1846c3337c6cc06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.556115748929695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.56,
  },
  {
    address: '0xf1d5377bf43260362f06d57b8d752ae239ee158b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.530004598630367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.53,
  },
  {
    address: '0x46abcc6935bd690ed41f9d7ac0788ca8815f75b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.525403789877007,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.53,
  },
  {
    address: '0x6f4fc22ff11d183a0db7551ac63aea9a9f54e4de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.517384655654805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.52,
  },
  {
    address: '0x48ea3b350eb12770dd3675a6ddb5107bfa6532ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.50745878075704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.51,
  },
  {
    address: '0xe02ca0d54862fadfffacce5bdde976aac27a6cd7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.478752428516742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.48,
  },
  {
    address: '0x0c58cfab87a6abb54593da43d955e3a7a35c3012',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.460569949206743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.46,
  },
  {
    address: '0x91fec737d852f693637dba1afbe8af44e8ec13c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.456411180002817,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.46,
  },
  {
    address: '0x878dc6ccb4b8d55ae729bc40a301e76b23113024',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.402802984400825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.4,
  },
  {
    address: '0x84b8afdb8a40c78837fc1134e9f764971158f3a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.400332016115723,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.4,
  },
  {
    address: '0x57dedabe3757cea7f71c33778b93e3ac7c857907',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.349243261372763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.35,
  },
  {
    address: '0x7cc841087c3fe1f7ff16c59b08d60fece1a59b43',
    minters_reward: 0,
    lp_providers_reward: 8.94,
    swapers_reward: 11.385765832218349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.33,
  },
  {
    address: '0x423e565f107c87c5ab77742120c988eae043cf5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.297658214967868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.3,
  },
  {
    address: '0x7766cb6ae6da8fc890b28d951f182df68463e0e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.294302808366446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.29,
  },
  {
    address: '0xcacc35a84e16e9661123f8b2bd087cf18134b1b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.260387509344937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.26,
  },
  {
    address: '0xce272228fe22d2f9e34961741da8ad010c0aaf52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.244265410005113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.24,
  },
  {
    address: '0x9b0a6b63fbe89d3b1a38f102c9356adceed54265',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.231974844756873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.23,
  },
  {
    address: '0x30903202efbb1dcc6ef7b1e9032fb25b507de09e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.212228961102916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.21,
  },
  {
    address: '0x7a40aa2fb7ad9c06e668184d36256b269f2de603',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.14845195831223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.15,
  },
  {
    address: '0xcd9fd48c6dc3e043d4797c4dc48f6759641ff3c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.1379172315642,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.14,
  },
  {
    address: '0x40a67e2e380013e1c0ff58f2b98f8620bac0d22f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.065287282117676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.07,
  },
  {
    address: '0x1a973bf36a5849218846e89838d34b608e5130f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.008711166378024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20.01,
  },
  {
    address: '0xa105e2fbcdea0dd073e1c289c744380f8a1890e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 20.00323259707216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 20,
  },
  {
    address: '0xae4db9e9e2e715142e85757a449e064eb64ac96b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.985079016153666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.99,
  },
  {
    address: '0xe8c3b306610c005226243e334717699ffdce1498',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.952135186249546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.95,
  },
  {
    address: '0xfd9d6447466b47df32214daadf2cff2145124935',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.924839450747157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.92,
  },
  {
    address: '0x25cc0052175c88a73f5dc5541e86b00c78aac8ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.912402104257836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.91,
  },
  {
    address: '0xf7b8400fe8e636f674e48c24bf8fd220f160ac4b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.859251235068808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.86,
  },
  {
    address: '0xe1b589c2422be4f6fb770fdb0e407ec4a02f3c5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.702919024857874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.7,
  },
  {
    address: '0xd70e596fec1cb98f0ed81b4238ea13d35c819f17',
    minters_reward: 0,
    lp_providers_reward: 19.7,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.7,
  },
  {
    address: '0x94cd56572dd571d050f7266effe2609941d6f957',
    minters_reward: 0,
    lp_providers_reward: 6.59,
    swapers_reward: 13.10165188215522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.69,
  },
  {
    address: '0x671a6193fe2abb63a9b7e70677d506ea568efcf4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.623279820593595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.62,
  },
  {
    address: '0xa3e39dfc2fff39587b02de2159cc80bdaf311daf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.62127713034104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.62,
  },
  {
    address: '0xed693c6a0cbaab0bba6a28a56d75c9252dad505f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.615046850448156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.62,
  },
  {
    address: '0xa42c300ca81414b41bed10415d517a40715fde29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.59521473542069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.6,
  },
  {
    address: '0x9b9e663718666b33a38b0e4be6ba01a78540dac0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.57956892287696,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.58,
  },
  {
    address: '0x22522ce786a5e218bbe5318b95f9306ec6188af3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.577194256148235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.58,
  },
  {
    address: '0xae93e7a6d74afae4a46e4fbd157d198c7a56c221',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.53502624228341,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.54,
  },
  {
    address: '0x38f3c5e38ae348bbc2cef7b429134fddc8651129',
    minters_reward: 0,
    lp_providers_reward: 19.54,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.54,
  },
  {
    address: '0x4b84cda0a83a88ff097abaad26064a54f1992c60',
    minters_reward: 0,
    lp_providers_reward: 11.84,
    swapers_reward: 7.6678891991379095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.51,
  },
  {
    address: '0x4047090d7c656087fc940067d318baba456bd169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.429175846494147,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.43,
  },
  {
    address: '0x81ad0d6a89e4344c83901241c485db434dfa9487',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.41674382921298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.42,
  },
  {
    address: '0xd20c2b3daccdcd28e5fb98a4d5b748023c5d0f34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.386345846848496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.39,
  },
  {
    address: '0xfd79a625005186b6852fa9ea8a8fbf21e811c3cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.382107344681547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.38,
  },
  {
    address: '0xafb16bd542ac252a755daba144f85eba4a33d01d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.371760825438518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.37,
  },
  {
    address: '0x94a1f4c8680498f01c2eff375338867f9a9e426b',
    minters_reward: 0,
    lp_providers_reward: 19.31,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.31,
  },
  {
    address: '0x8b8d0cfeccc1d084432b7fb727a6efd38355f4f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.296965912413125,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.3,
  },
  {
    address: '0x1982555fa56894821deb8df29e05ced7a785bdc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.292900311987683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.29,
  },
  {
    address: '0xa8027c9d332ee3c9cb693211641fa393093874ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.251699955878596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.25,
  },
  {
    address: '0xc924d3875ef1bf1df283fa4c05e6ea585bca8dc2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.241651603134066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.24,
  },
  {
    address: '0x193cb03134cb1a243d376ccbbc22f9ad540b21b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.234727297271334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.23,
  },
  {
    address: '0x5605f52441f4f1debce1993533fc6767c0dcaf1e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.20224925604742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.2,
  },
  {
    address: '0x88aa8f097528bb31c8377dc18e19154208a9e299',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.184953099432878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.18,
  },
  {
    address: '0x729fe872e8bd128601057939dbde4968519b27ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.138961389643235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.14,
  },
  {
    address: '0xe55bd96a184c00e951a9d7cd12d3b2fef6053e25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.133710126708255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.13,
  },
  {
    address: '0x4fa990736f70c549ddfd1251b87a2f8927ec5091',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.102150792580122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.1,
  },
  {
    address: '0x2602a31775f2dbddb8001decdd9677b18daf1075',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 19.081839380978913,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 19.08,
  },
  {
    address: '0x0087f1a6f492c37c61618673f183b3791677d0d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.980501383822837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.98,
  },
  {
    address: '0xf061da638f9e415d3851db667c3e941a73207573',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.962234312027554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.96,
  },
  {
    address: '0xf71c04623ef09a2c539f6257dc3e43b0db4a06ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.954541086906772,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.95,
  },
  {
    address: '0xe854cba8aeece606c1be96a2d6b022e8bcc133d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.941087265524825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.94,
  },
  {
    address: '0x7f453ae2480f62cb53c0aa30aafb20cf42dc39b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.912219037112685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.91,
  },
  {
    address: '0x26a106d303a1ed601a350d7bc05984d4e251e408',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.900181906214414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.9,
  },
  {
    address: '0x6d9e61c341b2118f15773bbe43ed597606afec91',
    minters_reward: 0,
    lp_providers_reward: 18.88,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.88,
  },
  {
    address: '0x44e58766d552c87f4e2f47882960ffaed5a90454',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.86872385438102,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.87,
  },
  {
    address: '0x9750e6ee3de102f5e2036ba395d4200c6f84ffc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.82063763280192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.82,
  },
  {
    address: '0xb22386c675be4258c885e31eb6a27e66a65dbca8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.80213468650791,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.8,
  },
  {
    address: '0x38ee1cc584aeba91f6c86da3d2774ad9f6962c00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.787026553618503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.79,
  },
  {
    address: '0xd69b1f67caecee939bdb43b1a1c6eeb2ab0530ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.78418270867314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.78,
  },
  {
    address: '0xdf8d968820d5931dec04d3569e117480050e988e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.76867735147579,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.77,
  },
  {
    address: '0x4e0c6b22bc21b48aa1bd7c53297433a97b3a3c22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.7068079635524,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.71,
  },
  {
    address: '0xad67f9bf3a292885abbe070650b652b5e24466fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.70285082521197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.7,
  },
  {
    address: '0x6b4689a2fe719581704f6e6b795ad57abf3eec6a',
    minters_reward: 0,
    lp_providers_reward: 18.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.68,
  },
  {
    address: '0x82d6a501485e7f2e7c83e64d5644e7dec16814b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.641294665191417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.64,
  },
  {
    address: '0x6acf93ca57da354c1f45f657fba12895288fd9b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.595269561437675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.6,
  },
  {
    address: '0xd971a967f5881c04afc27dd2e914d5dffa72b15e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.588840496639158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.59,
  },
  {
    address: '0x9734865c105fd070d182605867633d3796df2150',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.562799054072418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.56,
  },
  {
    address: '0x4c70e03cffb971c1361fa90c16b4f7419d92084b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.53337326696853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.53,
  },
  {
    address: '0x76898466ee09a1822500b0d88c4959f22ee14d98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.524553820027272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.52,
  },
  {
    address: '0xc57afb49a73fd31d2269578c684d92ebb85fd1e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.497356808260797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.5,
  },
  {
    address: '0x694ac6be6021f01ccb2426c6cdea366de4a19351',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.457720491211738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.46,
  },
  {
    address: '0x6dd72a5355f1acc8d3f0394b45ea6e72c1a27b35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.443768306782623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.44,
  },
  {
    address: '0x194587c314a280ebc135dc3e01bf74929c7c12d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.43733128565205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.44,
  },
  {
    address: '0xebe798198fe49637a55975f8bf39a8f1481259de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.417602321829044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.42,
  },
  {
    address: '0x65808b61b1621e929a10132a49f1ca9f1d975ab6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.387718113687704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.39,
  },
  {
    address: '0x06b95c401c7089f928d24b00d8658cede742edfa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.383142513923143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.38,
  },
  {
    address: '0x921b75ca1e67c4e05cc0ea274762c5454fca323d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.365779853711583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.37,
  },
  {
    address: '0xd3522aec791d10a6d3fe5a20d2196c8d6205620a',
    minters_reward: 0,
    lp_providers_reward: 18.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.37,
  },
  {
    address: '0x6391042e16517e2f05e2c337f19c25e8923a3aa2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.35411768790276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.35,
  },
  {
    address: '0x65a67fcababe5ac817cb4016669779b61ee3a479',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.327454715125384,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.33,
  },
  {
    address: '0xa7bb78a28c188220e539b130fcae697a8c116cf1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.32015954109085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.32,
  },
  {
    address: '0x7c9179b277ae7ab0c74dfbd096f064ce6c8adbb7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.315376183190846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.32,
  },
  {
    address: '0x1ca00e336be41327fe4ec8fae220b7b8b117529c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.290478732090893,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.29,
  },
  {
    address: '0xa938aa4811dd185aa5b5c4e69f099b7cac96b278',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.26657339476494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.27,
  },
  {
    address: '0x70220213fcb66f3ad9b1b4e9f099aa4012decf74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.26494964361272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.26,
  },
  {
    address: '0x2fc38bac806ffd805242b755ef4073de8cd2989b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.169797102634682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.17,
  },
  {
    address: '0x7cb725b43fe6ea19a9785491bd348d5bf084aa75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.168188681718753,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.17,
  },
  {
    address: '0x50f296e80ab42558f17d2ca870b8d5b45f2c2316',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.135992357053752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.14,
  },
  {
    address: '0x560858580455b2e277dde49e4e907653761df20c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.128213874334445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.13,
  },
  {
    address: '0x6314077131232f7d8282d07dcb4c31387ddc81da',
    minters_reward: 0,
    lp_providers_reward: 18.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.11,
  },
  {
    address: '0xd2a23272443f88434b3fffc79cee50d812e449a9',
    minters_reward: 0,
    lp_providers_reward: 18.08,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.08,
  },
  {
    address: '0x8773f7143fa04346e40cd174a30a1d6091a0d3ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.072454741972535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.07,
  },
  {
    address: '0x22c1c08509ba45858011019341349d0e5b23e0c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.070670217182936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.07,
  },
  {
    address: '0x190ff18e41b0ca0ee78585ad5eed0c32baa60f08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.069620463769894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.07,
  },
  {
    address: '0xe45284e139ec636c5604e714d08295968064fb28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.030534642095727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.03,
  },
  {
    address: '0xd03d70904fef99088f7d588b96f0a6c1da6308ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 18.02200982593278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18.02,
  },
  {
    address: '0x85f8fd1bd0c95d7dd2b1731a8a20ed639a6b80e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.998909683068963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 18,
  },
  {
    address: '0x9c0c7a5a2cb49be6d135afab319051a52189e126',
    minters_reward: 0,
    lp_providers_reward: 14.47,
    swapers_reward: 3.4987901773094396,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.97,
  },
  {
    address: '0x5bced5ffb8b5930c4b393066498b53d8e200f050',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.938278389571707,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.94,
  },
  {
    address: '0x41737a78ce7055c83b64eed2eb0b2d80e1884c16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.913637458556348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.91,
  },
  {
    address: '0xbe092634130fc3e55f9b845865acf66b5df12f4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.900084105323746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.9,
  },
  {
    address: '0x803c104617e2a4a987d16499313995b0cba896ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.882425820366976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.88,
  },
  {
    address: '0xba604ea0825537c457a7f23f585ff6beeac3d481',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.88234357358872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.88,
  },
  {
    address: '0xea0f97678dcad0826676cf4a796a65a8bffb6f51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.786719251858575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.79,
  },
  {
    address: '0x91b4a1ca68b547a5e3112008285307d6212212fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.72372183889044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.72,
  },
  {
    address: '0x5625e613548001d103a58c4d149d4521031332b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.716967674881495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.72,
  },
  {
    address: '0x2813fca6621e9e5c8d005e1b9c930334945b8afe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.716463579986595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.72,
  },
  {
    address: '0x19999189f1e48254ffa93c97ab274454250ccecd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.695984866918174,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.7,
  },
  {
    address: '0x7da0c620b03fcb788de347bcdb4530f0e1d1c4df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.693987797100828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.69,
  },
  {
    address: '0x9bbb45e12c4a75f7406cb38e858fa6c68a88f30d',
    minters_reward: 0,
    lp_providers_reward: 1.91,
    swapers_reward: 15.76988963391611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.68,
  },
  {
    address: '0x29c9cd9d0af9fac1b210c175c1f5cfb07598241c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.64126575126617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.64,
  },
  {
    address: '0x1887f6a048e1a7b09a107a920d907c404168493a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.635437575785033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.64,
  },
  {
    address: '0x8e802b99b25c5c247ab6f0bda39269fbdb6c2389',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.620284264169225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.62,
  },
  {
    address: '0xeb1b89b2625044b63093ec57e11205d3b134622d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.618996808349486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.62,
  },
  {
    address: '0x9b08e6b954cb9b458a9f43f4cde260ab3c5e838e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.529465951333876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.53,
  },
  {
    address: '0x16a270e8a542ca47cb36e0a7afd4ee68bbdc5f81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.519171208022343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.52,
  },
  {
    address: '0xb0aab2f41b5cea9ead2a74ba21dcd080d1a91904',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.50329519197506,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.5,
  },
  {
    address: '0x76a680ed4b07c530d26c017cb94c5bcb8d2cd628',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.484992414630565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.48,
  },
  {
    address: '0x0672d8ffcdbbec1fe4df6f397d362bbe633e30eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.454595150732192,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.45,
  },
  {
    address: '0x77311473e842c6976adc37d4061bc0906e36efc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.425998741461722,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.43,
  },
  {
    address: '0xf57f513a6ff6835df164053e42bf0e211de268d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.399232929169788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.4,
  },
  {
    address: '0x90e75294acc6294c4ca6b259cd487c5924c9011e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.397058339216347,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.4,
  },
  {
    address: '0x2ba4c61b674ab6f242db5a89aa897a5e57ea6a3a',
    minters_reward: 0,
    lp_providers_reward: 17.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.39,
  },
  {
    address: '0xde413e4be09b75f133396e94528a27807f9e6e70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.35965184217166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.36,
  },
  {
    address: '0x86c3b77a9316eb6ae4ffab2deaa24bd7596d4889',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.300277600060916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.3,
  },
  {
    address: '0xe11ff7dc515a83fbf6de86119d5fe38e2eba2c5f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.290938860784358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.29,
  },
  {
    address: '0x213aba11e82a86646c7366614e85982f34173806',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.22784929640685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.23,
  },
  {
    address: '0x517beb202756345fef34545de94dfbd2d5002c7d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.227017650979814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.23,
  },
  {
    address: '0x5223ac29cd48bfde494c065c1986f8652edc813d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.223755162918483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.22,
  },
  {
    address: '0xfe8040cc9e6ec85a0f8fe1015fc9774e289e55f8',
    minters_reward: 0,
    lp_providers_reward: 17.14,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.14,
  },
  {
    address: '0xefa7ab847557f0720893db90d1a2d11e8d6c9b43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.13465136567678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.13,
  },
  {
    address: '0x123b6c4a6055fe8c2f7bc64f0ac549ee083622f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.054077429525886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.05,
  },
  {
    address: '0xf51d64a8297c92fe23b532212bfaf987d832162a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.04692386009584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.05,
  },
  {
    address: '0x9526029f1a20c8d288444f8a87b4b1d4c2c87291',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.040551726186507,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.04,
  },
  {
    address: '0x03a6926f306092658456462b8f7d9d69151c442c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 17.028845247636582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 17.03,
  },
  {
    address: '0x9eb6339d296ea5c2eb96b88466dcf2d9fe29d8fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.978310537539205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.98,
  },
  {
    address: '0x7d102c84e4cad685df1c7f05a11cea3d1abc93af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.964719129825987,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.96,
  },
  {
    address: '0xed107ac00bc881550313afff6bebb914ed575851',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.926626670714917,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.93,
  },
  {
    address: '0xc19e007347a28b7892ea2d89c8a3f8344c0d3627',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.91170768118029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.91,
  },
  {
    address: '0xab735123edebc8f93e9f607bb126a62c59546ae6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.898349906631193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.9,
  },
  {
    address: '0xdc6e14eebcadab476730a93f2b61bfb4765840e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.878094097145624,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.88,
  },
  {
    address: '0x82ddc01460447c7e0e11853f95df92b329802536',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.867686324113034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.87,
  },
  {
    address: '0x6f0ee05a90efe9001b63c273860dd3c7eb577ed1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.856230453828786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.86,
  },
  {
    address: '0x6efb339c5a1788113be8a22223b42733e32616e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.773750054703267,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.77,
  },
  {
    address: '0x838e7063c0661d0a5d8878984db3b5b7fd126944',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.764585490045512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.76,
  },
  {
    address: '0x823e64cebc1e26ce980afc572c8a6fbbf889429a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.71960916217179,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.72,
  },
  {
    address: '0x0b985b14e61ba67890d0f4c61842c146df6889d1',
    minters_reward: 0,
    lp_providers_reward: 9.67,
    swapers_reward: 7.033982904747784,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.7,
  },
  {
    address: '0xe6886dd748332dd1159318ee6956082163d94722',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.68412163885061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.68,
  },
  {
    address: '0x75103a045dc96519d129554137375696765a5047',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.66021277389152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.66,
  },
  {
    address: '0xfd7e5da59f7d7e3ef0cbf279ed030ad816d8ff58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.637706616040408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.64,
  },
  {
    address: '0xf27ae4573f7979e2f6dde30564e646f071ecb37d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.631469541627972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.63,
  },
  {
    address: '0x5871288aa00a18f16dc03b222b5274a72375797d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.61257400403882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.61,
  },
  {
    address: '0xf2b408e850ef264110de9cc760bdcd50e8e462f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.604405048296464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.6,
  },
  {
    address: '0xba9b217f2d3a9286152b74ed56df8399775e9899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.575359886983065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.58,
  },
  {
    address: '0x8c44a22e23d442a23a656cd894f36ddb6c2c090e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.5614179756513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.56,
  },
  {
    address: '0x7e7564fb1ea4fee3f6ed7998bfd89902bab7c799',
    minters_reward: 0,
    lp_providers_reward: 5.09,
    swapers_reward: 11.464757203174988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.55,
  },
  {
    address: '0x9ff331a11dbff265d80512262068cabe4d85d9c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.52127031610935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.52,
  },
  {
    address: '0xe2cd94f41baa8cb8ccb0b8102de3cfc629d786d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.48321795882255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0x680e79224200d535faae7921a0efc4e4ec03b186',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.48321795882255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0xef937a975495f03fa610900462078a1e61a65c2a',
    minters_reward: 0,
    lp_providers_reward: 12,
    swapers_reward: 4.483140760847562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0xa908b6b5247d83fc8f4856faacb9cb5df30e7cbd',
    minters_reward: 0,
    lp_providers_reward: 15.22,
    swapers_reward: 1.260460766208614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.48,
  },
  {
    address: '0xe3d93cfee72f0123024062421633b5640f399337',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.457039408235413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.46,
  },
  {
    address: '0x7995190c2c2a051763344e24d198958b7b195d0a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.411653213614052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.41,
  },
  {
    address: '0x7cef7d9a646dd80217c0c8c1c693ab77789bc97e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.39202199767214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.39,
  },
  {
    address: '0x80a381fe8f9919559a1c2479f19998b03a9c1c09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.370806642308033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.37,
  },
  {
    address: '0x20f8a6e05a415367b6c0ebe04246d7ed28451bb0',
    minters_reward: 0,
    lp_providers_reward: 16.37,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.37,
  },
  {
    address: '0x711a290821ef89dcb9c7f0e21c980b10bef84078',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.347587477054073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.35,
  },
  {
    address: '0x93ea476bbee39636a41adbcfc30071b098dbbfe5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.31881860833318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.32,
  },
  {
    address: '0x8f2a089236152dbd742215a4f234bc05d2290830',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.31711092706159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.32,
  },
  {
    address: '0x4d8f793c1ea787736f2772d39b7262271a5a87a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.316565446732366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.32,
  },
  {
    address: '0x6d4455054ad598dac7268ece70206823f9cd477c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.312322789739223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.31,
  },
  {
    address: '0x23e7eb76be3bc78be5db2cd53e384706cbea90e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.273935501147392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.27,
  },
  {
    address: '0x1035d7e291948262d056998a9c4db1bbe26185e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.25334369602362,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x6e48875f48c03b354d07bf2841768ba232136cfb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.253343696023617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x95ad1c489451e59699b5a7be4bcc3b41337faa66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.247949026732776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0xc93f810acb1a1042a3532a6163553e8025c50a8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.247949026732776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x9de6b78ac1eecc8a0315d359af028eeb69b99e5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.246646065758338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.25,
  },
  {
    address: '0x6bbd33643842ec50c6ea7a7bf6b832ad3c7a4beb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.209200469462804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.21,
  },
  {
    address: '0xcc549813f332f80f909a00007b0afc261aa9de2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.194405646344986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.19,
  },
  {
    address: '0xcd5ecacb1e18f3e3258241d4d74d7ccae80cc1cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.164514133174418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.16,
  },
  {
    address: '0x5bad64437a089a5cb8bb1b5443504700a1d76061',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.12099828067143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.12,
  },
  {
    address: '0x7033ad22926c57b3275c021df1260090cd6d4b37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.102027763050383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.1,
  },
  {
    address: '0x23bec55f896a941443382bc88ecf5b407e6cd48f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 16.041576798374244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 16.04,
  },
  {
    address: '0xd2719f661509416ef6070da7938792d65d39f36d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.97827751618323,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.98,
  },
  {
    address: '0x683a202fe31213c41e8a1832ecd6678c9846bf02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.925589905902815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.93,
  },
  {
    address: '0x9ed92dc3138fc9bfe8d31f9570ba73419183cf33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.894097828105423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.89,
  },
  {
    address: '0x719d291da2a0d351d086fe87ac64089eec9b2f9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.845192765372182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.85,
  },
  {
    address: '0x7727fc3994b67489cdd3a18625bb8e5e4fa2c0b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.845192765372182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.85,
  },
  {
    address: '0x3b5715c9929e19a4c6908a17222bc9fdf47904bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.834757344471669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.83,
  },
  {
    address: '0xb9635eeed15321e26ce40f5c38b3455801a15c3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.832258585096424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.83,
  },
  {
    address: '0x1a81a34cf5e44e01535fffe6fe6e54868e5ead5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.817087900503072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.82,
  },
  {
    address: '0x0191bbef075076cb25285b06a59780e39aee9b53',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 15.428707079167006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.82,
  },
  {
    address: '0x541cff4f8d21f466deaf24da8732d82b3bd8546a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.771448743248563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.77,
  },
  {
    address: '0xb5789c442580ab33aaffbd98cf0b698d872338bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.756659278223589,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.76,
  },
  {
    address: '0x6e28a95a58ebed7a6626cf90421c1b759db1a260',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.756309919899172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.76,
  },
  {
    address: '0x97bc87ea160cf6368b315acea62a47c5a3f1a0e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.734845840381434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.73,
  },
  {
    address: '0xd1af79e196e998dc1f33d05a902cf6bdde8413f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.71407025050456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.71,
  },
  {
    address: '0x7a1dba7170f4464ae2dfe23babdecfd1615ed11a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.695462004146785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.7,
  },
  {
    address: '0xa7c219e5cb52fb270cd7f68aaff9a9be768748af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.67671326779853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.68,
  },
  {
    address: '0x934b036512880967716f5b7e2345a669670d732e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.673187354474512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.67,
  },
  {
    address: '0xafcb64773e3b004809795bb00467a07903bfbc13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.64912962092632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.65,
  },
  {
    address: '0x543cd5d50c2f4f106a6f6377c628f3abf9f08f75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.559175095940592,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.56,
  },
  {
    address: '0x04fd391576d394d9b7afb66f775259d21d922490',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.542924756603947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.54,
  },
  {
    address: '0x995a7bdbbded631e802a2d8f86422139437ac83a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.532840042219178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.53,
  },
  {
    address: '0x482eaf5ee7db72dbeb6129f0701708b246502316',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.507076954729557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.51,
  },
  {
    address: '0x18ba8999f791ceb17637ed6ee1b522aaee6d7904',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.49348108357373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.49,
  },
  {
    address: '0x6bdb627491e468fa859141f29af5779ede9cea10',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.49130973073637,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.49,
  },
  {
    address: '0x1947e25872d4ad818a4dac43c2b5d7f7b5fa1a10',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.467476779422155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.47,
  },
  {
    address: '0x5960ce076e79b3ddf8acc48115ca405593ea0b00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.45659949942326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.46,
  },
  {
    address: '0x117fa696bb31ed607e6e4ae3d5d66f7ea58e3ab6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.381882988220163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.38,
  },
  {
    address: '0x89f5ab50b177d3f024032ff9f5d2268da4d4a060',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.379373570575893,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.38,
  },
  {
    address: '0x0739784e4ddb44f515fdabba21bb7c7cc5775f68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.371483669298183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.37,
  },
  {
    address: '0x6a37f82a7d3b768609c09457873ec6713b3fc179',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.368705610754429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.37,
  },
  {
    address: '0x9675ddcbc4c72cf163c5539ac9b37b58b7fa00c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.315115576944327,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.32,
  },
  {
    address: '0x1294534aa20da2b60a88a8c02425a5ef6603e970',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.31224400154527,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.31,
  },
  {
    address: '0xf2c2a57b0994f94116bb4d53a322c1ee5a078e11',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.302353392503884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.3,
  },
  {
    address: '0xf656cf5c649243ae0742d8186baf5069c5944002',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.284959212000217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.28,
  },
  {
    address: '0x8fe1b719e4b1e3e022cc64465d42d1c37b68bdd9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.236285910667918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.24,
  },
  {
    address: '0x7e20d0f93fb8435e37b7a97fc0254bc2e1e37047',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.231279634269798,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.23,
  },
  {
    address: '0x8f02c514480d95802bf31b4eaff45a8731737474',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.206266882771363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.21,
  },
  {
    address: '0x893336ea5fe30e6c7b54806d4ad0cbc8dfc1f240',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.19770620117123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.2,
  },
  {
    address: '0x65b442f9a0bac40a498182c54d2d9d83a5c27f74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.16719593704374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.17,
  },
  {
    address: '0x4708b9fdc9799e908d482895697cc96eedb1982b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.111638917046944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.11,
  },
  {
    address: '0x659fe8eea755f761f220133312f1b693b6da8052',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.107835073502066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.11,
  },
  {
    address: '0xfa8e6091ba9b69dfb7227d9bf700b3368e1e1830',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.089155380530876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0x5f94902cc1feadfa4c71002c4ea74db0df8c92c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.089155380530876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0x642f217f395bffacebeaf57b8a1e5f143fa4b90e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.087077226325556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0x11aae49da2c6ce3609c0254549d274008eeed2d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.086907709744073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.09,
  },
  {
    address: '0xaca41c8ccf9a2b88c49216e289ffbe12912838d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.023325968882217,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.02,
  },
  {
    address: '0x83c6968c579e0d4bd88c267897a153a9aa83d9e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.012588113146293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.01,
  },
  {
    address: '0x60d418d75aac1751ad0b39157cfd24ed3010b5e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.012588113146293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.01,
  },
  {
    address: '0xdb753affb9282fb6808b1292d85b4f688fc5d46b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 15.012588113146293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 15.01,
  },
  {
    address: '0xf2f65eb12ea61571cefe90459561013f8bd1959e',
    minters_reward: 0,
    lp_providers_reward: 14.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.98,
  },
  {
    address: '0x92502ad18603475f691754c122ceb127fb7ac6ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.964558739614608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.96,
  },
  {
    address: '0xec98edd9cba2db1e1567b80600861061bd1b1672',
    minters_reward: 0,
    lp_providers_reward: 0.58,
    swapers_reward: 14.383912252227832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.96,
  },
  {
    address: '0xcf39e6ef02097d13d397577cb11a92892abe78b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.928366737979328,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.93,
  },
  {
    address: '0xd707086adba9c225a38a3a792b85847f1760c4e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.9251732248222,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.93,
  },
  {
    address: '0x55a49a19069348c94f52e6be4055441246cacfde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.9124714460807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.91,
  },
  {
    address: '0xeb2a2660c9906b0fe1e77f9ee26a523250f22f43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.91,
  },
  {
    address: '0xbabe930523a45f6bd95f9ed072825218912ad559',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.91,
  },
  {
    address: '0x6f515d5ddced29c9a5beba2649c906a94fb9e08f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.893612044455741,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.89,
  },
  {
    address: '0x689e9044ea89b17a40eb19740354140483901ba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.869522858124046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.87,
  },
  {
    address: '0x4efa4aebe9f040bce95149f8bff8167d213dabd2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.865749200098863,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.87,
  },
  {
    address: '0x8795a2ebc6af216cd95ba227f5eda3b5e4e7d773',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.852119120101277,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.85,
  },
  {
    address: '0xbb2536aa40fcd45907597145c9441d5e9f32050d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.851710103929888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.85,
  },
  {
    address: '0x5bc470907961c2dd8bd6a32b5a51f851a92e21cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.835545048552905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.84,
  },
  {
    address: '0x5cdd36a49e1ed26986fca5c3045313f5163815f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.82755341037587,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.83,
  },
  {
    address: '0x4bda0f253455a8f9a9053d615d85d75222a85553',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.803066882861925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.8,
  },
  {
    address: '0x8333fcd3ad2680b0d7cc87bb3ed0cde9d17fcbf3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.803066882861925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.8,
  },
  {
    address: '0x3cb27083412c952e5ae4c37cda8e057127a8b4dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.800229701490572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.8,
  },
  {
    address: '0xafa6732514ffe8cb4f4e180374b30e5c5afff39a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.785977368587188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.79,
  },
  {
    address: '0xfffc507514eadd9a122253793eb3d8fd4e557a92',
    minters_reward: 0,
    lp_providers_reward: 9.2,
    swapers_reward: 5.590216446167765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.79,
  },
  {
    address: '0x8690564792943845c767703846a4ace76df6e10d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.755340108526733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.76,
  },
  {
    address: '0x98b013a7f3dd0e4514106c9ebd624cd555e8e704',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.754869460563674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.75,
  },
  {
    address: '0x92480ff8d44bf8ba65dbfc0c9548400cc9f16326',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.74936484637551,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.75,
  },
  {
    address: '0x62a16bc1364dd576b34f32d45ca64224a1c101b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.742178292561354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.74,
  },
  {
    address: '0x01c4b95326e32d54de70abe203ed2c3e34c6e223',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.734840184785732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.73,
  },
  {
    address: '0x909aab229f8ad8ca6e113ed8358172757607c099',
    minters_reward: 0,
    lp_providers_reward: 14.73,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.73,
  },
  {
    address: '0x94be72493f0721abe487dd009062536f01cbffbf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.72,
  },
  {
    address: '0xad107aa182855f19ea8aa802aadef4d7625cbccc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.691080650397662,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.69,
  },
  {
    address: '0xc7787083ec5f1d5fd6aecfb021da37a2ce00a5e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.653509920694669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.65,
  },
  {
    address: '0x74644e7a6c0a19b2f2234513d06a07b4abf53bfc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.653444998058117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.65,
  },
  {
    address: '0x79e45dd965d52cefd7145942d8fe9ed04a0d5ad4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.643367119232819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.64,
  },
  {
    address: '0xf0f62a092f313c2d1ddde3da423a91605967ea0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.639144421682337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.64,
  },
  {
    address: '0x47d87c231aaba73644080a2f905f39fe6bd532ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.611122379241511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.61,
  },
  {
    address: '0x0b51be9cf911a305b98f366ca13cf909f188f714',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.611122379241511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.61,
  },
  {
    address: '0x13bf4d7f9bc5745ce03855463ceddcc180a8f63c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.595180617812861,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.6,
  },
  {
    address: '0xc48afde59352ce5e0241e99e02dfd30a3bd281be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.585782951344571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.59,
  },
  {
    address: '0xeebad714947915c6ad6c11a4bb4bf7745b434739',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.58478723785829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.58,
  },
  {
    address: '0x6514aa286555449cf00704f1543a1fee288603d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.572826305527526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.57,
  },
  {
    address: '0x8f23e54f65b8e293408eafefeea2fd7b5a2bc96a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.556605287577904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.56,
  },
  {
    address: '0xa72eca8e67a955e9db7a4a1b3decf86628857ccc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.553617558549774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.55,
  },
  {
    address: '0x6afb0e63fc03f77f69138ccfd7407e06d8a87761',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.536365867612089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.54,
  },
  {
    address: '0x4245a5ee4db5ab3679f834535b4527a87f3573fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.522990004486626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.52,
  },
  {
    address: '0x237ae8550cae9381ef0e3ada2c4264f447b7b9d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.517503905365238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.52,
  },
  {
    address: '0xebba6dda488aca009674841e7be39a39a87d2fff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.508798361327367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.51,
  },
  {
    address: '0x1a48b5f7334c82e0122551d0ed697623969c987d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.501089645821942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.5,
  },
  {
    address: '0xe6e92bbe059a5608670aba50e7a27acfd417a981',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.4797441884676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.48,
  },
  {
    address: '0x1eedd1f0b821fc2d94f07821d29c9484425b3d39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.47605269304022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.48,
  },
  {
    address: '0xd753d6aca896b996863d2fd802dfba92be904825',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.47455372276705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.47,
  },
  {
    address: '0x45a0accbac89c64b5662ab7fda5b6b10c8f1ec99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.47310760093314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.47,
  },
  {
    address: '0x049902f2b9dbbfed423ef34496d5c230dd801f21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.46106828675727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.46,
  },
  {
    address: '0x17694e06845e7619d303b5ed52305c6d91e8c284',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.43514556800271,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.44,
  },
  {
    address: '0xd24eb250b97290a838f19ad7252e6b3bb89a1c99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.432947453375743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.43,
  },
  {
    address: '0x1499b2e34acb5501392ff80f2c8ca10de5c5708d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.42226433237981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.42,
  },
  {
    address: '0xa955735a93b00ef8c5974fdba12f331b6a51ac35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.41328364360816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.41,
  },
  {
    address: '0xd9e7e96a5b9bc1c519dd5287486b641cab1b6197',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.40510749650474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.41,
  },
  {
    address: '0x7cfbaef2c5214ec1a30f72b4f177fd39032da020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.399605172180914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.4,
  },
  {
    address: '0xe216cbfb74a6bbde9805cdb89c677ae1b70bc065',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.349348919605333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.35,
  },
  {
    address: '0x053422305ea27a39674bbea30c6d125eb984a554',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.328805799494424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.33,
  },
  {
    address: '0x8b3612b8e4922e4332b3b7bf9b4dd2ea021774c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.309426321005473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.31,
  },
  {
    address: '0xf3dc62677e343063aea1c49de761bd982dca22e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.302966950293133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.3,
  },
  {
    address: '0xdf830ce13ce56367bd03f8c92a13b786abba994c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.294649427214436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.29,
  },
  {
    address: '0x0be3fd318b07b4f460086b4d47ee5523632c7650',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.243728136864776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.24,
  },
  {
    address: '0xd26d44eca73d5469df22c55e58bc738ac104ed8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.234064331613943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.23,
  },
  {
    address: '0x938a03e67e0b7de5f060b88e028e8ca19fe9e731',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.23406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.23,
  },
  {
    address: '0xd46af007c25941d22f68af5443f9fba8729b5ecf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.23406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.23,
  },
  {
    address: '0x31bf477251a10ed0b22ee3f350a05db86d4db5cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.211131708298206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.21,
  },
  {
    address: '0x96a5ff085281a5c62255ddaa93196760d83dbcb0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.210628864718215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.21,
  },
  {
    address: '0x1f94f97706254c944db2fc790674a52143bbd124',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.207226732519795,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.21,
  },
  {
    address: '0x46004d41f2cd66e8aeae78c98f7807fe9b9960e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x406629f8b74c2666ede0bf559a231cd0da293fd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0xd056602108a7fcb8064ea6b43591af5420980136',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0xcf79b206933198655027ed487c6aa1896e31add6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x6acc539a80187f30665c9c14fdddccd65cedc885',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x802630fecbfd01abac0c141f47bd61ca3f65e32a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x08210cdf531bf9433e0172895dd42b380fcdb8fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.198246531701335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0xe66ef175f7d55d592b2d7c249372287cfe258553',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.195899429241242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.2,
  },
  {
    address: '0x4b63eab799d31edda576d9ea320b3cff371e6c49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.176237035363844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.18,
  },
  {
    address: '0xd96dbe4a3d4ddc711270b6e2288e1cd498bca377',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.176237035363844,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.18,
  },
  {
    address: '0x20563d9627e41bf347b4cfef8c87ddc58524ea96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.168185368426052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.17,
  },
  {
    address: '0xd8102c196728971840a2bf4aed978df223fbcb77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.137010692849422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.14,
  },
  {
    address: '0x4080f840fbaaba731f0aadb1b7f87b1891d1a373',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.127813442837668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.13,
  },
  {
    address: '0x67e8a3f7883ebe681f3131c3ea113cf377328c86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.088294602159886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.09,
  },
  {
    address: '0xa0adeee037bc410784a20e7c89e834e2f55bc088',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.086989077469989,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.09,
  },
  {
    address: '0x11df641b32eff9efc0a3ca37a7b31e5d1d9ec8a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.072018596198001,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.07,
  },
  {
    address: '0xf9109e09efcf536af79566d72e43a8b2c91c6850',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.06851878476031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.07,
  },
  {
    address: '0xbfb2cd43f6f103c9e6ec7a4ad6813d259489513e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.060662913331488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.06,
  },
  {
    address: '0x40ebed799403ef62f1ba1e150396db5ae10b2673',
    minters_reward: 0,
    lp_providers_reward: 14.05,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.05,
  },
  {
    address: '0x9b05e04d6a73c01a70ddf773083334e9c218b6ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.030485405002814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.03,
  },
  {
    address: '0xd42ddbbe129d281fe60e133126a68716a3f96af6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.01288531310635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.01,
  },
  {
    address: '0xa53029d25e64f6150e967caee6a30eb114ede07d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.011743750062475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.01,
  },
  {
    address: '0x46fb77747487a75dd7c93bcfdb2f3868e90631ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.01115166190848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14.01,
  },
  {
    address: '0x2412ae253fe0d1ac3b7dcb7b6f26690c83f67682',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.00301983952595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14,
  },
  {
    address: '0xbec74d77f40cbb40f5b79a31d1c3afafb5b69d91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 14.00301983952595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14,
  },
  {
    address: '0x916b99fd8a9a9bd62f4876d4fa4d23aad4bf0746',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.996247742823163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 14,
  },
  {
    address: '0xcc18deef53b15e11a99bf310b16ca3222cbccd8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.990651004677595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0x77ed7f217d6dacad4f88e1d9f7939006ff6c3bae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.990651004677595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0xacbaf31ea1aa8221200a10683a741a284ee7d382',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.990651004677595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0xb7a32a428a388e9d48abf54d4500dc5d92f1778b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.99029473975505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.99,
  },
  {
    address: '0xc55b5a37e0567503a7847dabe4005bc1b91b553d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.96298783396984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.96,
  },
  {
    address: '0x7723c9b88ba6f2d6fde53b6301b41d778a06ccf2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.958888009351252,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.96,
  },
  {
    address: '0x43784716d1b80fd4fe4ec525dc3b544eef0887b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x564391f83fbc4b044b4c317ff363e9be14d3bed6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x5f885705adbb662d7b7523a44771f6d19b5f93f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x495a6f91cd7e8fd1a3d9fa109f85e68d26fad0fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0xc6deac50f876af4de3b075fc190598a042ef3914',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93837696001912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x875bde4c417e22035c03bac45bb953d8000aba70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.938307268482776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x3a9f3ed8cd3b657512af920c60f3601d476daaa5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93718013232259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0x3120212c44f19ee4cf4b63c31e1f0d9ef7bc14f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.93689349841435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.94,
  },
  {
    address: '0xe25cdac1887842e55451bdb7f61b4b03c9dd27c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.900937824895806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.9,
  },
  {
    address: '0x71bc3a5748fdf36ccb99627b1e950318d7d1d3bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.888659158853496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.89,
  },
  {
    address: '0x1aef83df5720e822769bcc74cbcab2ff9746c52c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.864748353663472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.86,
  },
  {
    address: '0xf59c8b343a0ac84fe3f95a98e43fb1b4c1097634',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.852090849421804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.85,
  },
  {
    address: '0xea8cc4f399c89552f8eda2f02be4f747fd3caba0',
    minters_reward: 0,
    lp_providers_reward: 1.55,
    swapers_reward: 12.298603144023078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.85,
  },
  {
    address: '0x56507403e7d0004f5180b4a244c6a544876ffee5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.798381324364803,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.8,
  },
  {
    address: '0xf578258b3079612b64cd5922411262218acc7530',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.795830528193305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.8,
  },
  {
    address: '0x028b7aab30af8ce43c828c542302eb341cee4ef0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.785781636215974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.79,
  },
  {
    address: '0x5119935607ec96df15d53ab7d37cf9c9185cb2c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.781240423264704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.78,
  },
  {
    address: '0x564eeefea2f144bc8b18c8e707eb5d10dac203ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.757007599183293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.76,
  },
  {
    address: '0x667bc76dc451919fca56b75d022bd30cbc66f169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.752272138049198,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.75,
  },
  {
    address: '0xb6138056df5c54ee8be49811e377f90ab21dc10f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.745064106325446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.75,
  },
  {
    address: '0xff294b5d01c07fecdeaf76c5cd9bd35462da8e4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.724161692077338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.72,
  },
  {
    address: '0xd7f9fec7159af8abb34faf137e8f9645163e0c59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.722657822843418,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.72,
  },
  {
    address: '0x2cc8b4e29f14c5002062868627f69fe5be2ca76b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.71,
  },
  {
    address: '0x706f16d9554172d35183dd6a556c160030848777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.71,
  },
  {
    address: '0xe81a1fe9ecb3e376f879142b22f8d3cb183952ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.708054117031121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.71,
  },
  {
    address: '0x4afb88178c35f74a9b6caaf017641963eb3315ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.686956822895572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.69,
  },
  {
    address: '0x47d5713b73adead0b0c90d34826c01bf360e622b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.633984176696895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.63,
  },
  {
    address: '0x0b7c5adcc4255c6f048b8cf8af701f7e70472508',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.612694852918676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.61,
  },
  {
    address: '0x2e7957b36507257a4092b82387715b9386f5006b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.591257901237045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.59,
  },
  {
    address: '0x498a1e3d13b5640e2eae64cc62d185b22d9a9298',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.585072649926815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.59,
  },
  {
    address: '0x456b896183485e4604740d2d4c9413171f49af4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.57762027541614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.58,
  },
  {
    address: '0x9e0fa34ec90d52e7454878435f5b23e217441802',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.57762027541614,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.58,
  },
  {
    address: '0x9be78ce3a70cf2231ee60f5361656525522a9fde',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.574892931153505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.57,
  },
  {
    address: '0x794bf0178c921a8fa106e85906f2befb64b5112a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.567717947771627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.57,
  },
  {
    address: '0xe1f16671a5bc90ff10bd1e72327e47a7b5563f44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.544453080019682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.54,
  },
  {
    address: '0x46154b47c6d35c94a955a0d217a00eaed630a128',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.544453080019682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.54,
  },
  {
    address: '0xe5250e69dae685298c639ace8b4e954358fad441',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.497023830671035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.5,
  },
  {
    address: '0x700818452a814372024d644383b2337a821c877d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.466292535935013,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.47,
  },
  {
    address: '0x789a09bbdf75f655c6b2ca9b0c1c01d2603dbc37',
    minters_reward: 0,
    lp_providers_reward: 5.54,
    swapers_reward: 7.91466936486322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.45,
  },
  {
    address: '0x0290e66e1f711dc5f8680e773cdec48aae117460',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.387259496675522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.39,
  },
  {
    address: '0x306600988cb8cbc9c4c7d35210625a0b98842346',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.385278348059664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.39,
  },
  {
    address: '0x5b82e8347186cec17d9160b31ea016263fa3e650',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.381134214061268,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.38,
  },
  {
    address: '0xc6e5380db09a4cc0551f4c5d69ddc81d712eb946',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.367443624489848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.37,
  },
  {
    address: '0x17eddf5f30ef6eccc44e9468b1fd7035142319f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.354793128568605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.35,
  },
  {
    address: '0x47003431fb44fdba2a0a5fd01cc305e5ef62181a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.336745264019584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.34,
  },
  {
    address: '0xeb4b2e9f5f3100debba512ee0d95647fb3416062',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.335796951717471,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.34,
  },
  {
    address: '0x3080bd9c9df314b6efe7c0a0a0d13acc23ddfc6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.333019659647364,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.33,
  },
  {
    address: '0x75c26b2b971ac740d2029575384e82017ecf9062',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.31523145387121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.32,
  },
  {
    address: '0x41e2fa384159f92bd990abdac4636c8d102d8354',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.260124809203571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.26,
  },
  {
    address: '0x5dfdb0e9685cf73892691c4d3dae2ce4a9646bbf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.241500206801106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0xc164ff4bd893b39d174998e61d079c4c025c0945',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.240800832925313,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0x7193e3c9e5a1d587ef57740b1791212b5a7255f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.237342395858597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0x48911f1564331b05264dcfca6fbea9c54702eacb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.236255294031185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.24,
  },
  {
    address: '0x33a8c56921ffe1c425dede4118f98ca4fd887a4b',
    minters_reward: 0,
    lp_providers_reward: 9.56,
    swapers_reward: 3.655934654402034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.22,
  },
  {
    address: '0x3842a6271c4909939eecdb9e7ac33d2a5092c907',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.207430024088618,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.21,
  },
  {
    address: '0xfcdf4e821bf425222c5c477395de38dc2104f7b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.206528639804272,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.21,
  },
  {
    address: '0xd4386a878b76715537b2f030f11a4ac933906daf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.199220963624581,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.2,
  },
  {
    address: '0x9cd5fc162bae2adf1054b2dd3d8c3646b73fd8c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.18708581466595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.19,
  },
  {
    address: '0x1dcb3192f5f62f84abea4f5d3cae046069496613',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.178640575095127,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.18,
  },
  {
    address: '0xc4eed93b1d1b0bc0a7de86a9ad5764fd9a07b1a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.174685203938632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.17,
  },
  {
    address: '0xf4db81be8b8186cdffce5f5ea556580f5a5a15e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.157215105154027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.16,
  },
  {
    address: '0xbf6e4b16b1a3793a19a66849a30cda656c78d33b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.143090263187766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.14,
  },
  {
    address: '0x9c0d2fa34f919d25299641be3b689d42cfe31249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.129920549027748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.13,
  },
  {
    address: '0x7a54fb2579ed5bbb8784033ee59ec7ce337635ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.106215774600882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.11,
  },
  {
    address: '0x4d2caa1d51a2479cde8a28e691395c3a0eb61949',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.076745478066455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.08,
  },
  {
    address: '0x0f3b34d78c9f377b53d37753676a14e989e0e77d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.07098951470934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.07,
  },
  {
    address: '0xaf643ef70d14a736ded6692b6ce3eb9904e8785e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.065754821404196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.07,
  },
  {
    address: '0x85df39349534ca69e928711363964c40bce15433',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.065483083961617,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.07,
  },
  {
    address: '0xb7f1bc0ddb2c583d532c2adb474cb2957d3acaa5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.062724271220699,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.06,
  },
  {
    address: '0xf767113ea9cd946fb5264c4ba8c5213325c9f827',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.056047591491932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.06,
  },
  {
    address: '0x1fd23bd1edb40101e971c46b99628ea0e8d00a48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.04906646198771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.05,
  },
  {
    address: '0x9225d5430c4057d0e7e6f9287d1f7be1c75d9076',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.042992564637816,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.04,
  },
  {
    address: '0x7e143f5e4c668b6a3c948651b435ce44679a65b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 13.032382860420771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 13.03,
  },
  {
    address: '0xa61b61d0c3d85f6724af6b259795bdffdf3aa284',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.970686998201016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.97,
  },
  {
    address: '0xff289a34eb119d20e59572f5da7a7b8cdb5e7314',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.959214971809654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.96,
  },
  {
    address: '0x4c61dd60cd7f1edd98cda7c1f2aa5d9d0c917cb5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.912311222814417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.91,
  },
  {
    address: '0x1d8df28ac035dd22595afefa0e5890ce730d0c02',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.912311222814417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.91,
  },
  {
    address: '0x60dae6535daf542fead19d4fde90337e716d18ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.90477573319812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.9,
  },
  {
    address: '0x6d1172e0c3148f537b5b63908c5a29324f85036b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.866521408160619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.87,
  },
  {
    address: '0x0f0728f3b9386a7808ee25108e51e58a1c72ffec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.858811122026827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.86,
  },
  {
    address: '0x3fc62b9f8daa3a8c559f832fd813a9d708961dd4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.854204498457301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.85,
  },
  {
    address: '0x85dc5ca057c612b9955da1bd58323c7af76657b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.782622012946582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.78,
  },
  {
    address: '0x20fdf58bc0d511064f80a2adfb07944ea42df497',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.747040805360912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.75,
  },
  {
    address: '0x20fa5ca22eb2978c267d15fd9c3b644d95e20237',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.684603170119463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.68,
  },
  {
    address: '0x88528857b50b9332de0bbcd8e03ea106ba8b734a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.670306055483952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.67,
  },
  {
    address: '0x4dc5680a8cd295a5fefb31e7d0fb7e2a9e6a137a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.661846813009937,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.66,
  },
  {
    address: '0x1879609a255ecdf6a2e079a885f291d3cb610aa7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.603639101139764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.6,
  },
  {
    address: '0xebce4b97f038592ae859eb82fe9633fda98733c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.569541949743096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.57,
  },
  {
    address: '0x517499b23f8a778c58e2e4ab2399e639150ac972',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.569541949743096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.57,
  },
  {
    address: '0xa8da841e16ba89902ee236291871993bdb320205',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.544725867930548,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.54,
  },
  {
    address: '0x19d29a4e5fc951cc1363590c03a689aa27688633',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.497504504432527,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.5,
  },
  {
    address: '0x2b47fb20a7558e38cf4e45f2c200bc39905b8f6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.488908315097953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.49,
  },
  {
    address: '0xdfa93f9d6b89408f79e35eb629444e2bc7194cf2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.453927051529265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.45,
  },
  {
    address: '0xef90572ed625c9cf115ac56a03c00092eed7527a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.43322821952763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.43,
  },
  {
    address: '0xefc0fd59e8b897bf9e38c438aeca231f68adf19d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.416427470240324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.42,
  },
  {
    address: '0xff5db07e13779edb918a10b70fb8840747b36039',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.409214082507805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.41,
  },
  {
    address: '0x81ddd2cf7dcea027b873885a05826d319cbfde42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.403173189532096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.4,
  },
  {
    address: '0xc2e02bf8b8f99f5a16f928ab7f6205489aabdc4b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.403173189532096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.4,
  },
  {
    address: '0x8afee15fdf261096309e982c2aab1e70b7a4c42b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.373969065647632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.37,
  },
  {
    address: '0x78971d6a7f3c6f0ec06fd99106709f9bd36c9643',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.342438376202317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.34,
  },
  {
    address: '0x95144c1c847fdbfd27ff5bce00bfa3df41859ff4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.326499424261351,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.33,
  },
  {
    address: '0x3e2eefd669e9f1b105c0bc6a604171c458906cf1',
    minters_reward: 0,
    lp_providers_reward: 5.42,
    swapers_reward: 6.890196142190878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.31,
  },
  {
    address: '0x18479ab88a117731e07db9d15716c38021dbf35d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.275423108249294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.28,
  },
  {
    address: '0xcf1ff5f09c9ae730087c425223580f94c4efa720',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.272339021452764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.27,
  },
  {
    address: '0xf67d5de18b4957cd7cbeacad203fff8f2e8fe96f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.236082078022628,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.24,
  },
  {
    address: '0xfc1ea811effa6126c940b870556e990ff73919fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.190018357940843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.19,
  },
  {
    address: '0x8e38d9fe9df4b1674b84ad19e0c2ab2a8143cf4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.148745565725271,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.15,
  },
  {
    address: '0xb4a97cb53993a3b4af7d3b817f7578574064758f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.111714305903755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.11,
  },
  {
    address: '0x097c37ed5a6d4b970734b80ff756765537bef3d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.100756310356383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.1,
  },
  {
    address: '0x2180013898144b44fcfdeca2820aad6fb5419635',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.062381067990914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.06,
  },
  {
    address: '0x366aa48b7dbb0d764cde412b2ed6b168c5e0b021',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.048054044914668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.05,
  },
  {
    address: '0x8f626958ec428d258f6481b563126640f8aef54e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.041254145820577,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.04,
  },
  {
    address: '0x6904172bbef6e828b03b0ef573347fcfb0f6161b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.040400040594083,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.04,
  },
  {
    address: '0x51a61e6bed080f19c417310955dafa4c8016c118',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.039843645440847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12.04,
  },
  {
    address: '0xe6f27141911095b5c4faa333fcf643d474f77d1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 12.002217256545725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 12,
  },
  {
    address: '0x0d020ac68ad827d9b945041dd164560dc291062e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.98370830848409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.98,
  },
  {
    address: '0xaddba44365254a27378f60899bb142139b6db020',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.97659828646657,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.98,
  },
  {
    address: '0x3087b2eeb34c566d8db892583dea0f703051979e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.974178454771746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.97,
  },
  {
    address: '0xc43351b66106598acdd4ae65cdc70c2df084ce5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.951990870966105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.95,
  },
  {
    address: '0x96d61e6d427404f3b6f76df9fd264f49e231d0bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.93811264974556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.94,
  },
  {
    address: '0x39ab2c8a0ea32d593a179a5eba1fcd8b4ade2505',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.917410619727969,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.92,
  },
  {
    address: '0x1eeae21b569dd6cd288113671e6aca2002ebe470',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.817729449443284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.82,
  },
  {
    address: '0xd823bdb2602a05b85c7f815d2a7bf012e2c1c5ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.791765905438842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.79,
  },
  {
    address: '0x321e3564ca989681d145b7db7a52e2a9fbecb117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.774735726841886,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.77,
  },
  {
    address: '0xc03056470257b9becf95e61c16b02add521ebb50',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.769869598060474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.77,
  },
  {
    address: '0xf355b11b6778f462cb79760cfe3fcd578e95ef54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.762682604197632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.76,
  },
  {
    address: '0xe4cfe9434449aeeeab66ab98d72f538e1937271e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.74631420423259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.75,
  },
  {
    address: '0x5bb6da0c430ca2c00f92945aa756115834cdfa46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.711901330459655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.71,
  },
  {
    address: '0x39cbe65f883840615b0acb2ef37786c408f9be70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.711770092161188,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.71,
  },
  {
    address: '0xd3752050126a82e93dae505762d2a519b805743c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.702014946038028,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.7,
  },
  {
    address: '0xba1bf4309c4c2ad6a9b0f42b6728631461da57f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.671736236154317,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.67,
  },
  {
    address: '0x5ec963005c1901a25fa4dd77ec033ed88230fe71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.635890733093,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.64,
  },
  {
    address: '0x8edd3371c6fc8a5fa618f5cb4bf06357450108ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.621163147164173,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.62,
  },
  {
    address: '0x1f06f58e6aef4a5e83b305fa04c4cc5a38db24fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.610436135434774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.61,
  },
  {
    address: '0x2b4514fee73e0808df63248f4d4af5f2ff8aba74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.578666036043694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.58,
  },
  {
    address: '0xeed9ecbf925b621ff5fd2ce6828bfb2940f48611',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.578381345209392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.58,
  },
  {
    address: '0x8113ce7e4e62de62495a7053364dbeb706c392f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.53014656380224,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.53,
  },
  {
    address: '0xa8a5efa1b33eb20889fc414240223461bc19709a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.51072248590815,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.51,
  },
  {
    address: '0x7887e46384bb81566b8e432ea0492b701ca7750d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.497864242835693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.5,
  },
  {
    address: '0x6bed9041b9d7033ac2de2fbf23483758e263aa64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.497697912746876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.5,
  },
  {
    address: '0xc19fba746656bdb136564b4bfc57346a65c7543b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.418994237966654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.42,
  },
  {
    address: '0x9ae6581cda4f9f5d516327ce78a2cdeec1492717',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.39378443501433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.39,
  },
  {
    address: '0xa38e66b181803f8e5135625cf5287c7fb7b767c9',
    minters_reward: 0,
    lp_providers_reward: 3.01,
    swapers_reward: 8.35901234010258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.37,
  },
  {
    address: '0x0f9735b21dd155ec9a2c4dbe6c7411aa97a388f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.307902276490749,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.31,
  },
  {
    address: '0xd91d065a1f45b3e51e9dffab1586026c605d5931',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.291771353430677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.29,
  },
  {
    address: '0x668cecd7ca16ebf9fb9c7e2c4304a38b79f9e4d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.255554972795078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.26,
  },
  {
    address: '0x36aec4013dbc48be984d4eb080bdae53aff0f6e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.253902644830877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.25,
  },
  {
    address: '0x5418af6fef00420e5b732bbad47f0d65bbd87578',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.205899710715475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.21,
  },
  {
    address: '0x2b50867a1002c6a31f2bf3755e74141cd4af7ea2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.200003400549802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.2,
  },
  {
    address: '0x519821cf7d4215e8aa045b8af1852c2714510cc4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.171778093365408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.17,
  },
  {
    address: '0xbb28ea42f4fc4195a71084e3fd8c204801b80711',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.152141410671609,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.15,
  },
  {
    address: '0x750e83642edc02866269c5e831a97226ff27132f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.102969416968733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.1,
  },
  {
    address: '0x9c80819dff82f4ed133491b9ea5b1e82aee03a53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.0232627846378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.02,
  },
  {
    address: '0xd64584f7ab321d65d95322884bb2ef62a16c64f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.011828881479422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.01,
  },
  {
    address: '0x6a9f9263076803641da308d909af1becaaee81cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 11.009794693889063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 11.01,
  },
  {
    address: '0x10cee5b91695048487e10d4583b21e38a3fcf1a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.990656795918902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.99,
  },
  {
    address: '0x3eb73f611f5926d8a78c3893cb9e759dfbb521c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.986153178516632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.99,
  },
  {
    address: '0xdac081c2de597914f23d3aacd63eb09e2fc93923',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.984433277811489,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.98,
  },
  {
    address: '0xd90994646c1a9b541b20f66e4c791a9095b655db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.960339953149502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.96,
  },
  {
    address: '0xd5cdf0821de32d2f426ddd043acd9037aa5aacc1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.958958965855905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.96,
  },
  {
    address: '0x94fbb8d5b4888cad040ce7c8d850e2036bdfb38a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.958219356599534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.96,
  },
  {
    address: '0x71b04995aae0c2d9c8cae9a53484b36f18313a98',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.952168787504522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.95,
  },
  {
    address: '0x9a15a776e24662e86155ba00149011a5b0f1ec34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.944792150424865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.94,
  },
  {
    address: '0x4c722ba6df98b09aa1159f97e938b07243910214',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.928562757947702,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.93,
  },
  {
    address: '0xe9f51ffb44f98c5b0933d43a123ee08a7becda5f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.927305125556197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.93,
  },
  {
    address: '0x1bfa385efadc6bd1a3fca3de29a8167fdb5fea15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.914238794261239,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.91,
  },
  {
    address: '0xf4cfd29e3835bddc99e1330fa5c028b5bfa0f61f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.877741663063745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.88,
  },
  {
    address: '0xef3c2286202f53ef20e1bb7ecf4f4a05498af624',
    minters_reward: 0,
    lp_providers_reward: 5.51,
    swapers_reward: 5.347695182523034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.86,
  },
  {
    address: '0x81fcf51540f48d80a39c2019a7ae2909716af1db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.82855293833218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.83,
  },
  {
    address: '0xbd7ace862a9e25f146f8523c66720aa2f3a72bf4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.811631773798082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.81,
  },
  {
    address: '0x946fc4090c5e7ce361707dc5704970e75583d81c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.80281976641473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.8,
  },
  {
    address: '0xe9eb78a0bd16f148dbffde6af31bdc1255ebf1f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.795632769172231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.8,
  },
  {
    address: '0x012ea0b1db520463fd14fa9c255cdccb6e91c25d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.759973509567688,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.76,
  },
  {
    address: '0xcdc5a0684c7cfc2418fb54767e583e351a31943e',
    minters_reward: 0,
    lp_providers_reward: 10.55,
    swapers_reward: 0.19230817099049724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.74,
  },
  {
    address: '0x88ae13c3b74ab4e30071b92e974d44b9ea0e17f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.70661831972825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.71,
  },
  {
    address: '0x18593d672659626631449f1679be25e2cd33d924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.705864438531002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.71,
  },
  {
    address: '0xe7fb81cbc8228b01ba71f277fc31c7f2dd41d297',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.705139113323286,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.71,
  },
  {
    address: '0x667b02a67dbee0a81237f0e9b87933e8c5b119b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.681582214885019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.68,
  },
  {
    address: '0x47cb92508279fd2bb431eea0ad82a18ceb63f7b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.676237626191464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.68,
  },
  {
    address: '0x50f1cb1b0497f45bcbfea0450ed9d82e6996f60f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.651898975754861,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.65,
  },
  {
    address: '0xf5a1f480df84f48b272e80724a7d755598f8bddd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.636239468567611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.64,
  },
  {
    address: '0xf2d1b17a69dde246178b808ba371da08dae99ae8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.629456653465253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.63,
  },
  {
    address: '0x986da69269023ec89486e6eeca3301178170f86d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.591076595399054,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.59,
  },
  {
    address: '0x62d4177005bed706e5e5272832a30809751329af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.571001659211827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.57,
  },
  {
    address: '0x4fc8c73f1c8e9988bd1c83a5cc33ecb555c02323',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.561542047080557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.56,
  },
  {
    address: '0x133bc5e31952db7be708ee6f915de1539fc54225',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.561168286053283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.56,
  },
  {
    address: '0xf29c93484f1d13da2f117c952488b3638088d774',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.557377023819829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.56,
  },
  {
    address: '0x9765d62a07fb0dc3ea7a8726f1ef7a05774bc4ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.552213917855555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.55,
  },
  {
    address: '0x368ccb511e9fdb2a0586b438af91275b6175e7ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.540662027360503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.54,
  },
  {
    address: '0x5175fe7f02505dbb9e9d1028045569152bd70ad7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.5111528577403,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.51,
  },
  {
    address: '0x8ded9b33cfa04ccb1dacf8c24c8da600f57f3baf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.468170564943355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.47,
  },
  {
    address: '0x407ad0f23f5ded868c5d10569bd73b06261f5556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.465390333512454,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.47,
  },
  {
    address: '0x5a03123e9e5ad9e4a356c11d680b41b47d24002d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.453938223275458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.45,
  },
  {
    address: '0x785cede2a5ad7c6f86b562cae97aa5f206b00ec5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.453797469121586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.45,
  },
  {
    address: '0x2af90c6c6adc0adae2ddae1925110cae1275b0f1',
    minters_reward: 0,
    lp_providers_reward: 7.35,
    swapers_reward: 3.0498287061168208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.4,
  },
  {
    address: '0x9758e6ef9bc6e5e485b19c2f897ff10e670eb263',
    minters_reward: 0,
    lp_providers_reward: 10.38,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.38,
  },
  {
    address: '0xdeed3275ccc6f0f81100afb1a5c47e272f0751bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.353122052352914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.35,
  },
  {
    address: '0x517e00849b78c4cb487b25422ce29a1d4c415151',
    minters_reward: 0,
    lp_providers_reward: 1.29,
    swapers_reward: 9.048297065178078,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.34,
  },
  {
    address: '0x221cecf21cd059378a2b9758b835f40dca52f803',
    minters_reward: 0,
    lp_providers_reward: 10.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.34,
  },
  {
    address: '0x7c1c586e01ae41a8c1e8f3e75bd86f7f154cf6d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.325659005936302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.33,
  },
  {
    address: '0xc7eb15e102f1ff71056bc8b13e1c0488bce153cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.28647076414029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.29,
  },
  {
    address: '0x348fb6c1495b9f69f414f1b4bf2370e35c88e5f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.283559858025436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.28,
  },
  {
    address: '0x674c0120977875c8bc3a3d9313033bc8adc7bc20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.280490901805537,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.28,
  },
  {
    address: '0xf9fa3278b32d7f06f5875ebc29c47b9f69803b9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.24673280192025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.25,
  },
  {
    address: '0x274e7a7808dceed01bec0045af388d6e1ef8822d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.181947992863314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.18,
  },
  {
    address: '0xa032ad0b4f14977e5f29cb56557ad3d62ed05b04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.181540250428746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.18,
  },
  {
    address: '0xebeaf2e85173586d4e0dbc6c7bde5edb8db63bb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.17109987412544,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.17,
  },
  {
    address: '0x48720a7b117e52d13231dee5b735c11377ef9958',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.148378246385954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.15,
  },
  {
    address: '0x4451b5ddbce63df82b96afa7ee22479151e38c2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.144905417461645,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.14,
  },
  {
    address: '0x1ec9f531a0672679f34c5c9c3a89938313801391',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.130122290831265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.13,
  },
  {
    address: '0x61096c851fe410be4fbb23fe348fa4c3e99c6aed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.103332620644741,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.1,
  },
  {
    address: '0x461249db6144d503ea428000dd0c7b9412749708',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.028542471111518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.03,
  },
  {
    address: '0x53f51b6debc2ab4d44884b266f0f78f275eace23',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.016034153932766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.02,
  },
  {
    address: '0xa52721a34e1ef17f977d4af583df123e53f1fe80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.007402864388405,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.01,
  },
  {
    address: '0x7a481d045e9ab9d079118334f94bc8a965d1ca49',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.006254599050106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10.01,
  },
  {
    address: '0x6295d41bf8021cf1070130be5591652324fa5d6a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 10.003319676809113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 10,
  },
  {
    address: '0xa8b3adc2af9fa5e0acfaf2f4351e088d870b2ef3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.989224824641765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.99,
  },
  {
    address: '0x94f3771a416c54269014bc753d1e6d134a8ed5c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.976304647950935,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.98,
  },
  {
    address: '0x3a9a33430efcb0c94f82fb165aac140b71a1c1de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.945058712763855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.95,
  },
  {
    address: '0x54c540cbdbaaeb15f881a0433eb1260103c0e1bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.927650197840025,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.93,
  },
  {
    address: '0xb386a4b00c10516b67501402f025424330fd3fba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.914019428038559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.91,
  },
  {
    address: '0x63dc220b050451ce66b8ca9a304998568e0315e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.912447030021003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.91,
  },
  {
    address: '0x536223085482d17449620eaebbdb7260bccdf4ce',
    minters_reward: 0,
    lp_providers_reward: 6.08,
    swapers_reward: 3.818929065706719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.9,
  },
  {
    address: '0x3704b99a87a4b957f78aefab1b70e26442b32917',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.849485150127794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.85,
  },
  {
    address: '0x1490297ff44019121d0d85f91f933cace5f7dda7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.829159568620016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.83,
  },
  {
    address: '0x8fd607e6a83f323e6dab201280727a5379168a81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.823032715512158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.82,
  },
  {
    address: '0xe00f75e96a79583c818039693bb5e35b52b4a8e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.820554138484477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.82,
  },
  {
    address: '0x1714a0f0aa120d7358c9c40d43df37bd68c07d8e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.779031366970996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.78,
  },
  {
    address: '0xdd1709343de72150263186f08259255292f18acf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.753289599796402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.75,
  },
  {
    address: '0x7f7ce3318deb501fd6ab823b389592df7fe83991',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.727927160736389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.73,
  },
  {
    address: '0xa53ed7212e5ae32ea70bd1df14c613414265d7f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.706492438943517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.71,
  },
  {
    address: '0xc051ee242994f138536fbeba6c61d4a32cf7cfa9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.704914066269026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.7,
  },
  {
    address: '0x8f024d7b2b22a45577d378db9ebe38c8acf6d778',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.697828511099067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.7,
  },
  {
    address: '0x5daec7d73f1a2aabce8ed5cddd74b80be1b35001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.66559223957296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.67,
  },
  {
    address: '0x3f90d0c40ca0e111a8fe93e3eaf8be42804511db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.64061923003866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.64,
  },
  {
    address: '0x4e3f49b5a8a3eff98b1a3c78800673dbe207f3d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.622822270287081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.62,
  },
  {
    address: '0x6329d3ccd96a893165395c6a00ef4d5e4bd70aad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.612169025665693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.61,
  },
  {
    address: '0x15684d42685adbc9ab59e0b02c01c1d1a28565fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.610794030218837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.61,
  },
  {
    address: '0x82df609a836355bd89de03d6b0ab8a5abd9487c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.575317763092984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.58,
  },
  {
    address: '0xb28204b13ef54ac28c8ccf299657b15c85a4a9d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.573321726695417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.57,
  },
  {
    address: '0xe89490b790ea4e1ce5196a31841df117a2d4b019',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.554553018125794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.55,
  },
  {
    address: '0x2342a68421ca64142403bcf27a22cc4042cf0386',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.526967456196846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.53,
  },
  {
    address: '0x559fd97a0bfb9f54b4ce69084a8ee6d7a75fb6a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.525020388945729,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.53,
  },
  {
    address: '0x933068dc9738a3158ef0a3a952801ed1081954e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.503424312660414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.5,
  },
  {
    address: '0xcf55cd2adc5691eac3274b675cb777a07912010d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.481117156013777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.48,
  },
  {
    address: '0xdc98eb63b5777d356f18e933fdfa10455570207a',
    minters_reward: 0,
    lp_providers_reward: 9.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.48,
  },
  {
    address: '0x1e9e62c5e9c982cc783707531387edf3f538bff8',
    minters_reward: 0,
    lp_providers_reward: 7.03,
    swapers_reward: 2.404332270871283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.43,
  },
  {
    address: '0x8cd42a1069e4e4c2701e282fc5d421e11c694052',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.396429990592951,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.4,
  },
  {
    address: '0xdd628184b83d48af7e0b192879ccf22efbeb5247',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.396107178778752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.4,
  },
  {
    address: '0x0fc75b45d19888d7fbdcb5cbc5f43ca8e01308c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.360447929094533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.36,
  },
  {
    address: '0x993a0df8a0711e04c73e114be41ce27bf578ee9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.359433035195861,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.36,
  },
  {
    address: '0x232a9149d1f72d977c7ce969eadf176e077ee62f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.358835378019144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.36,
  },
  {
    address: '0x4be7cced5d1efff718e51b388d7ddbb6ca1989bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.339073786061324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.34,
  },
  {
    address: '0xda464c5184bbb689f905039f4d715abcc183ec58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.339073576570643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.34,
  },
  {
    address: '0xb41fcc191cd75f558cfd55c75b39a7edb42ad105',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.333017591566959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.33,
  },
  {
    address: '0x094f5812f27d30a7cdd1766f75e012c563e42ea8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.322439461561869,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.32,
  },
  {
    address: '0xb65e824f75cc3cef85d25fe75666f375621383a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.30986308067839,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.31,
  },
  {
    address: '0x06fd08a4847d840a1d4ce6b70efe8344271811ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.299409764004519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.3,
  },
  {
    address: '0xbe6b9e948d4dbd1284e048c50fc0055a863f2c0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.292454315320322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.29,
  },
  {
    address: '0x132b46cd0461cba55241eb508258a89acd5f7be7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.242043342102166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.24,
  },
  {
    address: '0xa47ddd4c53fb9804cd0b135f4f4f990e541ef11c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.239711005862018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.24,
  },
  {
    address: '0xdad1c1c28e746cd2e694b27e397da26aeafde6fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.218888697765843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.22,
  },
  {
    address: '0x3728f0352197658a86ce9fba036bfa21e006832e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.217277541978511,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.22,
  },
  {
    address: '0x2984602e9bb439dd2f8072dbe43dc082ec0e5874',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.141977990988302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.14,
  },
  {
    address: '0x6d3ae21708c006b6c961c55dde9a365432229490',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.120684141749583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.12,
  },
  {
    address: '0xc031189fdfdaec31a78416964a50c4e29a7e9a84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.116291620867067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.12,
  },
  {
    address: '0x77321cacae1c16b8e719543d3cdb876f2892fdd7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.086666433030148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.09,
  },
  {
    address: '0xcab96270939b029b073b6eb8459e990405b259b2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.059897553755041,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.06,
  },
  {
    address: '0x8f08cbbeffe245a373c3d56ff2e1d43d791d66b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.05701791934408,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.06,
  },
  {
    address: '0xaa8e662e2de09077db8e7095503cb62eaef0029e',
    minters_reward: 0,
    lp_providers_reward: 5,
    swapers_reward: 4.037079141331719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.04,
  },
  {
    address: '0x8e6192d579e82946b2012877428e906ddeec3e26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.032368357412757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.03,
  },
  {
    address: '0xda6141f766208efa920ae1db209f0fa94cdfabab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 9.016666414497138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9.02,
  },
  {
    address: '0x8b3f5d205f3f77185aa78f48bb6a07026ecb29a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.999229422764284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 9,
  },
  {
    address: '0x44d337780e4748fe77fc0e2dc3577e57d39a4c54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.977888757460304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.98,
  },
  {
    address: '0x7ae871976d9c85260c9775b66cc26e5fc9d069d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.966362733187719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.97,
  },
  {
    address: '0x588dd1d30814380e2356a67ca2c0b9c5c801bac5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.957693018392975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.96,
  },
  {
    address: '0x8413971a1a746cbf46e9cc03328283603e0c1155',
    minters_reward: 0,
    lp_providers_reward: 8.94,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.94,
  },
  {
    address: '0x30d7e1239261d3d0156e5e61c3f05fea1cd28da5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.932831873759266,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.93,
  },
  {
    address: '0x529fa3a03d7999a8b36f763a2246a10f6dc92677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.927459202802206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.93,
  },
  {
    address: '0xe7031f88f3fa396d4b7ab2251c66ef03abc5e804',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.925925835889519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.93,
  },
  {
    address: '0xeb59f73c85d5b57655aefa534cd2ba7787ff53fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.899771495983249,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.9,
  },
  {
    address: '0x135238e06beb828b6d1f5e39e62ce02c7f558b35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.887467108938386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.89,
  },
  {
    address: '0xb830c20d271f204b8093494b17ff15fd06cfd8cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.881840129717157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.88,
  },
  {
    address: '0xb859eb4dcbde7a8fa8e95d53671230d599068bf4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.881498442128008,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.88,
  },
  {
    address: '0x620bd8dfd8bce587b766cbc2b6996521504154b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.88144040952409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.88,
  },
  {
    address: '0x54b1838086f6650548b43f510297685317f5f29b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.870537230001156,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.87,
  },
  {
    address: '0xbfe5663e87c42e537e625f653cba6d4d368f0068',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.870190207770658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.87,
  },
  {
    address: '0xbc246f5baa3288fd0bea4b05ba94fed99859429a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.816678540810738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.82,
  },
  {
    address: '0xb894c98a8ad3050bc03e92fef6e34d5eb3a80e4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.813881158700553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.81,
  },
  {
    address: '0x36c7b80fc7172cf507623d13c65f947c704d5c67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.804174226502326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.8,
  },
  {
    address: '0x0e31c28bbb92ef58aaded692d67adb8bc3d03d6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.803894502012792,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.8,
  },
  {
    address: '0xb724cb75c6c51e8c5fdcef37d0215904a6bf0a00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.800859059484981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.8,
  },
  {
    address: '0xc34d2fbb93484c918fd1b02f34b5e07118766033',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.789996908268114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.79,
  },
  {
    address: '0xfdee859af95e5b49f1f31185ac31d706a63ff250',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.788372146952877,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.79,
  },
  {
    address: '0x9249fb36925538850f9670492ed4c50fe776a8ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.783170524417704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.78,
  },
  {
    address: '0xe1411b8a07fd0c1b7b9d429e62764f331765a3a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.750682722540429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.75,
  },
  {
    address: '0x8fa51d459bd4d3d7fdca66cb102653ff9cc4ab75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.750184367365827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.75,
  },
  {
    address: '0xdab8d72e76f6aa46919139e08f7ecd5379504a7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.73219142287939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.73,
  },
  {
    address: '0xcf2b49d2075ff3b6f1e5651b2626f9047c2c992c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.731313378004067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.73,
  },
  {
    address: '0xd94f357b063e78a0f8dcecf187e1260ea3d7d5d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.713805055492259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.71,
  },
  {
    address: '0x48f7b079980ad740a5429eea9c19e8ced9ce918b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.713534819086211,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.71,
  },
  {
    address: '0x44d95f5037082f83e7fd03daa73ba1abcd3c6b2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.710646422866319,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.71,
  },
  {
    address: '0xc84aecaae1dea6210513a399e054de16b0b64793',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.668268018616358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.67,
  },
  {
    address: '0xaf9e5abe565214c7c72ca93c68192a8b96f11d16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.66080483158736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.66,
  },
  {
    address: '0x7d8d9fe08157453c5db81be4c1eafde0dbb28f4f',
    minters_reward: 0,
    lp_providers_reward: 7.61,
    swapers_reward: 1.0385816355433337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.65,
  },
  {
    address: '0xdc894aa317170296630eabb0ac0270ffe63911b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.623411004781538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.62,
  },
  {
    address: '0xfadaf4b85f01c7fc828ae218502ac5fd3f517aae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.62294790336802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.62,
  },
  {
    address: '0x71f2b9412356c6226079842ee3dd6203df69b74f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.595729672876807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.6,
  },
  {
    address: '0x74f72500198fc8a4553764208f8eab54747c9ea7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.593914483592924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.59,
  },
  {
    address: '0x0040331b1042feb29d5fda19660fe76c03082980',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.577281105051647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.58,
  },
  {
    address: '0x9fda00695f137f8b129bd2eff36e7d6e71516f61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.562874362321402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.56,
  },
  {
    address: '0xce2ecfa8f627c76b297baed0e8a087afbf8b57ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.558587412451367,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.56,
  },
  {
    address: '0x44c2130c18cce60fc9d3d2f99fcf95301517fad6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.51587272597246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.52,
  },
  {
    address: '0x706a418509cfcd461b12f6bfa0d8d9f160eb712a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.504387391262458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.5,
  },
  {
    address: '0xf37ef7c2a539459c93483030ea6791bd677e6d9e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.492740633912726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.49,
  },
  {
    address: '0x92aabece5960a1e88fd4cf326b36a7d498c2f741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.44170636032502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.44,
  },
  {
    address: '0x3629a9f9fdb392331922ad2bbd5f6ee01a9168a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.396303746783977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.4,
  },
  {
    address: '0x3934b57b4c5d857e08ca6252f2e6b1a02c6a8d61',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.348929721934129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.35,
  },
  {
    address: '0x305411d466b49f7e05f84ae12bff8c2f785069a0',
    minters_reward: 0,
    lp_providers_reward: 7.43,
    swapers_reward: 0.9194027266441714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.35,
  },
  {
    address: '0xce55d9eb38eeefecce2e839392b53d97ec050c59',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.341026677366658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.34,
  },
  {
    address: '0xc8522758875e74e3886d5e4f00d0788b2bf96107',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.306574543547683,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.31,
  },
  {
    address: '0x085d952efe573daa90557ba7bdeab0b95cf92459',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.301820845814865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.3,
  },
  {
    address: '0x409fc6ad97a7f8f3549c82409e3f5a6df043f732',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.296906917767442,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.3,
  },
  {
    address: '0x56f6f437d2fb0138652bec25b3a036b9d1d1ab90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.16456842563509,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.16,
  },
  {
    address: '0x65a723fc4bf861104474f815e08f2ff3f9ad468b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.159079635254546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.16,
  },
  {
    address: '0x2a9182e771247304c505f26d9a171b9e90acc9e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.156104341791178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.16,
  },
  {
    address: '0x754331bd5fd1b8b0783f4283df20fbd7232897fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.136026717680293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.14,
  },
  {
    address: '0x0e3d1b497ef59b6beb8ac77b264a2463f52f5224',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.123974513366388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0xa5df93949f0bb173d4227d3f5b7ac9373c6c6fad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.123974513366388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0x2fbde8cefe5f3a9d3f39ae1c2c10f7cff72a26a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.121021736834033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0xd47f25485a002b1626a760a5212e13b64f573aeb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.119428859782545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0x9218f24516f9184554cd24401bdff34884decb7b',
    minters_reward: 0,
    lp_providers_reward: 2.97,
    swapers_reward: 5.151508396439954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.12,
  },
  {
    address: '0xe882720d542f01f310a19b66d051e79abbe90365',
    minters_reward: 0,
    lp_providers_reward: 1.5,
    swapers_reward: 6.603641083448547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.1,
  },
  {
    address: '0x2356b4306900d8055c7fba0d044fd14c3d8da14a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.085296578971958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.09,
  },
  {
    address: '0x1c45ebb9b0a715f4ae8b0b6a8310762e3797f51f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.050642505344012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.05,
  },
  {
    address: '0xe62f6be9e9f853558d3bbe4c978e87fe3c0eb4d3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.042503238424047,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.04,
  },
  {
    address: '0x4d0e05c15138336546ae01e3a1be362634cd5053',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.032355698005315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.03,
  },
  {
    address: '0x69ec44a0f63f8b03083a3beb0f3b4f4f713e61aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.016003193667101,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.02,
  },
  {
    address: '0x4b10d37eed3da1e3e7f10e4f1d9c3ab379cb4a8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 8.006860993044178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 8.01,
  },
  {
    address: '0x096aa187594ebe957a0e14442823bc448015fc7d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.992522468861597,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0x69c1b7db9d4a03ab6f4aebfcc47200376b70da86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.989138758091615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0xfd381e6acc13be13b985bff76672e2ddeec4735b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.989138758091615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0x0d377f648646e5f595e10b30f0101f0ad8cdf74e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.988058116376573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.99,
  },
  {
    address: '0x0ad82c230338b52ac4f04d8c9da371ea3fd1184c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.9791557838789,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.98,
  },
  {
    address: '0x51f4dfa332cbc232102747d1aaf6865beb3e2350',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.965354354248009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.97,
  },
  {
    address: '0x6a522994329c3b1640a86809e2e7c2070e14632d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.958098651783528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.96,
  },
  {
    address: '0xf25372b14ad6528818e881324981ae3910e39444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.952326293371411,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.95,
  },
  {
    address: '0xdfe51f4168e6d0e220bfa140830f3d73d9dd6b68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.94637044729002,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.95,
  },
  {
    address: '0x92d0b9c6fd7a6b38085a8834415c6422777cdd62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.93925083214782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.94,
  },
  {
    address: '0xafec395b321aa4ba5bb78d47b88cdac7707a9df2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.93925083214782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.94,
  },
  {
    address: '0xf7b50248d2ddff723d72cf8114816bbec6db5b9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.93925083214782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.94,
  },
  {
    address: '0x368f767453491018a62e1176bd277a24706d624b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.901875040623358,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.9,
  },
  {
    address: '0x9b8609e6fd7a331fcaf940044113b344a0f2b0ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.892639926989056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.89,
  },
  {
    address: '0xa3f8b2f4bb787d88d58d46a3be39a34c23ed7e13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.865892423058122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.87,
  },
  {
    address: '0xec54b98634d93c995fed6c638f2349e7d4fd195a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.855092106983438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.86,
  },
  {
    address: '0x30663cd7615d2c074817489586eb9b995791de28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.847535495729187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.85,
  },
  {
    address: '0x9aa36b54763ce1eb9591619bb2795336a498cada',
    minters_reward: 0,
    lp_providers_reward: 5.33,
    swapers_reward: 2.5214513173538546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.85,
  },
  {
    address: '0x93e472865df3dfe3e70408443ddfa7428157e691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.8418701985875146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.84,
  },
  {
    address: '0x197ae9ad4465d160631074329aafa91bc21088d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.839742068570904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.84,
  },
  {
    address: '0x5674c5321dd7bf9a467577fe9105861103c0913f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.824093741168163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.82,
  },
  {
    address: '0x541bc9aaeeb8a974ff3f3150e506fc3c1908812b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.813451762651659,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.81,
  },
  {
    address: '0x390e57e5df4bc729de96d99209a9b53ab89ae525',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.812341826877713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.81,
  },
  {
    address: '0x6d6acbf04ac69d14f580c87fe01650a2ac4d1955',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.761123286315103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.76,
  },
  {
    address: '0x7dcb7608b9321f4b1f1d8881d5db4a77f66e2f1b',
    minters_reward: 0,
    lp_providers_reward: 7.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.76,
  },
  {
    address: '0xce08e0de81520e6db0eca8b0e1d38d3909a65847',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.737607686665713,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.74,
  },
  {
    address: '0x84d1c6b46957e518501811fb7b94f2398af137a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.725670865141584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.73,
  },
  {
    address: '0x18d8435d0da23ddf77d924525adbb8bbeffcb6a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.725670865141584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.73,
  },
  {
    address: '0x668ef87b096af8945f01c5d9339f5ef82f4829e0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.707201452529363,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.71,
  },
  {
    address: '0xed3fec83d146cc27a804be1113a745b502c4eaba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.707035325912009,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.71,
  },
  {
    address: '0x20f1ebcb510248eedfd7324fd6823cbfb8d695aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.703393339993925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x11764e024893d6a4dfd40a10ab8851cb8a5042a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.696590822570876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x587027ed44b0044bb437ae9a536c85b51c3a4241',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.696057497290201,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x3f9361179d7823108e5a32647db9877b5bfd29f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.695161666383534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.7,
  },
  {
    address: '0x36aebf0bb9bd11d67dd69d96858e90fa9057c4d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.694214226606433,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.69,
  },
  {
    address: '0xb41357563d81e7085832e4479d5b01e379f0515c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.681310000756658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.68,
  },
  {
    address: '0xb77180c4218a8a0367dc106680befc2408ccdc38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.655662488834706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.66,
  },
  {
    address: '0x90f870b923ee2b8f1fdedb434f82ee0184302a15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.6528562870154575,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.65,
  },
  {
    address: '0x8225aacdf913d48c1fb8fb813512e500d13b1700',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.65234969535117,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.65,
  },
  {
    address: '0xab58abc8a46ba6155748febad721ea7366403453',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.651626973764214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.65,
  },
  {
    address: '0xf5ea7eb725cf2d05c2c01ba873f5c3f996d594da',
    minters_reward: 0,
    lp_providers_reward: 7.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.62,
  },
  {
    address: '0x7a860de26fd79c0d756510112d86b3b672935c6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.601354278042366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.6,
  },
  {
    address: '0x1b48e015e0a3f0f89efe434e3e026574300bcf82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.568182129994051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.57,
  },
  {
    address: '0xd3482f1038f7e80b4228bfddd48a9f5cd399a66d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.552375643252865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.55,
  },
  {
    address: '0xb430612a48589013ab3e02b9050c7a2829f8f985',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.543561491872912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.54,
  },
  {
    address: '0xfcfee4d319341aff1e421864f5c126e2dd44c8a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.536701771783243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.54,
  },
  {
    address: '0xe3e8ddf7c2f90fff8f1151801130a5fa6850ba4b',
    minters_reward: 0,
    lp_providers_reward: 6.23,
    swapers_reward: 1.3103225548446824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.54,
  },
  {
    address: '0xb4142591f6b167b7901c7130c44ac58457d932a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.5223075815382465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.52,
  },
  {
    address: '0x892906b5416003b584b2120a8e16ca00cf27eab5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.518122101774573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.52,
  },
  {
    address: '0x646fe43f9331e18d41b8cf8702645cf1ceb8ea91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.516237990623039,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.52,
  },
  {
    address: '0x39c1f4cc285b379b7e1402adc7da62f9ce4eaec6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.506438700234322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.51,
  },
  {
    address: '0x4345cca77f01b65a21815cfeb9ba366ade74caac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.480851818511602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.48,
  },
  {
    address: '0xda58f560943616d19511dd7f090f526908878ad8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.468743442182858,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.47,
  },
  {
    address: '0xb1fa084901b179dec04e6ba0267865aaf9c76c6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.447062379337486,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.45,
  },
  {
    address: '0xe8f772032992aaf48ebe6acd7101762a473a17f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.421192521869764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.42,
  },
  {
    address: '0x302cbfdd443f43874980b03cdfc2126b32702bc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0xc80b29fd2b8c6270428c8fbea8c9ce63d298f800',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0x01cf679d22556f058254eacf42a2b7b66a3febdf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0xa6b89ceec5624190d4e8b6a8234f80e6dd02797b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0x39c767b6586722862de7d7e7baa25a01711bb9a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.401533441430963,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.4,
  },
  {
    address: '0xab9e4493c7c50ad6157d0dd5cf566b7428b4a84c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.393995029780165,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.39,
  },
  {
    address: '0xac3598667f7781c9b8fc0ff40bd1808dcdbc7996',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.388820158994814,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.39,
  },
  {
    address: '0x7f72ba7b318fae3ef1945b141cdafa650e206d60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.370291807796477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.37,
  },
  {
    address: '0xabb2a1a32b71784a92294a8d47d033a8863bdaa4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.362654928050409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0x48332886ce0f4e967ef9c10569d18635313152d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.362570105231923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0xfa7c763a8f8f4cdfd21b8eb932ffef5e4d46a3b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.359209829276178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0xc9ab941e957bb8c6feab314efbef0950d0e1aac6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.3586517979123265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0xe965c21678e2b90f08e56bf3c412757897fd74a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.355066769386829,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.36,
  },
  {
    address: '0x889efb9bee1bf0c82b86207c54e9bfe4681bfa7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.333153865918291,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0x249ee15b61846113442e56cecd62a987d11893d8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.3300965090662915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0x2e8edb53ee02a5029e2ff7eaf80849488680684c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.325780288785768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0x558f99a4570ef5af353521c9b4b8b614d3d97d25',
    minters_reward: 0,
    lp_providers_reward: 4.5,
    swapers_reward: 2.8260833430256516,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.33,
  },
  {
    address: '0xa8284503efa81664ae3ae8a0d99783c44787cdb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.323875359071119,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.32,
  },
  {
    address: '0x2ddad446b93769b5a30cdd39a5301b53022e92b9',
    minters_reward: 0,
    lp_providers_reward: 7.11,
    swapers_reward: 0.2034037621558422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.31,
  },
  {
    address: '0x7ee7d88458ba2a3c2e64442ba0226f5dfbb5fd73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.300187470309994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.3,
  },
  {
    address: '0x9231475e43bfea8f34a7bd91d252ee4b7fa91fbd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.297693147403901,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.3,
  },
  {
    address: '0xc3d8b2be67c164edf7c8e46613042866b3381c2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.287886004704562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.29,
  },
  {
    address: '0x2cc35b9540baa2ab79adc8e3688767bb92ad985b',
    minters_reward: 0,
    lp_providers_reward: 5.47,
    swapers_reward: 1.8170306699425671,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.29,
  },
  {
    address: '0xfda5d7231357ecc7b2aa5e3b7d7407478fbf3df6',
    minters_reward: 0,
    lp_providers_reward: 7.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.27,
  },
  {
    address: '0x78b5dd4e63c5129ff2212129c56542b08f5798ae',
    minters_reward: 0,
    lp_providers_reward: 7.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.27,
  },
  {
    address: '0x423e1ee706f2e413f4b9155ac1ec34757fa0f753',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.263214495302373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.26,
  },
  {
    address: '0xe400702c8f9578a7a0514e89a2c22b0409069c31',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.255727949145401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.26,
  },
  {
    address: '0x3e30fa6dd73930aedbafb132ca0c0f2b12027079',
    minters_reward: 0,
    lp_providers_reward: 5.95,
    swapers_reward: 1.307098951470934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.26,
  },
  {
    address: '0xc7a2a7cec19a0cde9ca84e8205ac7f14eae23230',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.213270568050647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.21,
  },
  {
    address: '0xb76bba464b9a984cf38a785a95e60f7636830718',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.200117810066388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.2,
  },
  {
    address: '0x22a8c55cd02f78425392dcc0b401db632951bd2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.193307957037948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.19,
  },
  {
    address: '0x89034ad8638ab8d52d930452a0fb4e2f318d505e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.187213257697998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.19,
  },
  {
    address: '0xe6c7129ea0441ef3af8fb7040b746c44473fb117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.164369077348348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.16,
  },
  {
    address: '0xc0ebe77128c8bc67a0593c2b8c8eff7c44371fe1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.161467167460301,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.16,
  },
  {
    address: '0x560fb826f4eca4f2a37e6abb00a8be8cf29d5538',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.15903485833692,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.16,
  },
  {
    address: '0xd7829a895bfe699b0683da7f17fba8e940dddf8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.147324713607218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.15,
  },
  {
    address: '0xfbac52ce9fc67281a705d5cd94bf8184d69751a1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.127986369687344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.13,
  },
  {
    address: '0xc3c5513dd1d314e790eceb8b68b06a44007b33e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11734772953012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x5954e73781bb5ece885c9432860b2ed41da30ee0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x5bc8a204a886bc615e6eb6de961dea89deeff699',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x5fa71ca168343c58af870014cf4694b89fc7f2f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x6291374cbdcfb8b6e9447f88e59a38fa38d23c00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x8594feca4dc910991bee6da708ce6700fb145946',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0xda19669ec1ce838b1b719f4d1a599d2ab9b16dc1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.12,
  },
  {
    address: '0x9469eafe654bc53c28edcb9104dc799543cce237',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.11397261364089,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.11,
  },
  {
    address: '0x87cac3b3e50b69ce2d888ec67214c8e673ecb0e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.053704052857598,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.05,
  },
  {
    address: '0x578dec2528eb30a9ef5329dc6a3961e5ab90fd3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.052824164470417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.05,
  },
  {
    address: '0xeb13e5928061aba7c2ff832d042752eb9ed24692',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.037594369995075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.04,
  },
  {
    address: '0x333a5f760aa7e4ad6e9d2740e58199a9760f5ee2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.026642991663362,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.03,
  },
  {
    address: '0xcbf1e50cb75c21ec89f925c75629cff1b27d8f69',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.008184860182018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7.01,
  },
  {
    address: '0x55de0e308dca2ca1bc8a38f5aaff38d141b1cabc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.001509919762975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 7,
  },
  {
    address: '0x7da7958714e9fd73753196d6444a8fab9e92bbce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.980792823916503,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.98,
  },
  {
    address: '0x40497b0a04b88a82908ef318684b7f994169380d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.97470720481185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x04f6a0ebc81467cca9a26a3b383369138639a8d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.974691522490831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x17a09dd198bae48837fa72d0c0676ca8792187b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.969292148850305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x9e9cb2e83b7b7780d5d4f8cbe3dae0268d35b19c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.968842543644902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0xba9653d1c6afd39e0af69c4fe0c85e834825af55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.968670016909281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.97,
  },
  {
    address: '0x4ef6e77d997e79c5aa1ecc25c29e8a18db6a35c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.9545380720552625,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.95,
  },
  {
    address: '0xaeb2981e43cfbbaf61d8c72843f3d8511554073c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.951036378580876,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.95,
  },
  {
    address: '0xb3ab126322098e12f43115e6f1032e4b219c7fa2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.938116121966305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.94,
  },
  {
    address: '0x8f8304772f3877efe661cc0432623496fd3d06b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.930499766104321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.93,
  },
  {
    address: '0xecaf1c43c3b8b095a8c1e09462d3b3d98c6b4775',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.929000236535141,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.93,
  },
  {
    address: '0x65905d647c6f86e0e4143e4a38f0f030e6f8c0c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.9248500553442245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.92,
  },
  {
    address: '0xd98f0379c1d5427dbd0663654c5b7f4d223e58f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.899370537783874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.9,
  },
  {
    address: '0xc7371941a9408f7c881497ab7223781a22e89403',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.897915264096652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.9,
  },
  {
    address: '0x5817244adba94805d248adfa5b0c5d3f0ded5aae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.897915264096652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.9,
  },
  {
    address: '0x24c2de5aa01fff4fe1a521fab1288fd3d010d414',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.8482463322818115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.85,
  },
  {
    address: '0x11ffd9022b85ac4df51d7b48ac1a8cd56246630d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.812846109916973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.81,
  },
  {
    address: '0x910483c449455667603230765c39434ed890b190',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.806347426459338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.81,
  },
  {
    address: '0x6fcf723ca68ca6ca1921dceeb700da17c950d084',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.77934104478824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.78,
  },
  {
    address: '0x6868d84dc2c8e1c902663f423c3c61b946a81d58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.778189866687517,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.78,
  },
  {
    address: '0x27e401bddbf1bc7bcf3fcf112528d9e4af9574fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.772226540009841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0xe690a61e3986d4ab739a4c94cf82d061b96af38c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.772181010147974,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0x8f0fe7f9a3966d8eb6dec75f857048fef2d9db24',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.771847876103412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0xc0e0dbfb8b9297b1b67b9690297f7bc141a68ae0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.768233202358693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.77,
  },
  {
    address: '0x5c5de1bcb04066816fb3595b4250770805ef1a91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.756445930344353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.76,
  },
  {
    address: '0x58b727802f1bee135d92d5584e4fa34df968953a',
    minters_reward: 0,
    lp_providers_reward: 6.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.76,
  },
  {
    address: '0xd42d6ae725bf1ea1fa217fb3b7211848250c55fc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.737617529579811,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.74,
  },
  {
    address: '0xc4658e41e326e5642368cd47d28b468bc054c2c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.72485386458326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.72,
  },
  {
    address: '0x7794e69ea85b9ca714a6974c4280c169b172a00c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.723639098531311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.72,
  },
  {
    address: '0xb6c491f9b2199ce132fd8dff86939ccf8b25bd4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.719777830560859,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.72,
  },
  {
    address: '0x30d5cc3e4ed875e36d28537984c61aa5ced652ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.709985925575739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.71,
  },
  {
    address: '0x857158f1fbc8f9d170db40d1a1db1641ec3ea18d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.69956027043957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.7,
  },
  {
    address: '0x721ca0ec3001856958a59744b24407afb9407a92',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.694693899312207,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.69,
  },
  {
    address: '0xaad8c941e22ba9b24898d3294aab709f96a74e94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.692143824697421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.69,
  },
  {
    address: '0x160401743ae457af789483ff455794c3ccd64bbf',
    minters_reward: 0,
    lp_providers_reward: 6.68,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.68,
  },
  {
    address: '0xb58ebb2dce51eebe31095e7b57d1ed5e6254349b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.665655723490182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.67,
  },
  {
    address: '0xf0f05438f3b0e005886f91624b701147223f622b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.663582045283323,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.66,
  },
  {
    address: '0xf968741d868b29fc257789dc8b414c449101d368',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.649454801681495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.65,
  },
  {
    address: '0x59d4ca42555c0e5bb6ce58921db306d33faa6b04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.647970338931763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.65,
  },
  {
    address: '0xf0c5a4dd47b3f2c9782e246df0c18fb2762d4017',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.636782009209644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.64,
  },
  {
    address: '0x6ae0703b42572b315898465035dcd9f532f8d37b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.633767737126284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.63,
  },
  {
    address: '0x088b722a46f9d957679a593a33a0855965cd5a8e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.607380620359302,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.61,
  },
  {
    address: '0xbbea6e3e273e77cb0baf5cdc21e2070ebe1dabee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.600853719570322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.6,
  },
  {
    address: '0x98e844e2fcf80532905d1e9e18ee7906ebb9474c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.589746341078445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.59,
  },
  {
    address: '0xbd860e5bcd35a7904974c22e1ffc65bb5efd9106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.584920498404175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.58,
  },
  {
    address: '0x1e4a56711a531a083a0e52cd19c9fff241d6c3d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.584920498404175,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.58,
  },
  {
    address: '0x0f87ceddb9ae6fe3bbd8eea8b6979cf659598765',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.567216212945154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.57,
  },
  {
    address: '0x05900288ec8e8230f357cb6520c3f7b031c0115b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.566717284395073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.57,
  },
  {
    address: '0x2a95d8eb4783749273ab38e380143ae3ab86c0c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.5590089203449065,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.56,
  },
  {
    address: '0x9e8496d865a76822e15d7521dd6c6b3889b9679e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.551612774223413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.55,
  },
  {
    address: '0x13a76160a98955ed5e212710933cfb7fb9e4335d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.532877410702098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.53,
  },
  {
    address: '0x646e3e02ffef0a93bd6a5723c9d850eedb5a25e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.49917961069311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.5,
  },
  {
    address: '0x34cc30e2609724de781a4b9baeb228f7c390ce0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.48747516847806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.49,
  },
  {
    address: '0xd2eb329f7329d2cd31071ed3143aeea52da8eaf3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.49,
  },
  {
    address: '0xd19e4b03c590e0e0ab7507b7a8762c44b43441cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.482839793338879,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.48,
  },
  {
    address: '0x6c68be3ca23d57663f076ce503a14bae872e83b4',
    minters_reward: 0,
    lp_providers_reward: 6.48,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.48,
  },
  {
    address: '0x3159062de8534571fe26fa9c1794bff46e9d8460',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.460800155156827,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.46,
  },
  {
    address: '0x721c4baf70aa541196342f820df445476727a622',
    minters_reward: 0,
    lp_providers_reward: 2.49,
    swapers_reward: 3.9481956419819797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.44,
  },
  {
    address: '0x94544bb40d82ac4207ec0761b78de5a5250206e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.432098273875894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.43,
  },
  {
    address: '0x0c82c004d648d5e154ab0edf50749a30a7cff794',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.418245714157463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.42,
  },
  {
    address: '0x5043aaea12fef25ef0f3711ca646d1e73f37fd6b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.385847018051924,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.39,
  },
  {
    address: '0x228664fc47104aa5cc9c77e9b6c95114c06433f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.372835815199674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.37,
  },
  {
    address: '0x07cbeba172d3a3966aed08f85e15b5a24e743d15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.367610192462932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.37,
  },
  {
    address: '0xc56c45f6b449d834f8ce1076b68a6e1bc422abf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.351728105814303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.35,
  },
  {
    address: '0xcacff0608d325bae1947af692059b4fd501ff3e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.346274481025044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.35,
  },
  {
    address: '0x023b9bf246d151cf99cc5ec2cf8c8f23947ae9c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.328296648186668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.33,
  },
  {
    address: '0x4ce9aa50556c804ed6801c57bc0aba5675679d21',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.316951557989993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.32,
  },
  {
    address: '0x4185574ff2daa8cff98988a1386c1608181cea18',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.3015399269069805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.3,
  },
  {
    address: '0x3634af0b942c44dd9bbc5b4aa0209e837e74822c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.292648252189679,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x4a662b8a87c18076958eaab9764410e2f34b4186',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x02436c0b2e1e0e306b006b89d582c33c45b4064f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x02d2ece1a2f727608ffa0e080fd5017a8fcb4407',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x6b1073faf198571dfc228927602a7685610d68ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.291311253650892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0x6f4d890f376536b8c451989861f9d9fbd4973fca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.288190900253003,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.29,
  },
  {
    address: '0xd8cd367c75972811806fdbbfde72e8709e993f7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.282581412581667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.28,
  },
  {
    address: '0x7ce9624a9c68ed59bad638c8d702d5996f19c8d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.27705135513335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.28,
  },
  {
    address: '0x8a2c64f75bb457356ab2f6b20db559d398d8a357',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.271044589045734,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.27,
  },
  {
    address: '0xb872734112b83199033571feb7817ff3fea648ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.269942945253122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.27,
  },
  {
    address: '0x57949caee8a86245eedb1a0988d0d49fef1412ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.263238007353893,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.26,
  },
  {
    address: '0x945eaca2d62adcba6741f47552bb2cf51fa11543',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.261726011403208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.26,
  },
  {
    address: '0xb7392a02602d814eadcf811b3ecb1b651a73b6f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.240275797080244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.24,
  },
  {
    address: '0xd7906bf1eececa95f138871b8eb0edb86efd8bc3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.237481032754044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.24,
  },
  {
    address: '0xb4486683beb002b1d27689bc1321843fb3e0998c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.227741001384148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.23,
  },
  {
    address: '0x6642344a4410ca2fad520ef94003b576c423c4b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.227195352853151,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.23,
  },
  {
    address: '0x56bcd440a9d2fbdd92818227883a2d1a77e34677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.226453269277792,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.23,
  },
  {
    address: '0x96da7872537965c81bffa982f426ce31b034de8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.219918723148106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.22,
  },
  {
    address: '0x4ff26a28350c86b38279bc0f01743c05c1541e0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.20560095816237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.21,
  },
  {
    address: '0x573b897be7451730176881a8a858b5ca4f90f694',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.202358706024205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.2,
  },
  {
    address: '0xa7b137d4f72fff6d3f4d88b95ecbd61029a6b8f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.180715298740129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.18,
  },
  {
    address: '0x098eaa4eba2e6645edd6fccee7a73a0535ae63bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.170857671744557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.17,
  },
  {
    address: '0xcd0978f99f7460202a6a077adcb808b3ba9219a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.165628260729607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.17,
  },
  {
    address: '0x7167353141cadedf3e2918996e7c33e71b4f8bcb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.158451190292214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.16,
  },
  {
    address: '0x0ae2d6e0ab57e4ed4c8a7376b0fe666aa0a9e204',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.155886442058143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.16,
  },
  {
    address: '0x5df9675f388ca626aced198a544e9f56ebd36e2b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.15140751351398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.15,
  },
  {
    address: '0x3fefd7ede6cf2cb1834cf39af46dd4d9e2f8c14f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.149345340010281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.15,
  },
  {
    address: '0x1a1513549fc3900ccc799ef18c89ebe554a64b80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.1284873976967775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.13,
  },
  {
    address: '0x678936ac78b23614461969c4a0c85d8775a8c215',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.10981282856168,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.11,
  },
  {
    address: '0x2b0e43697cc587670547cc7ef4336e1463cfa5f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.095003886008857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.1,
  },
  {
    address: '0x3670e91cbeda9f5115d447a05c6741b3be53550e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.070428148290965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.07,
  },
  {
    address: '0xffba47473418023fa60a9b1a6c06193e1614a172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.031877428818035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.03,
  },
  {
    address: '0xd0c94a05d3013239a9d0d6a08f29820b6a0638e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 6.021173250841428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6.02,
  },
  {
    address: '0x124714a5064fcbf367fc897bb49d7980695f53b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.995817375647077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 6,
  },
  {
    address: '0xd61d177276daaff3f33d0ae38aa2965914ea9c20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.994969386379593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.99,
  },
  {
    address: '0x654f3cb95b868a5b006405f9fc80e87b3de80124',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.984152789492008,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.98,
  },
  {
    address: '0xbd1f04e00c60d0a4a360f3019b1949d8c61bae6c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.965564953385556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.97,
  },
  {
    address: '0xcab291443527c44b85a5fd0ab5b90598d30aa617',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.955694005207535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.96,
  },
  {
    address: '0x15fd0ac8b9136b802ff96f98edb1e66b66b04780',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.9335199174951265,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.93,
  },
  {
    address: '0xe9d30bd886c69eab9471f36b78aa19ad642d082e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.925811714627021,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.93,
  },
  {
    address: '0x570bb7e6c9da144fb6bdbfbb4105bb6255688eb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.919234099754378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.92,
  },
  {
    address: '0x331bceff05ee9f7c786a135f00a8d174c600ee2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.907098867516232,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.91,
  },
  {
    address: '0x2abff15f66b5721fd8d4905fb591aec4373c3cec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.890663618189847,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.89,
  },
  {
    address: '0x20e978f5d8c8da5380418e8bfca147629232096f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.884499356395282,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.88,
  },
  {
    address: '0x561432eb9098f2ad6de2959f6142d57b2de4aa64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.876073421964589,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.88,
  },
  {
    address: '0x70eeb7d66d2820075d46c500fa9d06eac1fd16fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.874455346998663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.87,
  },
  {
    address: '0x18efdef35cb58b9a873eade3176a6a59f0147768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.867450811409279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.87,
  },
  {
    address: '0x6ca7f8504ef13b7e853edf1e78bb637c8c9c917d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.8665230927346315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.87,
  },
  {
    address: '0x6462b0fb25c5c06e0f87d145054ac6efe4c873be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.859933858208068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.86,
  },
  {
    address: '0x517f411d5f98d1b4c45fca6ad632fc8b32742d0e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.83889775524515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.84,
  },
  {
    address: '0xdadfb5cafe3a82167ea473502c23529430c4e583',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.838248903209909,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.84,
  },
  {
    address: '0x44373b14a936cbe1a8feb580991c45c50389a462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.837701111189176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.84,
  },
  {
    address: '0x33b9afd16acae9a8bdb0286a568c3185dcfc6249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.815514588758767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.82,
  },
  {
    address: '0xb04860358d3f23e570feac9f3efd2fad39e39dd2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.814790668339977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.81,
  },
  {
    address: '0x667166c6953210ff5d9a6c6c681a94a312c40d15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.800502458942253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.8,
  },
  {
    address: '0x13f6019903befee2dfbd169914709e0d3f24f940',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.7745773552514015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.77,
  },
  {
    address: '0x2507b82d7c77967138acc7aa61745e4c8dd60a56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.765334315529477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.77,
  },
  {
    address: '0x5cf683faedf310c8a6c506303aa33becd0c7f7dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.763677796810739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.76,
  },
  {
    address: '0x938557694499b871c06cb7ca9247077098f68daf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.759651098582069,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.76,
  },
  {
    address: '0x0a287ba835ce90f43b587f4885497f060f06f283',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.7420654411316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.74,
  },
  {
    address: '0xfc1291337ed84ddc2521a9646cb0e54c6288276f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.739562977106686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.74,
  },
  {
    address: '0x8363bf123ff3ee6f3f4a8b53e645fa8b1ba91bae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.735149659619357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.74,
  },
  {
    address: '0x09a051f7c0644c8c5117593c5009d8fe92d92ed0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.734112318146458,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.73,
  },
  {
    address: '0x3d6fb4c65ca66288bda1db1026aec3d5af2f9492',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.711699573087733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.71,
  },
  {
    address: '0xc663779720f6ccf0e6021fc12fd9312d42f17980',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.710640760824778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.71,
  },
  {
    address: '0xf459199af58fdc248b83f06ce06eab0b664956c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.698040547307345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.7,
  },
  {
    address: '0x577e22bf6f5d4035325b9883312dbacb359425ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.697129748317831,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.7,
  },
  {
    address: '0x7decb7d184e73f404f57067ac2b08eead82d9929',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.693661489604173,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.69,
  },
  {
    address: '0xe3b3bc1ba8071a8f61926b2d367ad7a16ed3cc46',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.691689237843958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.69,
  },
  {
    address: '0x45e3bcbc9f1f80258a7e283b10906de03bda266a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.677222342829578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.68,
  },
  {
    address: '0x96104536001578abe18723b7f2f9d4f9a2b24262',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.66916892784022,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.67,
  },
  {
    address: '0x510a2f2dfe509d99ed3130ba2c93521d41feea89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.668019561011509,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.67,
  },
  {
    address: '0x090c2f6085c6885b8c783f32356213a0e14d0188',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.659461344202536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.66,
  },
  {
    address: '0x489c4b60fc1caa75a8c218f2f0bbc38b61f9a305',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.656236618032687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.66,
  },
  {
    address: '0xd87fca1c317f7c8acba1fa50310fa4c0a28ba624',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.654121260707676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.65,
  },
  {
    address: '0xc898699336b984224c67c5bb5a1d4abdb54ec6a9',
    minters_reward: 0,
    lp_providers_reward: 5.62,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.62,
  },
  {
    address: '0xf4c0eb95ea850a37aff3c87eb6d51bcd5569847b',
    minters_reward: 0,
    lp_providers_reward: 5.61,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.61,
  },
  {
    address: '0x8da3ca8b18f5693d8fba55ff7f2dc6445c85f5cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.6005001857690475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.6,
  },
  {
    address: '0x96239782fb57332e32ad7501977b6aa56f4af6c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.595281588648699,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.6,
  },
  {
    address: '0xd96b9134fd153ea9082b9d77d3495d428833437f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.589435958172522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x0dd2a34da59b58725542c186b954a4f2dca04a2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.586458666571996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0xcc7ac57a671f9468e1a015de5ac3c9bf18a691bd',
    minters_reward: 0,
    lp_providers_reward: 5.21,
    swapers_reward: 0.38335853019903005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0xab4305c223cae1c22e5d282ee51516e1fa815b74',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x8355a88be71936f8e626d090fdd9e76f0e261f0b',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0xd16ea17289a487b362b1cf4a16c8a1a340fd1135',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x62e219ead407baf7dc4c162f97e3b264a08cc498',
    minters_reward: 0,
    lp_providers_reward: 5.59,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.59,
  },
  {
    address: '0x3bb7e01afbfb2cc8f1789240d9078b5c9a63cf08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.5771514047181565,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.58,
  },
  {
    address: '0xaba4489a76a13bfc9bd6ee18d7aa928bc9a3a971',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.5754337190802445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.58,
  },
  {
    address: '0x754ed6423108245bd5c7ff5acfeb8aa16d803d51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.566153880582748,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.57,
  },
  {
    address: '0x6f2f3c2ee38b3f5ed859ca3385d276c857d36e5d',
    minters_reward: 0,
    lp_providers_reward: 5.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.57,
  },
  {
    address: '0x594ab9eba797d5a977747125912b81f4ae8536c7',
    minters_reward: 0,
    lp_providers_reward: 5.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.56,
  },
  {
    address: '0xecf617b273d953e50309342132729ce70def2336',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.543507904553616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.54,
  },
  {
    address: '0xe3bd9c9e2433e311e612a161c3429062bcacc5bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.541141680016434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.54,
  },
  {
    address: '0x22281fe0ef89643c10ba42e1d1d014accc154033',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.538385744362303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.54,
  },
  {
    address: '0x893eed49af96efb6e187687e69765618757439c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.519864724290733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.52,
  },
  {
    address: '0x0acc629bafc382de295eea7158f2537493134fad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.519009306799048,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.52,
  },
  {
    address: '0xa435798dbe89671333b2b3be5afd8b834d4f2170',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.503924485809739,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.5,
  },
  {
    address: '0x232a181c4061d30a1fab8610226d95635141dc08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.489269691339618,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.49,
  },
  {
    address: '0x30d86b4982dc4d85f5dd1d434228248023454006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.46621378840135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.47,
  },
  {
    address: '0x6c8771a305e8ae504b6f7493122182fb47a74973',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.450654849366457,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.45,
  },
  {
    address: '0x4ee72b91a8a03a273828ae3c3b6c951d8c8ad41d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.448954360480231,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.45,
  },
  {
    address: '0x2a3907fe059daad328b86ca64a74de215b282ef0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.3835621900091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.38,
  },
  {
    address: '0x7b22bd41bb32d8bcf58954c66c79432941d1e40d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.3806415543258845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.38,
  },
  {
    address: '0xf9bfc269c76147261bb544522d20346bb1b0ae01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.357341818969112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.36,
  },
  {
    address: '0x97a6339827c016b2d4c3a450be820432a9223d36',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.355451276740496,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.36,
  },
  {
    address: '0xbbb1304bce3769a27aff85cecebd0155f44488b1',
    minters_reward: 0,
    lp_providers_reward: 5.35,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.35,
  },
  {
    address: '0x8b24038b4f2c73285fab6312eef2ead983d22c58',
    minters_reward: 0,
    lp_providers_reward: 4.53,
    swapers_reward: 0.7703878270022135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.3,
  },
  {
    address: '0xcd5700088c77d49507fe6d895a3ced468439beaf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.267936398723339,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.27,
  },
  {
    address: '0x02ce9103696ca0739fa43b154006e0073b6c40e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.266603802697158,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.27,
  },
  {
    address: '0x78e239c675786b59531796d144f35a19aa5a4412',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.245632790153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.25,
  },
  {
    address: '0xb6aff9f5ad7cfdd26a7ee203fa5658a76c883256',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.24554276248578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.25,
  },
  {
    address: '0x9dd89c09ab3d42a4688e7793a2e25feebe3fb784',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.244495481641424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0xa0d183e231879eebc20cf55e3b02a63a1aef0f5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.243130127418944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0xe92b0378e82a735d017add55a850cb45b27738b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.242177289896284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0x1105490afe48d0dc743610044487acacb80feb1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.241289606352243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0x434017d499798a2ad9dc8d46e00ba63cd68ad6aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.239520905953959,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.24,
  },
  {
    address: '0xe73deadcce0aebc29941dbf7d6abeb643caa337e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.2263019285616785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.23,
  },
  {
    address: '0x4d2202ff122817ed5d7e3cb1a70eb6e415aef1a1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.2116089909909205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.21,
  },
  {
    address: '0x825afe309617966dc7ae73d1a999493f79fb087d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.197903981154771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.2,
  },
  {
    address: '0x88888b8c9251a39700ce6cc2887802e6e6d7bc5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.190201834283678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.19,
  },
  {
    address: '0x9d58c4fbf830c157443af1a9d1b39e7451eca6d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.181073409001675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.18,
  },
  {
    address: '0x707b89c6c7cbf8d2eeb03c1860e9f6bc77b5021c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.172456555314292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.17,
  },
  {
    address: '0xc50cd4d3576742312ba2bb552a7c02ccc1aea001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.143001798616762,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.14,
  },
  {
    address: '0x22f36fbb2c3eacf8477b656a706f949a5ab041d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.136554329314787,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.14,
  },
  {
    address: '0xce8e9313800a8ddde944b5821255153eb2118ed7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.135790959660514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.14,
  },
  {
    address: '0xa7d71d39c6878ca6113507f3ab13318a9b745aaa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.130136363353765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.13,
  },
  {
    address: '0x1012800b08be659d02d2e437e96da032ebacf8b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.122846308451438,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0xcd1742514a78304ce003fba6530d5da955bcef5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0x37613c1c4a5ee18d60226efa4309cb39a998f949',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0x0ec0124d4fa2fe2893aec99f67f3887d3ab28077',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0x99922bdcd5f986d6bb5fb613f448efc2a8f11bf6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.1178718956218505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.12,
  },
  {
    address: '0xc125e45885a08ad5a8f50bfbd6e14f62bc22744f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.095594659311399,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.1,
  },
  {
    address: '0x2772d2b28f64869198811cd9e12aac195d4901a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.082484870937678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.08,
  },
  {
    address: '0x0ac8e875b9eb4639b106a740ffed3392d213e4f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.081943777593085,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.08,
  },
  {
    address: '0xeb73f43af3be3502258e401e02c1f40c916be065',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.066348286006189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.07,
  },
  {
    address: '0x76e153f8e30a294a2ce6e52daa2ab2f3089141d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.065404223115027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.07,
  },
  {
    address: '0x7af3241b8e03b1eb29b88edba5650284af0fbb33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.0624789377527915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.06,
  },
  {
    address: '0xfb9e27f75a5d89bd9066f2c0be6f492246cc81b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.033025646246514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.03,
  },
  {
    address: '0xb6f92ec1a181ffabff51406b425b4ff8399dfe0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.017790616653799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.02,
  },
  {
    address: '0xdb3c3f901f5cac8d3ab8500e3b4373619d0e1d7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.015514072370063,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.02,
  },
  {
    address: '0x0e29a16085570d1a1cfcb9a0b056266516fe0900',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.014636888243189,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.01,
  },
  {
    address: '0x672bc486e1b819bafde38b4e5e5ff4fc382fa1c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 5.014497083984799,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 5.01,
  },
  {
    address: '0x05c49ebf3d0285be5ac9edc1aa85ec978cf49f67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.9937186096879005,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.99,
  },
  {
    address: '0x09c67aaeb8a1ad31f9fe32f962eb878114ad035e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.970395142316518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.97,
  },
  {
    address: '0xfae7ae759c3d514397b12e5ff5835aa4552e806b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.969386286095467,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.97,
  },
  {
    address: '0xe118e81347bd2d0f4d4a6415a05d6e71ef5f105c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.949443891785733,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.95,
  },
  {
    address: '0xf556c08821d3c6414c80f04a88ea5e6bcee798b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.929765860177044,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.93,
  },
  {
    address: '0xbcc6973fcd64b3334ff4f71e26a64b98992cfaeb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.92823600136161,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.93,
  },
  {
    address: '0x9b725e4fbe0d122a45eb66b6813189cfed8e4f48',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.924582295993031,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.92,
  },
  {
    address: '0x9ddb2bd097876b661cf0867c9e1893798a4b92e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.922972262990538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.92,
  },
  {
    address: '0x52ca1f350d455e3789d1c4e7fd8c859959a87a57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.922747945835526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.92,
  },
  {
    address: '0xc1bae9f8c544171d05387cfb5f38fb203abc7329',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.902784418727958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.9,
  },
  {
    address: '0xbd0f7989ad3555e507882c089370ed9c06308886',
    minters_reward: 0,
    lp_providers_reward: 4.88,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.88,
  },
  {
    address: '0x494fdba3f9c78f527180fe310d9b3d28c9263a45',
    minters_reward: 0,
    lp_providers_reward: 3.6,
    swapers_reward: 1.2738008518388315,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.87,
  },
  {
    address: '0x00c4ce8f205caf2032a8f656eecacfe9cdda36db',
    minters_reward: 0,
    lp_providers_reward: 4.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.86,
  },
  {
    address: '0xb88ba2bc11fdf715a162dca573e8cf7a5b0159ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.838947099771242,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.84,
  },
  {
    address: '0xcd01debeaae5b9d43879e4d53c7417a2ca0a11c3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.831460636061255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.83,
  },
  {
    address: '0xf97666008ab56e8be5f5c7e9b1bba14c01baaf96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7937101712376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.79,
  },
  {
    address: '0x49fdef6995f588954d8e636ce3a42fc4fc4912e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.78517467164611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.79,
  },
  {
    address: '0xb63623be8e56ea42b9b31863a15a5a2765490677',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.750679182649894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.75,
  },
  {
    address: '0x25aa3367ed58e932b661feb0accc04ffcfa141ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.740558578006889,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.74,
  },
  {
    address: '0xaa7dbf93227e5cf11574986063d038049f87af8a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.732927019791939,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x3c684d30f85a8e089a6d2bd3351e35a28262414c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.732744111151602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x54b974456ea2e32b73f07256ad89ea99fe2be70a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7262330955813825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x1baa29099f7c904583683c793519a7f15775fe7b',
    minters_reward: 0,
    lp_providers_reward: 0.53,
    swapers_reward: 4.204339890938895,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.73,
  },
  {
    address: '0x0ae80ad3f2501963cbbc06b1071e0e7795745176',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.720921771734841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0xfa33b8f27fe963c68d1082a3bfffbf7a3023960a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7184834402381695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0xe90bd9429309a5bf5c52b6a04ca305d0abc1c652',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.7184834402381695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0x0b43d11fad0aa0f7d713aa255e1776e14293b15c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.717234310980764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.72,
  },
  {
    address: '0x6adbb86fb6ed6ef6ea93a85d1ff329c9fdd34c2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.659052624285187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.66,
  },
  {
    address: '0x763eed44a464a2dd67272580a19c51148e37680b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.652723003243572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.65,
  },
  {
    address: '0x0a009bde2d90dd1f66c90419fa1d1945ea006923',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.6393399698076445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.64,
  },
  {
    address: '0x09f0d03cc6d786bc084ad8a35505da034f63b3d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.628983255128862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.63,
  },
  {
    address: '0x5a24fef1c55ded96924c75e7a8f6b140aee04ec8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.612733289252446,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.61,
  },
  {
    address: '0x0e569fa7acbf6260c3e6601e44b6cb20803b49bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.607316954670693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.61,
  },
  {
    address: '0xb407d12572f08af66cb3b0bb10f2a403621a7be2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.59596394685185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.6,
  },
  {
    address: '0x1eb1ce11f7ee1609ee70944be703861766eef902',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.5777639019057865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.58,
  },
  {
    address: '0xcdb16517861212330a544fcba13e730298831d3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.573224086843628,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.57,
  },
  {
    address: '0x1ff5ce8e1d97d6eb06cfa02b6f31cbe053bc01db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.573014187491468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.57,
  },
  {
    address: '0x86a1bff82a5f2676f9a53f29bae6e8c65320546f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.56443516834619,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.56,
  },
  {
    address: '0x100b8a04328d46cc12c367dff9ecc498aeeb3c3c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.540336478221926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.54,
  },
  {
    address: '0x72052b3ce7c47c95498eef83068213581647c3eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.539310794354018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.54,
  },
  {
    address: '0x43a5f88aef5d01daf5cb0633c8ccfab468a958a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.538341865819771,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.54,
  },
  {
    address: '0xc38237be6bd3acbcd1f676185f0a101f590d1f86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.510890818413732,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.51,
  },
  {
    address: '0x1cde90d8c28778c1ed4bc031cf1812e8978735b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.499398032675534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.5,
  },
  {
    address: '0x6ac1e58c8661b7011c7272fdf78c508b7cd7db57',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.491164574729347,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.49,
  },
  {
    address: '0xcfb4cf841e8c98c84a7eba90e578865733277462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.487943659495045,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.49,
  },
  {
    address: '0xa411efd6af0029b72bac26af7c9952d48c54ddcb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.485363877577723,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.49,
  },
  {
    address: '0x36724c4146bd4ab560cd6b16fdd73db178b18ba7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.473741433824209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.47,
  },
  {
    address: '0xef992677c6f07fa5971c308fec13ff963b5a7d9d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.470802760737663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.47,
  },
  {
    address: '0x5bf3a59b483171598e49362cd1a25c87f3dca783',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.463151339084293,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.46,
  },
  {
    address: '0xc318ab4f8906c9cbf1475a68cab6aad7395aa91d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.4525314968680965,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.45,
  },
  {
    address: '0xe11332ffebc0a1dc9f2a0a2c9ae1568a64c09fce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.440920064858578,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.44,
  },
  {
    address: '0xa545699896d28f45940852dd1b86525a26311355',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.433258691173246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.43,
  },
  {
    address: '0xe78e3a40a88191813c19857d02d700eba93d0914',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.4313413224364755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.43,
  },
  {
    address: '0xe044ac9664d92ca4c6093f6a21c775920c61519f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.4283651796549375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.43,
  },
  {
    address: '0xf231f961473d7170a714a6852a8654867059b329',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.423295439244391,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.42,
  },
  {
    address: '0x13a4ee327d177d16d0b8d0330a31eda377f1b744',
    minters_reward: 0,
    lp_providers_reward: 2.48,
    swapers_reward: 1.9432836162606724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.42,
  },
  {
    address: '0xf0da8c924507064494bbc754a02a39ca811bd176',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.406980912887159,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.41,
  },
  {
    address: '0x7deec592e2e4fe1279591832353766a405178a3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.397240089298763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.4,
  },
  {
    address: '0xb61e4a1e52503a55732d06a1a557c27603c6f7fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.370273865187255,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.37,
  },
  {
    address: '0xb25f7b5665c190b586a8ce899e1dd70a99bcd564',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.368861080119738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.37,
  },
  {
    address: '0x2d5cc38a0cddcedcbfecbd71522867fe7846f29d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.334217552148775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.33,
  },
  {
    address: '0x763740b700ff5b2161dd02316dbe3a078e3b3691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.329338561566468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.33,
  },
  {
    address: '0xbc71cd2bcd54791e15a2ea58836a6b925bfd15ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.323120294628608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.32,
  },
  {
    address: '0x3f155c7897fa2014f8c18a146f26c9e000ebedff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.3224913096767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.32,
  },
  {
    address: '0x052f1fa632faa5cb1ccda800409fd8366093b3e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.319738323936551,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.32,
  },
  {
    address: '0xd0c5844346d244096cfa7a29c1b3fa7ea1f26f67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.313112288852664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.31,
  },
  {
    address: '0xe0c141b59395273af1c88894b205b8301f343baf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.301317032927607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0xe8c3725cacb8f0d6cb9a5e56e660d841b877c850',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.299206491642743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0x7a760cfd9bcadb65ea24d39d401e53a658db7bbc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.295912469718563,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0x021c11f19500815ba794dcbf6cbe2c861d118de5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.295662397552545,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.3,
  },
  {
    address: '0xd8ed1cb91389f052ff7e3a8f15b61dc712fff4d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.294838608869415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.29,
  },
  {
    address: '0xa3923d141e8fb7902b07d4dce0450bff0b2bae7d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.288394828164331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.29,
  },
  {
    address: '0x28ef36e890fa3d4c070dd22f76b33470f6471d40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.278960528170468,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.28,
  },
  {
    address: '0xaff6efd928f8099d1be8e0a003a238315cd0d0b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.2555383585808695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.26,
  },
  {
    address: '0x4dfbe785f20c56220103013ba82a852c67f5e013',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.255072060242903,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.26,
  },
  {
    address: '0xc4414f919ee2f631f905c527211a06db2d338a7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.251104193351873,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.25,
  },
  {
    address: '0x5aafe9aa7ddd0e0b4b9951ff8bf6818e7254f281',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.236138044930237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.24,
  },
  {
    address: '0x69e17350e9dc9b60387983f0f1fcbaa2644f29bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.23370730044373,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.23,
  },
  {
    address: '0xf247a6154f1a5af661104c422cda0c4d7360310a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.227417606291341,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.23,
  },
  {
    address: '0x03db410a14ccd2c0046e136fba23abe7404054ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.223024278012686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.22,
  },
  {
    address: '0x63e7a71e410914cc355c93ab9c9dbe43c1a85e38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.213418364447958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.21,
  },
  {
    address: '0xfa6897728fe3f0a8309725dfdc7bc1343b4a9a50',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.197195301403279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.2,
  },
  {
    address: '0x0953753af6d4533bb9c5120d355399d52480b316',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.156226088408145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.16,
  },
  {
    address: '0xe491a169c965b8cea2eefd7b082fd01f7da4777d',
    minters_reward: 0,
    lp_providers_reward: 4.15,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.15,
  },
  {
    address: '0xf45f487cb2940f3b8de353890e984dfc805ed31a',
    minters_reward: 0,
    lp_providers_reward: 3.69,
    swapers_reward: 0.44730421158803335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.14,
  },
  {
    address: '0xe06e1a7b3617505bd30c5961738d9667fc02c351',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.108478864346371,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.11,
  },
  {
    address: '0xc672a3a8955c8c16199662d469c1261f49612712',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.101819685902632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.1,
  },
  {
    address: '0x222c07935ef55c38b55cf8f45cf94da434ba96cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.099214823609355,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.1,
  },
  {
    address: '0xd369ee0387c693241711abd9a36f609310740609',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.060281455967834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.06,
  },
  {
    address: '0x132a6b6cc8491e28993b0953accb5cf9b82d511d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.047336863962191,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.05,
  },
  {
    address: '0xcfe2cbb3ad47513a6e4aa6dc403c8c9ca2c9d49d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.034767860219415,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.03,
  },
  {
    address: '0x6b28f5f47c3e98a1ea5f86f88ee780b1dccf1937',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.021385660932665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.02,
  },
  {
    address: '0xbcbe84a4078cd311e9310d4f81ef6ab75e923b5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.021251619212023,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.02,
  },
  {
    address: '0xf7df1d23ea5bf1b244255dd4e7db619e23d22045',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.0168362051758635,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.02,
  },
  {
    address: '0xd9e86c668bd104df50be91fc3f2bbc4d19b13b7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.014135499704604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.01,
  },
  {
    address: '0x3b8968d29c8d6c23ffbd12890acd42fb9a2b72a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.012384361171209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.01,
  },
  {
    address: '0x444adeeddcb0f0f6206d28b3e90aeb5c36f40e47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.008820096368825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4.01,
  },
  {
    address: '0x3e799ea01323ffee03bcd0f2edfba1b3e116d8cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.000739085515241,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 4,
  },
  {
    address: '0x18363e81880c98a1f357f9b6ed58488293b6d29b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9940290581882865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.99,
  },
  {
    address: '0xa597bbc5dee2a6dadd742d0abec1b67521ecf184',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9876106621186307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.99,
  },
  {
    address: '0xd26a10bb62f95cc3a8f65d0758dd0397891f0916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9859145952811676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.99,
  },
  {
    address: '0xbbdb5c05782747b4dda7bbaeb28c390a4c4dd418',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9764415620468396,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.98,
  },
  {
    address: '0x6331a3c8130c361aa425d1e7245d3657b0470c07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9620907357911,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.96,
  },
  {
    address: '0x93de3829eb378cd7be07c0dff9140bccb00f1273',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9574865820902145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.96,
  },
  {
    address: '0x785b9256f0f94cedd844d41929562cf1509a9022',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9521050931839046,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.95,
  },
  {
    address: '0xfe424315224eca32c4662c8ecdbbc8678aa8c616',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9511618157637654,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.95,
  },
  {
    address: '0x40f6718aa1df66184f6af4e33447a4e51ad22629',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.946319963494528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.95,
  },
  {
    address: '0xecad240a148acfa88be7ba1e2352217e1efa2979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9438947534121875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.94,
  },
  {
    address: '0x34c9375bef607a5b23ee4b3ba20ee1fcfef7535f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.942651506526905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.94,
  },
  {
    address: '0x19c10f306117d425d29ce25bad7aa15377342e67',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9333716112310677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.93,
  },
  {
    address: '0x98d494ce552a002d4d9b6e004bccf06166a1528f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.932069533531808,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.93,
  },
  {
    address: '0xbad923aff7b726700be27c041e434e6f20ddacc0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9271168496364246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.93,
  },
  {
    address: '0x38e5857a2d802119d79016d4aeb9467ac29235df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9204282453553976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0x3b271ad130675ff004550a704fa8ead593dfecab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9197264464212584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0xcde52bdda3df10e0992cfca8e5a1b982fa6bac1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9197264464212584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0x960dec349d6ff942f5af0a9fdd57b227995cdfb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9191437681582464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0xf1466d4fe4bbfe0d2bb8a010076e9d93d856c56d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9171226850712593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.92,
  },
  {
    address: '0x6809cde861143976dbcbb258adb2d186306fcb0d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.908402998154349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.91,
  },
  {
    address: '0x714dee22af3d7c4db9ba367321b6aa4deca1a11c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.903634763452884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.9,
  },
  {
    address: '0x7d0d956b95c9d2a4c85d169c520a43cfa63f626f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.9034136325298436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.9,
  },
  {
    address: '0x972d6782ea6bf2e75be9f616192f74a684469117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.894929476862665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0xfac7e487496a0ed09980d649c0dd6d96761c2029',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.894767324258288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0xbed87290e6d4ec30032076a9a6f8093ba891ded2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.893321265589073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0x7299e9b335f0ac06f0abc48e95250d32fd44cd6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.885285800450077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0x2832b734f4d3b689033e22f87b06c60a49543bf5',
    minters_reward: 0,
    lp_providers_reward: 3.89,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.89,
  },
  {
    address: '0x3174a062d6bc22316c8b0bf83f2c1805c90f01aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8760021600842136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.88,
  },
  {
    address: '0x88fadffaa45e04873ec6792789ff90656e1d4be6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8735073491953322,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.87,
  },
  {
    address: '0xddd5b3a5b6836eb9a579f7e6d06a7a0d92c52519',
    minters_reward: 0,
    lp_providers_reward: 3.86,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.86,
  },
  {
    address: '0x002ee2a9a46be2b100b9e937809c2fdf404096f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8311764382777778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.83,
  },
  {
    address: '0x89d1835edfea6eb211fc35a5fd94b4db80f42a45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.8175584761641437,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.82,
  },
  {
    address: '0xb8ed58524b18c9c64cc36227d8a0eb0ee76de0c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.799900017349261,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.8,
  },
  {
    address: '0xfd7a4a856daf7bc990997572bd85b54ef6de2d63',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.798554043902981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.8,
  },
  {
    address: '0x0260cd47c468716b6fca7f4df80765ddfcdccf4a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7860541462240835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.79,
  },
  {
    address: '0x4e8197f9a6689369968f36e49ccc51116612e14a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7844145725859923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.78,
  },
  {
    address: '0x3a604b930dec1393dafc26bd74823bcfe9c5a084',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.78299775724978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.78,
  },
  {
    address: '0xd75812f9c1244e9f5c5c5232c53fdff617bd575f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.782873973172788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.78,
  },
  {
    address: '0x458fbaac816d09005d14d1db91052df0bb2a431a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.769971713443429,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.77,
  },
  {
    address: '0x95a2e6fc69bb6ac241829a0c873bcddb0d1e944b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.760614430448369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.76,
  },
  {
    address: '0xc99634b2c0a2fa59bb2e6476645864798c2f4584',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7601683131338497,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.76,
  },
  {
    address: '0xaaea6b46c1f935567437d5580b9d9dac5296280d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7493755539160802,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.75,
  },
  {
    address: '0x114e88f07585e1e734d1eb96f6d609b0e1ace6f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7240361491961154,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.72,
  },
  {
    address: '0x05916dd858f64142512b73dddbc35f503634815c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7226724742786033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.72,
  },
  {
    address: '0x3ee3a5d52cadcd456f94e73af5227a026d418801',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7123523455783283,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.71,
  },
  {
    address: '0xbe7ca65e0d7109f59cc93839390648c4f03d0335',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.702076837299298,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.7,
  },
  {
    address: '0xfe987cd1b93c3a4b7ecc2b92fd853872aa51e7ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.7008567262196244,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.7,
  },
  {
    address: '0x15ccaadd0aba9eecf8f92114fee63b6bfc2d4e99',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6665769329591456,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.67,
  },
  {
    address: '0x9543c1142e7f1dc258078bf9aaf77e32443ac65e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.66617013964604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.67,
  },
  {
    address: '0x284eb99f81e89da47ad655f0d5ec25a4bc1ab9d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.65413093613169,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.65,
  },
  {
    address: '0x47d38a59fbcca4b3ea6db593f11bc50e9a422a80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6482189201782185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.65,
  },
  {
    address: '0x8fbafabb66edcc693ece1b1dd394988518824b95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.635563970901754,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.64,
  },
  {
    address: '0x4917892bac7e3cb2b4203d7b7dcd60668bcd249e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6349867898140746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.63,
  },
  {
    address: '0xf4ad49d93f45a83efa45e2c9f5faee717c2d124b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.6058302426568143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.61,
  },
  {
    address: '0x4d82a5e0954426d5ce01cedcee07ff919609b8dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.60316390730585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.6,
  },
  {
    address: '0x699b837f99a90e0a55e2ffe4ce7c42fbb6f79e73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5900188717843973,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.59,
  },
  {
    address: '0xd6160db972e6520ad30d265cad0409cf2527fbe2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5815773817468104,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.58,
  },
  {
    address: '0xb175bde622afff3360854976517ac11802037827',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5776906400144424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.58,
  },
  {
    address: '0xd2a672bce1455906c47dabdf30a9078ffacd806a',
    minters_reward: 0,
    lp_providers_reward: 3.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.58,
  },
  {
    address: '0xa89a82fdb0d0999804dc810222f724e77f3563c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.569839421408891,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.57,
  },
  {
    address: '0x83c1c9360bd9feb0f11913eafffd76a1feaf38ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.56689853125703,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.57,
  },
  {
    address: '0x5898afc7d40579e2b1952db28e52ce41e62ec0fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.562004113997116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0xdf2a04785284248378de21dd0415db5be135a691',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.558516082903485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0x308a21c7caac77fbca77769064b26e3c3f60f8d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.557851532545518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0x5184af6f86d4a3ff56fa2b83c61cd0d8f4dba5b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.556200776548588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.56,
  },
  {
    address: '0xbd2700b7504a7469f5ca98addc2f0aea3bf879b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.551687967145075,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.55,
  },
  {
    address: '0x7ee5a3b784af19136d4777a12d3b2899a86618ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.550852603587185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.55,
  },
  {
    address: '0x7a8c81497a7ac1b181f3442ffc21930f7d7e1210',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.514088796671651,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.51,
  },
  {
    address: '0x0f753bcc6a37522038e90724c837fcbd41674ea9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.5138399126835234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.51,
  },
  {
    address: '0x14ea8818307f6e1120be9b34ec8b7838b5c71897',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.495925622102121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.5,
  },
  {
    address: '0xb653ee9a4d041c7bc56c55c6c11d6a7c0d34ea1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.492790451927366,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.49,
  },
  {
    address: '0x5fc40a675bf33b98ae18a73ebf755e7c0c390002',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.488589612885288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.49,
  },
  {
    address: '0x57b2625eb4c3d6c149c129fc71a391f44b6a99a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4844212718224514,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.48,
  },
  {
    address: '0x3b307b27edc14e1c9e29e52d344c7ac6f8b5ab7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4834428009572918,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.48,
  },
  {
    address: '0x9c5dca2389f3a7ec4a2cc801b39a263b2a60bfe9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.479846585833957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.48,
  },
  {
    address: '0x85cfeaa4492cf83726237018e082872ad1a4b7cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.469884748572149,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.47,
  },
  {
    address: '0xf28f707811558ea7cfa9da5b577de1406a2ed924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4651711498500677,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.47,
  },
  {
    address: '0x825b393a1e707f7629eae210f5f6cb20a0bdba60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.453244723490936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.45,
  },
  {
    address: '0x768480082b697a7f2abb2136103e3e2c55f85d64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4484874703035118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.45,
  },
  {
    address: '0x56fef6ea47c63dcbe8e8680c8fb4f938bef43121',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4453547905038993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.45,
  },
  {
    address: '0xdd0d73e26c4cf203dc8b8330560c0457a965b0e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4326433729334695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.43,
  },
  {
    address: '0x6fd1c15ac89779d97071d9d17f3d54be832df49e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4228947638590417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.42,
  },
  {
    address: '0xb46c6645bfedb75b8bedf323574cacfa3ca5d521',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.417136080965921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.42,
  },
  {
    address: '0x738bd00e41d443c2fa9ebeb387bb0f80eab28e7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4098194585131836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.41,
  },
  {
    address: '0x4f54837d343a0a5356ac906653c50bbdad064555',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.405200420651872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.41,
  },
  {
    address: '0x2648ffbe9ccbbea33a07105691bbd7a18eefd588',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.4026264725303466,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.4,
  },
  {
    address: '0x3225b62e986152142effdfcf659775a69fa395b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.394405068854035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.39,
  },
  {
    address: '0xd435ff1fdbe6bbfec31a3aaabe75fc61ecd652f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.394405068854035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.39,
  },
  {
    address: '0xc7e301b50c47a009dc74891cd6bfb05996040236',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.383121827229927,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.38,
  },
  {
    address: '0x9be483697bfe9abaf88bce992c0faa61379c354b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.34765746529813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.35,
  },
  {
    address: '0x46bde2f8ebc9d3f1e946ef82c1c79c7bc487778a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3467621959969067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.35,
  },
  {
    address: '0x86225fd87f69d2f6ec0f5d9f5035b38c8d31cbec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.336570559752868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.34,
  },
  {
    address: '0x08b30ade63c9c1ec3f64a5a84d040eb6823e6870',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.335637571907904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.34,
  },
  {
    address: '0x6e9b385cb0c12bd6ee5152a332dce30e332648b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.327974953569856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.33,
  },
  {
    address: '0xc22184f746ac3dc2e3f004de4bee25fa44ade6c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3150312023008928,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.32,
  },
  {
    address: '0x0fbed925f9f8a54d6915065377e3d94170bb4041',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3127086062147164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.31,
  },
  {
    address: '0xc1bd40227f65b77d55733fe47986dd7d2483cde8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.3060347229133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.31,
  },
  {
    address: '0xa7bfbc468583cfa614cc175b71e97cd08d01f812',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.29411692283887,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.29,
  },
  {
    address: '0x4d7c5142a6061a5f24ccb66ddb9edfee665132ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.285703793435474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.29,
  },
  {
    address: '0xbe73ad57cd76e67416e0cf60099f425ea865ea4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.276414958217968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.28,
  },
  {
    address: '0x8623a3f0abd896a09c573e42bb214f427f4c7ec3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2746261358455646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x622c335182543fd998a1111c462db9fc757646a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.274151796920932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x551eb8fec882fc308b093bd4f65fed34d4a998fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.274151796920932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x42247ab26d7b0432d3486fc3e767ddf62451f157',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.266370770990404,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x5e0c604ed5678e274a52574672c5aea9d718ff52',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.266259331206857,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.27,
  },
  {
    address: '0x72d3d0528e5ca8fcc27daeb1ff1ae4ecbe1b85ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.249589805346555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.25,
  },
  {
    address: '0x976dbfa52197c57af7b8d2166ad9717b96764577',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2493160042184037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.25,
  },
  {
    address: '0x8fa4e9816406dcbcd90d39ce918b93bede564cb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2450966153764997,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.25,
  },
  {
    address: '0x6615bd92f0c3185865c3639146d16fa70d45edf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2398037429524136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.24,
  },
  {
    address: '0x99d68b6e98fc220092eb18cdbc345ef03bf4f72b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2303512241135914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.23,
  },
  {
    address: '0x1d43f473de16a4ab9b379ebcf0a21621795e669a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.229920365123445,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.23,
  },
  {
    address: '0x9b793088ad0c30eb18d7d33179090843230f24a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.2259002956655785,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.23,
  },
  {
    address: '0x6abe2a3142bcd6cf6339171ab0cde41461b14a6d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.224104893844184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.22,
  },
  {
    address: '0xd7dbbbe8067bbcaeb59b6cf0767496396d7028ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.223606260716278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.22,
  },
  {
    address: '0x91b270fe8452181f757b511a63d72122a6892a95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1997120674117836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.2,
  },
  {
    address: '0xa7ed819e2c628cf21cb8c88ae21d32c959470220',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1956555032366465,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.2,
  },
  {
    address: '0x8612ab6625437bcc09656eaeea0defc74ea88c2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1838144253327885,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0x4f41702044934cd5cca6f6553fa1f4901de1c2c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1798777404166807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0x4fdead2b66f179426755fa96d0c48ddfe4392768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1771121830937004,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0xe8b9a78ab1d60e9855f174f8449c817a1f5501e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.176355737016134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.18,
  },
  {
    address: '0xb3b29d07dedfa59a25a9584c812bcaf99f6d6c2d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.163033299423051,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0x3e4bbc4a8b8b003396f8160de193bafefe7caa5d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1617683539072208,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0x66d0918161a8a6285d08fa20a24327255b70534d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1617219826836096,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0xd9213cd1d8ea4cab34aa001c0d04bf2f576055e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.161144011899199,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.16,
  },
  {
    address: '0x298608c5fa4d1a7d53ee91a8bce1bb1684feacee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1498478623993402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.15,
  },
  {
    address: '0x3d449f0e0308b0c578b73817526d33c3d7ed08ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.145085868865778,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.15,
  },
  {
    address: '0xb1d5590dbe10a722ab3f317e378e8a7a07ac13b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.1438943773654273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.14,
  },
  {
    address: '0x19d260386ee278f61559efcf72c7c8b0585aa5c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.140859645415899,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.14,
  },
  {
    address: '0x7504f5b626b039e7f627bdf9834583acce1303f4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.132316039069663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.13,
  },
  {
    address: '0x36a6d3b5c6e04264335986be8604f29b80522dd7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0901383766447905,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.09,
  },
  {
    address: '0x0a0cb6b1039d80e06ca7e774c90def3fca823d81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0832022135962434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.08,
  },
  {
    address: '0x24f8deba73ca7640e53faeb15b8b99b51a89a5f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0828141303648033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.08,
  },
  {
    address: '0xdc6b668f4c2b1662cdf65d0a82cc1b88c287d769',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0813737850169494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.08,
  },
  {
    address: '0xa83a10f0585305daab77bc3a1d74de82385fd0f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.063531870897553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0x82c16c75c55ace87360aae4fc3af6c3662a713bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0570383471301623,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0x52e8afe3e29b8d2cf7ebbc506fcc28fefc8116f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0562819869583464,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0xc9895c0dcfeff7084ef51f4a59cfd98811f4f4dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.055656072119021,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.06,
  },
  {
    address: '0x29ce0c5d918c91bbaf7c75f120847244630e0026',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0516701347503314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0xa69a6fa9b13b7394ff6e85924db25dcbaf59f82e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0514635659101073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x8ae2f5838a4afd7e477d35c7ee7723b833db6d13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0495972691625735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x91185d8d117d7722c2398f149a313d80d504437a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0488191085730056,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x993a047358c1a98f980e106d519990b7d645ea2f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0483463712031167,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.05,
  },
  {
    address: '0x1d5168e36b6512512412125c027ad8ebe456e3e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.037663208400634,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.04,
  },
  {
    address: '0x1cdbcd9e8a37095163e76848271f96ef53c6a0e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0247152629523675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.02,
  },
  {
    address: '0x0eb9b3ef33c478568ef709b1699d18a3a2ed694c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.0178310761061744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3.02,
  },
  {
    address: '0x4a1e51910e3778bfa19e40ef8609c625c284295a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.000854843764813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 3,
  },
  {
    address: '0x61f5c255f5ac0f14aac0f336b2d4969f79d08f83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.990970261381243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.99,
  },
  {
    address: '0xda898f4aeac7e5f7ebad6b5383d7747845e659ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.983037392195325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.98,
  },
  {
    address: '0x4815e13640c2046b12900de69e5ebbdfa7c96785',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9824942892161395,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.98,
  },
  {
    address: '0x14b5e4a4e29329eb288fdc6ce3bbabe783d560a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.975434226056195,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.98,
  },
  {
    address: '0x7cf2ec0ffd3d2bbfc6576fdfa9a167af92de262d',
    minters_reward: 0,
    lp_providers_reward: 1.7,
    swapers_reward: 1.2719785248729307,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.97,
  },
  {
    address: '0x3c5c43ef18028e8fe2fc7381412dc58358af9e70',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9617159385305354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x2385173ce86b8891ae5b297c881fdadf7605a11f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9617159385305354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x42a6fddd32ff6324b17d4c37314e4af9e6c73169',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9607266090208926,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x8d84f55f0ebe3de2f71a5fc1888df5b3fe499f88',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.960613376572386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0x29bad0b8ea427dde4a20ee4958faac1831986563',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.959777779798331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.96,
  },
  {
    address: '0xca0fde5ea8c323d1a70c0bdde0f59c448e0f51f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9520820341092513,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.95,
  },
  {
    address: '0xc312296d4bf183c216a47fa8313ba14c99dd2e8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9436839317104715,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.94,
  },
  {
    address: '0xd22c97fb093076b49120ea9034aa66040ba6ca7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9423036241116853,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.94,
  },
  {
    address: '0x3741c185b1c078f162c08a9df775b70e8d207648',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9332615463673157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0xf95ed17c6d8d388f544f50e80e58585a60f48d9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.927279683929992,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0x9ee1838b080561c48f45895aecacfeb6d77b77a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.926549218453957,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0x236af103dff6041bf4be9ea8491a25605de7de81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9259372866498037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.93,
  },
  {
    address: '0x4e89bb346a587fafb9969887c027adb47b0d6a1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9209666267744354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.92,
  },
  {
    address: '0xb0875718f94fbc4ff19c0ec95c661d67dcfa79e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.920242685722253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.92,
  },
  {
    address: '0x4301da81e3473e72c38db999af6f2ec3d6e7d4bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9198634835248556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.92,
  },
  {
    address: '0xb7fbd026d11e55c772269a3495030a81817986d9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.9034981679220833,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.9,
  },
  {
    address: '0xd4d40e2aeb9fde55b948b7ec3caed56fddf8c8e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8968138465865714,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.9,
  },
  {
    address: '0xc705cead42fe25596b139580ba230383a8bf5599',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8865894906751484,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.89,
  },
  {
    address: '0x077e2b7998450d6287ed8db01a32d59f33e24c7c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.886063599661325,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.89,
  },
  {
    address: '0xf10c21a114ed86f50b802793c24159942eb90fcc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8857824982825164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.89,
  },
  {
    address: '0xb48299cb4a6be041a2ec90d2b7983fae39a82e34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8844725149027832,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x269f7a78fba5d6207cc5237abfde711a9a0fcf00',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.881659819661427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x642127774843ccfd57d062580a7c4b5928be6b73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.88038552351131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x3c0d41236559dcc06434023e68f9dd2ff867657e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8785793796309185,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.88,
  },
  {
    address: '0x65a83f46dfcc51fce2e9f6457a986f56fc49ade0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8749576727957726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.87,
  },
  {
    address: '0xd98f7ba408534ca085d60306481f0c40e34c56c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.872966508916526,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.87,
  },
  {
    address: '0xbcd6adb83b8c8ef93e517ee58371f94d61386170',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8682496474333767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.87,
  },
  {
    address: '0x769f7ae74fbd4e1f41a2df21bc0e8ab474c691e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8632214457585725,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.86,
  },
  {
    address: '0xd6faa3d09fa2e44ef926b1611563c90d473cb909',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.860542893577113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.86,
  },
  {
    address: '0x96753e89b0daa0d5099a195f247c44bea7f8c624',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8575825485983253,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.86,
  },
  {
    address: '0x9f0b281cb07f55bd68b91a111dd1595c7d9f5eb7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8517559830859875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.85,
  },
  {
    address: '0x92751c25d41e47d98f94c36d0c74585fe0df162a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8510108702346058,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.85,
  },
  {
    address: '0x907b58e388a1a580f988609eebc43f88097fa62c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8483345510661144,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.85,
  },
  {
    address: '0x9f31426c920e76ae032affcf16ee76a7d24ce9d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8410758402550678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.84,
  },
  {
    address: '0x1d715427e9d4b98e7ad8cd99c64de774f6065e1d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.838063151832664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.84,
  },
  {
    address: '0xfc3630059d42312da37c72329216696e11319a4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8353516106720695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.84,
  },
  {
    address: '0x4dc04aa19da23f3604e0744141ba8890b858db1f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.832342665136693,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.83,
  },
  {
    address: '0xbe59ab6a2507455d72343fa3384f6a908edb93a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.814445309277583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.81,
  },
  {
    address: '0x91dc408536d047aca23b1543074ce06f7c4ac3ea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8125827077437666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.81,
  },
  {
    address: '0xf4f485d838336a5704ecd7cd6f41d669225a3f66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.8008986056648864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.8,
  },
  {
    address: '0x6b19849be941cf24b088c646ecad548b34b88dbb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.792867864438875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0x8f20ff87f02566f6b8dd6fb3651b0aed44ca5c25',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.789821948299278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0x67cd2f35c38bcea43151ddfda48fbf4e34e53552',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.78863216041709,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0xdbac16e6483b32ada854208d304a927f9b933f16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7877168595401223,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.79,
  },
  {
    address: '0x8b4ab7047eb1e1f8e1199e5feb50d038082cb08d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7790563168872016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.78,
  },
  {
    address: '0x9e71db4e0981bdf9664bde35300b305145620404',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7748899171831134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.77,
  },
  {
    address: '0x9d9a17716b179e68a15c0fe652c40b944b665b20',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.75489247012434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xed6da853c259727d6a36da4f6a4072da0dedaa5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.753939735032643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xd21b907ad7eaf3980f11f451f3acc1436343c8c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7517171023566345,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xb1c467734a203d324866bd7300a167f6cdfd3957',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7469066722242776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.75,
  },
  {
    address: '0xa9a52349499ef7da2fa6fa78f1c047fedbe1ebfd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7416108234062246,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.74,
  },
  {
    address: '0x1a936ceddd227a9d4581cb58875af9985c4a7a71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7225389705837353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.72,
  },
  {
    address: '0x7b6c0c922d049901de868a9940bc8e866c31da7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7088906160039365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.71,
  },
  {
    address: '0x2f8819417f4e2093fe0fefaf0cf0f4a680c7cb1e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.7044722230066487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.7,
  },
  {
    address: '0x50c97d8574ea56cfc8fe61d088c42dfef5bfa8c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.702578372137741,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.7,
  },
  {
    address: '0x59ea8714545111ed47de386f0caa3d7c49ae22de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6891428034132674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.69,
  },
  {
    address: '0x1c75e733cc9b90bd633cbe9acaf5c1ebeed9ccd6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.681449699504273,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.68,
  },
  {
    address: '0x63f8dc5cec58a54e75331e8b74001dd1412998c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6791373622378925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.68,
  },
  {
    address: '0xf389514e7ead555c3b09b856d5f236d57caa63ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.662838613370038,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0x3ab4770695c1a0434972b3fa436119de700978b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6611172258292797,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0xa8f5aaff78e89707eaf6acc04fa81b231194be29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6591276397273167,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0x692b6db06ee021d2c3a9b4cff961ddd2f6ff05a6',
    minters_reward: 0,
    lp_providers_reward: 2.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.66,
  },
  {
    address: '0x3019c419a72b76b41a7fbc0e70dc4921ba467fd1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6489287481128385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.65,
  },
  {
    address: '0x2d4c9292c73258550a91bfe98e1cfb1f33fcf8da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6479797139372314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.65,
  },
  {
    address: '0xc4bdc5e1b5c1108d62e5c91a362b124760aa0d14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.634766584954105,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.63,
  },
  {
    address: '0x10da42923087e8f7708d0a22cd93026014b66ab3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6339681993616697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.63,
  },
  {
    address: '0x8fc054b59c914e1fffb93448251ac08a8ab5446e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6283022320484304,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.63,
  },
  {
    address: '0x2cc642c0bf3e5c98c0c36c3415ca7407ac064a01',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.622247740820712,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.62,
  },
  {
    address: '0x14ce05c813838e9f45e5f690047fed071de8456a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6131509642808393,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.61,
  },
  {
    address: '0xb403b32ad0bbf8a13cbfdbd4642cbe7df99e43a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6130966167923235,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.61,
  },
  {
    address: '0xbd77b8b39793b355ce60f1fbd9c851aab584865e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.6077420831499585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.61,
  },
  {
    address: '0xf8ac14515f7e034ce164fd73b9e4d5afdd4c43c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5928477220327153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.59,
  },
  {
    address: '0x9cd89a1ee0da084eb2b589091da15851f1f967d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5866931508987134,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.59,
  },
  {
    address: '0xe00c227c8dbb8296395d69884aa135b12e5dfd3f',
    minters_reward: 0,
    lp_providers_reward: 1.98,
    swapers_reward: 0.5922329315102921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.57,
  },
  {
    address: '0x097c99e94efe24c79ba434ce15ceccc788190d38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.557656945429295,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.56,
  },
  {
    address: '0x364434f181801fbb274b1f2ebb0dde502bea05ee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.556435898393259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.56,
  },
  {
    address: '0xb41ecb730c204ff8111ce2196bf0f115ad4b7117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.554714034271171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x0119560ec5ce5990fcb4b935a32c6c1e8679b86b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.549672477420678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x2be479ba00302e9f64238a4cf17ef10c27f262a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5453968142801866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x07213668f160025c9e35d767ad1c1b9383a7185a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.545218413060479,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.55,
  },
  {
    address: '0x7632605065be1c33b3d7a24612b8f52be6639c4e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.532021909926384,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.53,
  },
  {
    address: '0x203b3db3cb050781d45eef6a43bf6cbc97f96a29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5314324131573818,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.53,
  },
  {
    address: '0x58a565dc9922e00e0659eaaa9f217eb1984def1e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5278795223666704,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.53,
  },
  {
    address: '0x1efa143b0abb0c809c555b0514d7d28c4e58787f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.513492256846706,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.51,
  },
  {
    address: '0xec9760a26e315f5c58b76eb792fba9fd0bfefb40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.506839146151423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.51,
  },
  {
    address: '0x7c46160b618f6c2504e668513375cbfe2cffafd4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.5042461707710775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.5,
  },
  {
    address: '0xb8cb4c688dcaa2d687d5987279fc0a2edea8f3b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4742782401080636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.47,
  },
  {
    address: '0x508b4d5b0df2ccca912b33301ace05bd3db4b7c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4554098363536627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.46,
  },
  {
    address: '0x40e88ee920d6b3e2e1003d56391f4a2bf7228767',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4283221427225095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.43,
  },
  {
    address: '0x6c9a32b57f066344798821d022136311651fed42',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.4153857368505602,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.42,
  },
  {
    address: '0xd2dfd914f07b307929083625e622867bfe5495ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.397511175060077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.4,
  },
  {
    address: '0x8195cad2a90cb7976452c558be44d3a8eea1720c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.393714206933487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.39,
  },
  {
    address: '0x3c64aaff398f50bde86ca2a6753cb902a118aba8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3872028914931533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.39,
  },
  {
    address: '0x939608b5aa2a73e98c942ad69361fcb21da06f15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3827475398032254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.38,
  },
  {
    address: '0xac887bdec6f90aa284ddef6285688dcb2a50df83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3748729424804385,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.37,
  },
  {
    address: '0x526a4ad7d41cc1535d6fbd95f3fb74af19ba6a32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3699780616580295,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.37,
  },
  {
    address: '0xb63fb45dda7a941488bae5a9bac47a90091322ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3690715532263473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.37,
  },
  {
    address: '0xebfd774c1c2008e56ce40e0a4504ebecc81b1921',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3633809096889604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.36,
  },
  {
    address: '0x493bb91251582a3ae9a1e6e207fe6fb4f6ad250b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.354789127153574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.35,
  },
  {
    address: '0xb9c9828ca19044b48197539aa882b06cf57418df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.354712515686095,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.35,
  },
  {
    address: '0x377ffd9a9b38f393084fc0fd0bbf91c0b8d8432e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.337790075175711,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.34,
  },
  {
    address: '0x21688a487de5a26ca6aff7e77b9acb6fc82d22e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.319741694686331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.32,
  },
  {
    address: '0xce679f438dd332c4ba455906fcacfbe28bf63f2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.319741694686331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.32,
  },
  {
    address: '0x6468fbf477bd566dab1128330458264962a9aa5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.319741694686331,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.32,
  },
  {
    address: '0xff526693bb4de4f32184e5ba6838b34ed03c8faa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.3063110490602505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.31,
  },
  {
    address: '0x81c36d2ee2267f1dcaff97323f9353635fc9cb2c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.300350724916226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.3,
  },
  {
    address: '0xbf766b5e46c210d78a25ba9edb0283d5b982e470',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2955496187444813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.3,
  },
  {
    address: '0xd989424603941a9841bd5c51bf024165962bcc2f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.286641698142834,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.29,
  },
  {
    address: '0x3f460d26dc5024a59c1268f0d2a8812e430fe0f5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2774502930582305,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.28,
  },
  {
    address: '0xad2ce56efc80757a01ea34ceb44461759b24a415',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2765921418896933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.28,
  },
  {
    address: '0xabe2ce38eb66d7db334fc59e9ed2a642ed3e8556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2693343441960994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.27,
  },
  {
    address: '0xbc31757e8464820d6f79218796e9a47207f7e1b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.265364738256375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.27,
  },
  {
    address: '0xf9e27b0f6edebea7b18ef98fc81068f145b48095',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2542212175646066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0x8e7ecf6c6e132404812ea38fe5c46b207e2e4fb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.250324573301087,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0xe1b85b2a34ddb3ddc859eb68e66135b17789c34d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.24672491799412,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0x7515e0829be41f4917edfd7674326cb193ea9f0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2461979852124836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.25,
  },
  {
    address: '0xce015eb1f121c174786c1b9ea7cdff3f2ab30a81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2358621261558658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.24,
  },
  {
    address: '0xea46515d81e7a5f70174d0c7013575f9df27f6c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.235744216948052,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.24,
  },
  {
    address: '0x47c3ec861cedb867de645c77754e74e89a56b044',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2227388542281608,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.22,
  },
  {
    address: '0xb20fa1c62208f9e2a2b4d3af95428d263c693adf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.220452021321862,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.22,
  },
  {
    address: '0x5d74529c632d27464129a3818f32491ad722a1a8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.210696512228285,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.21,
  },
  {
    address: '0x28af3fe7e324a38ad6d9a2477ff9e91f6f5fb1fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2032365550046897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.2,
  },
  {
    address: '0xba6fae5236c480f5737a855cfa1385cd1ab0c91a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.2018213272815848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.2,
  },
  {
    address: '0x1aec6f42697aa824f6deb1d83e2a649d5df216da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1999461597754872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.2,
  },
  {
    address: '0x63d77075f98cb0d3b3f578b9f1c199e6fea8f0f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1922099102376644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.19,
  },
  {
    address: '0x75d6c8bcf117d47036679221ccaf9bb585901822',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.173055396408225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.17,
  },
  {
    address: '0xb44e554d61fd2650e4a3c2721d69f4400913801b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.166958154375146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.17,
  },
  {
    address: '0x0b98558f9ea02c7338f0b119fdf68a9e6b82c90c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1619024807129206,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.16,
  },
  {
    address: '0x902b858163721785cdb414741c7bd2cd0a43f7c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.156664098140475,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.16,
  },
  {
    address: '0x484bbb89ca521004405da5e5c2d8c90d5f04330e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1445378177253636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.14,
  },
  {
    address: '0x4934b15c0909b2be8b1a8a568b98a9bd70f82a28',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.135109649742091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.14,
  },
  {
    address: '0x2a332078b0a6ce3992ed358b6f6ccd110bf70dc2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.134170850490092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.13,
  },
  {
    address: '0xcde299174fc6dceea996f3988a12945a023c245c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.129736979755201,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.13,
  },
  {
    address: '0x8bac05bb9cde8a0f1ff5651e2ea3655fb854e12b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1289974929553237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.13,
  },
  {
    address: '0x81d48a91d4417bc87145440ac0439fb2cf1d802e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1238778660749595,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.12,
  },
  {
    address: '0xb4cd09d98b35c6c7de953c10ba44ea890494ad73',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.121619969472571,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.12,
  },
  {
    address: '0xce645ea0d31f5d1acd0bfbb24d3847040d793e3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.114555466372238,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.11,
  },
  {
    address: '0xd759feaad7a7863f46503a2eab403e5127887e95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1108644818588678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.11,
  },
  {
    address: '0xa8413d78a8addbb53196c5c487e66cd8cb2b8f17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.1091793084627297,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.11,
  },
  {
    address: '0x334c047f1a0867fec79e3b83fec34942e65b3b13',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0986518667799374,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.1,
  },
  {
    address: '0x4f60dc8da574dfb8760b796c343fc8fd004990d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0984982799220626,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.1,
  },
  {
    address: '0x9cad199bdab5d6e43e187847966eb78523571916',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0937007016559916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.09,
  },
  {
    address: '0x279a4f49b512295beda028901fd91c68c2fa39f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.090746090272416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.09,
  },
  {
    address: '0x6fb11b707b9dbf053abc8a04e5242177b3ffc4c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.082803958239337,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.08,
  },
  {
    address: '0xdbfc63eb2032a1a991feb791640954f2b6ece803',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0799020730666564,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.08,
  },
  {
    address: '0xd9b417bd6af03968e617213b6ac56453f564798f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0721552856598744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.07,
  },
  {
    address: '0x747f14c98841e67b1f7065be5ceb445b2385328b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.064967318802616,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.06,
  },
  {
    address: '0x39940850b5b95cfa3f7966a6383a7b283ea3c3e5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.064161169714172,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.06,
  },
  {
    address: '0xbd4cdf5672df0d8f3535e30c9fbddf0c8de99f54',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0601045207539115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.06,
  },
  {
    address: '0xce5428d8c2800355f85a821a756263bed212448e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.053921617031775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.05,
  },
  {
    address: '0x13e512527dff3691f0e16c71c587a2f08d5e1cf0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.048278163550344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.05,
  },
  {
    address: '0x75e94cbfe933a0638d48c827c6ed4e3647323e77',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.042919020612019,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.04,
  },
  {
    address: '0x5de91f4509e6d16dc0ec057f5319a91604e5de1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0419042279378012,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.04,
  },
  {
    address: '0x0e999cd7295714e509629d2693d9eae76347b468',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.0238169750349555,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.02,
  },
  {
    address: '0x69b6df29a47b31b1e9f0353bea252fcdcb0ab96b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 2.00711624666152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2.01,
  },
  {
    address: '0xb487f4e9af6096ddc39e0305f3ab8166ed230f3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9982926886300538,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2,
  },
  {
    address: '0xb535e4f14457b7410d0b3e2debd82de16598ef60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9970145290941432,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 2,
  },
  {
    address: '0x3010d7222a37143c33c4ab0e19961b904daca4ed',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9890295518445684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.99,
  },
  {
    address: '0x8dd549f4d0832612a31a649b85bc7ba3f6fd8692',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9872828426119646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.99,
  },
  {
    address: '0x5af526c4f1196e13120ad3a4ec004001cd3b7c09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9850742138304243,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.99,
  },
  {
    address: '0x1514bf24ded6d9e5ea5fc7c919914427a7b08b64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.973159981747264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x1974659067171f48bb10522c05043e805a947d9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9729612490700923,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x3b7adfd3169286605f514f55c379647279a1e8d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.97017114738483,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x9509b0a81f19b7689121cb49cec93f22624bb4a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9662923464647417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.97,
  },
  {
    address: '0x247b8fdb66396c778a40265f58ebe6a38e6b1da6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.960648427206401,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.96,
  },
  {
    address: '0x3537195aa118ec1761e8b14541c31c02b1923b76',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.957505418829584,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.96,
  },
  {
    address: '0x673271c41f56926d29f630acc7ac96e7348638a1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9568905781301658,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.96,
  },
  {
    address: '0xb289ac7ece50e24929268c0ba2250a6eb06656ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.952766265979772,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.95,
  },
  {
    address: '0xc0658ebddcfff897b1f95c20a40fd5328438b65c',
    minters_reward: 0,
    lp_providers_reward: 1.12,
    swapers_reward: 0.8343136591295824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.95,
  },
  {
    address: '0xdd1fe92225251ee789bfb04e2f188d3a03945133',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.938634584684821,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.94,
  },
  {
    address: '0x09d48ed00de80110bfecfd1a3dbc28cc014ec991',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9243986947720506,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x7d20d224796f1013253f999b1c530017a3d264b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9241418475540604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x0406f50f9dfd42965a4e9e38e259d8f7619b80f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9177611088373006,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x39a5786a0ae8ec4028d61910bbd6dfdc0b43d68c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.916333879033492,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0x8324fa247756a9d2be9d16884f620e65a142e514',
    minters_reward: 0,
    lp_providers_reward: 1.4,
    swapers_reward: 0.5221612714099848,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.92,
  },
  {
    address: '0xcdbac7fd279777ca1c6be90f076e03a77f50d055',
    minters_reward: 0,
    lp_providers_reward: 1.91,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.91,
  },
  {
    address: '0x8d455ce2826cc0a1e900fba8ab98630532463b72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.9013142738758058,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.9,
  },
  {
    address: '0xb82886268effc1317b17c0326df13a32a0290681',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8995401600280717,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.9,
  },
  {
    address: '0x22dd1d8167ee13a6fdc1dad12ac75385930aa5f3',
    minters_reward: 0,
    lp_providers_reward: 1.87,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.87,
  },
  {
    address: '0x33b76244f1885210b91c2c264ba0f4904c796ecc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8482713750361521,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.85,
  },
  {
    address: '0x7d1e680e42d6ab6010cf0e1bf1b9151cba48010f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8471513325874682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.85,
  },
  {
    address: '0x239a39aaea600fe65fdb2213b3a97ea9fecd0c64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0xa61721c51056909dd2a6a7731a22fe6ee7a35494',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x247c402a8bb8fddb4139d12ceac213a6a5e3576b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x2ae17edd21a758eb70608819166d55a9a877f819',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x6e0e7f4b0158502173a4b78a29e7c7ff1b3acf5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838495292045767,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0x632ed853b605d5a6ea624c5214721c606f958d3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.838454267637472,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0xdc786cdf761b82918994758329853d208ce94af1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.836686112469912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.84,
  },
  {
    address: '0xecda2216b7219f0b6c112e3c4a2872a62946cf62',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8325795245650975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.83,
  },
  {
    address: '0xdb82e322d9c59ae8f1deb018c0b6004fd4eacbd1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.812759188087738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.81,
  },
  {
    address: '0xebed1c7e28bb9d9e1024f8afb6f8cd31615e4eef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8110930318889042,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.81,
  },
  {
    address: '0x5e4d48284f5f0e1b772ef6d638635d438a25f11c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.8000273544359204,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0x925115baf5050034209bb52f3df218125565c30e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7994193717475402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0x4f26685fc3e79d8aadb2d7c256499c53110539b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.797007674308746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0x7c573b5cfd0149380c65a94437581a6313edbe1d',
    minters_reward: 0,
    lp_providers_reward: 1.8,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.8,
  },
  {
    address: '0xd8654bfc0f5b9a9f8e5abafaf276e27e719d3025',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7779749821346018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.78,
  },
  {
    address: '0xdf6563278b279ef095c8e7790a67885b29cbbea5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.758164711965855,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.76,
  },
  {
    address: '0x3c0d1014bc5f692e3ca1c58cf2d7c523405d0ce5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7478941653220346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.75,
  },
  {
    address: '0x18b58e344b65650d48fe9bffda0d91089d73fde7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7471170167844077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.75,
  },
  {
    address: '0xa0d254a39ea8645ffc79a9353c32f02504c5f3e7',
    minters_reward: 0,
    lp_providers_reward: 1.44,
    swapers_reward: 0.3098164380045622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.75,
  },
  {
    address: '0xd61eae1c9dcb589df2f5d3ff738f8abcac5ee172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7352208046625572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.74,
  },
  {
    address: '0x03731a2749f238e98bc28a1c32dcbc602f89a2d9',
    minters_reward: 0,
    lp_providers_reward: 1.74,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.74,
  },
  {
    address: '0xf0f21e63404aeb12df5a52862596000dc195fab8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.712952160282845,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.71,
  },
  {
    address: '0xb80ad6f5c7ffeb9b08ca2cad80f14e8a72b412b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.705884575663981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.71,
  },
  {
    address: '0x952f8e096d1252f7e82ade41c0741454d4a48d2a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.7030771330763226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.7,
  },
  {
    address: '0xed63e75f6dca2f29ccdad4ff6304e3b722858676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6991418228071995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.7,
  },
  {
    address: '0x206710759f9c6ad87490a131ab31255b38a423eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6941221863422504,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.69,
  },
  {
    address: '0x3496bc59019a8456a27cc4578976a2288efcafb6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6900571323340494,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.69,
  },
  {
    address: '0x698090c535255d6e5089d674368e914ff7710333',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6831881155761894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.68,
  },
  {
    address: '0x1ae321f5dfc22b5a331fe574b1abe77c54ac58d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6831751179695444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.68,
  },
  {
    address: '0x79f7dffa96c044ed68951acb25b49eb268dc020d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6823121012237674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.68,
  },
  {
    address: '0x907cd863b20b6c8952a95a51fd50b3c9656d1a9f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6727312479599954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x7fbdf7f1e9cf635b34f0cd97a3347b94bae658cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.672630115724073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x0e034e3144714fdf8d4b12e23bf37372713d0aee',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6698685977430976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0xe902406f9300288c47961a55d8d8eafc71dd02b3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6685746080025836,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x06b97a4b433f3c0aa3d3ffba37f97a295b7c0be7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6676605148467067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.67,
  },
  {
    address: '0x123a6ff772c57d4cb736f87a610bd8d19a1096eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6640084026888946,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.66,
  },
  {
    address: '0x5d02e4b74b62ababbb162afca4593d5273dc127b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6488861325953459,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.65,
  },
  {
    address: '0x0f00c2dcff14a6a27e4904d3d30923c52a647346',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6450632424540326,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.65,
  },
  {
    address: '0x965dfc3694c1bafec7cef645d7a17167c9a5f0f4',
    minters_reward: 0,
    lp_providers_reward: 1.65,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.65,
  },
  {
    address: '0xb863f4d8382cfd099d0a226bb08e38f4b697b585',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.644852393827329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.64,
  },
  {
    address: '0x089fcbdcc5efe63af7fb7d12e7866398e98b9c91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6436279702159398,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.64,
  },
  {
    address: '0xc03f5f2e85a0d0be778000090e2588fc2b0aca10',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.640999878273074,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.64,
  },
  {
    address: '0x31f99ac59c835f028b3cea1c1570d482d3492e0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6247949026732775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0xc8a11192b6d35f176e6281496896f3d6a1c4220d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.619948589080894,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0xc8d4b3465301e6496eb06241f596ad6bc3f4dbbd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6152587342460807,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0x222744f1bdaf5d74f4ed01aa5071472ca03e5063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.6151200540803383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.62,
  },
  {
    address: '0xcc59bbb69baf3d292c3ca0e3d1fe2bc8d48eb126',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.598879226223819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.6,
  },
  {
    address: '0xd3512ad4cc8476723f059931cdf77506e03e66c4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5954103695741064,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.6,
  },
  {
    address: '0x96147761d17ac6d9071f4f3f0e38a079f16c1c35',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5948329228601528,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0x25a4117a24c6283998cd34dd8e1d6cf6f47d275d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5945542594251663,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0xab1394286d69143b958d361fd60ea763fcbdb7a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5936694103655646,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0xe654f7a1d28ac0b18fcec467e8fc8c3872d29c40',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5868137680003018,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.59,
  },
  {
    address: '0x716823738954b2f9b2f00882c7745e0a1fd3eeca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.582840922915976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.58,
  },
  {
    address: '0x235eeff6a8cd136bd13ceca52ea77f8301b8efb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.582074162046667,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.58,
  },
  {
    address: '0x59bdd0891f43e466fedaeeb687be7d2e2107e91c',
    minters_reward: 0,
    lp_providers_reward: 1.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.58,
  },
  {
    address: '0x1883a4bffee2f62145649a799035c3bae48ac60d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5734845840381435,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0xd042deea0fc45bf56ce5674e7238afe2fbe6e392',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.572827813412723,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0xfe47b1dec8b0c14e3277cea44d10d7943d54ca55',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5714070250504557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0xe49e4f067c47e7289fc9952e6fb965b70153fd34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5673187354474512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0x158d27315ba32023ab46819a266d33c6d05c8c02',
    minters_reward: 0,
    lp_providers_reward: 1.57,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.57,
  },
  {
    address: '0x337c65289019f6b7d7089b2dc65b2ca152176852',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5620867097814746,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.56,
  },
  {
    address: '0x1372d3c0182c3d21fe23f0a3ac93b8c6810aafb8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.56164418977777,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.56,
  },
  {
    address: '0x376d70b51a08af7f46c00ae6d19f857806847dd0',
    minters_reward: 0,
    lp_providers_reward: 1.56,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.56,
  },
  {
    address: '0x4c09aee50693b1cd96a0b587898848f2aee92ab2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5477219919853284,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.55,
  },
  {
    address: '0xa037bfbc67b6be40795e6c1b048568c3c16e8b68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5414070651824017,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0xec05f03789178f9f4ed824b5afe0df4c0243c7f3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.53921149945804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0x89ad203f0ea569f15a571d8e9e566f33ef03e281',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.53921149945804,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0x56d4aab9c09c790a59d423132274d48e5f67ef43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.538457252489344,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.54,
  },
  {
    address: '0xd2dfc551f1584a5f6668db1425cd1eb402f0fcaa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5290870596818376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.53,
  },
  {
    address: '0xa45be7e9c2a6dd64f26a763d90cb3221e3fcfcb6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5240038303824035,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.52,
  },
  {
    address: '0x675205febc463b307fcb96b3984fd9ecf796e81d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5176249832366138,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.52,
  },
  {
    address: '0x5429b8ac8908830276dea4a3e2b5b6f42cf267c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5114789548790668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.51,
  },
  {
    address: '0xff7bcb959044c3a2d6f40247a52e0619c6e86547',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5089155380530872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.51,
  },
  {
    address: '0xac6c2d850ee943222d905ee84d6a61a91e3258c6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.504792546170409,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0x59e88daffcd84f5dc9a277eb8a9f080bb0369d1c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5023976785707334,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0x65f50f451f6bd37b44e387da622676a55a1e4f64',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.5001504907028782,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0xa95dc2b73a06c0b395f0eacd031387d75e4c1c37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4974171827663392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0xfef544b49dead669947c1d812e1ee474da9aa2eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4974171827663392,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.5,
  },
  {
    address: '0xee8490570c84a242ba5502044d7fae9e33239184',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4912471446080697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x393d348c91c7175dac2228a353bfdb2dff317651',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4885187128865716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x13eff7c7837eac58c9559ae3841f30978921d63c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4877171130280975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x93c3680a31c830318b060a4c22f17f1142ca509e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4877171130280975,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.49,
  },
  {
    address: '0x5a2cf86052acdfe2efcc48dde69a8864f947113b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.48,
  },
  {
    address: '0x7ebc42f23a677535b3484b94d9eecc2f901bdf8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.48,
  },
  {
    address: '0xc031c97f116015859041a08bc3f2b8df11866fab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.48,
  },
  {
    address: '0x354a18e6c65cac6ef404e0090db203585a03907f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xd415d07ef06a5e9351fb3babafdf26b816a15b8f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xf598bd711aa703f0c33cf969f51ad994a7d3b25d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4718419658552357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0x9477e7f178ed5ca52b15aee68b9997160d8653d1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4666307731836579,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xc596a98d433dbfe59ed57af3b4ac8ded717cd2d5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4663839379931851,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xe61c0bf161d19926f78a8fb3cf001749630f2a3f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4650785382449958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.47,
  },
  {
    address: '0xe18779b704a017d87ff5c23298abf1e72b7970f0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4625351337991213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.46,
  },
  {
    address: '0xda223771a24c2333f0d8d9e9a5f824f6126064f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4603046041210752,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.46,
  },
  {
    address: '0x9e0fff41af45ff20bf4387eb732ae9499675e297',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.457512788100281,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.46,
  },
  {
    address: '0xb3a007d1f2f6bf022cd1c21654137a48fcbe3cca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4504412935349351,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.45,
  },
  {
    address: '0x43d48d2bc32b1112f81aa86c374a3583c8490ec7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4406743211190611,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xe6f1aa1a66b00580c343dd9f12e0930497f4d36d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.439925251596806,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xbf020b7d6a4a83884a0f28b37d7c46e5286a2777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4399127746455171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xa8baa16c67085ab5c11ceeb8e45f7a6891a25348',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4399127746455171,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.44,
  },
  {
    address: '0xeb2f117c1429abe69204c71fe6a0fca0a761fe3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4349845459575163,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0xb6950b14d9b5570d60897fc27eee3b9d82133463',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.431324875750687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0x26a8efda8770828756adfe796a336c577bb11efb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4294649427214436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0x207e490da7580921a81ce0e09667c81d7609a3f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4269927428844387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.43,
  },
  {
    address: '0x5d75898279fbab3ec5a4c70d61ecea0159e654dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4236347172778554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0xee134ffced866d4b1faf4c43b3e169483698f5bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x42df2b643efc1de84ed50fb9a561b81ca4aa310d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x640d25fc1dee7684892a08a6edd329adab4be63e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0xd1db7966c5f23226818e63f456ed7718007d01ff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x26813a45e2814a745616131979ef95624f86b2f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4225395224300954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x2a351caba6236aa691afece294e531fb16fd1ea9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.421833085108413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x69cec5f47a5302902605352d25b41eeab9a382e8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4217603681670083,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0xad76395624e3eecf092582d9c6761634f689c2f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.420175629792558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x2ff234f543881a30c6e241730690b06fe210f540',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4159545345349553,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.42,
  },
  {
    address: '0x4ee83dbe51ce11287dac5a002662a41c08fc1874',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.412373885477967,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xc56532b5ce7c8550688100e9ed0767480ea929ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4123355322591766,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x04785c85fab3faca559f9894df526d27727c6e38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4100772178897558,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x898a2a1812f47bed5c04e2cee2cd9c091f77653a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4099597362063554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xa2ae3726432b1a0614c0a59be8abfb33392123de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4081670785083955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x88870c0d13e52de28bf8d13e6eb72f2af41ed603',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4070401758948254,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xa3fa6b12a021160fab607424a670dfce77338c14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4064981562757288,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0x19da70be3de5c015ff30851ecd61cef2f6b4fee5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4055732687535378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.41,
  },
  {
    address: '0xc036aa17459cd0be9b341683f4451031b1a913fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.403918413974421,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x538ebb9c4b05c21998b595644fc5e7def8accd56',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4008960169170332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x5d759cbc617284f6392a323c9e90e18f752deae3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.4003019839525952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x1bf6261aad22758c97a6ee4ca2aa729068709530',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3990651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0xd3caea3ec9bc6a302a58cb2f2b45ec608a4cb871',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3990651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x84da66246b2852d1461de4b476bd9640e1410cb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3990651004677594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0xb42136f57e39278b141cb4ccceb50c811cfaa785',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3954050399053892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.4,
  },
  {
    address: '0x1a05f6cb8631ecbab1002f745acb887a671aa53b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3945584873154686,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0xae608ea5f4d1c70208330c095a754156577ada97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3939427066333294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0x0e2ab1714008b21407f6fb6937994ff66158266f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3938307268482775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0xde139be569bbc57a93c33b4e4316e6692c761497',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.391938634333583,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0x0e5ce321cf57e95d679e95c8de5778d500c62738',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3902072757161754,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.39,
  },
  {
    address: '0x60fb3debee97b1eb9778f965e2e584ab14fc9f1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3823430632151805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.38,
  },
  {
    address: '0xf3ee2ef64eda8528c5f78b06a7782ff8a5a8f3b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.382318069275695,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.38,
  },
  {
    address: '0x0d4fc2dd92e52d972d30e6a096c685c707355741',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.378433152526794,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.38,
  },
  {
    address: '0x8306dd6122f66f38044f269ce6f17b95251d3970',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3715099184951864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xb67002233f6e55a54be610186f51bc92a43da4ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xe0f0ae22b923782602d38dea4dc52ef349bde1ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xbbd492bbca1d769b94c55bbf89f85012444f11ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0x0880d4b708c19a18fcb2a876a1c17f31c480c73b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3708054117031123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0x49885fb63c9e13ee4ed4320a7a6e5176fc4f5003',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3689785126885037,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0xf8ab6386efd0966038e352987d92eccebbaec2a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3681064967759178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.37,
  },
  {
    address: '0x04b89f4fa25d59def275a3a3fdd3bd6cc67b3001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3584370877457652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.36,
  },
  {
    address: '0xf80cb0c24fc725a04ba92144f71976ac7de63e14',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3554696209364028,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.36,
  },
  {
    address: '0xbc7c5a6d4ca02e4825b6e203742c42f3202c1b32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3498148210815193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.35,
  },
  {
    address: '0xa0d6847185f0232b397d2db4b9c4f6e2c71774f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.349799471751993,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.35,
  },
  {
    address: '0xd5553cf751c5b89e0b9f3b732ad1c19023ac1f27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3463075016735226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.35,
  },
  {
    address: '0xe272d722739c4ae10d07c86fa8e7f55128f21586',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3447763212449533,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x00b0689dc3ad7dda50c193b84b9a13cd8ffbd078',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3404172064040079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x0052a9e0e3d59da9bcdf00d9892eab102d7bc96b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3356919185095757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0xaf8b4f915857a0f3094fe2518aaa1ff2e83c2a12',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3351086001141605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x25af3f17c93e57ee864709d1e67897db11960fae',
    minters_reward: 0,
    lp_providers_reward: 1.34,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.34,
  },
  {
    address: '0x49f5ba00e8605e3065d2cdff8b72d3c43dd3c702',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3336906159030644,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.33,
  },
  {
    address: '0xc301056e55e0c4c980622f042f61777c27e53551',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.331523145387121,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.33,
  },
  {
    address: '0xa2f3b873d355d66e8106d74f09653452b828873e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.326012480920357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.33,
  },
  {
    address: '0x43059bb9360ad4643683fec463aa67b87898e949',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3246700479084237,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0xa78fc7090cb8b685a7ccdab316c68dfabbca6f07',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.323346212709324,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0x99083acaa2891b525a977eeb99a733fb977cdea7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3191045801168113,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0x41fa7a31edaed2bd74205cd0fb7fe0c37fb3021d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3182697306599838,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0xa67f4d6dd924cd89587fc742365970716f9a8f51',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3173832924770525,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0x6ff7e62b4d1d143ab8dbed4c9cba19a3401fa399',
    minters_reward: 0,
    lp_providers_reward: 1.32,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.32,
  },
  {
    address: '0xe556184c1eeb240f3d1dd402fa6acb5beeb31f4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3122974872551014,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0xc2184d23e130001258528eff8e8fa42b95eba096',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3067660468541813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0x401212ab64e8635a27daca31386d051ddd26dfcc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3065754821404196,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0xc5baf35c086eda0c20219869563ce0923b1c7789',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3065037324827427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0x2475c863669aa4d9f02c149b3d1afd5116cab631',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.3065037324827427,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.31,
  },
  {
    address: '0x3b21875d28cd2e138fd94f2273d098646278b52c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.302480879576632,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.3,
  },
  {
    address: '0xae4217e95f3180591469991634effae762b2744b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.300003253871786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.3,
  },
  {
    address: '0x67bd3598cf58e2c24a3fec73fe4bea00a3076308',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2987607319104986,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.3,
  },
  {
    address: '0xd28889fda1f9a41c3784b6d81207e85497816afe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2934386951579155,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.29,
  },
  {
    address: '0xb2654eb8acc50b55b40a52be138adc453161d345',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2904775733198122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.29,
  },
  {
    address: '0xc85b1d3d5f2d1c81a13abbfc7e3954677b3acad4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2903601182662314,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.29,
  },
  {
    address: '0xa205b7294f05218b9938a1b0625efa1c2a6534b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2844571654115546,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x4671071814d21f30119e6c8231ae5c1bf90dff17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2827612317027068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0xb41e3f4e6efa59edf1391bd52a55daaf49accd19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2790012314888828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x122fbc35675ba9672e20519868012d9b297af6da',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2759325926803455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x51d3552a5457a350a68faf61074678a07d33fb5b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2755879384108275,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.28,
  },
  {
    address: '0x23441b6682991389df1ad7131a1f3159bf1e889a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.273898664827841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.27,
  },
  {
    address: '0x8aafd9af16171f606044e296a19add5e1cfcd21a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2654743648758606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.27,
  },
  {
    address: '0x74f67324f90b6624056bca96a2257a93daf84f44',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2654743648758606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.27,
  },
  {
    address: '0xf0fb3e8de996e1fff04fd4b6dd5f6c3849f449ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2593031014366118,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.26,
  },
  {
    address: '0xf42aebc5f556df67c94f2dbb51e262ed5ddc0a0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2552255780449988,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.26,
  },
  {
    address: '0x83134cea937085c0d0e98189722cb410e86f7331',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2420107358576864,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.24,
  },
  {
    address: '0xda80d68fe0838b2cf2d2bae7b7d8de0092120567',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2407261341982854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.24,
  },
  {
    address: '0x6b4dbc7089306e260492f0d3bf3d4537054bd849',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2394244924565736,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.24,
  },
  {
    address: '0xbfbc368477a54b03e3149fa058407cc4ffca7bf8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.224616866541436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0xc1e0719ecc1d1e2ada144fc7353e2c54c2e24874',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2204660062586936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0x37099c20815606278ee22a6614e443e2858d8d34',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.216185121474015,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0x4626839977a800f0ddd48e879784b1af9088bf53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2158618555796727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.22,
  },
  {
    address: '0x5f40d0b597cfde8598fa14d8759b147982d26cb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.2077213712876902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.21,
  },
  {
    address: '0x512ad903bba750453f70c10eae0410fe91ff7870',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1991923612017148,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.2,
  },
  {
    address: '0x6010e43418fd693b37f17a9ab240ce17a95c48d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1991775071252053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.2,
  },
  {
    address: '0x1cef38f71be7a231777ef317bb9653912a2af502',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.191960376337471,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.19,
  },
  {
    address: '0x4c4d395f1e28bba94494d7125c6ef1f682bb9a60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1895673886559843,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.19,
  },
  {
    address: '0xf2d0f998de94aead8807e6b2d905d852fb4ee9ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1849499319460557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.18,
  },
  {
    address: '0xcb3c320b6dcf9855461d3e1ba714f4e0c27978af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1816761843587493,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.18,
  },
  {
    address: '0x72df8696bd7abf74a9d00f16108c47d27485e6c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1786494570267856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.18,
  },
  {
    address: '0xc16abf46fc5a5f91256274327deb37c7d2e812ab',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1748026226226111,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.17,
  },
  {
    address: '0xc429842839945e766869e1840a0a89f348b3a803',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.157659614880955,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.16,
  },
  {
    address: '0x2a5e0edb864fa0a47177db57b3e49b220554d5e4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.153295332849958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.15,
  },
  {
    address: '0x4eecfb3e814e866933ea34da7a72039b6699ebc9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1495773688629294,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.15,
  },
  {
    address: '0xb0ffb9f67f814d7ec97e7620e9318adcb8bb0089',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1467658023278708,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.15,
  },
  {
    address: '0xf3e3a179fd07488e1883283c091b67e823c42dda',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1444017298409102,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0xb31308f1eb576c93fdf762b248a7b2bb730f6bef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1435719541771552,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0x850904220a4f3d0755231bc67b7d7c8c5141bc17',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1411296126822668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0x4eef633f73c860791d0b584eac6348ae68d584c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1387251465291153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.14,
  },
  {
    address: '0x59ccd9ecd73cd9752fa8a46336d0908403dab1aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.134510727916463,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.13,
  },
  {
    address: '0x420f2d3ceea729df65b9145ddbfa14b50a44cb05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1254702966368295,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.13,
  },
  {
    address: '0xdfff6c89240b1040baedd498fb2e9a2c87af9079',
    minters_reward: 0,
    lp_providers_reward: 1.13,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.13,
  },
  {
    address: '0x85b1345c0dab5e57fcefda87e71fd8416cf3f7dd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1211016782474874,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x583823fcd9091e7fd1781bd2d9e55e77bf07d625',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1207679420083996,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x7e11ff235c442fa6c5d5df5a78f33efb1ce47ded',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1192520803742076,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x23bb1e608c36854ad1a15e98a00d85d0c21b37c4',
    minters_reward: 0,
    lp_providers_reward: 0.56,
    swapers_reward: 0.5604090367761942,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.12,
  },
  {
    address: '0x67d449889c131b4404803c43054fec26bde4041b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1116233568226397,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.11,
  },
  {
    address: '0x768c0e6e4785a7dfa00205d0695e9272f0334ddb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.1075072076797066,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.11,
  },
  {
    address: '0x0d1fb7595ed81983a3b84442f8c9e7381c6d3e21',
    minters_reward: 0,
    lp_providers_reward: 0.82,
    swapers_reward: 0.2867699263431286,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.11,
  },
  {
    address: '0xee5f520342614bacced80b3362b9b34968f5f249',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0974805930799596,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.1,
  },
  {
    address: '0xe53653afcc6d53dc7a7072a91f288dbebc9c4829',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0951681699535638,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.1,
  },
  {
    address: '0x1301e1f669af3996c6047ded55313b7267de6979',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0899290042673673,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.09,
  },
  {
    address: '0xad78ab2ae1c462c99b14951c528af4cabdb283ef',
    minters_reward: 0,
    lp_providers_reward: 0.41,
    swapers_reward: 0.6803647920676378,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.09,
  },
  {
    address: '0xd5b0e0fd71ba9e3983910eab3bbd1658afc13d80',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.075165845055972,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.08,
  },
  {
    address: '0x400d76a687c5badee24514fdabc4b8447df73df1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.071096385855444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.07,
  },
  {
    address: '0x3cf0fdec7292e6fe382eb54eaf4003f5a1c398e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.06907438752813,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.07,
  },
  {
    address: '0x0e23694bed466a9932306f6ee591e218b6e0c705',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.066786917292106,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.07,
  },
  {
    address: '0xb36d7476275d7dd3897b4d875fbf68b837d3c7ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0632018885924488,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.06,
  },
  {
    address: '0x853ed418904321f57faf4e246c8b4487076f0a68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0622406400059825,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.06,
  },
  {
    address: '0x3217e9ea08331de1313dffeea19e8f191f0117b4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0512525947348383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x68396c51e481fc3cb2b0c35afbf31833c327534a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0488990963282852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0xf98cf6f351e102e3ef7882667f80e10d9a321e29',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0475150288018944,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0xcfe864df0123f728f5c76cf4ccad632a167bc8b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0471553437699448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x55b020faa6d2a0c0d78f14cd43a167d5657b5cb5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0461962510495892,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x2115eacf394e1aae98fdf435316d4ec56679a461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0452603857123357,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x02657bf94645acd075f9d5627eb262dedfcf52ef',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0452391530217209,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.05,
  },
  {
    address: '0x1402fa71fe7d7860e88332a9e3e39498e808dd2e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.042305604887258,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.04,
  },
  {
    address: '0xf0bcf8253ccfcf28b1fef4d25b9f1035b6cd8ed2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0414019791196685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.04,
  },
  {
    address: '0xc4e97e96a20ee852c7a8d2c4558f5cba253351aa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0359888290223687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.04,
  },
  {
    address: '0x29bb4e73ef9cbfd72b49a7e2e86fcd2edd6b36e2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0343932445182027,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.03,
  },
  {
    address: '0x226390516c3a3ba1dae954459179a9552b823768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0300522603769557,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.03,
  },
  {
    address: '0x275a6cbe4a0d4bc90ef70dc41a4cc21479195c00',
    minters_reward: 0,
    lp_providers_reward: 1.03,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.03,
  },
  {
    address: '0x79d0320c8c56832cfc1ab632072809f59ac6848c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0218647498918452,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.02,
  },
  {
    address: '0x399768765877b5af7737c19bca1a9fe6607f882c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.0161470615536343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1.02,
  },
  {
    address: '0x1d0f9ebcbe6966b1b4adda4fa500723fd2086950',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 1.001811094039872,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1,
  },
  {
    address: '0x3b79251f464ba2e9bf61bd6c3f4c401bcefd54be',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9950759419234291,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 1,
  },
  {
    address: '0x2bd886071ba2066b58df1a2e67c7df85c02aeb33',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.990661772968406,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.99,
  },
  {
    address: '0x56d0021a8cfb00d5453bbe83502da85f45292ce9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9865576293854627,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.99,
  },
  {
    address: '0xac6427a820128faf1577fa2c469d7c5c22adac5e',
    minters_reward: 0,
    lp_providers_reward: 0.99,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.99,
  },
  {
    address: '0x47f9bf60467646aaa332f563ee80e4482b61b3bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9842049061349205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.98,
  },
  {
    address: '0x6e49458f10a364792646dae916e28e1f9123946d',
    minters_reward: 0,
    lp_providers_reward: 0.98,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.98,
  },
  {
    address: '0x087bbddc0891ae05d68e0e11763f978fd5cc1a45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9747898860532103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0xb22988e23ce8f799040fa57ee560be64e3056f38',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9723178957622685,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0xd4a72518f9354553201f29809258c3e1c4ff2e0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9662266945858995,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0x8a243b0ef5815d7b17c9a6d5bf59bc1ecd8cf1f7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9659929749183772,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0x7c72647055cfeec74fab537843c277d954abc736',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9652679699660757,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.97,
  },
  {
    address: '0xbb55e65aea5cf1b06261b7fe03fd303f609d1932',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9608394660262011,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.96,
  },
  {
    address: '0xef213299c376c978ade8ddf201cbd5ac158890b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9586966509709938,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.96,
  },
  {
    address: '0x352aa8dee04095e58197c0addb72b9e6b9357331',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9491057736568954,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.95,
  },
  {
    address: '0xec394d02683638a54b2c79a6563b51db474d3452',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9481117156013776,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.95,
  },
  {
    address: '0xbf9ad2f76c93226731563071e29dca9ab3e91a43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9437644346000442,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.94,
  },
  {
    address: '0xfba769e7ce383eb6abe19a8b1a174c5e75d62929',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9416940540269605,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.94,
  },
  {
    address: '0x808b87d1c83ef85b9d48ad4649410cbace74e001',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9324050926800423,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.93,
  },
  {
    address: '0xf51d38bd1bc1e49667502304b6528053c7f779d7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9310901040698164,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.93,
  },
  {
    address: '0x9408d72756337400efc4604d9051cdf4c09fe919',
    minters_reward: 0,
    lp_providers_reward: 0.93,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.93,
  },
  {
    address: '0xcea29e452595581f20c3482e5a0d6c0e062b9549',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9233191863058599,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.92,
  },
  {
    address: '0x083fef372f5a936e876a62efdccfe295119c71cd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9221683047339365,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.92,
  },
  {
    address: '0xcf2ec5777410808aaea53e847707399535791b08',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9079437141041274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.91,
  },
  {
    address: '0x4e915b2992dbead3ded9d013e6730f2de68ce13c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.9040969062297094,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x464ae967bd9ba3134794bcd7e09aef22497f1497',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8989540169392574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x8f89f7e5d3c500b6342fcc868259036f67114131',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8979328621918948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x92e93b73949626798f029ca96adfa91994e0091f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8979328621918948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x8b29b5421d8467ae222890c4eb4fb148798e9c9e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8979328621918948,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x16a6f45d6ae0a0473daf4e97caedae99ac80dd60',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8971633199089182,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.9,
  },
  {
    address: '0x676a9b15cece1b68efa8fd5114744b1b6a5482f6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8915769378553512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.89,
  },
  {
    address: '0x5065af8c69fd0646f4e72014a4716e8ff66019b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8866698212801676,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.89,
  },
  {
    address: '0x893798e182caa074ba68eff50eed9cc1f555d9fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8791961214640245,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.88,
  },
  {
    address: '0xa837acc2c5bb6f2332306f9aa2904fb1d32ac46d',
    minters_reward: 0,
    lp_providers_reward: 0.59,
    swapers_reward: 0.290552875929541,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.88,
  },
  {
    address: '0x20a52d62b9c23edd48537a8021e5731148ba51cf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8717119115352187,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.87,
  },
  {
    address: '0x9a41238cee6738e91cc5aab982a1fb323a034aa7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8456041320000515,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.85,
  },
  {
    address: '0x4a7dfa62df6de322342ef64fab73c8604248017a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8363656239799977,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.84,
  },
  {
    address: '0x1814f2caa56d9b06d8195134b31ef16f14b7d267',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8351461703892474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.84,
  },
  {
    address: '0x0ffd30708515bab7f8958728e2443044f6641f86',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8299783927827556,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.83,
  },
  {
    address: '0x1e37718abb5ecef76de0a3855fce118c0d624bb7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8248961313819215,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.82,
  },
  {
    address: '0xf0928914eb660d8baa3f11afc31ab4b95cdf3ce2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8133046579512112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xe0f379d6c38ed1369a94411f656294acd0ebb42d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8123974513366388,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xb0661450c5316e2626bc594859a1d0d3f55c59ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8113416669019947,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xc336223364d5451a043cbc5c4df2fc2547569a30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8105707599051202,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0xd880de2b0bc63375fbb1435ade1631ed9ac919a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8089190599208055,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.81,
  },
  {
    address: '0x1bbd4c3163f0c5fe4edbb90464991f6e2fa42a0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8028599805057609,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0x2859a82cf81bcf867879dc4235e66bae1df350a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.8026577892759819,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0xb419e204a85170bbf29ad4ba87741fb4ec2c2adc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7964129178488447,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0x7245c0222ad5234475b878efe207efbd511c3ef7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7954744546226934,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.8,
  },
  {
    address: '0x0ce643cf19ada8813728c002d8292f9107cdd642',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7934286152271487,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x0f0113f1ad223eab63d04377451780839960681d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7890379141299082,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x295bc408d472eaf525d9983e70a476d86e47f01e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7864139067063615,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x405dfb656bd295d5621dfb39642b1608672ecacb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7852202061431678,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.79,
  },
  {
    address: '0x73ac8357ccfa426b664a5b3631272590bdd119d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7822237382635316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.78,
  },
  {
    address: '0x7b3213b0e4203e7b3af738bd279712f3abd4c378',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7761397892129579,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.78,
  },
  {
    address: '0xca36a4bf0a747e67aa783d04ba347fff3f29a618',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7666684886483313,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.77,
  },
  {
    address: '0xd2f79690b3491f5ec471c7e4638baf9ab39f71fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.765953422026998,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.77,
  },
  {
    address: '0x15104a6d34219f4e3aabf99d9769a50f59096777',
    minters_reward: 0,
    lp_providers_reward: 0.76,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.76,
  },
  {
    address: '0x60a15b8681bf7d5350888ef186c7c0fab3186eaa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7524570320375413,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.75,
  },
  {
    address: '0x3a464261e228b8c315a1b51187f67f6f0f5eef75',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.74523373214213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.75,
  },
  {
    address: '0xc0243a104516d3335bc841cba5626e82084acd4d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7383211190855534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0xaddab4fa9d47516368e21a8dbcec92f92df3c94b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7383088196484694,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0x6bd37df5a87d5db68144f334d968373b671bfd03',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7354493174282765,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0x5d0433647ec205a28dafb3585d1b7b92a7181878',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7351094750084868,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.74,
  },
  {
    address: '0x19c4f70eecbcfdfb8710afbc47a5ea49ea77edbf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7347576262642932,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0xb32f12461d3fb14454d74a09fa2a0d7c72ea05c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.729712638898647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0xe14b9e005a39f419b97ff2a6500b7e6fec64f9f8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7289692160921133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0x409c3eb77de80deabe5ed47fe3e52274f1a36eec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7265561625162639,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0x24bf722ee63e5beb891a43be6a84bc96c4a4ec7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.725937280912311,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.73,
  },
  {
    address: '0xab9e2671817d3b18eb15552d79bc7fcb6883d7ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7248910248620992,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.72,
  },
  {
    address: '0x24612c8a84fd00170435d6caeefddc1b20f7eced',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7199563873227586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.72,
  },
  {
    address: '0xe06443e1c1dcbfdb31ccc78b8cf09a3c8efa4db5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.711703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0x142182fe9e86495ad1a98d784d9926c9afbeb3ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.711703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0xaae038b51ec180fe5a66070dd2b84307a3179d16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7099123265850669,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0xc615e9bc5a84b43e4bd962b6714a90a050fce59d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7061153237772079,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0xa2f84449db5ab644a73b34d7ac2965ae84f1fcd7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7060422640776229,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.71,
  },
  {
    address: '0x261e249cf9ec8fecf911d8b0bf05fa4678b02b3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7019175046610705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x55c22777bec3c1448f84e464064e044296bafc83',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7019175046610705,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0xfe29c044b726975e39e5019177197e37e3e0b8f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.7001509919762976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x605e6a7541a9034030edffb6d8677856f8c0e71c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969713533166647,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0xcc88c39b47f6f8fdb90c1870ef16d7bc1cb7d7b7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969153634241387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x0a5b7a297e9df63449fad36debee9894f4a564a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969153634241387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x27c7e9d908e9c6b6fcc52439d8da1848a81f6172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6969153634241387,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x99f72acf2c7701c1d9ca8b024ca8abbf78e0af45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6959693171667914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.7,
  },
  {
    address: '0x979a32d0040a9115d8ae9c490420d46fd617c0e9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6903488780886292,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x644cf587b7c5786f92174bfd77fe45629c51964d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6893570125187921,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0xcfbe78cc232f2a362fb606cd8344337230cc8c2b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.687951239185916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x726b7532886ce9cbea5560d742c0cde9fba17d15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6854027058515562,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x06fe1df20fff28f3f27f65870e88d4a6b9241f98',
    minters_reward: 0,
    lp_providers_reward: 0.33,
    swapers_reward: 0.36451586364614613,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.69,
  },
  {
    address: '0x65811eabef6e6c085aa8107747e70345e11271b6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6795628950618522,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.68,
  },
  {
    address: '0xcca3d4607c4529fe88a626e4e239ebdfe738c0eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6767515815238194,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.68,
  },
  {
    address: '0x92d6279323309b269cbb5ffb278160fe324c2a4b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6756445930344352,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.68,
  },
  {
    address: '0xfb4ff8210e1ed41b0d8ca503ccf0be32c4df42a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6711505127699108,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.67,
  },
  {
    address: '0x3bc866e02d95f78a159eda956f66adfb983c7b9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.66202109655369,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.66,
  },
  {
    address: '0x73d52bf205839bb5b090ad68d8691a2462492bf1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6556518584029943,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.66,
  },
  {
    address: '0xa06f384b72af85e4fa81156d1cc3f66d765b8daf',
    minters_reward: 0,
    lp_providers_reward: 0.66,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.66,
  },
  {
    address: '0x855c07750733a9f1679e67cd15b5de98a8c16dc1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6547908085471131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x6f74c089156a3097d8fc2d53fa9e9449494e97ba',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6532877410702098,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x6e86730013092ce670103eb0d0dada20f5187a6f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6532741541980809,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x4db714b6476fd14037fcc1b36d154e6cc112011f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6522692194470852,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x70ef4c91eb4973d84ec91634a073c8865014b157',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6485707442138762,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x7295d27a71b573c3bb49dc8a32547d41dc49da9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.646649748399684,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x0f2f1481d79ddb533e34336e7633421ee2a742e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6461984332968354,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x82229acb3470a880c0f85c154cc5b376c93cec53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6461609758296382,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0xd13974be49c43e4a022c381502dc89e448b4f3e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6459963092897884,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0x9f2c5318be1ae9b75932e7aeb4f4889aaebe02d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6451222355784184,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.65,
  },
  {
    address: '0xa88b10d59713995138a30c2ceabeb4833aa4290d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6402917717934481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0x915f0944c2b4192689f4d3170ae8003559622ec9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6398361062973346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0x997f9d80d45877b2dde48df6ab4b0feba64ceb06',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6394631986830902,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0xe91b443897af80d837aa024da60c45ea24c207cb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6394124536312719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.64,
  },
  {
    address: '0x68fd5933c05d024fb3629eb598d1441e467296af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6327371824379303,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x0c9db0571f34aa8aae823494fe44cc45e9b8f09c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6283286189072381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x7b5d0654febc5254d531f24bf5a8fe9c7c2cfccc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6283286189072381,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x3c68b1caa38f36f4c59e3150b8d9abe8d0714e58',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6277306622513984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x329395d2d615382c0dc9f2e786b0af6ce33ec12b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6259364817088539,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.63,
  },
  {
    address: '0x3f7605fe14296b80aa13869811cef2ca28c1b48a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6206088122785574,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.62,
  },
  {
    address: '0x6e23d48b3e7bf316a987424e35cc1e7db645300e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6175970727722143,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.62,
  },
  {
    address: '0xc5f44180cb2c9610a55c53ca45553d80f8e0e66f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6151828836100701,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.62,
  },
  {
    address: '0xcf1330c33962251ca6bee174250dc9b364074bc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6074372782862636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0xb0c2b195673ac5153f64d2311ca940794e982523',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6067005794289033,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0x6472ccdadb8b03aa327db0be16303220b7d6baea',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.6061160597595131,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0x47b8745c3ad60ce753280601695e056f80d0c596',
    minters_reward: 0,
    lp_providers_reward: 0.61,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.61,
  },
  {
    address: '0xb650c524dac0cb87bfa647a6e095abb138bb6225',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5971290927250786,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.6,
  },
  {
    address: '0x4a129340b9af53e75f0ef487af0931415bab8892',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5965036497224205,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.6,
  },
  {
    address: '0xda820061e0ef2e7d50789d155b29e7488883fe16',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5958195202972043,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.6,
  },
  {
    address: '0x64159c89e835aa12055a0fb8d17a59a3863d5c26',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5941464641835981,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0x186aa96f50709dc69b4244a0fac0e4d19ccf1106',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5938626141030059,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0x81f853bd507e7c42c2bbc8e525cfc20cc9d4d272',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5911767063115481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0x54cf82ae1cd00a3654e04753fb369519bd5c6ef9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5855341035118264,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.59,
  },
  {
    address: '0xd68d68fb8ed6c05abbf0b2dc65167bca3248df6a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5849199421156359,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x17cc4dbfa6b0351b67c8480542d54252d5020175',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5825482358390889,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x07a97a7b96c481b28dcf0b05f68772199021d6ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5816796871108607,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x2e6fa4a2399334a50f5e4566e394cc6d5f106c6a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5778502138004539,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0xe485fa562f4565ce9fdb3244626602d647f795d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5773196084316152,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x3d43b8b323ecd4ffd4cf828388929557646c3f32',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5759651098582068,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x2fc4a0f2b21ec0115997d6b4ca4ef8310bb77c38',
    minters_reward: 0,
    lp_providers_reward: 0.58,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.58,
  },
  {
    address: '0x89e1fbbf5e921b9ace05dc695d8646ed436e7257',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5740145028164279,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x352ac32c9663fb7caac363f77b121c34d9acb420',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5722688094866153,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x12179ba9a5cf0a6677dd6efbf74684e5f3d6448b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5721294353772652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xfe0373c9ca9fdc655bfc4203696135f7411c168d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5696461500932091,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xda5588428adf67ca906063a2bcec00f9730c9697',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5695679179871931,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x3ef7ed3e71ffcc038d6ac5b7dffcd25030171405',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x89ff54bc79e6c67f0e2400923e5237cf67eea884',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0x465c7c4ee9717b0ba39bf3c02c6c85e25814bf71',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xd552f9eb2980829bd162133a92866bf93e19d56b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5657759490695745,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.57,
  },
  {
    address: '0xbac60ff0acd1dfe6800fa1fb6d7ee0f20141e110',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5604276142676505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.56,
  },
  {
    address: '0x48cb62ae080ddf2aa18b2d69e83410dc3225b6ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5583806696296978,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.56,
  },
  {
    address: '0xc2f2d58047e16f0fa5378d737938ed8419cf852e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5533388588822389,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0xcd24dbd8806a8b001afd76d085c43ab3ecf7c161',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5518332211277321,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0xf1f34225cc902261a3e1e4a4cb609c6df75d937c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.550577979511679,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0x1f9ecf8fd0cc2f896f8699b626c64b87ad76a876',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.546723549086983,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.55,
  },
  {
    address: '0x68ff0bbd6907f5080f78f08326661c469689430d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.534496675362329,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.53,
  },
  {
    address: '0x38236de9567bc527b62fda208e12045c708ced3e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5326430186956469,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.53,
  },
  {
    address: '0x873f6d265072f06e1f01dbdc6468ae60a8b5bb66',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5313625941407945,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.53,
  },
  {
    address: '0x31c9832fc9173cecb98c8b4fe0a56715a35fef4c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5236086640800013,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.52,
  },
  {
    address: '0xeb03971589cf35c3335714b2162b4e8b56dcfab3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.522508970848828,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.52,
  },
  {
    address: '0xd66f0cd13a8c5bf8450c02076033228cf56ac570',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5193841799830081,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.52,
  },
  {
    address: '0xb539bdcbabd7ab95fd1fad26a3abc7d467d77777',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5143687604830032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x5013342dcddf22ba3b19b405e7b6eb634cc8912a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5128393766328115,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x5fb0c409a66b8009005ec4b701247f50b2974ae7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5116091090897029,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x4e413e66dbfd0bb8e0b9f01f097b188f224317df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5079078858921221,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0x34df404ab837627969866e8725095d5ab5f03d5e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.5063010387706698,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.51,
  },
  {
    address: '0xbc5897a090592946290b3aa188dd6c02aba26e15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4982872573993709,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.5,
  },
  {
    address: '0xedbf490a193da7316ad3f0eeb4a562a9e4cdef1a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4981922516064881,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.5,
  },
  {
    address: '0x9ea6d1ac8563b1ec9e61d24874205311f3653e93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.49023703953594544,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.49,
  },
  {
    address: '0x5f317677e67541a1fa63051957b148f7cc7a9eaa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.48413117114012666,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x9512423866dc7f81f0c985d444840548c40057e3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.48148365519331865,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x9bab713c6d35106bcf7921349819588ccc67496b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.47828322685383434,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0xa4cddef11ddf105f40742b742271ce45ae57c6ae',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4767144880847588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0xb415705864e0038418a5c7ef2bde45eba26836fd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4761026745438824,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x4aa9bd1f225fef649480e11a5042d8f7cc7fbe37',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4752816547779274,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.48,
  },
  {
    address: '0x92493977aa22d68fbe5bdfdb410666264d5fb768',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.47245121644487176,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.47,
  },
  {
    address: '0xcb86d67fb5c47ef81fa5e8cd06f1d217b051d91e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4721793277111353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.47,
  },
  {
    address: '0x09c2dee31044a5b77ae83f5d8aeac228933209ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.46234469366139835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.46,
  },
  {
    address: '0xf8bcaf5170ee05152e090b00a862c6d67150b756',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4615465138020406,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.46,
  },
  {
    address: '0x4910909a13bfe442c37ccad8e7cb1b53501e89a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4573758135987572,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.46,
  },
  {
    address: '0xcf2c3fb6f2b6f551be8e64428961a2be42fe28e1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.45475586094157744,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0xb89f8f0f2f284857985b73e616c387c7af26cf4b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4499490399359116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0x0569b9b07860824f2c5b288e019229a979e1f03d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4499490399359116,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0xa4a70543ee7a373d755faa713062814deacd6006',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4485824298409909,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0x59fc382c4dc918c087ac0c1ed7a8248108a1d361',
    minters_reward: 0,
    lp_providers_reward: 0.45,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.45,
  },
  {
    address: '0x5fdaaa0f811aa637c807c171cc219e832961e2db',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.43935003005804674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.44,
  },
  {
    address: '0xd311b7cdaf16d30716f73037140cdd15892dc86a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.43719770285585247,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.44,
  },
  {
    address: '0x10cc69ce41a73738c98632293af8600565cbc7c8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.42595038703468485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.43,
  },
  {
    address: '0x49f59bad07855ea5f3daa2e498c9d16eafbec36d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4191013442987569,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.42,
  },
  {
    address: '0x9bef5a5ccbd7437253e1a7cd10fca7f8f0f7d308',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.41884756943499024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.42,
  },
  {
    address: '0x0195d3231e02bb86416a1170beb6a0892c7a4b47',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.41884756943499024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.42,
  },
  {
    address: '0xf86172fa0ce6f557d42815fc6c624169007b3eb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.41453742050678727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0x506cfcc0415c3e50f09e608bdb2bf9a39b18b00d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4100363453831882,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0x8e63fde07a73ff6054162047f4c83443a8190003',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4063335924005904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0xbe7e848db82c1be21fdd6aacdfc51c900b2682d6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.4053894793924173,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.41,
  },
  {
    address: '0xc5c9ee1172731c310d08041dc004c8909774e0ac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.40495818552417573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0x384224ee2762df35ae3f956cf15ab568dc0498bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39859145952811675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xa92425b6fd2fee4ccf92cccab1773b2805dde95b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3985642866230956,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xc5d131bd5a5a69221905143907a380db7189edb0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39855380128519036,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xe9825fd47c5d863b1aecba3707abcc7c8b49b88d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39824804208515674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0x11d1c5cd049af2c9a9cc2765dd0695da5b5a504d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39566491106665674,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xc92a432e8160fd877f447eb09bfdddd3b9b0d7ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39532330807071353,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0x53acf1bce90d3398d33d1abf24f68731f383c482',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3950952299042505,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.4,
  },
  {
    address: '0xd8a3bfcc74131f28a798b0495e9342dc2a00abdc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39376088528032904,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0x41228ec3b11fa936ff2bd01efd12e270ec855db0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.39361791993316136,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0xa0a024009cd1ab4e6712adf8223504767df73545',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3908924106731479,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0x54033997648c1af693492d6c08407615a3fbf0b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.38540079486788276,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0xbe0d76fb8a4d4856c2d829f9eb51f66add4abfd5',
    minters_reward: 0,
    lp_providers_reward: 0.39,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.39,
  },
  {
    address: '0xa5456c7084eebd81052e06582b740fc90249c068',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3831959412567922,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x41e2be7dc0262c6bd1f8caa9112c3797d0f5295a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.380288153484476,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0xa04da2f915444e9889c089a885232bfddd513a3a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.37722888451327186,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x4f95e6323780b14ff498012a1c3b2c2d4f281666',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3769714188751424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x645ff23e37c18a1d798e8cd70773c62065dbfee1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.37511261829916953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.38,
  },
  {
    address: '0x10112800546516ed706fb4397fc9d5d5c9a75c9a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3720649979161652,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.37,
  },
  {
    address: '0x6c2b02890452762137ca5a87b626aa291a3c99c5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.36932742896664356,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.37,
  },
  {
    address: '0xd6687c8488ececf477700df10bf6036794ac31c9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3632422036653856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0xa515eec85c6055cf10692dabfc7c93f0901ba6a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.36142750135439417,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0xdef3a7a8914d6101cd3107844d2cea27b6075593',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3608474829803375,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0xfe4fbc09ae41c13e2f7480edfc435f9344b9bc72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3608342357440061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x7def07c34bca069bc56b04166bafd811f7beb525',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.359519985358559,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x89346b64995e5f8493431c821e3edcff8759d98b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3558516082903485,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x1a4bd9227948d20a841b09291ff1ae1f92fe9568',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3552654483140461,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.36,
  },
  {
    address: '0x4897d0997a07a4e0bc6c90382fdbd8465fb9cc09',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3539290522500953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.35,
  },
  {
    address: '0xf23df8d57890f8493d09b21e229344b370548ff6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.35337830361519573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.35,
  },
  {
    address: '0xebfa00c7424fdeb3635754210aad38c2ba563011',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.33861132700049207,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.34,
  },
  {
    address: '0x3a7da32453dfd858834fe9442182169be6e8bb36',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.33529734899427166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.34,
  },
  {
    address: '0xb6d7a2c9e84c5561880020fdd550e515041539cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3303431341383073,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.33,
  },
  {
    address: '0x01c8108736ddaa1c63123446dfbd0b711301920d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3267747378677335,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.33,
  },
  {
    address: '0xc93ea304831ac7b69a9dc7171a7885e96c7114fa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3266438705351049,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.33,
  },
  {
    address: '0xf57e17d80cb7d1c819f4b4f7cc6bd9b9dc5ad97c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3157055970795622,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.32,
  },
  {
    address: '0x03c9a1f504f75f9b57c0764cae715fa925e348d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.31346374708949026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0x1c7aec4f1b53d38aee4dab7dbf869d6f37c48b39',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3105960571713092,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0xe8c99c1f4e6f970d720c3a206cdc7063e4a961a0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.30965426867751783,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0x18b0b9df9a560b1a334ba522fa584885bf89b85b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.30686432701683586,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0xae64b448f8a6fb3ca9585f1e1d67dbf79f58afce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3053349624738114,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.31,
  },
  {
    address: '0xecce40003f47593c555a281a413dbfbc735312cc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3043370521025481,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x0f5174b9da6e38c5f35dabf6441993d5565dfdcc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.30411847142936316,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x6e22ee937560afc294567c6710bde36038d63bdf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.3018451086623474,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x3c98c2d722783694815376d033a3778c7ccd78bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2996575261217227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x3a652d1b1ce20affcd4cce0c085bf49cabf229bd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.297612635384278,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x7701571427db2b7875a9cf04dacc7762c8a38caa',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2960613376572386,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x66b6c2ffd174828cdb5658e7c7f40eb4d081eba7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.29574659048128976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0xe43d8570fec9d05446730cdb627a5af367725b5c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.29540749334282135,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x301e1a7dd927d7be2ec2511e3343f7fbd9d6f818',
    minters_reward: 0,
    lp_providers_reward: 0.3,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.3,
  },
  {
    address: '0x8976f945f656dfd8983a5b88232d762de5ff8063',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.29164251769612226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x4792b8fb7f98745379d1093b61fad6ed6ebc1fe2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2903145408575962,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0xb5fc69de6de620d266c7769167488fd3bdc94187',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28847603952800593,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x90d3ed16c5ae53f0505dd3e69812f55b2f844dff',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28825366409505604,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x2b3165b489e6f7886acb3cf52503e919def58987',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2858929885442888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0x678334f53a137c87be4730c201c870e5890f97e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2858929885442888,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0xc3a058f00fab7341884d68cf3e51f99dc41d926d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2850507864885912,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.29,
  },
  {
    address: '0xa80235834c107b31736a74b1e59f9c2c86081461',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28414453465039585,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x1c8b769c568eb33d8365396925ef8d2c44d33e7b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28167618872234157,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x62ffeb71757921db12f88f566c39b9d3a8db1ba0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.28078583824644177,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0xa1cfea3f2ee8c429b729cb913c0b9918b45d6101',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2798130200935519,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0xea1ce67599ccc459da5668d16db3a8bd0331c5ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27940089619917124,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x8bb37b3954587fa44c7c9eeb7d3e9a861737f7a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27923171295666016,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x4cc483f2d559b5746bc6fffda8e101e034b53ff8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2787716859540122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0xbfd96423e8afd460c107c2742264af5c755661fb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2787716859540122,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x4c43c8b542e9e8e936fe949a502cabf271c9a3df',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2783877268667166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.28,
  },
  {
    address: '0x8a9564e132bf245a48aa532adbf53c2251fce131',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2741610823406225,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xa8cd4d7fe44fa95e918baae15fbbb12f83f51444',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2736212993551835,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x0881756a3d760e5a390ce1a13cd8725a4f4984c1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27216244805035933,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xe32ba38945d8edf341967dda072113d13ccb0190',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2708890616003936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x20b25496e1f6b3691547555b7c025ef88988ad45',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.27055694125843455,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xbe839d1f7e5131af51b5b3e69c9da7a92e42427b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.26932585071870024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x4c998b7f09f46a88e6f7443698c9f404b7016751',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.26735738247952473,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0xf5e9fb8d7bad5a8683eb08a68bb18c12c30d974d',
    minters_reward: 0,
    lp_providers_reward: 0.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x431b5f310349cb9c144d338c04b4f6765ebf4aa3',
    minters_reward: 0,
    lp_providers_reward: 0.27,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.27,
  },
  {
    address: '0x26dfa0f121206df63d62b2d202e055f405c189b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2624149339331234,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x545d04a8bb4ee874f0c2470a13b1720e6854203f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2613150964280839,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0xff083771c74b91f618d5fbf63bd26908a8a6ad15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.26130966167923236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x7854bc146a8500f7d64fabadb0a36f05a585e676',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2579850331105922,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x35dea8b3e4a98cab50e2a9d44de2d70e5bfdd9ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.25713233996456897,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0x2bb8cd8f53d911e32004dce4d6c6270f7976f1e6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2565522463405414,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.26,
  },
  {
    address: '0xf9cba47ea410983c1ddb2ac243b5bf5d02866489',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.24684794534086846,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.25,
  },
  {
    address: '0x00cab0985bb3cebcfcf6a76f5c2c84a0dc808e43',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2440308924595573,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0x155ae4c750d59b3e3eea657036588fd945f3aebc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23784106707951916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0xb8f6ea352b61c4353bf28328253bfe8d59833e9c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23677919780967166,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0xeb9d0efb7497fed8307b14ed920cbfb15f8abfe1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23551874336199655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0x8766e945574c47b0f08aa4d9b18947b456445e0f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.23544706841731738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.24,
  },
  {
    address: '0x9cb7aff24057a8d1f81093c0c99b0977b6654d8d',
    minters_reward: 0,
    lp_providers_reward: 0.17,
    swapers_reward: 0.05840002691788495,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.23,
  },
  {
    address: '0x5f47382e3871e0e2ea1eb30f7995deef2f8c2016',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.22148611878202343,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0x7da8bea055c4cf1156746894955a2bd88dfe21ec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.21973774395458842,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0x90de232cc59c0a79c35c4ae69d24ac28efb59388',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2196021374487682,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0x562c26b394b177a46a561fe2386f2586df652baf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.21604320177234457,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.22,
  },
  {
    address: '0xcbee98c902b1b38b83b72fe841e4f96ce9a12c82',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.21471517070969137,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0x09c05ce5341e1d371d327b16a2884e6d728770f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2114720018854034,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0xeb0f5476c524a3158b8fd8225c9c2a93a4b491ce',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2110996184777724,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0x2f621a46c9ec3fa00bd04f14bd1346481baa5f90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20560563495125742,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.21,
  },
  {
    address: '0x4aea39dd7dbc2d93c88d2444696948b603bbd4ca',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.2029177059438259,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x75f17e59ef0c97fde04d79f48dcfc444f48885a5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20164736701039532,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0xfdc82816923310ab8a07dcead537f2f90e54923b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20146537446735738,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x8a02e2cae331ae4d77b98e7d0319c0e200636d7e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.20064696350439878,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x92fbfe5b110d125f386aa7839537fbc3c791139a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1993802789566788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.2,
  },
  {
    address: '0x7c213fa21cdadcc7cee2c6340f580abbab2e65b1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.19490447788102716,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0xf017e38547dff4c2b299c632f9b011aab0ad4d91',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1885688430060547,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0x875ba9318926e1634c7d86f492feca34d0c4a6af',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.18816912511807768,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0xbb80072d6b235f43fe23906cee8951c85545a5e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1876223815204554,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0x4d22c28e4385024b1fbd6f8ed3827f4eacb554b5',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.18574815035115103,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.19,
  },
  {
    address: '0xb375245be9ad3deffdfc348ba1f5e79abd9f8eac',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16847468755569633,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0xa0f416600eea83c02eca0300bb1d5a601ccee4ad',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16788781205613112,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0xe5c884d3ae937d63295c71afc81ad82278261586',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16783509592801477,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0x3a26e439f37318086ea765c3f922a4e5ca48fb7f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16703263612002994,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.17,
  },
  {
    address: '0x943e92fc2d236a0c6521daa34626fc7b5e781296',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16338310742397763,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x6942bf802d9ca7871b0a07350dc132ef7235c090',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.16036102731134655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x39dcfa69128e98f24ddc6d578ada8f95e80a0790',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15998930520941582,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x3bf0cb15daeb771dd995c999fd0c81df515e3d30',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1578527985397811,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x2fba0b80eca27df35091a14c7a2762c6fdb87d19',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15766447757678856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.16,
  },
  {
    address: '0x558697ee4ca24c26fbafaa632fbb9d9e80386319',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1541407065182402,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x9c8570e2e3006052e109bd4b7fc2ffc75c1e69bb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15384664399200318,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x46d6a748b634cd19770a620b535c82a67956ee74',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.15089155380530875,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0xd24f72e3b0f75f95bd3d030e64e9423ddeaabfa6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14898898388021653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x8ba7838c11500038c57816eb51858a22fdb1e0b0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1480306688286193,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x431afe78527498d5c0df8ca8bddb4a6006972525',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1471841965855236,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.15,
  },
  {
    address: '0x018266a46b0e1f194ba8b18a8ad47f18dff85d6e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14471394726034656,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xa83cd9876d33d4fdb9156033004ee6cd14ab9344',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1433849631715643,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xf4f5682ef17c58c6e7ca949fc3251990e1c0e72f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1429464942721444,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xa6f9e62efe564101c7887789be095bbac1a7304e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1427994726590743,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0x9ffe58075f9f9daf34b87b36d0984afb3161d7fe',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1427539139134439,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xf56aee5f5d349bc799311f70fdd3aed93c724924',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14207226732519793,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xf121149c25c100e838d5dfcc574adfb91be6aeb4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.14002879169302726,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xd2242e533da63fa0f5df92f0c12a30dd59290194',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1393858429770061,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xdeb7d63e3b8401a0e6e2542ef59bc811de1b62bf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13724161692077338,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xea90c7c536b5667dbe31689de3e5e92d3ee30be9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13544455788869664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0x2a4ed13becb406d3916e2e764efa0bfd1b22676b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1354445308001968,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xa32545fb08b09fb43e6f60cbc26397800f33acc2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13528046458647053,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0xae3c14c6a00e0a76b9f1794f8bd582a82136108e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.13527847062921727,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.14,
  },
  {
    address: '0x203d5a98351f9e636af48d633ea3bb7ba0b52fa3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12808652394864228,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0x30be9ff3f35decb96bb7919988c828e4270427bc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1273898664827841,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0x90b8b0ee6e538f845766c4bb48229b224b954d81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12591585904209837,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0xcb54e9e91ab20dd9d36aaf94cbb09148b518be27',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12547668450432523,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.13,
  },
  {
    address: '0x75066852eba349c1034e86323cf46e3f5e7abb15',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.12406147064977664,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x7611321f11b606df6cdc2704be99893aabebf6e7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1169985954054735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0xb9221c0d8b1cac58967368c7b798e089ab3d1f90',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.1168295450913612,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x8a0b4e05b6b8f80408dbba93600240a33404f9a9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.11649816000856332,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x32a1be91984bc63ad293ecd3578c5e5f46ddaeb2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.11527610066635675,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x5a3aca601ccdf8efb15b870b0816fe025e7d01ac',
    minters_reward: 0,
    lp_providers_reward: 0.08,
    swapers_reward: 0.041815752893101826,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.12,
  },
  {
    address: '0x3ad11f6749a2279af68eea8b597240541c9f60d2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.11007150121388296,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.11,
  },
  {
    address: '0xae75d395f98afd88da5f111a6105d680e54e6d41',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10558596379790032,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.11,
  },
  {
    address: '0xab5b717029cb1fedc64090c3de95bacaf9c4443c',
    minters_reward: 0,
    lp_providers_reward: 0.11,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.11,
  },
  {
    address: '0x1c1a2286d6b95987479251074538961f872cf932',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10362146818003348,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0xf8f739c1f70d777984d556638b49a9c5d42f7eb3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10302890081381735,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x68379333d973f0615a0aa58a357467fc74806d8c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.10029272123716214,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x39303389965b1a9b3b71d7853268690b2d1a763d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09938772572190936,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x9fc6de54c2dc599b93669c9c140e89f8fb7c918a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09722061411197448,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x132c03b5e084b0f3ca737bb83e2023fc54aa58dc',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09719850982130349,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x53a210c404296d397ca257a0d74d6d0ea9d986a7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09689069580138428,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0xe125559f75ff5532346e170ac6503ae8ac028131',
    minters_reward: 0,
    lp_providers_reward: 0.1,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.1,
  },
  {
    address: '0x50a27a780e8430178434bcad856891286e321683',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09424912886776653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x6a1a1dd4317be1b1eba5da0ccaf4992ef8a18e95',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09372227246549181,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x2064fccd76c54ee65610c5fe6b9e122361578934',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09215004396675958,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0xe00c0fb3078575d6ecdd3ab65d6fdc5b96bb1af9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09125282283966976,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x1ba001c94974bd4781b97c668154cff13f974f94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09072658787465072,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0xd9b33f9a6c2f3e8e1f37c9fc045838ebd0c2ae97',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.09070324578591145,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x02f8bedee709b9a771c7b02ed16318e2043c8db0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08827951319452129,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0x4c9b411f495a6b80eb9bb5a7c7d689a530a4b3a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0881861022771024,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.09,
  },
  {
    address: '0xabe7d7d429fccbb5a17677bf27f3ff6f3663b31c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08434856524811866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0xf6c1d2e4bc3ab508d7bf69aa43da78f6941396f2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08366804862009226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0x6d232f1f2a22d75963f1e4363dc042f851414997',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08267176681779588,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0xd7afa6ad574a4db64e57f45429c79eb677e2ab93',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.08114224716983026,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0x6fd67201fbfc568aa4986d10c006f7459aaae84f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07913772700745436,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.08,
  },
  {
    address: '0xb03365882b058ccd919a5fdbd3f26b0952bfb52a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07370160753456502,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0x8cf88578d84db3210322d551b263de418659ca72',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.07286861791669759,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0x98358d1d79adad834489f2f801bae66c626c3dd3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0711703216580697,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0xf2f1375d2d4cb894e21b7d1ca584062115302833',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0709912326585067,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0xdbd78ddd4e122cbbc376f999a3631b0b358590d0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06959693171667915,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0x99a562569702e836f69b6ba5a67df830497d0462',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0677222654000984,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.07,
  },
  {
    address: '0xc276432ffe0a9ee84c3a96eec36fa8dbd96d0bdd',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06460702448227183,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x8d96bdd30bbf9894394fad91e257c4ab1c0f7ee6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0636394449795929,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x79ae89c2b90cb49b7747fc28e0b50259b2bf42eb',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.06283286189072383,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0xef94538da28c8cdf5ef85564ba84b86e66cccf3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.061677147731852226,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0xa3ee47797ba6c085ad5cb8936526f93ff827fd0b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.060164492763015866,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x51efd291c46c6b3dc826a9f2c01370b027bf2551',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05895196357849535,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0x326d3cbac4f5a104a69aeeca7f1314c6e9fd6d84',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.055122309609885775,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.06,
  },
  {
    address: '0xe2d6cec63b5def091b121cc8389c3e2ce7adc372',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.054661102553794916,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xcf3dcc9ee6eef6d92de12d081540967058976560',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05421950006847982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xc6cb9584caaacaf4a9c4243b2a13dae68ee66ad9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.051822555549103665,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0x2d7bdd1b56560908f059beb3e11626d028d4c798',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05117569288132949,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0x300ca9b48c3bfcfbc3194860ed1eb31d37c51d0c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.05039781480690953,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0x575d4cedb7df51a7e6673c6a3ec331ae66c63770',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.04781007985024077,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xc310a82f5d1cc5d6d522a3ba2ab486596dad706b',
    minters_reward: 0,
    lp_providers_reward: 0.04,
    swapers_reward: 0.013740717771627883,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.05,
  },
  {
    address: '0xd55fdba085461d5b17ad4e2aff74f01defc06c68',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.043577492151246636,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x0ae0bcab1edd7e07c8b51cb14d4e1347c3bbd4b9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.043416316109512,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x4b7c30d50e53069d4f041f8b77ac63a6cc4481de',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0427152720167178,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x7fd2c4ed3a34e0d1cc0ce85d7c4a93afd3b3e2c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.036396727778402346,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x2d9ea8ff0074ab98aeae88337b160b92debab194',
    minters_reward: 0,
    lp_providers_reward: 0.04,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.04,
  },
  {
    address: '0x9404be01769159e513a00c6f48f3df2aedf417a1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.03124065533271393,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0xe9918b0a83332b8a7bb9218ae88a128f7f145608',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.029672369182670594,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x415d5016ab270007451150c79aec97631d6abdc1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.028834025526456822,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0xd62ed6e3ec421ebb3dab26c1baef553e9d1df4a4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0285610441514653,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x86afc841d7531bf12cdd9db6f31426d3ba100cb1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.027935548702913263,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x555531d40ddbcc6b70bcfe588692b6521969f6f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.027615040578836687,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.03,
  },
  {
    address: '0x633d2e18b05b16788e6c39951be2e26531e59b5a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.02312110597773603,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x690adc78cc701203b880bdb739d3371c554a7899',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.022077629487828536,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xcbf486fd541fb7d89ceed1ccac13d28a41bdb9d4',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.021250066077003218,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xb13a156b1cc2cc331d8653fa6c3f5ee5a2329172',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01966685805615534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x05db4750f72e352464371297e459c43906a5936f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01966685805615534,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x72db9e58487f0bc203f924f9cc5def0e4fd65c94',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.018311471836373952,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x1d0230a9d81cb29d3efa01523698d4833c2701c0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.018040402254640774,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xae724ca1d5c9aba287cd9586044a0f38d8e562c2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.017349060593430422,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xd76d59a84b82ce67923f4c0116a9789ecb31aba6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.016288651899390133,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xd5a42606ef111cd4f33523a6ccaf5345c1409503',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.015978277516183227,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xf5d31489e52c49ccd5bca6cfeb3c9b1b959dcc89',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01593398499468368,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0xc3ea6d42ba433f49e820876a5b7b3c40d189008f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01577144874324856,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x46ae957c6bdc3bb54373dbbefbdea91e63b697f9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.015061001927194914,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.02,
  },
  {
    address: '0x01ab2c974aca11c9d313cab66fda5696d4f0b784',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014910140386267779,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xddee9899a75f1a73137934b1b3eca3cee5465d9b',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014707188748438606,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x2b7da83c350853873ebaff565e1116b647fca342',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014522981830845655,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xb209f1c4f9b0449b7654dd0b3676f9931ca950a3',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.014271275096735333,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x32bdf00c07d6dec33a2102bf72d3ac7aca9bee53',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01423406433161394,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x55c03cfbb33dcdfcbc89dfe5e7f523280789094e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01398625799400123,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x4a747bd3ee3b5061c1702ef06153abc4533bae22',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.013379868602424518,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x67da37c824897e19211ec877a6a979f0ccf317a6',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.013065754821404197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x763324eaee99404ae4bc0a7e31fde3ef21317ec2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.01290477573319812,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x5f8a0392e616872d20844db47f124b9039219ad0',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.012566572378144764,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x6272355f955d5b520da7c3c9c589c9628fda7b81',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.011559982664404216,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x1dec1cdf7afb512d54db88308b46a83771acaeec',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.011198853310369376,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xa444f4e3a88a9b8aac2ee87715894eabc5a939c7',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.010496636501780982,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x3122ad3a93e28b74caee6f081de8cdf06dc1148d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.008046021046777424,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x816cdbeccec73066d7afd7f6a25f2f1036503117',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.007099123265850668,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x49d06b68a6c1c9657c0417749393ad8e2032dd05',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0069596931716679146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x93268f1684a331b19cdafb41a6788d559a9d3fc8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0069596931716679146,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xcae07e3055886177c9ba7f2c48655a3bdd140042',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x86534a5ecc7a00af5518ea316a0afc52a6513875',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x3405eaa4e24474aa700671c985a2f4c31328a16a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x3b3f93733947f2fa05b41f62b92d2f32c3b2d61a',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006486943225788788,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xf9e2dc91e4c9b6f9e30b67eb4e5619832a2c056f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.00636949332413925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0xad11cfa315cdcd2798c1fe4d3bd6047b470ba583',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.00636949332413925,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x28a321c5e5de740ab78170f761f4d35009cbcc96',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.006187253909297213,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0.01,
  },
  {
    address: '0x5e7ec1ddecdad30d2b8eff57e97053fb38f1bd4f',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0034775773584901854,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xc7cc6ff0f20cca32ba611b745acf691b64270100',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.00232321085902755,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xd9be99eade5573dba6ad9fb84e82834150f6d10c',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0014640438472076362,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xe05cff8ae6769a2be8ff757ee031e46c0162bacf',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0013937685087289805,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x6092c8d81ec78142e347c513f3d89905fc55e695',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0013065754821404197,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xceef340392e6313ea66c87686836ea33e7cac02e',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0006369493324139203,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x41f555a5460a3ca0b48cb2b8a69f0904af6989b8',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0002783852862637416,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x53f90c7e62a91044d969f7a2df59f4f4d942b7a2',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0.0000544931219445719,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x8d62c1fdd557d7520977a5384a2a22607ec3de3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 4.355394775341176e-8,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x3d679e0eafd1d75cf64dcfb517d566cd5e563556',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 3.664098698043694e-8,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xbce030c09d73ac91677e5df4bec4cf9ea52ed7f1',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 7.03252445063475e-15,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0xe8000ba6c83d377a73d7346f2da1bbefd019cd04',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x551be5a3de85c9e3eea712e746590fd95238be3d',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
  {
    address: '0x39084e2c8667bf6790632d546e8ab218ad0dabb9',
    minters_reward: 0,
    lp_providers_reward: 0,
    swapers_reward: 0,
    holders_reward: 0,
    hoaxs_reward: 0,
    total_rewards: 0,
  },
]
